import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
// import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
// import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { Typography } from '@mui/material'
//  TODO: Change icons
/// ////////COMPONENT PROPS
OtherOfferDetails.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  success: {
    color: theme.palette.success.main
  }
}))

export default function OtherOfferDetails(props) {
  const classes = useStyles()
  const { checked, text } = props

  return (
    <div className={classes.root}>
      {/* {checked ? (
        <CheckCircleOutlineRoundedIcon className={classes.success} />
      ) : (
        <HighlightOffRoundedIcon color='error' />
      )} */}
      <Typography>{text}</Typography>
    </div>
  )
}
