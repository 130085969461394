import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFirebase, useForm, useI18N, useLogin } from 'hooks'
import { Grid, Typography, Box } from '@mui/material'
import {
  PasswordInput,
  Button,
  Form,
  JobsaunLogo,
  Input,
  ResetPasswordDialog,
  SimplePageContainer,
  Spacer,
  Link
} from 'components'
import { routes } from 'constants/Routes'

const initialValues = {
  email: '',
  password: ''
}

export default function SignIn() {
  const { t } = useI18N()
  const history = useHistory()
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [sent, setSent] = useState(false)
  const { signIn, loading } = useLogin()
  const { values, handleInputChange } = useForm(initialValues)
  const { sendPasswordReset } = useFirebase()

  const [errors, setErrors] = useState({
    email: false,
    password: false
  })

  function handleErrors(error) {
    switch (error) {
      case 'auth/user-not-found':
        setErrors({ email: t('signInEmailError'), password: '' })
        break
      case 'auth/wrong-password':
        setErrors({
          email: false,
          password: t('signInPasswordError')
        })
        break
      case 'auth/too-many-requests':
        setErrors({
          email: false,
          password: t('tooManyRequest')
        })
        break
      default:
        setErrors({
          email: false,
          password: t('wrongCredentials')
        })
        break
    }
  }

  function handleLogIn() {
    signIn({
      email: values.email,
      password: values.password,
      onComplete: () => history.replace(routes.account),
      onError: (e) => handleErrors(e)
    })
  }

  const handleGuestButtonClick = () => {
    history.replace(routes.home)
  }

  function handleRecoverPassword() {
    setProcessing(true)
    sendPasswordReset({
      email: values.email.toLowerCase(),
      onCompleted: () => {
        setSent(true)
        setProcessing(false)
      },
      onError: () => {
        setProcessing(false)
      }
    })
  }

  const handleKeyPress = (e) => {
    // it triggers by pressing the enter key
    if (e.which === 13) {
      handleLogIn()
    }
  }

  return (
    <SimplePageContainer>
      <Spacer m={2}>
        <Box sx={{ color: 'text.primary', mb: 5, pt: 3, textAlign: 'center' }}>
          <JobsaunLogo width={180} />
        </Box>

        <Typography fontSize='2.2rem' fontWeight={600} lineHeight='1.4em'>
          {t('signIn')}
        </Typography>
        <Typography fontSize='1.2rem' color='textSecondary'>
          {t('signInWelcome')}
        </Typography>

        <Form sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                id='email'
                type='email'
                label={t('email')}
                error={errors.email}
                value={values.email.toLowerCase()}
                disabled={loading}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                id='password'
                label={t('pass')}
                error={errors.password}
                value={values.password}
                disabled={loading}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            </Grid>
          </Grid>

          <Box sx={{ my: 1 }}>
            <Button
              onClick={() => !loading && setPasswordDialogOpen(true)}
              text={t('forgotPassword')}
              variant='text'
            />
          </Box>

          <Button
            text={t('signInText')}
            fullWidth
            shadow
            onClick={handleLogIn}
            loading={loading}
          />

          <Box sx={{ mb: 6, mt: 3 }}>
            <Typography>
              {`${t('registerQ')} `}
              <Link href={routes.signUp}>{t('signUpText')}</Link>
            </Typography>
          </Box>

          <Button
            text={t('continueAsGuest')}
            variant='ghost'
            fullWidth
            onClick={handleGuestButtonClick}
            disabled={loading}
          />
        </Form>
      </Spacer>
      <ResetPasswordDialog
        open={passwordDialogOpen}
        onChange={handleInputChange}
        processing={processing}
        sent={sent}
        value={values.email.toLowerCase()}
        onClose={() => setPasswordDialogOpen(false)}
        onSend={handleRecoverPassword}
      />
    </SimplePageContainer>
  )
}
