import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { devKeys, prodKeys } from './keys'

const production = process.env.NODE_ENV === 'production'

const firebaseConfig = {
  apiKey: production ? prodKeys.firebaseApiKey : devKeys.firebaseApiKey,
  authDomain: production ? prodKeys.firebaseAuthDomain : devKeys.firebaseAuthDomain,
  projectId: production ? prodKeys.firebaseProjectId : devKeys.firebaseProjectId,
  storageBucket: production ? prodKeys.firebaseStorageBucket : devKeys.firebaseStorageBucket,
  messagingSenderId: production ? prodKeys.firebaseMessagingSenderId : devKeys.firebaseMessagingSenderId,
  appId: production ? prodKeys.firebaseAppId : devKeys.firebaseAppId,
  ...(production && { measurementId: prodKeys.firebaseMeasurementId }),
}

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()
export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const fbase = firebase
