import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_PARTICULAR } from 'apis/querys'
import {
  CardContent,
  Typography,
  Box,
  Avatar,
  Divider,
  Skeleton
} from '@mui/material'
import { CustomCard } from 'components'
import { getDate, getUserPicture } from 'helpers'
import { useI18N } from 'hooks'

export default function JobCard({
  loading,
  userId,
  title,
  city,
  startDate,
  postDate,
  budget,
  href
}) {
  const { t } = useI18N()
  const { data } = useQuery(GET_PARTICULAR, {
    errorPolicy: 'all',
    variables: { userId },
    skip: loading
  })

  if (loading) return <LoadingCard />

  return (
    <CustomCard href={href}>
      <CardContent sx={{ px: 0 }}>
        <Box aria-label='main-info-container' sx={{ display: 'flex', px: 2 }}>
          <Avatar
            src={
              data && data.getUser
                ? getUserPicture(data.getUser.picture).profileImage
                : ''
            }
            alt={title}
            sx={{
              width: 48,
              height: 48,
              boxShadow: (theme) =>
                `0px 0px 0px 1px ${theme.palette.action.disabledBackground}`,
              mr: 2,
              bgcolor: 'action.disabledBackground',
              backgroundImage:
                data && data.getUser
                  ? `url(${getUserPicture(data.getUser.picture).bgImage})`
                  : 'none',
              backgroundSize: 'cover'
            }}
          />
          <Box sx={{ flex: 1, maxWidth: '80%' }}>
            <Typography
              fontWeight={500}
              fontSize='1.1rem'
              lineHeight={1.3}
              noWrap
            >
              {title}
            </Typography>
            <Typography noWrap color='textSecondary' fontSize='small'>
              {city}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 1 }} light />
        <Box
          aria-label='meta-info-container'
          sx={{
            display: 'flex',
            px: 2,
            alignItems: 'flex-end'
          }}
        >
          <Box sx={{ flex: 1, display: 'flex' }}>
            <Box
              component='span'
              aria-label='start-date-container'
              sx={{
                bgcolor: 'action.selected',
                width: 48,
                height: 48,
                p: 1,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mr: 2
              }}
            >
              <Typography textAlign='center' fontWeight={600} lineHeight={1.1}>
                {getDate(startDate, false, 'd')}
              </Typography>
              <Typography
                color='textSecondary'
                fontSize='small'
                textAlign='center'
                lineHeight={1.2}
              >
                {getDate(startDate, false, 'MMM')}
              </Typography>
            </Box>
            <Box aria-label='budget-container'>
              <Typography
                color='textSecondary'
                fontSize='small'
                lineHeight={1.3}
              >
                {t('budget')}
              </Typography>
              <Typography fontSize='0.9rem'>{`${budget}€`}</Typography>
            </Box>
          </Box>
          <Typography
            color='textSecondary'
            fontSize='small'
            textAlign='right'
            lineHeight={1.2}
          >
            {t('posted')}: {getDate(postDate, false, 'd MMM yy')}
          </Typography>
        </Box>
      </CardContent>
    </CustomCard>
  )
}

const LoadingCard = () => (
  <CustomCard>
    <CardContent sx={{ px: 0 }}>
      <Box aria-label='main-info-container' sx={{ display: 'flex', px: 2 }}>
        <Skeleton variant='circular' width={48} height={48} />
        <Box sx={{ flex: 1, maxWidth: '80%', pl: 2 }}>
          <Typography fontSize='1.1rem'>
            <Skeleton />
          </Typography>
          <Typography fontSize='small'>
            <Skeleton width='60%' />
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} light />
      <Box
        aria-label='meta-info-container'
        sx={{
          display: 'flex',
          px: 2,
          alignItems: 'flex-end'
        }}
      >
        <Box sx={{ flex: 1, display: 'flex' }}>
          <Skeleton
            variant='rectangular'
            width={48}
            height={48}
            sx={{ borderRadius: 2, mr: 2 }}
          />

          <Box aria-label='budget-container'>
            <Typography fontSize='small'>
              <Skeleton width={80} />
            </Typography>
            <Typography fontSize='0.9rem'>
              <Skeleton width={60} />
            </Typography>
          </Box>
        </Box>
        <Typography fontSize='small' textAlign='right'>
          <Skeleton width={100} />
        </Typography>
      </Box>
    </CardContent>
  </CustomCard>
)
