import { useHistory } from 'react-router-dom'
import { ButtonsList, ScrollableContainer } from 'components'
import { externalRoutes, routes } from 'constants/Routes'
import { DocumentText, Lock1 } from 'iconsax-react'
import { useI18N } from 'hooks'

const createMenuItem = (id, title, subtitle, icon, action) => {
  return { id, title, subtitle, icon, action }
}

export default function Info() {
  const { t } = useI18N()
  const history = useHistory()
  const appVersion = process.env.REACT_APP_VERSION
  const goTo = (to) => () => history.push(to)
  const open = (url) => () => window.open(url, '_blank')

  const infoList = [
    createMenuItem(
      'privacy',
      t('privacy'),
      null,
      <Lock1 size='24' variant='Outline' />,
      open(externalRoutes.legal)
    ),
    createMenuItem(
      'updates',
      t('updates'),
      `${t('current')}: v${appVersion} Beta`,
      <DocumentText size='24' variant='Outline' />,
      goTo(routes.updates)
    )
  ]

  return (
    <ScrollableContainer title={t('info')} appBarProps={{ hasBack: true }}>
      <ButtonsList list={infoList} />
    </ScrollableContainer>
  )
}
