import { useLocalStorage } from 'react-use'
import { LOCAL_KEYS } from 'constants/LocalKeys'
import { useSettings, useUserInfo } from 'hooks'
import { subcategoriesIds } from 'constants/CategoriesData'
import { useEffect } from 'react'
// import { getUserCountryCode } from 'helpers/getUserCountryCode'

const defaultPostsFilters = {
  categories: subcategoriesIds,
  province: null,
  budget: 0,
  sortBy: {
    field: 'creation_date',
    order: 'DESC'
  }
}

const defaultCompaniesFilters = {
  categories: subcategoriesIds,
  province: null,
  sortBy: {
    field: 'creation_date',
    order: 'DESC'
  }
}

export default function useFilters(target) {
  const { settings } = useSettings()
  const { user, loading } = useUserInfo()
  const discover = target ?? settings.discover
  const [postsFilters, setPostsFilters] = useLocalStorage(
    LOCAL_KEYS.POSTS_FILTERS,
    defaultPostsFilters
  )
  const [companiesFilters, setCompaniesFilters] = useLocalStorage(
    LOCAL_KEYS.COMPANIES_FILTERS,
    defaultCompaniesFilters
  )

  // const client = useApolloClient()
  // await client.refetchQueries({
  //     updateCache(cache) {
  //       cache.evict({ fieldName: 'getJobs' })
  //     },
  //   })

  useEffect(() => {
    if (!loading && user && user?.country) {
      setPostsFilters((prev) => ({ ...prev, country: user.country }))
      setCompaniesFilters((prev) => ({ ...prev, country: user.country }))
    }
  }, [user])

  const companiesIsSelected = discover === 'companies'
  const filter = companiesIsSelected ? companiesFilters : postsFilters

  const updateFilters = (newValues) => {
    switch (discover) {
      case 'companies':
        setCompaniesFilters({ ...companiesFilters, ...newValues })
        break
      case 'posts':
        setPostsFilters({ ...postsFilters, ...newValues })
        break
      default:
        break
    }
  }

  const defaultFilters = () => {
    switch (discover) {
      case 'companies':
        return defaultCompaniesFilters
      case 'posts':
        return defaultPostsFilters
      default:
        break
    }
  }

  return {
    filter,
    companiesIsSelected,
    postsFilters,
    companiesFilters,
    updateFilters,
    defaultFilters
  }
}
