import { useState } from 'react'
import { GET_USER_OFFERS } from 'apis/querys'
import { useUser } from 'context/UserContext'
import { useQuery } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import ErrorIllustration from 'assets/illustration/error'
import { Button, DefaultOfferCard } from 'components'
import { OFFER_STATES } from 'constants/Enums'
import NothingHereIllustration from 'assets/illustration/nothing-here'
import NothingRejected from 'assets/illustration/nothing-rejected'
import NothingAccepted from 'assets/illustration/nothing-accepted'
import NothingNegotiating from 'assets/illustration/nothing-negotiating'
import { useI18N, useSearchParams } from 'hooks'
import NoResultsIllustration from 'assets/illustration/no-results'

export default function OffersList({
  state = OFFER_STATES.ACCEPTED,
  sort = 'DESC'
}) {
  const { t } = useI18N()
  const { user } = useUser()
  const { search } = useSearchParams('search')
  const [limit, setLimit] = useState(20)
  const [noMoreResults, setNoMoreResults] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  const variables = {
    userId: user.id,
    sortBy: {
      field: 'creation_date',
      order: sort
    },
    offset: 0,
    limit,
    state: [state]
  }

  const { error, data, fetchMore, networkStatus } = useQuery(GET_USER_OFFERS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables
  })

  const searchFilter = search ?? ''
  const offers =
    data?.getUserOffers?.filter((offer) =>
      offer.post.title.includes(searchFilter)
    ) ?? []

  const onLoadMore = () => {
    if (!noMoreResults) {
      setLoadingMore(true)
      const currentLength = offers.length
      if (currentLength) {
        fetchMore({
          variables: {
            offset: currentLength,
            limit: 20
          }
        }).then((fetchMoreResult) => {
          const newLength = fetchMoreResult.data?.getUserOffers.length

          if (newLength === 0) {
            setNoMoreResults(true)
          }
          setLimit(currentLength + newLength)
        })
      }
    }
  }

  const loading = networkStatus !== 7

  if (error) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          inset: 0,
          textAlign: 'center',
          p: 2
        }}
      >
        <Box width='70%' maxWidth={300}>
          <ErrorIllustration />
        </Box>
        <h2>{t('errorLoadingData')}</h2>
      </Box>
    )
  }

  if (loading) {
    return (
      <Grid container spacing={2}>
        {[...Array(3).keys()].map((i) => (
          <Grid key={i} item xs={12}>
            <DefaultOfferCard loading />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      {offers.length === 0 && (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            inset: 0,
            textAlign: 'center',
            p: 2
          }}
        >
          {search ? (
            <>
              <Box width='70%' maxWidth={200}>
                <NoResultsIllustration />
              </Box>
              <Typography fontSize={18} fontWeight={500} maxWidth={300}>
                {t('nothingFound')}
              </Typography>
            </>
          ) : (
            <>
              {state === OFFER_STATES.ACCEPTED && (
                <>
                  <Box width='70%' maxWidth={300}>
                    <NothingAccepted />
                  </Box>
                  <Typography fontSize={18} fontWeight={500} maxWidth={300}>
                    {t('nothingInAccepted')}
                  </Typography>
                  <Typography color='textSecondary' maxWidth={300}>
                    {t('StartSendingOffersToPotencialCustomers')}
                  </Typography>
                </>
              )}
              {state === OFFER_STATES.NEGOTIATING && (
                <>
                  <Box width='70%' maxWidth={300}>
                    <NothingNegotiating />
                  </Box>
                  <Typography fontSize={18} fontWeight={500} maxWidth={300}>
                    {t('nothingInNegotiating')}
                  </Typography>
                </>
              )}
              {state === OFFER_STATES.REJECTED && (
                <>
                  <Box width='80%' maxWidth={300}>
                    <NothingRejected />
                  </Box>
                  <Typography fontSize={18} fontWeight={500} maxWidth={200}>
                    {t('nothingInRejected')}
                  </Typography>
                </>
              )}
              {state === OFFER_STATES.ARCHIVED && (
                <>
                  <Box width='80%' maxWidth={300}>
                    <NothingHereIllustration />
                  </Box>
                  <Typography fontSize={18} fontWeight={500} maxWidth={150}>
                    {t('nothingInArchived')}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
      )}

      {offers.length > 0 && (
        <Grid container spacing={2}>
          {offers.map((offer) => (
            <Grid key={offer._id} item xs={12}>
              <DefaultOfferCard offerData={offer} />
            </Grid>
          ))}
          {!loading && !noMoreResults && offers.length >= limit && (
            <Button
              onClick={onLoadMore}
              variant='loadMore'
              loading={loadingMore}
              text={t('loadMore')}
              sx={{ mx: 'auto', mt: 2 }}
            />
          )}
        </Grid>
      )}
    </>
  )
}
