import React from 'react'
import { Avatar, ButtonBase } from '@mui/material'
import { getUserPicture } from 'helpers'
import { Image } from 'iconsax-react'

export default function AvatarInput({ alt, image, sx, ...other }) {
  return (
    <ButtonBase sx={{ borderRadius: '50%' }} component="span" {...other}>
      <Avatar
        alt={alt}
        src={getUserPicture(image).profileImage}
        sx={{
          transition: 'all 0.2s ease-in-out',
          width: 88,
          height: 88,
          bgcolor: 'action.hover',
          backgroundImage: `url(${getUserPicture(image).bgImage})`,
          backgroundSize: 'cover',
          mx: 'auto',
          color: 'text.secondary',
          ...sx,
        }}
      >
        {!image && <Image size="40%" />}
      </Avatar>
    </ButtonBase>
  )
}
