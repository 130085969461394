import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_COMPANIES } from 'apis/querys'
import { useFilters, useI18N, useSearchParams } from 'hooks'
import { Box, Grid } from '@mui/material'
import {
  CompanyResumeCard,
  Button,
  Modal,
  DiscoverFilter,
  SortDialog
} from 'components'
import { routes } from 'constants/Routes'
import NoResultsIllustration from 'assets/illustration/no-results'
import ErrorIllustration from 'assets/illustration/error'

const sortOptions = (t) => [
  {
    id: 'newFirst',
    field: 'creation_date',
    label: t('newFirst'),
    order: 'DESC'
  },
  {
    id: 'oldFirts',
    field: 'creation_date',
    label: t('oldFirst'),
    order: 'ASC'
  }
  //  TODO:
  // {
  //   id: 'betterRating',
  //   field: 'creation_date',
  //   label: texts().betterRating,
  //   order: 'ASC'
  // },
  // {
  //   id: 'moreAcceptedOffers',
  //   field: 'creation_date',
  //   label: texts().moreAcceptedOffers,
  //   order: 'ASC'
  // }
]

export default function CompaniesList({
  isFilterOpen,
  onFilterClose,
  isSortOpen,
  onSortClose
}) {
  const { t } = useI18N()
  const { search } = useSearchParams('search')
  const { companiesFilters: filter } = useFilters()
  const [noMoreResults, setNoMoreResults] = useState(false)
  const [limit, setLimit] = useState(20)
  const [loadingMore, setLoadingMore] = useState(false)

  const variables = (filter, search) => ({
    sortBy: filter.sortBy,
    offset: 0,
    limit,
    categories: filter.categories,
    province: filter.province,
    country: filter.country,
    name: search,
    nif: search,
    bio: search
  })

  const { error, data, fetchMore, networkStatus, refetch } = useQuery(
    GET_COMPANIES,
    {
      fetchPolicy: 'cache-and-network',
      // fetchPolicy: 'network-only',
      // nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: variables(filter, search)
    }
  )

  const companies = data?.companies

  const onLoadMore = () => {
    if (!noMoreResults) {
      setLoadingMore(true)
      const currentLength = companies?.length
      if (currentLength > 0) {
        fetchMore({
          variables: {
            offset: currentLength,
            limit: 20
          }
        }).then((fetchMoreResult) => {
          const newJobsLength = fetchMoreResult.data?.companies.length

          if (newJobsLength === 0) {
            setNoMoreResults(true)
          }
          setLoadingMore(false)
          setLimit(currentLength + newJobsLength)
        })
      }
    }
  }

  const handleFilterClose = () => {
    onFilterClose()
  }

  const handleSortClose = () => {
    onSortClose()
  }

  const handleFilterSave = (newValues) => {
    const newVariables = variables(newValues, search)
    refetch({ ...newVariables })
    onFilterClose()
  }

  const handleSortSave = (newValues) => {
    const newVariables = variables(newValues, search)
    refetch({ ...newVariables })
    onSortClose()
  }

  const loading = networkStatus === 1

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          p: 2
        }}
      >
        <Box height={240} width={240}>
          <ErrorIllustration />
        </Box>
        <h2>{t('errorLoadingData')}</h2>
      </Box>
    )
  }

  if (loading) {
    return (
      <Grid container spacing={2}>
        {[...Array(3).keys()].map((i) => (
          <Grid key={i} item xs={12}>
            <CompanyResumeCard loading />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      {companies?.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            p: 2
          }}
        >
          <Box height={240} width={240}>
            <NoResultsIllustration />
          </Box>
          <h2>{t('noResultsWasFound')}</h2>
        </Box>
      )}

      {companies?.length > 0 && (
        <>
          <Grid container spacing={2}>
            <>
              {companies.map((company, index) => (
                <Grid key={company._id} item xs={12}>
                  <CompanyResumeCard
                    name={company.name}
                    legalForm={t(company.company_data.legal_form)}
                    city={company.company_data.city}
                    postal={company.company_data.postal_code}
                    province={company.company_data.province}
                    img={company.picture}
                    href={`${routes.company}?id=${company._id}`}
                    companyId={company._id}
                  />
                </Grid>
              ))}
              {!loading && !noMoreResults && companies.length >= limit && (
                <Button
                  onClick={onLoadMore}
                  variant='loadMore'
                  loading={loadingMore}
                  text={t('loadMore')}
                  sx={{ mx: 'auto', mt: 2 }}
                />
              )}
            </>
          </Grid>
        </>
      )}
      {/* Filter Drawer */}
      <Modal
        aria-label='filter-drawer'
        anchor='bottom'
        open={isFilterOpen}
        onClose={handleFilterClose}
      >
        <DiscoverFilter onClose={handleFilterClose} onSave={handleFilterSave} />
      </Modal>

      {/* Sort Drawer */}
      <Modal
        aria-label='sort-drawer'
        anchor='bottom'
        open={isSortOpen}
        onClose={handleSortClose}
      >
        <SortDialog
          onClose={handleSortClose}
          onSave={handleSortSave}
          sortOptions={sortOptions(t)}
        />
      </Modal>
    </>
  )
}
