import { Box, Container, Typography } from '@mui/material'
import { Button, FAButton, ButtonsList, ScrollableContainer } from 'components'
import { Add, Camera, Category } from 'iconsax-react'

const listItemConstructor = (id, action, icon, title, subtitle) => ({
  id,
  action,
  icon,
  title,
  subtitle
})

const buttonsListItems = [
  listItemConstructor('item1', null, <Camera />, 'Title text', 'Subtitle text'),
  listItemConstructor(
    'item2',
    null,
    <Category />,
    'Title text',
    'Subtitle text'
  )
]

export default function Admin() {
  return (
    <ScrollableContainer>
      <Container sx={{ py: 3 }}>
        <Typography variant='h2'>Component tests</Typography>
        <Typography fontWeight={500} mb={3}>
          Buttons
        </Typography>
        {/* Tests Buttons */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
            mb: 3
          }}
        >
          <Button text='Default button' />
          <Button shadow text='Default button with shadow' />
          <Button round text='Default round button' />
          <Button variant='light' text='Light button' />
          <Button disabled text='Disabled button' />
          <Button loading text='Loading button' />
          <Button variant='ghost' text='Ghost button' />
          <Button variant='ghost' disabled text='Disabled ghost button' />
          <Button
            variant='ghost'
            borderColor='#0046DC'
            text='Custom color ghost button'
          />
          <Button variant='text' text='Text button' />
          <Button variant='text' disabled text='Disabled text button' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
            mb: 3
          }}
        >
          <FAButton icon={<Add />} />
          <FAButton icon={<Add />} loading />
          <FAButton text='Floating action button' />
          <FAButton icon={<Add />} text='FAB with icon' />
          <FAButton icon={<Add />} disabled text='Disabled FAB' />
          <FAButton icon={<Add />} text='Ghost FAB' ghost />
          <FAButton icon={<Add />} text='Disabled ghost FAB' ghost disabled />
          <FAButton icon={<Add />} loading text='Loading FAB with icon' />
        </Box>
        <Box sx={{ mb: 3 }}>
          <ButtonsList
            headerTitle='Título de la lista'
            subHeaderTitle='Sticky Subheader title'
            list={buttonsListItems}
          />
          <ButtonsList
            divider
            disableGutters
            headerTitle='Título de la lista'
            list={buttonsListItems}
          />
        </Box>
      </Container>
    </ScrollableContainer>
  )
}
