import { Box, Typography } from '@mui/material'
import welcome from 'assets/imgs/welcome.svg'
import { useI18N } from 'hooks'

export default function WelcomeStep() {
  const { t } = useI18N()

  return (
    <>
      <Box sx={{ mx: 'auto', display: 'block', maxWidth: 220, mb: 4 }}>
        <Box component='img' src={welcome} sx={{ width: '100%' }} />
      </Box>
      <Typography
        fontSize='1.4rem'
        fontWeight={600}
        sx={{
          textAlign: 'center',
          mb: 2,
          '& span': {
            fontSize: '1.4rem',
            fontWeight: 600,
            color: 'primary.main'
          }
        }}
      >
        {t('asEAsyAsFindTheProfessional')}
      </Typography>
      <Typography
        fontSize={18}
        color='textSecondary'
        sx={{ textAlign: 'center', mb: 1 }}
      >
        {t('allReady')} {t('letsCreateYourAccount')}
      </Typography>
      <Typography
        textAlign='center'
        fontSize={14}
        color='textSecondary'
        sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}
      >
        {t('termsAndPrivacy')}
      </Typography>
    </>
  )
}
