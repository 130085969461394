import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

const CustomLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none'
}))

export default function Link({
  href,
  search,
  hash,
  state,
  children,
  ...other
}) {
  return (
    <CustomLink
      to={{
        pathname: href,
        search,
        hash,
        state
      }}
      {...other}
    >
      {children}
    </CustomLink>
  )
}
