import * as React from 'react'
import { IMaskInput } from 'react-imask'
import { Input } from 'components'

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, overwrite = true, ...other } = props
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite={overwrite}
    />
  )
})

function MaskedInput({ maskProps, mask, ...other }) {
  return (
    <Input
      InputProps={{
        inputComponent: TextMaskCustom,
        inputProps: { mask, ...maskProps }
      }}
      {...other}
    />
  )
}

export default React.memo(MaskedInput)
