import React, { cloneElement, useEffect, useRef, useState } from 'react'
import { BottomNavigation, BottomNavigationAction, Badge } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Clipboard, Discover, Home, MessageText, Profile } from 'iconsax-react'
import { useHistory, useLocation } from 'react-router'
import { useSubscription } from '@apollo/client'
import { NOTIFICATIONS_SUB } from 'apis/subscriptions'
import { ConditionalWrapper } from 'components'
import texts from 'constants/texts'
import { ROLE } from 'constants/Roles'
import { useUser } from 'context/UserContext'

export const NAV_ITEMS = {
  home: {
    label: texts().home,
    icon: <Home />,
    path: '/',
    badge: false
  },
  discover: {
    label: texts().discover,
    icon: <Discover />,
    path: '/discover',
    badge: false
  },
  inbox: {
    label: texts().messages,
    icon: <MessageText />,
    path: '/inbox',
    badge: true
  },
  board: (role) => ({
    label: role === ROLE.COMPANY ? texts().offers : texts().posts,
    icon: <Clipboard />,
    path: '/board',
    badge: false
  }),
  account: {
    label: texts().account,
    icon: <Profile />,
    path: '/account',
    badge: false
  }
}

export const loggedOutUserItems = [
  NAV_ITEMS.home,
  NAV_ITEMS.discover,
  NAV_ITEMS.account
]
export const loggedUserItems = (role) => [
  NAV_ITEMS.discover,
  NAV_ITEMS.inbox,
  NAV_ITEMS.board(role),
  NAV_ITEMS.account
]

export default function BottomBar() {
  const { user } = useUser()
  const history = useHistory()
  const location = useLocation()

  const navItems =
    user && user.id ? loggedUserItems(user.role) : loggedOutUserItems

  const [value, setValue] = useState(navItems[0].path)
  const [width, setWidth] = useState(0)
  const navigatonRef = useRef(null)

  const { data } = useSubscription(NOTIFICATIONS_SUB, {
    fetchPolicy: 'network-only',
    variables: {
      userId: user.id
    }
  })

  useEffect(() => {
    if (navigatonRef) {
      setWidth(navigatonRef.current.offsetWidth)
    }
  }, [navigatonRef])

  useEffect(() => {
    if (location) {
      const { pathname } = location
      setValue(pathname)
    }
  }, [location])

  const handleChange = (event, newValue) => {
    history.replace(newValue, { from: location.pathname })
  }

  const itemWidth = width / (navItems.length * 2)
  return (
    <BottomNavigation
      ref={navigatonRef}
      showLabels
      value={value}
      onChange={handleChange}
      sx={{
        position: { xs: 'fixed', sm: 'absolute' },
        zIndex: { xs: 100, sm: 50 },
        bottom: 0,
        left: 0,
        right: 0,
        height: '80px',
        bgcolor: 'background.contrast',
        '&::before': {
          content: "''",
          position: 'absolute',
          height: 32,
          width: 64,
          borderRadius: 4,
          top: 12,
          left: `calc(${itemWidth - 64 / 2}px)`,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
          transition: 'transform 200ms ease',
          transform: `translateX(${
            (navItems.findIndex((i) => i.path === value) * width) /
            navItems.length
          }px)`
        }
      }}
    >
      {navItems.map(({ icon, label, badge, path }) => (
        <BottomNavigationAction
          key={label}
          disableRipple
          label={label}
          value={path}
          icon={
            <ConditionalWrapper
              condition={badge}
              wrapper={(children) => (
                <Badge
                  overlap='circular'
                  color='error'
                  variant='dot'
                  invisible={value === path || !data?.notifications > 0}
                  sx={{
                    '.MuiBadge-dot': {
                      border: (theme) =>
                        `2px solid ${theme.palette.background.contrast}`,
                      padding: '4px 4px',
                      borderRadius: '50%'
                    }
                  }}
                >
                  {children}
                </Badge>
              )}
            >
              {cloneElement(icon, {
                size: '24',
                variant: value === path ? 'Bold' : 'Linear'
              })}
            </ConditionalWrapper>
          }
          sx={{
            color: 'text.primary',
            justifyContent: 'flex-start',
            pt: 2,
            '&::before': {
              content: "''",
              position: 'absolute',
              height: 32,
              width: 64,
              borderRadius: 4,
              top: 12,
              left: `calc(${itemWidth - 64 / 2}px)`,
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
              transition: 'opacity 300ms ease',
              opacity: 0
            },
            '&:hover, :focus': {
              '&::before': {
                opacity: 1
              }
            },
            '&.Mui-selected': {
              color: 'text.primary',
              '& .MuiBottomNavigationAction-label': {
                fontSize: 12,
                pt: 1
              }
            },
            '& .MuiBottomNavigationAction-label': {
              fontSize: 12,
              pt: 1
            }
          }}
        />
      ))}
    </BottomNavigation>
  )
}
