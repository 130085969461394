import React from 'react'
import { CardContent, Typography, Avatar, Box, Skeleton } from '@mui/material'
import { Rating, CustomCard } from 'components'
import { getUserPicture } from 'helpers'
import { COMPANY_AVERAGES } from 'apis/querys'
import { useQuery } from '@apollo/client'

export default function CompanyResumeCard({
  loading,
  name,
  legalForm,
  postal,
  city,
  province,
  img,
  compact,
  companyId,
  ...other
}) {
  const { loading: loadingAverages, data } = useQuery(COMPANY_AVERAGES, {
    variables: {
      userId: companyId
    },
    skip: loading
  })

  if (loading) return <LoadingCard compact={compact} />

  return (
    <CustomCard {...other}>
      <CardContent
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          '&:last-child': { pb: 2 }
        }}
      >
        {!compact && (
          <Avatar
            variant='square'
            alt={name}
            src={img ? getUserPicture(img).profileImage : ''}
            sx={{
              width: 80,
              height: 80,
              boxShadow: (theme) =>
                `0px 0px 0px 1px ${theme.palette.action.disabledBackground}`,
              mr: 1,
              bgcolor: 'action.disabledBackground',
              borderRadius: 3
            }}
          />
        )}

        <Box aria-label='meta-data-container' sx={{ flex: 1, minWidth: 0 }}>
          <Typography noWrap fontWeight={500} sx={{ maxWidth: '100%' }}>
            {name}
          </Typography>
          <Typography color='textSecondary' fontSize={14} noWrap>
            {legalForm}
          </Typography>
          <Typography noWrap color='textSecondary' fontSize={13}>
            {`${postal} ${city}, ${province}`}
          </Typography>
          {loadingAverages ? (
            <Skeleton width='40%' />
          ) : (
            <Rating
              current={data.companyRatingsAverage.average}
              readOnly
              size={18}
            />
          )}
        </Box>
      </CardContent>
    </CustomCard>
  )
}

const LoadingCard = ({ compact }) => (
  <CustomCard>
    <CardContent
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        '&:last-child': { pb: 2 }
      }}
    >
      {!compact && (
        <Skeleton
          variant='rectangular'
          width={80}
          height={80}
          sx={{
            mr: 1,
            borderRadius: 3
          }}
        />
      )}

      <Box aria-label='meta-data-container' sx={{ flex: 1, minWidth: 0 }}>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography fontSize={14}>
          <Skeleton width='60%' />
        </Typography>
        <Typography fontSize={13}>
          <Skeleton width='60%' />
        </Typography>
        <Skeleton width='40%' />
      </Box>
    </CardContent>
  </CustomCard>
)
