import { useLocalStorage } from 'react-use'
import { LOCAL_KEYS } from 'constants/LocalKeys'

const defaultSettings = {
  theme: 'light',
  discover: 'companies',
  board: 0
}

export default function useSettings() {
  const [settings, setSettings] = useLocalStorage(
    LOCAL_KEYS.SETTINGS,
    defaultSettings
  )

  const updateSettings = (newValues) => {
    // console.log({ settings, newValues })
    setSettings({ ...settings, ...newValues })
  }

  return { settings, updateSettings }
}
