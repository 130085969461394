import { useEffect, useState } from 'react'
import { Box, CardContent, Grid, Typography } from '@mui/material'
import { CustomCard, Spacer, SearchBar, CustomChip, Button } from 'components'
import { blueGrey } from '@mui/material/colors'
import { useI18N, useThemeMode } from 'hooks'
import {
  categories as categoriesData,
  subcategoriesParents
} from 'constants/CategoriesData'
import texts from 'constants/texts'

const categoriesLabelText = texts().categories

export default function CategoriesSelector({
  value: categories = [],
  multiple,
  subcategories = true,
  categoriesLabel = categoriesLabelText,
  error,
  onChange
}) {
  const { t } = useI18N()
  const [search, setSearch] = useState('')
  const [categoryFilter, setCategoryFilter] = useState(
    subcategoriesParents(categories)
  )
  const { darkMode } = useThemeMode()

  useEffect(() => {
    if (categories.length) {
      setCategoryFilter(subcategoriesParents(categories))
    }
  }, [categories])

  const subCategoriesList = categoriesData
    .filter((category) => categoryFilter.includes(category.id))
    .reduce((prev, current) => {
      const total = prev.concat(current.subCategories)
      return total
    }, [])

  const handleFilter = (value) => {
    if (multiple) {
      const newValue = categoryFilter.slice(0)
      const index = categoryFilter.indexOf(value)
      if (index > -1) {
        newValue.splice(index, 1)

        const categorySubs = categoriesData
          .find((category) => category.id === value)
          .subCategories.map((item) => item.id)

        const subs = categories.filter(
          (subcategory) => !categorySubs.includes(subcategory)
        )

        onChange({ categories: subs })
      } else {
        newValue.push(value)
      }
      setCategoryFilter(newValue)
    } else {
      setCategoryFilter([value])
      onChange({ categories: [] })
    }
  }

  const handleChange = (value) => {
    const newValue = categories.slice(0)
    const index = categories.indexOf(value)
    if (index > -1) {
      newValue.splice(index, 1)
    } else {
      newValue.push(value)
    }
    onChange({ categories: newValue })
  }

  const allAreSelected = subCategoriesList
    .map((i) => i.id)
    .every((item) => categories.includes(item))

  const handleUnselectAll = () => {
    if (allAreSelected) {
      onChange({ categories: [] })
    } else {
      onChange({ categories: subCategoriesList.map((i) => i.id) })
    }
  }

  const filteredList = search
    ? subCategoriesList.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
    : subCategoriesList

  return (
    <>
      <Spacer y={1}>
        <Typography color='textSecondary' mb={error ? 0 : 2}>
          {categoriesLabel}
        </Typography>
        {error && (
          <Typography color='error' fontSize={12} mb={2}>
            {error}
          </Typography>
        )}
        <Grid container spacing={2}>
          {categoriesData?.map((category) => {
            const isSelected = categoryFilter.includes(category.id)

            return (
              <Grid key={category.id} item xs={6}>
                <CustomCard
                  sx={{
                    bgcolor: isSelected
                      ? `#${category.color}`
                      : 'background.default'
                  }}
                  onClick={() => handleFilter(category.id)}
                >
                  <CardContent
                    sx={{ minHeight: 80, '&:last-child': { pb: 2 } }}
                  >
                    <Typography
                      sx={{
                        position: 'relative',
                        zIndex: 1,
                        fontWeight: 600,
                        color: isSelected
                          ? 'whitesmoke'
                          : darkMode
                          ? 'text.secondary'
                          : blueGrey[400]
                      }}
                      fontSize={16}
                    >
                      {category.name}
                    </Typography>
                    <Box
                      element='span'
                      sx={{
                        transition: 'all 0.2s ease-out',
                        position: 'absolute',
                        height: '100%',
                        width: '50%',
                        bottom: 0,
                        right: 0,
                        backgroundImage: `url(${category.image})`,
                        backgroundSize: 'cover',
                        zIndex: 0,
                        filter: isSelected || darkMode ? 'none' : 'invert(1)',
                        opacity: isSelected ? 0.7 : 0.3
                      }}
                    />
                  </CardContent>
                </CustomCard>
              </Grid>
            )
          })}
        </Grid>
      </Spacer>

      {/* Subcategories */}
      {subcategories && (
        <Spacer y={1}>
          <Spacer
            y={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography color='textSecondary'>{t('subcategories')}</Typography>
            <Button
              onClick={handleUnselectAll}
              text={allAreSelected ? t('unSelectAll') : t('selectAll')}
              variant='text'
            />
          </Spacer>
          <SearchBar
            onChange={setSearch}
            placeholder={t('search')}
            onClean={() => setSearch('')}
          />
          <Box
            sx={{
              display: 'flex',
              alignContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
              maxWidth: '100vw',
              minHeight: 300,
              mt: 2
            }}
          >
            {filteredList?.map((subcategory) => (
              <CustomChip
                key={subcategory.id}
                label={subcategory.name}
                onClick={() => handleChange(subcategory.id)}
                selected={categories.includes(subcategory.id)}
                // sx={{ maxWidth: 300 }}
              />
            ))}
          </Box>
        </Spacer>
      )}
    </>
  )
}
