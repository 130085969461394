import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { StyledButton } from './StyledButton'

Button.propTypes = {
  round: PropTypes.bool,
  shadow: PropTypes.bool,
  borderColor: PropTypes.string,
  loading: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.oneOf(['default', 'light', 'ghost', 'text', 'loadMore'])
}

export default function Button({
  round,
  shadow,
  borderColor,
  loading,
  text,
  size,
  ...other
}) {
  return (
    <StyledButton
      round={round}
      shadow={shadow && !loading}
      borderColor={borderColor}
      size={size || 'large'}
      {...(loading && {
        disabled: true,
        startIcon: (
          <CircularProgress size={20} sx={{ color: 'action.disabled' }} />
        )
      })}
      {...other}
    >
      {text}
    </StyledButton>
  )
}
