import React, { useState, useEffect } from 'react'
import DOMPurify from 'dompurify'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  CardContent
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import {
  CompanyResumeCard,
  FAButton,
  ModalImage,
  SnackBar,
  CustomCard,
  ScrollableContainer,
  Spacer
} from 'components'
import { getAverages, getDate, formatText } from 'helpers'
import {
  GET_COMPANY_OFFERS,
  GET_POST_BY_ID,
  GET_PARTICULAR,
  RECEIVED_OFFERS,
  BASIC_COMPANY_INFO
} from 'apis/querys'
import { CANCEL_OFFER, MARK_PROPOSAL_AS_DELIVERED } from 'apis/mutations'
import { questions } from 'constants/Questions'
import { routes } from 'constants/Routes'
import {
  Calendar,
  CloseCircle,
  Location,
  Profile,
  ReceiptEdit,
  TickCircle,
  WalletMoney
} from 'iconsax-react'
import { useI18N, useSearchParams } from 'hooks'
import { useUser } from 'context/UserContext'

const getQuestion = (qId) => {
  const result = questions.find((question) => question.id === qId)
  return result.text
}

export default function Job() {
  const { t } = useI18N()
  const { id: jobId } = useSearchParams('id')
  const { user } = useUser()
  const location = useLocation()
  const [jobData, setJobData] = useState()
  const { chatId, fromProposal } = location?.state || ''
  const [markAsDelivered] = useMutation(MARK_PROPOSAL_AS_DELIVERED)

  const { loading, data, error } = useQuery(GET_POST_BY_ID, {
    variables: { id: jobId },
    skip: !jobId
  })

  useEffect(() => {
    if (fromProposal) {
      markAsDelivered({
        variables: {
          jobId,
          chatId
        }
      })
    }
  }, [])

  useEffect(() => {
    if (!loading && data && data.getJob) {
      setJobData(data.getJob)
    }
    if (error) {
      console.log(error)
    }
  }, [loading, data, error])

  return (
    <ScrollableContainer
      title={!jobData ? <Skeleton /> : jobData.title}
      appBarProps={{
        hasBack: true,
        sx: {
          '& #title': { transition: 'all 0.2s ease-out' },
          '&.ontop #title': { color: 'transparent' }
        }
      }}
    >
      <Spacer id='section-0' m={2}>
        <Typography
          fontSize='1.4rem'
          fontWeight={500}
          lineHeight={1.3}
          marginBottom={1.5}
        >
          {!jobData ? <Skeleton /> : jobData.title}
        </Typography>
        <Box
          aria-label='location-container'
          sx={{ display: 'flex', mb: 1, color: 'text.primary' }}
        >
          <Location variant='Outline' size={22} />
          <Typography
            marginLeft={2}
            color='textSecondary'
            sx={{ minWidth: 120 }}
          >
            {!jobData ? (
              <Skeleton />
            ) : (
              jobData.postal_code + ', ' + jobData.city
            )}
          </Typography>
        </Box>
        <Box
          aria-label='owner-container'
          sx={{ display: 'flex', color: 'text.primary' }}
        >
          <Profile variant='Outline' size={22} />
          {!jobData ? (
            <Typography marginLeft={2} sx={{ minWidth: 120 }}>
              <Skeleton />
            </Typography>
          ) : (
            <OwnerName userId={jobData.user_id} />
          )}
        </Box>
      </Spacer>

      <Spacer id='section-1' m={2}>
        {!jobData ? (
          [1, 2, 3, 4, 5, 6, 7, 8].map((i) => <Skeleton key={i} />)
        ) : (
          <>
            <Box
              sx={{ '& p': { mt: 0, mb: 1 } }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(formatText(jobData.description))
              }}
            />
            {jobData.questions
              .filter((item) => !item.main && item.answer !== '')
              .map((q, idx) => (
                <Box key={`q-${idx}`}>
                  <Typography marginBottom={1}>
                    {getQuestion(q.id) + ': ' + q.answer + '.'}{' '}
                  </Typography>
                </Box>
              ))}
          </>
        )}
      </Spacer>

      <Spacer id='section-2'>
        <Box
          className='main-container'
          aria-label='images-carrousel'
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'auto',
            gap: 2,
            px: 2,
            py: 2
          }}
        >
          {!jobData
            ? [1, 2, 3, 4].map((i) => (
                <Skeleton
                  key={`pic-${i}`}
                  variant='rectangular'
                  height='14rem'
                  sx={{
                    minWidth: (theme) => ({
                      xs: theme.breakpoints.values.sm / 2.2,
                      sm: theme.breakpoints.values.md / 3,
                      md: theme.breakpoints.values.lg / 3.8
                    }),
                    borderRadius: 3
                  }}
                />
              ))
            : jobData.pictures.map((picture) => (
                <ModalImage
                  key={picture.name}
                  src={picture.url}
                  alt={picture.name}
                />
              ))}
        </Box>
      </Spacer>

      <Spacer id='section-3' m={2}>
        <Typography variant='h6'>{t('mainDetails')}</Typography>
        <Box
          aria-label='main-details-container'
          sx={{ display: 'flex', alignItems: 'stretch', gap: 2, mb: 2 }}
        >
          <DetailsCard
            color='#3A90FF'
            icon={<Calendar />}
            title={t('preferredStartDate')}
            value={!jobData ? <Skeleton /> : getDate(jobData.start_date)}
          />
          <DetailsCard
            color='#FE9450'
            icon={<WalletMoney />}
            title={t('budget')}
            value={!jobData ? <Skeleton /> : `${jobData.budget}€`}
          />
        </Box>
        {jobData && jobData.questions && (
          <Grid aria-label='other-details-container' container spacing={2}>
            {jobData.questions.map(
              (item, index) =>
                item.main && (
                  <Grid key={`main-question-${index}`} item xs={12}>
                    <MainDetail
                      mainQuestionAnswer={item.answer}
                      mainQuestionId={item.id}
                    />
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Spacer>

      <Spacer id='section-4' m={2} mb={4}>
        <Typography variant='h6'>{t('receivedOffers')}</Typography>
        <ReceivedOffers jobId={jobId} />
      </Spacer>
      {jobData && (
        <FloatingActionButton
          user={user}
          job={{ jobId, jobUserId: jobData.user_id, jobTitle: jobData.title }}
        />
      )}
    </ScrollableContainer>
  )
}

const OwnerName = ({ userId }) => {
  const { loading, data } = useQuery(GET_PARTICULAR, {
    errorPolicy: 'all',
    variables: { userId }
  })

  return (
    <Typography color='textSecondary' minWidth={'50%'} marginLeft={2}>
      {loading || !data ? <Skeleton /> : data.getUser.public_name}
    </Typography>
  )
}

const DetailsCard = ({ icon, title, value, color }) => (
  <Box
    aria-label='detail-container'
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      p: 1,
      bgcolor: 'background.paper',
      borderRadius: 3
    }}
  >
    <Box
      sx={{
        borderRadius: 2,
        p: 1,
        bgcolor: color ? alpha(color, 0.2) : 'transparent',
        display: 'flex',
        color: color || '#070707'
      }}
    >
      {icon}
    </Box>
    <Box sx={{ pl: 1, flex: 1 }}>
      <Typography
        color='textSecondary'
        fontSize='small'
        lineHeight={1}
        marginBottom={0.5}
      >
        {title}
      </Typography>
      <Typography fontSize='0.9rem' fontWeight={500}>
        {value}
      </Typography>
    </Box>
  </Box>
)

const MainDetail = ({ mainQuestionAnswer, mainQuestionId }) => {
  const positiveAnswer = mainQuestionAnswer === 'true'

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        px: 1,
        py: 2,
        bgcolor: (theme) =>
          positiveAnswer
            ? alpha(theme.palette.success.light, 0.2)
            : alpha(theme.palette.error.light, 0.2),
        borderRadius: 3
      }}
    >
      <Box
        component='span'
        sx={{
          color: positiveAnswer ? 'success.main' : 'error.main',
          display: 'flex'
        }}
      >
        {positiveAnswer ? (
          <TickCircle variant='Bulk' />
        ) : (
          <CloseCircle variant='Bulk' />
        )}
      </Box>
      <Typography ml={2} lineHeight={1.2} color='textPrimary'>
        {getQuestion(mainQuestionId)}
      </Typography>
    </Box>
  )
}

const ReceivedOffers = ({ jobId }) => {
  const { t } = useI18N()
  const { loading, data } = useQuery(RECEIVED_OFFERS, {
    variables: {
      job_id: jobId,
      skip: ['proposal', 'cancelled'],
      limit: 4,
      sortBy: {
        field: 'creation_date',
        order: 'DESC'
      }
    }
  })

  if (loading)
    return (
      <Grid container spacing={2}>
        {[1, 2, 3, 4].map((it) => (
          <Grid key={`load-offer-${it}`} item xs={6}>
            <Skeleton
              sx={{ height: 80, width: '100%', borderRadius: 3 }}
              variant='rectangular'
            />
          </Grid>
        ))}
      </Grid>
    )

  if (data.getOffers && data.getOffers.length > 0)
    return (
      <Grid container spacing={2}>
        {data.getOffers.map((offer) => (
          <Grid key={offer._id} item xs={6}>
            <CompanyCard companyId={offer.company_id} />
          </Grid>
        ))}
      </Grid>
    )

  return <Typography>{t('noOfferYet')}</Typography>
}

const CompanyCard = ({ companyId }) => {
  const { t } = useI18N()
  const [company, setCompany] = useState()

  const { loading, data } = useQuery(BASIC_COMPANY_INFO, {
    errorPolicy: 'all',
    variables: {
      userId: companyId
    }
  })

  useEffect(() => {
    if (!loading && data && data.getUser) {
      setCompany(data.getUser)
    }
  }, [loading, data])

  if (!company)
    return (
      <CustomCard>
        <CardContent sx={{ '&:last-child': { pb: 2 } }}>
          <Skeleton />
          <Skeleton />
          <Skeleton sx={{ mb: 0 }} />
        </CardContent>
      </CustomCard>
    )

  return (
    <CompanyResumeCard
      href={`${routes.company}?id=${companyId}`}
      name={company.name}
      compact
      legalForm={t(company.company_data.legal_form)}
      city={company.company_data.city}
      postal={company.company_data.postal_code}
      province={company.company_data.province}
      average={
        company.company_data?.ratings
          ? getAverages(company.company_data?.ratings).average
          : 0
      }
      companyId={companyId}
    />
  )
}

const FloatingActionButton = ({ user, job }) => {
  const { t } = useI18N()
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [sentOfferId, setSentOfferId] = useState()
  const history = useHistory()
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'error',
    action: false,
    permanent: false,
    duration: undefined
  })

  const { jobId, jobUserId, jobTitle } = job
  const isOwner = user.id === jobUserId

  const [loadCompanyOffers] = useLazyQuery(GET_COMPANY_OFFERS, {
    fetchPolicy: 'network-only',
    variables: { userId: user.id, jobId, companyId: user.id, uid: user.uid }
  })
  const [cancelOffer] = useMutation(CANCEL_OFFER)

  function showAlert(message, severity, action, permanent, duration) {
    setAlert((prev) => ({
      ...prev,
      open: true,
      message: message || '',
      severity: severity || 'error',
      action: action || false,
      permanent: permanent || false,
      duration
    }))
  }

  function handleCloseAlert(e, reason) {
    if (reason === 'clickaway') {
      return
    }
    setAlert((prev) => ({ ...prev, open: false }))
  }

  function goToNewOffer() {
    history.push(`${routes.post}/${jobId}/new-offer`, {
      jobTitle,
      jobUserId
    })
  }

  async function handleButtonClick() {
    if (isOwner) {
      // If current user is the one who create the job, go to edit
      history.push(`${routes.post}/${jobId}`)
    } else if (user.role === 'company') {
      // If the user is a company
      setLoading((prev) => true)
      const { data } = await loadCompanyOffers().then((resp) => {
        setLoading((prev) => false)
        return resp
      })
      const plan = data.getUser.plan
      const offers = data.getOffers

      // If The company need a plan
      if (!plan.trial && !plan.plan_id) {
        showAlert(t('youNeedAPlan'), 'error')
        return
      }

      // If the company has a plan
      // Check if already has sent an offer for this job
      if (offers.length === 0) {
        // If any offer has been sent
        goToNewOffer()
        return
      }
      // If already has been sent an offer
      setSentOfferId(offers[0]._id)
      const offerState = offers[0].state
      switch (offerState) {
        case 'negotiating':
          showAlert(t('offerAlreadySent'), 'error', true, true)
          break
        case 'accepted':
          showAlert(t('yourOfferHasAlreadyBeenAccepted'), 'error')
          break
        case 'rejected':
          showAlert(t('yourOfferHasAlreadyBeenRejected'), 'error')
          break
        case 'proposal':
        case 'cancelled':
          goToNewOffer()
          break
        default:
          break
      }
    } else {
      showAlert(t('offerAlert'), 'error')
    }
  }

  function handleCancelCurrentOffer() {
    setDeleting((prev) => true)
    // Cancel the current company offer
    cancelOffer({
      variables: {
        offerId: sentOfferId,
        jobId,
        offer: sentOfferId,
        userId: jobUserId
      }
    }).then(() => {
      setDeleting((prev) => false)
      showAlert(t('offercancelledSuccesfully'), 'success')
    })
  }

  const SnackBarAction = () => (
    <Box display='flex' flexDirection='column'>
      <Typography display='block'>{alert.message}</Typography>
      <Typography display='block'>{t('doYouWantToCancelNow')}</Typography>
      <Box display='flex' flexDirection='row' gap={1} mt={2}>
        <Button
          sx={snackBarButtonStyle}
          onClick={handleCancelCurrentOffer}
          disabled={loading || deleting}
        >
          {deleting ? (
            <CircularProgress
              size={20}
              sx={{
                color: 'action.disabled'
              }}
            />
          ) : (
            t('yes')
          )}
        </Button>
        <Button
          sx={snackBarButtonStyle}
          onClick={handleCloseAlert}
          disabled={loading || deleting}
        >
          {t('no')}
        </Button>
      </Box>
    </Box>
  )

  return (
    <Box sx={{ height: 48 }}>
      <Box
        sx={{
          transition: 'all 0.2s ease-in',
          position: 'fixed',
          bottom: 16,
          left: 0,
          right: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <FAButton
          loading={loading}
          disabled={deleting || alert.open}
          version='default'
          text={isOwner ? t('editPost') : t('createAnOffer')}
          icon={<ReceiptEdit />}
          onClick={handleButtonClick}
          sx={{
            width: '0%'
          }}
        />
      </Box>
      <SnackBar
        open={alert.open}
        onClose={!loading ? handleCloseAlert : null}
        message={alert.action ? <SnackBarAction /> : alert.message}
        severity={alert.severity}
        noHide={alert.permanent}
        duration={alert.duration}
        sx={{ transition: 'margin 0.2s ease-out', mb: 8 }}
      />
    </Box>
  )
}

const snackBarButtonStyle = {
  bgcolor: 'background.default',
  color: 'text.primary',
  borderRadius: '6px',
  '&:hover': (theme) => ({
    backgroundColor: alpha(theme.palette.background.default, 0.9)
  })
}
