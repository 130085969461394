import { useState } from 'react'
import { Typography } from '@mui/material'
import { BottomDrawer, Button, InlineCheck } from 'components'
import { useFilters, useI18N } from 'hooks'

export default function SortDialog({ onClose, onSave, sortOptions = [] }) {
  const { t } = useI18N()
  const { filter, updateFilters } = useFilters()
  const [values, setValues] = useState(filter)

  const handleChange = ({ field, order }) => {
    setValues((prev) => ({ ...prev, sortBy: { field, order } }))
  }

  const handleSaveSort = () => {
    updateFilters(values)
    onSave(values)
  }

  return (
    <BottomDrawer
      title={
        <Typography align='left' fontSize='1.2rem'>
          {t('orderBy')}
        </Typography>
      }
      closeButton
      onClose={onClose}
      actions={
        <Button onClick={handleSaveSort} text={t('save')} sx={{ ml: 'auto' }} />
      }
    >
      {sortOptions.map(({ id, label, field, order }) => (
        <InlineCheck
          onClick={() => handleChange({ field, order })}
          key={id}
          text={label}
          selected={
            values.sortBy.field === field && values.sortBy.order === order
          }
        />
      ))}
    </BottomDrawer>
  )
}
