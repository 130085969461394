import { Grid } from '@mui/material'
import { Button, Dialog, Modal, PasswordInput } from 'components'
import { useI18N } from 'hooks'

export default function ChangePasswordDialog({
  open,
  onChange,
  processing,
  oldPassError,
  newPassError,
  onClose,
  onSave
}) {
  const { t } = useI18N()
  return (
    <Modal open={open} onClose={onClose}>
      <Dialog>
        <Dialog.Title title={t('passwordChange')} />
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PasswordInput
                id='old_password'
                onChange={onChange}
                label={t('currentPassword')}
                disabled={processing}
                error={oldPassError}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                id='new_password'
                onChange={onChange}
                label={t('newPassword')}
                disabled={processing}
                error={newPassError}
              />
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            fullWidth
            text={t('cancel')}
            onClick={onClose}
            variant='light'
          />
          <Button
            fullWidth
            text={processing ? t('changing') : t('change')}
            onClick={onSave}
          />
        </Dialog.Actions>
      </Dialog>
    </Modal>
  )
}
