import React from 'react'
import { Box, Typography } from '@mui/material'
import { Rating } from 'components'
import { Star1 } from 'iconsax-react'
import { useI18N } from 'hooks'

export default function CustomerReviews({ averages }) {
  const { t } = useI18N()
  const { communication, onTime, asDescribed, recommendation, average, count } =
    averages

  const RatingRow = ({ text, value }) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1
      }}
    >
      <Typography color='textSecondary'>{text}</Typography>
      <Box sx={{ display: 'flex', color: '#F7C056', alignItems: 'flex-end' }}>
        <Typography lineHeight={0.8} marginRight={0.5}>
          {value}
        </Typography>
        <Star1 size={16} variant='Bulk' />
      </Box>
    </Box>
  )

  return (
    <>
      <Typography fontSize={44} fontWeight={500} lineHeight={1.2}>
        {average}
      </Typography>
      <Rating amount={count} current={average} readOnly />
      <Box sx={{ mt: 1 }}>
        <RatingRow text={t('comunication')} value={communication} />
        <RatingRow text={t('times')} value={onTime} />
        <RatingRow text={t('asDescribed')} value={asDescribed} />
        <RatingRow text={t('recommend')} value={recommendation} />
      </Box>
    </>
  )
}
