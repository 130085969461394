import { Box } from '@mui/material'
import { Switcher, NewsDialog, UpdateAvailable } from 'components'
import { SnackBarProvider } from 'context/SnackBarContext'

export default function MainContainer() {
  return (
    <Box
      as='main'
      id='main-container'
      sx={{
        bgcolor: 'background.default',
        borderRadius: { sm: 9 },
        height: '100%',
        maxHeight: { sm: 844 },
        width: { xs: '100%', sm: 400 },
        boxShadow: {
          sm: '-20px 40px 40px rgba(27,43,72,0.4)'
        },
        border: { sm: '10px solid #1b2b48' },
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <SnackBarProvider>
        <Switcher />
        <UpdateAvailable />
      </SnackBarProvider>
      <NewsDialog />
    </Box>
  )
}
