import React, { useState, useRef, useEffect } from 'react'
import { Backdrop, Box, ButtonBase } from '@mui/material'
import { useGesture } from '@use-gesture/react'

const GestureImage = ({ src, alt, open }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0, scale: 1 })
  const imageRef = useRef(null)

  useGesture(
    {
      onDrag: ({ offset: [dx, dy] }) => {
        setCrop((crop) => ({ ...crop, x: dx, y: dy }))
      },
      onPinch: ({ offset: [d] }) => {
        // setCrop((crop) => ({ ...crop, scale: 1 + d / 50 }));
        setCrop((crop) => ({ ...crop, scale: d }))
      }
    },
    {
      target: imageRef,
      eventOptions: { passive: false }
    }
  )

  useEffect(() => {
    if (open) {
      setCrop({ x: 0, y: 0, scale: 1 })
    }
  }, [open])

  return (
    <Box
      component='img'
      ref={imageRef}
      src={src}
      alt={alt}
      sx={{
        width: '100%',
        touchAction: 'none',
        transform: `scale(${crop.scale}) translateX(${crop.x}px) translateY(${crop.y}px)`
      }}
    />
  )
}

export default function ModalImage(props) {
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      <ButtonBase onClick={toggleModal}>
        <Box
          component='img'
          src={props.src}
          alt={props.alt}
          sx={{
            height: '14rem',
            borderRadius: 3
          }}
        />
      </ButtonBase>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={modalOpen}
        onClick={toggleModal}
      >
        <GestureImage open={modalOpen} {...props} />
      </Backdrop>
    </>
  )
}
