import React, { cloneElement, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Slide,
  IconButton,
  Box,
  Stack
} from '@mui/material'
import {
  BestCompanyCard,
  Button,
  JobsaunLogo,
  SearchBar,
  Spacer
} from 'components'
import { useTheme } from '@mui/styles'
import { useI18N, useScrollTrigger, useUserInfo } from 'hooks'
import { SearchNormal1 } from 'iconsax-react'
import {
  categories as categoriesData,
  subcategoriesParents
} from 'constants/CategoriesData'
import { routes } from 'constants/Routes'
import { useQuery } from '@apollo/client'
import {
  GET_COMPANIES_LOGOS,
  GET_FILTERED_COMPANIES_COUNT,
  MOST_VALORATED_COMPANIES
} from 'apis/querys'

function ElevationScroll({ target, children }) {
  if (!target) return null

  const { trigger, onTop } = useScrollTrigger({
    target,
    threshold: 100
  })
  const childrenArray = React.Children.toArray(children)
  const theme = useTheme()

  return (
    <Slide appear={false} direction='down' in={trigger}>
      {cloneElement(children, {
        className: onTop ? 'ontop' : '',
        sx: {
          ...childrenArray[0].props.sx,
          bgcolor: !onTop
            ? theme.palette.background.contrast
            : theme.palette.background.default
        }
      })}
    </Slide>
  )
}

export default function Home() {
  const { t } = useI18N()
  const history = useHistory()
  const ref = useRef(null)
  const { user } = useUserInfo()
  const { data: mostValuatedCompanies } = useQuery(MOST_VALORATED_COMPANIES, {
    variables: { country: user?.country ?? '' }
  })
  const { data: companiesCountData } = useQuery(GET_FILTERED_COMPANIES_COUNT)
  const { data: companiesLogosData } = useQuery(GET_COMPANIES_LOGOS)

  const handleSearch = (search) => {
    history.push(routes.newPost, { query: search })
  }

  const handleFocusSearch = () => {
    const element = document.getElementById('search')
    const y = element.getBoundingClientRect().top + ref.current.scrollY
    ref.current.scroll({
      top: y,
      behavior: 'smooth'
    })
    setTimeout(() => {
      element.focus()
    }, 350)
  }

  const handleCompanyClick = (id) => {
    return history.push(`${routes.company}?id=${id}`)
  }

  let mostValuated = mostValuatedCompanies?.mostValueatedCompanies ?? []
  mostValuated = mostValuated.map(
    ({ _id, name, logo, province, ratings_average, categories }) => {
      const categoryParent = subcategoriesParents([categories[0]])[0]
      const categoryInfo = categoriesData.find(
        ({ id }) => id === categoryParent
      )

      return {
        id: _id,
        title: categoryInfo.name,
        color: categoryInfo.color,
        icon: categoryInfo.image,
        name,
        logo,
        province,
        average: ratings_average
      }
    }
  )
  const companiesCount = companiesCountData?.countFilteredCompanies
  const companies = new Array(10).fill({ name: '', picture: { url: '' } })
  const logos = companiesLogosData?.companiesLogos
  if (logos) {
    const length = logos.length
    companies.splice(0, length, ...logos)
  }

  return (
    <>
      <ElevationScroll target={ref?.current}>
        <AppBar
          elevation={0}
          position='absolute'
          color='inherit'
          sx={{
            transition:
              'background-color 300ms ease-in-out, transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important'
          }}
        >
          <Toolbar
            sx={{ '&.MuiToolbar-root': { minHeight: 64, px: 2, gap: 1 } }}
          >
            <JobsaunLogo width={100} />
            <Spacer sx={{ flex: 1 }} />
            <IconButton
              onClick={handleFocusSearch}
              sx={{ color: 'text.primary' }}
            >
              <SearchNormal1 />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box
        ref={ref}
        id='content-container'
        className='scrollable'
        sx={{
          height: '100%',
          overflowX: 'hidden',
          position: 'aboslute'
        }}
      >
        <Spacer x={2} aria-label='CallToAction'>
          <Box sx={{ mx: 'auto', width: 'fit-content', my: 4 }}>
            <JobsaunLogo width={150} />
          </Box>
          <Box sx={{ mx: 'auto', maxWidth: '90%', mb: 4 }}>
            <Typography
              align='center'
              fontSize={28}
              fontWeight={500}
              sx={{
                '& span': {
                  color: 'primary.main',
                  fontSize: 28,
                  fontWeight: 600
                }
              }}
            >
              {t('homeCTA')}
            </Typography>
          </Box>
          <SearchBar
            id='search'
            placeholder={t('whatServiceDoYouNeed')}
            onEnter={handleSearch}
          />
        </Spacer>

        <Spacer mt={10} mb={4} aria-label='Better Valorated'>
          <Spacer x={2}>
            <Typography fontSize={24} lineHeight={1.2}>
              {t('bestRatedCompanies')}
            </Typography>
          </Spacer>
          <Stack
            className='scrollable horizontal-scroll'
            direction='row'
            gap='1rem'
            overflow='auto'
            sx={{ px: 2, py: 2 }}
          >
            {mostValuated.map((item) => (
              <BestCompanyCard
                key={item.id}
                data={item}
                onClick={() => handleCompanyClick(item.id)}
              />
            ))}
          </Stack>
        </Spacer>

        <Spacer
          aria-label='company-join-CTA'
          sx={{
            bgcolor: 'primary.dark',
            pb: 18,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box sx={{ textAlign: 'center', p: 2, pt: 6 }}>
            <Typography
              sx={{
                color: '#fff',
                fontWeight: '600',
                fontSize: '1.75rem',
                mb: 1,
                lineHeight: 1.2
              }}
            >
              {t('doYouHaveACompany')}
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontWeight: '400',
                fontSize: '1rem',
                mb: 3
              }}
            >
              {t('joinJobsaun')}
            </Typography>

            <Button
              onClick={() => history.push(routes.signUp)}
              text={t('joinNowForFree')}
              sx={{
                mb: 3,
                bgcolor: '#1E1E1E',
                color: '#fff',
                '&:hover': {
                  bgcolor: '#030303'
                }
              }}
            />

            {companiesCount > 0 && (
              <Typography
                sx={{
                  color: '#fff',
                  fontWeight: '400',
                  fontSize: '0.875rem',
                  mb: 2
                }}
              >
                {`${t('weAreAlready')} ${companiesCount} ${t('andGoingUp')} 🚀`}
              </Typography>
            )}
          </Box>

          <Box
            sx={{ position: 'absolute', bottom: '-2.5rem', left: 0, right: 0 }}
          >
            <Stack
              direction='row'
              gap='1rem'
              justifyContent='center'
              overflow='hidden'
            >
              {companies.slice(0, 5).map((item, index) => (
                <CompanyImage
                  logo={item.picture?.url}
                  key={`company-row1-${index}`}
                />
              ))}
            </Stack>
            <Stack
              direction='row'
              gap='1rem'
              justifyContent='center'
              overflow='hidden'
              sx={{ mt: 2 }}
            >
              {companies.slice(6, 10).map((item, index) => (
                <CompanyImage
                  logo={item.picture?.url}
                  key={`company-row2-${index}`}
                />
              ))}
            </Stack>
          </Box>
        </Spacer>
      </Box>
    </>
  )
}

const CompanyImage = ({ logo }) => (
  <Box
    sx={{
      bgcolor: 'background.default',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderRadius: '0.75rem',
      border: '2px solid #fff',
      height: '5.3125rem',
      width: '5.3125rem',
      minWidth: '5.3125rem',
      ...(logo && { backgroundImage: `url(${logo})` })
    }}
  />
)
