import React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 8
  }
}))

function MultilineInput({ error, id, rows, value, onChange, ...other }) {
  const handleChange = (event) => {
    const { name, value } = event.target
    onChange({ [name]: value })
  }

  return (
    <StyledInput
      id={id}
      name={id}
      variant='outlined'
      fullWidth
      multiline
      rows={rows || 8}
      value={value || ''}
      onChange={handleChange}
      {...(error && { error: true, helperText: error })}
      {...other}
    />
  )
}

export default React.memo(MultilineInput)
