import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Box, Grid, Typography } from '@mui/material'
import { Button, PasswordInput } from 'components'
import { useFirebase, useForm, useI18N } from 'hooks'
import { slugs } from 'constants/Routes'
import { Like1, Lock } from 'iconsax-react'

const initialValues = {
  email: '',
  password: '',
  repeatPassword: ''
}

export default function ResetPassword() {
  const { t } = useI18N()
  const [processing, setProcessing] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [checkError, setCheckError] = useState(null)
  const { checkCode, confirmPasswordReset } = useFirebase()
  const history = useHistory()
  const authCode = new URLSearchParams(history.location.search).get('oobCode')

  const validate = (fieldValues = values) => {
    const temp = { ...errors }
    if ('password' in fieldValues) {
      temp.password =
        fieldValues.password !== '' && fieldValues.password.length >= 8
          ? ''
          : `${fieldValues.password.length} / 8`
      temp.repeatPassword =
        fieldValues.repeatPassword ||
        fieldValues.password === values.repeatPassword
          ? ''
          : t('repeatPasswordError')
    }
    if ('repeatPassword' in fieldValues)
      temp.repeatPassword =
        fieldValues.repeatPassword === values.password
          ? ''
          : t('repeatPasswordError')

    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }
  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    initialValues,
    false,
    validate
  )

  useEffect(() => {
    handleCheckCode()
    return () => {
      setProcessing(false)
    }
  }, [])

  const handleCheckCode = () => {
    checkCode({
      code: authCode,
      onCompleted: (email) => {
        setValues((prev) => ({ ...prev, email }))
      },
      onError: (error) => {
        // console.log(error);
        const { code } = error

        switch (code) {
          case 'auth/expired-action-code':
            setCheckError(t('resetError.ExpiredActionCode'))
            break
          default:
            setCheckError(t('resetError.InternalError'))
            break
        }
      }
    })
  }

  const handleResetPassword = () => {
    setProcessing(true)
    if (validate()) {
      confirmPasswordReset({
        code: authCode,
        newPassword: values.password,
        onCompleted: () => {
          setCompleted(true)
        },
        onError: (error) => {
          // console.log(error);
          setErrors((prev) => ({
            ...prev,
            password: t(`resetError.${error.code}`)
          }))
          setProcessing(false)
        }
      })
    } else {
      setProcessing(false)
    }
  }

  const handleGoToLogIn = () => {
    history.replace(slugs.signIn.link)
  }

  return (
    <Box
      component='main'
      sx={{
        bgcolor: 'primary.light',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 4,
          boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
          p: 4,
          width: '90%',
          maxWidth: 400,
          textAlign: 'center'
        }}
      >
        <Box component='span' sx={{ color: 'primary.main' }}>
          {completed ? (
            <Like1 size='4rem' variant='Bulk' />
          ) : (
            <Lock size='4rem' variant='Bulk' />
          )}
        </Box>
        <Typography
          fontWeight={500}
          fontSize='1.6rem'
          lineHeight={1}
          marginBottom={2}
        >
          {completed ? t('passwordResetSuccesfully') : t('resetPassword')}
        </Typography>

        {completed ? (
          <SuccesPasswordReset onClick={handleGoToLogIn} />
        ) : !checkError ? (
          <>
            <Typography marginBottom={1} color='textSecondary'>{`${t(
              'yourUser'
            )} ${values.email}`}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PasswordInput
                  id='password'
                  onChange={handleInputChange}
                  label={t('newPassword')}
                  disabled={processing}
                  error={errors.password}
                  value={values.password}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  id='repeatPassword'
                  onChange={handleInputChange}
                  label={t('repeatPass')}
                  disabled={processing}
                  error={errors.repeatPassword}
                  value={values.repeatPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  text={processing ? t('processing') : t('change')}
                  fullWidth
                  onClick={handleResetPassword}
                  disabled={processing}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography style={{ fontWeight: 500 }} color='error'>
            {checkError}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

const SuccesPasswordReset = ({ onClick }) => {
  const { t } = useI18N()
  return (
    <>
      <Box mb={3}>
        <Typography
          display='inline'
          color='textSecondary'
          style={{ fontWeight: 500, fontSize: 16 }}
        >
          {t('nowYouCanUseNewPass')}
        </Typography>
      </Box>

      <Button text={t('signIn')} fullWidth onClick={onClick} />
    </>
  )
}
