import { Grid, Typography } from '@mui/material'
import { Input, PasswordInput, Spacer } from 'components'
import { useI18N } from 'hooks'

export default function SignUpPage({ values, errors, onChange }) {
  const { t } = useI18N()

  return (
    <>
      <Typography fontSize='1.5rem' mb={2}>
        {t('toCompleteThePost')}
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Input
            id={'userData.name'}
            value={values.name}
            onChange={onChange}
            label={t('name')}
            error={errors['userData.name']}
            autoFocus
            helperText={t('onlyVisibleForYou')}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id={'userData.public_name'}
            value={values.public_name}
            onChange={onChange}
            label={t('publicName')}
            error={errors['userData.public_name']}
            helperText={t('visibleForEveryone')}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id={'userData.email'}
            type='email'
            label={t('email')}
            error={errors['userData.email']}
            value={values.email}
            onChange={onChange}
            autoComplete='off'
            helperText={t('onlyVisibleForYou')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            id={'userData.password'}
            value={values.password}
            onChange={onChange}
            autoComplete='new-password'
            label={t('pass')}
            error={errors['userData.password']}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            id={'userData.repeatPassword'}
            value={values.repeatPassword}
            onChange={onChange}
            autoComplete='new-password'
            label={t('repeatPass')}
            error={errors['userData.repeatPassword']}
          />
        </Grid>
        <Spacer y={2}>
          <Typography
            textAlign='center'
            fontSize={14}
            color='textSecondary'
            sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}
          >
            {t('termsAndPrivacy')}
          </Typography>
        </Spacer>
      </Grid>
    </>
  )
}
