import { Grid, Typography } from '@mui/material'
import { CountrySelect, Input, MaskedInput, SelectableInput } from 'components'
import { getCityByPostalCode, provincesList } from 'constants/Provinces'
import { getUserCountryCode } from 'helpers/getUserCountryCode'
import { useI18N, useLazyEffect } from 'hooks'
import { useEffect } from 'react'

export default function LocationSelectorPage({ values, onChange }) {
  const { t } = useI18N()
  const handleChange = (id, value) => {
    onChange({ [`localitation.${id}`]: value })
  }

  useEffect(() => {
    if (!values.country) {
      getUserCountryCode().then((country) => {
        onChange({
          'localitation.country': country,
          'userData.country': country
        })
      })
    }
  }, [])

  useLazyEffect(() => {
    if (values.postal_code.length >= 4 && values.country) {
      getCityByPostalCode(values.postal_code, values.country).then(
        ({ city = '', province = '' }) => {
          if (city) {
            onChange({
              'localitation.city': city,
              'localitation.province': province
            })
          }
        }
      )
    } else {
      onChange({ 'localitation.city': '', 'localitation.province': '' })
    }
  }, [values.postal_code])

  const handleCountryChange = ({ country }) => {
    onChange({
      'userData.country': country,
      'localitation.country': country,
      'localitation.postal_code': '',
      'localitation.city': '',
      'localitation.province': ''
    })
  }

  return (
    <>
      <Typography fontSize='1.5rem' mb={2}>
        {t('localitation')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CountrySelect
            id='country'
            onChange={handleCountryChange}
            label={t('country')}
            value={values.country}
          />
        </Grid>
        <Grid item xs={12}>
          <MaskedInput
            id='postalCode'
            onChange={(value) => handleChange('postal_code', value.postalCode)}
            label={t('postalCode')}
            value={values.postal_code}
            mask='00000'
          />
        </Grid>
        <Grid item xs={12}>
          <SelectableInput
            onChange={(value) => handleChange('province', value)}
            label={t('province')}
            value={values.province}
            items={
              values.province &&
              !provincesList(values.country).includes(values.province)
                ? provincesList(values.country).concat(values.province)
                : provincesList(values.country)
            }
            listContent='array'
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            onChange={(value) => handleChange('city', value)}
            label={t('city')}
            value={values.city}
          />
        </Grid>
      </Grid>
    </>
  )
}
