import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import { ChatCard } from 'components'
import { GET_USER_CHATS } from 'apis/querys'
import { NEW_CHAT_SUB } from 'apis/subscriptions'
import { useUser } from 'context/UserContext'
import { OFFER_STATES } from 'constants/Enums'
import { useSearchParams } from 'hooks'
import { useEffect } from 'react'

export default function ChatsList({ state = [] }) {
  const { search } = useSearchParams('search')
  const { user } = useUser()

  const { loading, data, subscribeToMore, networkStatus } = useQuery(
    GET_USER_CHATS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        userId: user.id,
        state
      },
      skip: state.length === 0
    }
  )

  const subscribeToNewChats = () => {
    return subscribeToMore({
      document: NEW_CHAT_SUB,
      variables: { userId: user.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newChats = [...prev.getUserChats]
        let prevChats

        const updatedChat = subscriptionData.data.newChat

        const chatIndex = newChats.findIndex(
          (item) => item.job._id === updatedChat.job._id
        )
        const deleted = subscriptionData.data.newChat.job.state === null

        if (chatIndex !== -1) {
          prevChats = [...newChats[chatIndex].chats]
          const prevJob = { ...newChats[chatIndex].job }
          const messageIndex = prevChats.findIndex(
            (item) => item.chat_id === updatedChat.chats[0].chat_id
          )
          const newChat = updatedChat.chats[0]

          if (messageIndex !== -1) {
            if (!deleted) {
              prevChats.splice(messageIndex, 1, newChat)
            } else {
              prevChats.splice(messageIndex, 1)
            }
          } else {
            prevChats.unshift(newChat)
          }

          prevChats.sort((a, b) => b.last_message.date - a.last_message.date)

          const finalObject = { job: prevJob, chats: prevChats }
          newChats.splice(chatIndex, 1, finalObject)
        } else {
          newChats.unshift(updatedChat)
        }

        return Object.assign({}, prev, {
          getUserChats: newChats
        })
      }
    })
  }

  useEffect(() => {
    subscribeToNewChats()
    return () => subscribeToNewChats()
  }, [])

  //   console.log({ loading, data, networkStatus })

  if (loading)
    return (
      <Grid container spacing={1}>
        {new Array(5).fill('').map((it, idx) => (
          <Grid key={idx} item xs={12}>
            <ChatCard loading />
          </Grid>
        ))}
      </Grid>
    )

  if (!data) return null

  const searchValue = search ? search.toLowerCase() : ''
  const chatsGroup = data.getUserChats.filter(
    ({ chats, job }) =>
      chats.length > 0 && job.title.toLowerCase().includes(searchValue)
  )

  return (
    <Grid container spacing={1}>
      {chatsGroup.length > 0 &&
        chatsGroup.map(({ chats, job }) => (
          <Grid key={job._id} item xs={12}>
            <ChatCard
              chats={chats}
              job={job}
              swipeable={state.includes(OFFER_STATES.PROPOSAL)}
            />
          </Grid>
        ))}
    </Grid>
  )
}
