import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { alpha, Box, IconButton, Stack, Typography } from '@mui/material'
import { Spacer } from 'components'
import { CloseCircle, Image } from 'iconsax-react'
import { motion } from 'framer-motion'
import { useSnackbar } from 'context/SnackBarContext'
import { useI18N } from 'hooks'

const maxFilesCount = 5

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return bytes

  const k = 1024
  const m = 1048576
  const dm = decimals < 0 ? 0 : decimals

  if (bytes < m) {
    return parseFloat((bytes / k).toFixed(dm)) + ' KB'
  } else {
    return parseFloat((bytes / m).toFixed(dm)) + ' MB'
  }
}

export default function ImagesSelectorPage({ values = [], onChange }) {
  const { t } = useI18N()
  const { showSnackbar } = useSnackbar()
  const isDisabledDropzone = values.length === maxFilesCount

  const errorHandler = (code, file) => {
    switch (code) {
      case 'file-too-large':
        showSnackbar({
          message: t(
            'imageErrors.file-too-large',
            file.name,
            '2 MB',
            formatBytes(file.size, 1)
          ),
          marginBottom: true
        })
        break
      case 'file-invalid-type':
        showSnackbar({
          message: t('imageErrors.file-invalid-type', 'png, jpg, jpeg.'),
          marginBottom: true
        })
        break
      case 'too-many-files':
        showSnackbar({
          message: t('imageErrors.too-many-files', maxFilesCount),
          marginBottom: true
        })
        break
      default:
        showSnackbar({
          message: t('imageErrors.bad-file', file.name),
          marginBottom: true
        })
        break
    }
  }

  const onDropAccepted = (acceptedFiles) => {
    if (values.length + acceptedFiles.length > maxFilesCount) {
      return errorHandler('too-many-files')
    }

    const files = values
    acceptedFiles.forEach((file) => {
      const size = formatBytes(file.size, 1)
      files.push({
        name: file.name,
        type: file.type,
        size,
        url: URL.createObjectURL(file),
        file
      })
    })

    onChange({ pictures: files })
  }

  const onDropRejected = useCallback((rejectedFiles) => {
    if (values.length + rejectedFiles.length > maxFilesCount) {
      return errorHandler('too-many-files')
    }

    rejectedFiles.forEach(({ errors, file }) => {
      //   console.log({ errors })
      const firstErrorCode = errors[0].code
      errorHandler(firstErrorCode, file)
    })
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': []
    },
    maxFiles: maxFilesCount - values.length,
    maxSize: 2 * 1024 * 1024, // 2MB
    onDropAccepted,
    onDropRejected,
    disabled: isDisabledDropzone
  })

  const handleDeleteImage = (index) => {
    const newPictures = values.slice(0)
    newPictures.splice(index, 1)
    onChange({ pictures: newPictures })
  }

  return (
    <>
      <Typography fontSize='1.5rem' mb={2}>
        {t('doYouWantToProvideAnImage')}
      </Typography>
      <Typography color='textSecondary'>*{t('optional')}</Typography>
      <Spacer y={2} />
      <Box
        className={`${isDragActive ? 'active' : ''} ${
          isDisabledDropzone ? 'disabled' : ''
        }`}
        sx={{
          transition: 'background-color 250ms ease',
          bgcolor: 'background.card',
          border: '1px dashed',
          borderColor: 'action.disabled',
          borderRadius: 4,
          p: 4,
          textAlign: 'center',
          cursor: isDisabledDropzone ? 'no-drop' : 'pointer',
          '&.disabled': {
            bgcolor: 'action.hover'
          },
          '&.active': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1)
          }
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box sx={{ color: 'primary.main', mb: 1 }}>
          <Box
            component={motion.div}
            sx={{ width: 'fit-content', mx: 'auto' }}
            {...(isDragActive && {
              animate: { y: [0, -10, 0] },
              transition: { times: [0, 0.5, 1], repeat: Infinity }
            })}
          >
            <Image size={48} />
          </Box>
        </Box>
        <Typography fontWeight={500} mb={1}>
          {t('dragAndDropOrBrowse')}{' '}
          <Typography component='span' color='primary'>
            {t('browse')}
          </Typography>
        </Typography>
        <Typography sx={{ color: 'text.disabled' }} fontSize={12}>{`${t(
          'allow'
        )}: PNG, JPG, JPEG`}</Typography>
        <Typography sx={{ color: 'text.disabled' }} fontSize={12}>{`${t(
          'maxFilesSize'
        )} 2MB`}</Typography>
      </Box>
      <Spacer m={1}>
        <Typography sx={{ color: 'text.disabled' }}>{`${values.length} ${t(
          'of'
        )} ${maxFilesCount}`}</Typography>
      </Spacer>

      <Spacer y={2}>
        <Stack spacing={2}>
          {values.map((file, index) => (
            <Box
              key={file.url}
              sx={{
                bgcolor: 'background.card',
                p: 1,
                borderRadius: 4,
                boxShadow: '0 1px 6px rgba(0,0,0,0.05)',
                overflow: 'hidden'
              }}
            >
              <Stack direction='row' spacing={2} alignItems='center'>
                <Box
                  sx={{
                    height: '3.75rem',
                    width: '3.75rem',
                    minWidth: '3.75rem'
                  }}
                >
                  <Box
                    component='img'
                    sx={{
                      display: 'block',
                      height: '100%',
                      width: '100%',
                      borderRadius: 3,
                      objectFit: 'cover'
                    }}
                    src={file.url}
                    alt={file.name}
                  />
                </Box>
                <Box sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  <Typography noWrap>{file.name}</Typography>
                  <Typography
                    sx={{
                      fontSize: '0.75rem',
                      color: 'text.disabled'
                    }}
                  >
                    {`${file.size}`}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => handleDeleteImage(index)}
                    sx={{ color: 'text.secondary' }}
                  >
                    <CloseCircle />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Spacer>
    </>
  )
}
