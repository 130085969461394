import React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 8
  }
}))

function Input({
  value,
  error = null,
  id,
  shrink,
  helperText,
  onChange,
  ...other
}) {
  const handleChange = (event) => {
    if (!id) return onChange(event.target.value)

    const { name, value } = event.target
    // console.log({ name, value });
    onChange({ [name]: value })
  }

  return (
    <StyledInput
      id={id}
      name={id}
      variant='outlined'
      fullWidth
      value={value || ''}
      InputLabelProps={{
        shrink
      }}
      helperText={error || helperText}
      onChange={handleChange}
      {...(error && { error: true })}
      {...other}
    />
  )
}

export default React.memo(Input)
