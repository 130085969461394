import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'

/// ////////COMPONENT PROPS
SelectableInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  bottomspace: PropTypes.string,
  items: PropTypes.array,
  id: PropTypes.string,
  listContent: PropTypes.string, // object, array
  customKey: PropTypes.string,
  required: PropTypes.bool,
  byIndex: PropTypes.bool
}

const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 8
  }
})

function SelectableInput({
  error,
  items,
  id,
  listContent,
  customKey,
  byIndex,
  children,
  label,
  required,
  helperText,
  onChange,
  ...other
}) {
  const handleChange = (event) => {
    if (!id) {
      return onChange(event.target.value)
    }

    const { name, value } = event.target
    onChange({ [name]: value })
  }

  function getSelectableType(listContent) {
    switch (listContent) {
      case 'array':
        return items.map((item, index) => (
          <MenuItem key={`${id}-${index}`} value={byIndex ? index : item}>
            {item}
          </MenuItem>
        ))
      case 'custom':
        return children
      default:
        return items.map((item, index) => (
          <MenuItem
            key={`${id}-${index}`}
            value={
              byIndex ? index : item[customKey] ? item[customKey] : item.name
            }
          >
            {item.name}
          </MenuItem>
        ))
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id='select-label' error={!!error}>
        {label}
        {required && '*'}
      </InputLabel>
      <StyledSelect
        id={id}
        name={id}
        label={label}
        MenuProps={{ sx: { '& .MuiMenu-paper': { borderRadius: 3 } } }}
        error={!!error}
        required={required}
        onChange={handleChange}
        {...other}
      >
        {getSelectableType(listContent)}
      </StyledSelect>
      <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
    </FormControl>
  )
}

export default React.memo(SelectableInput)
