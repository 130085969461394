import { useContext, useState } from 'react'
import { UserContext } from 'context/UserContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ADD_USER } from 'apis/mutations'
import { auth } from 'apis/firebase'
import { GET_USER_BY_EMAIL } from 'apis/querys'
import { ROLE } from 'constants/Roles'
import { useFirebase, useI18N } from 'hooks'
import { LOCAL_KEYS } from 'constants/LocalKeys'

export default function useSignUp() {
  const { t } = useI18N()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { setUser } = useContext(UserContext)
  const [saveUser] = useMutation(ADD_USER)
  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL)
  const { uploadFile, storageUserProfile } = useFirebase()

  const validateEmail = async (email) => {
    const userEmail = await getUser({ variables: { email } })
    const emailExist = userEmail.data.getUserByEmail?.email
    if (emailExist) return true
    return false
  }

  const uploadCompanyPicture = async (file, userId) => {
    const fileUrl = await uploadFile({
      storageRef: storageUserProfile(userId),
      file,
      fileName: 'profilePicture'
    })

    return fileUrl
  }

  const signup = async (values) => {
    setLoading(true)
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        values.email.toLowerCase(),
        values.password
      )
      const uid = userCredential.user.uid
      const finalValues = { ...values, uid }

      // Save the picture
      if (Object.keys(values.picture).includes('file')) {
        const pictureURL = await uploadCompanyPicture(values.picture.file, uid)
        finalValues.picture = { url: pictureURL?.data || '' }
      }

      const userResp = await saveUser({ variables: finalValues })
      const localUserData = {
        uid,
        id: userResp.data.addUser._id,
        role: values.role,
        email: values.email,
        name: values.role === ROLE.COMPANY ? values.name : values.public_name
      }
      setUser(localUserData)
      localStorage.setItem(LOCAL_KEYS.USER, JSON.stringify(localUserData))
      setLoading(false)
      // console.log('User created')
      return localUserData
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError({ ...error, email: t('error.alreadyInUse') })
          break
        default:
          break
      }
      console.log({ error })
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    signup,
    validateEmail
  }
}
