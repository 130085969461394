import { Typography, Box } from '@mui/material'
import { useI18N } from 'hooks'
import { Calendar2, Clock, TickCircle } from 'iconsax-react'

export default function OfferDates({ startDate, endDate }) {
  const { t } = useI18N()

  return (
    <Box aria-label='dates-container' sx={{ mt: 1, position: 'relative' }}>
      <Box sx={{ display: 'flex', position: 'relative' }}>
        {endDate ? (
          <Box
            element='span'
            sx={{
              color: 'warning.light',
              mt: 0.2,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Clock size={20} variant='Outline' />
            <Box
              element='span'
              sx={{
                width: 2,
                flexGrow: 1,
                bgcolor: 'divider',
                mx: 'auto',
                mt: 0.5,
                mb: 0.3,
                display: 'block'
              }}
            />
          </Box>
        ) : (
          <Box element='span' sx={{ color: 'primary.main', mt: 0.2 }}>
            <Calendar2 size={20} variant='Outline' />
          </Box>
        )}
        <div>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.9rem', pl: 1 }}>
              {t('startDate')}
            </Typography>
          </Box>

          <Typography color='textSecondary' sx={{ fontSize: '0.9rem', pl: 1 }}>
            {startDate}
          </Typography>
        </div>
      </Box>
      {endDate && (
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <Box element='span' sx={{ color: 'success.light', mt: 0.2 }}>
            <TickCircle size={20} variant='Outline' />
          </Box>
          <div>
            <Typography sx={{ fontSize: '0.9rem', pl: 1 }}>
              {t('endDate')}
            </Typography>
            <Typography
              color='textSecondary'
              sx={{ fontSize: '0.9rem', pl: 1 }}
            >
              {endDate}
            </Typography>
          </div>
        </Box>
      )}
    </Box>
  )
}
