import { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { Box, Typography } from '@mui/material'
import { useI18N } from 'hooks'
import { remark } from 'remark'
import html from 'remark-html'
import es from 'constants/contract/es.md'
import en from 'constants/contract/en.md'

const languages = { es, en }

export default function Contract() {
  const { t } = useI18N()
  const [contract, setContract] = useState('')

  useEffect(() => {
    getContract()
  }, [])

  const getContract = async () => {
    let locale = 'en'
    const currentLanguage = navigator.language.split('-')[0]

    switch (currentLanguage) {
      case 'es':
        locale = 'es'
        break
      // case 'de':
      //   locale = 'de'
      //   break
      // case 'fr':
      //   locale = 'fr'
      //   break
      // case 'it':
      //   locale = 'it'
      //   break
      default:
        break
    }

    const response = await fetch(languages[locale])
    const markdown = await response.text()
    const contractText = await remark().use(html).process(markdown)
    const sanitizedData = () => ({
      __html: DOMPurify.sanitize(contractText)
    })

    setContract(sanitizedData())
  }

  return (
    <>
      <Box sx={{ pb: 2, maxWidth: 320, mx: 'auto' }}>
        <Typography
          fontSize='1.4rem'
          fontWeight={600}
          lineHeight='1.1em'
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('pleaseReadTheContract')}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          border: '1px solid #cdcdcd',
          borderRadius: 2
        }}
      >
        <Box
          id='contract-container'
          className='main-container'
          sx={{
            p: 1.5,
            pb: 2,
            maxHeight: '50vh',
            overflowY: 'scroll',
            '& h1': {
              marginTop: 0,
              lineHeight: '2rem'
            }
          }}
        >
          {contract && <div dangerouslySetInnerHTML={contract} />}
        </Box>
        <Box
          component='span'
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            background: (theme) =>
              `linear-gradient(0deg, ${theme.palette.background.paper} 30%, rgba(255,255,255,0) 100%)`,
            height: 40,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8
          }}
        />
      </Box>
      <Typography
        fontSize={18}
        color='textSecondary'
        sx={{ textAlign: 'center', my: 1 }}
      >
        {t('ifYouContinue')}
      </Typography>
    </>
  )
}
