import { Box } from '@mui/material'

export default function SimplePageContainer({ sx, children }) {
  return (
    <Box
      id='content-container'
      className='scrollable'
      sx={{ height: '100%', ...sx }}
    >
      {children}
    </Box>
  )
}
