import { CssBaseline, Box } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import { MainContainer, DevThemeHandler } from 'components'
import './App.css'

export default function App() {
  return (
    <>
      <CssBaseline />
      <Box
        className='App'
        sx={{
          background: (theme) => ({
            xs: 'background.default',
            sm:
              theme.palette.mode === 'dark'
                ? 'linear-gradient(45deg, #5282EE 0%, #26447E 100%)'
                : 'linear-gradient(45deg, #6d99fc 0%, #c0f5e3 100%)'
          }),
          padding: { sm: 2 }
        }}
      >
        <DevThemeHandler />
        <Router>
          <MainContainer />
        </Router>
      </Box>
    </>
  )
}
