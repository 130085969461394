import { Box } from '@mui/material'

const Spacer = ({ m, y, x, children, ...other }) => {
  return (
    <Box py={y} px={x} m={m} {...other}>
      {children}
    </Box>
  )
}

export default Spacer
