import { cloneElement, useEffect, useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Box
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useScrollTrigger } from 'hooks'
import { useHistory } from 'react-router'
import { More } from 'iconsax-react'
import { ArrowBack } from 'assets/Icons'
import { getUserPicture } from 'helpers'
import { Menu } from 'components'

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  transition: 'background-color 500ms cubic-bezier(0.4, 0, 0.2, 1)',
  backgroundColor: theme.palette.background.paper,
  backgroundImage: 'inherit',
  position: 'absolute',
  '& .MuiToolbar-root': {
    padding: '0 16px'
  }
}))

const TopBarIconButton = ({ children, ...other }) => (
  <IconButton color='inherit' size='small' {...other}>
    {children}
  </IconButton>
)

function ElevationScroll({ children, target }) {
  const { trigger } = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target
  })

  return cloneElement(children, {
    elevation: trigger ? 4 : 0
  })
}

/** MAIN COMPONENT */
export default function ChatTopBar({
  title,
  avatarAlt,
  avatarSrc,
  hasMenu,
  menuList,
  onBackClick,
  disabled,
  toolbarProps,
  containerRef
}) {
  const history = useHistory()
  const [container, setContainer] = useState(null)

  useEffect(() => {
    if (containerRef) {
      setContainer(containerRef.current)
    }
  }, [containerRef])

  const goBack = () => (onBackClick ? onBackClick() : history.goBack())

  /**
   * MENU COMPONENT FUNCTIONS
   */
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  function onMenuItemClick(item) {
    setMobileMoreAnchorEl(null)
    item.action()
  }

  /**
   * TOPBAR RENDER
   */
  return (
    <>
      <ElevationScroll target={container}>
        <CustomAppBar
          sx={{
            '&.MuiPaper-elevation4': {
              bgcolor: (theme) => theme.palette.background.paper
            }
          }}
        >
          <Toolbar id='main-toolbar' {...toolbarProps}>
            {/** Back button */}
            <TopBarIconButton
              edge='start'
              sx={{
                mr: 1,
                borderRadius: 20,
                pr: 1,
                '&:hover': {
                  bgcolor: 'action.hover'
                }
              }}
              disableRipple
              aria-label='go-back'
              onClick={goBack}
              disabled={disabled}
            >
              <ArrowBack fontSize='small' />
              {/** Avatar */}
              <Avatar
                alt={avatarAlt}
                src={getUserPicture(avatarSrc).profileImage}
                sx={{
                  padding: 0,
                  width: (theme) => theme.spacing(4),
                  height: (theme) => theme.spacing(4),
                  textTransform: 'uppercase',
                  bgcolor: 'action.disabled',
                  backgroundImage: `url(${getUserPicture(avatarSrc).bgImage})`,
                  boxShadow: (theme) =>
                    `0px 0px 0px 1px ${theme.palette.action.disabledBackground}`,
                  backgroundSize: 'cover'
                }}
              />
            </TopBarIconButton>

            {/** TopBar Title */}
            {typeof title === 'string' ? (
              <Typography
                id='title'
                noWrap
                sx={{
                  flexGrow: 1,
                  textAlign: 'left',
                  lineHeight: 1.2,
                  pl: 1
                }}
              >
                {title}
              </Typography>
            ) : (
              title
            )}

            {/** Acion Button */}
            {hasMenu && !title && <Box sx={{ flexGrow: 1 }} />}
            {hasMenu && (
              <TopBarIconButton
                aria-label='appbar-menu'
                aria-controls='menu'
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                edge='end'
                disabled={disabled}
              >
                <More size={24} variant='Outline' />
              </TopBarIconButton>
            )}
          </Toolbar>
        </CustomAppBar>
      </ElevationScroll>

      {/** Menu */}
      {hasMenu && (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
          onItemClick={(item) => onMenuItemClick(item)}
          menuList={menuList}
        />
      )}
    </>
  )
}
