// Validate NIF, DNI o NIE
export function validateNif(value) {
  let number, lett, letter
  const RegularExpresion = /^[XYZ]?\d{5,8}[A-Z]$/

  const dni = value.toUpperCase()

  if (RegularExpresion.test(dni) === true) {
    number = dni.substr(0, dni.length - 1)
    number = number.replace('X', 0)
    number = number.replace('Y', 1)
    number = number.replace('Z', 2)
    lett = dni.substr(dni.length - 1, 1)
    number = number % 23
    letter = 'TRWAGMYFPDXBNJZSQVHLCKET'
    letter = letter.substring(number, number + 1)
    if (letter !== lett) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

function validateCif(cif) {
  // First, convert the CIF to a string if it isn't one already
  cif = cif.toString().toUpperCase()

  // Next, check that the CIF is 9 characters long and contains the required characters
  if (!/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9,A-J]$/i.test(cif)) {
    return false
  }

  // Next, extract the first digit and the last 8 digits of the CIF
  const firstDigit = cif[0]
  const lastEight = cif.substring(1)

  // Next, sum the digits in the last eight digits of the CIF
  let sum = 0
  for (let i = 0; i < lastEight.length; i++) {
    sum += parseInt(lastEight[i])
  }

  // Then, calculate the check digit using the formula specified in the CIF
  // specification
  let checkDigit = 0
  if (/^[ABEH]/.test(firstDigit)) {
    checkDigit = (sum % 10) + ''
    if (firstDigit === 'H') {
      checkDigit = checkDigit.replace(/^[A-Z]/, 'A')
    }
  } else if (/^[KPQS]/.test(firstDigit)) {
    checkDigit = (sum % 10) + ''
    if (/^[KPQS]/.test(checkDigit)) {
      checkDigit = 'JABCDEFGHI'[checkDigit]
    }
  } else {
    checkDigit = 10 - (sum % 10) + ''
  }

  // Finally, compare the calculated check digit with the last digit of the CIF
  return checkDigit === cif[cif.length - 1]
}

export function validateSwissVAT(vat) {
  // Check if the input is a string
  if (typeof vat !== 'string') {
    return false
  }
  // Remove any spaces or punctuation from the input
  vat = vat.replace(/\s|\W/g, '')
  // Check if the input has the correct format: CHE followed by 9 digits and MWST, TVA or IVA
  const regex = /^CHE\d{9}$/
  if (!regex.test(vat)) {
    return false
  }
  // Extract the 9 digits and the suffix from the input
  const digits = vat.slice(3, 12)
  // const suffix = vat.slice(12)
  // Calculate the check digit using the MOD11 algorithm
  const weights = [5, 4, 3, 2, 7, 6, 5, 4]
  let sum = 0
  for (let i = 0; i < weights.length; i++) {
    sum += weights[i] * digits[i]
  }
  const remainder = sum % 11
  const checkDigit = remainder === 0 ? 0 : 11 - remainder
  // Compare the check digit with the last digit of the input
  if (checkDigit !== +digits[8]) {
    return false
  }
  // Return true if all checks passed
  return true
}

export function validateAll(value) {
  if (validateNif(value) || validateCif(value)) {
    return true
  }
  return false
}
