import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER } from 'apis/querys'
import { UserContext } from 'context/UserContext'
import { getUserCountryCode } from 'helpers/getUserCountryCode'

// TODO: change all the useUserInfo for useUser from the user context
export default function useUserInfo() {
  const { user: userData } = useContext(UserContext)
  const { data, loading: isLoading } = useQuery(GET_USER, {
    variables: { userId: userData.id, uid: userData.uid },
    skip: !userData.id
  })
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(userData)

  useEffect(() => {
    if (!isLoading) {
      if (data && data.getUser) {
        setUser((prev) => ({ ...prev, ...data.getUser }))
        setLoading(false)
      } else {
        getUserCountryCode().then((country) => {
          setUser((prev) => ({ ...prev, country }))
        })
        setLoading(false)
      }
    }
  }, [data, isLoading])

  return {
    loading,
    user
  }
}
