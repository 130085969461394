import de from 'languages/de'
import en from 'languages/en'
import es from 'languages/es'
import fr from 'languages/fr'
import it from 'languages/it'

const texts = (...args) => {
  const currentLanguage = navigator.language.split('-')[0]

  switch (currentLanguage) {
    case 'es':
      return es(...args)
    case 'de':
      return de(...args)
    case 'fr':
      return fr(...args)
    case 'it':
      return it(...args)
    default:
      return en(...args)
  }
}

export default texts
