import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'
import { Button, Typography, MobileStepper, Box } from '@mui/material'
import { Spacer } from 'components'
import { useI18N } from 'hooks'

export default function Carrousel({ items }) {
  const { t } = useI18N()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = items.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ maxWidth: 370 }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {items.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <>
                <Box
                  component='img'
                  sx={{
                    height: 250,
                    maxWidth: 250,
                    display: 'block',
                    overflow: 'hidden',
                    width: '100%',
                    mx: 'auto'
                  }}
                  src={step.image}
                  alt={step.label}
                />
                <Box
                  sx={{
                    height: 80,
                    p: 2,
                    mb: 1
                  }}
                >
                  <Typography textAlign='center'>{step.label}</Typography>
                </Box>
              </>
            ) : null}
          </div>
        ))}
      </SwipeableViews>

      <Spacer x={2}>
        <MobileStepper
          steps={maxSteps}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button
              size='small'
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{
                textTransform: 'none',
                '&:active': {
                  transform: 'scale(0.95)'
                }
              }}
            >
              {t('next')}
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                textTransform: 'none',
                '&:active': {
                  transform: 'scale(0.95)'
                }
              }}
            >
              {t('preview')}
            </Button>
          }
        />
      </Spacer>
    </Box>
  )
}
