import { useState } from 'react'
import { Grid } from '@mui/material'
import { ScrollableContainer, SearchBar, Tabs, ChatsList } from 'components'
import { useUser } from 'context/UserContext'
import { OFFER_STATES } from 'constants/Enums'
import { ROLE } from 'constants/Roles'
import { useI18N, useSearchParams } from 'hooks'
import { useHistory } from 'react-router-dom'

const tabsItems = (t) => ({
  company: [t('tabAccepted'), t('tabProposal')],
  user: [t('tabAccepted'), t('tabStandBy')]
})

export default function Inbox() {
  const { t } = useI18N()
  const history = useHistory()
  const { user } = useUser()
  const { search } = useSearchParams('search')
  const [[page, direction], setPage] = useState([0, 0])

  const handleSearch = (search) => {
    const pathname = history.location.pathname
    if (search.length > 0) {
      history.push(`${pathname}?search=${search}`)
    } else {
      history.push(pathname)
    }
  }

  return (
    <ScrollableContainer
      toolBarChildren={
        <>
          <SearchBar
            onEnter={handleSearch}
            placeholder={t('search')}
            defaultValue={search}
            onClean={() => handleSearch('')}
          />
          <Tabs
            items={tabsItems(t)[user.role]}
            page={page}
            onChange={setPage}
          />
        </>
      }
    >
      <Tabs.Container direction={direction} page={page}>
        <Tabs.Page>
          <TabContent>
            <ChatsList state={[OFFER_STATES.ACCEPTED]} />
          </TabContent>
        </Tabs.Page>
        <Tabs.Page>
          <TabContent>
            <ChatsList
              state={
                user.role === ROLE.COMPANY
                  ? [OFFER_STATES.PROPOSAL]
                  : [OFFER_STATES.NEGOTIATING]
              }
            />
          </TabContent>
        </Tabs.Page>
      </Tabs.Container>
    </ScrollableContainer>
  )
}

const TabContent = ({ children }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}
