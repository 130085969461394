import { devKeys, prodKeys } from './keys'

const uri =
  process.env.NODE_ENV === 'production'
    ? prodKeys.backendURI
    : devKeys.backendURI

const getPlans = async () => {
  const response = await fetch(`${uri}/list-prices`)
  const plans = await response.json()
  const prices = []

  for (const price of plans.data) {
    const priceDetails = await getPrice(price.id)
    prices.push(priceDetails)
  }

  return prices
}

const getPrice = async (priceId) => {
  const price = await fetch(`${uri}/retrieve-price?priceId=${priceId}`)
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return price
}

const getInvoices = async (customer) => {
  const invoices = await fetch(`${uri}/list-invoices?customer=${customer}`)
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return invoices
}

const getCustomer = async (customerId) => {
  const customer = await fetch(
    `${uri}/retrieve-customer?customerId=${customerId}`
  )
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return customer
}

const getSubscription = async (subscriptionId) => {
  const subs = await fetch(
    `${uri}/retrieve-subscription?subscriptionId=${subscriptionId}`
  )
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return subs
}

const getPaymentMethod = async (paymentMethodId) => {
  const payment = await fetch(
    `${uri}/retrieve-payment-method?paymentMethodId=${paymentMethodId}`
  )
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return payment
}

const getLastInvoice = async (id) => {
  const invoice = await fetch(`${uri}/retrieve-invoice?invoiceId=${id}`)
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return invoice
}

const cancelSubscription = async (id) => {
  const subscription = await fetch(`${uri}/cancel-subscription`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscriptionId: id
    })
  })
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return subscription
}

const updatePlan = async (id, interval, subsItemId) => {
  const subscription = await fetch(`${uri}/update-subscription`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscriptionId: id,
      interval,
      subsItemId
    })
  })
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return subscription
}

const updateDefaultPaymentMethod = async (customerId, paymentMethodId) => {
  const customer = await fetch(`${uri}/update-default_payment_method`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      customerId,
      paymentMethodId
    })
  })
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return customer
}

const detachPaymentMethod = async (id) => {
  const paymentMethod = await fetch(`${uri}/detach-payment_method`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      paymentMethodId: id
    })
  })
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return paymentMethod
}

const attachPaymentMethod = async (customerId, paymentMethodId) => {
  const paymentMethod = await fetch(`${uri}/attach-payment_method`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      customerId,
      paymentMethodId
    })
  })
    .then((r) => r.json())
    .then((resp) => {
      return resp
    })

  return paymentMethod
}

const createSubscription = async (priceId, customerId, currency) => {
  const subscription = await fetch(`${uri}/create-subscription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      priceId,
      customerId,
      currency
    })
  })
    .then((r) => r.json())
    .then((snap) => {
      return snap
    })

  return subscription
}

const createPaymentIntent = async (amount, payment_method, currency) => {
  const paymentIntent = await fetch(`${uri}/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      amount,
      payment_method,
      currency
    })
  })
    .then((r) => r.json())
    .then((snap) => {
      return snap
    })
  return paymentIntent
}

const createCustomer = async (values) => {
  const customer = await fetch(`${uri}/create-customer`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: values.email,
      nif: values.nif,
      name: values.name
    })
  })
    .then((r) => r.json())
    .then((snap) => {
      return snap
    })

  return customer
}

export {
  getPlans,
  getPrice,
  getInvoices,
  getSubscription,
  getPaymentMethod,
  getLastInvoice,
  cancelSubscription,
  updatePlan,
  getCustomer,
  updateDefaultPaymentMethod,
  detachPaymentMethod,
  attachPaymentMethod,
  createSubscription,
  createPaymentIntent,
  createCustomer
}
