import React, { useEffect, useState } from 'react'
import { SubscriptionCard, Button, Spacer } from 'components'
import { Box, Typography, Skeleton } from '@mui/material'
import { getPlans } from 'apis/stripe'
import { routes } from 'constants/Routes'
import { useHistory } from 'react-router-dom'
import { useI18N, useUserInfo } from 'hooks'
import { currencies } from 'constants/Countries'

export default function SubSelector({ renew, customer }) {
  const { t } = useI18N()
  const history = useHistory()
  const { user } = useUserInfo()
  const [selected, setSelected] = useState(null)
  const [plans, setPlans] = useState(null)
  const [prices, setPrices] = useState({
    year: ''
  })

  const goToCheckout = (selected) => {
    history.push(routes.checkout, {
      price: selected,
      customer
    })
  }

  const handleCardClick = (plan) => () => {
    setSelected(plan)
  }

  useEffect(() => {
    if (!user) return

    const currency = currencies[user.country] ?? currencies.CH

    getPlans().then((plans) => {
      const yearlyPlan = plans.find(
        (plan) => plan.recurring?.interval === 'year' && !plan.metadata?.promo
      )
      // const monthlyPlan = plans.data.find((plan) => plan.interval === "month");
      setSelected(yearlyPlan)
      setPrices({
        year: yearlyPlan.currency_options[currency].unit_amount / 100
      })
      const plansData = plans
        .filter((plan) => (renew ? !plan.metadata?.promo : plan))
        .map((price) => {
          return {
            ...price,
            currency,
            unit_amount: price.currency_options[currency].unit_amount
          }
        })
      setPlans(plansData)
    })
    return () => {
      setSelected('')
      setPlans(null)
      setPrices({ year: '', month: '' })
    }
  }, [user])

  const handleGoToCheckOut = (e) => {
    e.preventDefault()
    goToCheckout(selected)
  }

  const getPlanProps = (plan) => {
    const price = plan.unit_amount / 100
    const interval = plan.recurring?.interval
    const promo = plan.metadata?.promo === 'true'
    let description, accentText, saving, content

    switch (interval) {
      case 'year':
        if (promo) {
          description = plan.nickname
          accentText = plan.metadata?.description
          content = price.toFixed(0)
        } else {
          description = `${price} ${plan.currency} ${t('annualDescription')}`
          content = (price / 12).toFixed(2)
        }
        break
      case 'month':
        description = `${price} ${plan.currency} ${t('monthlyDescription')}`
        saving = (((price * 12 - prices.year) * 100) / (price * 12)).toFixed(0)
        accentText =
          price * 12 > prices.year
            ? `${t('saveA')} ${saving}% ${t('withAnnualPayment')}`
            : ''
        content = price.toFixed(2)

        break
      default:
        description = `${price} ${plan.currency} ${t(
          'uniquePaymentDescription'
        )}`
        break
    }

    return {
      price,
      content,
      description,
      accentText,
      promo
    }
  }

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('postulate')}
      </Typography>
      <Typography color='textSecondary' align='center' fontSize={18}>
        {t('selectThePlan')}
      </Typography>
      <Spacer mt={2}>
        {user && plans && plans.length > 0 ? (
          plans.map((plan) => {
            const planData = getPlanProps(plan)
            return (
              <Box key={plan.id} sx={{ mb: 2 }}>
                <SubscriptionCard
                  title={
                    plan.recurring
                      ? t(`plan.${plan.recurring?.interval}`)
                      : t('uniquePayment')
                  }
                  price
                  currency={plan.currency}
                  recurring={plan.recurring}
                  content={planData.content}
                  description={planData.description}
                  accentText={planData.accentText}
                  selected={selected.id === plan.id}
                  onClick={handleCardClick(plan)}
                  promo={planData.promo}
                />
              </Box>
            )
          })
        ) : (
          <LoadingPlans />
        )}
        <Button
          text={t('continue')}
          onClick={handleGoToCheckOut}
          fullWidth
          disabled={!selected}
        />
      </Spacer>
    </>
  )
}

const LoadingPlans = () => (
  <>
    <Skeleton
      variant='rectangular'
      height={200}
      sx={{ borderRadius: 4, mb: 2 }}
    />
    <Skeleton
      variant='rectangular'
      height={200}
      sx={{ borderRadius: 4, mb: 2 }}
    />
  </>
)
