import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { AvatarInput, BottomDrawer, Button, Modal } from 'components'
import {
  colors,
  profilePictures,
  profilePicturesBg,
  skinColors
} from 'constants/ProfilePictures'
import { useI18N } from 'hooks'

export default function AvatarPicker({
  id,
  open,
  onClose,
  currentAvatar,
  onSave
}) {
  const { t } = useI18N()
  const [value, setValue] = useState(currentAvatar)
  const handleSave = (e) => {
    e.preventDefault()
    onSave({ [id]: value })
    onClose()
  }
  return (
    <Modal open={open} onClose={onClose} anchor='bottom'>
      <BottomDrawer
        title={
          <Typography fontSize={20} align='center'>
            {t('changeYourAvatar')}
          </Typography>
        }
        actions={
          <>
            <Button
              fullWidth
              variant='light'
              text={t('cancel')}
              onClick={onClose}
            />
            <Button fullWidth text={t('save')} onClick={handleSave} />
          </>
        }
      >
        <AvatarPickerContent
          current={currentAvatar}
          onChange={(avatar) => setValue(avatar)}
        />
      </BottomDrawer>
    </Modal>
  )
}

const AvatarPickerContent = ({ current, onChange }) => {
  const { t } = useI18N()
  const [avatar, setAvatar] = useState(current)
  const [filter, setFilter] = useState({ skin: 1, color: 'black' })
  const theme = useTheme()

  const selectedShadow = `0px 0px 0px 4px ${theme.palette.primary.dark}`

  const setBg = (bg) => (e) => {
    e.preventDefault()
    setAvatar((prev) => ({ ...prev, bg }))
  }

  const setPicture = (pic) => (e) => {
    e.preventDefault()
    setAvatar((prev) => ({ ...prev, pic }))
  }

  useEffect(() => {
    if (avatar) {
      onChange(avatar)
    }
  }, [avatar])

  const filteredAvatar = () => {
    const result = profilePictures.filter(
      (item) => item.skin === filter.skin && item.color === filter.color
    )
    return result
  }

  return (
    <>
      <Box sx={{ mx: 'auto', width: 'fit-content' }}>
        <AvatarInput disabled image={avatar} alt='preview-avatar' />
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography fontSize={16} textAlign='start' marginBottom={1}>
          {t('background')}
        </Typography>
        <Box
          aria-label='background-selector'
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: { xs: 'space-around', sm: 'flex-start' }
          }}
        >
          {profilePicturesBg.map(({ id, value }) => (
            <Box
              key={id}
              role='button'
              component='span'
              onClick={setBg(id)}
              sx={{
                transition: 'all 0.2s ease-in-out',
                height: 40,
                width: 40,
                backgroundImage: `url(${value})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: '50%',
                boxShadow:
                  avatar.bg === id
                    ? selectedShadow
                    : '0px 0px 0px 0px transparent'
              }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize={16} textAlign='start' marginBottom={1}>
          {t('hair')}
        </Typography>
        <Box
          aria-label='hair-selector'
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: { xs: 'space-around', sm: 'flex-start' }
          }}
        >
          {colors.map(({ id, value }) => (
            <Box
              key={id}
              role='button'
              component='span'
              onClick={() => setFilter((prev) => ({ ...prev, color: id }))}
              sx={{
                transition: 'all 0.2s ease-in-out',
                height: 40,
                width: 40,
                bgcolor: value,
                borderRadius: '50%',
                boxShadow:
                  filter.color === id
                    ? selectedShadow
                    : '0px 0px 0px 0px transparent'
              }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize={16} textAlign='start' marginBottom={1}>
          {t('skin')}
        </Typography>
        <Box
          aria-label='skin-selector'
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: { xs: 'space-around', sm: 'flex-start' }
          }}
        >
          {skinColors.map(({ id, value }) => (
            <Box
              key={id}
              role='button'
              component='span'
              onClick={() => setFilter((prev) => ({ ...prev, skin: id }))}
              sx={{
                transition: 'all 0.2s ease-in-out',
                height: 40,
                width: 40,
                bgcolor: value,
                borderRadius: '50%',
                boxShadow:
                  filter.skin === id
                    ? selectedShadow
                    : '0px 0px 0px 0px transparent'
              }}
            />
          ))}
        </Box>
      </Box>
      <Box>
        <Typography fontSize={16} textAlign='start' marginBottom={1}>
          {t('features')}
        </Typography>
        <Box
          aria-label='avatar-selector'
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap'
          }}
        >
          {filteredAvatar().map(({ id, value }) => (
            <Box
              key={id}
              role='button'
              component='span'
              onClick={setPicture(id)}
              sx={{
                transition: 'all 0.2s ease-in-out',
                height: 70,
                width: 70,
                borderRadius: '50%',
                boxShadow:
                  avatar.pic === id
                    ? selectedShadow
                    : '0px 0px 0px 0px transparent',
                overflow: 'hidden',
                display: 'flex',
                justifiContent: 'center',
                alignItem: 'center',
                bgcolor: 'action.selected'
              }}
            >
              <Box
                component='img'
                src={value}
                sx={{ transform: 'scale(1.2)' }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}
