import { gql } from '@apollo/client'

const ADD_USER = gql`
  mutation addUser(
    $role: String!
    $country: String!
    $name: String!
    $public_name: String!
    $picture: ProfilePictureInput
    $email: String!
    $phone: String
    $about_us: String
    $company_data: CompanyInput
    $plan: PlanInput
    $settings: SettingInput
    $uid: String!
  ) {
    addUser(
      role: $role
      country: $country
      name: $name
      public_name: $public_name
      picture: $picture
      email: $email
      phone: $phone
      about_us: $about_us
      company_data: $company_data
      plan: $plan
      settings: $settings
      uid: $uid
    ) {
      _id
    }
  }
`

const UPDATE_USER = gql`
  mutation updateUser(
    $user_id: String!
    $picture: ProfilePictureInput
    $phone: String
    $public_name: String
    $email: String
    $name: String
    $last_mod: String
    $categories: [String]
    $bio: String
    $legal_form: String
    $nif: String
    $postal_code: String
    $country: String
    $city: String
    $province: String
    $password: String
  ) {
    updateUser(
      user_id: $user_id
      picture: $picture
      phone: $phone
      public_name: $public_name
      email: $email
      name: $name
      last_mod: $last_mod
      categories: $categories
      bio: $bio
      legal_form: $legal_form
      nif: $nif
      postal_code: $postal_code
      country: $country
      city: $city
      province: $province
      password: $password
    ) {
      email
      public_name
      _id
    }
  }
`

const DELETE_USER = gql`
  mutation deleteUser($user_id: String!, $token: String!) {
    deleteUser(user_id: $user_id, token: $token) {
      role
    }
  }
`

const LOGIN_USER = gql`
  mutation LoginUser($token: String!, $email: String!) {
    loginUser(token: $token, email: $email) {
      _id
      email
      phone
      role
      public_name
      country
      name
    }
  }
`

const CREATE_AN_OFFER = gql`
  mutation CreateAnOffer(
    $jobId: String!
    $companyId: String!
    $particularId: String!
    $rateType: Int!
    $amount: Float!
    $startDate: String!
    $endDate: String
    $content: MessageContentInput!
  ) {
    createAnOffer(
      job_id: $jobId
      company_id: $companyId
      user_id: $particularId
      rate_type: $rateType
      amount: $amount
      start_date: $startDate
      end_date: $endDate
      content: $content
    ) {
      _id
    }
  }
`

const CANCEL_OFFER = gql`
  mutation CancelOffer(
    $offerId: String!
    $state: OfferState = cancelled
    $jobId: String!
    $offer: String!
    $userId: String!
  ) {
    updateOffer(f__id: $offerId, state: $state) {
      state
    }
    deleteChat(job_id: $jobId, offer: $offer, user_id: $userId) {
      _id
    }
  }
`

const ADD_RATING = gql`
  mutation AddRating(
    $companyId: String!
    $ratings: [Int]!
    $comment: String!
    $userId: String!
  ) {
    addRating(
      company_id: $companyId
      ratings: $ratings
      comment: $comment
      user_id: $userId
    ) {
      ratings
      comment
      date
      user_id
    }
  }
`

const EDIT_RATING = gql`
  mutation EditRating(
    $companyId: String!
    $ratings: [Int]!
    $comment: String!
    $userId: String!
    $date: String!
  ) {
    editRating(
      company_id: $companyId
      ratings: $ratings
      comment: $comment
      user_id: $userId
      date: $date
    ) {
      ratings
      comment
      date
      last_mod
      user_id
    }
  }
`

const SENT_PROPOSAL = gql`
  mutation SendProposal(
    $jobId: String!
    $companyId: String!
    $userId: String!
  ) {
    sendProposal(job_id: $jobId, company_id: $companyId, user_id: $userId) {
      state
      _id
    }
  }
`

const ADD_POST = gql`
  mutation AddPost(
    $userId: String!
    $title: String!
    $category: String
    $country: String
    $province: String
    $postalCode: String
    $city: String
    $budget: Float
    $startDate: String
    $description: String
    $state: JobState!
    $pictures: [FileInput]
    $questions: [QuestionInput]
    $chats: [ChatInput]
    $postDate: String
  ) {
    addJob(
      user_id: $userId
      title: $title
      category: $category
      country: $country
      province: $province
      postal_code: $postalCode
      city: $city
      budget: $budget
      start_date: $startDate
      description: $description
      state: $state
      pictures: $pictures
      questions: $questions
      chats: $chats
      post_date: $postDate
    ) {
      _id
      title
    }
  }
`

const UPDATE_POST = gql`
  mutation UpdateJob(
    $postId: String!
    $title: String
    $category: String
    $country: String
    $province: String
    $postalCode: String
    $city: String
    $budget: Float
    $startDate: String
    $description: String
    $state: JobState
    $pictures: [FileInput]
    $questions: [QuestionInput]
    $postDate: String
    $lastMod: String
  ) {
    updateJob(
      job_id: $postId
      title: $title
      category: $category
      country: $country
      province: $province
      postal_code: $postalCode
      city: $city
      budget: $budget
      start_date: $startDate
      description: $description
      state: $state
      pictures: $pictures
      questions: $questions
      post_date: $postDate
      last_mod: $lastMod
    ) {
      _id
      title
      state
    }
  }
`

const DELETE_POST = gql`
  mutation DeletePost($id: String!) {
    deleteJob(job_id: $id) {
      _id
    }
  }
`

const UPDATE_PLAN = gql`
  mutation UpdatePlan(
    $user_id: String!
    $trial: Boolean
    $customer_id: String
    $payment_method_id: String
    $plan_id: String
    $cancel_at: String
  ) {
    updatePlan(
      user_id: $user_id
      trial: $trial
      customer_id: $customer_id
      payment_method_id: $payment_method_id
      plan_id: $plan_id
      cancel_at: $cancel_at
    ) {
      _id
    }
  }
`

const ADD_MESSAGE = gql`
  mutation AddMessage(
    $jobId: String!
    $chatId: String!
    $content: MessageContentInput!
    $from: String!
  ) {
    addMessage(
      job_id: $jobId
      chat_id: $chatId
      content: $content
      from: $from
    ) {
      _id
      from
      content {
        type
        name
        value
      }
      date
      state
    }
  }
`

const ACCEPT_OFFER = gql`
  mutation AcceptOffer(
    $jobId: String!
    $offerId: String!
    $state: JobState!
    $userId: String!
    $trial: Boolean
  ) {
    rejectOffers(job_id: $jobId, offer_id: $offerId) {
      company_id
    }
    updateJobState(job_id: $jobId, state: $state, company_id: $userId) {
      assigned_to
    }
    updatePlan(user_id: $userId, trial: $trial) {
      _id
    }
  }
`

const MARK_PROPOSAL_AS_DELIVERED = gql`
  mutation MarkProposalAsDelivered($jobId: String!, $chatId: String!) {
    markProposalAsDelivered(job_id: $jobId, chat_id: $chatId) {
      _id
    }
  }
`

const ADD_CONTACT = gql`
  mutation AddContact(
    $user_id: String!
    $message: String!
    $category: String!
  ) {
    addContact(user_id: $user_id, message: $message, category: $category) {
      _id
    }
  }
`

const UPDATE_POST_STATE = gql`
  mutation updateJobState($job_id: String!, $state: JobState) {
    updateJobState(job_id: $job_id, state: $state) {
      state
    }
  }
`

const ADD_SERVICES_SUBMIT_STATISTICS = gql`
  mutation ServicesStatistics($type: String!, $data: Map) {
    createInteraction(type: $type, data: $data) {
      timestamp
    }
  }
`

export {
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_PLAN,
  UPDATE_POST_STATE,
  DELETE_POST,
  ADD_MESSAGE,
  ADD_CONTACT,
  LOGIN_USER,
  CREATE_AN_OFFER,
  CANCEL_OFFER,
  ADD_RATING,
  EDIT_RATING,
  SENT_PROPOSAL,
  ADD_POST,
  UPDATE_POST,
  ACCEPT_OFFER,
  MARK_PROPOSAL_AS_DELIVERED,
  ADD_SERVICES_SUBMIT_STATISTICS
}
