import { useMutation, useQuery } from '@apollo/client'
import { ADD_POST, DELETE_POST, UPDATE_POST } from 'apis/mutations'
import { GET_POST_BY_ID } from 'apis/querys'
import { useSnackbar } from 'context/SnackBarContext'
import { getRandomId } from 'helpers'
import { useFirebase, useI18N } from 'hooks'
import { useState } from 'react'

const createFile = (type, name, url) => {
  return { type, name, url }
}

export default function usePost({ id } = {}) {
  const { t } = useI18N()
  const { showSnackbar } = useSnackbar()
  const { uploadFile, storageJobsPicturesRef, deleteFolder, deleteFile } =
    useFirebase()
  // QUERIES
  const { data, loading } = useQuery(GET_POST_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id
    },
    skip: !id
  })

  // MUTATIONS
  const [addNewPost] = useMutation(ADD_POST)
  const [updatePost] = useMutation(UPDATE_POST)
  const [removePost] = useMutation(DELETE_POST)
  const [adding, setAdding] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [posted, setPosted] = useState(false)

  const savePictures = async ({ postId, pictures = [] }) => {
    const prevPictures = pictures.filter((pic) => !pic?.file)
    const newPictures = pictures.filter((pic) => pic.file)

    if (newPictures.length > 0) {
      const attachmentFiles = newPictures.map((pic) => pic.file)
      const promises = attachmentFiles.map((file) => {
        const randomName = getRandomId(24)
        return uploadFile({
          storageRef: storageJobsPicturesRef(postId),
          file,
          fileName: randomName
        }).then((pictureUrl) => {
          const pictureData = createFile(file.type, randomName, pictureUrl.data)
          return pictureData
        })
      })
      const newPicturesData = await Promise.all(promises)
      const updatedPictures = prevPictures.concat(newPicturesData)

      await updatePost({
        variables: {
          postId,
          pictures: updatedPictures
        }
      })
    }
  }

  const removePictures = async ({
    postId,
    prevPictures = [],
    removedPictures = []
  }) => {
    const removePromises = removedPictures.map((pic) => {
      return deleteFile({
        storageRef: storageJobsPicturesRef(postId),
        fileName: pic
      })
    })
    const newPictures = prevPictures.filter(
      (pic) => !removedPictures.includes(pic.name) && !pic?.file
    )
    console.log({ newPictures, prevPictures, removedPictures })

    const updatePostPromise = updatePost({
      variables: {
        postId,
        pictures: newPictures
      }
    })

    return await Promise.all(removePromises, updatePostPromise)
  }

  const add = async ({ variables, pictures }) => {
    setAdding(true)
    try {
      const post = await addNewPost({ variables })
      const postId = post.data.addJob._id
      await savePictures({ postId, pictures })
      setPosted(true)
    } catch (error) {
      //   console.log({ error })
      setAdding(false)
      showSnackbar({
        message: t('error.post.adding'),
        severity: 'error'
      })
    }
  }

  const update = async ({ postId, values }) => {
    try {
      setUpdating(true)
      await updatePost({
        variables: {
          postId,
          ...values
        }
      })
    } catch (error) {
      console.log({ error })
      throw error
    } finally {
      setUpdating(false)
    }
  }

  const remove = async ({ id }) => {
    try {
      setRemoving(true)
      const removePostPromise = removePost({
        variables: {
          id
        }
      })
      const removePostPicturesPromise = deleteFolder({
        storageRef: storageJobsPicturesRef(id)
      })
      await Promise.all([removePostPromise, removePostPicturesPromise])
      showSnackbar({
        message: t('jobDeletedSuccesfully'),
        severity: 'success'
      })
    } catch (error) {
      showSnackbar({
        message: t('error.post.deleting'),
        severity: 'error'
      })
      throw error
    } finally {
      setRemoving(false)
    }
  }

  return {
    data: data?.getJob,
    loading,
    adding,
    updating,
    removing,
    posted,
    add,
    update,
    remove,
    savePictures,
    removePictures
  }
}
