import { profilePictures, profilePicturesBg } from 'constants/ProfilePictures'

export default function getUserPicture(image) {
  const profileImage = image
    ? image?.url || profilePictures.find((item) => item.id === image.pic)?.value
    : ''
  const bgImage = image
    ? image?.url
      ? 'none'
      : profilePicturesBg.find((item) => item.id === image.bg)?.value
    : ''

  return { profileImage, bgImage }
}
