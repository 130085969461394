import Construction from 'assets/categories/construction.png'
import Mechanics from 'assets/categories/mechanics.png'
import Care from 'assets/categories/care.png'
import Leisure from 'assets/categories/leisure.png'
import Technology from 'assets/categories/technology.png'
import Others from 'assets/categories/others.png'
import texts from 'constants/texts'

const category = (id, name, color, image, slug, subCategories) => ({
  id,
  name,
  color,
  image,
  slug,
  subCategories
})

const subcategory = (id, name) => ({
  id,
  name
})

const constructionSubcategories = [
  subcategory('demolition', texts().demolition),
  subcategory('verticalsJobs', texts().verticalsJobs),
  subcategory('architecture', texts().architecture),
  subcategory('bathRenovations', texts().bathRenovations),
  subcategory('roofers', texts().roofers),
  subcategory('welding', texts().welding),
  subcategory('reforms', texts().reforms),
  subcategory('electrician', texts().electrician),
  subcategory('windows', texts().windows),
  subcategory('garage', texts().garage),
  subcategory('furnishPicker', texts().furnishPicker),
  subcategory('decoration', texts().decoration),
  subcategory('glass', texts().glass),
  subcategory('fireplace', texts().fireplace),
  subcategory('parquet', texts().parquet),
  subcategory('isolation', texts().isolation),
  subcategory('kitchen', texts().kitchen),
  subcategory('painting', texts().painting),
  subcategory('builder', texts().builder),
  subcategory('interiorDesign', texts().interiorDesign),
  subcategory('metal', texts().metal),
  subcategory('slabs', texts().slabs),
  subcategory('upholstery', texts().upholstery),
  subcategory('plumbing', texts().plumbing),
  subcategory('carpenter', texts().carpenter),
  subcategory('locksmith', texts().locksmith),
  subcategory('gardener', texts().gardener),
  subcategory('earthmoving', texts().earthmoving),
  subcategory('greenhouses', texts().greenhouses),
  subcategory('fences', texts().fences),
  subcategory('remodeling', texts().remodeling),
  subcategory('technician', texts().technician),
  subcategory('alarms', texts().alarms),
  subcategory('housekeeping', texts().housekeeping),
  subcategory('blinds', texts().blinds),
  subcategory('confort', texts().confort),
  subcategory('climate', texts().climate),
  subcategory('cleaning', texts().cleaning),
  subcategory('applianceTechnician', texts().applianceTechnician),
  subcategory('moving', texts().moving),
  subcategory('scaffolding', texts().scaffolding),
  subcategory('paving', texts().paving),
  subcategory('pestControl', texts().pestControl),
  subcategory('constructionMaterials', texts().constructionMaterials)
]

const mechanicSubcategories = [
  subcategory('mechanics', texts().mechanics),
  subcategory('crane', texts().crane),
  subcategory('carWash', texts().carWash),
  subcategory('electricCars', texts().electricCars),
  subcategory('wheelbarrows', texts().wheelbarrows),
  subcategory('chargingPoints', texts().chargingPoints),
  subcategory('smallTransport', texts().smallTransport),
  subcategory('largeTransport', texts().largeTransport),
  subcategory('carPainting', texts().carPainting),
  subcategory('carService', texts().carService),
  subcategory('fineTunning', texts().fineTunning),
  subcategory('motorbike', texts().motorbike),
  subcategory('tuning', texts().tuning)
]

const careSubcategories = [
  subcategory('tattooArtist', texts().tattooArtist),
  subcategory('dentist', texts().dentist),
  subcategory('massageTherapist', texts().massageTherapist),
  subcategory('osteopathy', texts().osteopathy),
  subcategory('hairdresser', texts().hairdresser),
  subcategory('beautician', texts().beautician),
  subcategory('rehabilitation', texts().rehabilitation),
  subcategory('physiotherapist', texts().physiotherapist),
  subcategory('makeupArtist', texts().makeupArtist),
  subcategory('acupuncture', texts().acupuncture),
  subcategory('motivationalCoach', texts().motivationalCoach),
  subcategory('personalTrainer', texts().personalTrainer),
  subcategory('zumbaInstructor', texts().zumbaInstructor),
  subcategory('pilatesInstructor', texts().pilatesInstructor)
]

const technologySubcategories = [
  subcategory('programmer', texts().programmer),
  subcategory('webProgrammer', texts().webProgrammer),
  subcategory('photographer', texts().photographer),
  subcategory('webDesigner', texts().webDesigner),
  subcategory('multimediaInstaller', texts().multimediaInstaller),
  subcategory('applicationProgrammer', texts().applicationProgrammer),
  subcategory('graphicDesigner', texts().graphicDesigner),
  subcategory('computerTechnician', texts().computerTechnician),
  subcategory('marketingAndCommunication', texts().marketingAndCommunication),
  subcategory('printing', texts().printing),
  subcategory('analyst', texts().analyst),
  subcategory('database', texts().database),
  subcategory('machineLearning', texts().machineLearning),
  subcategory('productCustomization', texts().productCustomization),
  subcategory('merchandising', texts().merchandising),
  subcategory('artificialIntelligence', texts().artificialIntelligence),
  subcategory('cyberSecurity', texts().cyberSecurity),
  subcategory('itSystemsTechnician', texts().itSystemsTechnician),
  subcategory('itSupportTechnician', texts().itSupportTechnician)
]

const leisureSubcategories = [
  subcategory('cook', texts().cook),
  subcategory('catering', texts().catering),
  subcategory('animator', texts().animator),
  subcategory('socioculturalAnimator', texts().socioculturalAnimator),
  subcategory('functionalDiversityMonitor', texts().functionalDiversityMonitor),
  subcategory('childrenMonitor', texts().childrenMonitor),
  subcategory('freeTimeMonitor', texts().freeTimeMonitor),
  subcategory('waiter', texts().waiter),
  subcategory('soundTechnician', texts().soundTechnician),
  subcategory('dj', texts().dj),
  subcategory('danceInstructor', texts().danceInstructor)
]

const otherSubcategories = [
  subcategory('model', texts().model),
  subcategory('concierge', texts().concierge),
  subcategory('administrative', texts().administrative),
  subcategory('receptionist', texts().receptionist),
  subcategory('lifeGuard', texts().lifeGuard),
  subcategory('commercial', texts().commercial),
  subcategory('teacher', texts().teacher),
  subcategory('privateTeacher', texts().privateTeacher),
  subcategory('pedagogue', texts().pedagogue),
  subcategory('seamstress', texts().seamstress),
  subcategory('educationalAssistant', texts().educationalAssistant),
  subcategory('animalCaretaker', texts().animalCaretaker),
  subcategory('parcelDeliveryMan', texts().parcelDeliveryMan),
  subcategory('usedOilCollection', texts().usedOilCollection),
  subcategory('socialEducator', texts().socialEducator),
  subcategory('otherJob', texts().otherJob)
]

export const categories = [
  category(
    'construction',
    texts().construction,
    'F25B5B',
    Construction,
    'construction&home',
    constructionSubcategories
  ),
  category(
    'mechanic',
    texts().mechanic,
    '00B6B0',
    Mechanics,
    'mechanics&transportation',
    mechanicSubcategories
  ),
  category(
    'care',
    texts().care,
    '818CF8',
    Care,
    'care&aesthetics',
    careSubcategories
  ),
  category(
    'leisure',
    texts().leisure,
    '3194C6',
    Leisure,
    'leisure&gastronomy',
    leisureSubcategories
  ),
  category(
    'technology',
    texts().technology,
    'FF9013',
    Technology,
    'technology&marketing',
    technologySubcategories
  ),
  category(
    'others',
    texts().others,
    '6C7B86',
    Others,
    'others',
    otherSubcategories
  )
]

export const categoriesIds = categories.map((categ) => categ.id)

export const subcategoriesIds = categories.reduce((prev, current) => {
  const subcategories = current.subCategories.map(
    (subcategory) => subcategory.id
  )
  const total = prev.concat(subcategories)
  return total
}, [])

export const subcategoriesParents = (subcategories = []) => {
  const result = []
  const onlyIds = categories.map((c) => ({
    parent: c.id,
    children: c.subCategories.map((sb) => sb.id)
  }))

  subcategories.forEach((subc) => {
    const category =
      onlyIds.find((item) => item.children.includes(subc))?.parent || 'others'
    const index = result.indexOf(category)
    if (index === -1) result.push(category)
  })

  return result
}
