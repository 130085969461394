import { Card, CardActionArea, CardHeader } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme, selected, href, onClick }) => ({
    transition: 'all 0.2s ease-in-out',
    position: 'relative',
    borderRadius: 16,
    width: '100%',
    backgroundColor: theme.palette.background.card,
    boxShadow: selected
      ? `0px 0px 0px 3px ${alpha(
          theme.palette.primary.main,
          0.8
        )}, 0px 0px 12px ${alpha(theme.palette.primary.main, 0.8)}`
      : `0px 0px 0px 1px ${theme.palette.background.contrast}`,
    '&:hover': {
      ...((href || onClick) && {
        boxShadow: selected
          ? `0px 0px 0px 3px ${alpha(
              theme.palette.primary.main,
              0.8
            )}, 0px 0px 14px ${alpha(theme.palette.primary.main, 0.7)}`
          : `0px 0px 0px 1px ${theme.palette.background.contrast}, ${theme.shadows[4]}`,
        transform: 'translateY(-1px)'
      })
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 16
    },
    '& .MuiCardActionArea-focusHighlight': {
      backgroundColor: 'inherit'
    }
  })
)

export default function CustomCard({
  children,
  href,
  onClick,
  selected,
  title,
  headerProps,
  ...other
}) {
  const history = useHistory()

  const defaultOnClick = () => {
    history.push(href)
  }

  return (
    <StyledCard selected={selected} {...other}>
      {title && (
        <CardHeader
          title={title}
          titleTypographyProps={{ color: 'primary', fontSize: 14 }}
          sx={{ pb: 0 }}
          {...headerProps}
        />
      )}
      {href || onClick ? (
        <CardActionArea onClick={href ? defaultOnClick : onClick}>
          {children}
        </CardActionArea>
      ) : (
        children
      )}
    </StyledCard>
  )
}
