import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Eye, EyeSlash } from 'iconsax-react'

/// ////////COMPONENT PROPS
PasswordInput.propTypes = {
  error: PropTypes.any,
  helperText: PropTypes.string,
  bottomspace: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string
}

// Other useful props
// - margin -> normal, none, dense

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 8
  }
}))

function PasswordInput({ error = null, helperText, onChange, id, ...other }) {
  const [showPass, setShowPass] = useState(false)

  const handleClickShowPassword = () => {
    setShowPass(!showPass)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    onChange({ [name]: value })
  }

  return (
    <StyledInput
      id={id}
      name={id}
      variant='outlined'
      fullWidth
      required
      helperText={error || helperText}
      type={showPass ? 'text' : 'password'}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPass ? (
                <EyeSlash size={24} variant='Outline' />
              ) : (
                <Eye size={24} variant='Outline' />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...(error && { error: true })}
      {...other}
    />
  )
}

export default React.memo(PasswordInput)
