import { cloneElement, useState } from 'react'
import { Typography, Box, ButtonBase } from '@mui/material'
import { motion } from 'framer-motion'

const variants = {
  active: {
    width: '100%',
    transition: { duration: 0.35 }
  },
  inactive: {
    width: '0%',
    transition: { duration: 0.35 }
  }
}

const iconAnimation = {
  active: {
    rotate: [30, -20, 0],
    transition: { type: 'spring', stiffness: 100 }
  },
  inactive: {
    rotate: 0
  }
}

const textAnimation = {
  active: {
    opacity: 1,
    x: 0,
    display: 'block',
    transition: { duration: 0.35 }
  },
  inactive: {
    opacity: 0,
    x: -30,
    transition: { duration: 0.35 },
    transitionEnd: {
      display: 'none'
    }
  }
}

export default function IconsSwitch({ id, items, defaultSelected, onChange }) {
  const [selected, setSelected] = useState(defaultSelected || items[0].id)
  const longestItemLabel = items.slice(0).sort(function (a, b) {
    return b.label.length - a.label.length
  })[0]

  const handleChange = (newValue) => {
    setSelected(newValue)
    if (id) {
      return onChange({ [id]: newValue })
    }
    onChange && onChange(newValue)
  }

  return (
    <Box
      sx={{
        width: `calc(100% - (100% - (${
          longestItemLabel.label.length * 12
        }px + ${10 + 4 * (items.length - 1) + 40 * items.length}px)))`,
        display: 'inline-flex',
        alignItems: 'center',
        gap: 0.5,
        borderRadius: 100,
        bgcolor: 'background.paper',
        p: 0.5,
        border: (theme) => `1px solid ${theme.palette.background.contrast}`
      }}
    >
      {items.map(({ id, label, icon }) => {
        const active = selected === id
        return (
          <ButtonBase
            key={id}
            component={motion.button}
            initial={false}
            variants={variants}
            animate={active ? 'active' : 'inactive'}
            onClick={() => handleChange(id)}
            disableRipple
            sx={{
              transition: 'color 350ms ease, background 350ms ease',
              justifyContent: 'flex-start',
              minWidth: 40,
              gap: 1,
              borderRadius: 100,
              p: 1,
              cursor: 'pointer',
              overflowX: 'hidden',
              color: active ? 'white' : 'text.primary',
              bgcolor: active ? 'primary.main' : 'transparent'
            }}
          >
            <Box
              component={motion.div}
              initial={false}
              variants={iconAnimation}
              animate={active ? 'active' : 'inactive'}
              sx={{ height: 24, width: 24 }}
            >
              {cloneElement(icon, { size: '24' })}
            </Box>
            <Typography
              component={motion.p}
              initial={false}
              variants={textAnimation}
              animate={active ? 'active' : 'inactive'}
              sx={{ whiteSpace: 'nowrap', fontSize: '1rem' }}
            >
              {label}
            </Typography>
          </ButtonBase>
        )
      })}
    </Box>
  )
}
