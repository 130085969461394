import { prodKeys, devKeys } from 'apis/keys'
import texts from './texts'

export const provincesList = (country) => {
  switch (country) {
    case 'ES':
      return spain
    case 'CH':
      return switzerland
    default:
      return []
  }
}

const uri =
  process.env.NODE_ENV === 'development'
    ? devKeys.backendURI
    : prodKeys.backendURI
export const getCityByPostalCode = async (postalCode, country) => {
  try {
    const response = await fetch(
      `${uri}/api/geodata?code=${postalCode}&country=${country}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const location = await response.json()
    return location
  } catch (e) {
    console.log({ error: e })
    return null
  }
}

const createCountry = (code, name) => {
  return { code, name }
}

export const countryCodes = [
  createCountry('ES', texts().esp),
  createCountry('CH', texts().ch)
]

export const phonePrefix = {
  ES: '+34',
  CH: '+41'
}

const spain = [
  texts().all,
  'Álava',
  'Albacete',
  'Alicante',
  'Almería',
  'Asturias',
  'Ávila',
  'Badajoz',
  'Barcelona',
  'Burgos',
  'Cáceres',
  'Cádiz',
  'Cantabria',
  'Castellón',
  'Ciudad Real',
  'Córdoba',
  'La Coruña',
  'Cuenca',
  'Gerona',
  'Granada',
  'Guadalajara',
  'Guipúzcoa',
  'Huelva',
  'Huesca',
  'Islas Baleares',
  'Jaén',
  'León',
  'Lérida',
  'Lugo',
  'Madrid',
  'Málaga',
  'Murcia',
  'Navarra',
  'Orense',
  'Palencia',
  'Las Palmas',
  'Pontevedra',
  'La Rioja',
  'Salamanca',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Santa Cruz de Tenerife',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Vizcaya',
  'Zamora',
  'Zaragoza'
]

const switzerland = [
  texts().all,
  'Aargau',
  'Appenzell Ausserrhoden',
  'Appenzell Innerrhoden',
  'Basel-Stadt',
  'Basel-Landschaft',
  'Bern',
  'Freiburg',
  'Genf',
  'Glarus',
  'Graubünden',
  'Jura',
  'Luzern',
  'Neuenburg',
  'Nidwalden',
  'Obwalden',
  'St. Gallen',
  'Schaffhausen',
  'Schwyz',
  'Solothurn',
  'Thurgau',
  'Ticino',
  'Uri',
  'Wallis',
  'Vaud',
  'Zug',
  'Zürich'
]
