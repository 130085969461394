import { useContext, useState } from 'react'
import { auth } from 'apis/firebase'
import { useMutation } from '@apollo/client'
import { LOGIN_USER } from 'apis/mutations'
import { UserContext } from 'context/UserContext'

export default function useLogin() {
  const [loading, setLoading] = useState(false)
  const [logIn] = useMutation(LOGIN_USER)
  const { setUser } = useContext(UserContext)

  const signIn = async ({ email, password, onError, onComplete }) => {
    setLoading(true)
    try {
      const userCredentials = await auth.signInWithEmailAndPassword(
        email.toLowerCase(),
        password
      )
      const userData = await logIn({
        variables: {
          token: userCredentials.user.uid,
          email: userCredentials.user.email
        }
      })

      if (userData.data.loginUser) {
        const { role, _id, email, public_name, name } = userData.data.loginUser
        const userObj = {
          id: _id,
          role,
          email,
          name: role === 'user' ? public_name : name
        }

        setUser({ ...userObj, uid: userCredentials.user.uid })
        localStorage.setItem('user', JSON.stringify(userObj))
        onComplete ? onComplete() : setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      const errorCode = error.code
      // console.log(errorCode)
      onError && onError(errorCode)
    }
  }

  return {
    signIn,
    loading
  }
}
