import { Box, CardContent, Typography } from '@mui/material'
import {
  CustomCard,
  ScrollableContainer,
  Spacer,
  UpdateItem,
  UpdateTag
} from 'components'
import { useI18N } from 'hooks'
import changelog from '../changelog.json'

const isBeta = (version) => {
  return version.substring(0, 1) === '0'
}

export default function Updates() {
  const { t } = useI18N()

  return (
    <ScrollableContainer title={t('updates')} appBarProps={{ hasBack: true }}>
      <Spacer m={2}>
        {changelog.map((change) => (
          <CustomCard key={change.v} sx={{ mb: 2 }}>
            <CardContent>
              <Typography fontWeight={600} fontSize={18} mb={2}>
                {`v${change.v} ${isBeta(change.v) ? 'Beta' : ''}`}
              </Typography>
              {change.new.length > 0 && (
                <Box aria-label='news-container' sx={{ mb: 2 }}>
                  <UpdateTag type='new' />
                  {change.new.map((item, index) => (
                    <UpdateItem key={`new-${index}`}>{item}</UpdateItem>
                  ))}
                </Box>
              )}
              {change.fix.length > 0 && (
                <Box aria-label='news-container'>
                  <UpdateTag type='fix' />
                  {change.fix.map((item, index) => (
                    <UpdateItem key={`fix-${index}`}>{item}</UpdateItem>
                  ))}
                </Box>
              )}
            </CardContent>
          </CustomCard>
        ))}
      </Spacer>
    </ScrollableContainer>
  )
}
