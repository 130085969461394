import React from 'react'
import { SvgIcon } from '@mui/material'

export function ArrowBack(props) {
  return (
    <SvgIcon {...props}>
      <path d='M16.88 2.88c-.49-.49-1.28-.49-1.77 0L6.7 11.29c-.39.39-.39 1.02 0 1.41l8.41 8.41c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.54 12l7.35-7.35c.48-.49.48-1.28-.01-1.77z' />
    </SvgIcon>
  )
}

export function ArrowFoward(props) {
  return (
    <SvgIcon {...props}>
      <path d='M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z' />
    </SvgIcon>
  )
}

export function Download(props) {
  return (
    <SvgIcon {...props}>
      <path d='M16.5026 8.84492C16.8624 8.57448 17.4022 8.57448 17.762 8.93507C18.1218 9.29565 18.1218 9.92667 17.8519 10.1971L12.7246 14.8847C12.6796 14.8847 12.6571 14.9072 12.6346 14.9298C12.6121 14.9523 12.5897 14.9748 12.5447 14.9748C12.5447 15.065 12.4547 15.065 12.4547 15.065C12.3648 15.1551 12.1849 15.1551 12.0949 15.1551C11.915 15.1551 11.825 15.1551 11.7351 15.065C11.6901 15.0199 11.6451 14.9974 11.6002 14.9748C11.5552 14.9523 11.5102 14.9298 11.4652 14.8847L6.33789 10.1971C5.88812 9.92667 5.88812 9.29565 6.24794 8.93507C6.60775 8.57448 7.14747 8.57448 7.50728 8.84492L10.5657 11.7296C10.7456 11.9099 11.1054 11.7296 11.1054 11.4591V3.8869C11.1054 3.34603 11.4652 2.98544 12.005 2.98544C12.5447 2.98544 12.9045 3.34603 12.9045 3.8869V11.369C12.9045 11.6394 13.2643 11.8197 13.4442 11.6394L16.5026 8.84492ZM3.9989 21.0146H20.1006C20.5503 21.0146 21.0001 20.654 21.0001 20.1131V14.1635C21.0001 13.6226 20.6403 13.262 20.1006 13.262C19.5608 13.262 19.201 13.6226 19.201 14.1635V19.2117H4.89843V14.1635C4.89843 13.6226 4.53862 13.262 3.9989 13.262C3.45918 13.262 3.09937 13.6226 3.09937 14.1635V20.1131C3.09937 20.654 3.45918 21.0146 3.9989 21.0146Z' />
    </SvgIcon>
  )
}

export function AttachFile(props) {
  return (
    <SvgIcon {...props}>
      <path d='M13.3379 5.45369L18.6279 14.6162C19.6729 16.4262 19.2779 18.802 17.5766 20.0152C15.6218 21.4094 12.9547 20.79 11.7847 18.7635L5.60471 8.05939C4.94971 6.92489 5.16877 5.42432 6.2296 4.66174C7.45364 3.78184 9.12983 4.16509 9.86483 5.43814L15.1148 14.5314C15.3898 15.0077 15.2251 15.6224 14.7488 15.8974C14.2725 16.1724 13.6578 16.0077 13.3828 15.5314L9.00778 7.95369C8.80278 7.59862 8.33833 7.47417 7.98326 7.67917C7.62819 7.88417 7.50375 8.34862 7.70875 8.70369L12.0137 16.1602C12.6687 17.2947 14.0778 17.8552 15.2686 17.3178C16.6427 16.6977 17.1489 15.0545 16.4139 13.7814L11.2489 4.83537C10.2039 3.02538 7.94885 2.17957 6.04752 3.04636C3.87138 4.03718 3.06567 6.66165 4.23567 8.68814L10.3707 19.3143C11.8057 21.7998 14.9093 22.9755 17.5212 21.7793C20.5204 20.3941 21.6219 16.8021 20.0119 14.0135L14.6369 4.70369C14.4319 4.34862 13.9675 4.22417 13.6124 4.42917C13.2574 4.63417 13.1329 5.09862 13.3379 5.45369Z' />
    </SvgIcon>
  )
}

export function SendMessage(props) {
  return (
    <SvgIcon {...props}>
      <path d='M3.4 20.4L20.9 12.9C21.7 12.5 21.7 11.4 20.9 11.1L3.4 3.59999C2.7 3.29999 2 3.79999 2 4.49999V9.09999C2 9.59999 2.4 9.99999 2.9 10.1L7.5 10.7C9 10.9 9 13.1 7.5 13.3L2.9 13.9C2.4 14 2 14.4 2 14.9V19.5C2 20.2 2.7 20.7 3.4 20.4Z' />
    </SvgIcon>
  )
}

export function Edit(props) {
  return (
    <SvgIcon {...props}>
      <path d='M3 17.6V20C3 20.6 3.4 21 3.9 21H6.3C6.5 21 6.8 20.9 7 20.7L17.1 10.6C17.5 10.2 17.5 9.6 17.1 9.3L14.7 6.9C14.3 6.5 13.7 6.5 13.4 6.9L3.3 17C3.1 17.2 3 17.4 3 17.6ZM5.9 19H5V18.1L14.1 9L15 9.9L5.9 19ZM20.6 5.5L18.4 3.3C18.2 3.1 17.9 3 17.7 3C17.5 3 17.1 3.1 17 3.3L15.8 4.5C15.4 4.9 15.4 5.5 15.8 5.8L18.2 8.2C18.6 8.6 19.2 8.6 19.5 8.2L20.5 7.2C21 6.7 21 6 20.6 5.5Z' />
    </SvgIcon>
  )
}

export function Star({ filled, size, ...props }) {
  return (
    <SvgIcon
      viewBox='0 0 24 24'
      sx={{ width: size || 24, height: size || 24 }}
      {...props}
    >
      {filled ? (
        <path
          d='m12.962 4.596 1.942 3.917a1 1 0 0 0 .755.546l4.483.648a.999.999 0 0 1 .555 1.705l-3.24 3.145a.998.998 0 0 0-.29.886l.741 4.304c.15.873-.767 1.539-1.553 1.128l-3.888-2.035a1.002 1.002 0 0 0-.93 0L7.65 20.873c-.787.412-1.707-.255-1.556-1.13l.741-4.3a.998.998 0 0 0-.289-.886l-3.24-3.145a.998.998 0 0 1 .554-1.705l4.483-.648c.326-.047.608-.251.755-.546l1.942-3.917c.391-.795 1.527-.795 1.921 0Z'
          fill='#F7C056'
        />
      ) : (
        <path
          d='M16.8539 21.75C16.5649 21.75 16.2749 21.681 16.0059 21.54L12.118 19.505C12.044 19.466 11.9559 19.466 11.8819 19.505L7.99688 21.538C7.37788 21.862 6.64198 21.808 6.07598 21.396C5.51298 20.985 5.23496 20.304 5.35296 19.617L6.09392 15.316C6.10792 15.235 6.08088 15.153 6.02288 15.096L2.78191 11.95C2.30091 11.483 2.12997 10.796 2.33697 10.157C2.54397 9.51798 3.08588 9.06099 3.75188 8.96399L8.23394 8.31598C8.31594 8.30398 8.38593 8.25299 8.42193 8.17999L10.3649 4.26202C10.6749 3.63702 11.301 3.24902 11.999 3.24902C12.697 3.24902 13.323 3.63702 13.633 4.26202L15.5749 8.17902C15.6119 8.25302 15.682 8.30398 15.764 8.31598L20.2459 8.96399C20.9119 9.05999 21.4539 9.51698 21.6609 10.157C21.8679 10.796 21.6969 11.483 21.2159 11.95L17.9759 15.095C17.9159 15.153 17.89 15.235 17.904 15.316L18.645 19.619C18.763 20.305 18.4869 20.986 17.9229 21.396C17.6049 21.631 17.2299 21.75 16.8539 21.75ZM11.9999 17.976C12.2789 17.976 12.5589 18.042 12.8129 18.175L16.701 20.21C16.856 20.292 16.9819 20.229 17.0419 20.185C17.1019 20.142 17.1969 20.043 17.1679 19.875L16.4269 15.571C16.3289 15.001 16.5189 14.421 16.9339 14.019L20.1729 10.875C20.2699 10.781 20.2539 10.675 20.2359 10.621C20.2179 10.566 20.1679 10.47 20.0329 10.45L15.551 9.802C14.98 9.72 14.487 9.36201 14.232 8.84601L12.2909 4.92999C12.2139 4.77399 12.0759 4.75098 12.0009 4.75098C11.9259 4.75098 11.788 4.77399 11.711 4.92999L9.76897 8.84698C9.51497 9.36198 9.02198 9.72 8.45098 9.802L3.96892 10.45C3.83392 10.47 3.78292 10.565 3.76592 10.621C3.74792 10.676 3.73291 10.781 3.82891 10.875L7.0689 14.02C7.4829 14.421 7.67288 15.001 7.57488 15.571L6.83391 19.872C6.80491 20.041 6.90099 20.14 6.96099 20.184C7.02299 20.228 7.14489 20.29 7.30389 20.209L11.1889 18.176C11.4409 18.043 11.7209 17.976 11.9999 17.976Z'
          fill='#C4C4C4'
        />
      )}
    </SvgIcon>
  )
}
