import { Box, Typography } from '@mui/material'

const Dialog = ({ children }) => {
  return (
    <Box
      aria-label='Dialog'
      className='scrollable'
      sx={{
        m: 2,
        py: 2,
        px: 3,
        width: '100%',
        bgcolor: 'background.paper',
        overflowY: 'auto',
        borderRadius: 4,
        maxHeight: '90%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      {children}
    </Box>
  )
}

const DialogTitle = ({ title, subtitle, children }) => {
  return (
    <Box aria-label='dialog-title-container' sx={{ flex: '0 0 auto', mb: 2 }}>
      {title && (
        <Typography fontWeight={600} fontSize='1.2rem'>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography fontSize='1rem' color='textSecondary'>
          {subtitle}
        </Typography>
      )}
      {children}
    </Box>
  )
}

const DialogContent = ({ children }) => {
  return (
    <Box aria-label='dialog-content-container' sx={{ flex: '1 1 auto', py: 1 }}>
      {children}
    </Box>
  )
}

const DialogActions = ({ column, children }) => {
  return (
    <Box
      aria-label='dialog-actions-container'
      sx={{
        flex: '0 0 auto',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: column ? 'column' : 'row',
        mt: 2,
        gap: 2
      }}
    >
      {children}
    </Box>
  )
}

Dialog.Title = DialogTitle
Dialog.Content = DialogContent
Dialog.Actions = DialogActions

export default Dialog
