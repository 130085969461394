import React, { useState } from 'react'
import { InputBase, IconButton, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Add, SearchNormal1 } from 'iconsax-react'
import Form from './Form'

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.paper,
  paddingRight: '4px',
  alignItems: 'center',
  border: `1px solid ${theme.palette.background.contrast}`
}))

function SearchBar({
  id,
  defaultValue,
  onChange,
  onClean,
  endActions,
  onEnter,
  ...other
}) {
  const [search, setSearch] = useState(defaultValue || '')

  const handleChange = (event) => {
    const { name, value } = event.target
    setSearch(value)

    if (!name) {
      return onChange && onChange(value)
    }
    onChange && onChange({ [name]: value })
  }

  const handleClean = () => {
    setSearch('')
    onClean()
  }

  // const handleKeyDown = (event) => {
  //   const { code } = event
  //   if (code === 'Enter') {
  //     onEnter && onEnter(search)
  //   }
  // }

  const handleSearch = (event) => {
    event.preventDefault()
    onEnter && onEnter(search)
  }

  return (
    <InputContainer aria-label='input-container' sx={{ width: '100%' }}>
      <IconButton
        aria-label='search'
        size='large'
        disabled
        sx={{ '&.Mui-disabled': { color: 'text.primary' } }}
      >
        <SearchNormal1 size={24} variant='Outline' />
      </IconButton>
      <Form onSubmit={handleSearch} sx={{ flex: 1 }}>
        <InputBase
          id={id}
          name={id}
          value={search}
          autoFocus={search !== ''}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'search' }}
          sx={{ ml: 1, width: '100%' }}
          // onKeyDown={handleKeyDown}
          type='search'
          {...other}
        />
      </Form>
      {onClean && (
        <IconButton
          onClick={handleClean}
          aria-label='clean'
          size='medium'
          sx={{
            transition: 'all 0.1s ease-in',
            opacity: search === '' ? 0 : 1,
            transform: search === '' ? 'scale(0)' : 'scale(1)'
          }}
        >
          <Add
            size={24}
            variant='Outline'
            style={{ transform: 'rotate(45deg)' }}
          />
        </IconButton>
      )}
      {endActions}
    </InputContainer>
  )
}

export default React.memo(SearchBar)
