import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { styled, Typography } from '@mui/material'
import { Spacer } from 'components'
import { useI18N } from 'hooks'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const CustomPicker = styled(StaticDatePicker)(({ theme }) => ({
  '& .MuiPickerStaticWrapper-content': {
    borderRadius: 16,
    boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
    '& .Mui-selected': {
      color: '#fff'
    }
  },
  '& .MuiPickersDay-root': {
    fontWeight: 500,
    '&.Mui-selected': {
      color: '#fff'
    },
    '&[aria-current=date]': {
      backgroundColor: theme.palette.action.disabledBackground,
      border: 'none'
    }
  }
}))

export default function DateSelectorPage({ date, onChange }) {
  const { t, locale } = useI18N()
  const handleDateChange = (value) => {
    const parsedValue = dayjs(value).toISOString()
    onChange({ date: parsedValue })
  }

  return (
    <>
      <Typography fontSize='1.5rem' mb={2}>
        {t('whenDoYouNeedIt')}
      </Typography>

      <Spacer y={2}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale ?? 'en'}
        >
          <CustomPicker
            displayStaticWrapperAs='desktop'
            value={dayjs(date)}
            disablePast
            minDate={dayjs(tomorrow)}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Spacer>
    </>
  )
}
