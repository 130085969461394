import { Box, Typography } from '@mui/material'
import { Button, Dialog, Modal, PasswordInput } from 'components'
import { useI18N } from 'hooks'

export default function DeleteAccountDialog({
  open,
  onChange,
  processing,
  passError,
  onClose,
  onDelete
}) {
  const { t } = useI18N()

  return (
    <Modal open={open} onClose={onClose}>
      <Dialog>
        <Dialog.Title title={t('youAreGoingToDeleteYourAccount')} />
        <Dialog.Content>
          <Box>
            <Typography style={{ marginBottom: 18 }}>
              {t('allYourDataWillBeDeleted')}
            </Typography>
            <PasswordInput
              id='password'
              onChange={onChange}
              label={t('introduceYourPassword')}
              disabled={processing}
              error={passError}
            />
          </Box>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            fullWidth
            text={t('cancel')}
            variant='light'
            onClick={onClose}
          />
          <Button
            fullWidth
            text={processing ? t('deleting') : t('delete')}
            onClick={onDelete}
            loading={processing}
          />
        </Dialog.Actions>
      </Dialog>
    </Modal>
  )
}
