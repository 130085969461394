import RiveComponent from '@rive-app/react-canvas'
import { Box } from '@mui/material'

export default function Searching({ size }) {
  return (
    <Box height={size} width={size}>
      <RiveComponent src='/assets/rives/searching.riv' />
    </Box>
  )
}
