import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import {
  Button,
  CustomCard,
  CompactJobCard,
  InfoMessage,
  ScrollableContainer,
  Spacer
} from 'components'
import { GET_USER_JOBS } from 'apis/querys'
import { SENT_PROPOSAL } from 'apis/mutations'
import { UserContext } from 'context/UserContext'
import { useSnackbar } from 'context/SnackBarContext'
import { getDate } from 'helpers'
import { useI18N } from 'hooks'

export default function PostedJobs() {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  const history = useHistory()
  const { companyId, categories } = history.location.state || false
  const [selected, setSelected] = useState()
  const [sending, setSending] = useState(false)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!companyId || !categories) {
      history.replace('/')
    }
  }, [])

  const { loading, data } = useQuery(GET_USER_JOBS, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: user.id,
      sortBy: {
        field: 'post_date',
        order: 'ASC'
      },
      categories,
      offset: 0,
      limit: 100,
      state: ['posted']
    }
  })

  const [createProposal] = useMutation(SENT_PROPOSAL)

  async function getOffers() {
    setSending(true)
    const proposal = await createProposal({
      variables: {
        jobId: selected,
        companyId,
        userId: user.id
      }
    })

    // Check the state of the offer
    const proposalState = proposal.data.sendProposal.state

    switch (proposalState) {
      case 'proposal': // The Job has ben proposed
        showSnackbar({
          message: t('theProposalHasBeenSent'),
          severity: 'success'
        })
        return history.goBack()
      case 'cancelled': // The offer or propose already has ben canceled
        showSnackbar({
          message: t('theCompanyHasCancelTheOffers'),
          marginBottom: true
        })
        break
      default: // The company already has sent an offer
        showSnackbar({
          message: t('theCompanyAlreadySentYouAnOffer'),
          marginBottom: true
        })
        break
    }
    setSending(false)
  }

  const userPostedJobs = data?.getUserJobs ?? []

  const PostsList = () => {
    if (!userPostedJobs.length) {
      return (
        <Grid item xs={12}>
          <InfoMessage text={t('anyJobWasFound')} variant='info' />
        </Grid>
      )
    }

    return userPostedJobs.map((job) => (
      <Grid key={job._id} item xs={12}>
        <CustomCard
          onClick={() => setSelected(job._id)}
          selected={selected === job._id}
        >
          <CompactJobCard
            title={job.title}
            startDate={job.start_date ? getDate(job.start_date) : t('noDate')}
            postedDate={job.post_date ? getDate(job.post_date) : t('noDate')}
            jobState={job.state}
            postalCode={job.postal_code}
            city={job.city}
          />
        </CustomCard>
      </Grid>
    ))
  }

  return (
    <ScrollableContainer
      title={t('postedJobs')}
      appBarProps={{ hasBack: true }}
    >
      <Spacer m={2} pb={10}>
        <Grid container spacing={2}>
          {loading ? (
            [...Array(3).keys()].map((i) => (
              <Grid key={i} item xs={12}>
                <CompactJobCard loading />
              </Grid>
            ))
          ) : (
            <PostsList />
          )}
        </Grid>
      </Spacer>

      <Container
        aria-label='buttons-container'
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          justifyContent: 'space-around',
          p: 1,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0
        }}
      >
        <Button
          text={t('cancel')}
          variant='light'
          onClick={() => history.goBack()}
        />
        <Button
          text={sending ? t('sending') : t('send')}
          onClick={getOffers}
          disabled={selected === undefined}
          loading={sending}
        />
      </Container>
    </ScrollableContainer>
  )
}
