import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { alpha, Box, MobileStepper, Typography } from '@mui/material'
import {
  Button,
  JobsaunLogo,
  FAButton,
  SimplePageContainer,
  Spacer
} from 'components'
import { useI18N, useSignUp } from 'hooks'
import { ArrowBack, ArrowFoward } from 'assets/Icons'
import { routes } from 'constants/Routes'
import { ROLE } from 'constants/Roles'
import { getRandomBg } from 'constants/ProfilePictures'
import AccountType from './signUp/AccountType'
import UserDataForm from './signUp/UserDataForm'
import WelcomeStep from './signUp/WelcomeStep'
import CompanyDataForm from './signUp/CompanyDataForm'
import AboutUs from './signUp/AboutUs'
import OtherCompanyData from './signUp/OtherCompanyData'
import Contract from './signUp/Contract'
import { validateAll, validateSwissVAT } from 'helpers/validateNif'
import { useForm } from '@mantine/form'
import { getUserCountryCode } from 'helpers/getUserCountryCode'
import { useSnackbar } from 'context/SnackBarContext'
import { countries, prefix } from 'constants/Countries'

const maxPublicNameLength = 15

const STEPS = {
  WELCOME: 'welcome',
  USER_ROLE: 'userRole',
  USER_DATA: 'userData',
  COMPANY_DATA: 'companyData',
  OTHER_COMPANY_DATA: 'otherCompanyData',
  ACCEPTANCE: 'acceptance',
  ABOUT: 'about'
}

const getSteps = (role) => {
  const globalSteps = [STEPS.WELCOME, STEPS.USER_ROLE]
  switch (role) {
    case ROLE.PARTICULAR:
      return [...globalSteps, STEPS.USER_DATA, STEPS.ABOUT]

    case ROLE.COMPANY:
      return [
        ...globalSteps,
        STEPS.USER_DATA,
        STEPS.COMPANY_DATA,
        STEPS.OTHER_COMPANY_DATA,
        STEPS.ACCEPTANCE,
        STEPS.ABOUT
      ]

    default:
      return globalSteps
  }
}

export default function SignUp() {
  const { t } = useI18N()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(STEPS.WELCOME)
  const [steps, setSteps] = useState(getSteps())
  const [disabled, setDisabled] = useState(true)
  const { loading, signup, validateEmail } = useSignUp()
  const [checkingEmail, setCheckingEmail] = useState(false)
  const { showSnackbar } = useSnackbar()

  const userRoleValidation = (values) => ({
    role: ![ROLE.COMPANY, ROLE.PARTICULAR].includes(values.role)
      ? t('required')
      : null
  })

  const userDataValidation = (values) => ({
    country: values.country === '' ? t('required') : null,
    name: values.name.trim().length < 1 ? t('required') : null,
    public_name: (() => {
      const value = values.public_name.trim()

      if (value.length < 1) return t('required')

      if (!value.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s.]+$/g)) {
        // Only letters
        return t('notValid')
      }
      if (value.length > maxPublicNameLength) {
        // Maximun length
        return `${t('maximumCharacters')} ${maxPublicNameLength}`
      }
      if (/^\S+@\S+$/.test(value.trim())) {
        // Not allow emails
        return t('notValid')
      }
      return null
    })(),
    email: (() => {
      if (!values.email) return t('required')
      if (!/\S+@\S+\.\S+/.test(values.email)) return t('signUpEmailError')
      return null
    })(),
    password:
      values.password.trim().length < 8 || values.password.trim().length > 20
        ? t('passwordError')
        : null,
    repeatPassword:
      values.password !== values.repeatPassword
        ? t('repeatPasswordError')
        : null
  })

  const companyDataValidation = (values, country) => {
    const nifValidations = {
      [countries.Spain]: validateAll(values.nif),
      [countries.Swiss]: validateSwissVAT(values.nif)
    }

    return {
      phone:
        values.phone.trim().replace(/\s/g, '').length !== 12
          ? t('phoneError')
          : null,
      legal_form: values.legal_form.length > 1 ? null : t('required'),
      nif: nifValidations[country] ? null : t('nifError'),
      province: values.province.length > 1 ? null : t('required'),
      postal_code: values.postal_code.length > 1 ? null : t('required'),
      city: values.city.length > 1 ? null : t('required'),
      categories:
        values.categories.length > 0 ? null : t('youMustSelectACategory')
    }
  }

  const formValidation = (values) => {
    if (!values.about.about_us) {
      showSnackbar({
        message: t('required'),
        severity: 'error',
        marginBottom: true
      })
      return { about: t('required') }
    }

    const globalValidation = {
      ...userRoleValidation(values.userRole),
      ...userDataValidation(values.userData),
      ...(values.userRole === ROLE.COMPANY &&
        companyDataValidation(values.companyData, values.userData.country))
    }
    const isValid = Object.values(globalValidation).every(
      (value) => value === null
    )

    if (!isValid) {
      showSnackbar({
        message: t('required'),
        severity: 'error',
        marginBottom: true
      })
    }

    return globalValidation
  }

  const form = useForm({
    initialValues: {
      userRole: {
        role: ''
      },
      userData: {
        country: '',
        name: '',
        public_name: '',
        email: '',
        password: '',
        repeatPassword: ''
      },
      companyData: {
        phone: '',
        legal_form: '',
        nif: '',
        province: '',
        postal_code: '',
        city: '',
        categories: []
      },
      otherCompanyData: {
        bio: '',
        picture: ''
      },
      acceptance: {
        contract: true
      },
      about: {
        about_us: ''
      }
    },
    validate: (values) => {
      if (activeStep === STEPS.USER_ROLE) {
        return userRoleValidation(values.userRole)
      }
      if (activeStep === STEPS.USER_DATA) {
        return userDataValidation(values.userData)
      }
      if (activeStep === STEPS.COMPANY_DATA) {
        return companyDataValidation(
          values.companyData,
          values.userData.country
        )
      }
      if (activeStep === STEPS.ABOUT) {
        return formValidation(values)
      }
      return {}
    }
  })

  const values = form.values
  const errors = form.errors
  const isLoading = loading ?? checkingEmail

  useEffect(() => {
    getUserCountryCode().then((country) => {
      form.setFieldValue('userData.country', country)
    })
  }, [])

  useEffect(() => {
    if (values.userData.country && values.userRole.role === ROLE.COMPANY) {
      form.setFieldValue('companyData.phone', prefix[values.userData.country])
    }
  }, [values.userData.country])

  useEffect(() => {
    const userRole = values.userRole.role

    if (activeStep === STEPS.USER_ROLE) {
      const roleSteps = getSteps(userRole)
      setSteps(roleSteps)
      const completed = Boolean(userRole)
      setDisabled(!completed)
      return
    }

    if (activeStep === STEPS.USER_DATA) {
      const completed = Object.values(values.userData).every(
        (value) => value !== ''
      )
      setDisabled(!completed)
      return
    }

    if (activeStep === STEPS.COMPANY_DATA) {
      const completed = Object.values(values.companyData).every((value) =>
        Array.isArray(value) ? value.length > 0 : value !== ''
      )
      setDisabled(!completed)
      return
    }

    if (activeStep === STEPS.ABOUT) {
      const completed = Object.values(values.about).every(
        (value) => value !== ''
      )
      setDisabled(!completed)
    }
  }, [values, activeStep])

  const goNext = async () => {
    const selectedRole = values.userRole.role

    if (form.validate().hasErrors) {
      // console.log({ errors: form.errors })
      return
    }

    if (activeStep === STEPS.USER_DATA) {
      setCheckingEmail(true)
      const emailExist = await validateEmail(values.userData.email)
      if (emailExist) {
        form.setFieldError('email', t('error.alreadyInUse'))
        setCheckingEmail(false)
        return
      }
      setCheckingEmail(false)
    }

    if (activeStep === STEPS.ABOUT) {
      if (selectedRole === ROLE.PARTICULAR) {
        return signup({
          role: ROLE.PARTICULAR,
          picture: { pic: 'c01s01t01', bg: getRandomBg() },
          ...values.userData,
          ...values.about
        })
      }

      const companyData = {
        ...values.companyData,
        bio: values.otherCompanyData.bio
      }
      delete companyData.phone

      return signup({
        role: ROLE.COMPANY,
        ...values.userData,
        phone: values.companyData.phone,
        picture:
          values.otherCompanyData.picture === ''
            ? { url: '' }
            : values.otherCompanyData.picture,
        company_data: companyData,
        ...values.about
      })
    }

    setActiveStep((current) => {
      const index = steps.indexOf(current)
      return steps[index + 1]
    })
  }

  const goBack = () => {
    if (activeStep !== STEPS.WELCOME) {
      setActiveStep((prevActiveStep) => {
        const index = steps.indexOf(prevActiveStep)
        return steps[index - 1]
      })
    }
  }

  const goToSignIn = () => {
    history.replace(routes.signIn)
  }

  const handleInputChange = (value) => {
    const valuesKeys = Object.keys(value)
    for (const key of valuesKeys) {
      form.setFieldValue(key, value[key])
    }
  }

  const getContent = () => {
    switch (activeStep) {
      case STEPS.WELCOME:
        return <WelcomeStep />
      case STEPS.USER_ROLE:
        return (
          <AccountType
            id='userRole.role'
            value={values.userRole.role}
            onChange={handleInputChange}
          />
        )
      case STEPS.USER_DATA:
        return (
          <UserDataForm
            id='userData'
            role={values.userRole.role}
            values={values.userData}
            onChange={handleInputChange}
            errors={errors}
          />
        )
      case STEPS.COMPANY_DATA:
        return (
          <CompanyDataForm
            id='companyData'
            values={{ ...values.companyData, country: values.userData.country }}
            onChange={handleInputChange}
            errors={errors}
          />
        )
      case STEPS.OTHER_COMPANY_DATA:
        return (
          <OtherCompanyData
            id='otherCompanyData'
            values={values.otherCompanyData}
            onChange={handleInputChange}
          />
        )
      case STEPS.ACCEPTANCE:
        return <Contract id='acceptance' values={values.acceptance} />
      case STEPS.ABOUT:
        return (
          <AboutUs
            id='about.about_us'
            value={values.about.about_us}
            onChange={handleInputChange}
          />
        )
      default:
        break
    }
  }

  return (
    <SimplePageContainer>
      <Spacer m={2}>
        <Box sx={{ color: 'text.primary', py: 3, textAlign: 'center' }}>
          <JobsaunLogo width={180} />
        </Box>

        <Box id='process-page-container' sx={{ pb: 8 }}>
          {getContent()}
        </Box>

        {activeStep !== STEPS.WELCOME ? (
          <MobileStepper
            variant='dots'
            steps={steps.length}
            activeStep={steps.indexOf(activeStep)}
            sx={{
              minWidth: 200,
              maxWidth: 400,
              flexGrow: 1,
              mx: 'auto',
              px: 4,
              pb: 3,
              bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
              backdropFilter: 'blur(1px)',
              width: '100%',
              position: 'absolute',
              bottom: 0
            }}
            nextButton={
              <FAButton
                loading={isLoading}
                size='medium'
                icon={<ArrowFoward />}
                onClick={goNext}
                disabled={disabled}
              />
            }
            backButton={
              <FAButton
                disabled={isLoading}
                size='medium'
                icon={<ArrowBack />}
                onClick={goBack}
              />
            }
          />
        ) : (
          <Box sx={{ width: '100%', maxWidth: { sm: 400 } }}>
            <Button
              text={t('continue')}
              fullWidth
              onClick={goNext}
              sx={{ mb: 2 }}
            />
            <Typography
              color='textSecondary'
              mb={2}
              textAlign='center'
              fontSize='small'
            >
              {t('alreadyHasAccount')}
            </Typography>
            <Button
              text={t('signIn')}
              fullWidth
              variant='light'
              onClick={goToSignIn}
            />
          </Box>
        )}
      </Spacer>
    </SimplePageContainer>
  )
}
