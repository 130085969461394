import { useState } from 'react'
import {
  SubSelector,
  ScrollableContainer,
  SubscriptionInfo,
  Modal,
  Dialog,
  Button,
  Tabs,
  Spacer
} from 'components'
import { Box, Typography, CircularProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { routes } from 'constants/Routes'
import { motion } from 'framer-motion'
import { LikeShapes } from 'iconsax-react'
import { useI18N, useUserInfo } from 'hooks'

export default function Subscription() {
  const { t } = useI18N()
  const { user, loading } = useUserInfo()
  const history = useHistory()
  const { fromCheckOut } = history.location.state ?? ''
  const [open, setOpen] = useState(fromCheckOut)
  const [[page, direction], setPage] = useState([0, 0])

  const tabsItems = [t('overview'), t('invoices')]

  const handleDialogClose = () => {
    setOpen(false)
    history.replace(routes.subscription)
  }

  return (
    <ScrollableContainer
      title={t('subscription')}
      appBarProps={{ hasBack: true }}
      toolBarChildren={
        user?.plan?.customer_id ? (
          <Tabs items={tabsItems} page={page} onChange={setPage} />
        ) : null
      }
    >
      {loading ? (
        <Box
          width='100%'
          height='90%'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={32} />
        </Box>
      ) : (
        <>
          {user.plan.customer_id !== '' ? (
            <SubscriptionInfo
              data={user.plan}
              direction={direction}
              page={page}
            />
          ) : (
            <Spacer m={2}>
              <SubSelector />
            </Spacer>
          )}
        </>
      )}
      <Modal open={open}>
        <Dialog>
          <Dialog.Title>
            <Typography fontWeight={600} fontSize='1.5rem' align='center'>
              {t('thankYou')}
            </Typography>
          </Dialog.Title>
          <Dialog.Content>
            <Box
              component={motion.div}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.3 }}
              sx={{ color: 'success.main', textAlign: 'center' }}
            >
              <LikeShapes size={90} variant='Bulk' />
            </Box>

            <Typography fontSize='1.2rem' align='center'>
              {t('subscriptionSucceed')}
            </Typography>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fullWidth onClick={handleDialogClose} text={t('accept')} />
          </Dialog.Actions>
        </Dialog>
      </Modal>
    </ScrollableContainer>
  )
}
