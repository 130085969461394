import { Box, Grid, Typography } from '@mui/material'
import { CountrySelect, Input, PasswordInput } from 'components'
import { ROLE } from 'constants/Roles'
import { useI18N } from 'hooks'

export default function UserDataForm({ role, id, values, errors, onChange }) {
  const { t } = useI18N()

  return (
    <>
      <Box sx={{ pb: 2, maxWidth: 400, mx: 'auto' }}>
        <Typography
          fontSize='1.4rem'
          fontWeight={600}
          lineHeight='1.3em'
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('completeTheData')}
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <CountrySelect
            id={`${id}.country`}
            value={values.country}
            error={errors?.country}
            onChange={onChange}
            label={t('country')}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id={`${id}.name`}
            value={values.name}
            error={errors?.name}
            onChange={onChange}
            label={role === ROLE.PARTICULAR ? t('name') : t('companyName')}
            autoFocus
            {...(role === ROLE.PARTICULAR && {
              helperText: t('onlyVisibleForYou')
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            id={`${id}.public_name`}
            value={values.public_name}
            error={errors?.public_name}
            onChange={onChange}
            label={
              role === ROLE.PARTICULAR ? t('publicName') : t('contactName')
            }
            {...(role === ROLE.PARTICULAR && {
              helperText: t('visibleForEveryone')
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id={`${id}.email`}
            type='email'
            label={t('email')}
            value={values.email}
            error={errors?.email}
            onChange={onChange}
            autoComplete='off'
            {...(role === ROLE.PARTICULAR && {
              helperText: t('onlyVisibleForYou')
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            id={`${id}.password`}
            value={values.password}
            error={errors?.password}
            onChange={onChange}
            autoComplete='new-password'
            label={t('pass')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            id={`${id}.repeatPassword`}
            value={values.repeatPassword}
            error={errors?.repeatPassword}
            onChange={onChange}
            autoComplete='new-password'
            label={t('repeatPass')}
          />
        </Grid>
      </Grid>
    </>
  )
}
