import React, { useContext, useState, useEffect } from 'react'
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js'
import {
  detachPaymentMethod,
  attachPaymentMethod,
  updateDefaultPaymentMethod
} from 'apis/stripe'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/client'
import { UPDATE_PLAN } from 'apis/mutations'
import { useForm, useI18N } from 'hooks'
import { Typography, Collapse } from '@mui/material'
import {
  Button,
  CardField,
  Checkbox,
  Dialog,
  Input,
  Modal,
  Spacer
} from 'components'
import { UserContext } from 'context/UserContext'

const defaultValues = {
  error: null,
  cardComplete: false,
  processing: false,
  disabled: true,
  succeded: false,
  cardData: null
}

export default function ChangePaymentMethodDialog({
  open,
  onClose,
  customer,
  paymentMethod
}) {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  const [state, setState] = useState(defaultValues)
  const [expanded, setExpanded] = useState(false)

  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const [updatePaymentMethod] = useMutation(UPDATE_PLAN)

  useEffect(() => {
    setState(defaultValues)
  }, [open])

  const { values, handleInputChange } = useForm({ name: '' })

  const handleCardChanges = async (event) => {
    setState({
      ...state,
      error: event.error ? event.error.message : '',
      cardComplete: event.complete,
      cardData: event.complete ? event : null
    })
  }

  const createNewPaymentMethod = async () => {
    const customerName = values.name || paymentMethod.billing_details.name

    const newPaymentMethod = await stripe.createPaymentMethod({
      type: state.cardData.elementType,
      card: elements.getElement(CardElement),
      billing_details: {
        name: customerName,
        email: paymentMethod.billing_details.email
      }
    })

    if (newPaymentMethod.error) {
      setState({
        ...state,
        error: newPaymentMethod.error.message,
        processing: false
      })
    } else {
      const newPaymentMethodData = await attachPaymentMethod(
        customer.id,
        newPaymentMethod.paymentMethod.id
      )

      if (newPaymentMethodData.error) {
        console.log(newPaymentMethodData.error.message)
        setState((prev) => ({
          ...prev,
          error: newPaymentMethodData.error.message,
          processing: false
        }))
      } else {
        detachPaymentMethod(paymentMethod.id).then(() => {
          updateDefaultPaymentMethod(customer.id, newPaymentMethodData.id).then(
            () => {
              // console.log(customer);
              // Updating Payment Method in DB
              updatePaymentMethod({
                variables: {
                  user_id: user.id,
                  payment_method_id: newPaymentMethodData.id
                }
              }).then(() => {
                // setState({
                //   ...state,
                //   error: null,
                //   processing: false,
                //   succeded: true
                // })
                history.go()
              })
            }
          )
        })
      }
    }
  }

  const changePaymentMethod = () => {
    setState({ ...state, processing: true })

    if (!stripe || !elements) {
      setState({ ...state, processing: false })
      return
    }

    if (state.error) {
      elements.getElement('card').focus()
      setState({ ...state, processing: false, error: state.error })
      return
    }

    if (state.cardComplete) {
      createNewPaymentMethod()
    } else {
      elements.getElement('card').focus()
      setState({
        ...state,
        error: t('required'),
        processing: false
      })
    }
  }

  const handleExpandedChanges = (checked) => {
    setExpanded(checked)
  }

  return (
    <Modal open={open} onClose={!state.processing ? onClose : null}>
      <Dialog>
        <Dialog.Title
          title={t('changePaymentMethod')}
          subtitle={t('introduceAValidPaymentMethod')}
        />
        <Dialog.Content>
          <Spacer y={2}>
            <CardField id='card' onChange={handleCardChanges} />
            <Typography variant='caption' color='error'>
              {state.error}
            </Typography>
          </Spacer>
          <Spacer y={2}>
            <Checkbox
              value={expanded}
              onChange={handleExpandedChanges}
              label={t('differentAccountHolder')}
            />
          </Spacer>
          <Collapse in={expanded}>
            <Input
              id='name'
              value={values.name}
              onChange={handleInputChange}
              label={t('checkoutName')}
            />
          </Collapse>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            fullWidth
            disabled={state.processing}
            onClick={onClose}
            text={t('cancel')}
            variant='light'
          />
          <Button
            fullWidth
            disabled={state.processing}
            onClick={changePaymentMethod}
            text={state.processing ? t('processing') : t('continue')}
          />
        </Dialog.Actions>
      </Dialog>
    </Modal>
  )
}
