import {
  ScrollableContainer,
  Spacer,
  SearchBar,
  IconsSwitch,
  UserPostsList,
  FAButton
} from 'components'
import { POST_STATES } from 'constants/Enums'
import { routes } from 'constants/Routes'
import { useI18N, useSettings } from 'hooks'
import { Add, ArchiveTick, BrifecaseTick, ReceiptEdit } from 'iconsax-react'
import { useHistory } from 'react-router-dom'

const POST_STATES_VALUES = Object.values(POST_STATES)

const postsSwitchOptions = (t) => [
  {
    id: 0,
    icon: <ArchiveTick />,
    label: t('pluralPosted')
  },
  {
    id: 1,
    icon: <ReceiptEdit />,
    label: t('pluralDraft')
  },
  {
    id: 2,
    icon: <BrifecaseTick />,
    label: t('pluralAssigned')
  }
]

export default function Posts() {
  const { t } = useI18N()
  const history = useHistory()
  const {
    settings: { board },
    updateSettings
  } = useSettings()

  const handleChange = (newValue) => {
    updateSettings({ board: newValue })
  }

  const handleSearch = (search) => {
    history.push(routes.newPost, { query: search })
  }

  return (
    <ScrollableContainer
      toolBarChildren={
        <>
          <SearchBar
            onEnter={handleSearch}
            placeholder={t('whatServiceDoYouNeed')}
            onClean={() => handleSearch('')}
          />
          <IconsSwitch
            items={postsSwitchOptions(t)}
            defaultSelected={board}
            onChange={handleChange}
          />
        </>
      }
    >
      <Spacer x={2}>
        <UserPostsList
          state={POST_STATES_VALUES[board] ?? POST_STATES_VALUES[0]}
        />
      </Spacer>
      <FAButton icon={<Add/>} sx={{position: 'absolute', bottom: 0, right: 0, m:2}} onClick={() => handleSearch('')}/>
    </ScrollableContainer>
  )
}
