import { useEffect } from 'react'
import { useRive, useStateMachineInput } from '@rive-app/react-canvas'
import { Box } from '@mui/material'

export default function Sending({ size, loaded }) {
  const STATE_MACHINE_NAME = 'SendingAnimation'
  const INPUT_NAME = 'Loaded'

  const { rive, RiveComponent } = useRive({
    src: '/assets/rives/sending.riv',
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true
  })
  const levelInput = useStateMachineInput(rive, STATE_MACHINE_NAME, INPUT_NAME)

  useEffect(() => {
    if (rive && levelInput) {
      levelInput.value = loaded
    }

    // return () => rive.cleanup()
  }, [loaded, levelInput, rive])

  //   useEffect(() => {
  //     if (rive) {
  //       rive.on('statechange', (event) => {
  //         console.log(event.data[0])
  //       })
  //     }
  //   }, [rive])

  return (
    <Box height={size} width={size}>
      <RiveComponent />
    </Box>
  )
}
