import React from 'react'
import PropTypes from 'prop-types'
import { Container, Box, Typography, Skeleton } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Danger, Gift, LikeShapes } from 'iconsax-react'
import { useI18N } from 'hooks'

/// ////////COMPONENT PROPS
AccountStateCard.propTypes = {
  state: PropTypes.string,
  problem: PropTypes.string // payment, subscription
}

const stateColors = {
  active: 'success.main',
  trial: 'warning.main',
  deactivate: 'error.main'
}

const StateCard = ({ icon, title, message, description, state }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'start', mb: 2 }}>
      <Box element='span' sx={{ color: stateColors[state] }}>
        {icon}
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography sx={{ fontSize: '1.4em', color: stateColors[state] }}>
          {title}
        </Typography>
        <Typography color='textSecondary' lineHeight={1.2}>
          {message}
        </Typography>
        <Typography
          sx={{
            fontSize: '0.9em',
            color: (theme) => alpha(theme.palette.text.secondary, 0.5)
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

export default function AccountStateCard({ state, problem }) {
  const { t } = useI18N()

  const getStateRender = () => {
    switch (state) {
      case 'active':
        return (
          <StateCard
            icon={<LikeShapes size={36} variant='Bulk' />}
            title={t('active')}
            message={t('activeMessage')}
            state={state}
          />
        )
      case 'trial':
        return (
          <StateCard
            icon={<Gift size={32} variant='Bulk' />}
            title={t('trial')}
            message={t('trialMessage')}
            description={t('trialDescription')}
            state={state}
          />
        )
      case 'deactivate':
        return (
          <StateCard
            icon={<Danger size={32} variant='Bulk' />}
            title={t('deactivate')}
            message={t(`error.${problem}`) || t('deactivateDefaultMessage')}
            description={t('deactivateDescription')}
            state={state}
          />
        )
      default:
        return (
          <Box>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        )
    }
  }

  return (
    <Container>
      <Typography sx={{ fontSize: '1.3em', pb: 1 }}>
        {t('accountState')}
      </Typography>
      {getStateRender()}
    </Container>
  )
}
