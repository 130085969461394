import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export default function useSearchParams(params = []) {
  const { search } = useLocation()

  const getResulst = () => {
    const result = {}
    const searchParams = new URLSearchParams(search)

    if (Array.isArray(params)) {
      params.forEach((i) => {
        result[i] = searchParams.get(i)
      })
    } else {
      result[params] = searchParams.get(params)
    }

    return result
  }

  return useMemo(getResulst, [search])
}
