const de = (...args) => ({
  // GENERAL
  processing: 'Verarbeitung...',
  sending: 'Senden...',
  loadMore: 'Mehr laden',

  // SIGN IN
  signIn: 'Anmelden',
  signInWelcome: 'Wir freuen uns, Sie wiederzusehen!',
  forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
  signInEmailError: 'Bitte überprüfen Sie Ihre E-Mail',
  signInPasswordError: 'Bitte überprüfen Sie Ihr Passwort',
  tooManyRequest:
    'Sie haben zu viele Fehlversuche gemacht, bitte versuchen Sie es später noch einmal',
  wrongCredentials: 'Falsche Anmeldedaten.',
  signInText: 'Anmelden',
  signUpText: 'Jetzt erstellen',
  registerQ: 'Sie haben kein Konto?',
  continueAsGuest: 'Als Gast fortfahren',
  resetPasswordTitle: 'Passwort zurücksetzen',
  aEmailWillBeSend:
    'Bitte geben Sie die E- Mail - Adresse ein, mit der Sie sich registriert haben. Sie werden eine E-Mail erhalten, um Ihr Passwort zurückzusetzen',
  theEmailHasBeenSent:
    'Die E-Mail wurde gesendet. Bitte prüfen Sie Ihren Posteingang',
  close: 'Schließen',
  resetPassword: 'Passwort zurücksetzen',
  yourUser: 'Ihr Benutzer:',
  'resetError.ExpiredActionCode':
    'Der Code ist abgelaufen, bitte fordern Sie das Passwort erneut an',
  'resetError.InternalError':
    'Es ist ein Fehler aufgetreten. Bitte kehren Sie zur App zurück, um Ihr Passwort zurückzusetzen.',
  'resetError.Processing':
    'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal',
  'resetError.auth/expired-action-code':
    'Der Code ist abgelaufen, bitte fordern Sie die Passwortänderung erneut an.',
  'resetError.auth/invalid-action-code':
    'Der Code ist falsch gebildet oder wurde bereits verwendet, bitte beantragen Sie die Änderung des Passworts erneut.',
  'resetError.auth/user-disabled':
    'Der Benutzer, der dem angegebenen Passwort-Rücksetzungscode entspricht, wurde deaktiviert.',
  'resetError.auth/user-not-found':
    'Es gibt keinen Benutzer, der dem Passwort- Rücksetzungscode entspricht.',
  'resetError.auth/weak-password': 'Das neue Passwort ist nicht sicher genug.',
  passwordResetSuccesfully: 'Passwort erfolgreich geändert',
  nowYouCanUseNewPass:
    'Sie können jetzt Ihr neues Passwort verwenden, um sich bei Ihrem Jobsaun-Konto anzumelden',

  // SIGN UP
  signUp: 'Anmelden',
  welcome: 'Wir begrüßen Sie',
  asEAsyAsFindTheProfessional: (
    <>
      So <span>einfach</span> wie die Suche nach dem Profi, den Sie brauchen
    </>
  ),
  allReady: 'Alles bereit!',
  letsCreateYourAccount: 'Lassen Sie uns Ihr Konto erstellen',
  accountType: 'Welchen Kontotyp möchten Sie erstellen?',
  completeTheData:
    'Füllen Sie das untenstehende Formular mit Ihren Kontodaten aus.',
  tellUsMoreAboutYourCompany: 'Erzählen Sie uns mehr über Ihr Unternehmen.',
  getMoreVisits:
    'Unternehmen erhalten mehr Kontakte, wenn sie Image und Biografie haben.',
  user: 'Benutzer',
  particular: 'Kunde',
  company: 'Unternehmen',
  country: 'Land',
  name: 'Vollständiger Name',
  onlyVisibleForYou: 'Es wird nur für Sie sichtbar sein',
  publicName: 'Öffentlicher Name',
  visibleForEveryone: 'Es wird für alle sichtbar sein',
  phoneNumber: 'Kontakttelefon',
  onlyVisibleOfferAccepted:
    'Es ist nur für Angebote sichtbar, die Sie annehmen.',
  email: 'E-Mail',
  pass: 'Passwort',
  repeatPass: 'Passwort wiederholen',
  howDidYouHear: 'Wie haben Sie uns gefunden ? ',
  accept1: 'Mit der Erstellung eines Kontos akzeptieren Sie die',
  accept2: 'und der',
  terms: 'Nutzungsbedingungen',
  Datenschutzerklärung: 'Datenschutzerklärung.',
  termsAndPrivacy: (
    <>
      Durch das Erstellen eines Kontos akzeptieren Sie die{' '}
      <a
        href='https://jobsaun.com/legal/terms'
        target='_blank'
        rel='noreferrer'
      >
        Nutzungsbedingungen
      </a>{' '}
      y la{' '}
      <a
        href='https://jobsaun.com/legal/privacy-and-cookies'
        target='_blank'
        rel='noreferrer'
      >
        Datenschutzrichtlinie.
      </a>
    </>
  ),
  signUpButton: 'Konto erstellen',

  maincategory: 'Hauptkategorie',
  companyName: 'Firmenname',
  nif: 'UID',
  province: 'Kanton',
  postalCode: 'Postleitzahl',
  contactName: 'Kontaktname',
  signUpEmailError: 'Die E-Mail scheint nicht korrekt zu sein.',
  phoneError: 'Das Telefon muss 9 Ziffern plus Präfix haben.',
  nifError: 'Falsches Feld, bitte überprüfen Sie es.',
  passwordError: 'Das Passwort muss zwischen 8 och 20 tecken.',
  repeatPasswordError: 'Passwörter stimmen nicht überein.',
  required: 'Dieses Feld ist erforderlich.',
  notValid: 'Ungültiger Wert',
  youMustSelectACategory: 'Sie müssen mindestens eine Kategorie auswählen',
  contractRequired:
    'Es ist notwendig, den Auftragsverarbeitungsvertrag zu akzeptieren, um fortzufahren.',
  privacyRequired:
    'Es ist notwendig, unsere Datenschutzerklärung zu akzeptieren, um fortzufahren.',
  legalFormHelper: 'Ex: Selbständige',
  contract:
    'Ich habe die Auftragsverarbeitungsvereinbarung gelesen und akzeptiere sie.',
  pleaseReadTheContract:
    'Bitte lesen Sie den folgenden Vertrag, bevor Sie fortfahren.',
  ifYouContinue:
    'Indem Sie fortfahren, akzeptieren Sie den Vertrag zur Auftragsverarbeitung.',
  seeMyOffers: 'Siehe meine Angebote',
  maximumCharacters: 'Die maximal zulässige Anzahl von Zeichen ist',
  'error.alreadyInUse': 'Der E-Mail ist bereits ein Konto zugewiesen.',
  friend: 'Freund',
  digitalPaper: 'Digitale Zeitung',
  other: 'andere',
  someFieldIsINcomplete:
    'Einige Felder sind unvollständig, gehen Sie zurück und füllen Sie alle aus.',

  // HOME
  exploreCategories: 'Entdecken Sie die Kategorien',
  particulars: 'Kunden',
  slider1: 'Kunden schnell und einfach finden.',
  slider2: 'Jetzt findet der Profi, den Sie brauchen, Sie.',
  share: 'Teilen',
  search: 'Suchen...',
  categories: 'Kategorien',
  whatServiceDoYouNeed: 'Welchen Service benötigen Sie?',
  homeCTA: (
    <>
      Jetzt <span>der Profi, den du brauchst</span> findet dich
    </>
  ),
  bestRatedCompanies: 'Die am besten bewerteten Unternehmen',
  doYouHaveACompany: 'Haben Sie eine Firma?',
  joinJobsaun:
    'Werden Sie Teil von Jobsaun , um Ihr Unternehmen sichtbarer zu machen.',
  joinNowForFree: 'Jetzt kostenlos anmelden',
  weAreAlready: 'Wir sind schon',
  andGoingUp: 'Unternehmen und steigend',

  //  DISCOVER
  reset: 'Wiederherstellen',

  // INBOX
  inboxGuestMessage:
    'Von der Mailbox aus können Sie mit dem Kunden oder Unternehmen in Kontakt treten und Details abschließen.',

  // OFFERS-POSTS
  boardGuestMessage:
    'Vom Vorstand aus können Sie Ihre Angebote oder Jobs verwalten.',
  pluralPosted: 'Veröffentlicht',
  pluralDraft: 'Entwürfe',
  pluralAssigned: 'Zugewiesen',
  pluralAccepted: 'Angenommen',
  archived: 'Archiviert',

  // ACCOUNT PAGE
  registerMessage:
    'Nutzen Sie alle Funktionen der Anwendung mit einem Konto. Es zu erstellen ist sehr einfach und KOSTENLOS',
  alreadyHasAccount: 'Sie haben bereits ein Konto?',
  knowMoreAboutUs: 'Möchten Sie mehr über uns erfahren?',
  moreInfo: 'Weitere Informationen',
  installPWA: 'Verbessern Sie die Erfahrung',
  install: 'Installieren',
  welcomeToJobsaun: 'Willkommen in Jobsaun',
  slogan: 'Lass dich finden, was du suchst.',
  joinNow: 'Vereinigt euch jetzt',
  next: 'Weiter',
  preview: 'Zurück',
  accountSlider_1:
    'Finden Sie leicht verifizierte Fachleute, die den Service erbringen, den Sie benötigen',
  accountSlider_2:
    'Erhöhen Sie die Sichtbarkeit Ihres Unternehmens, indem Sie näher am Kunden sind',
  accountSlider_3:
    'Nutzerbewertungen wie Sie, damit Sie die besten Alternativen bewerten können',
  accountSlider_4:
    'Erhalten Sie personalisierte Angebote, von denen sowohl das Unternehmen als auch Sie profitieren',

  // CATEGORY PAGE
  jobs: 'Arbeitsplätze',
  companies: 'Unternehmen',
  searchInCategory: 'Arbeit in dieser Kategorie gesucht...',
  searchInCompanies: 'Suche nach Unternehmen in dieser Kategorie...',
  jobFilterTitle: 'Jobfilter',
  proximityFilter: 'Nähe (km)',
  budgetFilter: 'Haushalt',
  subcategories: 'Unterkategorien',
  selectAll: 'Alles auswählen',
  unSelectAll: 'Auswahl aufheben',

  // CATEGORIES
  construction: 'Bau und Wohnung',
  mechanic: 'Mechanik und Transport',
  care: 'Pflege und Ästhetik',
  leisure: 'Freizeit und Gastronomie',
  technology: 'Technologie und Marketing',
  others: 'Sonstige Dienstleistungen',

  // Subcategories
  // ---Construction
  demolition: 'Abbruch',
  verticalsJobs: 'Vertikale Arbeiten (Fassaden)',
  architecture: 'Architektur, Planung',
  bathRenovations: 'Badezimmer',
  roofers: 'Dachdecker, Abdeckungen',
  welding: 'Schweißen',
  reforms: 'Reformen',
  electrician: 'Elektriker, Elektroinstallationen',
  windows: 'Fenster und Türen',
  garage: 'Garagentore',
  furnishPicker: 'Möbelmonteur',
  glass: 'Glas und Verglasung',
  fireplace: 'Schornsteine',
  parquet: 'Parkett, Laminat, Teppich',
  isolation: 'Isolation',
  kitchen: 'Küchen',
  decoration: 'Dekoration',
  painting: 'Malerei',
  builder: 'Maurer, Gips, Beton',
  interiorDesign: 'Innenarchitektur',
  metal: 'Metallbau, Schlosser, Schmieden',
  slabs: 'Platten, Fliesen',
  upholstery: 'Polster, Sattlerwaren',
  plumbing: 'Klempner',
  carpenter: 'Zimmermann',
  locksmith: 'Schlosser',
  gardener: 'Gärtner, Gartenbau',
  earthmoving: 'Bau von Brunnen, Erdarbeiten, Fundamenten',
  greenhouses: 'Gewächshäuser',
  fences: 'Zäune, Tore, Sichtschutzwände',
  remodeling: 'Umbau, Totalrenovierung, Erweiterung',
  technician: 'Techniker, Systeminstallateur',
  alarms: 'Alarme',
  housekeeping: 'Housekeeping, Supervisor',
  blinds: 'Jalousien, Rollläden, Markisen',
  confort: 'Heizung, Lüftung, Klima, Kältetechnik',
  climate: 'Klimaanlage',
  cleaning: 'Reinigung',
  applianceTechnician: 'Gerätetechniker',
  moving: 'Umzüge',
  scaffolding: 'Gerüstbau',
  paving: 'Pflasterarbeiten, Straßen, Straßen',
  pestControl: 'Schädlingsbekämpfung',
  constructionMaterials: 'Baustoffe',

  // ---Mechanic and transport
  mechanics: 'Mechanik, Elektronik',
  crane: 'Kran und Überführung von Fahrzeugen',
  carWash: 'Autowaschanlage',
  electricCars: 'Elektrofahrzeuge',
  wheelbarrows: 'Schubkarren',
  chargingPoints: 'Installation von Ladepunkten',
  smallTransport: 'Verkehr, Kleintransport',
  largeTransport: 'Verkehr, Hauptverkehrsmittel',
  carPainting: 'Blech und Farbe',
  carService: 'Autoservice (Pre-ITV)',
  fineTunning: 'Einrichtung',
  motorbike: 'Motorrad, Quad',
  tuning: 'Tuning',

  // ---Care and aesthetics
  tattooArtist: 'Tätowierer',
  dentist: 'Zahnarzt',
  massageTherapist: 'Masseurin',
  osteopathy: 'Osteopathie',
  hairdresser: 'Friseur',
  beautician: 'Kosmetikerin',
  rehabilitation: 'Rehabilitation',
  physiotherapist: 'Physiotherapeut',
  makeupArtist: 'Maskenbildner',
  acupuncture: 'Akupunktur',
  motivationalCoach: 'Motivationscoach',
  personalTrainer: 'Personal Trainer',
  zumbaInstructor: 'Zumba Monitor',
  pilatesInstructor: 'Pilates-Monitor',

  // ---Technology and marketing
  programmer: 'Programmierer',
  webProgrammer: 'Web-Programmierer',
  photographer: 'Fotograf',
  webDesigner: 'Webdesigner',
  multimediaInstaller: 'Multimedia-Installationsprogramm',
  applicationProgrammer: 'Anwendungsprogrammierer',
  graphicDesigner: 'Grafikdesigner',
  computerTechnician: 'Computertechniker',
  marketingAndCommunication: 'Marketing und Kommunikation',
  printing: 'Drucken',
  analyst: 'Analyst',
  database: 'Datenbank',
  machineLearning: 'Maschinelles Lernen',
  productCustomization: 'Produktanpassung',
  merchandising: 'Merchandising',
  artificialIntelligence: 'Künstliche Intelligenz',
  cyberSecurity: 'Cybersicherheit',
  itSystemsTechnician: 'Computersystemtechniker',
  itSupportTechnician: 'EDV-Support-Techniker',

  // --Leisure and gastronomy
  cook: 'Koch',
  catering: 'Catering',
  animator: 'Entertainer',
  socioculturalAnimator: 'Soziokultureller Animator',
  functionalDiversityMonitor:
    'Freizeitmonitor für Menschen mit funktionaler Vielfalt',
  childrenMonitor: 'Kinderfreizeitmonitor',
  freeTimeMonitor: 'Freizeit- und Freizeitmonitor',
  waiter: 'Kellner',
  soundTechnician: 'Tontechniker',
  dj: 'DJ',
  danceInstructor: 'Tanzlehrer',

  // ---Others
  model: 'Modell',
  concierge: 'Concierge',
  administrative: 'administrative',
  receptionist: 'Rezeptionistin',
  lifeGuard: 'Lebensretter',
  commercial: 'Kommerziell',
  teacher: 'Lehrer',
  privateTeacher: 'Privatlehrer',
  pedagogue: 'Pädagoge',
  seamstress: 'Näherin',
  educationalAssistant: 'Pädagogische Assistentin',
  animalCaretaker: 'Tierpfleger',
  parcelDeliveryMan: 'Bote',
  usedOilCollection: 'Sammlung von Altöl',
  socialEducator: 'Sozialpädagoge',
  otherJob: 'Ein anderer Job',

  // --------
  filter: 'Filter',
  filters: 'Filter',
  orderBy: 'Sortieren nach',
  betterRating: 'Beste Bewertung',
  moreAcceptedOffers: 'Mehr Angebote angenommen',
  youMustSelectAtLeastOneSubcategory:
    'Sie müssen mindestens eine Unterkategorie auswählen.',

  // COMPANY PROFILE
  sendJobOffer: 'Arbeit anbieten',
  message: 'Nachricht',
  companyInfo: 'Biographie',
  seeMore: 'Mehr sehen',
  seeLess: 'Weniger sehen',
  customerReviews: 'Kundenbewertungen',
  noDetailsYet: 'Das Unternehmen  hat noch keine Einzelheiten mitgeteilt.',
  createFreeAccount: 'Kostenloses Konto erstellen',

  // LEGAL FORMS
  freelance: 'Selbständige',
  civilSociety: 'Zivilgesellschaft',
  collectiveSociety: 'Partnerschaft',
  limitedLiabilityCompany: 'Gesellschaft mit beschränkter Haftung',
  limitedSociety: 'Sociedad Limitada, S.L.',
  anonymousSociety: 'Sociedad Anónima, S.A.',
  cooperativeSociety: 'Genossenschaft',

  // REVIEWS
  valorations: 'Ratings',
  comunication: 'Kommunikation',
  times: 'Pünktliche Lieferung',
  asDescribed: 'Abschlussarbeiten',
  recommend: 'Würdest du es empfehlen?',
  leaveAComment: 'Hinterlasse einen Kommentar',

  // PAGES
  home: 'Start',
  discover: 'Entdecken',
  inbox: 'Briefkasten',
  messages: 'Nachrichten',
  offers: 'Angebote',
  board: 'Plank',
  posts: 'Veröffentlichungen',
  profile: 'Profil',
  category: 'Kategorie',
  newOffer: 'Neues Angebot',

  // JOB OFFERS
  posted: 'Veröffentlicht',
  startDate: 'Startdatum',
  preferredStartDate: 'Wunschtermin',
  endDate: 'Enddatum/Lieferung',
  mainDetails: 'Details',
  otherDeatils: 'Sonstige Angaben',
  receivedOffers: 'Eingegangene Angebote',
  createAnOffer: 'Angebot abgeben',
  noOfferYet:
    'Dieser Job hat noch keine Angebote erhalten, seien Sie der Erste!',
  offerAlert:
    'Um ein Angebot zu erstellen, müssen Sie sich als Unternehmen anmelden.',
  rateType: 'Tarifart',
  flatRate: 'Pauschale',
  day: 'Tag',
  month: 'Monat',
  year: 'Jahr',
  amount: 'Menge',
  addEndDate: 'Enddatum hinzufügen',
  attachFile: 'Datei anhängen',
  sendOffer: 'Angebot senden',
  offerMessageHelper: 'Es wird als erste Nachricht des Chats angezeigt.',
  fileError: 'Dateifehler: nur akzeptiert. pdf und Ihre ist',
  maxFileSize: 'Die maximale Dateigröße beträgt 10MB',
  budget: 'Haushalt',
  editPost: 'Publikation bearbeiten',
  nothingPostedYet: 'Sie haben noch nichts gepostet',
  tryFromTheSearchbar: 'Versuchen Sie es von der oberen Suchleiste',
  nothingInDrafts: 'Es gibt nichts in Entwürfen',
  nothingInAssigned: 'Es ist nichts in zugewiesen',
  nothingInArchived: 'Es gibt nichts in der Archivierung',
  nothingInRejected: 'Sie haben keine abgelehnten Angebote',
  nothingInAccepted: 'Sie haben keine angenommenen Angebote',
  nothingInNegotiating: 'Angebote zur Aufnahme der Verhandlungen senden',
  nothingFound: 'Keine Suchergebnisse',
  StartSendingOffersToPotencialCustomers:
    'Beginnen Sie mit dem Senden von Angeboten an potenzielle Kunden',
  'errors.no_active_subscription_found':
    'Sie müssen sich für einen Plan anmelden, um Angebote senden zu können.',

  // Questions
  'question.flexibleHours': 'Haben Sie Flexibilität bei den Zeitplänen?',
  'question.availableToNegociate':
    'Wären Sie in der Lage, den Haushalt auszuhandeln?',
  'question.payInAdvance': 'Würden Sie bei Bedarf im Voraus bezahlen?',
  'question.accesible': 'Ist der Ort leicht zugänglich?',
  'question.availabilityForTravel':
    'Haben Sie die Möglichkeit, das Fahrzeug zu bewegen?',
  'question.parking': 'Gibt es Parkplätze in der Umgebung?',
  'question.weekends': 'Ist Wochenendarbeit erlaubt?',
  'question.material': 'Wird das Material zur Verfügung gestellt?',
  'question.neededParts': 'Haben Sie die Teile, die Sie brauchen?',
  'question.crane': 'Brauchen Sie einen Kran?',
  'question.bigTrees': 'Hat es hohe Bäume?',
  'question.water': 'Haben Sie Brunnen oder andere Bereiche mit Wasser?',
  'question.workAtHome': 'Kann man zu Hause arbeiten?',
  'question.goToTheHome': 'Brauchst du sie, um nach Hause zu gehen?',
  'question.squareMeters': 'Wie viele m² hat das Grundstück?',

  // Answers
  'answer.flexibleHours': 'Flexibilität bei den Zeitplänen',
  'answer.availableToNegociate': 'Verhandelbarer Haushalt',
  'answer.payInAdvance': 'Verfügbarkeit im Voraus zu zahlen',
  'answer.accesible': 'Ort des leichten Zugangs',
  'answer.availabilityForTravel': 'Verfügbarkeit von Reisen',
  'answer.parking': 'Parken in der Umgebung',
  'answer.weekends': 'Arbeiten ist am Wochenende erlaubt',
  'answer.material': 'Der Kunde stellt das Material zur Verfügung',
  'answer.neededParts': 'über die erforderlichen Teile verfügen',
  'answer.crane': 'Kran benötigt',
  'answer.bigTrees': 'Hohe Bäume',
  'answer.water': 'Brunnen oder andere Bereiche mit Wasser',
  'answer.workAtHome': 'Möglichkeit, zu Hause zu arbeiten',
  'answer.goToTheHome': 'Es ist notwendig, nach Hause zu gehen',
  'answer.squareMeters': 'Quadratmeter des Geländes',

  // OTHERS
  shareTheApp: 'App teilen',
  shareTheAppMessage:
    'Teilen Sie die Jobsaun App  mit Ihren Freunden, zeigen Sie ihnen, wie gut Sie sind, indem Sie neue und nützliche Apps finden.',
  goToChat: 'Zum Chat',
  seeMoreJobs: 'Weitere Werke sehen',
  sendingYourOffer: 'Angebot senden',
  itCanTakeAMoment:
    'Dieser Prozess kann eine Weile dauern. Bitte haben Sie etwas Geduld.',
  offerSuccessSent: 'Angebot erfolgreich übermittelt',
  continueWith: 'Was willst du als nächstes tun?',
  users: 'Nutzer',
  yes: 'Ja',
  no: 'Nein',
  save: 'Speichern',
  saveDraft: 'Entwurf speichern',
  toPost: 'Veröffentlichen',
  saving: 'Sparen...',
  posting: 'Veröffentlichung ...',
  postingJob: 'Ihre Arbeit wird veröffentlicht',
  pleaseBePatient: 'Bitte warten Sie, bis die Aktion beendet ist.',
  nothingHere: 'Nichts hier...',
  tryAnother: 'Versuchen Sie es mit einer anderen Suche oder Kategorie.',
  settings: 'Einstellungen',
  account: 'Konto',
  sent: 'Gesendet',
  youHaveToSignIn:
    'Sie müssen angemeldet sein, um auf diese Funktion zugreifen zu können.',
  loading: 'Laden...',
  all: 'Alle',

  // POSTED JOBS
  postedJobs: 'Veröffentlichte Werke',
  send: 'Senden',
  cancel: 'Abbrechen',
  acceptedOffer: 'Angebot angenommen',
  cancelledOffer: 'Angebot storniert',
  acceptOffer: 'Angebot annehmen',
  draft: 'Entwurf',
  negotiating: 'Verhandeln',
  rejected: 'Abgelehnt',
  accepted: 'Angenommen',
  cancelled: 'Annulliert',
  proposed: 'Vorschlag',
  assigned: 'Zugewiesen',
  noDate: 'undatiert',
  anyJobWasFound:
    'Es wurde kein veröffentlichtes Werk gefunden, das den Kategorien des Unternehmens entspricht  .',
  theProposalHasBeenSent: 'Der Vorschlag wurde erfolgreich übermittelt',
  youAlreadySentThisJob:
    'Sie haben diese Arbeit bereits an das Unternehmen geschickt.',
  theCompanyAlreadySentYouAnOffer:
    'Das Unternehmen hat Ihnen bereits ein Angebot für diese Stelle geschickt.',
  theCompanyHasCancelTheOffers:
    'Das Unternehmen hat Angebote für diesen Auftrag storniert.',
  // MENUS
  moveToDraft: 'Zu Entwürfen übergehen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  newFirst: 'Neu zuerst',
  oldFirst: 'Alte zuerst',
  edited: 'Bearbeitet am',

  // NEW JOB
  newJob: 'Neue Job',
  title: 'Titel',
  subCategory: 'Unterkategorie',
  explainTheNeededService:
    'Erklären Sie so detailliert wie möglich, welchen Service Sie benötigen, damit Fachleute Ihnen das fairste Angebot machen.',
  postDescription: 'Beschreibung der Dienstleistung',
  city: 'Stadt',
  helperCity:
    'Etwas ist schief gelaufen, die Stadt zu bekommen, bitte überprüfen Sie die Postleitzahl oder schreiben Sie es von Hand auf.',
  uploadPictures: 'Bilder',
  maximumSizeExceded: 'Die maximale Dateigröße wurde überschritten',
  maximumNumberOfFiles: 'Die Höchstzahl der Dossiers wurde überschritten',
  notAllowFile: 'Die Datei ist nicht zulässig.',
  allowFiles: 'Erlaubte Dateien',
  otherDetails: 'Sonstige Angaben',
  congrats: 'Herzlichen Glückwunsch!',
  postedSucceed: 'Die Arbeit wurde erfolgreich veröffentlicht.',
  justWait:
    'Jetzt müssen Sie nur noch warten, bis Sie Angebote von interessierten Unternehmen erhalten.',
  seeTheJob: 'Sehen Sie den Standort',
  fiveDigits: 'Es müssen 5 Ziffern sein.',
  alreadyUploadThisFile: 'Sie haben diese Datei bereits hochgeladen',
  optional: 'optional',
  willLoseTheProcessOnCancel:
    'Wenn Sie abbrechen, verlieren Sie jeglichen Fortschritt.',
  noCategoryFound:
    'Es tut uns leid, wir haben keine Kategorie gefunden, in der die Suche durchgeführt wurde. Bitte wählen Sie die am besten geeignete Kategorie aus den folgenden Optionen aus.',
  weHaveSelectedTheNextCategory:
    'Basierend auf Ihrer Suche haben wir die folgende Kategorie ausgewählt, wenn Sie der Meinung sind, dass sie nicht korrekt ist, wählen Sie bitte die am besten geeignete aus.',
  localitation: 'Standort',
  searchCompanies: 'Suche nach Unternehmen',
  lookingCompanies: 'Unternehmen gesucht',
  thisCanTakeAWhile:
    'Dies kann eine Weile dauern, bitte haben Sie etwas Geduld.',
  weHaveFound: 'Wir haben gefunden...',
  whatDoYouWantToDoNext: 'Was willst du als nächstes tun?',
  seeCompanies: 'Siehe Unternehmen',
  continueWithTheApplication: 'Mit dem Antrag fortfahren',
  weAreSorryWeStillGrowing:
    'Entschuldigung, wir wachsen weiter und immer mehr Unternehmen schließen sich Jobsaun an. Fordern Sie die Unternehmen weiterhin auf, sich mit Ihnen in Verbindung zu setzen.',
  whatBudgetDoYouHave: 'Welches Budget haben Sie?',
  budgetSubtitle:
    'Dies ist nur eine Annäherung, um den Unternehmen den maximalen Betrag mitzuteilen, den du dir leisten kannst.',
  recommended: 'Empfohlen',
  lessThan: 'Bis',
  moreThan: 'Größer als',
  whenDoYouNeedIt: 'Wann brauchen Sie es?',
  tellUsYourPreferences: 'Teilen Sie uns Ihre Präferenzen mit',
  doYouWantToProvideAnImage:
    'Möchten Sie ein Image liefern, das dem Profi hilft?',
  dragAndDropOrBrowse: 'Drag & Drop oder Suchen',
  browse: 'hier klicken',
  allow: 'Erlaubt',
  maxFilesSize: 'Höchstgröße',
  of: 'von',
  image: 'Bild',
  'imageErrors.file-too-large': `Die Datei ${args[0]} ist zu groß. Die maximale Größe ist ${args[1]} und die Datei hat eine Größe von ${args[2]}`,
  'imageErrors.file-invalid-type': `Der Dateityp muss einer der folgenden sein: ${args[0]}`,
  'imageErrors.too-many-files': `Zu viele Dateien ausgewählt, das Maximum ist ${args[0]} Dateien.`,
  'imageErrors.bad-file': `Die Datei ${args[0]} kann nicht angehängt werden. Bitte versuchen Sie es mit einem anderen.`,
  postDetails: 'Angaben zum Antrag',
  'post.sending': 'Ihr Antrag wird eingereicht',
  'post.pleaseBePatient':
    'Das kann einen Moment dauern. Bitte haben Sie etwas Geduld.',
  'post.added': 'Veröffentlichung erfolgreich erstellt.',
  'error.post.adding':
    ' Die  Publikation konnte nicht erstellt werden. Bitte versuchen Sie es ein anderes Mal.',
  'error.post.updating':
    'Beitrag konnte nicht aktualisiert werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
  'error.post.deleting':
    'Der Beitrag konnte nicht gelöscht werden. Bitte versuchen Sie es ein anderes Mal.',

  // NEW OFFER
  sendOfferTo: 'Sie werden ein Angebot für die Stellenausschreibung senden:',
  sendingOffer: 'Senden des Angebots ...',
  theCompanyIsInterested: `Das Unternehmen ${args[0]} ist an Ihrer Anzeige interessiert. Nehmen Sie das Angebot an, wenn Sie interessiert sind, um das Gespräch zu beginnen. Hinweis: Alle anderen Angebote werden automatisch abgelehnt.`,
  offerAlreadySent:
    'Pro Anzeige ist nur  ein Gebot zulässig. Um ein anderes Angebot zu senden, müssen Sie das vorherige stornieren.',
  youNeedAPlan:
    'Hoppla! Es sieht so aus, als ob Ihre kostenlose Testversion beendet ist. Bitte abonnieren Sie einen Plan, damit Sie ein Angebot senden können.',
  yourOfferHasAlreadyBeenAccepted:
    'Ihr Angebot wurde bereits angenommen. Weitere Informationen finden Sie im Chat.',
  yourOfferHasAlreadyBeenRejected:
    'Ihr Angebot wurde automatisch abgelehnt, weil der Kunde ein anderes angenommen hat. Wir wünschen Ihnen  viel Glück mit dem nächsten.',
  doYouWantToCancelNow: 'Willst du es jetzt abbrechen?',
  offercancelledSuccesfully: 'Angebot erfolgreich storniert',
  finalize: 'Fertigstellen',
  toCompleteThePost: 'Um den Antrag abzuschließen, benötigen Sie ein Konto.',
  createAccountAndFinalize: 'Konto erstellen und abschließen',

  // PROFILE
  details: 'Kontodaten',
  companyDetails: 'Angaben zum Unternehmen',
  ratings: 'Ratings',
  privacy: 'Datenschutz und Cookies',
  legal: 'Rechtliches',
  info: 'Informationen',
  updates: 'Protokoll aktualisieren',
  current: 'Aktuell',
  subscription: 'Abonnement',
  accountState: 'Kontostatus',
  active: 'Aktiv',
  deactivate: 'Aus',
  trial: 'Test',
  activeMessage: 'Alles läuft gut, es besteht kein Handlungsbedarf.',
  trialMessage: 'Genießen Sie die kostenlose Testversion',
  trialDescription:
    'Es wird automatisch erfüllt, wenn eines Ihrer Angebote angenommen wurde.',
  guest: 'Gast',
  contact: 'Kontakt',
  // SUBSCRIPTION ERRORS
  deactivateDescription:
    'Solange  Ihr Konto deaktiviert bleibt, können Sie keine Angebote senden oder mit neuen Kunden kommunizieren.',
  deactivateDefaultMessage:
    'Wir haben ein Problem mit Ihrem Konto. Bitte kontaktieren Sie uns.',

  'error.payment': 'Wir haben Schwierigkeiten, bezahlt zu werden.',
  'error.subscription': 'Sie haben noch keinen Plan abonniert.',
  'error.trialOff':
    'Ihre kostenlose Testversion ist beendet. Bitte greifen Sie auf ein Abonnement zu, um weiterhin alle Funktionen nutzen zu können.',
  'error.404': 'Das Abonnement existiert nicht.',
  'error.incomplete': 'Der erste Sammelversuch ist fehlgeschlagen.',
  'error.past_due': 'Die Zahlung konnte nicht fristgerecht erfolgen.',
  'error.unpaid': 'Zahlung konnte nicht geleistet werden.',
  'error.cancelled': 'Ihr Abonnement wurde gekündigt',
  'error.incomplete_expired': 'Der Abonnementversuch ist mit Fehler abgelaufen',

  // DETAILS
  changePassword: 'Passwort ändern',
  editData: 'Bearbeiten Sie Ihre Daten',
  editBasicData: 'Grunddaten bearbeiten',
  editCompanyData: 'Unternehmensdaten bearbeiten',
  deleteAccount: 'Konto löschen',
  signOut: 'Abmelden',
  confirmationEmailWillBeSent: 'Sie erhalten eine Bestätigungs-E-Mail.',
  legalForm: 'Verfassung',
  bio: 'Unternehmensinformationen',
  bioPlaceHolder:
    'Beschreiben Sie Ihr Unternehmen so gut wie möglich, diese Einführung kann viele Kunden überzeugen.',
  changeYourAvatar: 'Avatar ändern',
  background: 'Hintergrund',
  hair: 'Haare',
  skin: 'Haut',
  features: 'Merkmale',
  profileUpdatedSuccessfully: 'Profil erfolgreich aktualisiert.',
  accountDeleted: 'Konto erfolgreich gelöscht.',

  // SUBSCRIPTION PAGE
  postulate:
    'Bewerben Sie sich aus Hunderten von Jobs und senden Sie Angebote an potenzielle Kunden.',
  selectThePlan: 'Wählen Sie den Plan, der am besten zu Ihnen passt.',
  annual: 'Jährlich',
  monthly: 'Monatlich',
  promo: 'Promo',
  uniquePayment: 'Einmalzahlung',
  annualDescription: 'Jährlich als wiederkehrende Zahlung berechnet.',
  monthlyDescription:
    'Wird monatlich als wiederkehrende Zahlung berechnet. Ohne Dauerhaftigkeit.',
  uniquePaymentDescription: 'In einer einzigen Zahlung berechnet.',
  billedAnnualy: 'jährlich berechnet',
  billedmonthly: 'monatlich berechnet',
  saveA: 'Save a',
  withAnnualPayment: 'mit der jährlichen Zahlung.',
  continue: 'Weiter',
  overview: 'Allgemeines',
  invoices: 'Rechnungen',
  paymentDetails: 'Zahlungsdaten',
  yourSubscriptionPlan: 'Ihr Abonnement',
  paymentMethod: 'Zahlungsmethode',
  lastInvoice: 'Letzte Rechnung',
  cancelPlan: 'Abmelden',
  changePlan: 'Änderungsplan',
  cardOf: 'Karte von',
  changePaymentMethod: 'Zahlungsmethode ändern',
  introduceAValidPaymentMethod:
    'Bitte  geben Sie unten eine gültige Zahlungsmethode ein.',
  credit: 'Kredit',
  debit: 'Soll',
  cancelSubDialogTitle: 'Abmelden',
  weSorryYouCancel:
    'Es tut uns leid, dass Sie Ihr Abonnement kündigen möchten.',
  rememberThat: 'Denken Sie daran:',
  yourSubWillFinishAt:
    'Auch wenn Sie jetzt kündigen, bleiben Ihre Abonnentenvorteile bis zum Tag: ',
  comeBack: 'Zurück',
  cancelling: 'Stornierung...',
  changing: 'Ändern...',
  yourPlanWillBecancelledAt: 'Ihr Abonnement wird automatisch gekündigt am',
  'plan.year': 'Jährlich',
  'plan.month': 'Monatlich',
  'plan.year.cancelDialogBody':
    'Sie haben die Möglichkeit, zu einem monatlichen Abonnement zu wechseln.',
  'plan.month.cancelDialogBody':
    'Sie haben die Möglichkeit, zu einem Jahresabonnement zu wechseln und eines zu speichern',
  'plan.year.changeQ': 'Möchten Sie zum Monatsplan wechseln?',
  'plan.month.changeQ': 'Möchten Sie zum Jahresplan wechseln?',
  withAnnualPlanYouCanSave: 'Mit dem Jahresplan sparen Sie eine',
  whenChangePlan:
    'Ihr Plan wird jetzt geändert und die Rechnungen werden anteilig mit Ihrem aktuellen Abrechnungszeitraum berechnet.',
  moreOptions: 'Mehr Optionen',
  differentAccountHolder: 'Die Überschrift ist eine andere',
  noActiveSubscription: 'Sie haben keine aktiven Abonnements.',
  subscribeToAPlan:
    'Abonnieren Sie  einen der Pläne, um alle Funktionen zu genießen',
  seePlans: 'Pläne ansehen',
  plans: 'Pläne',
  status: 'Staat',
  'status.active': 'Aktiv',
  'status.incomplete': 'Unvollständig',
  'status.incomplete_expired': 'Abgelaufen',
  'status.trialing': 'Test',
  'status.past_due': 'Zahlbar',
  'status.updaid': 'Unbezahlt',
  'status.canceled': 'Annulliert',

  // CHECKOUT
  checkout: 'Zahlungsdaten',
  finishCheckOut:
    'Schließen Sie den Zahlungsvorgang ab, um Ihr Abonnement abzuschließen.',
  yourOrder: 'Ihr Abonnement',
  annualSubscription: 'Jahresabonnement',
  monthlySubscription: 'Monatsabonnement',
  taxes: 'Steuern',
  total: 'Insgesamt',
  subTotal: 'Zwischensumme',
  subscribe: 'Abonnieren',
  weWillNotStoreYourData:
    'Wir werden Ihre Zahlungsdaten nicht sehen oder speichern. Der Zahlungsvorgang und die Daten werden direkt in das hochsichere System von Stripe verschlüsselt.',
  checkoutName: 'Name des Inhabers',
  securePayment: 'Zahlung',
  safeAndSecure: 'sicher und geschützt',
  garanteed: 'garantiert',
  thankYou: 'Vielen Dank!',
  subscriptionSucceed: 'Ihr Abonnement wurde erfolgreich abgeschlossen.',
  accept: 'OK',
  somethingWentWrong: 'Etwas ist schief gelaufen...',
  thePaymentCouldNotBeDone:
    'Die Zahlung konnte nicht erfolgen. Bitte überprüfen Sie, ob die eingegebenen Daten korrekt sind.',

  // CHAT
  tabAll: 'Alles',
  tabAccepted: 'Angenommen',
  tabRejected: 'Abgelehnt',
  tabNegotiation: 'Verhandeln',
  tabProposal: 'Vorschläge',
  tabStandBy: 'Pendent',
  chat: 'Chat',
  sendAMessage: 'Schreiben Sie eine Nachricht...',
  acceptToWrite: 'Nehmen Sie das Angebot an, zu schreiben...',
  theUserHasToAccept:
    'Ihr Angebot muss angenommen werden, um mit dem Schreiben zu beginnen...',
  jobOfferIdText: 'Ad Id',
  attachedFileText: 'Pfändung',
  offerIdText: 'Angebots-ID',
  theUserIsStudingYourOffer:
    'Er studiert dein Angebot. Sobald er es akzeptiert, können Sie ein Gespräch beginnen, um die Details zu skizzieren.',
  theUserHasAccepted:
    'Er hat Ihr Angebot angenommen und Sie können die Details besprechen.',
  yourOfferHasBeenRejected: 'Sie haben bereits ein anderes Angebot angenommen.',
  thinksThatYouCanBeInterested:
    'Ich denke, Sie könnten an dieser Arbeit interessiert sein.',
  valorate: 'Wert',
  companyNotAvailable:
    'Das Unternehmen hat Ihr Konto im Moment nicht aktiv, bitte versuchen Sie es mit einem anderen Angebot.',
  offer: 'Angebot',
  theOfferNeedToBeAccepted: [
    'Um ein Gespräch mit dem Unternehmen aufzunehmen, müssen Sie sein Angebot annehmen.',
    'Sobald Sie ein Angebot annehmen, werden die anderen automatisch storniert.',
    'Bevor Sie annehmen, können Sie mehr über das Unternehmen erfahren, indem Sie auf den Namen klicken.'
  ],
  you: 'Sie',

  // ALERTS
  areYouSure: 'Vorsicht!',
  youAreGoingToDelete:
    'Sie werden den Beitrag und die Angebote, die ich erhalten habe, löschen. Willst du weitermachen?',
  deleteJobMessage:
    'Diese Aktion kann nicht rückgängig gemacht werden, wenn Sie irgendwelche Details ändern möchten, versuchen Sie, sie an Entwürfe weiterzugeben.',
  youAreGoingToDeleteYourAccount: 'Sie werden Ihr Konto löschen',
  allYourDataWillBeDeleted:
    'Alle Ihre Daten werden aus unserer Datenbank gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.',
  introduceYourPassword: 'Geben Sie Ihr Passwort ein.',
  deleting: 'Eliminierung...',
  incorrectPassword: 'Falsches Passwort.',
  passwordChange: 'Passwort ändern',
  currentPassword: 'Aktuelles Passwort',
  newPassword: 'Neues Passwort',
  change: 'Veränderung',
  minimum8characteres: 'Muss mindestens 8 Zeichen haben',
  anErrorHappens: 'Es ist ein Fehler aufgetreten. Versuchen Sie es später',
  fix: 'Berichtigung',
  new: 'Neuheit',
  news: 'Letzte Änderungen',
  update: 'Aktualisieren',
  later: 'Später',
  newUpdateTitle: 'Update verfügbar',
  newUpdateAvailable:
    'Eine neue Version ist verfügbar. Bitte aktualisieren Sie für die neuesten Nachrichten und Verbesserungen.',
  editingRating:
    'Sie haben dieses Unternehmen bereits geschätzt. Sie können Ihre Bewertung unten bearbeiten.',
  commentSentSuccesfully: 'Ihre Bewertung wurde erfolgreich eingereicht.',
  onlyAvailableForUsers:
    'Diese Funktion steht nur Personen mit einem Konto zur Verfügung.',
  youShouldCompleteTheRequiredFields: 'Sie müssen die Pflichtfelder ausfüllen.',
  postUpdatedSuccessfully: 'Veröffentlichung erfolgreich aktualisiert.',
  jobDeletedSuccesfully: 'Beitrag erfolgreich gelöscht',
  errorLoadingData:
    'Hoppla! Bei der Beschaffung der Daten ist ein Fehler aufgetreten  . Bitte versuchen Sie es später.',
  noResultsWasFound:
    'Leider haben wir keine Ergebnisse gefunden. Testen Sie, indem Sie die Filter von oben modifizieren.',

  // DELETE ACCOUNT PAGE
  pleaseIndicateReason:
    'Bitte teilen Sie uns mit , warum Sie Ihr Konto löschen möchten.',
  requestSended:
    'Ihre Anfrage wurde erfolgreich übermittelt. Sobald wir es studiert haben und alles korrekt ist, werden wir Ihr Konto löschen.',
  toEliminateYouShouldIndicateAReason:
    'Damit wir Ihr Konto löschen können, müssen Sie uns einen Grund nennen.',
  // COUNTRIES
  esp: 'Spanien',
  ch: 'Schweiz',

  deletedAccount: 'Konto gelöscht',

  // PLANS NAMES
  Anual: 'Jährlich',
  Mensual: 'Monatlich'
})

export default de
