const es = (...args) => ({
  // GENERAL
  processing: 'Procesando...',
  sending: 'Enviando...',
  loadMore: 'Cargar más',
  // SIGN IN
  signIn: 'Iniciar sesión',
  signInWelcome: '¡Nos alegra verte de nuevo!',
  forgotPassword: '¿Has olvidado la contraseña?',
  signInEmailError: 'Por favor, revise su correo electrónico.',
  signInPasswordError: 'Por favor, revise su contraseña.',
  tooManyRequest:
    'Has realizado demasiados intentos incorrectos, por favor prueba más tarde.',
  wrongCredentials: 'Credenciales incorrectas.',
  signInText: 'Iniciar sesión',
  signUpText: 'Crear ahora',
  registerQ: '¿No tienes una cuenta?',
  continueAsGuest: 'Continuar como invitado',
  resetPasswordTitle: 'Recuperar contraseña',
  aEmailWillBeSend:
    'Por favor, introduzca la dirección de correo con la que se registró. Recibirá un email para recuperar su contraseña.',
  theEmailHasBeenSent:
    'El email ha sido enviado. Por favor, comprueba tu bandeja de entrada.',
  close: 'Cerrar',
  resetPassword: 'Restablecer contraseña',
  yourUser: 'Tu usuario:',
  'resetError.ExpiredActionCode':
    'El código a expirado, por favor vuelve a solicitar el cambio de contraseña.',
  'resetError.InternalError':
    'Ha ocurrido un error. Vuelve a la app para restablecer tu contraseña.',
  'resetError.Processing':
    'Ha ocurrido un error al procesar su solicitud. Pruebe en otro momento.',
  'resetError.auth/expired-action-code':
    'El código a expirado, por favor vuelve a solicitar el cambio de contraseña.',
  'resetError.auth/invalid-action-code':
    'El código está mal formado o ya se ha utilizado, por favor vuelve a solicitar el cambio de contraseña.',
  'resetError.auth/user-disabled':
    'El usuario correspondiente al código de restablecimiento de contraseña dado ha sido deshabilitado.',
  'resetError.auth/user-not-found':
    'No hay ningún usuario correspondiente al código de restablecimiento de contraseña.',
  'resetError.auth/weak-password':
    'La nueva contraseña no es lo suficientemente segura.',
  passwordResetSuccesfully: 'Contraseña cambiada con éxito.',
  nowYouCanUseNewPass:
    'Ya puedes uilizar tu nueva contraseña para iniciar sesión en tu cuenta de Jobsaun.',

  // SIGN UP
  signUp: 'Registro',
  welcome: 'Te damos la bienvenida',
  asEAsyAsFindTheProfessional: (
    <>
      Tan <span>fácil</span> como encontrar al profesional que necesitas
    </>
  ),
  allReady: '¡Todo Listo!',
  letsCreateYourAccount: 'Vamos a crear tu cuenta',
  accountType: '¿Qué tipo de cuenta quieres crear?',
  completeTheData:
    'Completa el siguiente formulario con los datos de tu cuenta.',
  tellUsMoreAboutYourCompany: 'Cuéntanos más sobre tu empresa.',
  getMoreVisits:
    'Las empresas reciben más contactos cuando tienen imagen y biografía.',
  user: 'Usuario',
  particular: 'Particular',
  company: 'Empresa',
  country: 'País',
  name: 'Nombre completo',
  onlyVisibleForYou: 'Solo será visible para tí',
  publicName: 'Nombre público',
  visibleForEveryone: 'Será visible para todos',
  phoneNumber: 'Teléfono de contacto',
  onlyVisibleOfferAccepted: 'Sólo será visible para las ofertas que aceptes.',
  email: 'Correo electrónico',
  pass: 'Contraseña',
  repeatPass: 'Repite la contraseña',
  howDidYouHear: '¿Cómo nos has encontrado?',
  accept1: 'Al crear una cuenta aceptas los',
  accept2: 'y la',
  terms: 'Términos y condiciones de uso',
  privacyPolicy: 'Política de Privacidad.',
  termsAndPrivacy: (
    <>
      Al crear una cuenta aceptas los{' '}
      <a
        href='https://jobsaun.com/legal/terms'
        target='_blank'
        rel='noreferrer'
      >
        Términos y condiciones de uso
      </a>{' '}
      y la{' '}
      <a
        href='https://jobsaun.com/legal/privacy-and-cookies'
        target='_blank'
        rel='noreferrer'
      >
        Política de Privacidad.
      </a>
    </>
  ),
  signUpButton: 'Crear cuenta',
  maincategory: 'Categoría principal',
  companyName: 'Nombre de la empresa',
  nif: 'NIF',
  province: 'Provincia',
  postalCode: 'Código postal',
  contactName: 'Nombre de contacto',
  signUpEmailError: 'El email no parece ser correcto.',
  phoneError: 'El télefono debe tener 9 dígitos + prefijo.',
  nifError: 'Campo incorrecto, por favor revisalo.',
  passwordError: 'La contraseña debe tener entre 8 y 20 caracteres.',
  repeatPasswordError: 'Las contraseñas no coinciden.',
  required: 'Este campo es obligatorio.',
  notValid: 'Valor no válido',
  youMustSelectACategory: 'Debes seleccionar al menos una categoría.',
  contractRequired:
    'Es necesario aceptar el Contrato de Tramitación de Pedidos para continuar.',
  privacyRequired:
    'Es necesario aceptar nuestras Políticas de Privacidad para continuar.',
  legalFormHelper: 'Ej.: Autónomo',
  contract: 'He leído y acepto el Contrato de Tramitación de Pedidos.',
  pleaseReadTheContract:
    'Por favor, lea el siguiente contrato antes de continuar.',
  ifYouContinue: 'Al continuar aceptas el contrato de tramitación pedidos.',
  seeMyOffers: 'Ver mis ofertas',
  maximumCharacters: 'El número máximo de carácteres permitidos es',
  'error.alreadyInUse': 'El email ya tiene asignada una cuenta.',
  friend: 'Amigo',
  digitalPaper: 'Periódico digital',
  other: 'Otro',
  someFieldIsINcomplete:
    'Algún campo está incompleto. Por favor, vuelve hacia atrás y cumplimentalos todos.',

  // HOME
  exploreCategories: 'Explora las categorías',
  particulars: 'Particulares',
  slider1: 'Encuentra clientes de forma fácil y rápida.',
  slider2: 'Ahora el profesional que necesitas te encuentra a tí.',
  share: 'Compartir',
  search: 'Buscar...',
  categories: 'Categorias',
  whatServiceDoYouNeed: '¿Qué servicio necesitas?',
  homeCTA: (
    <>
      Ahora <span>el profesional que necesitas</span> te encuentra a tí
    </>
  ),
  bestRatedCompanies: 'Las empresas mejor valoradas',
  doYouHaveACompany: '¿Tienes una empresa?',
  joinJobsaun: 'Únete a Jobsaun para hacer tu empresa más visible.',
  joinNowForFree: 'Únete gratis ahora',
  weAreAlready: 'Ya somos',
  andGoingUp: 'empresas y subiendo',

  //  DISCOVER
  reset: 'Restaurar',

  // INBOX
  inboxGuestMessage:
    'Desde el Buzón podrás ponerte en contacto y ultimar detalles con el cliente o empresa.',

  // OFFERS-POSTS
  boardGuestMessage: 'Desde el tablón podrás gestionar tus ofertas o trabajos.',
  pluralPosted: 'Publicados',
  pluralDraft: 'Borradores',
  pluralAssigned: 'Asignados',
  pluralAccepted: 'Aceptadas',
  archived: 'Archivadas',

  // ACCOUNT PAGE
  registerMessage:
    'Aprovecha todas las funcionalidades de la aplicación con una cuenta. Crearla es muy fácil y GRATIS',
  alreadyHasAccount: '¿Ya tienes una cuenta?',
  knowMoreAboutUs: '¿Quiéres saber más sobre nosotros?',
  moreInfo: 'Más información',
  installPWA: 'Mejora la experiencia',
  install: 'Instalar',
  welcomeToJobsaun: 'Te damos la bienvenida a Jobsaun',
  slogan: 'Deja que lo que buscas te encuentre.',
  joinNow: 'Unirme ahora',
  next: 'Siguiente',
  preview: 'Anterior',
  accountSlider_1:
    'Encuentra de forma fácil profesionales verificados para realizar el servicio que necesitas',
  accountSlider_2:
    'Aumenta la visibilidad de tu empresa estando más cerca de los clientes',
  accountSlider_3:
    'Opiniones de usuarios como tú para que puedas evaluar las mejores alternativas',
  accountSlider_4:
    'Recibe presupuestos personalizados que benefician tanto a la empresa como a tí',

  // CATEGORY PAGE
  jobs: 'Trabajos',
  companies: 'Empresas',
  searchInCategory: 'Buscar trabajo en esta categoría...',
  searchInCompanies: 'Buscar empresa en esta categoría...',
  jobFilterTitle: 'Filtro de trabajo',
  proximityFilter: 'Proximidad (km)',
  budgetFilter: 'Presupuesto',
  subcategories: 'Subcategorías',
  selectAll: 'Seleccionar todas',
  unSelectAll: 'Deseleccionar todas',

  // CATEGORIES
  construction: 'Construcción y hogar',
  mechanic: 'Mecánica y transporte',
  care: 'Cuidados y estética',
  leisure: 'Ocio y gastronomía',
  technology: 'Tecnología y marketing',
  others: 'Otros servicios',

  // Subcategories
  // ---Construction
  demolition: 'Demolición',
  verticalsJobs: 'Trabajos verticales (Fachadas)',
  architecture: 'Arquitectura, planificación',
  bathRenovations: 'Baños',
  roofers: 'Techadores, cubiertas',
  welding: 'Soldadura',
  reforms: 'Reformas',
  electrician: 'Electricista, instalaciones eléctricas',
  windows: 'Ventanas y puertas',
  garage: 'Puertas de garaje',
  furnishPicker: 'Montador/a de muebles',
  glass: 'Vidrio y acristalamiento',
  fireplace: 'Chimeneas',
  parquet: 'Parquet, laminado, moqueta',
  isolation: 'Aislamiento',
  kitchen: 'Cocinas',
  decoration: 'Decoración',
  painting: 'Pintura',
  builder: 'Albañil, yeso, hormigón',
  interiorDesign: 'Interiorismo',
  metal: 'Construcción metálica, cerrajería, forja',
  slabs: 'Losas, tejas',
  upholstery: 'Tapicería, talabartería',
  plumbing: 'Fontanero/a',
  carpenter: 'Carpintero/a',
  locksmith: 'Cerrajero/a',
  gardener: 'Jardinero, horticultura',
  earthmoving: 'Construcción de pozos, movimiento de tierras, cimentación',
  greenhouses: 'Invernaderos',
  fences: 'Vallas, portones, mamparas de privacidad',
  remodeling: 'Remodelación, renovación total, ampliación',
  technician: 'Técnico, Instalador de sistemas',
  alarms: 'Alarmas',
  housekeeping: 'Housekeeping, Supervisor/a',
  blinds: 'Persianas, contraventanas, toldos',
  confort: 'Calefacción, ventilación, aire acondicionado, refrigeración',
  climate: 'Climatización',
  cleaning: 'Limpieza',
  applianceTechnician: 'Técnico de electrodomésticos',
  moving: 'Mudanzas',
  scaffolding: 'Andamios',
  paving: 'Trabajos de pavimentación, caminos, carreteras',
  pestControl: 'Control de plagas',
  constructionMaterials: 'Materiales de construcción',

  // ---Mechanic and transport
  mechanics: 'Mecánica, electrónica',
  crane: 'Grúa y translado de vehículos',
  carWash: 'Lavado de vehículos',
  electricCars: 'Vehículos eléctricos',
  wheelbarrows: 'Carretillas',
  chargingPoints: 'Instalacion de puntos de carga',
  smallTransport: 'Transportes, pequeños transportes',
  largeTransport: 'Transportes, grandes transportes',
  carPainting: 'Chapa y pintura',
  carService: 'Servicio de automóviles (Pre-ITV)',
  fineTunning: 'Puesta a punto',
  motorbike: 'Moto, Quad',
  tuning: 'Tuning',

  // ---Care and aesthetics
  tattooArtist: 'Tatuador/a',
  dentist: 'Dentista',
  massageTherapist: 'Masajista',
  osteopathy: 'Osteopatía',
  hairdresser: 'Peluquero/a',
  beautician: 'Esteticista',
  rehabilitation: 'Rehabilitación',
  physiotherapist: 'Fisioterapeuta',
  makeupArtist: 'Maquillador/a',
  acupuncture: 'Acupuntura',
  motivationalCoach: 'Coach de motivación',
  personalTrainer: 'Entrenador/a personal',
  zumbaInstructor: 'Monitor/a de Zumba',
  pilatesInstructor: 'Monitor/a de Pilates',

  // ---Technology and marketing
  programmer: 'Programador/a',
  webProgrammer: 'Programador/a web',
  photographer: ' Fotógrafo/a',
  webDesigner: 'Diseñador/a web',
  multimediaInstaller: 'Instalador/a multimedia',
  applicationProgrammer: 'Programador/a de aplicaciones',
  graphicDesigner: 'Diseñador/a gráfico/a',
  computerTechnician: 'Técnico/a informático/a',
  marketingAndCommunication: 'Marketing y Comunicación',
  printing: 'Imprenta',
  analyst: 'Analista',
  database: 'Base de datos',
  machineLearning: 'Aprendizaje automático',
  productCustomization: 'Personalización de productos',
  merchandising: 'Merchandising',
  artificialIntelligence: 'Inteligencia artificial',
  cyberSecurity: 'Ciber seguridad',
  itSystemsTechnician: 'Técnico/a de sistemas informáticos',
  itSupportTechnician: 'Técnico/a de soporte informático',

  // --Leisure and gastronomy
  cook: 'Cocinero/a',
  catering: 'Catering',
  animator: 'Animador/a',
  socioculturalAnimator: 'Animador/a sociocultural',
  functionalDiversityMonitor:
    'Monitor/a de ocio para personas con diversidad funcional',
  childrenMonitor: 'Monitor/a de ocio infantil',
  freeTimeMonitor: 'Monitor/a de ocio y tiempo libre',
  waiter: 'Camarero/a',
  soundTechnician: 'Técnico/a de sonido',
  dj: 'DJ',
  danceInstructor: 'Instructor/a de baile',

  // ---Others
  model: 'Modelo',
  concierge: 'Conserje',
  administrative: 'Administrativo/a',
  receptionist: 'Recepcionista',
  lifeGuard: 'Salvavidas',
  commercial: 'Comercial',
  teacher: 'Profesor/a',
  privateTeacher: 'Profesor/a privado',
  pedagogue: 'Pedagogo/a',
  seamstress: 'Costurero/a',
  educationalAssistant: 'Asistente educativo/a',
  animalCaretaker: 'Cuidador/a de animales',
  parcelDeliveryMan: 'Mensajero',
  usedOilCollection: 'Recogida de aceite usado',
  socialEducator: 'Educador/a social',
  otherJob: 'Otro trabajo',

  // --------
  filter: 'Filtro',
  filters: 'Filtros',
  orderBy: 'Ordenar por',
  betterRating: 'Mejor valoración',
  moreAcceptedOffers: 'Más ofertas aceptadas',
  youMustSelectAtLeastOneSubcategory:
    'Debes seleccionar al menos una subcategoría.',

  // COMPANY PROFILE
  sendJobOffer: 'Ofrecer trabajo',
  message: 'Mensaje',
  companyInfo: 'Biografía',
  seeMore: 'Ver más',
  seeLess: 'Ver menos',
  customerReviews: 'Valoraciones de clientes',
  noDetailsYet: 'La empresa aun no ha proporcionado detalles.',
  createFreeAccount: 'Crear cuenta gratuita',

  // LEGAL FORMS
  freelance: 'Autónomo',
  civilSociety: 'Sociedad Civil',
  collectiveSociety: 'Sociedad Colectiva',
  limitedLiabilityCompany: 'Sociedad de Responsabilidad Limitada',
  limitedSociety: 'Sociedad Limitada, S.L.',
  anonymousSociety: 'Sociedad Anónima, S.A.',
  cooperativeSociety: 'Sociedad Cooperativa',

  // REVIEWS
  valorations: 'Valoraciones',
  comunication: 'Comunicación',
  times: 'Entrega a tiempo',
  asDescribed: 'Trabajo final',
  recommend: '¿Lo recomendarías?',
  leaveAComment: 'Deja un comentario',

  // PAGES
  home: 'Inicio',
  discover: 'Descubrir',
  inbox: 'Buzón',
  messages: 'Mensajes',
  offers: 'Ofertas',
  board: 'Tablón',
  posts: 'Publicaciones',
  profile: 'Perfil',
  category: 'Categoría',
  newOffer: 'Nueva oferta',

  // JOB OFFERS
  posted: 'Publicado',
  startDate: 'Fecha de comienzo',
  preferredStartDate: 'Fecha deseada',
  endDate: 'Fecha fin/entrega',
  mainDetails: 'Detalles',
  otherDeatils: 'Otros detalles',
  receivedOffers: 'Ofertas recibidas',
  createAnOffer: 'Enviar una oferta',
  noOfferYet: 'Este trabajo no ha recibido ninguna oferta aun, ¡Se el primero!',
  offerAlert: 'Para crear una oferta debes iniciar sesión como empresa.',
  rateType: 'Tipo de tarifa',
  flatRate: 'Tarifa plana',
  day: 'día',
  month: 'mes',
  year: 'año',
  amount: 'Cantidad',
  addEndDate: 'Añadir fecha de finalización',
  attachFile: 'Adjuntar archivo',
  sendOffer: 'Enviar oferta',
  offerMessageHelper: 'Se mostrará como primer mensaje del chat.',
  fileError: 'Error de archivo: solo se aceptan .pdf y el suyo es',
  maxFileSize: 'El tamaño máximo del archivo es de 10MB',
  budget: 'Presupuesto',
  editPost: 'Editar publicación',
  nothingPostedYet: 'Aun no has publicado nada',
  tryFromTheSearchbar: 'Prueba desde la barra de búsqueda superior',
  nothingInDrafts: 'No hay nada en borradores',
  nothingInAssigned: 'No hay nada en asignados',
  nothingInArchived: 'No hay nada en archivados',
  nothingInRejected: 'No tienes ofertas rechazadas',
  nothingInAccepted: 'No tienes ofertas aceptadas',
  nothingInNegotiating: 'Envia ofertas para comenzar la negociación',
  nothingFound: 'No hay resultados para la búsqueda',
  StartSendingOffersToPotencialCustomers:
    'Comienza enviando ofertas a las publicaciones de potenciales clientes',
  'errors.no_active_subscription_found':
    'Debes suscribirte a un plan para poder enviar ofertas.',

  // Questions
  'question.flexibleHours': '¿Tiene flexibilidad con los horarios?',
  'question.availableToNegociate':
    'Estarías en disposición de negociar el presupuesto?',
  'question.payInAdvance': '¿Pagarías por adelantado si fuera necesario?',
  'question.accesible': '¿El lugar es de fácil acceso?',
  'question.availabilityForTravel':
    '¿Tiene disponibilidad para desplazar el vehículo?',
  'question.parking': '¿Hay parking en la zona?',
  'question.weekends': '¿Se permite trabajar en fin de semana?',
  'question.material': '¿Se aporta el material?',
  'question.neededParts': '¿Dispone de las piezas que necesita?',
  'question.crane': '¿Necesita Grua?',
  'question.bigTrees': '¿Tiene árboles áltos?',
  'question.water': '¿Tiene fuentes u otras zonas con agua?',
  'question.workAtHome': '¿Se puede realizar el trabajo en el domicilio?',
  'question.goToTheHome': '¿Necesita que se desplacen al domicilio?',

  'question.squareMeters': '¿Cuántos m² tiene el emplazamiento?',

  // Answers
  'answer.flexibleHours': 'Flexibilidad en los horarios',
  'answer.availableToNegociate': 'Presupuesto negociable',
  'answer.payInAdvance': 'Disponibilidad a pagar por adelantado',
  'answer.accesible': 'Lugar de fácil acceso',
  'answer.availabilityForTravel': 'Disponibilidad de desplazamiento',
  'answer.parking': 'Parking en la zona',
  'answer.weekends': 'Se permite trabajar en fin de semana',
  'answer.material': 'El cliente aporta el material',
  'answer.neededParts': 'Dispone de las piezas necesarias',
  'answer.crane': 'Se necesita grua',
  'answer.bigTrees': 'Árboles altos',
  'answer.water': 'Fuentes u otras zonas con agua',
  'answer.workAtHome': 'Posibilidad de trabajar en el domicilio',
  'answer.goToTheHome': 'Es necesario desplazarse al domicilio',

  'answer.squareMeters': 'Metros cuadrados del emplazamiento',

  // OTHERS
  shareTheApp: 'Compartir la app',
  shareTheAppMessage:
    'Comparte la aplicación de Jobsaun con tus amigos, enséñales lo bueno/a que eres encontrando nuevas y útiles aplicaciones.',
  goToChat: 'Ir al chat',
  seeMoreJobs: 'Ver más trabajos',
  sendingYourOffer: 'Enviando oferta',
  itCanTakeAMoment:
    'Este proceso puede tardar un rato. Por favor, ten paciencia.',
  offerSuccessSent: 'Oferta enviada satisfactoriamente',
  continueWith: '¿Qué deseas hacer a continuación?',
  users: 'usuarios',
  yes: 'Sí',
  no: 'No',
  save: 'Guardar',
  saveDraft: 'Guardar borrador',
  toPost: 'Publicar',
  saving: 'Guardando...',
  posting: 'Publicando...',
  postingJob: 'Tu trabajo se está publicando',
  pleaseBePatient: 'Por favor, espera a que la acción termine.',
  nothingHere: 'Nada por aquí...',
  tryAnother: 'Prueba con otra búsqueda o categoría.',
  settings: 'Ajustes',
  account: 'Cuenta',
  sent: 'Enviada',
  youHaveToSignIn:
    'Debes iniciar sesión para tener acceso a esta característica.',
  loading: 'Cargando...',
  all: 'Todas',

  // POSTED JOBS
  postedJobs: 'Trabajos publicados',
  send: 'Enviar',
  cancel: 'Cancelar',
  acceptedOffer: 'Oferta aceptada',
  cancelledOffer: 'Oferta cancelada',
  acceptOffer: 'Aceptar oferta',
  draft: 'Borrador',
  negotiating: 'Negociando',
  rejected: 'Rechazada',
  accepted: 'Aceptada',
  cancelled: 'Cancelada',
  proposed: 'Propuesta',
  assigned: 'Asignado',
  noDate: 'Sin fecha',
  anyJobWasFound:
    'No se ha encontrado ningún trabajo publicado que corresponda a las categorias de la empresa.',
  theProposalHasBeenSent: 'La propuesta se ha mandado con éxito',
  youAlreadySentThisJob: 'Ya has enviado este trabajo a la empresa.',
  theCompanyAlreadySentYouAnOffer:
    'La empresa ya te ha enviado una oferta acerca de este trabajo.',
  theCompanyHasCancelTheOffers:
    'La empresa ha cancelado las ofertas para este trabajo.',
  // MENUS
  moveToDraft: 'Mover a borradores',
  edit: 'Editar',
  delete: 'Eliminar',
  newFirst: 'Nuevos primero',
  oldFirst: 'Antiguos primero',
  edited: 'Editado el',

  // NEW JOB
  newJob: 'Nuevo trabajo',
  title: 'Título',
  subCategory: 'Subcategoría',
  explainTheNeededService:
    'Explica con los máximos detalles posibles el servicio que necesitas, para que los profesionales te hagan la oferta más justa.',
  postDescription: 'Descripción del servicio',
  city: 'Ciudad',
  helperCity:
    'Algo ha fallado al obtener la ciudad, por favor revisa el código postal, o escríbela a mano.',
  uploadPictures: 'Imágenes',
  maximumSizeExceded: 'Se ha superado el tamaño máximo de archivo',
  maximumNumberOfFiles: 'Se ha superado el número máximo de archivos',
  notAllowFile: 'El archivo no está permitido.',
  allowFiles: 'Archivos permitidos',
  otherDetails: 'Otros detalles',
  congrats: '¡Felicidades!',
  postedSucceed: 'El trabajo ha sido publicado con éxito.',
  justWait:
    'Ahora solo tienes que esperar a recibir las ofertas de las empresas interesadas.',
  seeTheJob: 'Ver la pubicación',
  fiveDigits: 'Debe haber 5 dígitos.',
  alreadyUploadThisFile: 'Ya has subido este archivo',
  optional: 'Opcional',
  willLoseTheProcessOnCancel: 'Al cancelar perderás todo el progreso.',
  noCategoryFound:
    'Lo sentimos, no hemos encontrado una categoría con la búsqueda realizada. Por favor, selecciona la categoría más adecuada desde las opciones de abajo.',
  weHaveSelectedTheNextCategory:
    'Basándonos en tu búsqueda hemos seleccionado la siguiente categoría, si crees que no es correcto, por favor, selecciona la más adecuada.',
  localitation: 'Localización',
  searchCompanies: 'Buscar empresas',
  lookingCompanies: 'Buscando empresas',
  thisCanTakeAWhile: 'Esto puede llevar un tiempo, por favor ten paciencia.',
  weHaveFound: 'Hemos encontrado...',
  whatDoYouWantToDoNext: '¿Qué deseas hacer a continuación?',
  seeCompanies: 'Ver empresas',
  continueWithTheApplication: 'Continuar con la solicitud',
  weAreSorryWeStillGrowing:
    'Lo sentimos, seguimos creciendo y cada vez más empresas se unen a Jobsaun. Continua la solicitud para que las empresas se pongan en contacto contigo.',
  whatBudgetDoYouHave: '¿De qué presupuesto dispones?',
  budgetSubtitle:
    'Esto es solo una aproximación para que las empresas conozcan la cantidad máxima que puedes permitirte.',
  recommended: 'Recomendado',
  lessThan: 'Hasta',
  moreThan: 'Mayor de',
  whenDoYouNeedIt: '¿Cuándo lo necesitas?',
  tellUsYourPreferences: 'Índicanos tus preferencias',
  doYouWantToProvideAnImage:
    '¿Quieres aportar alguna imagen que ayude al profesional?',
  dragAndDropOrBrowse: 'Arrastra y suelta o',
  browse: 'pulsa aquí',
  allow: 'Permitido',
  maxFilesSize: 'tamaño máximo',
  of: 'de',
  image: 'Imagen',
  'imageErrors.file-too-large': `El archivo ${args[0]} es demasiado grande. El tamaño máximo es de ${args[1]} y el archivo tiene un tamaño de ${args[2]}`,
  'imageErrors.file-invalid-type': `El tipo del archivo debe ser alguno de los siguientes: ${args[0]}`,
  'imageErrors.too-many-files': `Demasiados archivos seleccionados, el máximo es de ${args[0]} archivos.`,
  'imageErrors.bad-file': `El archivo ${args[0]} no se puede adjuntar. Por favor, prueba con otro.`,
  postDetails: 'Detalles de la solicitud',
  'post.sending': 'Tu solicitud se está enviando',
  'post.pleaseBePatient':
    'Esto puede llevar un momento. Por favor, ten paciencia.',
  'post.added': 'Publicación creada con éxito.',
  'error.post.adding':
    'No se a podido crear la publicación. Por favor, prueba en otro momento.',
  'error.post.updating':
    'No se pudo actualizar la publicación. Por favor, inténtalo nuevamente más tarde.',
  'error.post.deleting':
    'No se ha podido eliminar la publicación. Por favor, prueba en otro momento.',
  foundCompanies: 'Empresas encontradas',
  weCoudntFindAnyCompany:
    'Oops! Parece que no hemos podido encontrar ninguna empresa con esos criterios.',

  // NEW OFFER
  sendOfferTo: 'Vas a enviar una oferta para el anuncio de trabajo:',
  sendingOffer: 'Enviando la oferta...',
  theCompanyIsInterested: `La empresa ${args[0]} está interesada en tu anuncio. Acepta la oferta, si te interesa, para empezar la conversación. Nota: Las demás ofertas se rechazarán automáticamente.`,
  offerAlreadySent:
    'Sólo se permite envíar una oferta por anuncio. Para enviar otra oferta debes cancelar la anterior.',
  youNeedAPlan:
    'Ups! parece que tu prueba gratuita a finalizado. Por favor, suscribete a un plan para poder mandar una oferta.',
  yourOfferHasAlreadyBeenAccepted:
    'Tu oferta ya ha sido aceptada. Dirígete al chat para más detalles.',
  yourOfferHasAlreadyBeenRejected:
    'Tu oferta ha sido rechazada automáticamente porque el cliente ha aceptado otra. Te desamos suerte con la próxima.',
  doYouWantToCancelNow: '¿Quieres cancelarla ahora?',
  offercancelledSuccesfully: 'Oferta cancelada con éxito',
  finalize: 'Finalizar',
  toCompleteThePost: 'Para completar la solicitud necesitas una cuenta.',
  createAccountAndFinalize: 'Crear cuenta y finalizar',

  // PROFILE
  details: 'Detalles de la cuenta',
  companyDetails: 'Detalles de la empresa',
  ratings: 'Valoraciones',
  privacy: 'Privacidad y Cookies',
  legal: 'Legal',
  info: 'Información',
  updates: 'Registro de actualizaciones',
  current: 'Actual',

  subscription: 'Suscripción',
  accountState: 'Estado de la cuenta',
  active: 'Activa',
  deactivate: 'Desactivada',
  trial: 'Prueba',
  activeMessage: 'Todo va bien, no se requiere ninguna acción.',
  trialMessage: 'Disfruta de la prueba gratuita.',
  trialDescription:
    'Cumplirá automáticamente cuando una de tus ofertas haya sido aceptada.',

  guest: 'Invitado',
  contact: 'Contacto',
  // SUBSCRIPTION ERRORS
  deactivateDescription:
    'Mientra su cuenta permaneza desactivada no podrá mandar ofertas o comunicarse con nuevos clientes.',
  deactivateDefaultMessage:
    'Estamos teniendo algún tipo de problema con su cuenta. Por favor, póngase en contacto con nosotros.',

  'error.payment': 'Estamos teniendo problemas en recibir sus pagos.',
  'error.subscription': 'Aún no se ha suscrito a ningún plan.',
  'error.trialOff':
    'Tu prueba gratuita ha finalizado. Por favor, accede a una suscripción para seguir disfrutando de todas las características.',
  'error.404': 'La suscripción no existe.',
  'error.incomplete': 'El intento de cobro inicial ha fallado.',
  'error.past_due':
    'No se ha podido efectuar el cobro en la fecha de vencimiento.',
  'error.unpaid': 'No se ha podido efectuar el cobro de la suscripción.',
  'error.cancelled': 'La suscripción se ha cancelado',
  'error.incomplete_expired': 'El intento de suscripción ha expirado con error',

  // DETAILS
  changePassword: 'Cambiar la contraseña',
  editData: 'Editar tus datos',
  editBasicData: 'Editar datos básicos',
  editCompanyData: 'Editar datos de la empresa',
  deleteAccount: 'Eliminar cuenta',
  signOut: 'Cerrar sesión',
  confirmationEmailWillBeSent: 'Recibirás un email de confirmación.',
  legalForm: 'Constitución',
  bio: 'Información de la empresa',
  bioPlaceHolder:
    'Describe tu empresa lo mejor posible, esta introducción puede convencer a muchos clientes.',
  changeYourAvatar: 'Cambiar avatar',
  background: 'Fondo',
  hair: 'Cabello',
  skin: 'Piel',
  features: 'Rasgos',
  profileUpdatedSuccessfully: 'Perfil actualizado con éxito.',
  accountDeleted: 'Cuenta eliminada satisfactoriamente.',

  // SUBSCRIPTION PAGE
  postulate:
    'Postula entre cientos de trabajos y envía presupuestos a clientes potenciales.',
  selectThePlan: 'Selecciona el plan que más te convenga.',
  annual: 'Anual',
  monthly: 'Mensual',
  promo: 'Promo',
  uniquePayment: 'Pago único',
  annualDescription: 'Cargados anualmente como pago recurrente.',
  monthlyDescription:
    'Cargados mensualmente como pago recurrente. Sin permanencia.',
  uniquePaymentDescription: 'Cargados en un único pago.',
  billedAnnualy: 'cargados anualmente.',
  billedmonthly: 'cargados mensualmente.',
  saveA: 'Ahorra un',
  withAnnualPayment: 'con el pago anual.',
  continue: 'Continuar',
  overview: 'General',
  invoices: 'Facturas',
  paymentDetails: 'Detalles de pago',
  yourSubscriptionPlan: 'Tu suscripción',
  paymentMethod: 'Método de pago',
  lastInvoice: 'Última factura',
  cancelPlan: 'Cancelar suscripción',
  changePlan: 'Cambiar plan',
  cardOf: 'Tarjeta de',
  changePaymentMethod: 'Cambiar método de pago',
  introduceAValidPaymentMethod:
    'Introduce a continuacion un método de pago válido.',
  credit: 'crédito',
  debit: 'débito',
  cancelSubDialogTitle: 'Cancelar suscripción',
  weSorryYouCancel: 'Sentimos que quieras cancelar tu suscripción.',
  rememberThat: 'Recuerda que:',
  yourSubWillFinishAt:
    'Aunque canceles ahora, tus ventajas como suscriptor seguirán funcionando hasta el día:',
  comeBack: 'Volver',
  cancelling: 'Cancelando...',
  changing: 'Cambiando...',
  yourPlanWillBecancelledAt: 'Tu suscripción se cancelará automáticamente el',
  'plan.year': 'Anual',
  'plan.month': 'Mensual',
  'plan.year.cancelDialogBody':
    'Tienes la opción de cambiar a una suscripción mensual.',
  'plan.month.cancelDialogBody':
    'Tienes la opción de cambiar a una suscripción anual y ahorrar un',
  'plan.year.changeQ': '¿Quieres cambiar al Plan Mensual?',
  'plan.month.changeQ': '¿Quieres cambiar al Plan Anual?',
  withAnnualPlanYouCanSave: 'Con el Plan Anual ahorras un',
  whenChangePlan:
    'Tu plan se cambiará en este mismo momento y las facturas se prorratearán con tu periodo de facturación actual.',
  moreOptions: 'Más opciones',
  differentAccountHolder: 'El titular es diferente',
  noActiveSubscription: 'No tienes ninguna suscripción activa.',
  subscribeToAPlan:
    'Suscribete a alguno de los planes para disfrutar de todas las características',
  seePlans: 'Ver planes',
  plans: 'Planes',
  status: 'Estado',
  'status.active': 'Activa',
  'status.incomplete': 'Incompleta',
  'status.incomplete_expired': 'Expirada',
  'status.trialing': 'Prueba',
  'status.past_due': 'Por pagar',
  'status.updaid': 'Inpagada',
  'status.canceled': 'Cancelada',

  // CHECKOUT
  checkout: 'Detalles de pago',
  finishCheckOut: 'Termina el proceso de pago para completar tu suscripción.',
  yourOrder: 'Tu suscripción',
  annualSubscription: 'Suscripción anual',
  monthlySubscription: 'Suscripción mensual',
  taxes: 'Impuestos',
  total: 'Total',
  subTotal: 'Subtotal',
  subscribe: 'Suscribirme',
  weWillNotStoreYourData:
    'No veremos ni almacenaremos sus datos de pago. El proceso de pago y los datos van encriptados directamente al sistema de alta seguridad de Stripe.',
  checkoutName: 'Nombre del titular',
  securePayment: 'Pago',
  safeAndSecure: 'seguro y protegido',
  garanteed: 'garantizado',
  thankYou: '¡Gracias!',
  subscriptionSucceed: 'Tu suscripción se ha completado con éxito.',
  accept: 'Aceptar',
  somethingWentWrong: 'Algo ha ido mal...',
  thePaymentCouldNotBeDone:
    'El pago no se ha podido efectuar. Por favor comprueba que los datos introducidos son correctos',

  // CHAT
  tabAll: 'Todo',
  tabAccepted: 'Aceptadas',
  tabRejected: 'Rechazadas',
  tabNegotiation: 'Negociando',
  tabProposal: 'Propuestas',
  tabStandBy: 'Pendientes',
  chat: 'Chat',
  sendAMessage: 'Escribe un mensaje...',
  acceptToWrite: 'Acepta la oferta para escribir...',
  theUserHasToAccept: 'Tu oferta debe ser aceptada para comenzar a escribir...',
  jobOfferIdText: 'Anuncio Id',
  attachedFileText: 'Archivo adjunto',
  offerIdText: 'Oferta Id',
  theUserIsStudingYourOffer:
    'está estudiando tu oferta. Una vez que la acepte, podréis entablar conversación para perfilar los detalles.',
  theUserHasAccepted:
    'ha aceptado tu oferta y ya podéis discutir los detalles.',
  yourOfferHasBeenRejected: 'ya ha aceptado otra oferta.',
  thinksThatYouCanBeInterested: 'cree que puede interesarte este trabajo.',
  valorate: 'Valorar',
  companyNotAvailable:
    'La empresa no tiene su cuenta activa en estos momentos, por favor, prueba con otra oferta.',
  offer: 'Oferta',
  theOfferNeedToBeAccepted: [
    'Para establecer conversación con la empresa debes aceptar su oferta.',
    'Una vez aceptes una oferta, las demás se cancelarán automáticamente.',
    'Antes de aceptar, puedes consultar más información acerca de la empresa pulsando en su nombre.'
  ],
  you: 'tú',

  // ALERTS
  areYouSure: '¡Cuidado!',
  youAreGoingToDelete:
    'Vas a eliminar la publicación y las ofertas que haya recibido. ¿Quieres continuar?',
  deleteJobMessage:
    'Está acción no se puede deshacer, si quieres cambiar algún detalle prueba a pasarlo a borradores.',
  youAreGoingToDeleteYourAccount: 'Vas a eliminar tu cuenta',
  allYourDataWillBeDeleted:
    'Todos tus datos se borrarán de nuestra base de datos. Esta acción no se puede deshacer.',
  introduceYourPassword: 'Introduce tu contraseña.',
  deleting: 'Eliminando...',
  incorrectPassword: 'Contraseña incorrecta.',
  passwordChange: 'Cambio de contraseña',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  change: 'Cambiar',
  minimum8characteres: 'Debe tener cómo mínimo 8 carácteres',
  anErrorHappens: 'A ocurrido un error. Prueba más tarde',
  fix: 'corrección',
  new: 'novedad',
  news: 'Últimos cambios',
  update: 'Actualizar',
  later: 'Más tarde',
  newUpdateTitle: 'Actualización disponible',
  newUpdateAvailable:
    'Una nueva versión está disponible. Por favor, actualiza para disponer de las últimas novedades y mejoras.',
  editingRating:
    'Ya has valorado a esta empresa. Puedes editar tu valoración a continuación.',
  commentSentSuccesfully: 'Tu valoración se ha enviado con éxito.',
  onlyAvailableForUsers:
    'Esta función solo está disponible para particulares con cuenta.',
  youShouldCompleteTheRequiredFields:
    'Tienes que cumplimentar los campos obligatorios.',
  postUpdatedSuccessfully: 'Publicación actualizada con éxito.',
  jobDeletedSuccesfully: 'Publicación eliminada con éxito',
  errorLoadingData:
    'Ups! ha habido un errror al obtener los datos. Por favor prueba más tarde.',
  noResultsWasFound:
    'Lo sentimos, no hemos encontrado ningún resultado. Prueba modificando los filtros desde la parte superior.',

  // DELETE ACCOUNT PAGE
  pleaseIndicateReason:
    'Por favor indiquenos el motivo por el que quiere eliminar su cuenta.',
  requestSended:
    'Su petición se ha enviado correctamente. Una vez la estudiemos y todo esté correcto, procederemos a la eliminación de su cuenta.',
  toEliminateYouShouldIndicateAReason:
    'Para que podamos eliminar su cuenta debe indicarnos un motivo.',
  // COUNTRIES
  esp: 'España',
  ch: 'Suiza',

  deletedAccount: 'Cuenta eliminada',

  // PLANS NAMES
  Anual: 'Anual',
  Mensual: 'Mensual'
})

export default es
