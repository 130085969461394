import { Box, ButtonBase, Typography } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { TickSquare } from 'iconsax-react'

export default function InlineCheck({ onClick, text, selected }) {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 1
      }}
    >
      <Typography
        fontWeight={selected ? 500 : 400}
        color={selected ? 'inherit' : 'textSecondary'}
        sx={{ transition: 'color 250ms ease' }}
      >
        {text}
      </Typography>
      <AnimatePresence>
        {selected && (
          <Box
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            as={motion.div}
            sx={{ display: 'inline-flex', color: 'success.main' }}
          >
            <TickSquare size={20} variant='Bold' />
          </Box>
        )}
      </AnimatePresence>
    </ButtonBase>
  )
}
