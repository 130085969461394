import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import {
  Button,
  Dialog,
  Modal,
  ProgressButton,
  ScrollableContainer,
  Sending,
  Spacer
} from 'components'
import { routes } from 'constants/Routes'
import { deviceInfo, getCategoryByContext } from 'helpers'
import { useI18N, usePost, useSignUp } from 'hooks'
import { useUser } from 'context/UserContext'
import { useHistory } from 'react-router-dom'
import CategorySelectorPage from './newPost/CategorySelectorPage'
import LocationSelectorPage from './newPost/LocationSelectorPage'
import CompaniesCountDialog from './newPost/CompaniesCountDialog'
import BudgetSelectorPage from './newPost/BudgetSelectorPage'
import DateSelectorPage from './newPost/DateSelectorPage'
import QuestionsSelectorPage from './newPost/QuestionsSelectorPage'
import ImagesSelectorPage from './newPost/ImagesSelectorPage'
import DescriptionPage from './newPost/DescriptionPage'
import SignUpPage from './newPost/SignUpPage'
import { useMutation } from '@apollo/client'
import { ADD_SERVICES_SUBMIT_STATISTICS } from 'apis/mutations'
import { ROLE } from 'constants/Roles'
import { getRandomBg } from 'constants/ProfilePictures'
import { isEmail, isNotEmpty, useForm } from '@mantine/form'
import { useSnackbar } from 'context/SnackBarContext'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const maxPublicNameLength = 15

const STEPS = {
  CATEGORY: 0,
  LOCALITATION: 1,
  BUDGET: 2,
  DATE: 3,
  QUESTIONS: 4,
  PICTURES: 5,
  DESCRIPTION: 6,
  SIGNUP: 7
}

export default function NewPost() {
  const { t, locale } = useI18N()
  const { user } = useUser()
  const [activeStep, setActiveStep] = useState(STEPS.CATEGORY)
  const [canceling, setCanceling] = useState(false)
  const [companiesDialogOpen, setCompaniesDialogOpen] = useState(false)
  const history = useHistory()
  const { query } = history.location.state || ''
  const [saveSearchStatistic] = useMutation(ADD_SERVICES_SUBMIT_STATISTICS)
  const { loading: signingUp, signup } = useSignUp()
  const { adding: posting, add, posted } = usePost()
  const { showSnackbar } = useSnackbar()
  const category = query ? getCategoryByContext(locale, query) : ''

  useEffect(() => {
    if (query === null || query === undefined) return history.replace(routes.home)

    // Avoid saving statistics on development
    if (process.env.NODE_ENV === 'development') return

    const saveStatistics = async () => {
      const info = await deviceInfo()

      saveSearchStatistic({
        variables: {
          type: 'services',
          data: {
            value: query,
            loggedIn: Boolean(user?.id),
            found: category,
            ...info
          }
        }
      })
    }

    saveStatistics()
  }, [])

  const form = useForm({
    initialValues: {
      category,
      localitation: {
        country: '',
        province: '',
        city: '',
        postal_code: ''
      },
      budget: 500,
      date: tomorrow,
      questions: [], // Array of objects: {id, answer, main}
      pictures: [], // Array of objects: {type, name, url}
      title: '',
      description: '',
      userData: {
        country: '',
        name: '',
        public_name: '',
        email: '',
        password: '',
        repeatPassword: ''
      }
    },
    validate: {
      category: isNotEmpty(t('youMustSelectACategory')),
      localitation: {
        country: isNotEmpty(t('required')),
        province: isNotEmpty(t('required')),
        city: isNotEmpty(t('required')),
        postal_code: isNotEmpty(t('required'))
      },
      questions: (value) => (value.length === 3 ? null : t('required')),
      title: isNotEmpty(t('required')),
      description: isNotEmpty(t('required')),
      ...(!user.id && {
        userData: {
          country: isNotEmpty(t('required')),
          name: isNotEmpty(t('required')),
          public_name: (passedValue) => {
            const value = passedValue.trim()

            if (value.length < 1) return t('required')

            if (!value.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s.]+$/g)) {
              // Only letters
              return t('notValid')
            }
            if (value.length > maxPublicNameLength) {
              // Maximum length
              return `${t('maximumCharacters')} ${maxPublicNameLength}`
            }
            if (/^\S+@\S+$/.test(value.trim())) {
              // Not allow emails
              return t('notValid')
            }
            return null
          },
          email: isEmail(t('signUpEmailError')),
          password: (passedValue) => {
            const value = passedValue.trim()
            return value.length < 8 || value.length > 20
              ? t('passwordError')
              : null
          },
          repeatPassword: (value, values) =>
            value === values.userData.password ? null : t('repeatPasswordError')
        }
      })
    }
  })

  const values = form.values
  const errors = form.errors

  const processSteps = Object.keys(STEPS).length - 1

  const onCancel = () => {
    setCanceling(true)
  }

  const onBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleCancel = () => {
    history.replace(routes.home)
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1)
    if (companiesDialogOpen) setCompaniesDialogOpen(false)
  }

  const searchCompanies = () => {
    setCompaniesDialogOpen(true)
  }

  const signUpUser = () => {
    const data = values.userData

    if (form.validate().hasErrors) {
      showSnackbar({
        message: t('someFieldIsINcomplete')
      })
      return
    }

    const { email, name, public_name, password, country } = data
    const valuesToSave = {
      country,
      email,
      password,
      name,
      public_name,
      picture: { pic: 'c01s01t01', bg: getRandomBg() },
      role: ROLE.PARTICULAR,
      about_us: 'search'
    }
    signup(valuesToSave).then((userData) => {
      post(userData.id)
    })
  }

  const post = async (userId) => {
    const data = {
      userId,
      title: values.title,
      category: values.category,
      country: values.localitation.country,
      province: values.localitation.province,
      postalCode: values.localitation.postal_code,
      city: values.localitation.city,
      budget: parseInt(values.budget),
      startDate: values.date,
      description: values.description,
      questions: values.questions,
      state: 'posted',
      postDate: new Date()
    }

    add({ variables: data, pictures: values.pictures })
  }

  const isDisabled = () => {
    if (signingUp) return true

    switch (activeStep) {
      case STEPS.CATEGORY:
        return !values.category
      case STEPS.LOCALITATION:
        return Object.values(values.localitation).some((i) => i === '')
      case STEPS.QUESTIONS:
        return values.questions.length < 3
      case STEPS.DESCRIPTION:
        return !values.title || !values.description
      default:
        return false
    }
  }

  const getSubmitButtonText = () => {
    switch (activeStep) {
      case STEPS.LOCALITATION:
        return t('searchCompanies')
      case STEPS.DESCRIPTION:
        return t('finalize')
      case STEPS.SIGNUP:
        return t('createAccountAndFinalize')
      default:
        return t('continue')
    }
  }

  const getSubmitButtonAction = () => {
    switch (activeStep) {
      case STEPS.LOCALITATION:
        return searchCompanies
      case STEPS.DESCRIPTION:
        return Boolean(user.id) === true ? () => post(user.id) : handleNext
      case STEPS.SIGNUP:
        return signUpUser
      default:
        return handleNext
    }
  }

  const handleGoToPosts = () => {
    history.replace(routes.board)
  }

  const handleInputChange = (value) => {
    const valuesKeys = Object.keys(value)
    for (const key of valuesKeys) {
      form.setFieldValue(key, value[key])
    }
  }

  return (
    <>
      <ScrollableContainer
        appBarProps={{
          hasBack: activeStep > 0,
          customGoBack: onBack,
          actions: (
            <Button
              onClick={onCancel}
              variant='text'
              text={t('cancel')}
              sx={{
                color: 'text.primary',
                '&:hover': { bgcolor: 'action.hover' }
              }}
            />
          )
        }}
      >
        <Spacer m={2} pb={9}>
          {activeStep === STEPS.CATEGORY && (
            <CategorySelectorPage
              category={values.category}
              onChange={handleInputChange}
            />
          )}
          {activeStep === STEPS.LOCALITATION && (
            <LocationSelectorPage
              values={values.localitation}
              onChange={handleInputChange}
            />
          )}
          {activeStep === STEPS.BUDGET && (
            <BudgetSelectorPage
              budget={values.budget}
              onChange={handleInputChange}
            />
          )}
          {activeStep === STEPS.DATE && (
            <DateSelectorPage date={values.date} onChange={handleInputChange} />
          )}
          {activeStep === STEPS.QUESTIONS && (
            <QuestionsSelectorPage
              values={values.questions}
              category={values.category}
              onChange={handleInputChange}
            />
          )}
          {activeStep === STEPS.PICTURES && (
            <ImagesSelectorPage
              values={values.pictures}
              onChange={handleInputChange}
            />
          )}
          {activeStep === STEPS.DESCRIPTION && (
            <DescriptionPage
              title={values.title}
              description={values.description}
              onChange={handleInputChange}
            />
          )}
          {activeStep === STEPS.SIGNUP && (
            <SignUpPage
              values={values.userData}
              errors={errors}
              onChange={handleInputChange}
            />
          )}
        </Spacer>
      </ScrollableContainer>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          bgcolor: 'background.default'
        }}
      >
        <ProgressButton
          onClick={getSubmitButtonAction()}
          text={getSubmitButtonText()}
          disabled={isDisabled()}
          loading={signingUp}
          fullWidth
          progress={
            activeStep > STEPS.LOCALITATION && activeStep < STEPS.SIGNUP
              ? ((activeStep + 1) * 100) / processSteps
              : null
          }
        />
      </Box>

      <Modal open={canceling}>
        <Dialog>
          <Dialog.Title title={t('willLoseTheProcessOnCancel')} />
          <Dialog.Actions>
            <Button
              onClick={handleCancel}
              fullWidth
              text={t('cancel')}
              sx={{
                bgcolor: 'error.main',
                '&:hover': { bgcolor: 'error.light' }
              }}
            />
            <Button
              onClick={() => setCanceling(false)}
              fullWidth
              text={t('comeBack')}
            />
          </Dialog.Actions>
        </Dialog>
      </Modal>

      <Modal open={posting}>
        <Dialog>
          <Dialog.Title
            title={t('post.sending')}
            subtitle={posted ? t('post.added') : t('post.pleaseBePatient')}
          />
          <Dialog.Content>
            <Box p={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Sending size={120} loaded={posted} />
            </Box>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              disabled={posting && !posted}
              onClick={handleGoToPosts}
              fullWidth
              text={t('close')}
            />
          </Dialog.Actions>
        </Dialog>
      </Modal>

      <CompaniesCountDialog
        open={companiesDialogOpen}
        province={values.localitation.province}
        category={values.category}
        search={query}
        onContinue={handleNext}
      />
    </>
  )
}
