import React from 'react'
import PropTypes from 'prop-types'
import { InputBase, IconButton, Box } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { FileInput } from 'components'
import { AttachSquare, Send } from 'iconsax-react'
import { motion, AnimatePresence } from 'framer-motion'

/// ////////COMPONENT PROPS
ChatInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.any,
  onChange: PropTypes.func,
  onAttachChange: PropTypes.func,
  onSendClick: PropTypes.func,
  disabled: PropTypes.bool
}

function ChatInput({
  id,
  value,
  onChange,
  onSubmit,
  onAttachChange,
  attachment,
  disabled,
  ...other
}) {
  const handleChange = (event) => {
    const { name, value } = event.target
    onChange({ [name]: value })
  }

  const handleSend = (event) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <Box
      sx={{
        transition: 'all 0.2s ease-in-out',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        bottom: 0,
        zIndex: 1100,
        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.9),
        backdropFilter: 'blur(4px)',
        boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.08)',
        alignItems: 'flex-end'
      }}
    >
      <InputBase
        id={id}
        name={id}
        maxRows={5}
        multiline
        inputProps={{ 'aria-label': 'message' }}
        onChange={handleChange}
        value={value}
        sx={{ flex: 1, minHeight: 56, py: 1, px: 2, wordBreak: 'break-all' }}
        disabled={disabled}
        {...other}
      />

      <Box sx={{ width: 54 }}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {value === '' && (
            <motion.div
              key='attachButton'
              initial={{ opacity: 1, x: 48 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 48 }}
            >
              <FileInput
                id='attachment'
                accept={'.pdf,.jpg,.png,.jpeg'}
                maxSize={5}
                onChange={onChange}
                disabled={disabled}
                value={attachment}
                onError={(error) => (error ? console.log(error) : null)}
              >
                <IconButton
                  aria-label='attachment'
                  component='span'
                  size='large'
                  disabled={disabled}
                  sx={{ position: 'absolute', bottom: 4, right: 4 }}
                >
                  <AttachSquare />
                </IconButton>
              </FileInput>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {value !== '' && (
            <motion.div
              key='sendButton'
              initial={{ opacity: 0, x: 48 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 48 }}
            >
              <IconButton
                aria-label='send'
                onClick={handleSend}
                color='primary'
                size='large'
                disabled={disabled}
                sx={{ position: 'absolute', bottom: 4, right: 4 }}
              >
                <Send variant='Bulk' />
              </IconButton>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  )
}

export default React.memo(ChatInput)
