export default function NothingAccepted(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' {...props}>
      <defs>
        <clipPath id='a'>
          <path
            d='M283.28 160.49v-5.39l-2.95-.23a12.09 12.09 0 0 0-1.33-3.21l1.92-2.25-3.8-3.81-2.26 1.92a12 12 0 0 0-3.2-1.33l-.24-2.95H266l-.23 2.95a12.09 12.09 0 0 0-3.21 1.33l-2.25-1.92-3.81 3.81 1.92 2.25a12.09 12.09 0 0 0-1.33 3.21l-3 .23v5.39l3 .23a11.82 11.82 0 0 0 1.33 3.21l-1.92 2.26 3.81 3.8 2.25-1.92a12.09 12.09 0 0 0 3.21 1.33l.23 2.95h5.39l.24-2.95a12 12 0 0 0 3.2-1.33l2.26 1.92 3.8-3.8-1.89-2.26a12 12 0 0 0 1.33-3.21ZM268.73 163a5.18 5.18 0 1 1 5.18-5.18 5.17 5.17 0 0 1-5.18 5.18Z'
            style={{
              fill: '#52aeb9',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='b'>
          <path
            d='M257.9 179.25v-3.87l-2.12-.17a8.48 8.48 0 0 0-1-2.3l1.38-1.62-2.73-2.74-1.62 1.38a8.52 8.52 0 0 0-2.31-1l-.17-2.13h-3.83l-.17 2.13a8.52 8.52 0 0 0-2.31 1l-1.62-1.38-2.73 2.74 1.38 1.62a8.48 8.48 0 0 0-1 2.3l-2.12.17v3.87l2.12.17a8.55 8.55 0 0 0 1 2.31l-1.38 1.62 2.73 2.74 1.6-1.39a8.55 8.55 0 0 0 2.31 1l.17 2.12h3.87l.17-2.12a8.55 8.55 0 0 0 2.31-1l1.62 1.39 2.73-2.74-1.38-1.62a8.55 8.55 0 0 0 1-2.31ZM247.44 181a3.73 3.73 0 1 1 3.72-3.72 3.72 3.72 0 0 1-3.72 3.72Z'
            style={{
              fill: '#52aeb9',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='c'>
          <path
            d='m373.72 271.64-32.22 29.25c-15.12-12.87-10.25-32.25-10.25-32.25l11.58-8.13 14-9.82a53 53 0 0 1 12.81 8.56c3.63 3.56 6.13 7.9 4.08 12.39Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='d'>
          <path
            d='M360.72 268.38s-5.3-1.2-9.18-8.7-3.43-42.82-2.56-49.82 14.28-10.85 14.91-3.81c.1 1.07.51 3.17 1.12 6 .8 3.63 1.92 8.46 3.12 13.8 4 17.89 9 41.58 5.59 45.79'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='e'>
          <path
            d='M402.37 163v.19c-.52 5.67-7.46 4.6-16.61 6.18-7 1.2-16.08 6.84-22.77 11.6-5.27 3.74-9 6.93-9 6.93s-7.68-5.11-14.62-19.32c0 0 25.12-15.77 28.05-35.28 2.31-15.3 24.48-.31 32.82 20.61 1.6 4.09 2.27 7.03 2.13 9.09Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='f'>
          <path
            d='M365.46 181s-15.43 11.65-18.7 11.54-7.76-.19-12.07-5.94c-3.33-4.45-2.84-8.29-1.88-11a21 21 0 0 1 .86-2.08c.67-1.46 13.43-14.31 13.43-14.31l3.17.84s2.06 11.53 14.62 19.53Z'
            style={{
              fill: '#52aeb9',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='g'>
          <path
            d='M349.12 191.25s6.1 17 10.5 20.08 15.78-6.35 14.76-8-12-11.82-13.76-20.13c0 .05-5.18.8-11.5 8.05Z'
            style={{
              fill: '#52aeb9',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='h'>
          <path
            d='M403.12 376.38c-1.65 1.12-4.81 1.11-8.82.37a90.81 90.81 0 0 1-12.65-3.56 337.08 337.08 0 0 1-32.82-14s.05-20.52 12.11-28.47c0 0 15.64 10.71 25.71 13.36 8.44 2.27 16.47 32.3 16.47 32.3Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='i'>
          <path
            d='M389.73 322.21v.32c-.05 2.94-.88 8.77-2.75 19.1-1.75 9.66-2.08 10.41-3.25 10.9-1.43.59-11.68 2.87-16.05 3.81l-.95.2c-.63.14-2.29-.73-2.18-1.36.52-3.19 4.08-32 5.51-33 2.17-1.42 16.2-2.76 17.76-2.33a3.48 3.48 0 0 1 1.91 2.36Z'
            style={{
              fill: '#999',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='j'>
          <path
            d='M177.49 255s-.35 16.36-18.16 25.16l-30.83-43.63c-2.17-11.8 21.5-16.2 21.5-16.2v0l11.16 14.07Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='k'>
          <path
            d='M128.5 236.53c-1.26-2.7 0-9.25 2.24-16.84 2.57-8.71 6.42-18.81 9.25-26 1.56-4 2.81-7.13 3.34-8.66 2.34-6.67 14.4.33 13.54 7.33s1.65 31.67-3.94 38-11 6.2-11 6.2'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='l'>
          <path
            transform='rotate(-54.15 135.472 159.78)'
            style={{
              fill: '#52aeb9',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
            d='M101.23 134.65h68.5v50.25h-68.5z'
          />
        </clipPath>
        <clipPath id='m'>
          <path
            d='M152.84 360.23s-47.51 17.21-55.27 7.86a102.52 102.52 0 0 1 8.3-17.44c2.57-4.06 5.52-7.45 8.48-8.23a112.34 112.34 0 0 0 12.87-4.58c7.2-3 13.51-6.08 13.51-6.08 12.05 7.95 12.11 28.47 12.11 28.47Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='n'>
          <path
            d='M101.22 370.15c-11.18.5-11.89-25.82-6.27-64.91 0 0 8.62-17.11 12.6-17.34 1.07-.06 2.55-.09 4.13-.09 4.29 0 9.37.09 9.37.09s2.3 9.91 2.32 13.28c0 5.05-2.19 5.62-2.19 5.62.12 4.76-3.73 5.24-7.86 4.59a2.79 2.79 0 0 1-.29.6c-.55.92-2 2.63-5.64 3.09-.06 15.25 14.75 38.34 6.91 43.38'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='o'>
          <path
            d='M148.07 283.66c0 8.2-3.35 15.34-8.29 19.09h-15.85c-5-3.75-8.3-10.89-8.3-19.09v-1H148c.05.34.07.66.07 1Z'
            style={{
              fill: '#757575',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='p'>
          <path
            d='M213.94 396s-56.55 9.49-60.62 1.74a8.28 8.28 0 0 1-.82-2.76c-1.33-9.35 6.78-27.79 6.78-27.79 6.29-.6 12.89 1 18.17 3h0a53.08 53.08 0 0 1 10.55 5.06l-.42.92-.14.29 13.21.42 11.82.38h.27v0Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='q'>
          <path
            d='M286.35 396s56.55 9.49 60.62 1.74a8.28 8.28 0 0 0 .82-2.76c1.33-9.35-6.78-27.79-6.78-27.79-6.29-.6-12.89 1-18.17 3h0a53.08 53.08 0 0 0-10.54 5.07l.42.92.14.29-13.21.42-11.82.38h-.27v0Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
      </defs>
      <path
        d='M205.17 59c113.64-29.48 151.08 48.5 198 124.25S498.84 353.4 418 412.67H81s-49.2-12.49-50-78.76c-.61-48.75 48.11-55.37 64.05-130.14S147.33 74 205.17 59Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M205.17 59c113.64-29.48 151.08 48.5 198 124.25S498.84 353.4 418 412.67H81s-49.2-12.49-50-78.76c-.61-48.75 48.11-55.37 64.05-130.14S147.33 74 205.17 59Z'
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001
        }}
      />
      <path
        d='M413.31 295.93H387.2a1.26 1.26 0 0 0-1.26 1.26v8a1.25 1.25 0 0 0 1.26 1.25h1.7c-.63 1.11-1.59 2.9-1.35 2.9a40.27 40.27 0 0 0 4.26-2.9h21.5a1.26 1.26 0 0 0 1.26-1.25v-8a1.27 1.27 0 0 0-1.26-1.26ZM406.59 311.15h26.11a1.25 1.25 0 0 1 1.26 1.26v8a1.25 1.25 0 0 1-1.26 1.26H431c.62 1.11 1.59 2.9 1.35 2.9a40.27 40.27 0 0 1-4.26-2.9h-21.5a1.26 1.26 0 0 1-1.26-1.26v-8a1.26 1.26 0 0 1 1.26-1.26ZM406.59 279.49h26.11a1.25 1.25 0 0 1 1.26 1.26v8a1.25 1.25 0 0 1-1.26 1.25H431c.62 1.11 1.59 2.9 1.35 2.9a40.27 40.27 0 0 1-4.26-2.9h-21.5a1.26 1.26 0 0 1-1.26-1.25v-8a1.26 1.26 0 0 1 1.26-1.26ZM95.05 232.64a6 6 0 0 0-6.05-7.22c-3.58 0-6.74 6-6.74 6s-4.24-4.25-8-4.62-7.15 3.68-5.4 9C71.38 243.42 83 248.44 83 248.44s10.62-7.32 12.05-15.8ZM69.85 247.51a3.21 3.21 0 0 0-4.46-2.28c-1.74.75-2.07 4.29-2.07 4.29s-3-1.2-4.85-.62-2.74 3.26-.79 5.5c2.81 3.23 9.52 3.31 9.52 3.31s3.69-5.71 2.65-10.2ZM76.26 252.46c-10 10.31-3.46 24.67 7.08 24.49 11.93-.19 11-19.57 5.35-10.65s8.45 24 15.81 15.31'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M308 158.71c0-24.19-26.06-43.8-58.2-43.8s-58.21 19.61-58.21 43.8c0 22.8 23.16 41.53 52.74 43.6l5.47 14.27 5.46-14.27c29.54-2.07 52.74-20.8 52.74-43.6Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M253.86 151.94v-7l-3.82-.31a15.48 15.48 0 0 0-1.72-4.15l2.49-2.91-4.93-4.93-2.88 2.53a15.81 15.81 0 0 0-4.15-1.72l-.3-3.82h-7l-.3 3.82a15.81 15.81 0 0 0-4.15 1.72l-2.91-2.49-4.93 4.93 2.49 2.91a15.48 15.48 0 0 0-1.72 4.15l-3.82.31v7l3.82.3a15.48 15.48 0 0 0 1.72 4.15l-2.49 2.92 4.93 4.92 2.91-2.49a15.16 15.16 0 0 0 4.15 1.72l.3 3.82h7l.3-3.82a15.16 15.16 0 0 0 4.15-1.72l2.91 2.49 4.93-4.92-2.49-2.92a15.48 15.48 0 0 0 1.72-4.15ZM235 155.16a6.7 6.7 0 1 1 6.71-6.7 6.7 6.7 0 0 1-6.71 6.7Z'
        style={{
          fill: '#52aeb9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M283.28 160.49v-5.39l-2.95-.23a12.09 12.09 0 0 0-1.33-3.21l1.92-2.25-3.8-3.81-2.26 1.92a12 12 0 0 0-3.2-1.33l-.24-2.95H266l-.23 2.95a12.09 12.09 0 0 0-3.21 1.33l-2.25-1.92-3.81 3.81 1.92 2.25a12.09 12.09 0 0 0-1.33 3.21l-3 .23v5.39l3 .23a11.82 11.82 0 0 0 1.33 3.21l-1.92 2.26 3.81 3.8 2.25-1.92a12.09 12.09 0 0 0 3.21 1.33l.23 2.95h5.39l.24-2.95a12 12 0 0 0 3.2-1.33l2.26 1.92 3.8-3.8-1.89-2.26a12 12 0 0 0 1.33-3.21ZM268.73 163a5.18 5.18 0 1 1 5.18-5.18 5.17 5.17 0 0 1-5.18 5.18Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <g
        style={{
          clipPath: 'url(#a)'
        }}
      >
        <path
          d='M283.28 160.49v-5.39l-2.95-.23a12.09 12.09 0 0 0-1.33-3.21l1.92-2.25-3.8-3.81-2.26 1.92a12 12 0 0 0-3.2-1.33l-.24-2.95H266l-.23 2.95a12.09 12.09 0 0 0-3.21 1.33l-2.25-1.92-3.81 3.81 1.92 2.25a12.09 12.09 0 0 0-1.33 3.21l-3 .23v5.39l3 .23a11.82 11.82 0 0 0 1.33 3.21l-1.92 2.26 3.81 3.8 2.25-1.92a12.09 12.09 0 0 0 3.21 1.33l.23 2.95h5.39l.24-2.95a12 12 0 0 0 3.2-1.33l2.26 1.92 3.8-3.8-1.89-2.26a12 12 0 0 0 1.33-3.21ZM268.73 163a5.18 5.18 0 1 1 5.18-5.18 5.17 5.17 0 0 1-5.18 5.18Z'
          style={{
            fill: '#fff',
            opacity: 0.5
          }}
        />
      </g>
      <path
        d='M283.28 160.49v-5.39l-2.95-.23a12.09 12.09 0 0 0-1.33-3.21l1.92-2.25-3.8-3.81-2.26 1.92a12 12 0 0 0-3.2-1.33l-.24-2.95H266l-.23 2.95a12.09 12.09 0 0 0-3.21 1.33l-2.25-1.92-3.81 3.81 1.92 2.25a12.09 12.09 0 0 0-1.33 3.21l-3 .23v5.39l3 .23a11.82 11.82 0 0 0 1.33 3.21l-1.92 2.26 3.81 3.8 2.25-1.92a12.09 12.09 0 0 0 3.21 1.33l.23 2.95h5.39l.24-2.95a12 12 0 0 0 3.2-1.33l2.26 1.92 3.8-3.8-1.89-2.26a12 12 0 0 0 1.33-3.21ZM268.73 163a5.18 5.18 0 1 1 5.18-5.18 5.17 5.17 0 0 1-5.18 5.18Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M257.9 179.25v-3.87l-2.12-.17a8.48 8.48 0 0 0-1-2.3l1.38-1.62-2.73-2.74-1.62 1.38a8.52 8.52 0 0 0-2.31-1l-.17-2.13h-3.83l-.17 2.13a8.52 8.52 0 0 0-2.31 1l-1.62-1.38-2.73 2.74 1.38 1.62a8.48 8.48 0 0 0-1 2.3l-2.12.17v3.87l2.12.17a8.55 8.55 0 0 0 1 2.31l-1.38 1.62 2.73 2.74 1.6-1.39a8.55 8.55 0 0 0 2.31 1l.17 2.12h3.87l.17-2.12a8.55 8.55 0 0 0 2.31-1l1.62 1.39 2.73-2.74-1.38-1.62a8.55 8.55 0 0 0 1-2.31ZM247.44 181a3.73 3.73 0 1 1 3.72-3.72 3.72 3.72 0 0 1-3.72 3.72Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <g
        style={{
          clipPath: 'url(#b)'
        }}
      >
        <path
          d='M257.9 179.25v-3.87l-2.12-.17a8.48 8.48 0 0 0-1-2.3l1.38-1.62-2.73-2.74-1.62 1.38a8.52 8.52 0 0 0-2.31-1l-.17-2.13h-3.83l-.17 2.13a8.52 8.52 0 0 0-2.31 1l-1.62-1.38-2.73 2.74 1.38 1.62a8.48 8.48 0 0 0-1 2.3l-2.12.17v3.87l2.12.17a8.55 8.55 0 0 0 1 2.31l-1.38 1.62 2.73 2.74 1.6-1.39a8.55 8.55 0 0 0 2.31 1l.17 2.12h3.87l.17-2.12a8.55 8.55 0 0 0 2.31-1l1.62 1.39 2.73-2.74-1.38-1.62a8.55 8.55 0 0 0 1-2.31ZM247.44 181a3.73 3.73 0 1 1 3.72-3.72 3.72 3.72 0 0 1-3.72 3.72Z'
          style={{
            fill: '#fff',
            opacity: 0.7000000000000001
          }}
        />
      </g>
      <path
        d='M257.9 179.25v-3.87l-2.12-.17a8.48 8.48 0 0 0-1-2.3l1.38-1.62-2.73-2.74-1.62 1.38a8.52 8.52 0 0 0-2.31-1l-.17-2.13h-3.83l-.17 2.13a8.52 8.52 0 0 0-2.31 1l-1.62-1.38-2.73 2.74 1.38 1.62a8.48 8.48 0 0 0-1 2.3l-2.12.17v3.87l2.12.17a8.55 8.55 0 0 0 1 2.31l-1.38 1.62 2.73 2.74 1.6-1.39a8.55 8.55 0 0 0 2.31 1l.17 2.12h3.87l.17-2.12a8.55 8.55 0 0 0 2.31-1l1.62 1.39 2.73-2.74-1.38-1.62a8.55 8.55 0 0 0 1-2.31ZM247.44 181a3.73 3.73 0 1 1 3.72-3.72 3.72 3.72 0 0 1-3.72 3.72Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='m342.53 304.59-7.1 5.41c-11.06-4.26-10.35-5.22-20.93-6.75-5.48-.79-16.83-.46-26.37 0-8.62.44-15.77 1-15.77 1s21.09-21 26.89-24.07 31.27-14 32.07-14.36 19.21 21.43 11.21 38.77Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='m342.53 304.59-7.1 5.41c-11.06-4.26-10.35-5.22-20.93-6.75-5.48-.79-16.83-.46-26.37 0 11.87-8.21 44-1.48 44-1.48s-5-6.33-5.36-19.16c-.38-12.35 4.23-16.47 4.58-16.77s19.18 21.41 11.18 38.75Z'
        style={{
          opacity: 0.2
        }}
      />
      <path
        d='M272.36 304.31s21.09-21 26.89-24.07 32.09-14.37 32.09-14.37 19.19 21.38 11.19 38.72l-7.1 5.41'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='m373.72 271.64-32.22 29.25c-15.12-12.87-10.25-32.25-10.25-32.25l11.58-8.13 14-9.82a53 53 0 0 1 12.81 8.56c3.63 3.56 6.13 7.9 4.08 12.39Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#c)'
        }}
      >
        <path
          d='M369.62 259.25a9.71 9.71 0 0 1-3.33 4.48c-10.42 8.63-18.94 2.1-23.46-3.22l14-9.82a53 53 0 0 1 12.79 8.56Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='m373.72 271.64-32.22 29.25c-15.12-12.87-10.25-32.25-10.25-32.25l11.58-8.13 14-9.82a53 53 0 0 1 12.81 8.56c3.63 3.56 6.13 7.9 4.08 12.39Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M360.72 268.38s-5.3-1.2-9.18-8.7-3.43-42.82-2.56-49.82 14.28-10.85 14.91-3.81c.1 1.07.51 3.17 1.12 6 .8 3.63 1.92 8.46 3.12 13.8 4 17.89 9 41.58 5.59 45.79'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#d)'
        }}
      >
        <path
          d='M368.13 225.85C362.2 221.46 358 213.2 358 213.2l7-1.15c.81 3.63 1.93 8.46 3.13 13.8Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M360.72 268.38s-5.3-1.2-9.18-8.7-3.43-42.82-2.56-49.82 14.28-10.85 14.91-3.81c.1 1.07.51 3.17 1.12 6 .8 3.63 1.92 8.46 3.12 13.8 4 17.89 9 41.58 5.59 45.79'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M402.37 163v.19c-.52 5.67-7.46 4.6-16.61 6.18-7 1.2-16.08 6.84-22.77 11.6-5.27 3.74-9 6.93-9 6.93s-7.68-5.11-14.62-19.32c0 0 25.12-15.77 28.05-35.28 2.31-15.3 24.48-.31 32.82 20.61 1.6 4.09 2.27 7.03 2.13 9.09Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#e)'
        }}
      >
        <path
          d='M402.34 163.17c-.52 5.67-7.46 4.6-16.61 6.18-7 1.2-16.08 6.84-22.77 11.6-3-1.8-4.73-3.54-4-5 3.09-6.85 14.71-9.05 14.71-9.05s-1-4.49.82-6.66 8.47 2.17 18 5.55c8.45 2.99 9.69-1.63 9.85-2.62Z'
          style={{
            opacity: 0.15
          }}
        />
      </g>
      <path
        d='M402.37 163v.19c-.52 5.67-7.46 4.6-16.61 6.18-7 1.2-16.08 6.84-22.77 11.6-5.27 3.74-9 6.93-9 6.93s-7.68-5.11-14.62-19.32c0 0 25.12-15.77 28.05-35.28 2.31-15.3 24.48-.31 32.82 20.61 1.6 4.09 2.27 7.03 2.13 9.09Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M376.2 146c6.15-2.32 8.52-6.5 9.7-5.56s5.46 4 4.77 5.86-6.22 8.22-6.77 9.37'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M373.34 129.64c-3.31.31-2.87 9 5.24 19.73s16.12 15.6 19.67 14.38 1.09-5.89 1.09-5.89'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M365.46 181s-15.43 11.65-18.7 11.54-7.76-.19-12.07-5.94c-3.33-4.45-2.84-8.29-1.88-11a21 21 0 0 1 .86-2.08c.67-1.46 13.43-14.31 13.43-14.31l3.17.84s2.06 11.53 14.62 19.53Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <g
        style={{
          clipPath: 'url(#f)'
        }}
      >
        <path
          d='M365.46 181s-15.43 11.65-18.7 11.54-7.76-.19-12.07-5.94c-3.33-4.45-2.84-8.29-1.88-11 .8 3.13 2.34 7.62 4.81 9.4 3.95 2.86 20.38-6.33 20.38-6.33s-9.12-11.23-7.73-18.62c0 0 2.06 11.53 14.62 19.53Z'
          style={{
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M365.46 181s-15.43 11.65-18.7 11.54-7.76-.19-12.07-5.94c-3.33-4.45-2.84-8.29-1.88-11a21 21 0 0 1 .86-2.08c.67-1.46 13.43-14.31 13.43-14.31l3.17.84s2.06 11.53 14.62 19.53Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M373.34 129.64c-3.31.31-2.87 9 5.24 19.73s16.12 15.6 19.67 14.38 1.09-5.89 1.09-5.89a43.36 43.36 0 0 0-10.09-17.61c-9.25-9.87-15.91-10.61-15.91-10.61Z'
        style={{
          opacity: 0.15
        }}
      />
      <path
        d='M377.86 199.06c-.36 4.44-12.21 20.61-19.86 17.11'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M349.12 191.25s6.1 17 10.5 20.08 15.78-6.35 14.76-8-12-11.82-13.76-20.13c0 .05-5.18.8-11.5 8.05Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <g
        style={{
          clipPath: 'url(#g)'
        }}
      >
        <path
          d='M349.12 191.25s6.1 17 10.5 20.08 15.78-6.35 14.76-8-12-11.82-13.76-20.13c0 .05-5.18.8-11.5 8.05Z'
          style={{
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M349.12 191.25s6.1 17 10.5 20.08 15.78-6.35 14.76-8-12-11.82-13.76-20.13c0 .05-5.18.8-11.5 8.05Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M377.61 200.48c1-3.5-1.5-2.56-1.09-5.84s-1.71-3.28-2.38-6.68-2.76-3.06-4-5.86-4.28-1.68-7.24-.31-6.94 7.86-6.94 7.86c-.58 4.19 2.92 3.32 2.92 3.32-2.33 4.29 1.55 5.24 1.55 5.24-.21 4.62 2.58 4 2.58 4a2.37 2.37 0 0 0 2 2.51c1.33.28 11.56-.72 12.6-4.24Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M358.91 193s5.65-6 7.38-6.65M360.46 198.21a23.21 23.21 0 0 1 9.07-5.74M363 202.25s7.5-3.69 8.61-4'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M353.12 215.38s-5.5-5.09-6.5-7.26-2.92-11.46-1.55-14 5.42-6.66 6.05-8.66 4.13 2.38.13 10.38c0 0 4.78 5.78 4 12.12'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M402.37 163v.19a1.08 1.08 0 0 0 0-.19Z'
        style={{
          opacity: 0.15
        }}
      />
      <path
        d='M349.85 363.4s-2.2.89-5.37-3.07c-6-7.54-15.05-25.65-21-34.33-2.54-3.72-9.56-7.38-17.15-10.45a227 227 0 0 0-24.84-8.09s31.94-2 40.05.47c7 2.1 31.88 16.32 39 20.41l1.9 1.1s1.06 15.06-12.59 33.96Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M349.85 363.4s-2.2.89-5.37-3.07c-6-7.54-15.05-25.65-21-34.33-2.54-3.72-9.56-7.38-17.15-10.45 29 6.62 39.24 35.7 39.24 35.7s1.54-16.51 14.94-22.91l1.9 1.1s1.09 15.06-12.56 33.96Z'
        style={{
          opacity: 0.2
        }}
      />
      <path
        d='M281.51 307.46s31.94-2 40.05.47 40.87 21.51 40.87 21.51 1.07 15.06-12.58 34'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M403.12 376.38c-1.65 1.12-4.81 1.11-8.82.37a90.81 90.81 0 0 1-12.65-3.56 337.08 337.08 0 0 1-32.82-14s.05-20.52 12.11-28.47c0 0 15.64 10.71 25.71 13.36 8.44 2.27 16.47 32.3 16.47 32.3Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#h)'
        }}
      >
        <path
          d='M394.3 376.75a90.81 90.81 0 0 1-12.65-3.56c-2.25-10.39 4.29-21.94 4.29-21.94a47.65 47.65 0 0 0 8.36 25.5Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M403.12 376.38c-1.65 1.12-4.81 1.11-8.82.37a90.81 90.81 0 0 1-12.65-3.56 337.08 337.08 0 0 1-32.82-14s.05-20.52 12.11-28.47c0 0 15.64 10.71 25.71 13.36 8.44 2.27 16.47 32.3 16.47 32.3Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M388.72 370.46a33.57 33.57 0 0 1-2.07-8.09M388.73 335.8c4.44.7 8.52 10.26 11.27 13.66 0 0 7.83 3.65 9.17 7.1s6.67 17.23-3.92 20.06c-6.09 1.63-10.64 1.35-13.8-1.88'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M389.73 322.21v.32c-.05 2.94-.88 8.77-2.75 19.1-1.75 9.66-2.08 10.41-3.25 10.9-1.43.59-11.68 2.87-16.05 3.81l-.95.2c-.63.14-2.29-.73-2.18-1.36.52-3.19 4.08-32 5.51-33 2.17-1.42 16.2-2.76 17.76-2.33a3.48 3.48 0 0 1 1.91 2.36Z'
        style={{
          fill: '#999'
        }}
      />
      <g
        style={{
          clipPath: 'url(#i)'
        }}
      >
        <path
          d='M389.73 322.53c-.05 2.94-.88 8.77-2.75 19.1-1.75 9.66-2.08 10.41-3.25 10.9-1.43.59-11.68 2.87-16.05 3.81.53-4.17 2.13-15.82 5.43-31.78Z'
          style={{
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M389.73 322.21v.32c-.05 2.94-.88 8.77-2.75 19.1-1.75 9.66-2.08 10.41-3.25 10.9-1.43.59-11.68 2.87-16.05 3.81l-.95.2c-.63.14-2.29-.73-2.18-1.36.52-3.19 4.08-32 5.51-33 2.17-1.42 16.2-2.76 17.76-2.33a3.48 3.48 0 0 1 1.91 2.36Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M367.48 354.29s4.66-27.16 5.08-28.83.84-1 14.84-2.83'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M386.65 362.37s-7.25 2.59-6.75-1.83a4.06 4.06 0 0 1-1.67-3 9 9 0 0 1 1.17-3.83s-2.75 4.75-5.09 2c-.61-.72 1.88-5.88 1.88-5.88a2.25 2.25 0 0 1-3.38-1.2c-.33-.79 3.49-7.89 4.33-8.42 2.8-1.76 10.09-5.52 12.59-4.09s3.33 5.92 4.83 7.59.67 7.33 0 11.33'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M376.19 349.83s2.62-4.54 3.37-5.12 7.09-2.42 7.09-2.42M379.4 353.71c1.58-1.75 2.5-3.77 3.16-3.88s5.75-.87 6.17-1M379.63 360.33c1.77-2 4.52-4.12 5.1-4.12s3.83-.42 4-.5M387.82 361.38a4.69 4.69 0 0 1-3.76.91M386.65 362.37s6.6 5.51 9.6-1'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='m225 303.85-7.15-.19c-9.74-.24-26.45-.6-30.41-.35a45.57 45.57 0 0 0-14.44 3.85l-16.13-22c2.46-30.49 21.9-33.83 21.9-33.83S192.5 267 197.5 271.27s27.5 32.58 27.5 32.58Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M217.85 303.66c-9.74-.24-26.45-.6-30.41-.35a45.57 45.57 0 0 0-14.44 3.85l-16.13-22c2.46-30.49 21.9-33.83 21.9-33.83S179.91 270 165 282.72c0 0 4.6 12.92 14.64 15.48s29.68.46 38.21 5.46Z'
        style={{
          opacity: 0.2
        }}
      />
      <path
        d='M225 303.85s-22.5-28.31-27.5-32.58-18.73-19.94-18.73-19.94-19.44 3.34-21.9 33.83l16.13 22'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M177.49 255s-.35 16.36-18.16 25.16l-30.83-43.63c-2.17-11.8 21.5-16.2 21.5-16.2l11.16 14.07Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#j)'
        }}
      >
        <path
          d='M161.17 234.42c-10.41 8.23-22-.65-22-.65L150 220.35Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M177.49 255s-.35 16.36-18.16 25.16l-30.83-43.63c-2.17-11.8 21.5-16.2 21.5-16.2v0l11.16 14.07Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M128.5 236.53c-1.26-2.7 0-9.25 2.24-16.84 2.57-8.71 6.42-18.81 9.25-26 1.56-4 2.81-7.13 3.34-8.66 2.34-6.67 14.4.33 13.54 7.33s1.65 31.67-3.94 38-11 6.2-11 6.2'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#k)'
        }}
      >
        <path
          d='M148.07 189.64s-4.52 27-8.86 28.57a42.84 42.84 0 0 1-8.47 1.48c2.57-8.71 6.42-18.81 9.25-26Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M128.5 236.53c-1.26-2.7 0-9.25 2.24-16.84 2.57-8.71 6.42-18.81 9.25-26 1.56-4 2.81-7.13 3.34-8.66 2.34-6.67 14.4.33 13.54 7.33s1.65 31.67-3.94 38-11 6.2-11 6.2'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M138.21 205.16a2.8 2.8 0 0 1-3.92.63l-42.13-30.44a2.79 2.79 0 0 1-.63-3.91l41.21-57a2.81 2.81 0 0 1 3.92-.63l42.13 30.44a2.81 2.81 0 0 1 .64 3.92Z'
        style={{
          fill: '#bfbfbf',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        transform='rotate(-54.15 156.478 130.706)'
        style={{
          fill: '#52aeb9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='M153.36 117.2h6.25v27.02h-6.25z'
      />
      <path
        transform='rotate(-54.15 135.472 159.78)'
        style={{
          fill: '#52aeb9'
        }}
        d='M101.23 134.65h68.5v50.25h-68.5z'
      />
      <g
        style={{
          clipPath: 'url(#l)'
        }}
      >
        <path
          transform='rotate(-54.15 135.472 159.78)'
          style={{
            fill: '#fff',
            opacity: 0.7000000000000001
          }}
          d='M101.23 134.65h68.5v50.25h-68.5z'
        />
      </g>
      <path
        transform='rotate(-54.15 135.472 159.78)'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='M101.23 134.65h68.5v50.25h-68.5z'
      />
      <path
        d='m164.77 136.35-2.22 3.08a1.06 1.06 0 0 1-1.48.23l-5.55-4a2.2 2.2 0 0 0-3.43-2.48l-5.56-4a1.05 1.05 0 0 1-.23-1.48l2.22-3.07a1.07 1.07 0 0 1 1.48-.24l14.53 10.5a1.06 1.06 0 0 1 .24 1.46Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M153 196.11s6.36-4 7.75-5.89 5-10.71 4.15-13.43-4.06-7.56-4.3-9.64-4.51 1.56-2.09 10.16c0 0-5.78 4.79-6.21 11.16M149.61 159.24a13.68 13.68 0 1 1-13.68-13.68 13.67 13.67 0 0 1 13.68 13.68Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m129.13 154.33 1.67 9.6 15.29-4.15'
      />
      <path
        d='M220.15 308.46s-19.63 3.13-34.4 11.12a47.05 47.05 0 0 0-8.26 5.54c-6.87 5.89-12.87 18.09-18 27.76-4.38 8.24-7.69 11.52-7.69 11.52-13.64-18.9-12.58-34-12.58-34l.13-.07c2.22-1.3 32.84-19.07 40.74-21.44 8.12-2.4 40.06-.43 40.06-.43Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M185.75 319.58a47.05 47.05 0 0 0-8.26 5.54c-6.87 5.89-12.87 18.09-18 27.76-4.38 8.24-7.69 11.52-7.69 11.52-13.64-18.9-12.58-34-12.58-34l.13-.07c1-.47 8.12-2.82 17.51 15.2.01.04 6.5-21.15 28.89-25.95Z'
        style={{
          opacity: 0.2
        }}
      />
      <path
        d='M119.81 286.84s-8.58-2.22-6 5.82 11.77 7.78 11.77 7.78'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M220.15 308.46s-31.94-2-40.05.47-40.87 21.51-40.87 21.51-1.06 15.06 12.58 34'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M152.84 360.23s-47.51 17.21-55.27 7.86a102.52 102.52 0 0 1 8.3-17.44c2.57-4.06 5.52-7.45 8.48-8.23a112.34 112.34 0 0 0 12.87-4.58c7.2-3 13.51-6.08 13.51-6.08 12.05 7.95 12.11 28.47 12.11 28.47Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#m)'
        }}
      >
        <path
          d='M127.22 337.84c-1.36 6.43-5.18 18.42-14.77 17.64-3.72-.3-5.68-2.22-6.58-4.83 2.57-4.06 5.52-7.45 8.48-8.23a112.34 112.34 0 0 0 12.87-4.58Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M152.84 360.23s-47.51 17.21-55.27 7.86a102.52 102.52 0 0 1 8.3-17.44c2.57-4.06 5.52-7.45 8.48-8.23a112.34 112.34 0 0 0 12.87-4.58c7.2-3 13.51-6.08 13.51-6.08 12.05 7.95 12.11 28.47 12.11 28.47Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M101.22 370.15c-11.18.5-11.89-25.82-6.27-64.91 0 0 8.62-17.11 12.6-17.34 1.07-.06 2.55-.09 4.13-.09 4.29 0 9.37.09 9.37.09s2.3 9.91 2.32 13.28c0 5.05-2.19 5.62-2.19 5.62.12 4.76-3.73 5.24-7.86 4.59a2.79 2.79 0 0 1-.29.6c-.55.92-2 2.63-5.64 3.09-.06 15.25 14.75 38.34 6.91 43.38'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#n)'
        }}
      >
        <path
          d='M123.37 301.18c0 5.05-2.19 5.62-2.19 5.62.12 4.76-3.73 5.24-7.86 4.59a2.79 2.79 0 0 1-.29.6l1.27-3.83a3.22 3.22 0 0 0 2.13.17c2.5-1.23 1.19-5.54 1.19-5.54a2.87 2.87 0 0 0 1.82-2.35c.39-2-4-3.3-4-3.3 1.37-2.29-1.68-4.48-1.68-4.48 1.09-1.64-.33-3.44-2.11-4.85 4.29 0 9.37.09 9.37.09s2.33 9.91 2.35 13.28Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M101.22 370.15c-11.18.5-11.89-25.82-6.27-64.91 0 0 8.62-17.11 12.6-17.34 1.07-.06 2.55-.09 4.13-.09 4.29 0 9.37.09 9.37.09s2.3 9.91 2.32 13.28c0 5.05-2.19 5.62-2.19 5.62.12 4.76-3.73 5.24-7.86 4.59a2.79 2.79 0 0 1-.29.6c-.55.92-2 2.63-5.64 3.09-.06 15.25 14.75 38.34 6.91 43.38'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M121.1 299.72c-1.38-2.54-2.71-1.07-9.35-2.68a27.13 27.13 0 0 0 7.57-.36M111 292.11c.9.4 4.81.11 6.64 0M113.32 302.83c0-.19 5.59.28 6.5.94s1.48 3.14 1.48 3.14M117.62 308.16c-.42.38-3.32 0-3.32 0a9 9 0 0 1-1 3.23'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M148.07 283.66c0 8.2-3.35 15.34-8.29 19.09h-15.85c-5-3.75-8.3-10.89-8.3-19.09v-1H148c.05.34.07.66.07 1Z'
        style={{
          fill: '#757575'
        }}
      />
      <g
        style={{
          clipPath: 'url(#o)'
        }}
      >
        <path
          d='m135.93 301.81-.07.94h-11.93c-5-3.75-8.3-10.89-8.3-19.09v-1h3.52c2.47 5.97 7.85 16.21 16.78 19.15Z'
          style={{
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M148.07 283.66c0 8.2-3.35 15.34-8.29 19.09h-15.85c-5-3.75-8.3-10.89-8.3-19.09v-1H148c.05.34.07.66.07 1Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: '#757575',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='M141.04 301.81h-18.37l-.86 2.46h20.09l-.86-2.46z'
      />
      <path
        style={{
          opacity: 0.2
        }}
        d='m141.9 304.27-.86-2.46h-18.37l-.86 2.46'
      />
      <path
        d='M126.65 262.71c-2.79 4.79 4.69 9.3 1 15.87M131.44 262.71c-2.79 4.8 4.69 9.3 1 15.87M136.57 262.71c-2.79 4.8 4.69 9.3 1 15.87'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M324.3 333.12c-7.11-12.13-36.52-22.68-69.19-24h-.26l-3.21-.09v0h-3.26v0l-3.21.09h-.28c-32.66 1.3-62.06 11.86-69.17 24-7.59 12.95-18.83 33.79-18.83 33.79 6.3 10.2 30.5 11.57 30.5 11.57l1.29 27.44h122.66l1.29-27.44s24.2-1.37 30.5-11.57c0 0-11.24-20.84-18.83-33.79Z'
        style={{
          fill: '#52aeb9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M213.94 396s-56.55 9.49-60.62 1.74a8.28 8.28 0 0 1-.82-2.76c-1.33-9.35 6.78-27.79 6.78-27.79 6.29-.6 12.89 1 18.17 3a53.08 53.08 0 0 1 10.55 5.06l-.42.92-.14.29 13.21.42 11.82.38h.27Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#p)'
        }}
      >
        <path
          d='M187.58 376.17c-12.94.25-17.85 2-17.85 2a30.17 30.17 0 0 1 7.72-8 53.08 53.08 0 0 1 10.55 5.08ZM213.94 396s-56.55 9.49-60.62 1.74a8.28 8.28 0 0 1-.82-2.76 13.81 13.81 0 0 0 4.37 1c7.4.26 44.72-4.35 44.72-4.35l-.94-14.74v-.48c3.58.18 7.52.45 11.85.86h.27Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M213.94 396s-56.55 9.49-60.62 1.74a8.28 8.28 0 0 1-.82-2.76c-1.33-9.35 6.78-27.79 6.78-27.79 6.29-.6 12.89 1 18.17 3h0a53.08 53.08 0 0 1 10.55 5.06l-.42.92-.14.29 13.21.42 11.82.38h.27v0Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M187.44 376.46s-13.36-.36-19.05 2.25M307.65 372.67s-5.24-24.22 4.91-35.94'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M316.54 348.79s-6.87 5-8.12 13.09M187.44 333.91a62.84 62.84 0 0 1 3.91 40.09'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M180.71 342.29s7.33 4.46 10.64 11.42M272.36 312s-10.84 27.67-43.1 12.67M322.62 330.76a25.85 25.85 0 0 0-10.14 13.07M177.38 330.76a21.8 21.8 0 0 1 8.65 9.91'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M225 311.1s3.59 14.73 24.21 15.07M292.17 326.17s-38.34 37.44-65.5 38.8M205.17 332.5s5.31 11.92 15 16.29'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M286.35 396s56.55 9.49 60.62 1.74a8.28 8.28 0 0 0 .82-2.76c1.33-9.35-6.78-27.79-6.78-27.79-6.29-.6-12.89 1-18.17 3a53.08 53.08 0 0 0-10.54 5.07l.42.92.14.29-13.21.42-11.82.38h-.27Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#q)'
        }}
      >
        <path
          d='M312.71 376.17c12.94.25 17.85 2 17.85 2a30.17 30.17 0 0 0-7.72-8 53.08 53.08 0 0 0-10.54 5.07ZM286.35 396s56.55 9.49 60.62 1.74a8.28 8.28 0 0 0 .82-2.76 13.81 13.81 0 0 1-4.37 1c-7.4.26-44.72-4.35-44.72-4.35l.94-14.74v-.48c-3.58.18-7.52.45-11.85.86h-.27Z'
          style={{
            fill: '#52aeb9',
            opacity: 0.2
          }}
        />
      </g>
      <path
        d='M286.35 396s56.55 9.49 60.62 1.74a8.28 8.28 0 0 0 .82-2.76c1.33-9.35-6.78-27.79-6.78-27.79-6.29-.6-12.89 1-18.17 3h0a53.08 53.08 0 0 0-10.54 5.07l.42.92.14.29-13.21.42-11.82.38h-.27v0Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M312.85 376.46s13.36-.36 19 2.25'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M269.16 312.06a22.27 22.27 0 0 1-20.32 10.32c-11.63-.58-19.58-9.4-19.58-9.4 4.18-3.39 4.8-11.59 4.51-17v-.36c0-.35-.05-.7-.07-1a45.56 45.56 0 0 0-1.07-6.81l34.81 1.74a36.3 36.3 0 0 0-2.28 10.51 20.82 20.82 0 0 0 .27 4.71v.15c.97 5.17 3.73 7.14 3.73 7.14Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M191.35 391.33v3.72a2.12 2.12 0 0 0 2.12 2.12h112.06a2.12 2.12 0 0 0 2.12-2.12v-3.72Z'
        style={{
          fill: '#757575',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M321.41 336.6 307 390.52a3.89 3.89 0 0 1-2.26 2.46 3.11 3.11 0 0 1-1.16.23H195.45a3.11 3.11 0 0 1-1.16-.23 3.89 3.89 0 0 1-2.26-2.46l-14.44-53.92a2 2 0 0 1 2-2.69h139.85a2 2 0 0 1 1.97 2.69Z'
        style={{
          fill: '#bfbfbf',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M319.05 339.42 304.71 393a3.11 3.11 0 0 1-1.16.23h-108.1a3.11 3.11 0 0 1-1.16-.23l-14.35-53.56a2 2 0 0 1 2-2.69h135.15a2 2 0 0 1 1.96 2.67Z'
        style={{
          fill: '#999',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M256.82 369.56c-3.2 0-5.79-1.92-5.79-4.28a3.32 3.32 0 0 1 .39-1.54 10.87 10.87 0 0 0-1.92-.18c-4.48 0-8.12 2.69-8.12 6s3.64 6 8.12 6 8.12-2.68 8.12-6c-.26-.02-.53 0-.8 0Z'
        style={{
          fill: '#bfbfbf',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M267.42 289.54a36.3 36.3 0 0 0-2.28 10.51c-3.78 3.79-8.41 6.6-13.52 5.8-7.39-1.16-13.54-6.62-17.94-11.24a45.56 45.56 0 0 0-1.07-6.81Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M274.51 264.2s2.81-3.05 4.19-2.05a6.56 6.56 0 0 1 2.26 6.44c-.75 2.59-4 7.1-4.27 9.73s-1.94 5.62-3.79 3.88 1.61-18 1.61-18ZM224.49 264.2s-2.81-3.05-4.19-2.05a6.56 6.56 0 0 0-2.26 6.44c.76 2.59 4 7.1 4.27 9.73s1.94 5.62 3.79 3.88-1.61-18-1.61-18Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M248.91 228.94c35.3 0 26.91 47 20.41 62.11-2.28 5.31-14.1 12.26-19.82 12.26-5.32 0-17.48-8.31-19.64-13.29-6.57-15.17-14.6-61.08 19.05-61.08Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M254.89 266.45s9-1.66 12 1.89'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M261.7 273.23c0 1.09-.55 2-1.23 2s-1.23-.88-1.23-2 .55-2 1.23-2 1.23.92 1.23 2Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M244.11 266.45s-8.3-.74-12 1.89'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M237.3 273.23c0 1.09.55 2 1.23 2s1.23-.88 1.23-2-.55-2-1.23-2-1.23.92-1.23 2Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M263.52 241a44.62 44.62 0 0 1-27.2 0s-4.77-.27-6.65 2.12 0 9.79.5 12.8-4.13 5.1-3.51 12.71l-1.79 2.62s-5-12.24-3.73-22.77 7.9-14.62 7.9-14.62 3-9.1 20.8-9c19.14.08 21.08 10.44 21.08 10.44s13 8.65 3.38 36.86l-2.09-3.5a15.8 15.8 0 0 0-2.42-10.7c-1.67-2.44 1-9.78.37-13.54s-6.64-3.42-6.64-3.42Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M251.6 271.27s.53 8 2.09 11.08-3.89 3.57-6.22 1.76M242.71 290.55c0 1.22 4 2.76 8 2.76s6.94-1.66 6.35-2.27'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M274.09 246.11s-5.18 7-16.94 11.91v-3.36s-10.63 6.14-20.21 5.2l2.17-4.36s-8.08 8.55-14.91 8.22-8.81-38.93 25.64-38.93c28 0 24.25 21.32 24.25 21.32Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M268.29 248.44s-1.84 10.21 7.79 17.69c0 0 5.7-6.14 3.5-17.69s-7.22-11.91-7.22-11.91Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M264 232.5s-3.59 15.25-16.79 23M255.6 232.5s-5.85 15-27.6 17.5M269.16 238.11S269.25 246 264 250'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeMiterlimit: 10,
          opacity: 0.2
        }}
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m395.4 120.26 9.93-19.26M405.33 130.71l22.34-13.41M414.57 146.79l19.39-1.23'
      />
      <path
        d='M96.5 412.67H81v-13.29a2.21 2.21 0 0 1 2.21-2.21h332.55a2.21 2.21 0 0 1 2.21 2.21v13.29'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M400 412.67H261.7M251.79 412.67h-9.93M177.49 412.67H104.5'
      />
    </svg>
  )
}
