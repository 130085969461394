import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { Typography, Box, ButtonBase } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { formatText } from 'helpers'
import { AttachSquare } from 'iconsax-react'
import { useI18N } from 'hooks'

/// ////////COMPONENT PROPS
ChatBubble.propTypes = {
  from: PropTypes.string,
  content: PropTypes.string,
  time: PropTypes.any,
  type: PropTypes.string,
  fileName: PropTypes.string
}

export default function ChatBubble(props) {
  const { type, from, user, state } = props

  const handlePictureClick = (url) => {
    window.open(url)
  }
  const handleFileClick = (url) => {
    window.open(url)
  }

  const setMessageContent = () => {
    switch (type) {
      case 'image':
        return <PictureMessage onClick={handlePictureClick} {...props} />
      case 'pdf':
        return <FileMessage onClick={handleFileClick} {...props} />
      default:
        return <TextMessage {...props} />
    }
  }

  return (
    <>
      <MessageBubbleContainer {...props}>
        {setMessageContent()}
        {from === user && (
          <Box
            aria-label='delivered-indicator'
            component='span'
            sx={{
              transition: 'background-color 0.2s ease-in',
              borderRadius: 8,
              height: 8,
              width: 8,
              bgcolor: state === 'delivered' ? 'success.main' : '#B0BEC5',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              outline: (theme) =>
                `2px solid ${theme.palette.background.default}`
            }}
          />
        )}
      </MessageBubbleContainer>
      <MessageTime {...props} />
    </>
  )
}

const MessageTime = ({ time, user, from }) => (
  <Typography
    color='textSecondary'
    fontSize='0.9rem'
    lineHeight={1}
    sx={{ py: 1, textAlign: from === user ? 'right' : 'left' }}
  >
    {time}
  </Typography>
)

const MessageBubbleContainer = ({ from, user, children, type }) => (
  <Box
    sx={{
      maxWidth: { xs: '80%', sm: 300 },
      width: 'fit-content',
      position: 'relative',
      py: type === 'image' ? 0 : 1,
      px: type === 'image' ? 0 : 2,
      bgcolor: (theme) =>
        type === 'image'
          ? 'transparent'
          : from === user
          ? alpha(theme.palette.primary.main, 0.2)
          : 'primary.main',
      borderRadius: from === user ? '12px 12px 4px 12px' : '4px 12px 12px 12px',
      ...(from === user ? { ml: 'auto' } : { mr: 'auto' })
    }}
  >
    {children}
  </Box>
)

const TextMessage = ({ content, from, user }) => (
  <Box
    sx={{
      '& p': { m: 0, color: from === user ? 'inherit' : 'white' },
      '& p:not(:last-child)': { mt: 0, mb: 1 }
    }}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(formatText(content))
    }}
  />
)

const PictureMessage = ({ onClick, content, fileName, from, user }) => {
  const handlePictureClick = (e) => {
    e.preventDefault()
    onClick(content)
  }

  return (
    <ButtonBase onClick={handlePictureClick}>
      <Box
        component='img'
        sx={{
          maxWidth: '100%',
          borderRadius:
            from === user ? '12px 12px 4px 12px' : '4px 12px 12px 12px'
        }}
        src={content}
        alt={fileName}
      />
    </ButtonBase>
  )
}

const FileMessage = ({ onClick, content, fileName, from, user }) => {
  const { t } = useI18N()
  const handleFileClick = (e) => {
    e.preventDefault()
    onClick(content)
  }

  return (
    <ButtonBase sx={{ maxWidth: '100%' }} onClick={handleFileClick}>
      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
        <Box
          sx={{
            color: from === user ? 'primary.dark' : 'white',
            mr: 1,
            ml: -0.5
          }}
        >
          <AttachSquare size={42} variant='Bulk' />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            textAlign: 'start',
            maxWidth: '100%',
            display: 'block',
            minWidth: 0
          }}
        >
          <Typography
            lineHeight={1.2}
            noWrap
            sx={{ color: from === user ? 'inherit' : 'white' }}
          >
            {fileName}
          </Typography>
          <Typography
            fontSize={12}
            sx={{ color: from === user ? 'primary.dark' : 'white' }}
          >
            {t('attachedFileText')}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  )
}
