export default function NothingNegotiating(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' {...props}>
      <defs>
        <clipPath id='a'>
          <path
            style={{
              fill: '#fff'
            }}
            d='M115.8 106.4h134.29v115.22H115.8z'
          />
        </clipPath>
        <clipPath id='b'>
          <path
            d='M197.66 285.64s2.8 12.51 2.64 16-6.75 12.84-6.92 10.7a14.6 14.6 0 0 1 1.16-5.27s-2.31 3.13-3.13 3 .66-4.45.66-4.45-2.47 2.47-3.3 2 1.81-4.12 1.81-4.12-3 3.13-3.12 2.14 2.63-5.1 2.63-5.1-2.8-5.93-3-8.4a8 8 0 0 1 1.64-4.77Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='c'>
          <path
            d='M197 285.64s2 8.73 1.82 11.2-2.64 9.71-4.45 8.39 1.15-6.25 1.15-6.25-5.08-3.57-6.25-6.92-.5-4.77-.5-4.77Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='d'>
          <path
            d='M156.5 164.91s-5.45 4.88-6.39 6.57-12.38 18-12.57 29.66 8.46 46.92 8.46 46.92l-13 33.07 3.76 1.5 3.75-4.13v6.2s4.32 3.94 15.58 7.69 25.72-.56 25.72-.56l4.31-27.44v27.07l5.07-3.38.41-45.08s4.13-24.21 4.13-31-11.45-28-16.52-34.16-13.52-11.26-16-13.14-6.71.21-6.71.21Z'
            style={{
              fill: '#263238',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='e'>
          <path
            d='M186.91 132.62s4.56 3.5 5.13 6.88-1.13 7.14-.94 8.45 3.56 4.32 3.38 5.82-3 2.06-3 2.06-3.06 11.14-3.81 13-4.51 1.31-7.32.94l-5.07 15-16.72-19.67 2.63-7.89s-7.13-8.63-7.7-17.83 6.76-15.58 17.27-16.89 19.34 3.75 20.46 5.82-4.31 4.31-4.31 4.31Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='f'>
          <path
            d='M217 238.88s6.4-3.6 7.4-3.6 6.8 5.2 6.8 5.2l-2.4 3.6s7.6 3.8 6.8 5.2-3.8 3.6-7 4-12.2-5.4-12.2-5.4Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='g'>
          <path
            d='M147.57 181.48s-5.4 10.4-6 15.6 21.4 46.6 23 48 48.39 2.8 48.39 2.8l2-10.2-40-11.4-13.6-41.8'
            style={{
              fill: '#263238',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='h'>
          <path
            d='M315 178a37.14 37.14 0 0 0-3.34-5.26 20 20 0 0 0-4.94-3.67c-6.36-2.51-14.68 1.13-19.51 5.73-.64 1.42-5.68 12.92-8.07 22.32-2.55 10-2.23 22.64-2.23 22.64s8.45-10.84 19.92-23.59a152.09 152.09 0 0 1 18.7-17.57Z'
            style={{
              fill: '#263238',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
        <clipPath id='i'>
          <path
            d='M275 135.63s-3.83 10.88-3.47 12.44.72 3.94.72 3.94-6 7.41-5.26 8.25 4.06 1.79 4.06 1.79 2.75 11.48 3.83 12.55 7.77-1.31 7.77-1.31l7.17 9.08 14.7-14.1-1.79-7.41s7.77-13.63 7.65-20.09-13.38-15.06-22.83-14.94-18.29 3.59-16.5 6.1a13.54 13.54 0 0 0 3.95 3.7Z'
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          />
        </clipPath>
      </defs>
      <path
        d='M131.92 120.89s-48.27 45.74-39.06 109.2S158.55 342.56 203 383.34s107.55 46.38 153.07 15.17 20.93-70.68 46.65-126.95 42.49-68.19 28.69-126.42S335 50.12 252.77 65.51s-120.85 55.38-120.85 55.38Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M131.92 120.89s-48.27 45.74-39.06 109.2S158.55 342.56 203 383.34s107.55 46.38 153.07 15.17 20.93-70.68 46.65-126.95 42.49-68.19 28.69-126.42S335 50.12 252.77 65.51s-120.85 55.38-120.85 55.38Z'
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001
        }}
      />
      <path
        style={{
          fill: '#ccc'
        }}
        d='M339.91 111.11h-72.22l.73-10.53h72.22l-.73 10.53zM339.91 125.86h-72.22l.73-10.53h72.22l-.73 10.53zM339.91 140.62h-72.22l.73-10.54h72.22l-.73 10.54zM339.91 155.37h-72.22l.73-10.54h72.22l-.73 10.54zM339.91 170.12h-72.22l.73-10.54h72.22l-.73 10.54zM339.91 184.87h-72.22l.73-10.53h72.22l-.73 10.53zM339.91 199.62h-72.22l.73-10.53h72.22l-.73 10.53zM339.91 214.38h-72.22l.73-10.54h72.22l-.73 10.54zM339.91 229.13h-72.22l.73-10.54h72.22l-.73 10.54zM339.91 243.88h-72.22l.73-10.54h72.22l-.73 10.54zM339.91 258.63h-72.22l.73-10.54h72.22l-.73 10.54zM416.28 111.11h-72.23l.73-10.53h72.23l-.73 10.53zM416.28 125.86h-72.23l.73-10.53h72.23l-.73 10.53zM416.28 140.62h-72.23l.73-10.54h72.23l-.73 10.54zM416.28 155.37h-72.23l.73-10.54h72.23l-.73 10.54zM416.28 170.12h-72.23l.73-10.54h72.23l-.73 10.54zM416.28 184.87h-72.23l.73-10.53h72.23l-.73 10.53zM416.28 199.62h-72.23l.73-10.53h72.23l-.73 10.53zM416.28 214.38h-72.23l.73-10.54h72.23l-.73 10.54zM416.28 229.13h-72.23l.73-10.54h72.23l-.73 10.54zM416.28 243.88h-72.23l.73-10.54h72.23l-.73 10.54zM416.28 258.63h-72.23l.73-10.54h72.23l-.73 10.54z'
      />
      <path
        style={{
          fill: '#52aeb9'
        }}
        d='M108.34 100h149.22v128.02H108.34z'
      />
      <path
        style={{
          fill: '#fff'
        }}
        d='M115.8 106.4h134.29v115.22H115.8z'
      />
      <g
        style={{
          clipPath: 'url(#a)'
        }}
      >
        <path
          d='M250.09 141.33c-24.15 17.18-58.74 28.58-98.87 46.49A165.3 165.3 0 0 0 115.8 209v12.63h134.29ZM250.09 106.4H196.2c-6 5.13-13.09 10.86-21.47 17-30.67 22.49-17.38 45 19.43 28.62 18.89-8.39 39.92-27.82 55.93-44.75ZM139 119.32c0-9.39-11.69-2-23.15 7.44v23.71C127 139.53 139 127.72 139 119.32ZM150.2 156.64a10.74 10.74 0 1 1-10.74-10.74 10.74 10.74 0 0 1 10.74 10.74Z'
          style={{
            fill: '#52aeb9'
          }}
        />
        <path
          d='M250.09 141.33c-24.15 17.18-58.74 28.58-98.87 46.49A165.3 165.3 0 0 0 115.8 209v12.63h134.29ZM250.09 106.4H196.2c-6 5.13-13.09 10.86-21.47 17-30.67 22.49-17.38 45 19.43 28.62 18.89-8.39 39.92-27.82 55.93-44.75Z'
          style={{
            fill: '#fff',
            opacity: 0.75
          }}
        />
        <g
          style={{
            opacity: 0.75
          }}
        >
          <path
            d='M139 119.32c0-9.39-11.69-2-23.15 7.44v23.71C127 139.53 139 127.72 139 119.32Z'
            style={{
              fill: '#fff'
            }}
          />
        </g>
        <path
          d='M150.2 156.64a10.74 10.74 0 1 1-10.74-10.74 10.74 10.74 0 0 1 10.74 10.74Z'
          style={{
            fill: '#fff',
            opacity: 0.75
          }}
        />
      </g>
      <path
        d='M226.88 190.89a14.32 14.32 0 1 1-14.31-14.32 14.31 14.31 0 0 1 14.31 14.32Z'
        style={{
          fill: '#fff'
        }}
      />
      <path
        d='M428.74 363.88a22.52 22.52 0 0 0-5.08 10.56l-10.19 5.71v-27l.2.27 16.23-11.88c2.05.32 9.93 1.22 17.11-2.65 8.34-4.49 14.43-19.87 14.43-19.87s-19.88-2.25-26.61 6.09c-5.46 6.76-5.65 13.31-5.52 15.54l-15.84 11.59v-19.95a22.06 22.06 0 0 0 12.07-14.57c2.88-11.22-12.5-33.33-12.5-33.33s-16 22.76-12.83 34.3c2.72 9.77 10 12.87 12.19 13.59v43.25L401 366c.26-2.28.47-9.41-5.88-16.55-7.7-8.66-28.21-8-28.21-8s8.65 17.31 16 23.08c6.44 5 15.33 3 17.47 2.39l12 10v13.15h-17.85l7.29 45.56h21.87l7.31-45.55h-17.53v-8.7l11.2-6.28c2.72.76 10.33 2.32 17.53-1.28 9-4.49 16.35-14.11 16.35-14.11s-21.47-5.45-29.81 4.17Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M428.74 363.88a22.52 22.52 0 0 0-5.08 10.56l-10.19 5.71v-27l.2.27 16.23-11.88c2.05.32 9.93 1.22 17.11-2.65 8.34-4.49 14.43-19.87 14.43-19.87s-19.88-2.25-26.61 6.09c-5.46 6.76-5.65 13.31-5.52 15.54l-15.84 11.59v-19.95a22.06 22.06 0 0 0 12.07-14.57c2.88-11.22-12.5-33.33-12.5-33.33s-16 22.76-12.83 34.3c2.72 9.77 10 12.87 12.19 13.59v43.25L401 366c.26-2.28.47-9.41-5.88-16.55-7.7-8.66-28.21-8-28.21-8s8.65 17.31 16 23.08c6.44 5 15.33 3 17.47 2.39l12 10v13.15h-17.85l7.29 45.56h21.87l7.31-45.55h-17.53v-8.7l11.2-6.28c2.72.76 10.33 2.32 17.53-1.28 9-4.49 16.35-14.11 16.35-14.11s-21.47-5.45-29.81 4.17Z'
        style={{
          fill: '#fff',
          opacity: 0.5
        }}
      />
      <path
        d='m408.53 226.23-29.43.25s-2-.25-2.43 1.21c0 0-5.11 12.9-5.35 17.52s1 3.89-1 10-7.54 20.43-10.7 32.11c-4.25 15.69-5.36 32.35-5.63 48.55 0 2.6-1.6 25 2.45 25 3.36 0 34.33 4.11 34.7-1.86 1.49-23.8-2.81-47.05 5.45-70.19 3.21-9 6.89-18.48 9.62-27.88s3.2-19.18 5-28.8c1.21-6.64-2.68-5.91-2.68-5.91Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M411.42 229.15c-.29-3.4-2.89-2.92-2.89-2.92l-29.43.25s-2-.25-2.43 1.21c0 0-5.11 12.9-5.35 17.52a21.2 21.2 0 0 0 .1 3.66l.62 1s1 8.51 12.65 9 18.25-4.87 20.68-10 3.41-13.38 5.35-18.25a16.16 16.16 0 0 1 .7-1.47Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M404.59 238.65c-2 7.11-.79 9.08-2.76 21.32s-9.08 26-11.85 38.69-1.57 57.24-1.18 59.22a17.12 17.12 0 0 1 .17 3.17c1.33-.5 2.13-1.18 2.19-2.11 1.49-23.8-2.81-47.05 5.45-70.19 3.21-9 6.89-18.48 9.62-27.88s3.2-19.18 5-28.8c.75-4-.43-5.31-1.46-5.7a53 53 0 0 0-5.18 12.28Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='m347.74 418.28-.2-.16v-27.48h3.95v-17.37h-14.6v17.37h3.94v27.95l-34.94 11.14v2.76a3.16 3.16 0 1 0 6.32 0v-.39l29.22-3.4v4.19a2.76 2.76 0 0 0 5.52 0v-4.33l30.4 3.54v.39a3.16 3.16 0 1 0 6.32 0v-2.76Z'
        style={{
          fill: '#263238'
        }}
      />
      <rect
        x={298.63}
        y={356.33}
        width={94.77}
        height={20.15}
        rx={4}
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='m47.84 226.23 29.43.25s2-.25 2.44 1.21c0 0 5.11 12.9 5.35 17.52s-1 3.89 1 10 7.54 20.43 10.7 32.11c4.26 15.69 5.36 32.35 5.63 48.55 0 2.6 1.6 25-2.45 25-3.35 0-34.32 4.11-34.7-1.86-1.48-23.8 2.81-47.05-5.45-70.19-3.21-9-6.89-18.48-9.61-27.88s-3.2-19.18-5-28.8c-1.18-6.64 2.66-5.91 2.66-5.91Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M45 229.15c.29-3.4 2.88-2.92 2.88-2.92l29.43.25s2-.25 2.44 1.21c0 0 5.11 12.9 5.35 17.52a22 22 0 0 1-.11 3.66l-.62 1s-1 8.51-12.65 9S53.44 254 51 248.86s-3.4-13.38-5.35-18.25a13.74 13.74 0 0 0-.65-1.46Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M51.78 238.65c2 7.11.79 9.08 2.76 21.32s9.09 26 11.85 38.69 1.61 57.24 1.18 59.22a18 18 0 0 0-.17 3.17c-1.32-.5-2.13-1.18-2.19-2.11-1.48-23.8 2.81-47.05-5.45-70.19-3.21-9-6.89-18.48-9.61-27.88s-3.2-19.18-5-28.8c-.74-4 .44-5.31 1.46-5.7a52.5 52.5 0 0 1 5.17 12.28Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='m108.63 418.28.2-.16v-27.48h-4v-17.37h14.61v17.37h-3.95v27.95l34.94 11.14v2.76a3.16 3.16 0 0 1-6.32 0v-.39L115 428.7v4.19a2.77 2.77 0 0 1-5.53 0v-4.33L79 432.1v.39a3.16 3.16 0 1 1-6.31 0v-2.76Z'
        style={{
          fill: '#263238'
        }}
      />
      <rect
        x={62.98}
        y={356.33}
        width={94.77}
        height={20.15}
        rx={4}
        transform='rotate(180 110.36 366.4)'
        style={{
          fill: '#263238'
        }}
      />
      <path
        style={{
          fill: '#ccc'
        }}
        d='M134.4 299.74H372V436.3H134.4z'
      />
      <path
        style={{
          fill: '#263238'
        }}
        d='M134.52 303.02h237.3v3.78h-237.3z'
      />
      <path
        style={{
          fill: '#ccc'
        }}
        d='M129.11 297.5h244.65v5.97H129.11z'
      />
      <path
        d='M197.66 285.64s2.8 12.51 2.64 16-6.75 12.84-6.92 10.7a14.6 14.6 0 0 1 1.16-5.27s-2.31 3.13-3.13 3 .66-4.45.66-4.45-2.47 2.47-3.3 2 1.81-4.12 1.81-4.12-3 3.13-3.12 2.14 2.63-5.1 2.63-5.1-2.8-5.93-3-8.4a8 8 0 0 1 1.64-4.77Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#b)'
        }}
      >
        <path
          d='M197.66 285.64s2.8 12.51 2.64 16-6.75 12.84-6.92 10.7a14.6 14.6 0 0 1 1.16-5.27s-2.31 3.13-3.13 3 .66-4.45.66-4.45-2.47 2.47-3.3 2 1.81-4.12 1.81-4.12-3 3.13-3.12 2.14 2.63-5.1 2.63-5.1-2.8-5.93-3-8.4a8 8 0 0 1 1.64-4.77Z'
          style={{
            fill: '#d9d9d9'
          }}
        />
      </g>
      <path
        d='M197.66 285.64s2.8 12.51 2.64 16-6.75 12.84-6.92 10.7a14.6 14.6 0 0 1 1.16-5.27s-2.31 3.13-3.13 3 .66-4.45.66-4.45-2.47 2.47-3.3 2 1.81-4.12 1.81-4.12-3 3.13-3.12 2.14 2.63-5.1 2.63-5.1-2.8-5.93-3-8.4a8 8 0 0 1 1.64-4.77Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M197 285.64s2 8.73 1.82 11.2-2.64 9.71-4.45 8.39 1.15-6.25 1.15-6.25-5.08-3.57-6.25-6.92-.5-4.77-.5-4.77Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#c)'
        }}
      >
        <path
          d='M197 285.64s2 8.73 1.82 11.2-2.64 9.71-4.45 8.39 1.15-6.25 1.15-6.25-5.08-3.57-6.25-6.92-.5-4.77-.5-4.77Z'
          style={{
            fill: '#d9d9d9'
          }}
        />
      </g>
      <path
        d='M197 285.64s2 8.73 1.82 11.2-2.64 9.71-4.45 8.39 1.15-6.25 1.15-6.25-5.08-3.57-6.25-6.92-.5-4.77-.5-4.77Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m190.97 244.73 7.01 41.75-9.88 2.23-9.88-32.19 12.75-11.79z'
      />
      <path
        d='M188.1 265.13s.64 49.72 1.59 63.11-2.55 31.24-5.73 36.34-17.22 37-16.58 38.89 3.51 7.65 3.51 9.24v6.69s15.93 10.52 17.21 10.52 2.87 2.55-.32 4.47-38.89 1.91-40.48.63a7.72 7.72 0 0 1-2.23-3.5s-5.1 1.91-4.78-2.87 6.05-25.18 6.05-25.18-3.5-22.32-2.23-36.66 5.74-31.56 5.74-35.06-4.46-26.78-5.1-30.6-9.88-14.67-5.74-23.27 7.33-22.32 8.61-23.27 18.49-1.6 25.5 0 14.66 1.59 14.98 10.52Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M156.5 164.91s-5.45 4.88-6.39 6.57-12.38 18-12.57 29.66 8.46 46.92 8.46 46.92l-13 33.07 3.76 1.5 3.75-4.13v6.2s4.32 3.94 15.58 7.69 25.72-.56 25.72-.56l4.31-27.44v27.07l5.07-3.38.41-45.08s4.13-24.21 4.13-31-11.45-28-16.52-34.16-13.52-11.26-16-13.14-6.71.21-6.71.21Z'
        style={{
          fill: '#263238'
        }}
      />
      <g
        style={{
          clipPath: 'url(#d)'
        }}
      >
        <path
          d='M156.5 164.91s-5.45 4.88-6.39 6.57-12.38 18-12.57 29.66 8.46 46.92 8.46 46.92l-13 33.07 3.76 1.5 3.75-4.13v6.2s4.32 3.94 15.58 7.69 25.72-.56 25.72-.56l4.31-27.44v27.07l5.07-3.38.41-45.08s4.13-24.21 4.13-31-11.45-28-16.52-34.16-13.52-11.26-16-13.14-6.71.21-6.71.21Z'
          style={{
            fill: '#fff',
            opacity: 0.17
          }}
        />
        <path
          d='M140.46 204.88s6.82 4.83 11.36 6.53M142.45 210s7.67 2 11.64 2M153.53 255.42l21.58 2.56-1.42 4.54-19.88-2.27'
          style={{
            fill: 'none',
            stroke: '#263238',
            strokeLinecap: 'round',
            strokeLinejoin: 'round'
          }}
        />
      </g>
      <path
        d='M156.5 164.91s-5.45 4.88-6.39 6.57-12.38 18-12.57 29.66 8.46 46.92 8.46 46.92l-13 33.07 3.76 1.5 3.75-4.13v6.2s4.32 3.94 15.58 7.69 25.72-.56 25.72-.56l4.31-27.44v27.07l5.07-3.38.41-45.08s4.13-24.21 4.13-31-11.45-28-16.52-34.16-13.52-11.26-16-13.14-6.71.21-6.71.21Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='m155.37 166 20.84 22.53 12.2 23.28s-2.63-17.27-4.88-22-12.39-16.45-13.53-19.08-14.63-4.73-14.63-4.73Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='m182.64 200.83 5.77 11s-1.79-11.77-3.69-18.52l-1.19-3.45a62.49 62.49 0 0 0-5.42-8l-3.27-.65 1.37 7.36 4.22 3.45Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='m177.08 174.49 1.08 1.25 3.39 4 2 10.16-8.69-8.67s1.14-5.23 2.22-6.74Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M186.91 132.62s4.56 3.5 5.13 6.88-1.13 7.14-.94 8.45 3.56 4.32 3.38 5.82-3 2.06-3 2.06-3.06 11.14-3.81 13-4.51 1.31-7.32.94l-5.07 15-16.72-19.67 2.63-7.89s-7.13-8.63-7.7-17.83 6.76-15.58 17.27-16.89 19.34 3.75 20.46 5.82-4.31 4.31-4.31 4.31Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#e)'
        }}
      >
        <path
          d='M186.91 132.62s4.56 3.5 5.13 6.88-1.13 7.14-.94 8.45 3.56 4.32 3.38 5.82-3 2.06-3 2.06-3.06 11.14-3.81 13-4.51 1.31-7.32.94l-5.07 15-16.72-19.67 2.63-7.89s-7.13-8.63-7.7-17.83 6.76-15.58 17.27-16.89 19.34 3.75 20.46 5.82-4.31 4.31-4.31 4.31Z'
          style={{
            fill: '#d9d9d9'
          }}
        />
        <path
          d='M167.43 175.56c2.82-.57 7.55-1.55 11.81-2.51l1.1-3.26c2.34.31 5.34.75 6.7-.2-3.2-1.32-11.12-3.63-12.74-6.6-2-3.69 5-14.43 5-19.8s-6.71-11.41-6.71-11.41c-3.23-3.07-9.12-4-14.35-4a14.08 14.08 0 0 0-4.78 11.65c.57 9.2 7.7 17.83 7.7 17.83l-2.63 7.89Z'
          style={{
            fill: '#b8b8b8'
          }}
        />
      </g>
      <path
        d='M186.91 132.62s4.56 3.5 5.13 6.88-1.13 7.14-.94 8.45 3.56 4.32 3.38 5.82-3 2.06-3 2.06-3.06 11.14-3.81 13-4.51 1.31-7.32.94l-5.07 15-16.72-19.67 2.63-7.89s-7.13-8.63-7.7-17.83 6.76-15.58 17.27-16.89 19.34 3.75 20.46 5.82-4.31 4.31-4.31 4.31Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M186.91 132.62s5.44-2.25 4.31-4.31-9.95-7.14-20.46-5.82-17.83 7.69-17.27 16.89 7.7 17.83 7.7 17.83l-.4 1.2c3.89 1.9 7.91-1.2 7.91-1.2a3.87 3.87 0 0 1-4.51-.75c-2.25-2.06-2.44-8.63.38-9.2s4.69.57 4.69 2.63.56.75 2.07.56.18-4.69 1.12-5.44a14.69 14.69 0 0 0 3.94-3.57c1.69-2.25-3.19-4.12-3.75-6.19s14.27-2.63 14.27-2.63Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m188.25 147.28-3.48 1.16h3.15M189.58 143.8s-1.49-2.15-3.15-1.16-1.82 1.66-1.82 1.66M186 158.88a4.89 4.89 0 0 0 3.48.8'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m157.81 161.53 21.02 20.65-1.87 8.82-22.34-24.03 3.19-5.44zM238.56 240.48s-7.4-4.4-8.6-4-12.8 8.8-12 10.8 7 4.6 10.6 6 11-1.6 11-1.6Z'
      />
      <path
        d='M217 238.88s6.4-3.6 7.4-3.6 6.8 5.2 6.8 5.2l-2.4 3.6s7.6 3.8 6.8 5.2-3.8 3.6-7 4-12.2-5.4-12.2-5.4Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#f)'
        }}
      >
        <path
          d='M217 238.88s6.4-3.6 7.4-3.6 6.8 5.2 6.8 5.2l-2.4 3.6s7.6 3.8 6.8 5.2-3.8 3.6-7 4-12.2-5.4-12.2-5.4Z'
          style={{
            fill: '#d9d9d9'
          }}
        />
      </g>
      <path
        d='M217 238.88s6.4-3.6 7.4-3.6 6.8 5.2 6.8 5.2l-2.4 3.6s7.6 3.8 6.8 5.2-3.8 3.6-7 4-12.2-5.4-12.2-5.4Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m212.56 237.28 5.6 1-1.8 9.6-4.8-1.6 1-9z'
      />
      <path
        d='M147.57 181.48s-5.4 10.4-6 15.6 21.4 46.6 23 48 48.39 2.8 48.39 2.8l2-10.2-40-11.4-13.6-41.8'
        style={{
          fill: '#263238'
        }}
      />
      <g
        style={{
          clipPath: 'url(#g)'
        }}
      >
        <path
          d='M147.57 181.48s-5.4 10.4-6 15.6 21.4 46.6 23 48 48.39 2.8 48.39 2.8l2-10.2-40-11.4-13.6-41.8'
          style={{
            fill: '#fff',
            opacity: 0.17
          }}
        />
      </g>
      <path
        d='M147.57 181.48s-5.4 10.4-6 15.6 21.4 46.6 23 48 48.39 2.8 48.39 2.8l2-10.2-40-11.4-13.6-41.8'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M231.56 237.48s-2.8-2.4-3.8.4-7.2 9.6-4.4 9.8 8-5.6 8-5.6M219.16 248.68s-.72 3.37 1 4.8c1.21 1 1.89-1.8 1.6-3.8s-2-2.6-2.6-1Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M222 249.48s-.72 3.37 1 4.8c1.21 1 1.89-1.8 1.6-3.8s-2.04-2.6-2.6-1Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M224.76 250.68s-.72 3.37 1 4.8c1.21 1 1.89-1.8 1.6-3.8s-2-2.6-2.6-1Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M227.53 252.31s-.66 2.27.92 3.23c1.11.68 1.74-1.21 1.47-2.56s-1.84-1.75-2.39-.67Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m238.56 240.48.6 12.8 4.4-1.2-1-11.8-4 .2z'
      />
      <path
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m241.56 239.28 2 15.2 33.4-3.6.8-19.8-36.2 8.2z'
      />
      <path
        d='M183 299.87s-6.06 40.48-6.06 54.19-9.24 36.66-9.56 49.41'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M147.3 435c1.59 1.28 37.29 1.28 40.48-.63s1.59-4.47.32-4.47-17.21-10.52-17.21-10.52-8.61 6.7-13.39 8.29-12.43 1.91-12.43 1.91v1.92a7.72 7.72 0 0 0 2.23 3.5Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M187.78 434.39c1.68-1 2-2.2 1.8-3.09a78.85 78.85 0 0 1-14.23 1.49c-9.63.26-24.78-.33-30-.55a7.17 7.17 0 0 0 2 2.78c1.54 1.28 37.24 1.28 40.43-.63Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='m144.14 431.77.93-2.17 1.27-26.13s-5.74 20.4-6.05 25.18c-.24 3.48 2.4 3.41 3.85 3.12ZM281.81 291.9s-3.18 47.5 0 66.62 11.19 52.28 12.43 56.42a22 22 0 0 1 1 7.65l17.85 2.55s-5.42-62.79-5.42-70.12 6.06-62.16 6.06-62.16Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M295.2 422.59s-19.44 8.29-21.68 9.88 4.79 3.83 13.39 3.83 17.22-1 17.22-1l3.5-1.91.32 2.23h9.24s1.6-7.65 1-9.56a35.26 35.26 0 0 0-1.59-3.83Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M278.94 288.08s1.6 45.58 5.42 61.84 10.52 55.78 10.52 59.92a24.39 24.39 0 0 1-.95 7l-1.28 4.16a12.83 12.83 0 0 0 1 2.87s8.28 1.91 13.7 1.91a36 36 0 0 0 10-1.51s.17-10 .49-14.75-1.27-35.38-1.27-42.71-1.92-11.48-1.92-20.4-1.59-32.83-1.27-36.66 4.78-8 5.74-12.75 1.91-9.88 1.91-9.88Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M282.76 336s8.91 61.81 11.14 65.71a11.36 11.36 0 0 1 1 8.12'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M271.29 252.06s-1.19 12.19-1.19 21.51c0 5.2-.05 12-.1 16.92a8.72 8.72 0 0 0 7 8.61 33.43 33.43 0 0 0 6.37.77l-7.49-46.38s-3.39-5.73-4.59-1.43Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M306.68 169a20 20 0 0 1 4.94 3.67A37.14 37.14 0 0 1 315 178s13.55 15.62 13.87 17.21-7.65 51.8-7.65 51.8l9.08 47.49s-14.66 8.13-25.82 8.61c-7.85.34-19.66-2.17-26-3.69a6.18 6.18 0 0 1-4.73-5.46c-.62-6.68-1.78-20-2.46-32.61-.78-14.65 1.44-43.67 3-53.87s6.54-24.06 10.36-29.8S299 166 306.68 169Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M315 178a37.14 37.14 0 0 0-3.34-5.26 20 20 0 0 0-4.94-3.67c-6.36-2.51-14.68 1.13-19.51 5.73-.64 1.42-5.68 12.92-8.07 22.32-2.55 10-2.23 22.64-2.23 22.64s8.45-10.84 19.92-23.59a152.09 152.09 0 0 1 18.7-17.57Z'
        style={{
          fill: '#263238'
        }}
      />
      <g
        style={{
          clipPath: 'url(#h)'
        }}
      >
        <path
          d='M315 178a37.14 37.14 0 0 0-3.34-5.26 20 20 0 0 0-4.94-3.67c-6.36-2.51-14.68 1.13-19.51 5.73-.64 1.42-5.68 12.92-8.07 22.32-2.55 10-2.23 22.64-2.23 22.64s8.45-10.84 19.92-23.59a152.09 152.09 0 0 1 18.7-17.57Z'
          style={{
            fill: '#fff',
            opacity: 0.17
          }}
        />
      </g>
      <path
        d='M315 178a37.14 37.14 0 0 0-3.34-5.26 20 20 0 0 0-4.94-3.67c-6.36-2.51-14.68 1.13-19.51 5.73-.64 1.42-5.68 12.92-8.07 22.32-2.55 10-2.23 22.64-2.23 22.64s8.45-10.84 19.92-23.59a152.09 152.09 0 0 1 18.7-17.57Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M286.12 182.13s-4.31 13.51-5.62 17.33-1.44 9.81-1.44 9.81 16.14-24.15 20.08-28.21 9.09-10.4 9.09-10.4l-3.47-1Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M287.31 177.23s-1.91 5.62-1.91 6-.48 5.74-.48 5.74l6.81-9.21Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='M304.64 166 286 181.18l2.87 8.36 19.12-19.48-3.35-4.06zM302.23 286.4s-2.65 6.59-3.44 8.75-.39 10.62-.29 11.51 1.67 1.08 1.67 1.08.49 6.78 1.08 7a10.5 10.5 0 0 0 1.87.29s1.08 3.45 2.46 3.15a2.24 2.24 0 0 0 1.57-1.87 2.6 2.6 0 0 0 2.36-1.08 12.8 12.8 0 0 0 1.38-2.65s2.36-1.18 2.55-2.07.79-4 .79-4 .69-.2.69-2.26-1.57-14.75-1.57-14.75Z'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m300.17 307.74.29-5.98M303.12 315.01l.64-8.85M307.15 316.29l.46-9.58M310.89 312.56l.57-6.67'
      />
      <path
        d='m321.66 190.86 7.17 4.3-2.39 49.57-12.75 46.85-12.11-3.18 3.82-37-1.91-37.29s2.71-8.69 8.92-13.47'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M306.91 199.23s-3.26 8.28-3.42 14.9M309.63 203.17s-5.58 9.11-5.58 13.23 1.47 38.5 1.47 38.5l-3.82 29.68'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M275 135.63s-3.83 10.88-3.47 12.44.72 3.94.72 3.94-6 7.41-5.26 8.25 4.06 1.79 4.06 1.79 2.75 11.48 3.83 12.55 7.77-1.31 7.77-1.31l7.17 9.08 14.7-14.1-1.79-7.41s7.77-13.63 7.65-20.09-13.38-15.06-22.83-14.94-18.29 3.59-16.5 6.1a13.54 13.54 0 0 0 3.95 3.7Z'
        style={{
          fill: '#fff'
        }}
      />
      <g
        style={{
          clipPath: 'url(#i)'
        }}
      >
        <path
          d='M305.74 133.3a21 21 0 0 0-5.29 1.5l-14.09 5.71s-6.71 5.7-6 10.06 6.71 10.4 5.37 14.76-9.73 9.06-9.73 9.06l2.63.1c2-.47 4.06-1.2 4.06-1.2l1.1 1.39 13.58.49 7.19-6.9-1.79-7.41s7.77-13.63 7.65-20.09c-.08-2.38-1.92-5.05-4.68-7.47Z'
          style={{
            fill: '#b8b8b8'
          }}
        />
      </g>
      <path
        d='M275 135.63s-3.83 10.88-3.47 12.44.72 3.94.72 3.94-6 7.41-5.26 8.25 4.06 1.79 4.06 1.79 2.75 11.48 3.83 12.55 7.77-1.31 7.77-1.31l7.17 9.08 14.7-14.1-1.79-7.41s7.77-13.63 7.65-20.09-13.38-15.06-22.83-14.94-18.29 3.59-16.5 6.1a13.54 13.54 0 0 0 3.95 3.7Z'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M302.73 160.86s7.77-13.63 7.65-20.09-13.38-15.06-22.83-14.94-18.29 3.59-16.5 6.1a13.54 13.54 0 0 0 3.95 3.7s5.86 2.75 5.86 3.47-3.47 4.54-2.27 6.22 6.93 3.7 6.93 3.7-1 3.23.48 3.59 2.15-1.08 3.1-2 4.31-2.28 4.79 1.79a12.35 12.35 0 0 1-2.16 8.25c-.47.35 6.1 4.78 9 3.46.75-.34 1.63-.79 2.51-1.26Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <ellipse
        cx={274.04}
        cy={151.77}
        rx={0.84}
        ry={3.23}
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='m275 150.46 14.1.12M272.68 146.57s1.34-2.14 2.68.53M272.41 164a15.09 15.09 0 0 0 4-2.41'
      />
    </svg>
  )
}
