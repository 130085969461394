import { gql } from '@apollo/client'

const GET_ALL_USERS = gql`
  query getAllUsers($sortBy: SortBy) {
    allUsers(sortBy: $sortBy) {
      _id
      creation_date
      last_mod
      role
      country
      name
      public_name
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
      email
      phone
      about_us
      company_data {
        categories
        nif
        bio
        postal_code
        province
        city
        ratings {
          ratings
          comment
          user_id
          date
        }
        legal_form
      }
      settings {
        theme
      }
      plan {
        trial
        customer_id
        payment_method_id
        plan_id
      }
    }
  }
`

const GET_USER = gql`
  query GetUser($userId: String!, $uid: String) {
    getUser(user_id: $userId, uid: $uid) {
      _id
      creation_date
      last_mod
      role
      country
      name
      public_name
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
      email
      phone
      company_data {
        categories
        nif
        bio
        postal_code
        province
        city
        ratings {
          ratings
          comment
          user_id
          date
        }
        legal_form
      }
      plan {
        trial
        customer_id
        payment_method_id
        plan_id
      }
    }
  }
`

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      email
    }
  }
`

const GET_USERS = gql`
  query getUsers(
    $role: Role
    $country: String
    $name: String
    $public_name: String
    $email: String
    $phone: String
    $city: String
    $province: String
    $legal_form: String
    $nif: String
    $categories: [String]
  ) {
    getUsers(
      role: $role
      country: $country
      name: $name
      public_name: $public_name
      email: $email
      phone: $phone
      city: $city
      province: $province
      legal_form: $legal_form
      nif: $nif
      categories: $categories
    ) {
      _id
      role
      name
      email
      phone
      country
      public_name
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
      company_data {
        ratings {
          ratings
        }
        categories
        legal_form
        nif
        bio
        postal_code
        city
        province
      }
    }
  }
`

const GET_OFFER = gql`
  query getOffer($id: String!) {
    getOffer(id: $id) {
      _id
      visible_for_user
      visible_for_company
      state
      user_id
      company_id
      job_id
      creation_date
      start_date
      end_date
      amount
      rate_type
    }
  }
`

const GET_OFFER_BY_JOB_AND_COMPANY = gql`
  query GetOffers($jobId: String, $companyId: String, $limit: Int) {
    getOffers(job_id: $jobId, company_id: $companyId, limit: $limit) {
      _id
      state
    }
  }
`

const GET_OFFER_STATE = gql`
  query getOfferState($id: String!) {
    getOfferState(id: $id)
  }
`

const GET_JOB = gql`
  query getJob($jobId: String) {
    getJob(job_id: $jobId) {
      _id
      user_id
      title
      category
      country
      province
      postal_code
      city
      budget
      start_date
      description
      state
      pictures {
        type
        name
        url
      }
      questions {
        id
        main
        answer
      }
      chats {
        _id
        offer {
          _id
        }
        participants
        messages {
          _id
          from
          content {
            type
            name
            url
          }
          text
          date
          state
        }
      }
      post_date
      last_mod
      creation_date
    }
  }
`

const GET_USER_ROLE = gql`
  query GetUser($userId: String!) {
    getUser(user_id: $userId) {
      role
    }
  }
`

const GET_POSTS = gql`
  query getJobs(
    $title: String
    $category: [String]
    $province: String
    $country: String
    $postal_code: String
    $budget: Int
    $start_date: String
    $description: String
    $state: JobState = posted
    $sortBy: SortBy
    $offset: Int!
    $limit: Int!
  ) {
    getJobs(
      title: $title
      category: $category
      province: $province
      country: $country
      postal_code: $postal_code
      budget: $budget
      start_date: $start_date
      description: $description
      state: $state
      sortBy: $sortBy
      offset: $offset
      limit: $limit
    ) {
      _id
      user_id
      title
      category
      country
      province
      postal_code
      city
      budget
      start_date
      post_date
      last_mod
      description
    }
  }
`

const GET_POST_BY_ID = gql`
  query GetJobById($id: String!) {
    getJob(job_id: $id) {
      _id
      user_id
      category
      title
      country
      province
      postal_code
      city
      budget
      start_date
      description
      state
      pictures {
        type
        name
        url
      }
      questions {
        id
        main
        answer
      }
      post_date
      last_mod
    }
  }
`

const GET_USER_JOBS = gql`
  query GetUserJobs(
    $userId: String!
    $sortBy: SortBy
    $offset: Int!
    $limit: Int!
    $state: [JobState]
    $categories: [String]
  ) {
    getUserJobs(
      user_id: $userId
      sortBy: $sortBy
      offset: $offset
      limit: $limit
      state: $state
      categories: $categories
    ) {
      _id
      title
      start_date
      state
      post_date
      postal_code
      city
      category
      assigned_to
    }
  }
`

const GET_PARTICULAR = gql`
  query GetParticular($userId: String!) {
    getUser(user_id: $userId) {
      _id
      public_name
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
    }
  }
`

const GET_COMPANY = gql`
  query GetCompany($userId: String!) {
    getUser(user_id: $userId) {
      _id
      public_name
      name
      email
      phone
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
      company_data {
        categories
        legal_form
        bio
        postal_code
        city
        province
      }
    }
  }
`

const BASIC_COMPANY_INFO = gql`
  query GetCompanyBasic($userId: String!) {
    getUser(user_id: $userId) {
      _id
      public_name
      name
      phone
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
      company_data {
        categories
        legal_form
        bio
        postal_code
        city
        province
      }
    }
  }
`

const COMPANY_PLAN_STATUS = gql`
  query CompanyPlanStatus($userId: String!) {
    companyPlanStatus(user_id: $userId) {
      trial
      plan_id
      cancel_at
    }
  }
`

const MOST_VALORATED_COMPANIES = gql`
  query MostValuatedCompanies($country: String) {
    mostValueatedCompanies(country: $country) {
      _id
      name
      logo
      ratings_average
      categories
      province
    }
  }
`

const COMPANY_RATINGS = gql`
  query CompanyRatings(
    $userId: String!
    $offset: Int!
    $limit: Int!
    $sortBy: SortBy
  ) {
    companyRatings(
      user_id: $userId
      offset: $offset
      limit: $limit
      sortBy: $sortBy
    ) {
      ratings
      comment
      user_id
      date
      last_mod
    }
    companyRatingsAverage(user_id: $userId) {
      communication
      onTime
      asDescribed
      recommendation
      average
      count
    }
  }
`

const COMPANY_AVERAGES = gql`
  query CompanyAverages($userId: String!) {
    companyRatingsAverage(user_id: $userId) {
      communication
      onTime
      asDescribed
      recommendation
      average
      count
    }
  }
`

const USER_RATINGS = gql`
  query UserRatings($userId: String!, $companyId: String) {
    userRatings(user_id: $userId, company_id: $companyId) {
      comment
      user_id
      date
      ratings
    }
  }
`

const GET_COMPANIES = gql`
  query Companies(
    $sortBy: SortBy
    $country: String
    $name: String
    $email: String
    $phone: String
    $city: String
    $province: String
    $legal_form: String
    $nif: String
    $bio: String
    $categories: [String]
    $offset: Int!
    $limit: Int!
  ) {
    companies(
      sortBy: $sortBy
      country: $country
      name: $name
      email: $email
      phone: $phone
      city: $city
      province: $province
      legal_form: $legal_form
      nif: $nif
      bio: $bio
      categories: $categories
      offset: $offset
      limit: $limit
    ) {
      _id
      name
      country
      public_name
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
      company_data {
        ratings {
          ratings
        }
        legal_form
        postal_code
        city
        province
        nif
        bio
      }
      creation_date
    }
  }
`

const GET_COMPANIES_BY_ID = gql`
  query CompaniesByIds($ids: [String]) {
    companiesByIds(ids: $ids) {
      _id
      name
      country
      public_name
      picture {
        __typename
        ... on ProfileAvatar {
          pic
          bg
        }
        ... on PictureString {
          url
        }
      }
      company_data {
        ratings {
          ratings
        }
        legal_form
        postal_code
        city
        province
        nif
        bio
      }
      creation_date
    }
  }
`

const GET_COMPANIES_LOGOS = gql`
  query CompaniesLogos {
    companiesLogos {
      name
      picture {
        __typename
        ... on PictureString {
          url
        }
      }
    }
  }
`

const GET_FILTERED_COMPANIES_COUNT = gql`
  query CountFilteredCompanies(
    $bio: String
    $category: String
    $province: String
  ) {
    countFilteredCompanies(bio: $bio, category: $category, province: $province)
  }
`

const GET_USER_OFFERS = gql`
  query GetUserOffers(
    $userId: String!
    $offset: Int!
    $limit: Int!
    $sortBy: SortBy
    $state: [OfferState]
    $skip: [OfferState]
  ) {
    getUserOffers(
      user_id: $userId
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      state: $state
      skip: $skip
    ) {
      _id
      creation_date
      job_id
      company_id
      user_id
      visible_for_user
      visible_for_company
      rate_type
      amount
      start_date
      end_date
      state
      post {
        _id
        title
        city
      }
      particular {
        public_name
      }
    }
  }
`

const GET_OFFER_META = gql`
  query GetOfferMeta($userId: String!, $jobId: String!) {
    getUser(user_id: $userId) {
      _id
      public_name
    }
    getJob(job_id: $jobId) {
      _id
      category
      title
      city
    }
  }
`

// To see if the company alredy sent an offer for the job
const GET_COMPANY_OFFERS = gql`
  query GetCompanyOffers(
    $userId: String!
    $jobId: String
    $companyId: String
    $uid: String!
  ) {
    getUser(user_id: $userId, uid: $uid) {
      plan {
        trial
        customer_id
        plan_id
      }
    }
    getOffers(job_id: $jobId, company_id: $companyId) {
      _id
      state
    }
  }
`

const RECEIVED_OFFERS = gql`
  query ReceivedOffers(
    $sortBy: SortBy
    $job_id: String
    $skip: [OfferState]
    $limit: Int
  ) {
    getOffers(sortBy: $sortBy, job_id: $job_id, skip: $skip, limit: $limit) {
      _id
      company_id
    }
  }
`

const GET_USER_CHATS = gql`
  query UserChats($userId: String!, $state: [OfferState]) {
    getUserChats(user_id: $userId, state: $state) {
      job {
        _id
        title
        state
        category
        city
        province
      }
      chats {
        chat_id
        last_message {
          from
          content {
            type
            name
            value
          }
          date
          state
        }
        participants
        offer {
          _id
          state
        }
      }
    }
  }
`

const GET_CHAT = gql`
  query GetChat(
    $jobId: String!
    $chatId: String
    $offerId: String
    $companyId: String
  ) {
    getChat(
      job_id: $jobId
      chat_id: $chatId
      offer_id: $offerId
      company_id: $companyId
    ) {
      participants
      messages {
        _id
        from
        content {
          type
          name
          value
        }
        date
        state
      }
      _id
      offer {
        _id
      }
    }
  }
`

const GET_CHAT_ID_BY_ASSIGNED = gql`
  query GetChat($jobId: String!, $companyId: String) {
    getChat(job_id: $jobId, company_id: $companyId) {
      _id
    }
  }
`

const GET_TEXT = gql`
  query getText($name: String) {
    getText(name: $name) {
      _id
      name
      content
    }
  }
`

const GET_CONTACTS = gql`
  query getContacts($user_id: String!, $category: String) {
    getContacts(user_id: $user_id, category: $category) {
      _id
      message
      category
    }
  }
`

export {
  GET_ALL_USERS,
  GET_USER,
  GET_USER_BY_EMAIL,
  GET_USERS,
  GET_OFFER,
  GET_OFFER_BY_JOB_AND_COMPANY,
  GET_OFFER_STATE,
  GET_USER_OFFERS,
  GET_JOB,
  GET_USER_JOBS,
  GET_POSTS,
  GET_USER_CHATS,
  GET_TEXT,
  GET_CONTACTS,
  GET_USER_ROLE,
  GET_PARTICULAR,
  GET_COMPANY,
  BASIC_COMPANY_INFO,
  COMPANY_PLAN_STATUS,
  GET_COMPANIES,
  GET_COMPANIES_BY_ID,
  GET_COMPANIES_LOGOS,
  GET_FILTERED_COMPANIES_COUNT,
  MOST_VALORATED_COMPANIES,
  COMPANY_RATINGS,
  COMPANY_AVERAGES,
  USER_RATINGS,
  GET_COMPANY_OFFERS,
  GET_POST_BY_ID,
  GET_OFFER_META,
  RECEIVED_OFFERS,
  GET_CHAT,
  GET_CHAT_ID_BY_ASSIGNED
}
