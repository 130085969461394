import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, ButtonBase, Typography, Box, Badge } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_USER_ROLE } from 'apis/querys'
import { useHistory } from 'react-router-dom'
import { routes } from 'constants/Routes'
import { getUserPicture } from 'helpers'
import { useI18N, useUserPublicInfo } from 'hooks'

InboxChat.propTypes = {
  onClick: PropTypes.func,
  unread: PropTypes.bool,
  hasAvatar: PropTypes.bool,
  receptorId: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.string,
  role: PropTypes.string
}

export default function InboxChat({
  fromCurrentUser,
  hasAvatar,
  receptorId,
  date,
  content,
  chatId,
  jobData,
  offerData,
  unread
}) {
  const { t } = useI18N()
  const history = useHistory()
  const [userData, setUserData] = useState()
  const { getBasicUserInfo } = useUserPublicInfo()

  const { loading, data } = useQuery(GET_USER_ROLE, {
    variables: { userId: receptorId }
  })

  useEffect(() => {
    if (!loading && data && data.getUser) {
      getUserData(data.getUser.role)
    }
  }, [loading, data])

  const getUserData = async (receptorRole) => {
    const { name, picture } = await getBasicUserInfo(receptorId, receptorRole)
    setUserData({ name, picture })
  }

  if (loading || !userData) return null

  const handleClick = () => {
    if (offerData.state === 'proposal') {
      // If Proposal go to the job offer page
      history.push(`${routes.post}?id=${jobData._id}`, {
        jobId: jobData._id,
        fromProposal: true,
        chatId
      })
    } else {
      // Else go to the chat
      history.push(routes.chat, {
        chatId,
        jobData,
        receptorId,
        receptorRole: data.getUser.role
      })
    }
  }

  return (
    <ButtonBase
      aria-label='chat-button'
      onClick={handleClick}
      sx={{
        transition: 'background-color 250ms ease-in-out',
        width: '100%',
        p: 2,
        '&:hover': { bgcolor: 'action.hover' }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%'
        }}
      >
        {hasAvatar && (
          <Avatar
            src={getUserPicture(userData.picture).profileImage}
            alt={userData.name}
            sx={{
              p: 0,
              width: 40,
              height: 40,
              mr: 2,
              textTransform: 'uppercase',
              bgcolor: 'action.disabled',
              backgroundImage: `url(${
                getUserPicture(userData.picture).bgImage
              })`,
              boxShadow: (theme) =>
                `0px 0px 0px 1px ${theme.palette.action.disabledBackground}`,
              backgroundSize: 'cover'
            }}
          />
        )}
        <Box
          sx={{
            textAlign: 'start',
            flexGrow: 1,
            maxWidth: (theme) =>
              hasAvatar ? `calc(100% - ${theme.spacing(7)})` : '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography className='wrap-anywhere' noWrap fontWeight={500}>
              {userData.name}
            </Typography>
            <Typography
              color='textSecondary'
              sx={{
                fontSize: '0.9em',
                marginLeft: 2,
                minWidth: 'fit-content',
                textAlign: 'end'
              }}
            >
              {date}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              className='wrap-anywhere'
              noWrap
              color='textSecondary'
              sx={{
                fontSize: 14,
                fontWeight: unread ? 600 : 'regular',
                maxWidth: '90%'
              }}
            >
              <Typography
                as='span'
                sx={{ fontSize: 14, color: 'text.primary' }}
              >{`${fromCurrentUser ? t('you') + ': ' : ''}`}</Typography>
              {content}
            </Typography>
            <Badge
              sx={{
                mr: 0.5,
                '& .MuiBadge-dot': {
                  height: 10,
                  width: 10,
                  borderRadius: '50%'
                }
              }}
              invisible={!unread}
              badgeContent=' '
              variant='dot'
              color='primary'
            />
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  )
}
