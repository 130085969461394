import { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { COMPANY_PLAN_STATUS, GET_CHAT, GET_OFFER_STATE } from 'apis/querys'
import { getSubscription } from 'apis/stripe'
import { ACCEPT_OFFER } from 'apis/mutations'

export default function useOffer() {
  const [loading, setLoading] = useState(false)
  const [getCompanyPlanStatus] = useLazyQuery(COMPANY_PLAN_STATUS)
  const [handleAcceptOffer] = useMutation(ACCEPT_OFFER)

  const acceptOffer = async ({ companyId, jobId, offerId }) => {
    setLoading(true)
    let result = { error: null }
    // Check if the company account is active
    let company = await getCompanyPlanStatus({
      variables: { userId: companyId }
    })
    company = company.data.companyPlanStatus

    let accountIsActive = company.trial
    if (company.plan_id) {
      const subscription = await getSubscription(company.plan_id)
      accountIsActive = subscription.status === 'active'
    }

    if (accountIsActive) {
      // The account is ACTIVE
      // Update offer "state" to "accepted" and "rejected" the others.
      // Update Job State to asigned & Add asigned_to id
      // Change trial of company to false if it is true
      handleAcceptOffer({
        variables: {
          userId: companyId,
          trial: false,
          jobId,
          offerId,
          state: 'assigned'
        },
        refetchQueries: [GET_OFFER_STATE, GET_CHAT]
      })
        .then(() => {
          setLoading(false)
        })
        .catch((e) => {
          result = { error: 'error-saving-data' }
        })
    } else {
      result = { error: 'deactivate-account' }
    }

    return result
  }

  return {
    acceptOffer,
    loading
  }
}
