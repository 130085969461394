import React from 'react'
import { Box, Slider, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'

/// ////////COMPONENT PROPS
// inputTitle: PropTypes.string,
// inputId: PropTypes.string,
// defaultValue: PropTypes.number,
// maxValue: PropTypes.number,
// minValue: PropTypes.number,
// value: PropTypes.array,
// steps: PropTypes.number,
// marks: PropTypes.array,
// onChange: PropTypes.func, //function(event: object, value: number | number[]) => void
// valueLabelFormat: PropTypes.func,
// name: PropTypes.string,

function valuetext(value) {
  return `${value}`
}

function RangeSlider({
  inputId,
  inputTitle,
  onChange,
  maxValue,
  minValue,
  steps,
  marks,
  valueLabelFormat,
  name,
  sx,
  ...other
}) {
  const lastMark = {}
  lastMark[`&[data-index='${marks.length - 1}']`] = { display: 'none' }

  const handleChange = (name) => (event, newValue) => {
    onChange({ [name]: newValue })
  }

  return (
    <Box aria-label='input-container'>
      <Typography gutterBottom color='textSecondary'>
        {inputTitle}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Slider
          id={inputId}
          name={inputId}
          onChange={handleChange(inputId)}
          valueLabelDisplay='on'
          aria-labelledby='range-slider'
          step={steps}
          min={minValue}
          max={maxValue}
          getAriaValueText={valuetext}
          marks={marks}
          valueLabelFormat={valueLabelFormat}
          sx={{
            ...sx,
            height: 8,
            '& .MuiSlider-track': { height: 6, borderRadius: 1 },
            '& .MuiSlider-rail': { height: 6, borderRadius: 1 },
            '& .MuiSlider-thumb': {
              boxShadow: (theme) =>
                `0px 2px 6px 0px ${alpha(theme.palette.primary.main, 0.7)}`,
              bgcolor: 'background.default',
              border: (theme) => `4px solid ${theme.palette.primary.main}`,
              '&.Mui-active': {
                boxShadow: (theme) =>
                  `0px 0px 0px 14px ${alpha(theme.palette.primary.main, 0.16)}`
              }
            },
            '& .MuiSlider-mark': {
              bgcolor: 'background.default',
              height: 8,
              width: 2,
              "&[data-index='0']": {
                display: 'none'
              },
              ...lastMark
            },
            '& .MuiSlider-valueLabel': {
              fontSize: 11,
              fontWeight: 'normal',
              top: -6,
              backgroundColor: 'unset',
              color: 'text.primary',
              '&:before': {
                display: 'none'
              },
              '& *': {
                background: 'transparent',
                color: 'text.primary'
              }
            }
          }}
          {...other}
        />
      </Box>
    </Box>
  )
}

export default React.memo(RangeSlider)
