import React from 'react'
import { Rating as MUIRating, Typography, Box } from '@mui/material'
import { Star } from 'assets/Icons'

export default function Rating({ name, amount, current, size, ...other }) {
  return (
    <Box sx={{ display: 'flex', ml: -0.5 }}>
      <MUIRating
        name={name || 'start-rating'}
        defaultValue={current}
        icon={<Star filled size={size} />}
        emptyIcon={<Star size={size} />}
        {...other}
      />
      {amount !== undefined && (
        <Typography
          aria-label='ratings-count'
          sx={{
            color: '#F7C056',
            ml: 1,
            fontWeight: '500'
          }}
        >
          {amount}
        </Typography>
      )}
    </Box>
  )
}
