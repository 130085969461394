import { CardContent, Typography, Box, Skeleton } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { CustomCard, OfferDates } from 'components'
import { useQuery } from '@apollo/client'
import { GET_OFFER } from 'apis/querys'
import { getDate, getRate } from 'helpers'
import { useI18N } from 'hooks'

export default function ChatOfferCard({ offerId }) {
  const { t } = useI18N()
  const { data } = useQuery(GET_OFFER, {
    variables: {
      id: offerId
    },
    skip: !offerId
  })

  const offer = data?.getOffer ?? null

  return (
    <CustomCard title={t('offer')}>
      <CardContent sx={{ maxWidth: '100%', pt: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            aria-label='price-container'
            sx={{
              bgcolor: (theme) => alpha(theme.palette.primary.light, 0.15),
              borderRadius: 100,
              p: '6px 18px',
              position: 'absolute',
              right: 8,
              bottom: 8,
              color: 'primary.main'
            }}
          >
            {offer ? (
              <Typography
                fontSize={17}
                fontWeight={600}
                sx={{ color: 'primary.main', lineHeight: 1 }}
              >
                {offer.amount}{' '}
                <span style={{ fontSize: 14 }}>
                  €{getRate(offer.rate_type)}
                </span>
              </Typography>
            ) : (
              <Skeleton width={30} height={17} />
            )}
          </Box>
        </Box>
        <OfferDates
          startDate={offer ? getDate(offer.start_date) : <Skeleton />}
          endDate={offer ? getDate(offer.end_date) : null}
        />
      </CardContent>
    </CustomCard>
  )
}
