import { useContext } from 'react'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SettingContext } from 'context/SettingContext'
import { Moon, Sun1 } from 'iconsax-react'

const DevThemeHandler = () => {
  const theme = useTheme()
  const { colorMode } = useContext(SettingContext)

  return process.env.NODE_ENV === 'development' ? (
    <IconButton
      sx={{
        position: 'fixed',
        left: 16,
        top: '70%',
        boxShadow: '0px 1px 8px rgba(0,0,0,0.2)',
        zIndex: 99,
        color: theme.palette.text.primary,
        bgcolor: 'background.default'
      }}
      onClick={colorMode.toggleColorMode}
    >
      {theme.palette.mode === 'dark' ? (
        <Sun1 size={24} variant='Outline' />
      ) : (
        <Moon size={24} variant='Outline' />
      )}
    </IconButton>
  ) : null
}

export default DevThemeHandler
