import { Alert } from '@mui/material'

export default function InfoMessage({
  receptorName,
  text,
  variant: severity = 'error',
  sx,
  ...other
}) {
  const getColor = (theme) => {
    const isDark = theme === 'dark'

    switch (severity) {
      case 'success':
        return isDark ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.7)'
      case 'warning':
        return isDark ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.6)'
      case 'error':
        return isDark ? 'rgba(0,0,0,0.87)' : '#fff'
      case 'info':
        return isDark ? 'rgba(0,0,0,0.87)' : '#fff'
      default:
        return 'rgba(0,0,0,0.87)'
    }
  }

  return (
    <Alert
      icon={false}
      variant='filled'
      severity={severity}
      sx={{
        borderRadius: 3,
        mt: 1,
        color: (theme) => getColor(theme.palette.mode),
        ...sx
      }}
      {...other}
    >
      {receptorName ? `${receptorName} ${text}` : text}
    </Alert>
  )
}
