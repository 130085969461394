import { format, getTime } from 'date-fns'
import spanishLocale from 'date-fns/locale/es'

export default function getDate(date, long, custom, fromString, timeStamp) {
  if (date) {
    const dateValue = fromString ? String(date) : parseInt(String(date))
    const formattedDate = new Date(dateValue)
    const shortDate = format(formattedDate, 'd MMM yyyy', {
      locale: spanishLocale,
    })
    const longDate = format(formattedDate, "d 'de' MMMM 'de' yyyy", {
      locale: spanishLocale,
    })
    const customDate =
      custom !== undefined &&
      format(formattedDate, String(custom), {
        locale: spanishLocale,
      })
    // let year = formattedDate.getFullYear();
    // let month = formattedDate.getMonth();
    // let day = formattedDate.getDate();
    // let hour = formattedDate.getHours();
    // let minutes = formattedDate.getHours();

    const timestamp = getTime(formattedDate)

    if (long) {
      return longDate
    } else if (custom) {
      return customDate
    } else if (timeStamp) {
      return timestamp
    } else {
      return shortDate
    }
  }
}
