import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import {
  BottomDrawer,
  CancelSubscriptionDialog,
  ChangePaymentMethodDialog,
  ChangePlanDialog,
  CurrentSubscriptionCard,
  InvoiceCard,
  Modal,
  NoSubscription,
  PaymentMethodCard,
  StripeElements,
  SubSelector
} from 'components'
import { getDate } from 'helpers'
import { getLastInvoice } from 'apis/stripe'
import { useI18N } from 'hooks'

export default function General({ subscription, plan }) {
  const { t } = useI18N()
  const [lastInvoice, setLastInvoice] = useState()
  const [cancelDialog, setCancelDialog] = useState(false)
  const [changeDialog, setChangeDialog] = useState(false)
  const [chosePlanDialogOpen, setChosePlanDialogOpen] = useState(false)
  const [paymentMethodDialog, setPaymentMethodDialog] = useState(false)

  // console.log({ subscription, plan })

  const { billing_details, card } = subscription.paymentMethodData ?? ''
  const { latest_invoice, status, items, current_period_end } =
    subscription.subscriptionData ?? ''
  const subsItemId = items.data[0].id
  const periodEnd = current_period_end * 1000
  const endDate = new Date(periodEnd)
  const savingValue = (
    ((subscription.monthlyPrice * 12 - subscription.yearlyPrice) * 100) /
    (subscription.monthlyPrice * 12)
  ).toFixed(0)
  const today = new Date()

  useEffect(() => {
    const getLastInvoiceData = async () => {
      const data = await getLastInvoice(latest_invoice)
      setLastInvoice(data)
    }
    getLastInvoiceData()
  }, [])

  const handleCancelPlan = () => {
    setCancelDialog(true)
  }

  const handleChangePlan = () => {
    setChangeDialog(true)
  }

  const handleChangePaymentMethod = () => {
    setPaymentMethodDialog(true)
  }

  const handleDownloadInvoice = () => {
    window.open(lastInvoice.invoice_pdf, '_blank, rel=noopener')
  }

  const handleSubscriptionSelector = () => {
    setChosePlanDialogOpen(!chosePlanDialogOpen)
  }

  return (
    <>
      {status === 'canceled' && endDate < today ? (
        <NoSubscription onClick={handleSubscriptionSelector} />
      ) : (
        <CurrentSubscriptionCard
          status={status}
          plan={plan}
          subscriptionData={subscription.subscriptionData}
          onCancelPlan={handleCancelPlan}
          onChangePlan={handleChangePlan}
        />
      )}
      <Box mt={2}>
        <PaymentMethodCard
          billingDetails={billing_details}
          cardData={card}
          onChange={handleChangePaymentMethod}
        />
      </Box>

      <Box mt={3}>
        <Typography fontSize={22} marginBottom={1}>
          {t('lastInvoice')}
        </Typography>
        {lastInvoice && (
          <InvoiceCard
            loading={!lastInvoice}
            date={getDate(lastInvoice?.created * 1000)}
            plan={t(subscription.subscriptionData.plan.metadata.nickname)}
            amount={lastInvoice?.amount_due / 100}
            onClick={handleDownloadInvoice}
            currency={lastInvoice?.currency}
          />
        )}
      </Box>

      <CancelSubscriptionDialog
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        interval={plan.interval}
        periodEnd={periodEnd}
        savingValue={savingValue}
        subscriptionId={subscription.subscriptionId}
        endDate={endDate}
      />

      <ChangePlanDialog
        open={changeDialog}
        onClose={() => setChangeDialog(false)}
        interval={plan.interval}
        savingValue={savingValue}
        subscriptionId={subscription.subscriptionId}
        subsItemId={subsItemId}
      />

      <StripeElements>
        <ChangePaymentMethodDialog
          open={paymentMethodDialog}
          onClose={() => setPaymentMethodDialog(false)}
          customer={subscription.customerData}
          paymentMethod={subscription.paymentMethodData}
        />
      </StripeElements>

      <Modal
        open={chosePlanDialogOpen}
        anchor='bottom'
        onClose={handleSubscriptionSelector}
      >
        <BottomDrawer
          title={
            <Typography align='center' fontSize='1.2rem'>
              {t('plans')}
            </Typography>
          }
          closeButton
          onClose={handleSubscriptionSelector}
        >
          <SubSelector renew customer={subscription.customerData} />
        </BottomDrawer>
      </Modal>
    </>
  )
}
