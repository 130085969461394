import { useLazyQuery } from '@apollo/client'
import { BASIC_COMPANY_INFO, GET_PARTICULAR } from 'apis/querys'
import { ROLE } from 'constants/Roles'

export default function useUserPublicInfo() {
  const [getParticularData] = useLazyQuery(GET_PARTICULAR)
  const [getCompanyData] = useLazyQuery(BASIC_COMPANY_INFO)

  const getBasicUserInfo = async (userId, userRole) => {
    if (userRole === ROLE.COMPANY) {
      const userData = await getCompanyData({ variables: { userId } })
      return userData.data.getUser
    } else {
      const userData = await getParticularData({ variables: { userId } })
      return {
        ...userData.data.getUser,
        name: userData.data.getUser.public_name
      }
    }
  }

  return { getBasicUserInfo }
}
