import { Box, Grid, MenuItem, Typography } from '@mui/material'
import {
  CategoriesSelector,
  Input,
  MaskedInput,
  SelectableInput
} from 'components'
import { countries } from 'constants/Countries'
import { legalForms } from 'constants/LegalForms'
import { getCityByPostalCode, provincesList } from 'constants/Provinces'
import { useI18N, useLazyEffect } from 'hooks'

export default function CompanyDataForm({ id, values, errors, onChange }) {
  const { t } = useI18N()

  useLazyEffect(() => {
    if (values.postal_code.length >= 4 && values.country) {
      getCityByPostalCode(values.postal_code, values.country).then((resp) => {
        const { city, province } = resp || ''
        if (city) {
          // console.log({ [`${id}.city`]: city })
          onChange({ [`${id}.city`]: city, [`${id}.province`]: province })
        }
      })
    } else {
      onChange({ [`${id}.city`]: '', [`${id}.province`]: '' })
    }
  }, [values.postal_code])

  return (
    <>
      <Box sx={{ pb: 2, maxWidth: 400, mx: 'auto' }}>
        <Typography
          fontSize='1.4rem'
          fontWeight={600}
          lineHeight='1.1em'
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('tellUsMoreAboutYourCompany')}
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <MaskedInput
            id={`${id}.phone`}
            onChange={onChange}
            label={t('phoneNumber')}
            value={values.phone}
            error={errors.phone}
            mask='+00 000 000 000'
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <SelectableInput
            id={`${id}.legal_form`}
            value={values.legal_form}
            error={errors.legal_form}
            onChange={onChange}
            label={t('legalForm')}
            items={legalForms}
            listContent='custom'
          >
            {legalForms.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </SelectableInput>
        </Grid>
        <Grid item xs={12}>
          <Input
            id={`${id}.nif`}
            value={values.nif}
            error={errors.nif}
            onChange={onChange}
            label={t('nif')}
            placeholder={
              values.country === countries.Swiss ? 'CHE999999999' : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MaskedInput
            id={`${id}.postal_code`}
            value={values.postal_code}
            error={errors.postal_code}
            onChange={onChange}
            label={t('postalCode')}
            mask='00000'
          />
        </Grid>
        <Grid item xs={12}>
          <SelectableInput
            id={`${id}.province`}
            value={values.province}
            error={errors.province}
            onChange={onChange}
            label={t('province')}
            items={
              values.province &&
              !provincesList(values.country).includes(values.province)
                ? provincesList(values.country).concat(values.province)
                : provincesList(values.country)
            }
            listContent='array'
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id={`${id}.city`}
            value={values.city}
            error={errors.city}
            onChange={onChange}
            label={t('city')}
          />
        </Grid>
        <Grid item xs={12}>
          <CategoriesSelector
            value={values.categories}
            error={errors.categories}
            onChange={(newValue) => {
              onChange({ [`${id}.categories`]: newValue.categories })
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
