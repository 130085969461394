import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  from
} from '@apollo/client'
import {
  getMainDefinition,
  offsetLimitPagination
} from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { devKeys, prodKeys } from './keys'
import { onError } from '@apollo/client/link/error'

export default function createApolloClient() {
  const GRAPHQL_ENDPOINT =
    process.env.NODE_ENV === 'production'
      ? prodKeys.graphBackendUri
      : devKeys.graphBackendUri

  const SUBSCRIPTION_ENDPOINT =
    process.env.NODE_ENV === 'production'
      ? prodKeys.graphSubscriptionUri
      : devKeys.graphSubscriptionUri

  const httpLink = new HttpLink({
    uri: GRAPHQL_ENDPOINT
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: SUBSCRIPTION_ENDPOINT
    })
  )

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )

    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  const getValue = (obj, key) => {
    const keys = key.split('.')
    let value = obj
    const lastKey = keys[keys.length - 1]
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]]
      if (!value) {
        break
      }
    }
    return { value, key: lastKey }
  }

  const loadMoreObject = (keyArgs = false, ...searchArgs) => {
    return {
      ...offsetLimitPagination(keyArgs),
      read(existing, { args }) {
        const { offset, limit } = args

        if (!existing) return

        if (searchArgs.length > 0) {
          const filter = existing.filter((value, index, self) => {
            const conditions = []

            for (let i = 0; i < searchArgs.length; i++) {
              const { value: existingValue, key } = getValue(
                value,
                searchArgs[i]
              )

              const searchValue = args[key] || ''

              // console.log({
              //   existingValue,
              //   key,
              //   searchValue
              // })

              conditions.push(
                existingValue?.toLowerCase().includes(searchValue.toLowerCase())
              )
            }

            return (
              index === self.findIndex((t) => t._id === value._id) &&
              conditions.some((value) => value === true)
            )
          })

          // console.log({ filter })
          return filter
        }

        return existing.slice(offset, offset + limit)
      }
    }
  }

  const cache = new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          getUsers: offsetLimitPagination(['categories', 'province']),
          getJobs: loadMoreObject(false, 'title'),
          companies: loadMoreObject(
            false,
            'name',
            'company_data.nif',
            'company_data.bio'
          )
        }
      }
    }
  })

  const client = new ApolloClient({
    link: from([errorLink, link]),
    cache
  })

  return client
}
