import texts from 'constants/texts'

export default function getRate(rate) {
  const rates = new Array(3)
  rates[0] = ''
  rates[1] = '/m²'
  rates[2] = '/h'
  rates[3] = `/${texts().day}`
  rates[4] = `/${texts().month}`

  const r = rate !== null ? rate : 0
  return rates[r]
}
