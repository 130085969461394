import { useState } from 'react'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { Button, Dialog, Modal } from 'components'
import { getDate } from 'helpers'
import { useMutation } from '@apollo/client'
import { UPDATE_PLAN } from 'apis/mutations'
import { useUser } from 'context/UserContext'
import { cancelSubscription } from 'apis/stripe'
import { useHistory } from 'react-router-dom'
import { useI18N } from 'hooks'

export default function CancelSubscriptionDialog({
  open,
  interval,
  periodEnd,
  endDate,
  onClose,
  savingValue,
  subscriptionId
}) {
  const { t } = useI18N()
  const history = useHistory()
  const { user } = useUser()
  const [deleteSubscription] = useMutation(UPDATE_PLAN)
  const [cancelling, setCancelling] = useState(false)

  const cancelPlan = () => {
    setCancelling(true)
    cancelSubscription(subscriptionId).then(() => {
      // Update Subscription in DB
      deleteSubscription({
        variables: {
          user_id: user.id,
          cancel_at: endDate
        }
      }).then(() => {
        history.go()
      })
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Dialog>
        <Dialog.Title
          title={t('cancelSubDialogTitle')}
          subtitle={t('weSorryYouCancel')}
        />

        <Dialog.Content>
          <Typography>{t('rememberThat')}</Typography>
          <List sx={{ listStyleType: 'disc', pl: 3 }}>
            <ListItem sx={{ display: 'list-item', pl: 1, pr: 0 }}>
              <ListItemText
                primary={
                  <Typography>
                    {t(`plan.${interval}.cancelDialogBody`)}{' '}
                    <Typography color='primary' fontWeight={600} as='span'>
                      {interval === 'month' && savingValue + '%'}
                    </Typography>
                    .
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 1, pr: 0 }}>
              <ListItemText
                primary={
                  <Typography>
                    {t('yourSubWillFinishAt')}{' '}
                    <Typography fontWeight={600} as='span'>
                      {getDate(periodEnd, true)}
                    </Typography>
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Dialog.Content>

        <Dialog.Actions>
          <Button
            fullWidth
            disabled={cancelling}
            onClick={onClose}
            text={t('cancel')}
            variant='light'
          />
          <Button
            fullWidth
            disabled={cancelling}
            onClick={cancelPlan}
            text={cancelling ? t('cancelling') : t('continue')}
          />
        </Dialog.Actions>
      </Dialog>
    </Modal>
  )
}
