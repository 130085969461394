import { useCallback, useState } from 'react'

export function checkKey(obj, level, ...rest) {
  if (obj === undefined) return false
  if (rest.length === 0 && Object.prototype.hasOwnProperty.call(level))
    return true
  return checkKey(obj[level], ...rest)
}

export default function useForm(initialValues) {
  const [values, setValues] = useState(initialValues || {})
  const [errors, setErrors] = useState({})

  const handleInputChange = useCallback(
    (newValue) => {
      Object.keys(newValue).forEach((key) => {
        const value = newValue[key]
        const fields = key.split('.')
        let parent, child

        if (fields.length > 1) {
          parent = fields[0]
          child = fields[1]
          setValues((prev) => {
            const newObject = { ...prev }
            newObject[parent][child] = value
            return newObject
          })

          // Validate on Change if submited with error
          // if (checkKey(errors, [parent], [child])) {
          //   setErrors((prev) => {
          //     const newObject = { ...prev }
          //     newObject[parent][child] = ''
          //     return newObject
          //   })
          //   // validate({
          //   //   [parent]: { [child]: value },
          //   // });
          // }
        } else {
          setValues((prev) => ({ ...prev, ...newValue }))
          // if (checkKey(errors, key)) {
          //   setErrors((prev) => ({ ...prev, [key]: '' }))
          //   // validate({ [key]: value });
          // }
        }
      })
    },
    [setValues, setErrors]
  )

  const resetForm = () => {
    setValues(initialValues)
    setErrors({})
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  }
}

/// *****Nested validation example
// const defaultValues = {
//   job:{
//     title: ""
//   }
// }
// const validate = (fieldValues = values) => {
// let temp = { ...errors };
// if (checkKey(fieldValues, "job", "title"))
// temp.job = fieldValues.job.title ? { title: "" } : { title: texts().required };
// ....
// }
