import { gql } from '@apollo/client'

const NEW_MESSAGES_SUB = gql`
  subscription NewMessage($jobId: String!, $chatId: String!, $userId: String!) {
    newMessage(job_id: $jobId, chat_id: $chatId, user_id: $userId) {
      _id
      offer {
        _id
        state
      }
      participants
      messages {
        from
        content {
          type
          name
          value
        }
        date
        state
        _id
      }
    }
  }
`

const NEW_CHAT_SUB = gql`
  subscription NewChat($userId: String!) {
    newChat(user_id: $userId) {
      job {
        _id
        title
        state
        category
      }
      chats {
        chat_id
        last_message {
          from
          content {
            type
            name
            value
          }
          date
          state
        }
        participants
        offer {
          _id
          state
        }
      }
    }
  }
`

const NOTIFICATIONS_SUB = gql`
  subscription Notifications($userId: String!) {
    notifications(user_id: $userId)
  }
`

export { NEW_MESSAGES_SUB, NEW_CHAT_SUB, NOTIFICATIONS_SUB }
