import { useQuery } from '@apollo/client'
import { Box, ButtonBase, Skeleton, Typography } from '@mui/material'
import { GET_CHAT } from 'apis/querys'
import { NEW_MESSAGES_SUB } from 'apis/subscriptions'
import {
  ChatOfferCard,
  ChatTopBar,
  MessagesFeed,
  ScrollBottom,
  Spacer
} from 'components'
import { routes } from 'constants/Routes'
import { UserContext } from 'context/UserContext'
import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

export default function ChatContainer({
  receptorInfo,
  role,
  accepted,
  chatId,
  post,
  offer,
  children
}) {
  const { user } = useContext(UserContext)
  const [messages, setMessages] = useState()
  const contentRef = useRef(null)
  const history = useHistory()

  const { loading, data, subscribeToMore } = useQuery(GET_CHAT, {
    variables: {
      jobId: post._id,
      chatId
    },
    skip: !chatId
  })

  useEffect(() => {
    if (!loading && data && data.getChat) {
      const msgs = [...data.getChat.messages]
      if (msgs[0].content.value === 'proposal') {
        user.role === 'user' && msgs.shift()
        user.role === 'company' && msgs.splice(1, 1)
      } else {
        msgs.shift()
      }
      setMessages(msgs)
    }
  }, [data, loading])

  useEffect(() => {
    if (contentRef) {
      contentRef.current.scrollTo({
        behavior: 'smooth',
        top: contentRef.current.scrollHeight
      })
    }
  }, [messages])

  const chat = data?.getChat

  return (
    <>
      <ChatTopBar
        title={
          !receptorInfo ? (
            <Skeleton width={120} />
          ) : (
            <ChatTitle
              userInfo={receptorInfo}
              isCompany={role === 'company'}
              offerAccepted={accepted}
              post={post}
            />
          )
        }
        onBackClick={() => history.replace(routes.inbox)}
        avatarSrc={!receptorInfo ? '' : receptorInfo.picture}
        avatarAlt={!receptorInfo ? '' : receptorInfo.name}
        containerRef={contentRef}
        // hasMenu
      />

      <Box
        ref={contentRef}
        id='content-container'
        className='scrollable'
        sx={{
          height: '100%',
          width: '100%',
          overflowX: 'hidden',
          position: 'absolute',
          pt: 9,
          pb: 7,
          '&::-webkit-scrollbar-track': {
            mt: 9,
            mb: 7
          }
        }}
      >
        <Spacer x={2}>
          {chat && (
            <>
              <Spacer my={2}>
                <ChatOfferCard offerId={offer?._id} />
              </Spacer>

              {!messages && <LoadingMessages />}

              {messages && (
                <MessagesFeed
                  receptorName={receptorInfo?.name}
                  offerState={offer?.state}
                  messages={messages}
                  subscribeToNewMessages={subscribeToMore({
                    document: NEW_MESSAGES_SUB,
                    variables: { jobId: post._id, chatId, userId: user.id },
                    updateQuery: (prev, { subscriptionData }) => {
                      if (!subscriptionData.data) return prev
                      const updatedChat = subscriptionData.data.newMessage
                      return Object.assign({}, prev, {
                        getChat: updatedChat
                      })
                    }
                  })}
                />
              )}
            </>
          )}
        </Spacer>
        {children}
      </Box>
      {contentRef?.current && <ScrollBottom target={contentRef.current} />}
    </>
  )
}

const ChatTitle = ({ userInfo, isCompany, offerAccepted, post }) => {
  const history = useHistory()

  const handleClick = () => {
    if (isCompany) {
      history.push(`${routes.company}?id=${userInfo._id}`, {
        companyId: userInfo._id,
        fromChat: offerAccepted
      })
    } else {
      history.push(`${routes.post}?id=${post._id}`, {
        fromChat: true
      })
    }
  }

  return (
    <ButtonBase
      sx={{
        flexGrow: 1,
        height: '56px',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        px: 1,
        minWidth: 0
      }}
      onClick={handleClick}
    >
      <Typography lineHeight={1.2}>{userInfo.public_name}</Typography>
      <Typography
        lineHeight={1.2}
        color='textSecondary'
        noWrap
        fontSize={14}
        maxWidth='100%'
      >
        {isCompany ? userInfo.name : post.title}
      </Typography>
    </ButtonBase>
  )
}

const LoadingMessages = () => {
  const Message1 = ({ height }) => (
    <>
      <Skeleton
        variant='rectangular'
        height={height}
        sx={{
          maxWidth: { xs: '80%', sm: 300 },
          borderRadius: '12px 12px 4px 12px',
          ml: 'auto'
        }}
      />
      <Skeleton width={40} sx={{ ml: 'auto', mb: 2 }} />
    </>
  )
  const Message2 = ({ height }) => (
    <>
      <Skeleton
        variant='rectangular'
        height={height}
        sx={{
          maxWidth: { xs: '80%', sm: 300 },
          borderRadius: '4px 12px 12px 12px',
          mr: 'auto'
        }}
      />
      <Skeleton width={40} sx={{ mr: 'auto', mb: 2 }} />
    </>
  )

  return (
    <>
      <Message1 height={100} />
      <Message2 height={140} />
      <Message2 height={80} />
      <Message1 height={120} />
    </>
  )
}
