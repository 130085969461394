import { Box, MenuItem, Typography } from '@mui/material'
import { SelectableInput } from 'components'

//  https://github.com/lipis/flag-icons/tree/main/flags/1x1
const flags = {
  ES: (
    <svg viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_15_31)'>
        <path d='M0 0H512V512H0V0Z' fill='#AA151B' />
        <path d='M0 128H512V384H0V128Z' fill='#F1BF00' />
      </g>
      <defs>
        <clipPath id='clip0_15_31'>
          <rect width='512' height='512' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
  CH: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='flag-icons-ch'
      viewBox='0 0 512 512'
    >
      <g fillRule='evenodd' strokeWidth='1pt'>
        <path fill='red' d='M0 0h512v512H0z' />
        <g fill='#fff'>
          <path d='M96 208h320v96H96z' />
          <path d='M208 96h96v320h-96z' />
        </g>
      </g>
    </svg>
  )
}

export default function CountrySelect({
  id,
  value,
  error,
  onChange,
  label,
  ...other
}) {
  return (
    <SelectableInput
      id={id}
      value={value}
      error={error}
      onChange={onChange}
      label={label}
      listContent='custom'
      {...other}
    >
      {[
        {
          label: 'España',
          value: 'ES',
          icon: flags.ES
        },
        {
          label: 'Switzerland',
          value: 'CH',
          icon: flags.CH
        }
      ].map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <Box
            width='100%'
            display='flex'
            sx={{ gap: 2, alignItems: 'center' }}
          >
            <Box
              height={20}
              width={20}
              sx={{ borderRadius: 1, overflow: 'hidden' }}
            >
              {item.icon}
            </Box>
            <Typography variant='inherit' noWrap>
              {item.label}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </SelectableInput>
  )
}
