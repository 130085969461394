import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Skeleton } from '@mui/material'
import { Rating } from 'components'
import { useQuery } from '@apollo/client'
import { GET_PARTICULAR } from 'apis/querys'
import { useI18N } from 'hooks'

/// ////////COMPONENT PROPS
UserComment.propTypes = {
  userId: PropTypes.string,
  date: PropTypes.string,
  lastMod: PropTypes.string,
  average: PropTypes.number,
  comment: PropTypes.string
}

export default function UserComment({
  userId,
  date,
  average,
  comment,
  lastMod
}) {
  const { t } = useI18N()
  const { loading, data } = useQuery(GET_PARTICULAR, {
    variables: { userId }
  })

  return (
    <Box aria-label='user-comment'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography fontSize={18}>
          {loading || !data || !data.getUser ? (
            <Skeleton width={100} />
          ) : (
            data.getUser.public_name
          )}
        </Typography>
        <Typography color='textSecondary'>{date}</Typography>
      </Box>
      <Rating current={average} size={16} readOnly />
      <Typography marginTop={1} color='textSecondary'>
        {comment}
      </Typography>
      {lastMod && (
        <Typography color='textSecondary' fontSize={12}>{`${t(
          'edited'
        )} ${lastMod}`}</Typography>
      )}
    </Box>
  )
}
