import es from './keywords/es.json'
import en from './keywords/en.json'
import it from './keywords/it.json'
import fr from './keywords/fr.json'
import de from './keywords/de.json'

const languages = { es, en, it, fr, de }

// Ignore uppercase and accents
const parseString = (string) =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

export default function getCategoryByContext(lang = 'es', context = '') {
  const foundObject = keyWords(lang).find((obj) => {
    if (!obj.words) {
      // console.log({ obj })
      return false
    }

    return obj.words.some((word) => {
      return parseString(context).includes(parseString(word))
    })
  })

  if (foundObject) return foundObject.category
  return 'otherJob'
}

const constructor = (category, words) => ({ category, words })

const keyWords = (lang) => [
  constructor('demolition', languages[lang].demolition),
  constructor('verticalsJobs', languages[lang].verticalsJobs),
  constructor('architecture', languages[lang].architecture),
  constructor('bathRenovations', languages[lang].bathRenovations),
  constructor('roofers', languages[lang].roofers),
  constructor('welding', languages[lang].welding),
  constructor('reforms', languages[lang].reforms),
  constructor('electrician', languages[lang].electrician),
  constructor('windows', languages[lang].windows),
  constructor('garage', languages[lang].garage),
  constructor('furnishPicker', languages[lang].furnishPicker),
  constructor('decoration', languages[lang].decoration),
  constructor('glass', languages[lang].glass),
  constructor('fireplace', languages[lang].fireplace),
  constructor('parquet', languages[lang].parquet),
  constructor('isolation', languages[lang].isolation),
  constructor('kitchen', languages[lang].kitchen),
  constructor('painting', languages[lang].painting),
  constructor('builder', languages[lang].builder),
  constructor('interiorDesign', languages[lang].interiorDesign),
  constructor('metal', languages[lang].metal),
  constructor('slabs', languages[lang].slabs),
  constructor('upholstery', languages[lang].upholstery),
  constructor('plumbing', languages[lang].plumbing),
  constructor('carpenter', languages[lang].carpenter),
  constructor('locksmith', languages[lang].locksmith),
  constructor('gardener', languages[lang].gardener),
  constructor('earthmoving', languages[lang].earthmoving),
  constructor('greenhouses', languages[lang].greenhouses),
  constructor('fences', languages[lang].fences),
  constructor('remodeling', languages[lang].remodeling),
  constructor('technician', languages[lang].technician),
  constructor('alarms', languages[lang].alarms),
  constructor('housekeeping', languages[lang].housekeeping),
  constructor('blinds', languages[lang].blinds),
  constructor('confort', languages[lang].confort),
  constructor('climate', languages[lang].climate),
  constructor('cleaning', languages[lang].cleaning),
  constructor('applianceTechnician', languages[lang].applianceTechnician),
  constructor('moving', languages[lang].moving),
  constructor('scaffolding', languages[lang].scaffolding),
  constructor('paving', languages[lang].paving),
  constructor('pestControl', languages[lang].pestControl),
  constructor('mechanics', languages[lang].mechanics),
  constructor('crane', languages[lang].crane),
  constructor('carWash', languages[lang].carWash),
  constructor('electricCars', languages[lang].electricCars),
  constructor('wheelbarrows', languages[lang].wheelbarrows),
  constructor('chargingPoints', languages[lang].chargingPoints),
  constructor('smallTransport', languages[lang].smallTransport),
  constructor('largeTransport', languages[lang].largeTransport),
  constructor('carPainting', languages[lang].carPainting),
  constructor('carService', languages[lang].carService),
  constructor('fineTunning', languages[lang].fineTunning),
  constructor('motorbike', languages[lang].motorbike),
  constructor('tuning', languages[lang].tuning),
  constructor('tattooArtist', languages[lang].tattooArtist),
  constructor('dentist', languages[lang].dentist),
  constructor('massageTherapist', languages[lang].massageTherapist),
  constructor('osteopathy', languages[lang].osteopathy),
  constructor('hairdresser', languages[lang].hairdresser),
  constructor('beautician', languages[lang].beautician),
  constructor('rehabilitation', languages[lang].rehabilitation),
  constructor('physiotherapist', languages[lang].physiotherapist),
  constructor('makeupArtist', languages[lang].makeupArtist),
  constructor('acupuncture', languages[lang].acupuncture),
  constructor('motivationalCoach', languages[lang].motivationalCoach),
  constructor('personalTrainer', languages[lang].personalTrainer),
  constructor('zumbaInstructor', languages[lang].zumbaInstructor),
  constructor('pilatesInstructor', languages[lang].pilatesInstructor),
  constructor('programmer', languages[lang].programmer),
  constructor('webProgrammer', languages[lang].webProgrammer),
  constructor('photographer', languages[lang].photographer),
  constructor('webDesigner', languages[lang].webDesigner),
  constructor('multimediaInstaller', languages[lang].multimediaInstaller),
  constructor('applicationProgrammer', languages[lang].applicationProgrammer),
  constructor('graphicDesigner', languages[lang].graphicDesigner),
  constructor('computerTechnician', languages[lang].computerTechnician),
  constructor(
    'marketingAndCommunication',
    languages[lang].marketingAndCommunication
  ),
  constructor('printing', languages[lang].printing),
  constructor('analyst', languages[lang].analyst),
  constructor('database', languages[lang].database),
  constructor('machineLearning', languages[lang].machineLearning),
  constructor('productCustomization', languages[lang].productCustomization),
  constructor('merchandising', languages[lang].merchandising),
  constructor('artificialIntelligence', languages[lang].artificialIntelligence),
  constructor('cyberSecurity', languages[lang].cyberSecurity),
  constructor('itSystemsTechnician', languages[lang].itSystemsTechnician),
  constructor('itSupportTechnician', languages[lang].itSupportTechnician),
  constructor('cook', languages[lang].cook),
  constructor('catering', languages[lang].catering),
  constructor('animator', languages[lang].animator),
  constructor('socioculturalAnimator', languages[lang].socioculturalAnimator),
  constructor(
    'functionalDiversityMonitor',
    languages[lang].functionalDiversityMonitor
  ),
  constructor('childrenMonitor', languages[lang].childrenMonitor),
  constructor('freeTimeMonitor', languages[lang].freeTimeMonitor),
  constructor('waiter', languages[lang].waiter),
  constructor('soundTechnician', languages[lang].soundTechnician),
  constructor('dj', languages[lang].dj),
  constructor('danceInstructor', languages[lang].danceInstructor),
  constructor('model', languages[lang].model),
  constructor('concierge', languages[lang].concierge),
  constructor('administrative', languages[lang].administrative),
  constructor('receptionist', languages[lang].receptionist),
  constructor('lifeGuard', languages[lang].lifeGuard),
  constructor('commercial', languages[lang].commercial),
  constructor('teacher', languages[lang].teacher),
  constructor('privateTeacher', languages[lang].privateTeacher),
  constructor('pedagogue', languages[lang].pedagogue),
  constructor('seamstress', languages[lang].seamstress),
  constructor('educationalAssistant', languages[lang].educationalAssistant),
  constructor('animalCaretaker', languages[lang].animalCaretaker),
  constructor('parcelDeliveryMan', languages[lang].parcelDeliveryMan),
  constructor('usedOilCollection', languages[lang].usedOilCollection),
  constructor('socialEducator', languages[lang].socialEducator)
]
