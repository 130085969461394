import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import { FileInput } from 'components'
import { Add } from 'iconsax-react'

/// ////////COMPONENT PROPS
PicturesInput.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default function PicturesInput({ accept, multiple, disabled, ...other }) {
  return (
    <FileInput accept=".png,.jpg,.jpeg" multiple disabled={disabled} maxFiles={5} maxSize={5} {...other}>
      <IconButton
        component="span"
        aria-label="add-picture"
        disabled={disabled}
        size="large"
        sx={{
          borderRadius: 2,
          border: (theme) => `1px solid ${theme.palette.action.disabled}`,
          width: '100%',
          minWidth: 100,
          maxWidth: 'calc(100%/3 - 16px)',
          height: 100,
          color: 'action.disabled',
          '&.Mui-disabled': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <Add />
      </IconButton>
    </FileInput>
  )
}
