const it = (...args) => ({
  // GENERAL
  processing: 'Trattamento...',
  sending: 'Invio...',
  loadMore: 'Carica di più',

  // SIGN IN
  signIn: 'Accedi',
  signInWelcome: 'Siamo felici di rivederti!',
  forgotPassword: 'Hai dimenticato la password?',
  signInEmailError: 'Controlla la tua email.',
  signInPasswordError: 'Controlla la tua password.',
  tooManyRequest:
    'Hai fatto troppi tentativi errati, per favore prova più tardi.',
  wrongCredentials: 'Credenziali errate.',
  signInText: 'Accedi',
  signUpText: 'Crea ora',
  registerQ: 'Non hai un account?',
  continueAsGuest: 'Continua come ospite',
  resetPasswordTitle: 'Recupera password',
  aEmailWillBeSend:
    'Inserisci l’indirizzo e-mail con cui ti sei registrato. Riceverai un’e - mail per recuperare la tua password.',
  theEmailHasBeenSent:
    'L’e-mail è stata inviata. Controlla la tua casella di posta.',
  close: 'Chiudi',
  resetPassword: 'Reimposta password',
  yourUser: 'Il tuo utente:',
  'resetError.ExpiredActionCode':
    'Il codice è scaduto, richiedi nuovamente la modifica della password.',
  'resetError.InternalError':
    'Si è verificato un errore. Torna all’app per reimpostare la password.',
  'resetError.Processing':
    'Si è verificato un errore durante l’elaborazione della richiesta.Prova un’altra volta.',
  'resetError.auth/expired-action-code':
    'Il codice è scaduto, richiedi nuovamente la modifica della password.',
  'resetError.auth/invalid-action-code':
    'Il codice non è valido o è già stato utilizzato, si prega di richiedere nuovamente la modifica della password.',
  'resetError.auth/user-disabled':
    'L’utente corrispondente al codice di reimpostazione della password specificato è stato disabilitato.',
  'resetError.auth/user-not-found':
    'Non vi è alcun utente corrispondente al codice di reimpostazione della password.',
  'resetError.auth/weak-password': 'La nuova password non è abbastanza forte.',
  passwordResetSuccesfully: 'Password modificata correttamente.',
  nowYouCanUseNewPass:
    'Ora puoi utilizzare la tua nuova password per accedere al tuo account Jobsaun.',

  // SIGN UP
  signUp: 'Registrazione',
  welcome: 'Vi diamo il benvenuto',
  asEAsyAsFindTheProfessional: (
    <>
      Come <span>facile</span> come trovare il professionista di cui hai bisogno
    </>
  ),
  allReady: 'Tutto pronto!',
  letsCreateYourAccount: 'Creiamo il tuo account',
  accountType: 'Che tipo di account vuoi creare?',
  completeTheData:
    'Compila il modulo sottostante con i dettagli del tuo account.',
  tellUsMoreAboutYourCompany: 'Dicci di più sulla tua azienda.',
  getMoreVisits:
    'Le aziende ricevono più contatti quando hanno un’immagine e una biografia.',

  user: 'Utente',
  particular: 'Cliente',
  company: 'Società',
  country: 'Paese',
  name: 'Nome e cognome',
  onlyVisibleForYou: 'Sarà visibile solo a te',
  publicName: 'Nome pubblico',
  visibleForEveryone: 'Sarà visibile a tutti',
  phoneNumber: 'Telefono di contatto',
  onlyVisibleOfferAccepted: 'Sarà visibile solo per le offerte che accetti.',
  email: 'Posta elettronica',
  pass: 'Password',
  repeatPass: 'Ripeti password',
  howDidYouHear: 'Come ci hai trovato?',

  accept1: 'Creando un account accetti il',
  accept2: 'e il',
  terms: 'Termini e condizioni d’uso',
  Datenschutzerklärung: 'Informativa sulla privacy.',
  termsAndPrivacy: (
    <>
      Creando un account accetti il{' '}
      <a
        href='https://jobsaun.com/legal/terms'
        target='_blank'
        rel='noreferrer'
      >
        {"Termini e condizioni d'uso"}
      </a>{' '}
      e il{' '}
      <a
        href='https://jobsaun.com/legal/privacy-and-cookies'
        target='_blank'
        rel='noreferrer'
      >
        Informativa sulla privacy.
      </a>
    </>
  ),
  signUpButton: 'Crea account',

  maincategory: 'Categoria principale',
  companyName: 'Nome dell’azienda',
  nif: 'NIF',
  province: 'Provincia',
  postalCode: 'Codice postale',
  contactName: 'Nome del contatto',
  signUpEmailError: 'L’e - mail non sembra essere corretta.',
  phoneError: 'Il telefono deve avere 9 cifre più il prefisso.',
  nifError: 'Campo sbagliato, per favore controllalo.',
  passwordError: 'La password deve contenere da 8 a 20 caratteri.',
  repeatPasswordError: 'Le password non corrispondono.',
  required: 'Questo campo è obbligatorio.',
  notValid: 'Valore non valido',
  youMustSelectACategory: 'Devi selezionare almeno una categoria',
  contractRequired:
    'È necessario accettare il contratto di elaborazione dell’ordine per continuare.',

  privacyRequired:
    'È necessario accettare la nostra Informativa sulla privacy per continuare.',

  legalFormHelper: 'Es: lavoratore autonomo',
  contract: 'Ho letto e accetto il Contratto di elaborazione dell’ordine.',
  pleaseReadTheContract:
    'Si prega di leggere il seguente contratto prima di continuare.',
  ifYouContinue:
    'Continuando accetti il contratto di elaborazione dell’ordine.',
  seeMyOffers: 'Vedi le mie offerte',
  maximumCharacters: 'Il numero massimo di caratteri consentiti è',
  'error.alreadyInUse': 'All’e-mail è già stato assegnato un account.',
  friend: 'Amico',
  digitalPaper: 'Giornale digitale',
  other: 'altri',
  someFieldIsINcomplete:
    'Alcuni campi sono incompleti, torna indietro e compilali tutti.',

  // HOME
  exploreCategories: 'Esplora le categorie',
  particulars: 'Clienti',
  slider1: 'Trova i clienti in modo rapido e semplice.',
  slider2: 'Ora il professionista di cui hai bisogno ti trova.',
  share: 'Condividi',
  search: 'Ricerca...',
  categories: 'Categorie',
  whatServiceDoYouNeed: 'Di quale servizio hai bisogno?',
  homeCTA: (
    <>
      Ora <span>il professionista di cui hai bisogno</span> ti trova
    </>
  ),
  bestRatedCompanies: 'Le aziende con il punteggio migliore',
  doYouHaveACompany: 'Hai un’azienda?',
  joinJobsaun: 'Unisciti a Jobsaun per rendere la tua azienda più visibile.',
  joinNowForFree: 'Iscriviti gratuitamente ora',
  weAreAlready: 'Lo siamo già',
  andGoingUp: 'imprese e in aumento',

  //  DISCOVER
  reset: 'Ripristina',

  // INBOX
  inboxGuestMessage:
    'Dalla casella di posta elettronica è possibile entrare in contatto e finalizzare i dettagli con il cliente o l’azienda.',

  // OFFERS-POSTS
  boardGuestMessage: 'Dalla bacheca puoi gestire le tue offerte o lavori.',
  pluralPosted: 'Pubblicato',
  pluralDraft: 'Bozze',
  pluralAssigned: 'Assegnato',
  pluralAccepted: 'Accettato',
  archived: 'Archiviato',

  // ACCOUNT PAGE
  registerMessage:
    'Sfrutta tutte le funzionalità dell’applicazione con un account. Crearlo è molto facile e GRATUITO',
  alreadyHasAccount: 'Hai già un account?',
  knowMoreAboutUs: 'Vuoi saperne di più su di noi?',
  moreInfo: 'Ulteriori informazioni',
  installPWA: 'Migliorare l’esperienza',
  install: 'Installa',
  welcomeToJobsaun: 'Benvenuti a Jobsaun',
  slogan: 'Lascia che ciò che stai cercando ti trovi.',
  joinNow: 'Unitevi ora',
  next: 'Avanti',
  preview: 'Precedente',
  accountSlider_1:
    'Trova facilmente professionisti verificati per eseguire il servizio di cui hai bisogno',
  accountSlider_2:
    'Aumenta la visibilità della tua azienda essendo più vicino ai clienti',
  accountSlider_3:
    'Recensioni degli utenti come te in modo da poter valutare le migliori alternative',
  accountSlider_4:
    'Ricevi preventivi personalizzati che avvantaggiano sia l’azienda che te',

  // CATEGORY PAGE
  jobs: 'Posti di lavoro',
  companies: 'Società',
  searchInCategory: 'Cerco lavoro in questa categoria...',
  searchInCompanies: 'Cerca azienda in questa categoria...',
  jobFilterTitle: 'Filtro lavoro',
  proximityFilter: 'Prossimità (km)',
  budgetFilter: 'Bilancio',
  subcategories: 'Sottocategorie',
  selectAll: 'Seleziona tutto',
  unSelectAll: 'Deseleziona tutto',

  // CATEGORIES
  construction: 'Edilizia e abitazione',
  mechanic: 'Meccanica e trasporti',
  care: 'Cura ed estetica',
  leisure: 'Tempo libero e gastronomia',
  technology: 'Tecnologia e marketing',
  others: 'Altri servizi',

  // Subcategories
  // ---Construction
  demolition: 'Demolizione',
  verticalsJobs: 'Opere verticali (facciate)',
  architecture: 'Architettura, pianificazione',
  bathRenovations: 'Bagni',
  roofers: 'Coperture, coperture',
  welding: 'Saldatura',
  reforms: 'Riforme',
  electrician: 'Elettricisti, impianti elettrici',
  windows: 'Finestre e porte',
  garage: 'Porte da garage',
  furnishPicker: 'Assemblatore di mobili',
  glass: 'Vetro e vetri',
  fireplace: 'Camini',
  parquet: 'Parquet, laminato, moquette',
  isolation: 'isolamento',
  kitchen: 'Cucine',
  decoration: 'Decorazione',
  painting: 'Pittura',
  builder: 'Muratore, gesso, cemento',
  interiorDesign: 'Progettazione di interni',
  metal: 'Costruzioni metalliche, fabbro, forgiatura',
  slabs: 'Bramme, piastrelle',
  upholstery: 'Tappezzeria, selleria',
  plumbing: 'Idraulico',
  carpenter: 'Falegname',
  locksmith: 'Fabbro',
  gardener: 'Giardiniere, orticoltura',
  earthmoving: 'Costruzione di pozzi, sterri, fondazioni',
  greenhouses: 'Serre',
  fences: 'Recinzioni, cancelli, schermi per la privacy',
  remodeling: 'Rimodellamento, ristrutturazione totale, ampliamento',
  technician: 'Tecnico, installatore di sistema',
  alarms: 'Allarmi',
  housekeeping: 'Servizio di pulizia, supervisore',
  blinds: 'Persiane, persiane, tende da sole',
  confort:
    'Riscaldamento, ventilazione, condizionamento dell’aria, refrigerazione',
  climate: 'Aria condizionata',
  cleaning: 'Pulizia',
  applianceTechnician: 'Tecnico degli apparecchi',
  moving: 'Rimozioni',
  scaffolding: 'Ponteggere',
  paving: 'Lavori di pavimentazione, strade, strade',
  pestControl: 'Disinfestazione',
  constructionMaterials: 'Materiali da costruzione',

  // ---Mechanic and transport
  mechanics: 'Meccanica, elettronica',
  crane: 'Gru e trasferimento di veicoli',
  carWash: 'Autolavaggio',
  electricCars: 'Veicoli elettrici',
  wheelbarrows: 'carriole',
  chargingPoints: 'Installazione di punti di ricarica',
  smallTransport: 'Trasporti, piccoli trasporti',
  largeTransport: 'Trasporti, grandi trasporti',
  carPainting: 'Lamiere e vernici',
  carService: 'Servizio auto (Pre-ITV)',
  fineTunning: 'Allestimento',
  motorbike: 'Motocicletta, Quad',
  tuning: 'Tuning',

  // ---Care and aesthetics
  tattooArtist: 'Tatuatore',
  dentist: 'Dentista',
  massageTherapist: 'Massaggiatrice',
  osteopathy: 'Osteopatia',
  hairdresser: 'Parrucchiere',
  beautician: 'Estetista',
  rehabilitation: 'Riabilitazione',
  physiotherapist: 'fisioterapista',
  makeupArtist: 'Truccatore',
  acupuncture: 'Agopuntura',
  motivationalCoach: 'Coach motivazionale',
  personalTrainer: 'Personal trainer',
  zumbaInstructor: 'Zumba Monitor',
  pilatesInstructor: 'Pilates Monitor',

  // ---Technology and marketing
  programmer: 'Programmatore',
  webProgrammer: 'Programmatore web',
  photographer: 'Fotografo',
  webDesigner: 'Web designer',
  multimediaInstaller: 'Installatore multimediale',
  applicationProgrammer: 'Programmatore di applicazioni',
  graphicDesigner: 'Grafico',
  computerTechnician: 'Tecnico informatico',
  marketingAndCommunication: 'Marketing e comunicazione',
  printing: 'Stampa',
  analyst: 'Analista',
  database: 'Banca dati',
  machineLearning: 'apprendimento automatico',
  productCustomization: 'Personalizzazione del prodotto',
  merchandising: 'Merchandising',
  artificialIntelligence: 'intelligenza artificiale',
  cyberSecurity: 'Sicurezza informatica',
  itSystemsTechnician: 'Tecnico dei sistemi informatici',
  itSupportTechnician: 'Tecnico di supporto informatico',

  // --Leisure and gastronomy
  cook: 'Cuoco',
  catering: 'Ristorazione',
  animator: 'Intrattenitore',
  socioculturalAnimator: 'Animatore socioculturale',
  functionalDiversityMonitor:
    'Monitor per il tempo libero per le persone con diversità funzionale',
  childrenMonitor: 'Monitor per il tempo libero per bambini',
  freeTimeMonitor: 'Monitor del tempo libero e del tempo libero',
  waiter: 'Cameriere',
  soundTechnician: 'Tecnico del suono',
  dj: 'DJ',
  danceInstructor: 'Istruttore di danza',

  // ---Others
  model: 'Modello',
  concierge: 'Portineria',
  administrative: 'Amministrativo',
  receptionist: 'Receptionist',
  lifeGuard: 'salvavita',
  commercial: 'Commerciale',
  teacher: 'Insegnante',
  privateTeacher: 'Insegnante privato',
  pedagogue: 'Pedagogo',
  seamstress: 'Sarta',
  educationalAssistant: 'Assistente didattico',
  animalCaretaker: 'Custode degli animali',
  parcelDeliveryMan: 'Messaggero',
  usedOilCollection: 'Raccolta dell’olio usato',
  socialEducator: 'Educatore sociale',
  otherJob: 'Un altro lavoro',

  // --------
  filter: 'filtro',
  filters: 'Filtri',
  orderBy: 'Ordina per',
  betterRating: 'Miglior valutazione',
  moreAcceptedOffers: 'Altre offerte accettate',
  youMustSelectAtLeastOneSubcategory:
    'È necessario selezionare almeno una sottocategoria.',

  // COMPANY PROFILE
  sendJobOffer: 'Offri lavoro',
  message: 'Messaggio',
  companyInfo: 'Biografia',
  seeMore: 'Vedi di più',
  seeLess: 'Vedere meno',
  customerReviews: 'Recensioni dei clienti',
  noDetailsYet: 'La società non ha ancora fornito dettagli.',
  createFreeAccount: 'Crea un account gratuito',

  // LEGAL FORMS
  freelance: 'Lavoratore autonomo',
  civilSociety: 'società civile',
  collectiveSociety: 'partenariato',
  limitedLiabilityCompany: 'società a responsabilità limitata',
  limitedSociety: 'Sociedad Limitada, S.L.',
  anonymousSociety: 'Sociedad Anónima, S.A.',
  cooperativeSociety: 'Società cooperativa',

  // REVIEWS
  valorations: 'Valutazioni',
  comunication: 'Comunicazione',
  times: 'Consegna puntuale',
  asDescribed: 'Lavoro finale',
  recommend: 'Lo consiglieresti?',
  leaveAComment: 'Lascia un commento',

  // PAGES
  home: 'Inizio',
  discover: 'Scopri',
  inbox: 'Cassetta postale',
  messages: 'Messaggi',
  offers: 'Offerte',
  board: 'Plank',
  posts: 'Pubblicazioni',
  profile: 'Profilo',
  category: 'Categoria',
  newOffer: 'Nuova offerta',

  // JOB OFFERS
  posted: 'Pubblicato',
  startDate: 'Data di inizio',
  preferredStartDate: 'Data desiderata',
  endDate: 'Data di fine/consegna',
  mainDetails: 'Dettagli',
  otherDeatils: 'Altre informazioni',
  receivedOffers: 'Offerte ricevute',
  createAnOffer: 'Presentare un’offerta',
  noOfferYet: 'Questo lavoro non ha ancora ricevuto offerte, sii il primo!',
  offerAlert: 'Per creare un’offerta è necessario accedere come azienda.',
  rateType: 'Tipo di tariffa',
  flatRate: 'Tariffa forfettaria',
  day: 'giorno',
  month: 'mese',
  year: 'anno',
  amount: 'Quantità',
  addEndDate: 'Aggiungi data di fine',
  attachFile: 'Allega file',
  sendOffer: 'Invia offerta',
  offerMessageHelper: 'Verrà visualizzato come primo messaggio della chat.',
  fileError: 'Errore file: solo .pdf sono accettate e il tuo è',
  maxFileSize: 'La dimensione massima del file è 10MB',
  budget: 'Bilancio',
  editPost: 'Modifica pubblicazione',
  nothingPostedYet: 'Non hai ancora pubblicato nulla',
  tryFromTheSearchbar: 'Prova dalla barra di ricerca in alto',
  nothingInDrafts: 'Non c’è nulla nelle bozze',
  nothingInAssigned: 'Non c’è nulla nell’assegnato',
  nothingInArchived: 'Non c’è nulla nell’archiviazione',
  nothingInRejected: 'Non hai offerte rifiutate',
  nothingInAccepted: 'Non hai offerte accettate',
  nothingInNegotiating: 'Inviare offerte per avviare la trattativa',
  nothingFound: 'Nessun risultato di ricerca',
  StartSendingOffersToPotencialCustomers:
    'Inizia inviando offerte ai potenziali post dei clienti',
  'errors.no_active_subscription_found':
    'È necessario sottoscrivere un piano per poter inviare offerte.',

  // Questions
  'question.flexibleHours': 'Hai flessibilità con gli orari?',
  'question.availableToNegociate': 'Sareste in grado di negoziare il bilancio?',
  'question.payInAdvance': 'Pagheresti in anticipo se necessario?',
  'question.accesible': 'Il posto è facilmente accessibile?',
  'question.availabilityForTravel': 'Hai disponibilità a spostare il veicolo?',
  'question.parking': 'C’è un parcheggio nella zona?',
  'question.weekends': 'È consentito lavorare nel fine settimana?',
  'question.material': 'Il materiale è fornito?',
  'question.neededParts': 'Hai le parti di cui hai bisogno?',
  'question.crane': 'Hai bisogno di una gru?',
  'question.bigTrees': 'Ha alberi ad alto fusto?',
  'question.water': 'Avete fontane o altre aree con acqua?',
  'question.workAtHome': 'Si può lavorare a casa?',
  'question.goToTheHome': 'Hai bisogno che tornino a casa?',

  'question.squareMeters': 'Quanti m² ha il sito?',

  // Answers
  'answer.flexibleHours': 'Flessibilità negli orari',
  'answer.availableToNegociate': 'bilancio negoziabile',
  'answer.payInAdvance': 'Disponibilità da pagare in anticipo',
  'answer.accesible': 'Luogo di facile accesso',
  'answer.availabilityForTravel': 'Disponibilità del viaggio',
  'answer.parking': 'Parcheggio nella zona',
  'answer.weekends': 'Il lavoro è consentito nei fine settimana',
  'answer.material': 'Il cliente fornisce il materiale',
  'answer.neededParts': 'disporre delle parti necessarie',
  'answer.crane': 'Gru necessaria',
  'answer.bigTrees': 'Alberi ad alto fusto',
  'answer.water': 'Fontane o altre zone con acqua',
  'answer.workAtHome': 'Possibilità di lavorare a casa',
  'answer.goToTheHome': 'È necessario andare a casa',

  'answer.squareMeters': 'metri quadrati del sito',

  // OTHERS
  shareTheApp: 'Condividi l’app',
  shareTheAppMessage:
    'Condividi l’app Jobsaun con i tuoi amici, mostra loro quanto sei bravo trovando app nuove e utili.',
  goToChat: 'Vai alla chat',
  seeMoreJobs: 'Vedi altre opere',
  sendingYourOffer: 'Invio dell’offerta',
  itCanTakeAMoment:
    'Questo processo potrebbe richiedere del tempo. Vi prego di essere pazienti.',
  offerSuccessSent: 'Offerta inviata con successo',
  continueWith: 'Cosa vuoi fare dopo?',
  users: 'utenti',
  yes: 'Sì',
  no: 'No',
  save: 'Salva',
  saveDraft: 'Salva bozza',
  toPost: 'Pubblica',
  saving: 'Risparmiare...',
  posting: 'Editoria...',
  postingJob: 'Il tuo lavoro è in corso di pubblicazione',
  pleaseBePatient: 'Si prega di attendere la fine dell’azione.',
  nothingHere: 'Niente qui intorno...',
  tryAnother: 'Prova un’altra ricerca o categoria.',
  settings: 'Impostazioni',
  account: 'conto',
  sent: 'Inviato',
  youHaveToSignIn:
    'Devi aver effettuato l’accesso per accedere a questa funzione.',
  loading: 'Caricamento in corso...',
  all: 'Tutti',

  // POSTED JOBS
  postedJobs: 'Opere pubblicate',
  send: 'Invia',
  cancel: 'Annulla',
  acceptedOffer: 'Offerta accettata',
  cancelledOffer: 'Offerta annullata',
  acceptOffer: 'Accetta l’offerta',
  draft: 'Bozza',
  negotiating: 'negoziazione',
  rejected: 'Respinto',
  accepted: 'Accettato',
  cancelled: 'Annullato',
  proposed: 'proposta',
  assigned: 'Assegnato',
  noDate: 'Senza data',
  anyJobWasFound:
    'Non è stato trovato alcun lavoro pubblicato corrispondente alle categorie della società.',
  theProposalHasBeenSent: 'La proposta è stata inviata con successo',
  youAlreadySentThisJob: 'Hai già inviato questo lavoro all’azienda.',
  theCompanyAlreadySentYouAnOffer:
    'L’azienda ti ha già inviato un’offerta per questo lavoro.',
  theCompanyHasCancelTheOffers:
    'La società ha annullato le offerte per questo lavoro.',
  // MENUS
  moveToDraft: 'Passa alle bozze',
  edit: 'Modifica',
  delete: 'Cancellare',
  newFirst: 'Prima il nuovo',
  oldFirst: 'Prima gli antichi',
  edited: 'Modificato il',

  // NEW JOB
  newJob: 'Nuovo lavoro',
  title: 'Titolo',
  subCategory: 'Sottocategoria',
  explainTheNeededService:
    'Spiega nel modo più dettagliato possibile il servizio di cui hai bisogno, in modo che i professionisti ti facciano l’offerta più equa.',
  postDescription: 'Descrizione del servizio',
  city: 'Città',
  helperCity:
    'Qualcosa è andato storto per ottenere la città, si prega di controllare il codice postale, o scriverlo a mano.',
  uploadPictures: 'Immagini',
  maximumSizeExceded: 'La dimensione massima del file è stata superata',
  maximumNumberOfFiles: 'Il numero massimo di fascicoli è stato superato',
  notAllowFile: 'Il file non è consentito.',
  allowFiles: 'File consentiti',
  otherDetails: 'Altre informazioni',
  congrats: 'Congratulazioni!',
  postedSucceed: 'L’opera è stata pubblicata con successo.',
  justWait:
    'Ora devi solo aspettare di ricevere offerte dalle aziende interessate.',
  seeTheJob: 'Vedi la posizione',
  fiveDigits: 'Ci devono essere 5 cifre.',
  alreadyUploadThisFile: 'Hai già caricato questo file',
  optional: 'facoltativo',
  willLoseTheProcessOnCancel: 'Annullando perderai tutti i progressi.',
  noCategoryFound:
    'Siamo spiacenti, non abbiamo trovato una categoria con la ricerca eseguita. Selezionare la categoria più adatta tra le opzioni seguenti.',
  weHaveSelectedTheNextCategory:
    'Sulla base della vostra ricerca abbiamo selezionato la seguente categoria, se pensate che non sia corretta, vi preghiamo di selezionare quella più adatta.',
  localitation: 'Ubicazione',
  searchCompanies: 'Ricerca di aziende',
  lookingCompanies: 'Alla ricerca di imprese',
  thisCanTakeAWhile:
    'Potrebbe volerci un po’ di tempo, per favore sii paziente.',
  weHaveFound: 'Abbiamo trovato...',
  whatDoYouWantToDoNext: 'Cosa vuoi fare dopo?',
  seeCompanies: 'Vedi aziende',
  continueWithTheApplication: 'Continuare con la domanda',
  weAreSorryWeStillGrowing:
    'Siamo spiacenti, continuiamo a crescere e sempre più aziende si uniscono a Jobsaun. Continuare la richiesta alle aziende di contattarti.',
  whatBudgetDoYouHave: 'Che budget avete?',
  budgetSubtitle:
    "Questa è solo un'approssimazione per far sapere alle aziende l'importo massimo che puoi permetterti.",
  recommended: 'Consigliato',
  lessThan: 'Fino a',
  moreThan: 'Maggiore di',
  whenDoYouNeedIt: 'Quando ne hai bisogno?',
  tellUsYourPreferences: 'Comunicaci le tue preferenze',
  doYouWantToProvideAnImage:
    'Vuoi fornire un’immagine che aiuti il professionista?',
  dragAndDropOrBrowse: 'Trascina e rilascia o',
  browse: 'clicca qui',
  allow: 'Consentito',
  maxFilesSize: 'dimensione massima',
  of: 'di',
  image: 'Immagine',
  'imageErrors.file-too-large': `Il file ${args[0]} è troppo grande. La dimensione massima è ${args[1]} e il file ha una dimensione di ${args[2]}`,
  'imageErrors.file-invalid-type': `Il tipo di file deve essere uno dei seguenti: ${args[0]}`,
  'imageErrors.too-many-files': `Troppi file selezionati, il massimo è ${args[0]} file.`,
  'imageErrors.bad-file': `Il file ${args[0]} non può essere allegato. Per favore, provane un altro.`,
  postDetails: 'Dettagli della domanda',
  'post.sending': 'La tua candidatura è in fase di presentazione',
  'post.pleaseBePatient':
    'Potrebbe volerci un attimo. Vi prego di essere pazienti',
  'post.added': 'Pubblicazione creata con successo.',
  'error.post.adding':
    'Impossibile creare la pubblicazione. Per favore, prova un’altra volta.',
  'error.post.updating': 'Impossibile aggiornare il post. Riprova più tardi.',
  'error.post.deleting':
    'Il post non poteva essere cancellato. Per favore, prova un’altra volta.',
  // NEW OFFER
  sendOfferTo: 'Invierai un’offerta per l’annuncio di lavoro:',
  sendingOffer: 'Invio dell’offerta...',
  theCompanyIsInterested: `L’impresa ${args[0]} è interessato al vostro annuncio. Accettate l’offerta, se siete interessati, per iniziare la conversazione. Nota: tutte le altre offerte saranno automaticamente rifiutate`,
  offerAlreadySent:
    'È consentita una sola offerta per annuncio. Per inviare un’altra offerta è necessario annullare la precedente.',
  youNeedAPlan:
    'Oops! Sembra che la tua prova gratuita sia terminata. Si prega di sottoscrivere un piano in modo da poter inviare un’offerta.',
  yourOfferHasAlreadyBeenAccepted:
    'La tua offerta è già stata accettata. Vai alla chat per maggiori dettagli.',
  yourOfferHasAlreadyBeenRejected:
    'La tua offerta è stata automaticamente rifiutata perché il cliente ne ha accettata un’altra. Vi auguriamo buona fortuna con il prossimo.',
  doYouWantToCancelNow: 'Vuoi cancellarlo ora?',
  offercancelledSuccesfully: 'Offerta annullata con successo',
  finalize: 'Finisci',
  toCompleteThePost: 'Per completare la domanda è necessario un account.',
  createAccountAndFinalize: 'Crea account e finisci',

  // PROFILE
  details: 'Dettagli dell’account',
  companyDetails: 'Dati societari',
  ratings: 'Valutazioni',
  privacy: 'Privacy e cookie',
  legal: 'Legale',
  info: 'Informazioni',
  updates: 'Registro di aggiornamento',
  current: 'Corrente',

  subscription: 'Abbonamento',
  accountState: 'Stato dell’account',
  active: 'Attivo',
  deactivate: 'Spento',
  trial: 'Test',
  activeMessage: 'Tutto sta andando bene, non è necessaria alcuna azione.',
  trialMessage: 'Goditi la prova gratuita.',
  trialDescription:
    'Sarà automaticamente conforme quando una delle tue offerte è stata accettata.',

  guest: 'Ospite',
  contact: 'Contatto',
  // SUBSCRIPTION ERRORS
  deactivateDescription:
    'Finché il tuo account rimarrà disattivato, non potrai inviare offerte o comunicare con nuovi clienti.',
  deactivateDefaultMessage:
    'Stiamo riscontrando qualche tipo di problema con il tuo account. Vi preghiamo di contattarci.',

  'error.payment': 'Abbiamo difficoltà a essere pagati.',
  'error.subscription': 'Non hai ancora sottoscritto alcun piano.',
  'error.trialOff':
    'La tua prova gratuita è terminata. Si prega di accedere a un abbonamento per continuare a godere di tutte le funzionalità.',
  'error.404': 'L’abbonamento non esiste.',
  'error.incomplete': 'Il tentativo iniziale di raccolta non è riuscito.',
  'error.past_due':
    'Il pagamento non ha potuto essere effettuato entro la data di scadenza.',
  'error.unpaid': 'Impossibile effettuare il pagamento.',
  'error.cancelled': 'Il tuo abbonamento è stato annullato',
  'error.incomplete_expired':
    'Il tentativo di sottoscrizione è scaduto per errore',

  // DETAILS
  changePassword: 'Cambia password',
  editData: 'Modifica i tuoi dati',
  editBasicData: 'Modifica dati di base',
  editCompanyData: 'Modifica dati aziendali',
  deleteAccount: 'Elimina account',
  signOut: 'Esci',
  confirmationEmailWillBeSent: 'Riceverai un’e-mail di conferma.',
  legalForm: 'Costituzione',
  bio: 'Informazioni sull’azienda',
  bioPlaceHolder:
    'Descrivi la tua azienda nel miglior modo possibile, questa introduzione può convincere molti clienti.',
  changeYourAvatar: 'Cambia avatar',
  background: 'Antefatti',
  hair: 'Capelli',
  skin: 'Pelle',
  features: 'Tratti',
  profileUpdatedSuccessfully: 'Profilo aggiornato con successo.',
  accountDeleted: 'Account eliminato con successo.',

  // SUBSCRIPTION PAGE
  postulate:
    'Candidati da centinaia di offerte di lavoro e invia preventivi ai potenziali clienti.',
  selectThePlan: 'Seleziona il piano più adatto a te.',
  annual: 'Annuale',
  monthly: 'mensile',
  promo: 'Promo',
  uniquePayment: 'Pagamento una tantum',
  annualDescription: 'Addebitato annualmente come pagamento ricorrente.',
  monthlyDescription:
    'Addebito mensile come pagamento ricorrente. Senza permanenza.',
  uniquePaymentDescription: 'Addebitato in un unico pagamento.',
  billedAnnualy: 'addebitato annualmente.',
  billedmonthly: 'addebitato mensilmente.',
  saveA: 'Salva un',
  withAnnualPayment: 'con il pagamento annuale.',
  continue: 'Continua',
  overview: 'Generale',
  invoices: 'fatture',
  paymentDetails: 'Dati di pagamento',
  yourSubscriptionPlan: 'Il tuo abbonamento',
  paymentMethod: 'Metodo di pagamento',
  lastInvoice: 'Ultima fattura',
  cancelPlan: 'Annulla iscrizione',
  changePlan: 'Cambia piano',
  cardOf: 'Carta di',
  changePaymentMethod: 'Modifica metodo di pagamento',
  introduceAValidPaymentMethod:
    'Inserisci un metodo di pagamento valido di seguito.',
  credit: 'credito',
  debit: 'debito',
  cancelSubDialogTitle: 'Annulla iscrizione',
  weSorryYouCancel: 'Siamo spiacenti che tu voglia annullare l’abbonamento.',
  rememberThat: 'Ricordati che:',
  yourSubWillFinishAt:
    'Anche se si disdice ora, le prestazioni dell’abbonato continueranno a funzionare fino al giorno:',
  comeBack: 'Indietro',
  cancelling: 'Annullamento...',
  changing: 'Cambiando...',
  yourPlanWillBecancelledAt:
    'Il tuo abbonamento verrà automaticamente annullato il',
  'plan.year': 'Annuale',
  'plan.month': 'mensile',
  'plan.year.cancelDialogBody':
    'Hai la possibilità di passare a un abbonamento mensile.',
  'plan.month.cancelDialogBody':
    'Hai la possibilità di passare a un abbonamento annuale e risparmiarne uno',
  'plan.year.changeQ': 'Vuoi passare al piano mensile?',
  'plan.month.changeQ': 'Vuoi passare al piano annuale?',
  withAnnualPlanYouCanSave: 'Con il Piano annuale risparmi un',
  whenChangePlan:
    'Il tuo piano verrà modificato in questo momento e le fatture verranno ripartite proporzionalmente con il periodo di fatturazione corrente.',
  moreOptions: 'Altre opzioni',
  differentAccountHolder: 'Il titolo è diverso',
  noActiveSubscription: 'Non hai abbonamenti attivi.',
  subscribeToAPlan:
    'Iscriviti a uno dei piani per usufruire di tutte le funzionalità',
  seePlans: 'Visualizza piani',
  plans: 'Piani',
  status: 'Stato',
  'status.active': 'Attivo',
  'status.incomplete': 'Incompleto',
  'status.incomplete_expired': 'scaduto',
  'status.trialing': 'Test',
  'status.past_due': 'Dovuto',
  'status.updaid': 'Non pagato',
  'status.canceled': 'Annullato',

  // CHECKOUT
  checkout: 'Dati di pagamento',
  finishCheckOut:
    'Completa il processo di pagamento per completare l’abbonamento.',
  yourOrder: 'Il tuo abbonamento',
  annualSubscription: 'Abbonamento annuale',
  monthlySubscription: 'Abbonamento mensile',
  taxes: 'imposte',
  total: 'Totale',
  subTotal: 'Totale parziale',
  subscribe: 'Iscriviti',
  weWillNotStoreYourData:
    'Non vedremo né memorizzeremo i tuoi dati di pagamento. Il processo di pagamento e i dati sono crittografati direttamente sul sistema altamente sicuro di Stripe.',
  checkoutName: 'Nome del titolare',
  securePayment: 'Pagamento',
  safeAndSecure: 'sicuro e protetto',
  garanteed: 'garantito',
  thankYou: 'Grazie!',
  subscriptionSucceed: 'L’abbonamento è stato completato con successo.',
  accept: 'Va bene',
  somethingWentWrong: 'Qualcosa è andato storto...',
  thePaymentCouldNotBeDone:
    'Non è stato possibile effettuare il pagamento. Si prega di verificare che i dati inseriti siano corretti',

  // CHAT
  tabAll: 'Tutto',
  tabAccepted: 'Accettato',
  tabRejected: 'Respinto',
  tabNegotiation: 'negoziazione',
  tabProposal: 'Proposte',
  tabStandBy: 'Orecchini',
  chat: 'Chat',
  sendAMessage: 'Scrivi un messaggio...',
  acceptToWrite: 'Accetta l’offerta di scrivere...',
  theUserHasToAccept:
    'La tua offerta deve essere accettata per iniziare a scrivere...',
  jobOfferIdText: 'ID annuncio',
  attachedFileText: 'Allegato',
  offerIdText: 'ID offerta',
  theUserIsStudingYourOffer:
    'Sta studiando la tua offerta. Una volta accettato, puoi iniziare una conversazione per delineare i dettagli.',
  theUserHasAccepted: 'Ha accettato la tua offerta e puoi discutere i dettagli',
  yourOfferHasBeenRejected: 'Hai già accettato un’altra offerta.',
  thinksThatYouCanBeInterested:
    'Penso che potresti essere interessato a questo lavoro.',
  valorate: 'Valore',
  companyNotAvailable:
    'L’azienda non ha il tuo account attivo al momento, prova un’altra offerta.',
  offer: 'Offerta',
  theOfferNeedToBeAccepted: [
    'Per stabilire una conversazione con l’azienda è necessario accettare la loro offerta.',
    'Una volta accettata un’offerta, le altre saranno automaticamente annullate.',
    'Prima di accettare, puoi saperne di più sull’azienda cliccando sul loro nome.'
  ],
  you: 'tu',

  // ALERTS
  areYouSure: 'Attenzione!',
  youAreGoingToDelete:
    'Cancellerai il post e le offerte che ho ricevuto. Vuoi continuare?',
  deleteJobMessage:
    'Questa azione non può essere annullata, se vuoi cambiare qualche dettaglio prova a passarlo alle bozze.',
  youAreGoingToDeleteYourAccount: 'Eliminerai il tuo account',
  allYourDataWillBeDeleted:
    'Tutti i tuoi dati saranno cancellati dal nostro database. Questa azione non può essere annullata.',
  introduceYourPassword: 'Inserisci la tua password.',
  deleting: 'Eliminare...',
  incorrectPassword: 'Password errata.',
  passwordChange: 'Cambia password',
  currentPassword: 'Password corrente',
  newPassword: 'Nuova password',
  change: 'Cambiamento',
  minimum8characteres: 'Deve contenere almeno 8 caratteri',
  anErrorHappens: 'Si è verificato un errore. Prova più tardi',
  fix: 'correzione',
  new: 'novità',
  news: 'Ultime modifiche',
  update: 'Aggiornamento',
  later: 'Più tardi',
  newUpdateTitle: 'Aggiornamento disponibile',
  newUpdateAvailable:
    'È disponibile una nuova versione. Si prega di aggiornare per le ultime notizie e miglioramenti.',
  editingRating:
    'Avete già apprezzato questa azienda. Puoi modificare la tua valutazione qui sotto.',
  commentSentSuccesfully: 'La tua recensione è stata inviata con successo.',
  onlyAvailableForUsers:
    'Questa funzione è disponibile solo per le persone con un account.',
  youShouldCompleteTheRequiredFields: 'Devi compilare i campi obbligatori.',
  postUpdatedSuccessfully: 'Pubblicazione aggiornata con successo.',
  jobDeletedSuccesfully: 'Post cancellato con successo',
  errorLoadingData:
    'Oops! C’è stato un errore nell’ottenere i dati. Riprova più tardi.',
  noResultsWasFound:
    'Siamo spiacenti, non abbiamo trovato alcun risultato. Prova modificando i filtri dall’alto.',

  // DELETE ACCOUNT PAGE
  pleaseIndicateReason:
    'Per favore, dicci perché vuoi eliminare il tuo account.',
  requestSended:
    'La tua richiesta è stata inviata con successo. Una volta che lo studiamo e tutto è corretto, procederemo a cancellare il tuo account.',
  toEliminateYouShouldIndicateAReason:
    'Per consentirci di eliminare il tuo account, devi comunicarci un motivo.',
  // COUNTRIES
  esp: 'Spagna',
  ch: 'Svizzera',

  deletedAccount: 'Account soppresso',

  // PLANS NAMES
  Anual: 'Annuale',
  Mensual: 'Mensile'
})

export default it
