import Button from './Button'

export default function ProgressButton({
  progress = 0,
  text,
  sx,
  disabled,
  ...props
}) {
  return (
    <Button
      sx={{
        '&::before': {
          content: '""',
          transition: 'width 300ms ease, opacity 250ms ease',
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: `${progress}%`,
          opacity: progress === 0 ? 0 : 1,
          borderRadius: 3,
          backgroundColor: disabled ? '#cdcdcd' : '#238F9C',
          ...sx
        }
      }}
      disabled={disabled}
      text={<span style={{ position: 'relative' }}>{text}</span>}
      {...props}
    />
  )
}
