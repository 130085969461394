import { Box, Typography } from '@mui/material'

export default function UpdateItem({ children }) {
  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Box
        component="span"
        sx={{
          height: 6,
          width: 6,
          bgcolor: 'text.primary',
          display: 'block',
          borderRadius: 10,
          position: 'absolute',
          top: '0.5rem',
        }}
      />
      <Typography marginLeft={2}>{children}</Typography>
    </Box>
  )
}
