import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar as MUISnackbar, Alert } from '@mui/material'
import { Danger, InfoCircle, Information, TickCircle } from 'iconsax-react'

function SnackBar({
  severity = 'info',
  open,
  message,
  onClose,
  duration,
  noHide,
  closeButton = true,
  marginBottom,
  sx,
  alertProps,
  ...other
}) {
  return (
    <MUISnackbar
      open={open}
      autoHideDuration={!noHide ? duration || 4000 : null}
      onClose={onClose}
      sx={{
        position: 'absolute',
        left: '0px !important',
        right: '0px !important',
        mx: 'auto',
        justifyContent: 'center',
        bottom: marginBottom ? '90px !important' : 16,
        width: '100%',
        maxWidth: 'calc(100% - 16px * 2)',
        ...sx
      }}
      {...other}
    >
      <Alert
        className='snackbar-alert'
        onClose={onClose && closeButton ? onClose : null}
        variant='filled'
        severity={severity}
        iconMapping={{
          error: <Information />,
          info: <InfoCircle />,
          success: <TickCircle />,
          warning: <Danger />
        }}
        sx={{ borderRadius: 3 }}
        {...alertProps}
      >
        {message}
      </Alert>
    </MUISnackbar>
  )
}

export default SnackBar

SnackBar.propTypes = {
  message: PropTypes.any.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  open: PropTypes.bool,
  duration: PropTypes.number,
  onClose: PropTypes.func,
  noHide: PropTypes.bool
}
