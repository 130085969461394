import {
  Typography,
  Box,
  Divider,
  Skeleton,
  Stack,
  CardContent
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { getDate, getRate } from 'helpers'
import { OfferDates, CustomCard } from 'components'
import { useLazyQuery } from '@apollo/client'
import { GET_CHAT_ID_BY_ASSIGNED } from 'apis/querys'
import { routes } from 'constants/Routes'
import { useHistory } from 'react-router-dom'
import { useI18N } from 'hooks'

export default function DefaultOfferCard({ loading, offerData, ...other }) {
  const { t } = useI18N()
  const history = useHistory()
  const {
    user_id,
    company_id,
    job_id,
    creation_date,
    start_date,
    end_date,
    amount,
    rate_type,
    state,
    post,
    particular
  } = offerData || ''

  const [getChatId] = useLazyQuery(GET_CHAT_ID_BY_ASSIGNED)

  // const getBg = () => {
  //   switch (state) {
  //     case 'negotiating':
  //       return 'info.main'
  //     case 'accepted':
  //       return 'success.main'
  //     case 'rejected':
  //       return 'error.main'
  //     default:
  //       break
  //   }
  // }

  const handleClick = () => {
    switch (state) {
      case 'negotiating':
        history.push(`${routes.post}?id=${job_id}`)
        break
      case 'accepted':
        getChatId({
          variables: {
            jobId: job_id,
            companyId: company_id
          }
        }).then((resp) => {
          const { _id: chatId } = resp.data.getChat
          history.push(`${routes.chat}`, {
            chatId,
            receptorId: user_id,
            jobData: post
          })
        })
        break
      default:
        break
    }
  }

  if (loading || !offerData) return <LoadingCard />

  const { public_name } = particular
  const { title, city } = post

  return (
    <CustomCard onClick={handleClick} {...other}>
      <CardContent sx={{ maxWidth: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography color='textSecondary' fontSize={14}>
            {t('sent')}: {getDate(creation_date)}
          </Typography>
          <Typography
            fontSize={17}
            fontWeight={600}
            lineHeight='1.8rem'
            sx={{
              color: 'primary.main',
              bgcolor: (theme) => alpha(theme.palette.primary.light, 0.15),
              borderRadius: 10,
              px: 2
            }}
          >
            {amount}
            <Typography as='span' fontSize={16}>
              {` €${getRate(rate_type)}`}
            </Typography>
          </Typography>
          {/* <Typography
            fontSize={12}
            sx={{
              bgcolor: getBg(),
              borderRadius: 10,
              px: 1,
              py: 0.25,
              color: 'white'
            }}
          >
            {texts()[state]}
          </Typography> */}
        </Box>

        <Divider light sx={{ my: 1 }} />

        <Typography fontSize={16} fontWeight={500}>
          {title}
        </Typography>

        <Stack
          direction='row'
          divider={<Divider orientation='vertical' flexItem />}
          spacing={2}
        >
          <Typography color='textSecondary'>{public_name}</Typography>
          <Typography>{city}</Typography>
        </Stack>

        <OfferDates
          startDate={getDate(start_date)}
          endDate={getDate(end_date)}
        />
      </CardContent>
    </CustomCard>
  )
}

const LoadingCard = () => (
  <CustomCard>
    <CardContent sx={{ maxWidth: '100%' }}>
      <Typography fontSize={14}>
        <Skeleton width='50%' />
      </Typography>

      <Divider light sx={{ my: 1 }} />

      <Typography fontSize={16} fontWeight={500}>
        <Skeleton />
      </Typography>

      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={2}
      >
        <Typography>
          <Skeleton width={100} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>

      <Typography fontSize={17} display='block'>
        <Skeleton width='50%' />
        <Skeleton width='20%' />
      </Typography>
    </CardContent>
  </CustomCard>
)
