import { useEffect, useState } from 'react'
import { Typography, Box, Slide, Stack } from '@mui/material'
import { Button, SnackBar } from 'components'
import { useI18N, useServiceWorker } from 'hooks'

const Message = ({ content, actions }) => {
  const { t } = useI18N()
  return (
    <Box color='white'>
      <Typography fontWeight={600} mb={1}>
        {t('newUpdateTitle')}
      </Typography>
      <Typography fontSize={14} mb={2}>
        {content}
      </Typography>
      <Stack direction='row'>{actions}</Stack>
    </Box>
  )
}

export default function UpdateAvailable() {
  const { t } = useI18N()
  const [open, setOpen] = useState(false)
  const { isUpdateAvailable, updateAssets } = useServiceWorker()

  useEffect(() => {
    setOpen(isUpdateAvailable)
  }, [isUpdateAvailable])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const updateApp = () => {
    updateAssets()
  }

  return (
    <SnackBar
      open={open}
      onClose={handleClose}
      noHide
      closeButton={false}
      message={
        <Message
          content={t('newUpdateAvailable')}
          actions={
            <Button
              fullWidth
              text={t('update')}
              onClick={updateApp}
              sx={{
                bgcolor: 'white',
                color: '#191b1a',
                '&:hover': { bgcolor: '#ffffffE6' }
              }}
            />
          }
        />
      }
      TransitionComponent={Slide}
      alertProps={{ icon: false }}
      sx={{
        '& .snackbar-alert': {
          background: 'linear-gradient(45deg, #5282EE 0%, #26447E 100%)'
        }
      }}
    />
  )
}
