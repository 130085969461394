import { styled } from '@mui/system'
import { Button } from '@mui/material'
import { alpha } from '@mui/material/styles'

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'round' &&
    prop !== 'variant' &&
    prop !== 'shadow' &&
    prop !== 'borderColor'
})(({ theme, round, variant = 'primary', shadow, borderColor }) => ({
  transition: 'all 0.15s ease-in-out',
  minHeight: 48,
  alignItems: 'center',
  padding: '8px 16px',
  minWidth: 126,
  textTransform: 'none',
  maxWidth: theme.breakpoints.values.sm,
  borderRadius: round ? 50 : 12,
  '&:active': { transform: 'scale(0.97)' },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    boxShadow: 'none'
  },
  ...(variant === 'primary' && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }),
  ...(variant === 'primary' &&
    shadow && {
      boxShadow: `0 5px 8px ${alpha(theme.palette.primary.main, 0.2)}`
    }),
  ...(variant === 'light' && {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3)
    }
  }),
  ...(variant === 'ghost' && {
    backgroundColor: 'transparent',
    color: borderColor || theme.palette.primary.main,
    border: `1px solid ${borderColor || theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: borderColor
        ? alpha(borderColor, 0.1)
        : alpha(theme.palette.primary.main, 0.1)
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.action.disabledBackground}`
    }
  }),
  ...(variant === 'text' && {
    display: 'block',
    padding: '8px 8px',
    backgroundColor: 'transparent',
    minWidth: 'inherit',
    width: 'fit-content',
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    },
    '&.Mui-disabled': {
      color: theme.palette.text.hint,
      backgroundColor: 'transparent',
      fontWeight: 500
    }
    // "& .MuiTouchRipple-root .MuiTouchRipple-ripple": {
    //   color: alpha(theme.palette.primary.main, 0.1),
    // },
  }),
  ...(variant === 'loadMore' && {
    backgroundColor: theme.palette.background.card,
    border: `1px solid ${theme.palette.background.contrast}`,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.background.card
    }
  })
}))
