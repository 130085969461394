import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CardContent,
  Typography,
  Box,
  Skeleton,
  IconButton
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { CustomCard, MoreMenu } from 'components'
import { More } from 'iconsax-react'
import { getDate } from 'helpers'
import { useI18N } from 'hooks'

/// ////////COMPONENT PROPS
CurrentSubscriptionCard.propTypes = {
  plan: PropTypes.object,
  subscriptionData: PropTypes.object,
  onChangePlan: PropTypes.func,
  onCancelPlan: PropTypes.func
}

const getMonthlyAmmount = (plan) => {
  if (plan.interval === 'year') {
    const monthAmount = (plan.unit_amount / 100 / 12).toFixed(2)
    return monthAmount
  } else {
    return plan.unit_amount / 100
  }
}

const moreOptions = (t) => [
  {
    id: 'changePlan',
    title: t('changePlan')
  },
  {
    id: 'cancelPlan',
    title: t('cancelPlan')
  }
]

export default function CurrentSubscriptionCard({
  status,
  plan,
  subscriptionData,
  onChangePlan,
  onCancelPlan
}) {
  const { t } = useI18N()
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null)
  const isMenuOpen = Boolean(moreMenuAnchorEl)
  const canceled = subscriptionData.cancel_at_period_end
  const cancelDate = subscriptionData.current_period_end * 1000
  const pendingToCancel =
    new Date().getTime() < new Date(getDate(cancelDate)).getTime()

  const subStatus = status === 'canceled' && pendingToCancel ? 'active' : status
  const subNickName = subscriptionData.plan.metadata.nickname
  // TODO: add nickname promo to translations

  const handleMoreMenuClose = () => {
    setMoreMenuAnchorEl(null)
  }

  const handleMoreMenuOpen = (event) => {
    setMoreMenuAnchorEl(event.currentTarget)
  }

  const handleItemClick = (itemId) => {
    switch (itemId) {
      case 'cancelPlan':
        onCancelPlan()
        break
      case 'changePlan':
        onChangePlan()
        break
      default:
        break
    }
    handleMoreMenuClose()
  }

  const getBadgeColor = (theme) => {
    let color
    switch (subStatus) {
      case 'active':
        color = theme.palette.success.main
        return { bg: alpha(color, 0.2), text: color }
      case 'trialing':
        color = theme.palette.warning.main
        return { bg: alpha(color, 0.2), text: color }
      default:
        color = theme.palette.error.main
        return { bg: alpha(color, 0.2), text: color }
    }
  }

  return (
    <CustomCard
      title={t('yourSubscriptionPlan')}
      {...(!canceled && {
        headerProps: {
          action: (
            <IconButton
              aria-label='more-options'
              aria-controls='more-menu'
              aria-haspopup='true'
              onClick={handleMoreMenuOpen}
            >
              <More />
            </IconButton>
          )
        }
      })}
    >
      <CardContent
        sx={{
          p: 2,
          '&:last-child': {
            pb: 2
          }
        }}
      >
        <Box
          aria-label='card-header'
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography fontSize={20} textTransform='uppercase'>
              {t(subNickName) || <Skeleton sx={{ maxWidth: 100 }} />}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={20}>
              {getMonthlyAmmount(plan)} {plan.currency}
              <Typography
                fontSize={14}
                display='inline'
                color='textSecondary'
                component='span'
              >
                {`/${t('month')}`}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Typography fontSize={14} color='textSecondary'>
          {`${plan.unit_amount / 100} ${plan.currency} ${
            plan.interval === 'year' ? t('billedAnnualy') : t('billedmonthly')
          }`}
        </Typography>
        <Box
          sx={{
            width: 'fit-content',
            px: 1,
            py: 0.25,
            mt: 2,
            bgcolor: (theme) => getBadgeColor(theme).bg,
            color: (theme) => getBadgeColor(theme).text,
            borderRadius: 2,
            display: 'block',
            ml: 'auto'
          }}
        >
          <Typography>{t(`status.${status}`)}</Typography>
        </Box>
        {canceled && pendingToCancel && (
          <Typography
            fontSize={14}
            lineHeight={1.2}
            marginTop={1}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >{`${t('yourPlanWillBecancelledAt')} ${getDate(
            cancelDate,
            true
          )}`}</Typography>
        )}
      </CardContent>
      <MoreMenu
        aria-label='more-menu'
        id='more-menu'
        anchorEl={moreMenuAnchorEl}
        open={isMenuOpen}
        actionList={moreOptions(t)}
        onItemClick={handleItemClick}
        onClose={handleMoreMenuClose}
      />
    </CustomCard>
  )
}
