import React, { useContext, useState } from 'react'
import { SnackBar } from 'components'

const SnackBarContext = React.createContext([{}, () => {}])

const SnackBarProvider = ({ children }) => {
  const [snackBar, setSnackBar] = useState({ message: '', severity: 'error' })

  const showSnackbar = ({
    message,
    severity,
    duration,
    noHide,
    marginBottom,
    sx
  }) => {
    setSnackBar((prev) => ({
      ...prev,
      open: true,
      message: message || '',
      severity: severity || 'error',
      duration: duration || 4000,
      noHide,
      marginBottom,
      sx
    }))
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar((prev) => ({ ...prev, open: false }))
  }

  return (
    <SnackBarContext.Provider value={{ showSnackbar, handleClose }}>
      {children}
      <SnackBar onClose={handleClose} {...snackBar} />
    </SnackBarContext.Provider>
  )
}

export { SnackBarContext, SnackBarProvider }

export function useSnackbar() {
  const snackBar = useContext(SnackBarContext)
  return snackBar
}
