import { useLazyQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { GET_FILTERED_COMPANIES_COUNT } from 'apis/querys'
import { Button, Dialog, Modal } from 'components'
import Searching from 'components/rives/Searching'
import { routes } from 'constants/Routes'
import { AnimatePresence, motion } from 'framer-motion'
import { useI18N, useSettings } from 'hooks'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function CompaniesCountDialog({
  province,
  category,
  search,
  open,
  onContinue
}) {
  const { t } = useI18N()
  const { updateSettings } = useSettings()
  const [countCompanies, { data, loading }] = useLazyQuery(
    GET_FILTERED_COMPANIES_COUNT,
    {
      variables: { province, category, bio: search }
    }
  )
  const history = useHistory()

  const count = data?.countFilteredCompanies

  useEffect(() => {
    if (open) {
      countCompanies()
    }
  }, [open])

  const handleSeeCompanies = () => {
    updateSettings({ discover: 'companies' })
    return history.push(
      `${routes.filteredCompanies}?province=${province}&category=${category}&search=${search}`,
      { count }
    )
  }

  return (
    <Modal open={open}>
      <Dialog>
        <Dialog.Title>
          <Typography fontWeight={600} fontSize='1.2rem' align='center'>
            {t('lookingCompanies')}
          </Typography>
        </Dialog.Title>
        <Dialog.Content>
          <Typography color='textSecondary' align='center'>
            {count >= 0 ? t('weHaveFound') : t('thisCanTakeAWhile')}
          </Typography>

          <AnimatePresence exitBeforeEnter>
            {loading ? (
              <Box
                key='search'
                component={motion.div}
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                sx={{ display: 'flex', justifyContent: 'center', mt: -4 }}
              >
                <Searching size={250} />
              </Box>
            ) : (
              <>
                <motion.div
                  key='result'
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                >
                  <Typography fontWeight={600} fontSize='3.3rem' align='center'>
                    {count}
                  </Typography>
                  <Typography
                    fontWeight={600}
                    align='center'
                    lineHeight={0}
                    mb={4}
                  >
                    {t('companies')}
                  </Typography>
                  <Typography color='textSecondary' align='center'>
                    {count > 0
                      ? t('whatDoYouWantToDoNext')
                      : t('weAreSorryWeStillGrowing')}
                  </Typography>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </Dialog.Content>
        <Dialog.Actions column>
          {count > 0 && (
            <Button
              onClick={handleSeeCompanies}
              fullWidth
              variant='light'
              text={t('seeCompanies')}
            />
          )}
          <Button
            onClick={onContinue}
            fullWidth
            text={t('continueWithTheApplication')}
          />
        </Dialog.Actions>
      </Dialog>
    </Modal>
  )
}
