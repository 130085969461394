import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Modal, Dialog, Button } from 'components'
import { useHistory } from 'react-router-dom'
import { updatePlan } from 'apis/stripe'
import { useI18N } from 'hooks'

export default function ChangePlanDialog({
  open,
  onClose,
  interval,
  savingValue,
  subscriptionId,
  subsItemId
}) {
  const { t } = useI18N()
  const history = useHistory()
  const [changing, setChanging] = useState(false)

  const onChangePlan = () => {
    setChanging(true)
    const newInterval = interval === 'year' ? 'month' : 'year'
    updatePlan(subscriptionId, newInterval, subsItemId).then(() => {
      history.go()
    })
  }

  return (
    <Modal open={open} onClose={!changing ? onClose : null}>
      <Dialog disabled={changing}>
        <Dialog.Title
          title={t('changePlan')}
          subtitle={t(`plan.${interval}.changeQ`)}
        />
        <Dialog.Content>
          <Typography>{t('whenChangePlan')}</Typography>

          <Box
            sx={{
              bgcolor: '#ceecff',
              borderRadius: 4,
              p: 1,
              mt: 2
            }}
          >
            <Typography sx={{ color: '#3c7cdc' }} align='center'>
              {t('withAnnualPlanYouCanSave')}
              <Typography
                as='span'
                sx={{ fontWeight: 'bold' }}
              >{` ${savingValue}%`}</Typography>
            </Typography>
          </Box>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            fullWidth
            disabled={changing}
            onClick={onClose}
            text={t('cancel')}
            variant='light'
          />
          <Button
            fullWidth
            disabled={changing}
            onClick={onChangePlan}
            text={changing ? t('changing') : t('continue')}
          />
        </Dialog.Actions>
      </Dialog>
    </Modal>
  )
}
