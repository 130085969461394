import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import {
  AdornmentInput,
  DateInput,
  SelectableInput,
  Checkbox,
  Button,
  SendingOffer,
  ScrollableContainer,
  Spacer
} from 'components'
import { useForm, useI18N } from 'hooks'
import { CREATE_AN_OFFER } from 'apis/mutations'
import { UserContext } from 'context/UserContext'
import { useSnackbar } from 'context/SnackBarContext'
import { routes } from 'constants/Routes'
import { rates } from 'constants/Rates'
import { AnimatePresence, motion } from 'framer-motion'
import dayjs from 'dayjs'

const initialValues = {
  rateType: '', // rate index from "../constants/Rates"
  amount: '',
  startDate: new Date(),
  endDate: new Date()
}

export default function NewOffer() {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  const { showSnackbar } = useSnackbar()
  const { postId } = useParams()
  const history = useHistory()
  const state = history.location.state
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [succed, setSucced] = useState(false)
  const [newOffer] = useMutation(CREATE_AN_OFFER)

  const validate = (fieldValues = values) => {
    const temp = { ...errors }

    if ('rateType' in fieldValues)
      temp.rateType = fieldValues.rateType !== '' ? '' : t('required')

    if ('amount' in fieldValues)
      temp.amount = fieldValues.amount ? '' : t('required')

    if ('startDate' in fieldValues)
      temp.startDate = fieldValues.startDate ? '' : t('required')

    if ('endDate' in fieldValues)
      temp.endDate =
        !checked || (checked && fieldValues.endDate) ? '' : t('required')

    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, handleInputChange, errors, setErrors, resetForm, setValues } =
    useForm(initialValues, true, validate)

  const handleDateChange = ({ startDate }) => {
    setValues((prev) => ({ ...prev, startDate, endDate: startDate }))
  }

  useEffect(() => {
    // If user is not a company or go directly by link go to the job info page
    if (user.role !== 'company' || !state) {
      history.replace(`${routes.post}?id=${postId}`)
    }
  }, [])

  function handleSubmit() {
    if (validate()) {
      setLoading(true)
      newOffer({
        variables: {
          jobId: postId,
          companyId: user.id,
          particularId: state.jobUserId,
          rateType: values.rateType,
          amount: parseFloat(values.amount),
          startDate: values.startDate,
          endDate: !checked ? null : values.endDate,
          content: {
            type: 'text',
            name: null,
            value: t('theCompanyIsInterested', user.name)
          }
        }
      })
        .then(() => {
          // const offerId = resp.data.createAnOffer._id;
          resetForm()
          setSucced(true)
        })
        .catch((err) => {
          if (err.message === 'no_active_subscription_found') {
            showSnackbar({
              message: t(`errors.${err.message}`),
              marginBottom: true,
              duration: 6000
            })
          } else {
            showSnackbar({ message: err.message, marginBottom: true })
          }
          setLoading(false)
        })
    }
  }

  return loading ? (
    <SendingOffer loaded={succed} />
  ) : (
    <ScrollableContainer title={t('newOffer')} appBarProps={{ hasBack: true }}>
      <Spacer x={2}>
        <Typography marginBottom={1}>{t('sendOfferTo')}</Typography>
        <Typography variant='h2'>{state?.jobTitle}</Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <SelectableInput
              id='rateType'
              label={t('rateType')}
              items={rates}
              listContent='array'
              required
              error={errors.rateType}
              value={values.rateType}
              byIndex
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <AdornmentInput
              id='amount'
              label={t('amount')}
              adornment={
                <Typography
                  fontSize='1.4rem'
                  fontWeight={500}
                  color='textSecondary'
                >
                  €
                </Typography>
              }
              required
              error={errors.amount}
              value={values.amount}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <DateInput
              id='startDate'
              label={t('startDate')}
              value={values.startDate}
              required
              disablePast
              error={errors.startDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              name='endDateCheck'
              label={<Typography>{t('addEndDate')}</Typography>}
              value={checked}
              onChange={() => setChecked(!checked)}
            />
          </Grid>
          <AnimatePresence>
            {checked && (
              <Grid
                component={motion.div}
                item
                xs={12}
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 40 }}
                transition={{
                  duration: 0.3,
                  ease: [0, 0.71, 0.2, 1.01]
                }}
              >
                <DateInput
                  id='endDate'
                  label={t('endDate')}
                  value={values.endDate}
                  required={checked}
                  minDate={dayjs(values.startDate)}
                  error={errors.endDate}
                  onChange={handleInputChange}
                />
              </Grid>
            )}
          </AnimatePresence>
        </Grid>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            p: 2
          }}
        >
          <Button
            fullWidth
            text={t('sendOffer')}
            onClick={handleSubmit}
            loading={loading}
          />
        </Box>
      </Spacer>
    </ScrollableContainer>
  )
}
