import React, { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { AppBar } from 'components'

const ScrollableContainer = ({
  toolBarChildren,
  title,
  appBarProps,
  scrollTop,
  children
}) => {
  const contentRef = useRef(null)
  const toolBarChildrenArray = React.Children.toArray(toolBarChildren)
  const isFragment = toolBarChildrenArray[0]?.type === React.Fragment
  const toolBarNodes = isFragment
    ? toolBarChildrenArray[0].props.children
    : toolBarChildrenArray

  useEffect(() => {
    if (contentRef || (contentRef && scrollTop)) {
      contentRef.current.scrollTop = 0
    }
  }, [contentRef, scrollTop])

  const getPaddingTop = () => {
    const toolBarChildrenSpace = toolBarNodes.length * 8
    if ((title || appBarProps) && toolBarNodes) return 8 + toolBarChildrenSpace
    if (toolBarNodes) return toolBarChildrenSpace
    return 0
  }

  return (
    <>
      {(title || appBarProps || toolBarNodes.length > 0) && (
        <AppBar title={title} containerRef={contentRef} {...appBarProps}>
          {toolBarNodes}
        </AppBar>
      )}

      <Box
        ref={contentRef}
        id='content-container'
        className='scrollable'
        sx={{
          position:'relative',
          height: '100%',
          overflowX: 'hidden',
          pt: getPaddingTop(),
          '&::-webkit-scrollbar-track': {
            mt: getPaddingTop() + 1
          }
        }}
      >
        {children}
      </Box>
    </>
  )
}

export default ScrollableContainer
