import { useEffect, useState } from 'react'
import { Box, Typography, Button, Stack, alpha } from '@mui/material'
import { AnimatePresence, motion, useMotionValue } from 'framer-motion'
import { Add, Minus } from 'iconsax-react'
import { useI18N } from 'hooks'

const buildBudgets = () => {
  const list = [50, 100]

  // Add numbers from 100 to 1000, increasing by 100 each time.
  for (let i = 200; i <= 1000; i += 100) {
    list.push(i)
  }

  // Add numbers from 1250 to 5000, increasing by 250 each time.
  for (let i = 1250; i <= 5000; i += 250) {
    list.push(i)
  }

  return list
}

export default function BudgetSelectorPage({ budget, onChange }) {
  const budgetsList = buildBudgets()
  const { t } = useI18N()
  const [selected, setSelected] = useState(budgetsList.indexOf(budget))
  const x = useMotionValue(budgetsList.indexOf(budget))
  const y = useMotionValue(1)

  const handlePlus = () => {
    if (selected < budgetsList.length - 1) {
      x.set(x.get() + 1)
    }
  }
  const handleMinus = () => {
    if (selected > 0) {
      x.set(x.get() - 1)
    }
  }

  useEffect(() => {
    x.onChange((latest) => {
      const value = latest > selected ? -1 : 1
      //   console.log(latest, selected, latest > selected ? -1 : 1)
      y.set(value)
      onChange({
        budget:
          latest < budgetsList.length - 1
            ? budgetsList[latest]
            : budgetsList[latest] + 1
      })
      setSelected(latest)
    })

    return () => x.clearListeners()
  }, [selected])

  return (
    <>
      <Typography fontSize='1.5rem' mb={2}>
        {t('whatBudgetDoYouHave')}
      </Typography>
      <Typography color='textSecondary'>{t('budgetSubtitle')}</Typography>

      <Stack
        alignItems='center'
        direction='row'
        sx={{
          borderTop: '1px solid rgba(0,0,0,0.1)',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          mb: 1,
          mt: 6
        }}
      >
        <Typography
          align='center'
          fontSize='1.5rem'
          minWidth='fit-content'
          pr={2}
        >
          {selected < budgetsList.length - 1 ? t('lessThan') : t('moreThan')}
        </Typography>
        <Box
          width='100%'
          height='4rem'
          overflow='hidden'
          textAlign='center'
          position='relative'
        >
          <AnimatePresence>
            <Box
              key={selected}
              component={motion.div}
              animate={{ y: 0 }}
              initial={{ y: `${y.get() * 100}%` }}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%'
              }}
            >
              {budgetsList[selected + 1] && (
                <Digit
                  aria-hidden='true'
                  sx={{
                    top: '-100%'
                  }}
                >
                  {`${budgetsList[selected + 1]}€`}
                </Digit>
              )}

              <Digit>{`${budgetsList[selected]}€`}</Digit>

              {budgetsList[selected - 1] && (
                <Digit
                  aria-hidden='true'
                  sx={{
                    top: '100%'
                  }}
                >
                  {`${budgetsList[selected - 1]}€`}
                </Digit>
              )}
            </Box>
          </AnimatePresence>
        </Box>
      </Stack>

      <Stack direction='row' gap={1}>
        <Button
          onClick={handlePlus}
          fullWidth
          startIcon={<Add />}
          sx={{
            height: '4em',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            borderRadius: 0,
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2)
            },
            '& .MuiButton-startIcon': {
              mr: -0.5
            }
          }}
        />
        <Button
          onClick={handleMinus}
          fullWidth
          startIcon={<Minus />}
          sx={{
            height: '4em',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            borderRadius: 0,
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2)
            },
            '& .MuiButton-startIcon': {
              mr: -0.5
            }
          }}
        />
      </Stack>
    </>
  )
}

const Digit = ({ sx, children }) => (
  <Typography
    color='primary'
    sx={{
      position: 'absolute',
      display: 'flex',
      width: '100%',
      height: '100%',
      fontWeight: '400',
      color: 'primary.main',
      fontSize: '3.5rem',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx
    }}
  >
    {children}
  </Typography>
)
