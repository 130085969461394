import { Container, Typography, Box } from '@mui/material'
import { FAButton, Sending } from 'components'
import { slugs } from 'constants/Routes'
import { RowVertical, SearchNormal, Share } from 'iconsax-react'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { useI18N } from 'hooks'

export default function SendingOffer({ loaded }) {
  const { t } = useI18N()
  const history = useHistory()

  const sharingData = {
    url: slugs.app.link,
    text: t('shareTheAppMessage'),
    title: 'Jobsaun App'
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        pt: '15vh'
      }}
    >
      <Typography variant='h4' align='center' fontWeight={500}>
        {t('sendingYourOffer')}
      </Typography>
      <Typography align='center' color='textSecondary' fontSize={18}>
        {t('itCanTakeAMoment')}
      </Typography>
      <Box p={3}>
        <Sending size={180} loaded={loaded} />
      </Box>
      {loaded && (
        <motion.div
          transition={{
            opacity: { duration: 0.2 }
          }}
          initial={{ opacity: 0, y: '25vh' }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography align='center' fontSize={18}>
              {t('offerSuccessSent')}
            </Typography>
            <Typography align='center' color='textSecondary' fontSize={18}>
              {t('continueWith')}
            </Typography>
            <Box pt={3}>
              <FAButton
                sx={{ minWidth: 220 }}
                version='ghost'
                text={t('seeMoreJobs')}
                icon={<SearchNormal />}
                onClick={() => history.replace(slugs.root.link)}
              />
            </Box>
            <Box pt={3}>
              <FAButton
                sx={{ minWidth: 220 }}
                version='ghost'
                text={t('seeMyOffers')}
                icon={<RowVertical />}
                onClick={() => history.replace(slugs.board.link)}
              />
            </Box>
            <Box pt={3}>
              <FAButton
                sx={{ minWidth: 220 }}
                version='ghost'
                text={t('shareTheApp')}
                icon={<Share />}
                onClick={() => navigator.share(sharingData)}
              />
            </Box>
          </Box>
        </motion.div>
      )}
    </Container>
  )
}
