import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton } from '@mui/material'
import { Add } from 'iconsax-react'

/// ////////COMPONENT PROPS
UploadedPicture.propTypes = {
  onDelete: PropTypes.func,
  image: PropTypes.string,
}

export default function UploadedPicture({ onDelete, image }) {
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: 2,
        height: 100,
        width: '100%',
        minWidth: 100,
        maxWidth: 100,
        backgroundImage: `url(${image})`,
        bgcolor: 'action.hover',
        backgroundSize: 'cover',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: 4,
          right: 4,
          padding: 0,
          margin: 0,
          lineHeight: 0,
        }}
        disableRipple
        onClick={onDelete}
      >
        <Box
          sx={{
            borderRadius: '50%',
            bgcolor: 'background.paper',
            transform: 'rotate(45deg)',
            opacity: 0.8,
            color: 'text.primary',
            boxShadow: '1px 1px 4px rgba(0,0,0,0.15)',
          }}
        >
          <Add />
        </Box>
      </IconButton>
    </Box>
  )
}
