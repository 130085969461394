import React, { useContext, useRef, useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { motion, AnimatePresence } from 'framer-motion'
import {
  FAButton,
  ProfileInfo,
  RatingsGroup,
  MultilineInput,
  Button,
  Rating,
  ScrollableContainer,
  Spacer
} from 'components'
import { Alert, Box, Collapse, Skeleton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { GET_COMPANY, BASIC_COMPANY_INFO, USER_RATINGS } from 'apis/querys'
import { useHistory } from 'react-router'
import { routes } from 'constants/Routes'
import { UserContext } from 'context/UserContext'
import { ADD_RATING, EDIT_RATING } from 'apis/mutations'
import { Call, Sms } from 'iconsax-react'
import { formatText } from 'helpers'
import { useSnackbar } from 'context/SnackBarContext'
import { useI18N, useSearchParams } from 'hooks'

const maxBioHeight = 140

const SnackBarMessage = ({ onClick, userRole }) => {
  const { t } = useI18N()
  return (
    <Box display='flex' flexDirection='column'>
      <Typography display='block'>{t('onlyAvailableForUsers')}</Typography>
      {userRole === 'guest' && (
        <Box display='flex' flexDirection='row' gap={1} mt={2}>
          <Button
            text={t('createFreeAccount')}
            sx={{
              py: 1,
              bgcolor: 'background.default',
              color: 'text.primary',
              borderRadius: '6px',
              '&:hover': (theme) => ({
                backgroundColor: alpha(theme.palette.background.default, 0.9)
              })
            }}
            onClick={onClick}
          />
        </Box>
      )}
    </Box>
  )
}

export default function CompanyProfile() {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  const history = useHistory()
  const { id: companyId } = useSearchParams('id')
  const { fromChat } = history.location.state || false
  const bioRef = useRef()
  const { showSnackbar, handleClose } = useSnackbar()
  const [companyData, setCompanyData] = useState()
  const [bioHeight, setBioHeight] = useState()
  const [expanded, setExpanded] = useState(false)
  const [valorating, setValorating] = useState(false)

  useEffect(() => {
    return () => {
      setCompanyData()
      setValorating(false)
    }
  }, [])

  const { loading, data } = useQuery(
    fromChat ? GET_COMPANY : BASIC_COMPANY_INFO,
    {
      variables: { userId: companyId }
    }
  )
  const [userRatings, { loading: userRatingsLoading, data: userRatingsData }] =
    useLazyQuery(USER_RATINGS, {
      variables: { userId: user.id, companyId }
    })

  useEffect(() => {
    if (!loading && data && data.getUser) {
      setCompanyData(data.getUser)
    }
  }, [data, loading])

  useEffect(() => {
    if (companyData && bioRef.current) {
      const { clientHeight } = bioRef.current
      const bioHeight =
        clientHeight < maxBioHeight ? clientHeight + 40 : maxBioHeight
      setBioHeight(bioHeight)
    }
  }, [companyData])

  const goToValorate = () => {
    userRatings().then(() => setValorating(true))
  }

  const handleSnackBarClick = () => {
    handleClose()
    history.push(routes.signUp)
  }

  const goToPostedJobs = () => {
    if (user.role === 'user') {
      history.push(routes.postedJobs, {
        companyId,
        categories: companyData.company_data.categories
      })
    } else {
      showSnackbar({
        message: (
          <SnackBarMessage onClick={handleSnackBarClick} userRole={user.role} />
        ),
        severity: 'info',
        duration: 4000
      })
    }
  }

  const TwoButtons = ({ company, disabled }) => (
    <>
      <FAButton
        version='default'
        text={t('sendJobOffer')}
        onClick={goToPostedJobs}
        disabled={disabled}
      />
      <FAButton
        ghost
        icon={<Call variant='Bulk' />}
        size='medium'
        disabled={disabled}
        onClick={() => (window.location.href = `tel:${company.phone}`)}
      />
    </>
  )

  const ThreeButtons = ({ company, disabled }) => (
    <>
      <FAButton
        ghost
        icon={<Call variant='Bulk' />}
        size='medium'
        disabled={disabled}
        onClick={() => (window.location.href = `tel:${company.phone}`)}
      />
      <FAButton
        text={t('valorate')}
        onClick={goToValorate}
        loading={userRatingsLoading}
        disabled={disabled}
      />
      <FAButton
        ghost
        icon={<Sms variant='Bulk' />}
        size='medium'
        disabled={disabled}
        onClick={() => (window.location.href = `mailto:${company.email}`)}
      />
    </>
  )

  return (
    <ScrollableContainer
      title={companyData ? companyData.name : ''}
      appBarProps={{ hasBack: true }}
    >
      <Spacer sx={{ position: 'relative' }} m={2}>
        {companyData ? (
          <ProfileInfo
            alt={companyData.name}
            image={companyData.picture}
            userName={companyData.public_name}
            legalForm={t(companyData.company_data.legal_form)}
            postal={companyData.company_data.postal_code}
            city={companyData.company_data.city}
            province={companyData.company_data.province}
            sx={{ my: 2 }}
          />
        ) : (
          <>
            <Skeleton
              variant='circular'
              height={88}
              width={88}
              sx={{ mx: 'auto', mb: 1, mt: 2 }}
            />
            <Skeleton width={120} sx={{ mx: 'auto', mb: 2 }} />
          </>
        )}
        <AnimatePresence initial={false}>
          {user.role === 'user' && valorating && fromChat ? (
            <Box
              sx={{ my: 2, bgcolor: 'background.paper', borderRadius: 2, p: 2 }}
            >
              <Valorate
                userId={user.id}
                companyId={companyId}
                onCancel={() => setValorating(false)}
                prevData={userRatingsData}
              />
            </Box>
          ) : (
            <motion.div
              transition={{
                x: { type: 'spring', stiffness: 400, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              initial={{ opacity: 1, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
            >
              {/* Actions Buttons */}
              {companyId !== user.id && (
                <Box
                  sx={{
                    mb: 6,
                    width: '100%',
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {fromChat ? (
                    <ThreeButtons
                      company={companyData}
                      disabled={companyData === undefined}
                    />
                  ) : (
                    <TwoButtons
                      company={companyData}
                      disabled={companyData === undefined}
                    />
                  )}
                </Box>
              )}

              {/* Company Info */}
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  p: 2,
                  pb: bioHeight >= maxBioHeight ? 1 : 2
                }}
              >
                {/* Bio */}
                <Collapse
                  sx={{ position: 'relative' }}
                  in={expanded}
                  collapsedSize={bioHeight || maxBioHeight}
                >
                  <Typography variant='h6'>{t('companyInfo')}</Typography>

                  <Box aria-label='bio-container'>
                    {companyData ? (
                      <>
                        <Box
                          sx={{
                            '& p': { mt: 0, mb: 1, color: 'text.secondary' }
                          }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              formatText(companyData.company_data.bio)
                            )
                          }}
                          {...(companyData.company_data.bio !== '' && {
                            ref: bioRef
                          })}
                        />
                        {companyData.company_data.bio === '' && (
                          <Typography color='textSecondary' ref={bioRef}>
                            {t('noDetailsYet')}
                          </Typography>
                        )}
                        {!expanded && bioHeight >= maxBioHeight && (
                          <Box
                            component='span'
                            sx={{
                              position: 'absolute',
                              bottom: 0,
                              left: 0,
                              right: 0,
                              height: 20,
                              background: (theme) =>
                                `linear-gradient(0deg, ${theme.palette.background.paper} 0%, transparent 100%)`
                            }}
                          />
                        )}
                      </>
                    ) : (
                      [0, 1, 2, 3].map((item) => (
                        <Skeleton key={`bio-${item}`} />
                      ))
                    )}
                  </Box>
                </Collapse>
                {/* Collapse Button */}
                {companyData &&
                  companyData.company_data.bio &&
                  bioHeight >= maxBioHeight && (
                    <Typography
                      style={{ cursor: 'pointer' }}
                      color='primary'
                      textAlign='right'
                      onClick={() => setExpanded(!expanded)}
                    >
                      {!expanded ? t('seeMore') : t('seeLess')}
                    </Typography>
                  )}
              </Box>
              {/* RatingsGroups Button */}
              <Box
                sx={{
                  my: 2,
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  p: 2
                }}
              >
                <Typography variant='h6'>{t('customerReviews')}</Typography>
                <RatingsGroup companyId={companyId} />
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Spacer>
    </ScrollableContainer>
  )
}

const defaultValues = {
  comunication: 0,
  times: 0,
  service: 0,
  recommend: 0,
  comment: ''
}

const Valorate = ({ userId, companyId, onCancel, prevData }) => {
  const { t } = useI18N()
  const [ratingValues, setRatingValues] = useState(defaultValues)
  const [editing, setEditing] = useState(false)
  const { showSnackbar } = useSnackbar()
  const [sending, setSending] = useState(false)

  const [addRating] = useMutation(ADD_RATING)
  const [editRating] = useMutation(EDIT_RATING)

  useEffect(() => {
    if (prevData && prevData.userRatings) {
      const userRating = prevData.userRatings[0]
      if (userRating) {
        setEditing(true)
        const { comment, ratings } = userRating
        setRatingValues({
          comunication: ratings[0],
          times: ratings[1],
          service: ratings[2],
          recommend: ratings[3],
          comment
        })
      }
    }
  }, [prevData])

  const saveRatings = () => {
    setSending(true)
    const ratingObj = {
      companyId,
      userId,
      ratings: [
        parseInt(ratingValues.comunication),
        parseInt(ratingValues.times),
        parseInt(ratingValues.service),
        parseInt(ratingValues.recommend)
      ],
      comment: ratingValues.comment
    }
    if (editing) {
      // Edit Rating
      editRating({
        variables: { ...ratingObj, date: new Date() }
      })
        .then(() => {
          showSnackbar({
            message: t('commentSentSuccesfully'),
            severity: 'success'
          })
          setSending(false)
          onCancel()
        })
        .catch((err) => {
          setSending(false)
          showSnackbar({
            message: t('somethingWentWrong'),
            severity: 'error'
          })
          return err
        })
    } else {
      // Create a new Rating
      addRating({ variables: ratingObj })
        .then(() => {
          showSnackbar({
            message: t('commentSentSuccesfully'),
            severity: 'success'
          })
          setSending(false)
          onCancel()
        })
        .catch((err) => {
          setSending(false)
          showSnackbar({
            message: t('somethingWentWrong'),
            severity: 'error'
          })
          return err
        })
    }
  }
  const handleCancel = () => {
    setRatingValues(defaultValues)
    onCancel()
  }

  const handleInputChange = ({ comment }) => {
    setRatingValues({
      ...ratingValues,
      comment
    })
  }

  const handleRatingChange = (event, newValue) => {
    const { name } = event.target
    setRatingValues({ ...ratingValues, [name]: newValue })
  }

  const ValorationRow = ({ title, name }) => (
    <Box
      display='flex'
      justifyContent='space-between'
      borderColor='transparent'
      mb={1}
    >
      <Typography>{title}</Typography>
      <Rating
        name={name}
        value={ratingValues[name]}
        onChange={handleRatingChange}
      />
    </Box>
  )

  return (
    <motion.div
      transition={{
        x: { type: 'spring', stiffness: 400, damping: 30 },
        opacity: { duration: 0.2 }
      }}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
    >
      {editing && (
        <Alert severity='info' sx={{ mb: 2 }}>
          {t('editingRating')}
        </Alert>
      )}
      <ValorationRow title={t('comunication')} name='comunication' />
      <ValorationRow title={t('times')} name='times' />
      <ValorationRow title={t('asDescribed')} name='service' />
      <ValorationRow title={t('recommend')} name='recommend' />
      <Box sx={{ my: 2 }}>
        <MultilineInput
          id='comment'
          onChange={handleInputChange}
          label={t('leaveAComment')}
          value={ratingValues.comment}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          text={t('cancel')}
          variant='light'
          onClick={handleCancel}
          disabled={sending}
        />
        <Button
          text={editing ? t('edit') : t('send')}
          onClick={saveRatings}
          loading={sending}
        />
      </Box>
    </motion.div>
  )
}
