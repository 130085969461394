import { useUser } from 'context/UserContext'
import { GuestUserAccount, LoggedUser } from 'views'

export default function Account() {
  const { user } = useUser()

  if (user?.role && user.role !== 'guest') {
    return <LoggedUser user={user} />
  } else {
    return <GuestUserAccount />
  }
}
