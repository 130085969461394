import React, { cloneElement, useEffect, useState } from 'react'
import {
  AppBar as MUIAppBar,
  Toolbar,
  Typography,
  Slide,
  IconButton
} from '@mui/material'
import { useTheme } from '@mui/styles'
import { useScrollTrigger } from 'hooks'
import { ArrowBack } from 'assets/Icons'
import { useHistory } from 'react-router-dom'

function ElevationScroll({ containerRef, children }) {
  const { trigger, onTop } = useScrollTrigger({
    target: containerRef,
    threshold: 100
  })
  const childrenArray = React.Children.toArray(children)
  const theme = useTheme()

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {cloneElement(children, {
        className: onTop ? 'ontop' : '',
        sx: {
          ...childrenArray[0].props.sx,
          bgcolor: !onTop
            ? theme.palette.background.contrast
            : theme.palette.background.default
        }
      })}
    </Slide>
  )
}

export default function AppBar({
  title,
  actions,
  hasBack,
  customGoBack,
  containerRef,
  children,
  sx
}) {
  const history = useHistory()
  const [container, setContainer] = useState(null)
  const childrenArray = React.Children.toArray(children)

  useEffect(() => {
    if (containerRef) {
      setContainer(containerRef.current)
    }
  }, [containerRef])

  const handleGoBack = () => {
    if (customGoBack) {
      return customGoBack()
    }

    history.goBack()
  }

  return (
    <ElevationScroll containerRef={container}>
      <MUIAppBar
        elevation={0}
        position='absolute'
        color='inherit'
        sx={{
          transition:
            'background-color 300ms ease-in-out, transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important',
          ...sx
        }}
      >
        {(title || actions || hasBack) && (
          <Toolbar
            sx={{ '&.MuiToolbar-root': { minHeight: 64, px: 2, gap: 1 } }}
          >
            {hasBack && (
              <IconButton onClick={handleGoBack}>
                <ArrowBack fontSize='small' />
              </IconButton>
            )}
            <Typography id='title' fontSize={18} sx={{ flexGrow: 1 }} noWrap>
              {title}
            </Typography>
            {actions}
          </Toolbar>
        )}
        {children &&
          childrenArray.map((child, index) => (
            <React.Fragment key={`toolbar-${index}`}>
              {index === 0 && !title ? (
                <Toolbar sx={{ '&.MuiToolbar-root': { minHeight: 64, px: 2 } }}>
                  {child}
                </Toolbar>
              ) : (
                <Toolbar
                  sx={{ '&.MuiToolbar-root': { minHeight: 0, px: 2, pb: 1 } }}
                >
                  {child}
                </Toolbar>
              )}
            </React.Fragment>
          ))}
      </MUIAppBar>
    </ElevationScroll>
  )
}
