export default async function deviceInfo() {
  const userAgent = navigator.userAgent

  const oscpu = navigator.oscpu

  const platform = (function () {
    if (userAgent.indexOf('Win') !== -1) return 'Windows'
    if (userAgent.indexOf('Mac') !== -1) return 'Mac'
    if (userAgent.indexOf('Linux') !== -1) return 'Linux'
    if (oscpu.indexOf('Win') !== -1) return 'Windows'
    if (oscpu.indexOf('Mac') !== -1) return 'Mac'
    if (oscpu.indexOf('Linux') !== -1) return 'Linux'
    return 'unknown'
  })()

  const browser = (function () {
    if (userAgent.indexOf('Edge') !== -1) return 'Edge'
    if (userAgent.indexOf('Chrome') !== -1) return 'Chrome'
    if (userAgent.indexOf('Firefox') !== -1) return 'Firefox'
    if (userAgent.indexOf('Safari') !== -1) return 'Safari'
    if (userAgent.indexOf('Trident') !== -1) return 'Internet Explorer'
  })()

  let ip
  try {
    const response = await fetch('https://api.ipify.org?format=json')
    const data = await response.json()
    ip = data.ip
  } catch (error) {
    ip = 'unknown'
  }

  return {
    ip,
    platform,
    browser
  }
}
