import { Box, Typography } from '@mui/material'
import { useI18N } from 'hooks'

export default function UpdateTag({ type }) {
  const { t } = useI18N()
  return (
    <Box
      id='tag'
      sx={{
        bgcolor: type === 'fix' ? '#ff9902' : 'success.main',
        py: 0.25,
        px: 1,
        mb: 1,
        borderRadius: 10,
        width: 'fit-content'
      }}
    >
      <Typography
        textAlign='center'
        fontSize={12}
        sx={{
          color: 'background.paper',
          textTransform: 'capitalize'
        }}
      >
        {t(type)}
      </Typography>
    </Box>
  )
}
