import {
  ScrollableContainer,
  SearchBar,
  IconsSwitch,
  Spacer,
  OffersList
} from 'components'
import {
  ArchiveBox,
  Briefcase,
  BrifecaseCross,
  BrifecaseTick
} from 'iconsax-react'
import { useI18N, useSearchParams, useSettings } from 'hooks'
import { OFFER_STATES } from 'constants/Enums'
import { useHistory } from 'react-router-dom'

const OFFER_STATES_VALUES = Object.values(OFFER_STATES)

const offerSwitchOptions = (t) => [
  {
    id: 0,
    icon: <BrifecaseTick />,
    label: t('pluralAccepted')
  },
  {
    id: 1,
    icon: <Briefcase />,
    label: t('negotiating')
  },
  {
    id: 2,
    icon: <BrifecaseCross />,
    label: t('rejected')
  },
  {
    id: 3,
    icon: <ArchiveBox />,
    label: t('archived')
  }
]

export default function Offers() {
  const { t } = useI18N()
  const { search } = useSearchParams('search')
  const history = useHistory()
  const {
    settings: { board },
    updateSettings
  } = useSettings()

  const handleChange = (newValue) => {
    updateSettings({ board: newValue })
  }

  const handleSearch = (search) => {
    const pathname = history.location.pathname
    if (search.length > 0) {
      history.push(`${pathname}?search=${search}`)
    } else {
      history.push(pathname)
    }
  }

  return (
    <ScrollableContainer
      toolBarChildren={
        <>
          <SearchBar
            onEnter={handleSearch}
            placeholder={t('search')}
            defaultValue={search}
            onClean={() => handleSearch('')}
          />
          <IconsSwitch
            items={offerSwitchOptions(t)}
            defaultSelected={board}
            onChange={handleChange}
          />
        </>
      }
    >
      <Spacer x={2}>
        <OffersList
          state={OFFER_STATES_VALUES[board] ?? OFFER_STATES_VALUES[0]}
        />
      </Spacer>
    </ScrollableContainer>
  )
}
