import texts from './texts'

export const legalForms = [
  {
    id: 'freelance',
    name: texts().freelance
  },
  {
    id: 'civilSociety',
    name: texts().civilSociety
  },
  {
    id: 'collectiveSociety',
    name: texts().collectiveSociety
  },
  {
    id: 'limitedLiabilityCompany',
    name: texts().limitedLiabilityCompany
  },
  {
    id: 'limitedSociety',
    name: texts().limitedSociety
  },
  {
    id: 'anonymousSociety',
    name: texts().anonymousSociety
  },
  {
    id: 'cooperativeSociety',
    name: texts().cooperativeSociety
  }
]
