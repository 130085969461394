import ReactDOM from 'react-dom'
import { Box } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'

const variants = {
  center: {
    enter: {
      scale: 0,
      opacity: 0
    },
    visible: {
      scale: 1,
      opacity: 1
    },
    exit: {
      scale: 0,
      opacity: 0
    }
  },
  bottom: {
    enter: {
      y: '100%'
    },
    visible: {
      y: 0
    },
    exit: {
      y: '100%'
    }
  }
}

const backdropVariants = {
  enter: {
    opacity: 0
  },
  visible: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
}

function ModalPortal({ children }) {
  return ReactDOM.createPortal(
    children,
    document.getElementById('main-container')
  )
}

const Modal = ({ open, anchor = 'center', onClose, children }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  if (!mounted) return null

  return (
    <ModalPortal>
      <AnimatePresence>
        {open && (
          <Box
            aria-label='modal-container'
            sx={{
              position: 'absolute',
              inset: 0,
              zIndex: 'drawer',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Box
              key='backdrop'
              as={motion.div}
              variants={backdropVariants}
              initial='enter'
              animate='visible'
              exit='exit'
              transition={{ duration: 0.2 }}
              onClick={onClose}
              aria-label='backdrop'
              sx={{
                position: 'absolute',
                inset: 0,
                bgcolor: 'rgba(0,0,0,0.3)',
                zIndex: -1,
                opacity: 0,
                backdropFilter: 'blur(2px)'
              }}
            />
            <Box
              key='modal'
              as={motion.div}
              aria-label='modal'
              variants={variants[anchor]}
              initial='enter'
              animate='visible'
              exit='exit'
              transition={{ duration: 0.25, ease: [0, 0, 0.2, 1] }}
              sx={{
                position: 'absolute',
                maxHeight: '100%',
                width: '100%',
                zIndex: 'modal',
                display: 'flex',
                ...(anchor === 'bottom' && {
                  bottom: 0
                }),
                ...(anchor === 'center' && {
                  position: 'relative',
                  height: 'fit-content'
                })
              }}
            >
              {children}
            </Box>
          </Box>
        )}
      </AnimatePresence>
    </ModalPortal>
  )
}

export default Modal
