import { useState } from 'react'
import { useFilters, useI18N } from 'hooks'
import { Grid, Typography } from '@mui/material'
import {
  BottomDrawer,
  Button,
  SelectableInput,
  RangeSlider,
  CategoriesSelector
} from 'components'
import { provincesList } from 'constants/Provinces'
import { useSnackbar } from 'context/SnackBarContext'

const budgetMarks = [
  {
    value: 0,
    label: '+0€'
  },
  {
    value: 2500,
    label: '+2.500€'
  },
  {
    value: 5000,
    label: '+5.000€'
  }
]

export default function DiscoverFilter({ onClose, onSave }) {
  const { t } = useI18N()
  const { showSnackbar } = useSnackbar()
  const { filter, companiesIsSelected, updateFilters, defaultFilters } =
    useFilters()
  const [values, setValues] = useState({ ...filter })

  const handleChange = (newValue) => {
    setValues((prev) => ({ ...prev, ...newValue }))
  }

  const handleSaveChanges = () => {
    if (values.categories.length === 0) {
      showSnackbar({
        message: t('youMustSelectAtLeastOneSubcategory'),
        marginBottom: true
      })
      return
    }
    updateFilters(values)
    onSave(values)
  }

  const handleResetFilters = () => {
    const defaultFiltersValues = defaultFilters()
    setValues(defaultFiltersValues)
  }

  return (
    <BottomDrawer
      title={
        <Typography align='center' fontSize='1.2rem'>
          {t('filters')}
        </Typography>
      }
      closeButton
      onClose={onClose}
      actions={
        <>
          <Button
            onClick={handleResetFilters}
            text={t('reset')}
            fullWidth
            variant='light'
          />
          <Button onClick={handleSaveChanges} text={t('save')} fullWidth />
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectableInput
            id='province'
            label={t('province')}
            listContent='array'
            items={provincesList('ES')}
            value={values.province || provincesList('ES')[0]}
            required
            onChange={({ province }) => {
              if (province === provincesList('ES')[0])
                return handleChange({ province: null })
              handleChange({ province })
            }}
          />
        </Grid>
        {!companiesIsSelected && (
          <Grid item xs={12}>
            <RangeSlider
              inputTitle={t('budgetFilter')}
              inputId='budget'
              maxValue={budgetMarks[budgetMarks.length - 1].value}
              minValue={0}
              value={values.budget}
              steps={100}
              onChange={handleChange}
              marks={budgetMarks}
              valueLabelFormat={(x) => `+${x}€`}
              sx={{ maxWidth: '80%' }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CategoriesSelector
            multiple
            value={values.categories}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </BottomDrawer>
  )
}
