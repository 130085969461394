import { useContext } from 'react'
import { I18NContext } from 'context/i18n'

export default function useI18N() {
  const context = useContext(I18NContext)
  if (context === undefined) {
    throw new Error('useI18N must be used within a I18NProvider')
  }
  return context
}
