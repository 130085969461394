import { useEffect, useState } from 'react'
import { useScrollTrigger as useMuiScrollTrigger } from '@mui/material'

const useScrollTrigger = ({
  target,
  threshold = 16,
  disableHysteresis = false
}) => {
  const [onTop, setOnTop] = useState(true)

  const trigger = useMuiScrollTrigger({
    disableHysteresis,
    threshold,
    target: target || undefined
  })

  useEffect(() => {
    const hanldeScroll = () => {
      if (!target) return
      setOnTop(target.scrollTop < threshold)
    }

    hanldeScroll()

    if (target) {
      target.addEventListener('scroll', hanldeScroll)
    }

    return () =>
      target?.removeEventListener('scroll', hanldeScroll, {
        passive: true
      })
  }, [target, trigger])

  return { trigger, onTop }
}

export default useScrollTrigger
