import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { PicturesInput, UploadedPicture } from 'components'

/// ////////COMPONENT PROPS
PicturesInputContainer.propTypes = {
  imagesList: PropTypes.array,
  onDelete: PropTypes.func,
}

function PicturesInputContainer({ imagesList, onDelete, ...other }) {
  const [error, setError] = useState(null)
  const handleError = (err) => {
    setError(err)
  }

  const handleDelete = (imageName) => (e) => {
    e.preventDefault()
    onDelete(imageName)
  }

  return (
    <Box
      sx={{
        maxWidth: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      <PicturesInput onError={handleError} {...other} />
      {imagesList?.map((image, index) => (
        <UploadedPicture
          key={`img-${image.name}-${index}`}
          image={image.url}
          onDelete={handleDelete(image.name)}
        />
      ))}
      {error && (
        <Typography color="error" lineHeight={1.1} fontSize={'0.9rem'} sx={{ px: 1 }}>
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default React.memo(PicturesInputContainer)
