import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Input,
  StripeElements,
  CardField,
  ScrollableContainer,
  Spacer,
  Modal,
  Dialog
} from 'components'
import { Typography, Box, Grid } from '@mui/material'
import { routes } from 'constants/Routes'
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js'
import { useForm, useI18N, useUserInfo } from 'hooks'
import { useMutation } from '@apollo/client'
import { GET_USER } from 'apis/querys'
import { UPDATE_PLAN } from 'apis/mutations'
import {
  createCustomer,
  createSubscription,
  updateDefaultPaymentMethod
} from 'apis/stripe'
import { Danger, SecuritySafe } from 'iconsax-react'
import { validateAll, validateSwissVAT } from 'helpers/validateNif'
import { countries } from 'constants/Countries'

export default function CheckOut() {
  const history = useHistory()
  const { price, customer } = history.location.state ?? ''

  useEffect(() => {
    if (!price) {
      history.replace(routes.subscription)
    }
  }, [])

  if (!price) return null

  return (
    <StripeElements>
      <CheckOutForm price={price} customer={customer} />
    </StripeElements>
  )
}

const defaultValues = {
  email: '',
  nif: '',
  name: ''
}

const defaultState = {
  error: null,
  cardComplete: false,
  processing: false,
  disabled: true,
  succeded: false
}

const getTotal = (price) => {
  const taxesPerc = 7.7
  const subTotal = parseFloat(price / (taxesPerc / 100 + 1)).toFixed(2)
  const total = price
  const taxes = parseFloat((price - price / (taxesPerc / 100 + 1)).toFixed(2))
  return { total, subTotal, taxes }
}

const CheckOutForm = ({ price, customer }) => {
  const { t } = useI18N()
  const { user, loading } = useUserInfo()
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()
  const [state, setState] = useState(defaultState)
  const [subscription, setSubscription] = useState(0)
  const [taxes, setTaxes] = useState(0)
  const [total, setTotal] = useState(0)
  const [errorDialog, setErrorDialog] = useState(false)

  const [updatePlan] = useMutation(UPDATE_PLAN, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { userId: user.id, uid: user.uid }
      }
    ]
  })

  const validate = () => {
    const temp = { ...errors }

    const nifValidations = {
      [countries.Spain]: validateAll(values.nif),
      [countries.Swiss]: validateSwissVAT(values.nif)
    }

    temp.email = /[^@]+@[^@]+\.[^@]+/.test(String(values.email).toLowerCase())
      ? ''
      : t('signUpEmailError')
    temp.nif = nifValidations[user.country] ? '' : t('nifError')
    temp.name = values.name ? '' : t('required')

    setErrors({
      ...temp
    })
    return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    defaultValues,
    false,
    validate
  )

  useEffect(() => {
    const unit_amount = price.unit_amount / 100
    const { subTotal, total, taxes } = getTotal(unit_amount)
    setTaxes(taxes)
    setTotal(total)
    setSubscription(subTotal)
  }, [])

  useEffect(() => {
    if (!loading && user) {
      setValues({ ...values, email: user.email, nif: user.company_data.nif })
    }
  }, [loading, user])

  useEffect(() => {
    if (validate() && state.error === '' && state.cardComplete) {
      setState((prev) => ({ ...prev, disabled: false }))
    } else {
      setState((prev) => ({ ...prev, disabled: true }))
    }
  }, [values, state.cardComplete])

  const handleCardChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setState({
      ...state,
      error: event.error ? event.error.message : '',
      cardComplete: event.complete
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setState((prev) => ({ ...prev, processing: true }))

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setState((prev) => ({ ...prev, processing: false }))
      return
    }

    if (state.error) {
      elements.getElement('card').focus()
      setState((prev) => ({ ...prev, processing: false }))
      return
    }

    const customerData = customer ?? (await createCustomer(values))
    const subscription = await createSubscription(
      price.id,
      customerData.id,
      price.currency
    )
    confirmPayment({ customerId: customerData.id, subscription })
  }

  const confirmPayment = async ({ customerId, subscription }) => {
    const payload = await stripe.confirmCardPayment(subscription.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: values.email,
          name: values.name
        }
      }
    })

    if (payload.error) {
      setState((prev) => ({
        ...prev,
        error: payload.error.message,
        processing: false
      }))
      setErrorDialog(true)
      // console.log(payload.error.message);
    } else {
      updatePlan({
        variables: {
          user_id: user.id,
          customer_id: customerId,
          payment_method_id: payload.paymentIntent.payment_method,
          plan_id: subscription.subscriptionId,
          trial: false
        }
      })
        .then(() => updateDefaultPayment(customerId, payload))
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const updateDefaultPayment = async (customerId, payload) => {
    const customer = await updateDefaultPaymentMethod(
      customerId,
      payload.paymentIntent.payment_method
    )
    if (customer) {
      setState((prev) => ({
        ...prev,
        error: null,
        processing: false,
        succeded: true
      }))

      history.replace(routes.subscription, {
        fromCheckOut: true
      })
    }
  }

  const handleComeBack = () => {
    setErrorDialog(false)
  }

  return (
    <ScrollableContainer title={t('checkout')} appBarProps={{ hasBack: true }}>
      <Spacer m={2}>
        <Typography fontSize='1.2rem'>{t('finishCheckOut')}</Typography>
        <Grid aria-label='checkout-form' container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12}>
            <Input
              type='email'
              id='email'
              label={t('email')}
              onChange={handleInputChange}
              value={values.email}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id='nif'
              value={values.nif}
              onChange={handleInputChange}
              label={t('nif')}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id='name'
              value={values.name}
              onChange={handleInputChange}
              label={t('checkoutName')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CardField id='card_number' onChange={handleCardChange} />
          </Grid>
        </Grid>

        <Box
          aria-label='sumary-container'
          sx={{ bgcolor: 'action.hover', p: 2, borderRadius: 4 }}
        >
          <Typography fontWeight={500} fontSize={18} mb={2}>
            {t('yourOrder')}:
          </Typography>
          <Box display='flex' justifyContent='space-between' mb={1}>
            <Typography color='textSecondary'>{t('subTotal')}</Typography>
            <Typography>{`${subscription} ${price.currency}`}</Typography>
          </Box>
          <Box display='flex' justifyContent='space-between' mb={1}>
            <Typography color='textSecondary'>{t('taxes')}</Typography>
            <Typography>{`${taxes} ${price.currency}`}</Typography>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Typography fontWeight='bold'>{t('total')}</Typography>
            <Typography
              fontWeight={500}
            >{`${total} ${price.currency}`}</Typography>
          </Box>
          <Typography mt={2} color='textSecondary' variant='body2'>
            {price.recurring
              ? price.recurring?.interval === 'year'
                ? t('annualDescription')
                : t('monthlyDescription')
              : t('uniquePaymentDescription')}
          </Typography>
        </Box>

        <Box aria-label='secure-payment-badge' sx={{ mb: 3 }}>
          <Box
            sx={{
              color: 'text.secondary',
              display: 'flex',
              gap: 1,
              mt: 2,
              mb: 1,
              justifyContent: 'center'
            }}
          >
            <SecuritySafe variant='Bulk' />
            <Typography>
              {`${t('securePayment')} `}
              <Typography component='span' fontWeight='bold'>
                {t('safeAndSecure')}
              </Typography>
              {` ${t('garanteed')}`}
            </Typography>
          </Box>
          <Box
            component='img'
            sx={{ maxWidth: 140, mx: 'auto', display: 'block' }}
            src='https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg'
          />
        </Box>
        <Button
          text={state.processing ? t('processing') : t('subscribe')}
          loading={state.processing}
          onClick={handleSubmit}
          fullWidth
          disabled={
            !stripe || state.processing || state.disabled || state.succeded
          }
        />
      </Spacer>

      <Modal open={errorDialog} onClose={handleComeBack}>
        <Dialog>
          <Dialog.Title title={t('somethingWentWrong')} />
          <Dialog.Content>
            <>
              <Box component='span' sx={{ color: 'error.main' }}>
                <Danger size={90} variant='Bulk' />
              </Box>
              <Typography>{t('thePaymentCouldNotBeDone')}</Typography>
            </>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={handleComeBack} text={t('comeBack')} fullWidth />
          </Dialog.Actions>
        </Dialog>
      </Modal>
    </ScrollableContainer>
  )
}
