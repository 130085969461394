import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardActionArea, Typography, Box, Stack } from '@mui/material'
import { Star } from 'assets/Icons'

export default function BestCompanyCard({ data, onClick }) {
  return (
    <Card
      elevation={0}
      sx={{
        minWidth: '13.5rem',
        width: '13.5rem',
        borderRadius: 4,
        bgcolor: `#${data.color}`,
        position: 'relative',
        '&:hover': {
          boxShadow: `0 2px 10px #${data.color}80`
        }
      }}
    >
      <CardActionArea
        onClick={onClick}
        sx={{ height: '100%' }}
        TouchRippleProps={{
          sx: {
            color: '#ffffff80'
          }
        }}
      >
        <CardContent
          sx={{
            position: 'relative',
            zIndex: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight: '5rem'
          }}
        >
          <Typography
            sx={{
              width: '70%',
              fontWeight: '500',
              fontSize: '1.125rem',
              textAlign: 'center',
              color: '#fff',
              zIndex: 1,
              position: 'relative',
              lineHeight: 1.2,
              mb: 2
            }}
          >
            {data.title}
          </Typography>
          <Box
            sx={{
              backgroundImage: `url(${data.logo})`,
              bgcolor: '#ffffff80',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: '0.75rem',
              border: '2px solid #fff',
              height: '5.3rem',
              width: '5.3rem',
              minWidth: '5.3rem',
              mb: 1
            }}
          />
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '1rem',
              textAlign: 'center',
              color: '#fff',
              zIndex: 1,
              position: 'relative',
              lineHeight: 1.2
            }}
          >
            {data.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '0.875rem',
              textAlign: 'center',
              color: '#fff',
              zIndex: 1,
              position: 'relative'
            }}
          >
            {data.province}
          </Typography>
          <Box sx={{ flex: 1 }} />
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{
              mt: 1,
              p: '0.3rem 0.6rem',
              bgcolor: 'background.card',
              borderRadius: '4rem',
              minWidth: 80
            }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '1rem',
                color: 'text.primary'
              }}
            >
              {data.average}
              <Typography
                as='span'
                sx={{
                  fontSize: '0.875rem',
                  color: '#A5A5A5'
                }}
              >
                /5
              </Typography>
            </Typography>
            <Box sx={{ ml: 0.5 }}>
              <Star filled size={20} />
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
      <Box
        element='span'
        sx={{
          position: 'absolute',
          height: '10rem',
          width: '10rem',
          right: -8,
          bottom: -8,
          backgroundImage: `url(${data.icon})`,
          backgroundSize: 'cover',
          zIndex: 0
        }}
      />
    </Card>
  )
}
