import React from 'react'

export default function useLazyEffect(effect, deps, customCondition) {
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    let effectReturns = () => {}

    if (loaded || customCondition) {
      effectReturns = effect()
    } else {
      setLoaded(true)
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns
    }

  }, deps)
}
