import React from 'react'
import PropTypes from 'prop-types'
import { Box, CardContent, Typography } from '@mui/material'
import { Button, CustomCard } from 'components'
import { useI18N } from 'hooks'

/// ////////COMPONENT PROPS
NoSubscription.propTypes = {
  onClick: PropTypes.func
}

export default function NoSubscription({ onClick }) {
  const { t } = useI18N()

  return (
    <CustomCard title={t('yourSubscriptionPlan')}>
      <CardContent
        sx={{
          p: 2,
          '&:last-child': {
            pb: 2
          }
        }}
      >
        <Typography
          align='center'
          fontSize={20}
          lineHeight={1.3}
          marginBottom={2}
        >
          {t('noActiveSubscription')}
        </Typography>
        <Typography align='center' color='textSecondary'>
          {t('subscribeToAPlan')}
        </Typography>
        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
          <Button text={t('seePlans')} variant='light' onClick={onClick} />
        </Box>
      </CardContent>
    </CustomCard>
  )
}
