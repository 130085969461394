import { useEffect, useMemo } from 'react'
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { Dislike, Like1 } from 'iconsax-react'
import { questions as questionsDB } from 'constants/Questions'
import { useI18N } from 'hooks'

export default function QuestionsSelectorPage({
  values = [],
  category,
  onChange
}) {
  const { t } = useI18N()
  const categoryQuestions = useMemo(
    () =>
      questionsDB.filter((question) => question.categories.includes(category)),
    [category]
  )

  useEffect(() => {
    if (!values.length && categoryQuestions.length) {
      const initialValues = categoryQuestions.map((q) => ({
        id: q.id,
        answer: 'true',
        main: q.main
      }))
      onChange({ questions: initialValues })
    }
  }, [categoryQuestions])

  const handleChange = (newValue) => {
    const currentQuestions = values.splice(0)
    const index = currentQuestions.findIndex((q) => q.id === newValue.id)
    if (index > -1) {
      currentQuestions.splice(index, 1, newValue)
      onChange({ questions: currentQuestions })
    }
  }

  return (
    <>
      <Typography fontSize='1.5rem' mb={2}>
        {t('tellUsYourPreferences')}
      </Typography>
      <Grid container spacing={2}>
        {categoryQuestions.map(({ id, question, main }) => (
          <Grid key={id} item xs={12}>
            <Typography fontSize={18} mb={2}>
              {question}
            </Typography>
            <AnswerButtons
              answer={values.find((q) => q.id === id)?.answer ?? 'true'}
              onChange={(answer) => handleChange({ id, answer, main })}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

const AnswerButtons = ({ answer, onChange }) => (
  <Stack direction='row' gap='1rem'>
    <IconButton
      onClick={() => onChange('true')}
      className={`${answer === 'true' && 'active'}`}
      sx={{
        transition: 'all 200ms ease',
        padding: 2,
        color: 'text.secondary',
        border: '1px solid',
        borderColor: 'text.secondary',
        borderRadius: 4,

        '&.active': {
          border: '1px solid #39BE6E1a',
          bgcolor: '#39BE6E1a',
          color: '#39BE6E'
        }
      }}
    >
      <Like1 />
    </IconButton>

    <IconButton
      onClick={() => onChange('false')}
      className={`${answer === 'false' && 'active'}`}
      sx={{
        transition: 'all 200ms ease',
        padding: 2,
        color: 'text.secondary',
        border: '1px solid',
        borderColor: 'text.secondary',
        borderRadius: 4,

        '&.active': {
          border: '1px solid #f25b5b1a',
          bgcolor: '#f25b5b1a',
          color: '#F25B5B'
        }
      }}
    >
      <Dislike />
    </IconButton>
  </Stack>
)
