import React from 'react'
import { Input } from '@mui/material'
import { useI18N } from 'hooks'

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return bytes

  const k = 1048576
  const dm = decimals < 0 ? 0 : decimals

  return parseFloat((bytes / k).toFixed(dm))
}

export default function FileInput({
  id,
  value,
  multiple,
  maxSize,
  maxFiles,
  accept,
  onChange,
  onError,
  children,
  ...other
}) {
  const { t } = useI18N()
  const handleChange = (event) => {
    const { name, files } = event.target
    const filesData = []
    const prevFiles = value || []
    const lengthLeft = maxFiles ? maxFiles - prevFiles.length : files.length
    const maxLenght = files.length > lengthLeft ? lengthLeft : files.length

    for (let i = 0; i < maxLenght; i++) {
      const file = files[i]
      const extension = file.type
      const size = formatBytes(file.size, 2)
      const fileName = file.name

      let error = null

      const acceptedType = accept.split(',').some((r) => {
        const type = r.replace('.', '')
        return extension.includes(type)
      })

      if (Array.isArray(prevFiles)) {
        const prevFilesNames = prevFiles.map((file) => file.name)
        if (prevFilesNames.includes(fileName)) {
          error = `${t('alreadyUploadThisFile')}`
        }
      }

      if (maxSize && size > maxSize) {
        error = `${t('maximumSizeExceded')} ${maxSize}MB`
        onError(error)
        return
      }

      if (maxFiles && files.length > lengthLeft) {
        error = `${t('maximumNumberOfFiles')}, ${maxFiles}`
      }

      if (!acceptedType) {
        error = `${t('notAllowFile')} ${t('allowFiles')} ${accept
          .replace('application', '')
          .replace('/', '.')
          .replace(',', ', ')
          .replace('*', '')}`
        onError(error)
        return
      }

      if (error) onError(error)

      filesData.push({
        type: extension,
        name: fileName,
        url: URL.createObjectURL(file),
        file
      })
    }

    if (multiple) {
      const filesArray = prevFiles.concat(filesData)
      onChange({ [name]: filesArray })
    } else {
      onChange({ [name]: filesData[0] })
    }
  }

  return (
    <label htmlFor={id}>
      <Input
        id={id}
        name={id}
        inputProps={{ accept }}
        multiple={multiple}
        type='file'
        onChange={handleChange}
        sx={{ display: 'none' }}
        {...other}
      />
      {children}
    </label>
  )
}
