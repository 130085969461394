import React from 'react'
import { Menu, MenuItem, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'

export default function MoreMenu({ actionList, onItemClick, ...props }) {
  return (
    <Menu
      {...props}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        '.MuiPaper-root': {
          boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
          borderRadius: 4,
        },
      }}
    >
      <MenuItemsList list={actionList} onClick={onItemClick} />
    </Menu>
  )
}

const MenuItemsList = ({ list, onClick }) =>
  list.map(({ id, title }) => (
    <MenuItem
      key={`menu-item-${id}`}
      onClick={() => onClick(id)}
      TouchRippleProps={{
        sx: {
          color: (theme) => alpha(theme.palette.text.primary, 0.2),
        },
      }}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  ))
