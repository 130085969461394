import { useEffect, useState } from 'react'
import { Box, CardContent, Collapse, Grid, Typography } from '@mui/material'
import {
  CustomCard,
  Spacer,
  SearchBar,
  CustomChip,
  InfoMessage
} from 'components'
import { blueGrey } from '@mui/material/colors'
import { useI18N, useThemeMode } from 'hooks'
import {
  categories as categoriesData,
  subcategoriesParents
} from 'constants/CategoriesData'

export default function CategorySelectorPage({ category, onChange }) {
  const { t } = useI18N()
  const [search, setSearch] = useState('')
  const [categoryFilter, setCategoryFilter] = useState([])
  const [alert, setAlert] = useState(true)
  const { darkMode } = useThemeMode()

  useEffect(() => {
    if (category) {
      setCategoryFilter(subcategoriesParents([category]))
    }
  }, [category])

  const subCategoriesList = categoriesData
    .filter((category) => categoryFilter?.includes(category.id))
    .reduce((prev, current) => {
      const total = prev.concat(current.subCategories)
      return total
    }, [])

  const handleFilter = (value) => {
    setCategoryFilter([value])
    onChange({ category: '' })
    setAlert(false)
  }

  const handleChange = (value) => {
    onChange({ category: value })
    setAlert(false)
  }

  const handleCloseAlert = () => {
    setAlert(false)
  }

  const filteredList = search
    ? subCategoriesList.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
    : subCategoriesList

  return (
    <>
      {alert && categoryFilter.length > 0 && (
        <Spacer mb={2}>
          <InfoMessage
            text={
              category === 'otherJob'
                ? t('noCategoryFound')
                : t('weHaveSelectedTheNextCategory')
            }
            variant={category === 'otherJob' ? 'warning' : 'success'}
            onClose={handleCloseAlert}
          />
        </Spacer>
      )}
      <Typography fontSize='1.5rem' mb={2}>
        {t('maincategory')}
      </Typography>
      <Grid container spacing={2}>
        {categoriesData?.map((category) => {
          const isSelected = categoryFilter?.includes(category.id)

          return (
            <Grid key={category.id} item xs={6}>
              <CustomCard
                sx={{
                  bgcolor: isSelected
                    ? `#${category.color}`
                    : 'background.default',
                  '&:hover': {
                    bgcolor: isSelected ? `#${category.color}` : 'action.hover'
                  }
                }}
                onClick={() => handleFilter(category.id)}
              >
                <CardContent sx={{ minHeight: 80, '&:last-child': { pb: 2 } }}>
                  <Typography
                    sx={{
                      position: 'relative',
                      zIndex: 1,
                      fontWeight: 600,
                      color: isSelected
                        ? 'whitesmoke'
                        : darkMode
                        ? 'text.secondary'
                        : blueGrey[400]
                    }}
                    fontSize={16}
                  >
                    {category.name}
                  </Typography>
                  <Box
                    element='span'
                    sx={{
                      transition: 'all 0.2s ease-out',
                      position: 'absolute',
                      height: '100%',
                      width: '50%',
                      bottom: 0,
                      right: 0,
                      backgroundImage: `url(${category.image})`,
                      backgroundSize: 'cover',
                      zIndex: 0,
                      filter: isSelected || darkMode ? 'none' : 'invert(1)',
                      opacity: isSelected ? 0.7 : 0.3
                    }}
                  />
                </CardContent>
              </CustomCard>
            </Grid>
          )
        })}
      </Grid>

      {/* Subcategories */}
      <Spacer y={1}>
        <Spacer
          y={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography fontSize='1.5rem'>{t('subCategory')}</Typography>
        </Spacer>
        <Collapse in={Boolean(category)} unmountOnExit>
          <Box mb={2} sx={{ maxWidth: 'fit-content' }}>
            <CustomChip
              label={t(category)}
              selected
              onClick={() => onChange({ category: '' })}
            />
          </Box>
        </Collapse>

        <SearchBar
          onChange={setSearch}
          placeholder={t('search')}
          onClean={() => setSearch('')}
        />
        <Box
          sx={{
            display: 'flex',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            gap: 1,
            maxWidth: '100vw',
            minHeight: 300,
            mt: 2
          }}
        >
          {filteredList?.map((subcategory) => {
            const isSelected = [category].includes(subcategory.id)
            return (
              <CustomChip
                key={subcategory.id}
                label={subcategory.name}
                onClick={() => handleChange(subcategory.id)}
                selected={isSelected}
                sx={{
                  bgcolor: isSelected ? 'action.hover' : 'inherit',
                  color: isSelected ? 'text.secondary' : 'text.primary'
                }}
              />
            )
          })}
        </Box>
      </Spacer>
    </>
  )
}
