export default function NothingAsigned(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 750 500' {...props}>
      <path
        d='M137.79 472.38h476.73c7.83-9.35 53.34-68.58 32.94-158.57-22.27-98.29-35.38-99.6-133.67-161.2S381.43 38.6 268.72 80.53 152.08 201.1 157.33 264 90.49 375.4 86.56 414.72c-3.16 31.56 24.09 52.12 51.23 57.66Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M137.79 472.38h476.73c7.83-9.35 53.34-68.58 32.94-158.57-22.27-98.29-35.38-99.6-133.67-161.2S381.43 38.6 268.72 80.53 152.08 201.1 157.33 264 90.49 375.4 86.56 414.72c-3.16 31.56 24.09 52.12 51.23 57.66Z'
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001
        }}
      />
      <path
        d='M603.62 306.64s-12.29-6.83-13.2 8.64-3.19 116.1-3.19 116.1'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <path
        d='M584 316.65s10 22.31 5.47 113.36'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <path
        d='M593.89 309.75s-4.71 3-7.38-.43 1.81-9.77-2.14-12.47-10.78 1.42-10.78 1.42 5.25 1.54 5.08 4.41-2.7 4-3 2.7-2.85-7.57-7.12-4.94a10.58 10.58 0 0 0-4.91 6.7s9.93-1.06 10.53 2.68-4.07-.65-8.17-.89-7.84.36-7.57 2.84a32.69 32.69 0 0 1-.39 6.56 12.17 12.17 0 0 1 8-2.82c4.51.26 4.34 3.13.24 2.89s-7.62 3.66-7.62 3.66 3.74 6.39 8.49 9.55 6.46 2 7-.41-.72-8.68 2.15-8.51-4.75 10.83-1.09 11.46 6.61-.44 7.57-2.85l1-2.41s-1.28-6.24-.72-8.68 1.23.07 1.88 3 1.06 9.94 2.31 9.6 5.26-5.45 6.2-7.45-5.28-8.13-5.21-9.36 4.87 1.11 5.62 2.39a31.16 31.16 0 0 1 1.47 3s3-1.88 2.92-7.64-4.36-4-4.36-4Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M593.89 309.75s-4.71 3-7.38-.43 1.81-9.77-2.14-12.47-10.78 1.42-10.78 1.42 5.25 1.54 5.08 4.41-2.7 4-3 2.7-2.85-7.57-7.12-4.94a10.58 10.58 0 0 0-4.91 6.7s9.93-1.06 10.53 2.68-4.07-.65-8.17-.89-7.84.36-7.57 2.84a32.69 32.69 0 0 1-.39 6.56 12.17 12.17 0 0 1 8-2.82c4.51.26 4.34 3.13.24 2.89s-7.62 3.66-7.62 3.66 3.74 6.39 8.49 9.55 6.46 2 7-.41-.72-8.68 2.15-8.51-4.75 10.83-1.09 11.46 6.61-.44 7.57-2.85l1-2.41s-1.28-6.24-.72-8.68 1.23.07 1.88 3 1.06 9.94 2.31 9.6 5.26-5.45 6.2-7.45-5.28-8.13-5.21-9.36 4.87 1.11 5.62 2.39a31.16 31.16 0 0 1 1.47 3s3-1.88 2.92-7.64-4.36-4-4.36-4Z'
        style={{
          fill: '#263238',
          opacity: 0.30000000000000004
        }}
      />
      <path
        d='M570.05 323.26c3.07-3.79 7.58-8.92 11.37-11.37M566.49 327.88s.79-1.1 2.07-2.74M583.42 312.83s4.56 6.44 5.4 13.07M578.26 316.64s1.28-.74.8 7.45M581.59 309s2.6-2.32 1.23-6.92M578.07 312.93l-1.86-3.4M574.16 316.4s-5.9-4.46-9.59-4.68'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M610.65 297.6s-.19 6.76-5.47 7.09-8.54-8.46-13.94-6.35-5.92 11.76-5.92 11.76 5.12-4.21 7.88-2.08 2.12 5.4.63 4.88-9.52-2.31-9.79 3.75a12.8 12.8 0 0 0 3.35 9.49s5.71-10.66 9.87-8.72-3.43 3.63-6.47 7.57-5 8.09-2.31 9.52a39.58 39.58 0 0 1 6.3 4.86s-.72-5.58 2.62-9.91 6.09-2.21 3.06 1.73-1.53 10.12-1.53 10.12 8.94.62 15.34-2 6.44-5.09 4.38-7.31-9.18-5.19-7.06-7.95 7.6 12.14 10.73 8.9 4.06-6.91 2.31-9.52l-1.76-2.61s-7.12-3-9.18-5.19.91-1.19 4.28.15 10.66 5.71 11.18 4.23-1.87-9-3.23-11.28-11.74-.26-12.92-1.17 4.43-4.12 6.22-4 4 .55 4 .55.16-4.27-5.66-8.13-6.91 1.62-6.91 1.62Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M610.65 297.6s-.19 6.76-5.47 7.09-8.54-8.46-13.94-6.35-5.92 11.76-5.92 11.76 5.12-4.21 7.88-2.08 2.12 5.4.63 4.88-9.52-2.31-9.79 3.75a12.8 12.8 0 0 0 3.35 9.49s5.71-10.66 9.87-8.72-3.43 3.63-6.47 7.57-5 8.09-2.31 9.52a39.58 39.58 0 0 1 6.3 4.86s-.72-5.58 2.62-9.91 6.09-2.21 3.06 1.73-1.53 10.12-1.53 10.12 8.94.62 15.34-2 6.44-5.09 4.38-7.31-9.18-5.19-7.06-7.95 7.6 12.14 10.73 8.9 4.06-6.91 2.31-9.52l-1.76-2.61s-7.12-3-9.18-5.19.91-1.19 4.28.15 10.66 5.71 11.18 4.23-1.87-9-3.23-11.28-11.74-.26-12.92-1.17 4.43-4.12 6.22-4 4 .55 4 .55.16-4.27-5.66-8.13-6.91 1.62-6.91 1.62Z'
        style={{
          fill: '#263238',
          opacity: 0.2
        }}
      />
      <path
        d='M607.92 330.68c-1.7-5.66-3.76-13.68-3.62-19.14M610.12 337.38s-.56-1.53-1.33-3.93M606.6 310.18s9.55-.17 16.76 3.5M606.9 317.94s.12-1.78 8 4.29M601.54 309.42s-.54-4.19-6.09-5.95M603.05 315.61l-4.67-.46M603.86 321.88s-8.49 2.88-11.22 6.42'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M554 342.6s26.86 17.76 26.4 86.5'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <path
        d='M587.12 337.91s-.86 7.78-7 7.67-9.06-10.58-15.48-8.65-7.95 13-7.95 13 6.31-4.37 9.3-1.66 1.93 6.43.26 5.69-10.76-3.56-11.65 3.41a14.8 14.8 0 0 0 3 11.27s7.6-11.77 12.21-9.14-4.3 3.87-8.18 8.13-6.51 8.87-3.57 10.77a45.19 45.19 0 0 1 6.82 6.2s-.31-6.51 4-11.19 7.24-2 3.37 2.28-2.72 11.54-2.72 11.54 10.27 1.56 17.9-.84 7.9-5.26 5.73-8-10.1-6.86-7.39-9.84 7.62 14.72 11.54 11.28 5.34-7.59 3.56-10.77l-1.87-3.18s-7.93-4.11-10.1-6.86 1.16-1.28 4.92.58 11.77 7.6 12.5 5.93-1.32-10.53-2.63-13.32-13.52-1.4-14.8-2.56 5.51-4.34 7.56-4a44.47 44.47 0 0 1 4.53 1s.58-4.92-5.77-9.92-8.09 1.18-8.09 1.18Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M587.12 337.91s-.86 7.78-7 7.67-9.06-10.58-15.48-8.65-7.95 13-7.95 13 6.31-4.37 9.3-1.66 1.93 6.43.26 5.69-10.76-3.56-11.65 3.41a14.8 14.8 0 0 0 3 11.27s7.6-11.77 12.21-9.14-4.3 3.87-8.18 8.13-6.51 8.87-3.57 10.77a45.19 45.19 0 0 1 6.82 6.2s-.31-6.51 4-11.19 7.24-2 3.37 2.28-2.72 11.54-2.72 11.54 10.27 1.56 17.9-.84 7.9-5.26 5.73-8-10.1-6.86-7.39-9.84 7.62 14.72 11.54 11.28 5.34-7.59 3.56-10.77l-1.87-3.18s-7.93-4.11-10.1-6.86 1.16-1.28 4.92.58 11.77 7.6 12.5 5.93-1.32-10.53-2.63-13.32-13.52-1.4-14.8-2.56 5.51-4.34 7.56-4a44.47 44.47 0 0 1 4.53 1s.58-4.92-5.77-9.92-8.09 1.18-8.09 1.18Z'
        style={{
          fill: '#263238',
          opacity: 0.30000000000000004
        }}
      />
      <path
        d='M580.86 375.82c-1.44-6.68-3.05-16.13-2.39-22.42M582.76 383.76s-.5-1.82-1.17-4.66M581.26 352s11 .71 19 5.63M580.87 361s.31-2 8.83 5.7M575.49 350.69s-.23-4.88-6.46-7.44M576.65 357.97l-5.34-.97M577 365.29s-10.07 2.51-13.56 6.34'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M564.47 396.78s17.3 13.2 19.12 32.78L580 364M606.35 387.22c-22.76-4.1-22.76 42.34-22.76 42.34'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <path
        d='M586.32 429.1s-13.2-96.06 25-96.06'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <path
        d='M597.68 384.45c-3.11-3.49-9.72-3.49-13.6 5.45s-.39 7.38-.39 7.38 7.38-2.33 12-3.89 10.88-5.44 11.27-3.1-3.11 4.66-7.39 5.44-15.54 3.88-14.38 6.22 3.89 9.32 3.89 9.32a99.3 99.3 0 0 1 10.49-7.77c5.06-3.11 8.17-1.94 5.45 1.55s-10.5 7.39-8.94 12.05S607 427.6 607 427.6s1.16-9.72 3.89-12.83 2.72.39 1.94 5.83a63 63 0 0 0-.78 7l10.88 3.88a77.69 77.69 0 0 1 5.83-7.77l2-1.94s-8.55-7.39-8.94-12 5.83 0 7.77.78 5.05 6.21 5.05 6.21 1.95-8.93 2.34-12l.39-3.11s-8.17-3.11-11.28-3.11-4.66 2.33-4.66-.78 3.11-5.05 6.61-4.66a59.65 59.65 0 0 1 9.33 2.33s2.33-1.95 1.55-7.78-2.72-5.83-7.38-4.27-7.78 5-11.28 5-1.55-4.27.78-5.44 8.16-3.5 9.33-3.89-6.22-5.83-10.11-6.6-8.92 2-8.55 5.83c.74 8.12-10.92 9.72-14.03 6.17Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M622.7 424.47c.12 1.17.2 2.35.24 3.52M614.89 396.57a103 103 0 0 1 7.32 24.09M613.49 393.45c-1.92-4.14-3.37-6.66-3.37-6.66a11.5 11.5 0 0 0 5-4.67 13 13 0 0 1 3.89-4.66M596.51 400s14-5.83 14-4.66M618.28 392.62s6.22-3.11 11.66-3.11M616 402a53.94 53.94 0 0 0-7.39 7.38 33.6 33.6 0 0 0-5.44 8.94M620.22 403.5s8.17-2.33 12.44 1.55'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M635 351c-4.23-2.5-8.91-2.56-11.21-5.2s2.21-4 4.62-3 8 3.87 9.05 4.5.33-8.52-1.63-12-7.33-5.45-10-2.64c-5.65 5.94-14.47-1.85-13.87-6.49s-3.73-9.63-13-6.7-5.83 4.54-5.83 4.54 6.6 4.05 10.84 6.56 11.24 4.65 9.73 6.47-5.56.71-8.95-2-13.12-9.19-14.12-6.78-4.5 9-4.5 9a98.52 98.52 0 0 1 12.75 2.83c5.66 1.77 6.82 4.88 2.4 5.12s-12.46-3.08-15 1.15-.79 15.1-.79 15.1 8.1-5.49 12.23-5.47 1.49 2.31-3.13 5.29a64.56 64.56 0 0 0-5.79 4l4.2 10.77a76.38 76.38 0 0 1 9.69-.69l2.74.19s0-11.3 3.24-14.65 3.82 4.4 4.51 6.38-1.38 7.89-1.38 7.89 8-4.39 10.62-6.13l2.61-1.75s-3-8.2-5-10.55-4.81-2-2.47-4 5.86-1 7.86 1.93a60.64 60.64 0 0 1 4.35 8.57s3 .49 6.89-3.92 2.58-5.83-1.66-8.32Zm-36.78 12.44c-3.11-1.55 4.27-4.66 4.27-4.66 2.73-.41-1.16 6.22-4.26 4.64Zm5.23-14.51c-1.32-.66 2.65-2 2.65-2 5.76 1.07-1.31 2.65-2.64 1.98Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M598.14 371.34q-1.21 1.29-2.49 2.49M614.09 347.15a102.64 102.64 0 0 1-13.39 21.32M615.53 344.05c1.87-4.17 2.82-6.92 2.82-6.92a11.46 11.46 0 0 0 6.84.76 13 13 0 0 1 6.07-.12M612.15 343c.88-.76-12.69-7.51-12.69-7.51M619.3 347.12s6.43 2.66 10 6.77M610.73 351.47a54.41 54.41 0 0 0-10.42-.73 33.44 33.44 0 0 0-10.31 1.75M612.36 355.72s7.11 4.64 7 10.41'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M574.61 404.84c-3.4-2-7.17-2.06-9-4.19s1.77-3.2 3.71-2.39 6.43 3.11 7.29 3.61.26-6.85-1.32-9.62-5.9-4.39-8-2.12c-4.55 4.77-11.65-1.49-11.16-5.23s-3-7.75-10.49-5.39-4.69 3.66-4.69 3.66 5.31 3.26 8.72 5.27 9 3.74 7.83 5.21-4.47.57-7.2-1.62-10.56-7.4-11.37-5.46-3.61 7.29-3.61 7.29a79.28 79.28 0 0 1 10.26 2.27c4.55 1.43 5.48 3.94 1.92 4.13s-10-2.48-12 .92-.63 12.15-.63 12.15 6.52-4.41 9.84-4.4 1.2 1.86-2.51 4.26a51.22 51.22 0 0 0-4.67 3.21l3.38 8.67a61.53 61.53 0 0 1 7.8-.56l2.21.16s0-9.1 2.61-11.79 3.07 3.54 3.62 5.13-1.11 6.35-1.11 6.35 6.46-3.53 8.55-4.94l2.1-1.4s-2.42-6.6-4.06-8.49-3.88-1.61-2-3.25 4.71-.77 6.32 1.56a49 49 0 0 1 3.51 6.9s2.41.39 5.54-3.16 2-4.73-1.39-6.74Zm-29.6 10c-2.5-1.25 3.44-3.75 3.44-3.75 2.19-.31-.94 5.01-3.45 3.76Zm4.22-11.68c-1.07-.53 2.13-1.6 2.13-1.6 4.64.9-1.07 2.15-2.13 1.61Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M545 421.22c-.65.69-1.32 1.36-2 2M557.78 401.76A83 83 0 0 1 547 418.91M558.94 399.26c1.5-3.35 2.27-5.57 2.27-5.57a9.21 9.21 0 0 0 5.5.61 10.5 10.5 0 0 1 4.88-.1M546 392.4s10.93 5.43 10.22 6M562 401.73s5.17 2.14 8 5.44M555.07 405.23a43.16 43.16 0 0 0-8.38-.59 27 27 0 0 0-8.3 1.41M556.38 408.65s5.73 3.73 5.62 8.38'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        d='M560.73 340.66s-4.28 3.11-7 0 1.17-9.33-2.73-11.66-10.1 1.94-10.1 1.94 5.05 1.17 5.05 3.89-2.33 3.88-2.72 2.72-3.11-7-7-4.28a10 10 0 0 0-4.27 6.61s9.32-1.56 10.1 1.94-3.89-.39-7.77-.39-7.39.78-7 3.11a30.52 30.52 0 0 1 0 6.22 11.56 11.56 0 0 1 7.39-3.11c4.27 0 4.27 2.72.39 2.72s-7 3.89-7 3.89 3.89 5.83 8.55 8.55 6.22 1.56 6.61-.78-1.17-8.16 1.55-8.16-3.88 10.5-.39 10.88 6.22-.77 7-3.11l.78-2.33s-1.56-5.83-1.17-8.16 1.17 0 1.94 2.72 1.56 9.33 2.72 8.94 4.67-5.44 5.45-7.38-5.45-7.39-5.45-8.55 4.67.77 5.45 1.94a30.71 30.71 0 0 1 1.55 2.72s2.72-1.94 2.33-7.39-4.26-3.49-4.26-3.49Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M539 354.75c2.69-3.75 6.67-8.85 10.12-11.37M535.85 359.31s.69-1.08 1.8-2.7M551 344.15s4.66 5.83 5.83 12.05M546.35 348s1.16-.78 1.16 7M549.07 340.66s2.33-2.34.77-6.61M545.96 344.54l-1.95-3.11M542.46 348s-5.83-3.89-9.33-3.89'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
      />
      <path
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
        d='M569.93 427.73h30.05v43.25h-30.05z'
      />
      <path
        d='M329.32 95.38s.36-12.78-.09-23.77.46-34.55.46-34.55M329.48 50.92l-4.99-3.93M329.42 53.62l1.78-2.87M329.02 64.99l4.43-3.67M329.69 41.89l-2.34-2.13M328.98 66.98l-2.93-3.14M329.23 79.32l-6.09-5.56'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M329.39 86.17a37.35 37.35 0 0 0 4.52-2.11'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M326 52.89c-2.45-1.89-10.67-1.56-12.25 2.37s3.45 10.34 8.25 9.89c1.89-.18 2.71-.31 3.45-1.37l-6.75-6.6 7.31 5.88a6.66 6.66 0 0 0 .5-1.2c.08-.29.16-.61.22-.94.51-2.35 1.58-6.26-.73-8.03ZM341.91 48.33v.14c-.66 2.93-4 4.59-6.83 4.34a6.25 6.25 0 0 1-1.16-.21 8 8 0 0 0-.72 7.1 4.35 4.35 0 0 0 .47.94c.83-.68 9.71-8.26 9.71-8.26l-9.22 8.88c2.78 3 9.3 2.57 11.57-2.94s-.62-9.2-3.82-9.99Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M337.39 38.08c-4.78-1-9.06 1.12-9 6.11a8.06 8.06 0 0 0 2.68 5.81l4.93-6.72-4.14 7.33a6.6 6.6 0 0 0 3.12 1.12c2.82.24 6.17-1.42 6.83-4.35.69-3.27.36-8.25-4.42-9.3ZM331.81 20.73s-.49.15-3.55 1.64a7.74 7.74 0 0 0-3.06 10.48 7.25 7.25 0 0 0 4.09 3.58l1.23-11.89-.52 12.05a3.42 3.42 0 0 0 .7 0c2-.2 5.13-2.58 5.43-7.45s-4.32-8.41-4.32-8.41ZM317.84 31.54l9.23 7.38a2.75 2.75 0 0 0 .09-1c-.43-4.22-4.64-6.67-7.42-7.55s-4.71.69-2.92 4.73 6.48 6 8.57 5.39a2.49 2.49 0 0 0 1.28-.78Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        d='M324.19 41a9.37 9.37 0 0 1-7.09-4.1 3.77 3.77 0 0 0-4.1 3.31c-.94 4.4 2.24 9 6.83 8.44a6.09 6.09 0 0 0 3.86-1.65l-8.2-7.49 8.78 6.69a2.66 2.66 0 0 0 .32-1 7.29 7.29 0 0 0-.4-4.2ZM324.14 65.47a7.11 7.11 0 0 1-2.35.56c-3 .28-6.06-2.07-7.58-4.83a4 4 0 0 0-3.67 3.3c-.91 5.15 3.86 11.94 8.68 10.86a6.86 6.86 0 0 0 3.35-1.7l-10.52-10.12L323.18 73a5.9 5.9 0 0 0 1.19-2.81 6.41 6.41 0 0 0-.23-4.72ZM345.31 76c-3.54-4.1-7.75-.43-7.75-.43s-2.58.85-3.56 4.51a5.66 5.66 0 0 0 .13 3.37l10.64-6.2-10.38 6.78c1 1.84 3.08 3 6 2.15 4.66-1.28 8.45-6.05 4.92-10.18Z'
        style={{
          fill: '#52aeb9'
        }}
      />
      <path
        style={{
          fill: '#263238'
        }}
        d='M321.76 91.52h14.73v21.81h-14.73z'
      />
      <path
        style={{
          fill: '#52aeb9',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M304.79 113.15h194.28v359.23H304.79z'
      />
      <path
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='M308.83 117.7h162.84v349.21H308.83z'
      />
      <path
        style={{
          fill: '#52aeb9',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M308.83 171.86h162.84v5.15H308.83zM308.83 231.4h162.84v5.15H308.83zM415.73 117.76h3.46v54.09h-3.46zM360.37 117.76h3.46v54.09h-3.46zM415.73 236.61h3.46v54.09h-3.46zM360.37 236.61h3.46v54.09h-3.46zM387.78 177.3h3.46v54.09h-3.46zM308.83 290.71h162.84v5.15H308.83zM308.83 350.05h162.84v118.68H308.83zM387.78 295.95h3.46v54.09h-3.46z'
      />
      <path
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='M475.66 113.12V472.3l23.42.08V113.12h-23.42z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M421.59 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M421.59 161.04h8.36v4.08h-8.36zM421.59 127.01h8.36v4.08h-8.36zM430.14 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M430.14 161.04h8.36v4.08h-8.36zM430.14 127.01h8.36v4.08h-8.36zM438.7 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M438.7 161.04h8.36v4.08h-8.36zM438.7 127.01h8.36v4.08h-8.36zM447.25 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M455.54 121.96h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M447.25 161.04h8.36v4.08h-8.36zM447.25 127.01h8.36v4.08h-8.36zM365.66 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M365.66 161.04h8.36v4.08h-8.36zM365.66 127.01h8.36v4.08h-8.36zM374.21 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M374.21 161.04h8.36v4.08h-8.36zM374.21 127.01h8.36v4.08h-8.36zM382.77 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M382.77 161.04h8.36v4.08h-8.36zM382.77 127.01h8.36v4.08h-8.36zM391.32 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M399.61 121.96h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M391.32 161.04h8.36v4.08h-8.36zM391.32 127.01h8.36v4.08h-8.36zM365.66 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M365.66 280.07h8.36v4.08h-8.36zM365.66 246.05h8.36v4.08h-8.36zM374.21 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M374.21 280.07h8.36v4.08h-8.36zM374.21 246.05h8.36v4.08h-8.36zM382.77 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M382.77 280.07h8.36v4.08h-8.36zM382.77 246.05h8.36v4.08h-8.36zM391.32 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M399.61 240.99h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M391.32 280.07h8.36v4.08h-8.36zM391.32 246.05h8.36v4.08h-8.36zM420.87 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M420.87 280.07h8.36v4.08h-8.36zM420.87 246.05h8.36v4.08h-8.36zM429.43 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M429.43 280.07h8.36v4.08h-8.36zM429.43 246.05h8.36v4.08h-8.36zM437.98 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M437.98 280.07h8.36v4.08h-8.36zM437.98 246.05h8.36v4.08h-8.36zM446.54 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M454.82 240.99h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M446.54 280.07h8.36v4.08h-8.36zM446.54 246.05h8.36v4.08h-8.36zM310.8 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M310.8 280.07h8.36v4.08h-8.36zM310.8 246.05h8.36v4.08h-8.36zM319.36 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M319.36 280.07h8.36v4.08h-8.36zM319.36 246.05h8.36v4.08h-8.36zM327.91 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M327.91 280.07h8.36v4.08h-8.36zM327.91 246.05h8.36v4.08h-8.36zM336.47 240.99h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M344.75 240.99h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M336.47 280.07h8.36v4.08h-8.36zM336.47 246.05h8.36v4.08h-8.36zM309.73 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M309.73 161.04h8.36v4.08h-8.36zM309.73 127.01h8.36v4.08h-8.36zM318.28 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M318.28 161.04h8.36v4.08h-8.36zM318.28 127.01h8.36v4.08h-8.36zM326.84 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M326.84 161.04h8.36v4.08h-8.36zM326.84 127.01h8.36v4.08h-8.36zM335.39 121.96h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M343.68 121.96h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M335.39 161.04h8.36v4.08h-8.36zM335.39 127.01h8.36v4.08h-8.36zM334.47 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M334.47 220.35h8.36v4.08h-8.36zM334.47 186.33h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M326.22 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M326.22 220.35h8.36v4.08h-8.36zM326.22 186.33h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M317.97 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M317.97 220.35h8.36v4.08h-8.36zM317.97 186.33h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M309.73 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M309.73 220.35h8.36v4.08h-8.36zM309.73 186.33h8.36v4.08h-8.36zM343.02 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M343.02 220.35h8.36v4.08h-8.36zM343.02 186.33h8.36v4.08h-8.36zM351.57 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M351.57 220.35h8.36v4.08h-8.36zM351.57 186.33h8.36v4.08h-8.36zM360.13 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M368.42 181.27h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M360.13 220.35h8.36v4.08h-8.36zM360.13 186.33h8.36v4.08h-8.36zM393.91 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M393.91 220.35h8.36v4.08h-8.36zM393.91 186.33h8.36v4.08h-8.36zM402.47 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M402.47 220.35h8.36v4.08h-8.36zM402.47 186.33h8.36v4.08h-8.36z'
      />
      <path
        transform='rotate(-22.89 433.897 206.107)'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M429.62 180.9h8.46v50.39h-8.46z'
      />
      <path
        transform='rotate(-22.89 440.29 221.258)'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M436.02 219.17h8.46v4.13h-8.46z'
      />
      <path
        transform='rotate(-22.89 426.894 189.52)'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M422.62 187.44h8.46v4.13h-8.46z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M439.76 230.95h17.45l7.85-3.27-17.51-.02-7.79 3.29z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M427.95 181.24h16.39l20.72 46.44-17.51-.02-19.6-46.42zM410.72 181.27h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M410.72 220.35h8.36v4.08h-8.36zM410.72 186.33h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M419.08 181.27h8.87l-7.8 3.26 19.61 46.42h-20.68v-49.68zM334.47 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M334.47 339.17h8.36v4.08h-8.36zM334.47 305.14h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M326.22 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M326.22 339.17h8.36v4.08h-8.36zM326.22 305.14h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M317.97 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M317.97 339.17h8.36v4.08h-8.36zM317.97 305.14h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M309.73 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M309.73 339.17h8.36v4.08h-8.36zM309.73 305.14h8.36v4.08h-8.36zM343.02 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M343.02 339.17h8.36v4.08h-8.36zM343.02 305.14h8.36v4.08h-8.36zM351.57 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M351.57 339.17h8.36v4.08h-8.36zM351.57 305.14h8.36v4.08h-8.36zM360.13 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M368.42 300.09h14v49.77h-14z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M360.13 339.17h8.36v4.08h-8.36zM360.13 305.14h8.36v4.08h-8.36zM393.91 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M393.91 339.17h8.36v4.08h-8.36zM393.91 305.14h8.36v4.08h-8.36zM402.47 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M402.47 339.17h8.36v4.08h-8.36zM402.47 305.14h8.36v4.08h-8.36z'
      />
      <path
        transform='rotate(-22.89 433.903 324.937)'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M429.62 299.71h8.46v50.39h-8.46z'
      />
      <path
        transform='rotate(-23 438.029 340.109)'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M436.02 337.98h8.46v4.13h-8.46z'
      />
      <path
        transform='rotate(-22.89 426.9 308.35)'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M422.62 306.25h8.46v4.13h-8.46z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M439.76 349.77h17.45l7.85-3.27-17.51-.03-7.79 3.3z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M427.95 300.05h16.39l20.72 46.45-17.51-.03-19.6-46.42zM410.72 300.09h8.36v49.77h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M410.72 339.17h8.36v4.08h-8.36zM410.72 305.14h8.36v4.08h-8.36z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M419.08 300.09h8.87l-7.8 3.25 19.61 46.43h-20.68v-49.68z'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M387.78 350.04v116.7M310.15 364.49h161.48'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10,
          strokeWidth: 2
        }}
        d='M343.29 374.36v21.16M350.34 374.36v21.16M424.39 374.36v21.16M431.44 374.36v21.16'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M516.64 115.01V79.13h52.18M572.66 79.13h21.98v89h-78v-47.68'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M566.26 156.13h-39.11v-65h56.97v65h-13.38'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M544.68 111.13h21.91v25h-21.91zM601.57 156.49v-22.38h32.55M636.51 134.11h13.71v55.51h-48.65v-29.74'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M632.52 182.14h-24.39v-40.55h35.53v40.55h-8.35'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M619.06 154.07h13.67v15.59h-13.67z'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round'
        }}
        d='M85.8 472.38h564'
      />
      <path
        d='M125.8 332.38s-6-80-3-89 21.5-8.5 25-8.5 76.5 0 87 5 12 128 12 128h-116Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M250.79 325.88s-12 0-14.4 12 7.05 61.5 7.75 75.5 3.16 33.5 3.16 33.5h42.5l9.5-121Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M299.3 325.88s-12 0-15 12 4 61.5 4 75.5 1.5 33.5 1.5 33.5h15s1.5-36 1.5-41 6-57 3-67.5-5.5-11.5-10-12.5Z'
        style={{
          fill: '#636363',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M172.55 240.13s-39.75 16.25-39.75 18 13.5 48.75 18.5 56 8.5 15.25 10 18.25 8 10.5 13.25 10.5 11.5-6.25 28.25-8.75 31.75-2.25 34.75-4.5-.75-8.25-1.25-9.5-2.5-5.75-2.5-5.75 16.25 13.5 22.75 14 11.25-2.75 12.25-7-2.75-14.5-5.25-20-14.25-14.25-19.5-21-12-14.75-16.75-20.25a45.47 45.47 0 0 0-15-11.5c-4.5-2-16-7-23.25-7.75s-16.5-.75-16.5-.75Z'
        style={{
          fill: '#52aeb9',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M250.46 298.71s-5.66 15.67-3.66 19.67 7.33 5.33 7.33 5.33M166.55 245.38l33 32 5-24'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M252.05 300.63s8 5.75 10.75 3.75 2-5.75.25-9.75-7.5-6.25-9.75-4.5-3 8.75-1.25 10.5ZM170.05 229.63l1.25 15 26.5 26 4-18.5 1.75-7.75s-17.25-3-22.75-6.75-10.75-8-10.75-8Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M203.55 244.38s-17.25-3-22.75-6.75-10.75-8-10.75-8l1.25 15 6.7 6.57c5.21 1.47 10.4 2.18 13.93.82a55.39 55.39 0 0 0 11.16-5.55ZM162.13 218.05s-4.67-21.34-4.67-25 12.67-22.34 30-19.34 27 13 27.67 16-2 9-2 9a24.84 24.84 0 0 1 1 5.34c0 2-3 10.66-3 10.66l-1-9s-8.33 2.34-13 2.34-12.67-3-12.67-3 1.67 5.33 0 8-6.66 5-6.66 5-.67 5.33-2.67 5.33a43.6 43.6 0 0 1-7.33-1c-1.34-.33-5.67-4.33-5.67-4.33Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M169.8 220.38s-.67-8-7.34-6-2.33 9.33 1.34 13 4.66 1 4.66 1 1 7.67 6 12 12.67 12.33 20.67 11.33 13.67-11 14.67-18.33 1.66-24.67 1.66-26.67a37.49 37.49 0 0 0-1.33-6.66s-7 5-13.33 3.66-13-4-16-4-2.34 4.34-1.34 5.67 3.67 4 .67 5.67-7 3.33-7 3.33v7Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='m201.69 216.74-1.64 18.07-8.21-2.38'
      />
      <path
        d='M194.76 233.89s-4 0-6.2.19a16.87 16.87 0 0 1-4 .18c-.91-.18-2-.91-.18.91s7.3 4 10.22 4.38 4.93-.55 7.11-2 4-2.92 2.92-3.28a24.08 24.08 0 0 0-4.56-.37v.92Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <ellipse
        cx={189.01}
        cy={220.48}
        rx={1.19}
        ry={2.46}
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M206.8 220.12c0 1.36-.53 2.46-1.18 2.46s-1.19-1.1-1.19-2.46.53-2.47 1.19-2.47 1.18 1.11 1.18 2.47Z'
        style={{
          fill: '#263238'
        }}
      />
      <path
        d='M184.54 216.74s4.93-3.47 8.94-2M202.61 214.92s5.11-2.38 7.3-.19M192.21 225s-1.1 1.28-4.75 2.37M202.24 224.22a16.52 16.52 0 0 0 3.47 1.83'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='m217.8 271.63 1.75-4.5s2.25 2.25 8.25 2.25 15.75 4.25 15.75 4.25l.75 1.25s1.75-5.25 5-6.25 8.5-4.5 9.25-5.5l.75-1 3.5 3-15.5 20.5-29-11.25Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='m218.05 269.88-7.5 39.75 26 6 9.5-38.5-28-7.25z'
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='m246.05 277.13 16.75-12-10 38.5-16.25 12 9.5-38.5zM185.8 302.38l11.33-4.67s10-10.66 11-11.66 11-2 11-2l11 7.33s2 1.67 0 2.33-6.33-1.33-6.33-1.33l-4.34-2.67-2.66 1.34 5.66 4.66s1.67 4.34 1.67 6.67a10.84 10.84 0 0 1-1 4.33l-3.33 4.67-10-5s-6 4.33-7.34 5-14-.33-14-.33Z'
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='m209.71 290.7 3.54-.67 3.04.84M206.34 297.28s7.92-2.2 8.43-2.2 6.41 6.07 6.41 6.07l.67 6.58'
      />
      <path
        d='m206.68 301.49 6.4.17 5.9 3.88a17.67 17.67 0 0 1 .82 5.84'
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M139.8 253.38s-7.34 1-8.34 7.33-7.66 59.67-5.66 65.67 39-4.33 40.33-5 22.33-7 22.67-8.33 1-12.34-2-13-31.67.33-31.67.33l-4-34.67'
        style={{
          fill: '#52aeb9',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='m155.13 300.38-17.67 3M134.13 299.38l15.33 2'
      />
      <path
        d='M258.3 281.63s-6.75 7-6.5 8.75 3.75-1 4.5-1.75 4.25-2.75 4.25-4.75-.75-4.25-2.25-2.25Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M259.05 285.88s2 2 2 3.75-4.25 7.5-6.25 7.5-.5-4 .5-5.75 3.75-5.5 3.75-5.5Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M261.05 289.63s2.5.75 2.5 3-4.75 6-6 6.25-1-2 0-3.75 3.5-5.5 3.5-5.5Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M263.55 292.63a7.85 7.85 0 0 1 .5 4c-.25 2.5-4 4-5.25 4s-1.25-1.75-1.25-1.75a77 77 0 0 0 6-6.25ZM184.92 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5zM141.42 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5zM298.42 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5zM254.92 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M178.3 380.61h113.5v66H178.3z'
      />
      <path
        d='M374.13 370.05s12-5 14.67-7 6.33 2.33 4.66 7-6 13-12.66 17-12 7.33-13.34 8.66-1 6.34-6.66 8.34-12.67-2-16.67-8.34-6.67-10.66-6.67-10.66.34-4.67 7-7 18-3.34 18-3.34Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M237.55 329.63s7.91-1.92 9.91-.92 8.67 7.34 8.67 7.34 40.33.66 43.33 2 34 25 40.67 28.33 12.33 6.33 12.33 6.33l-5 22v2l.34 3.34s-17.34-5.34-21.67-6.34-22.33-10.33-24-10.33-15.33-4-15.33-4-2-1.33-23.67-3.33-46-4.34-54.67-4.67-20-1-24.33-2.67-12-13.33-13.33-20.33 12.66-13 18.66-15.67 28.34-6 32.34-6 15.75 2.92 15.75 2.92Z'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='m245.13 339.38-6.67 9.67 9-6M347.46 368.38s9.67-7.67 11.67-10 8.67-15 10-19 4.67-6 7-3 4 9 5.33 11.67-.66 15.66-4.66 20-9.53 14.85-10.67 17c-3 5.66-7.67 6.33-10.67 6.66s-10.33-7.66-10.33-7.66Z'
      />
      <path
        d='M199.13 346.05s10-2 19-1 27.33 6.66 35 8.66 21.33 3 27.67 5.34 23 5.33 35.66 5.33 32 1.67 32 1.67-2.66 19.33-2 23a56.74 56.74 0 0 0 2.67 8.66s-25.67 2-33 3-37-3.66-42-5-12.33-3.33-17-3.33-13.67 2-26.67 3.33-31.33-4.33-33.66-4.66-10.67-3.34-12.67-4.67-13.67-22-9.67-30.67 9-10 9-10'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M260.45 379.68c6.43 1.28 13.24 5.18 22.45 8.79 12.29 4.81 36.89 4.37 40.06 4.81s11.22 0 11.22 0M210.87 384.2l3.67.53c10.68 1.6 31-4.27 39.52-5.34M200.12 383.13s1.92 0 6.93.59'
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M135.29 325.88s-12 0-14.4 12 7 61.5 7.75 75.5 3.16 33.5 3.16 33.5h42.5l9.5-121Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M183.8 325.88s-12 0-15 12 4 61.5 4 75.5 1.5 33.5 1.5 33.5h15s1.5-36 1.5-41 6-57 3-67.5-5.5-11.5-10-12.5Z'
        style={{
          fill: '#636363',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
        d='M281.42 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5zM321.92 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5zM340.92 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5zM380.92 472.38h-4.75l-2.37-31.5h9.5l-2.38 31.5z'
      />
      <path
        d='M275.55 399.88H380a7.25 7.25 0 0 1 7.25 7.25v51.25h-119v-51.25a7.25 7.25 0 0 1 7.3-7.25Z'
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M380.05 399.88h-38a7.25 7.25 0 0 0-7.25 7.25v51.25h52.5v-51.25a7.26 7.26 0 0 0-7.25-7.25Z'
        style={{
          fill: '#636363',
          stroke: '#263238',
          strokeMiterlimit: 10
        }}
      />
    </svg>
  )
}
