import texts from './texts'
import { subcategoriesIds } from './CategoriesData'

export const questions = [
  {
    id: '1',
    question: texts()['question.flexibleHours'],
    text: texts()['answer.flexibleHours'],
    categories: subcategoriesIds,
    main: true
  },
  {
    id: '2',
    question: texts()['question.availableToNegociate'],
    text: texts()['answer.availableToNegociate'],
    categories: subcategoriesIds,
    main: true
  },
  {
    id: '3',
    question: texts()['question.payInAdvance'],
    text: texts()['answer.payInAdvance'],
    categories: subcategoriesIds,
    main: true
  },
  {
    id: '4',
    question: texts()['question.accesible'],
    text: texts()['answer.accesible'],
    categories: [],
    main: true
  },
  {
    id: '5',
    question: texts()['question.availabilityForTravel'],
    text: texts()['answer.availabilityForTravel'],
    categories: [],
    main: true
  },
  {
    id: '6',
    question: texts()['question.parking'],
    text: texts()['answer.parking'],
    categories: [],
    main: true
  },
  {
    id: '7',
    question: texts()['question.weekends'],
    text: texts()['answer.weekends'],
    categories: [],
    main: true
  },
  {
    id: '8',
    question: texts()['question.material'],
    text: texts()['answer.material'],
    categories: [],
    main: true
  },
  {
    id: '9',
    question: texts()['question.neededParts'],
    text: texts()['answer.neededParts'],
    categories: [],
    main: true
  },
  {
    id: '10',
    question: texts()['question.bigTrees'],
    text: texts()['answer.bigTrees'],
    categories: [],
    main: true
  },
  {
    id: '11',
    question: texts()['question.water'],
    text: texts()['answer.water'],
    categories: [],
    main: true
  },
  {
    id: '12',
    question: texts()['question.workAtHome'],
    text: texts()['answer.workAtHome'],
    categories: [],
    main: true
  },
  {
    id: '13',
    question: texts()['question.goToTheHome'],
    text: texts()['answer.goToTheHome'],
    categories: [],
    main: true
  },
  {
    id: '14',
    question: texts()['question.crane'],
    text: texts()['answer.crane'],
    categories: [],
    main: true
  },

  // // Other Details
  {
    id: 'o1',
    question: texts()['question.squareMeters'],
    text: texts()['answer.squareMeters'],
    categories: [],
    main: false,
    sufix: 'm²',
    prefix: null,
    type: 'number'
  }
]
