import React from 'react'
import PropTypes from 'prop-types'
import { CardContent, Typography, Divider, Box } from '@mui/material'
import { CustomCard } from 'components'
import { useI18N } from 'hooks'

/// ////////COMPONENT PROPS
SubscriptionCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  price: PropTypes.bool,
  description: PropTypes.string,
  accentText: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  promo: PropTypes.bool,
  currency: PropTypes.string
}

export default function SubscriptionCard({
  content,
  description,
  title,
  accentText,
  price,
  recurring,
  promo,
  currency,
  selected,
  ...other
}) {
  const { t } = useI18N()

  return (
    <CustomCard
      {...(promo && {
        sx: {
          background:
            'linear-gradient(110deg, rgba(101,59,167,1) 0%, rgba(53,143,221,1) 100%)',
          boxShadow:
            selected && '0px 0px 0px 3px #fac988, 0px 0px 12px #fac988cc'
        }
      })}
      selected={selected}
      {...other}
    >
      {promo && <PromoDecoration />}
      <CardContent
        sx={{
          zIndex: 2,
          position: 'relative',
          textAlign: 'center',
          ...(promo && {
            '& p, h2': { color: 'white' },
            '& hr': { borderColor: 'white' }
          })
        }}
      >
        <Typography textTransform='uppercase'>
          {promo ? t('promo') : title}
        </Typography>
        <Divider
          variant='middle'
          sx={{
            maxWidth: 60,
            mx: 'auto',
            borderBottomWidth: 'medium',
            borderColor: 'text.primary',
            mt: 1,
            mb: 3
          }}
        />
        <Box aria-label='content-container'>
          {!price && (
            <Typography aria-label='trial-text' marginBottom={1}>
              {content}
            </Typography>
          )}
          {price && (
            <Typography aria-label='price' variant='h2' fontSize='2rem'>
              {`${content} ${currency}`}
              {recurring && !promo && (
                <Typography component='span' color='textSecondary'>
                  {`/${t('month')}`}
                </Typography>
              )}
              {promo && (
                <Typography component='span'>{`/${t('year')}`}</Typography>
              )}
            </Typography>
          )}
          <Typography
            aria-label='plan-description'
            color={promo ? 'white' : 'textSecondary'}
          >
            {description} <br />
            <Typography
              component='span'
              fontSize={promo ? '0.8rem' : '1rem'}
              fontWeight={500}
              color={promo ? '#FFFFFFcc' : 'primary'}
            >
              {accentText}
            </Typography>
          </Typography>
        </Box>
      </CardContent>
    </CustomCard>
  )
}

const PromoDecoration = () => (
  <Box
    sx={{ position: 'absolute', zIndex: 1, opacity: 0.15, left: 0, right: 0 }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 800 800'
    >
      <rect fill='transparent' width='800' height='800' />
      <defs>
        <radialGradient
          id='b'
          cx='400'
          cy='400'
          r='70%'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#FFFFFF09' />
          <stop offset='1' stopColor='#FFFFFF' />
        </radialGradient>
      </defs>
      <rect fill='url(#a)' width='800' height='800' />
      <g fillOpacity='.8'>
        <path
          fill='url(#b)'
          d='M998.7 439.2c1.7-26.5 1.7-52.7 0.1-78.5L401 399.9c0 0 0-0.1 0-0.1l587.6-116.9c-5.1-25.9-11.9-51.2-20.3-75.8L400.9 399.7c0 0 0-0.1 0-0.1l537.3-265c-11.6-23.5-24.8-46.2-39.3-67.9L400.8 399.5c0 0 0-0.1-0.1-0.1l450.4-395c-17.3-19.7-35.8-38.2-55.5-55.5l-395 450.4c0 0-0.1 0-0.1-0.1L733.4-99c-21.7-14.5-44.4-27.6-68-39.3l-265 537.4c0 0-0.1 0-0.1 0l192.6-567.4c-24.6-8.3-49.9-15.1-75.8-20.2L400.2 399c0 0-0.1 0-0.1 0l39.2-597.7c-26.5-1.7-52.7-1.7-78.5-0.1L399.9 399c0 0-0.1 0-0.1 0L282.9-188.6c-25.9 5.1-51.2 11.9-75.8 20.3l192.6 567.4c0 0-0.1 0-0.1 0l-265-537.3c-23.5 11.6-46.2 24.8-67.9 39.3l332.8 498.1c0 0-0.1 0-0.1 0.1L4.4-51.1C-15.3-33.9-33.8-15.3-51.1 4.4l450.4 395c0 0 0 0.1-0.1 0.1L-99 66.6c-14.5 21.7-27.6 44.4-39.3 68l537.4 265c0 0 0 0.1 0 0.1l-567.4-192.6c-8.3 24.6-15.1 49.9-20.2 75.8L399 399.8c0 0 0 0.1 0 0.1l-597.7-39.2c-1.7 26.5-1.7 52.7-0.1 78.5L399 400.1c0 0 0 0.1 0 0.1l-587.6 116.9c5.1 25.9 11.9 51.2 20.3 75.8l567.4-192.6c0 0 0 0.1 0 0.1l-537.3 265c11.6 23.5 24.8 46.2 39.3 67.9l498.1-332.8c0 0 0 0.1 0.1 0.1l-450.4 395c17.3 19.7 35.8 38.2 55.5 55.5l395-450.4c0 0 0.1 0 0.1 0.1L66.6 899c21.7 14.5 44.4 27.6 68 39.3l265-537.4c0 0 0.1 0 0.1 0L207.1 968.3c24.6 8.3 49.9 15.1 75.8 20.2L399.8 401c0 0 0.1 0 0.1 0l-39.2 597.7c26.5 1.7 52.7 1.7 78.5 0.1L400.1 401c0 0 0.1 0 0.1 0l116.9 587.6c25.9-5.1 51.2-11.9 75.8-20.3L400.3 400.9c0 0 0.1 0 0.1 0l265 537.3c23.5-11.6 46.2-24.8 67.9-39.3L400.5 400.8c0 0 0.1 0 0.1-0.1l395 450.4c19.7-17.3 38.2-35.8 55.5-55.5l-450.4-395c0 0 0-0.1 0.1-0.1L899 733.4c14.5-21.7 27.6-44.4 39.3-68l-537.4-265c0 0 0-0.1 0-0.1l567.4 192.6c8.3-24.6 15.1-49.9 20.2-75.8L401 400.2c0 0 0-0.1 0-0.1L998.7 439.2z'
        />
      </g>
    </svg>
  </Box>
)
