import { useEffect, useState } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { UpdateTag, UpdateItem, BottomDrawer, Modal } from 'components'
import changelog from '../../changelog.json'
import { useI18N } from 'hooks'

export default function NewsDialog() {
  const { t } = useI18N()
  const [open, setOpen] = useState(false)
  const appVersion = process.env.REACT_APP_VERSION
  const update = changelog[0]

  useEffect(() => {
    const localVersion = localStorage.getItem('version')
    if (localVersion) {
      const parseLocal = parseInt(localVersion.replace(/\./g, ''))
      const parseCurrent = parseInt(appVersion.replace(/\./g, ''))
      if (parseCurrent > parseLocal && update.v === appVersion) {
        setOpen(true)
        localStorage.setItem('version', appVersion)
      }
    }
  }, [])

  const toggleDrawer = () => {
    setOpen(false)
  }

  return (
    <Modal
      aria-label='news-changes'
      anchor='bottom'
      open={open}
      onClose={toggleDrawer}
    >
      <BottomDrawer
        onClose={toggleDrawer}
        title={
          <Stack direction='row' alignItems='baseline'>
            <Typography fontSize={18} sx={{ mr: 2 }}>
              {t('news')}
            </Typography>
            <Typography
              fontSize={14}
              textAlign='center'
            >{` v ${appVersion}`}</Typography>
          </Stack>
        }
        closeButton
      >
        {update.new.length > 0 && (
          <Box aria-label='news-container' sx={{ mb: 2 }}>
            <UpdateTag type='new' />
            {update.new.map((item, index) => (
              <UpdateItem key={`new-${index}`}>{item}</UpdateItem>
            ))}
          </Box>
        )}
        {update.fix.length > 0 && (
          <Box aria-label='news-container' sx={{ mb: 2 }}>
            <UpdateTag type='fix' />
            {update.fix.map((item, index) => (
              <UpdateItem key={`fix-${index}`}>{item}</UpdateItem>
            ))}
          </Box>
        )}
      </BottomDrawer>
    </Modal>
  )
}
