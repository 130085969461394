const fr = (...args) => ({
  // GENERAL
  processing: 'Traitement...',
  sending: 'Envoi...',
  loadMore: 'Charger plus',

  // SIGN IN
  signIn: 'Connexion',
  signInWelcome: ' Nous sommes heureux de vous revoir ! ',
  forgotPassword: 'Vous avez oublié votre mot de passe?',
  signInEmailError: 'Veuillez vérifier votre e-mail.',
  signInPasswordError: 'Veuillez vérifier votre mot de passe.',
  tooManyRequest:
    'Vous avez fait trop de tentatives incorrectes, veuillez essayer plus tard.',
  wrongCredentials: 'Informations d’identification incorrectes.',
  signInText: 'Connexion',
  signUpText: 'Créer maintenant',
  registerQ: ' Vous n’avez pas de compte? ',
  continueAsGuest: 'Continuer en tant qu’invité',
  resetPasswordTitle: 'Récupérer le mot de passe',
  aEmailWillBeSend:
    'Veuillez saisir l’adresse e- mail avec laquelle vous vous êtes inscrit.Vous recevrez un e-mail pour récupérer votre mot de passe.',
  theEmailHasBeenSent:
    ' L’e - mail a été envoyé.Veuillez vérifier votre boîte de réception.',
  close: 'Fermer',
  resetPassword: 'Réinitialiser le mot de passe',
  yourUser: 'Votre utilisateur:',
  'resetError.ExpiredActionCode':
    'Le code a expiré, veuillez demander à nouveau le changement de mot de passe.',
  'resetError.InternalError':
    'Une erreur s’est produite.Retournez à l’application pour réinitialiser votre mot de passe.',
  'resetError.Processing':
    ' Une erreur s’est produite lors du traitement de votre demande.Essayez une autre fois.',
  'resetError.auth/expired-action-code':
    'Le code a expiré, veuillez demander à nouveau le changement de mot de passe.',
  'resetError.auth/invalid-action-code':
    'Le code est mal formé ou a déjà été utilisé, veuillez demander à nouveau le changement de mot de passe.',
  'resetError.auth/user-disabled':
    'L’utilisateur correspondant au code de réinitialisation du mot de passe donné a été désactivé.',
  'resetError.auth/user-not-found':
    'Il n’y a pas d’utilisateur correspondant au code de réinitialisation du mot de passe.',
  'resetError.auth/weak-password':
    'Le nouveau mot de passe n’est pas assez fort.',
  passwordResetSuccesfully: 'Mot de passe modifié avec succès.',
  nowYouCanUseNewPass:
    'Vous pouvez maintenant utiliser votre nouveau mot de passe pour vous connecter à votre compte Jobsaun.',

  // SIGN UP
  signUp: 'Enregistrement',
  welcome: 'Nous vous souhaitons la bienvenue',
  asEAsyAsFindTheProfessional: (
    <>
      Aussi <span>facile</span> que de trouver le professionnel dont vous avez
      besoin
    </>
  ),
  allReady: 'Tout est prêt!',
  letsCreateYourAccount: 'Créons votre compte',
  accountType: 'Quel type de compte voulez-vous créer?',
  completeTheData:
    'Remplissez le formulaire ci-dessous avec les détails de votre compte.',
  tellUsMoreAboutYourCompany: 'Dites-nous en plus sur votre entreprise.',
  getMoreVisits:
    'Les entreprises reçoivent plus de contacts lorsqu’elles ont une image et une biographie.',
  user: 'Utilisateur',
  particular: 'Client',
  company: 'Société',
  country: 'Pays',
  name: 'Nom complet',
  onlyVisibleForYou: 'Il ne sera visible que par vous',
  publicName: 'Nom public',
  visibleForEveryone: ' Elle sera visible par tous ',
  phoneNumber: 'Téléphone de contact',
  onlyVisibleOfferAccepted:
    'Il ne sera visible que pour les offres que vous acceptez.',
  email: 'E-Mail',
  pass: 'mot de passe',
  repeatPass: 'Répéter le mot de passe',
  howDidYouHear: ' Comment nous avez - vous trouvés ? ',
  accept1: 'En créant un compte, vous acceptez le',
  accept2: 'et le',
  terms: 'Conditions d’utilisation',
  Datenschutzerklärung: 'Politique de confidentialité.',
  termsAndPrivacy: (
    <>
      En créant un compte vous acceptez le{' '}
      <a
        href='https://jobsaun.com/legal/terms'
        target='_blank'
        rel='noreferrer'
      >
        Conditions d’utilisation
      </a>{' '}
      et le{' '}
      <a
        href='https://jobsaun.com/legal/privacy-and-cookies'
        target='_blank'
        rel='noreferrer'
      >
        Politique de confidentialité.
      </a>
    </>
  ),
  signUpButton: 'Créer un compte',
  maincategory: 'Catégorie principale',
  companyName: 'raison sociale',
  nif: 'NIF',
  province: 'Province',
  postalCode: 'Code postal',
  contactName: 'Nom du contact',
  signUpEmailError: 'L’e-mail ne semble pas être correct.',
  phoneError: 'Le téléphone doit avoir 9 chiffres plus le préfixe.',
  nifError: 'Mauvais champ, veuillez le vérifier.',
  passwordError: 'Le mot de passe doit comporter entre 8 et 20 caractères.',
  repeatPasswordError: 'Les mots de passe ne correspondent pas.',
  required: 'Ce champ est obligatoire.',
  notValid: 'Valeur non valide',
  youMustSelectACategory: 'Vous devez sélectionner au moins une catégorie.',
  contractRequired:
    'Il est nécessaire d’accepter le contrat de traitement des commandes pour continuer.',
  privacyRequired:
    'Il est nécessaire d’accepter notre politique de confidentialité pour continuer.',
  legalFormHelper: 'Ex: indépendant',
  contract: ' J’ai lu et j’accepte l’accord de traitement des commandes. ',
  pleaseReadTheContract: 'Veuillez lire le contrat suivant avant de continuer.',
  ifYouContinue:
    'En continuant, vous acceptez le contrat de traitement de la commande.',
  seeMyOffers: 'Voir mes offres',
  maximumCharacters: 'Le nombre maximal de caractères autorisés est',
  'error.alreadyInUse': 'Un compte est déjà attribué à l’e-mail.',
  friend: 'ami',
  digitalPaper: 'Journal numérique',
  other: 'Autres',
  someFieldIsINcomplete:
    'Certains champs sont incomplets, veuillez revenir en arrière et les remplir tous.',

  // HOME
  exploreCategories: 'Explorer les catégories',
  particulars: 'Clients',
  slider1: 'Trouver des clients rapidement et facilement.',
  slider2: 'Maintenant, le professionnel dont vous avez besoin vous trouve.',
  share: 'Partager',
  search: 'Recherche...',
  categories: 'Catégories',
  whatServiceDoYouNeed: 'De quel service avez-vous besoin?',
  homeCTA: (
    <>
      Maintenant, <span>le professionnel dont vous avez besoin</span> vous
      trouve
    </>
  ),
  bestRatedCompanies: 'Les entreprises les mieux notées',
  doYouHaveACompany: 'Avez-vous une entreprise?',
  joinJobsaun: 'Rejoignez Jobsaun pour rendre votre entreprise plus visible.',
  joinNowForFree: 'Inscrivez-vous gratuitement maintenant',
  weAreAlready: ' Nous le sommes déjà ',
  andGoingUp: 'sociétés et montants',

  //  DISCOVER
  reset: 'Restaurer',

  // INBOX
  inboxGuestMessage:
    'Depuis la boîte aux lettres, vous pouvez entrer en contact et finaliser les détails avec le client ou l’entreprise.',

  // OFFERS-POSTS
  boardGuestMessage:
    'Depuis le conseil d’administration, vous pouvez gérer vos offres ou vos emplois.',
  pluralPosted: 'publié',
  pluralDraft: 'Projets',
  pluralAssigned: 'Attribué',
  pluralAccepted: 'Accepté',
  archived: 'Archivé',

  // ACCOUNT PAGE
  registerMessage:
    ' Profitez de toutes les fonctionnalités de l’application avec un compte.Le créer est très facile et GRATUIT',
  alreadyHasAccount: ' Vous avez déjà un compte ? ',
  knowMoreAboutUs: 'Vous voulez en savoir plus sur nous?',
  moreInfo: 'Plus d’informations',
  installPWA: 'Améliorer l’expérience',
  install: 'Installer',
  welcomeToJobsaun: 'Bienvenue à Jobsaun',
  slogan: 'Laissez ce que vous cherchez vous trouver.',
  joinNow: ' Unissez - vous maintenant ',
  next: 'Suivant',
  preview: 'Précédent',
  accountSlider_1:
    'Trouvez facilement des professionnels vérifiés pour effectuer le service dont vous avez besoin',
  accountSlider_2:
    'Augmenter la visibilité de votre entreprise en étant plus proche des clients',
  accountSlider_3:
    'Avis d’utilisateurs comme vous afin que vous puissiez évaluer les meilleures alternatives',
  accountSlider_4:
    'Recevez des devis personnalisés qui profitent à la fois à l’entreprise et à vous',

  // CATEGORY PAGE
  jobs: 'Emplois',
  companies: 'Sociétés',
  searchInCategory: 'Recherche d’emploi dans cette catégorie...',
  searchInCompanies: 'Recherche d’entreprise dans cette catégorie...',
  jobFilterTitle: 'Filtre d’emploi',
  proximityFilter: 'Proximité(km)',
  budgetFilter: 'Budget',
  subcategories: 'Sous - catégories',
  selectAll: 'Tout sélectionner',
  unSelectAll: 'Désélectionner tout',

  // CATEGORIES
  construction: 'Construction et maison',
  mechanic: 'Mécanique et transport',
  care: 'Soin et esthétique',
  leisure: 'Loisirs et gastronomie',
  technology: 'Technologie et marketing',
  others: 'Autres services',

  // Subcategories
  // ---Construction
  demolition: 'Démolition',
  verticalsJobs: 'Travaux verticaux(façades)',
  architecture: 'Architecture, planification',
  bathRenovations: 'Salles de bains',
  roofers: 'Couvreurs, couvertures',
  welding: 'Soudage',
  reforms: 'Réformes',
  electrician: 'Électricien, installations électriques',
  windows: 'Portes et fenêtres',
  garage: 'Portes de garage',
  furnishPicker: 'Monteur de meubles',
  glass: 'Verre et vitrage',
  fireplace: 'cheminées',
  parquet: 'Parquet, stratifié, moquette',
  isolation: 'Isolement',
  kitchen: 'Cuisines',
  decoration: 'Décoration',
  painting: 'Peinture',
  builder: 'Maçon, plâtre, béton',
  interiorDesign: 'Design d’intérieur',
  metal: 'Construction métallique, serrurier, forgeage',
  slabs: 'Dalles, carreaux',
  upholstery: 'Sellerie, sellerie',
  plumbing: 'plombier',
  carpenter: 'Charpentier',
  locksmith: 'Serrurier',
  gardener: 'Jardinier, horticulture',
  earthmoving: 'Construction de puits, terrassements, fondations',
  greenhouses: 'Serres',
  fences: 'Clôtures, portails, écrans d’intimité',
  remodeling: 'Remodelage, rénovation totale, extension',
  technician: 'technicien, installateur système',
  alarms: 'alarmes',
  housekeeping: 'Entretien ménager, superviseur',
  blinds: 'Stores, volets, auvents',
  confort: 'Chauffage, ventilation, climatisation, réfrigération',
  climate: 'Climatisation',
  cleaning: 'nettoyage',
  applianceTechnician: 'Technicien d’appareils',
  moving: 'déménagements',
  scaffolding: 'Échafaudages',
  paving: 'Travaux de pavage, routes, routes',
  pestControl: 'lutte antiparasitaire',
  constructionMaterials: 'Matériaux de construction',

  // ---Mechanic and transport
  mechanics: 'Mécanique, électronique',
  crane: 'Grue et transfert de véhicules',
  carWash: 'Lavage de voiture',
  electricCars: 'véhicules électriques',
  wheelbarrows: 'brouettes',
  chargingPoints: 'Installation de bornes de recharge',
  smallTransport: 'Transports, petits transports',
  largeTransport: 'Transports, grands transports',
  carPainting: 'Tôle et peinture',
  carService: 'Service de voiture(pré - ITV)',
  fineTunning: 'Installation',
  motorbike: 'Moto, quad',
  tuning: 'Tuning',

  // ---Care and aesthetics
  tattooArtist: 'Tatoueur',
  dentist: 'dentiste',
  massageTherapist: 'Masseuse',
  osteopathy: 'Ostéopathie',
  hairdresser: 'Coiffeur',
  beautician: 'Esthéticienne',
  rehabilitation: 'Réhabilitation',
  physiotherapist: 'physiothérapeute',
  makeupArtist: 'Maquilleuse',
  acupuncture: 'Acupuncture',
  motivationalCoach: 'Coach de motivation',
  personalTrainer: 'Entraîneur personnel',
  zumbaInstructor: 'Zumba Monitor',
  pilatesInstructor: 'Moniteur Pilates',

  // ---Technology and marketing
  programmer: 'Programmeur',
  webProgrammer: 'Programmeur Web',
  photographer: 'Photographe',
  webDesigner: 'concepteur Web',
  multimediaInstaller: 'installateur multimédia',
  applicationProgrammer: 'Programmeur d’applications',
  graphicDesigner: 'graphiste',
  computerTechnician: 'Technicien en informatique',
  marketingAndCommunication: 'Marketing et communication',
  printing: 'Impression',
  analyst: 'analyste',
  database: 'Base de données',
  machineLearning: 'Apprentissage automatique',
  productCustomization: 'Personnalisation du produit',
  merchandising: 'Merchandising',
  artificialIntelligence: 'Intelligence artificielle',
  cyberSecurity: 'Cybersécurité',
  itSystemsTechnician: 'technicien en systèmes informatiques',
  itSupportTechnician: 'technicien de support informatique',

  // --Leisure and gastronomy
  cook: 'Cuisiner',
  catering: 'Restauration',
  animator: 'Artiste',
  socioculturalAnimator: 'Animateur socioculturel',
  functionalDiversityMonitor:
    'Moniteur de loisirs pour les personnes présentant une diversité fonctionnelle',
  childrenMonitor: 'moniteur de loisirs pour enfants',
  freeTimeMonitor: 'Moniteur de loisirs et de temps libre',
  waiter: 'Serveur',
  soundTechnician: 'Technicien du son',
  dj: 'DJ',
  danceInstructor: 'Professeur de danse',

  // ---Others
  model: 'modèle',
  concierge: 'Concierge',
  administrative: 'administratif',
  receptionist: 'réceptionniste',
  lifeGuard: 'Sauveteur',
  commercial: 'Commercial',
  teacher: 'Enseignant',
  privateTeacher: 'Professeur particulier',
  pedagogue: 'Pédagogue',
  seamstress: 'Couturière',
  educationalAssistant: 'aide - enseignant',
  animalCaretaker: 'gardien d’animaux',
  parcelDeliveryMan: 'Messager',
  usedOilCollection: 'Collecte des huiles usagées',
  socialEducator: 'éducateur social',
  otherJob: 'Un autre emploi',

  // --------
  filter: 'filtre',
  filters: 'filtres',
  orderBy: 'Trier par',
  betterRating: 'Meilleure note',
  moreAcceptedOffers: 'Plus d’offres acceptées',
  youMustSelectAtLeastOneSubcategory:
    'Vous devez sélectionner au moins une sous - catégorie.',

  // COMPANY PROFILE
  sendJobOffer: 'Offrir du travail',
  message: 'Message',
  companyInfo: 'Biographie',
  seeMore: 'Voir plus',
  seeLess: 'Voir moins',
  customerReviews: 'Avis clients',
  noDetailsYet: 'La société n’a pas encore fourni de détails.',
  createFreeAccount: 'Créer un compte gratuit',

  // LEGAL FORMS
  freelance: 'Travailleurs indépendants',
  civilSociety: 'Société civile',
  collectiveSociety: 'Partenariat',
  limitedLiabilityCompany: 'société à responsabilité limitée',
  limitedSociety: 'Sociedad Limitada, S.L.',
  anonymousSociety: 'Sociedad Anónima, S.A.',
  cooperativeSociety: 'société coopérative',

  // REVIEWS
  valorations: 'Évaluations',
  comunication: 'Communication',
  times: 'Livraison dans les délais',
  asDescribed: 'Travaux finaux',
  recommend: 'Le recommanderiez-vous?',
  leaveAComment: 'Laisser un commentaire',

  // PAGES
  home: 'Démarrer',
  discover: 'Découvrir',
  inbox: 'boîte aux lettres',
  messages: 'Messages',
  offers: 'offres',
  board: 'Planche',
  posts: 'Publications',
  profile: 'Profil',
  category: 'Catégorie',
  newOffer: 'Nouvelle offre',

  // JOB OFFERS
  posted: 'publié',
  startDate: 'Date de début',
  preferredStartDate: 'Date souhaitée',
  endDate: 'Date de fin / livraison',
  mainDetails: 'Détails',
  otherDeatils: 'Autres précisions',
  receivedOffers: 'Offres reçues',
  createAnOffer: 'Soumettre une offre',
  noOfferYet: ' Ce poste n’a pas encore reçu d’offres, soyez le premier! ',
  offerAlert:
    'Pour créer une offre, vous devez vous connecter en tant qu’entreprise.',
  rateType: 'type de tarif',
  flatRate: 'Forfaitaire',
  day: 'jour',
  month: 'mois',
  year: 'année',
  amount: 'Quantité',
  addEndDate: 'Ajouter une date de fin',
  attachFile: 'Joindre un fichier',
  sendOffer: 'Envoyer l’offre',
  offerMessageHelper: 'Il sera affiché comme le premier message du chat.',
  fileError: 'Erreur de fichier: seuls .pdf sont acceptés et le vôtre l’est',
  maxFileSize: 'La taille maximale du fichier est de 10 Mo',
  budget: 'Budget',
  editPost: 'Modifier la publication',
  nothingPostedYet: 'Vous n’avez encore rien posté',
  tryFromTheSearchbar: 'Essayer à partir de la barre de recherche supérieure',
  nothingInDrafts: 'Il n’y a rien dans les projets',
  nothingInAssigned: 'Il n’y a rien dans assigné',
  nothingInArchived: ' Il n’y a rien dans l’archivage ',
  nothingInRejected: 'Vous n’avez pas d’offres rejetées',
  nothingInAccepted: 'Vous n’avez pas d’offres acceptées',
  nothingInNegotiating: 'Envoyer des offres pour entamer la négociation',
  nothingFound: 'Pas de résultats de recherche',
  StartSendingOffersToPotencialCustomers:
    'Commencez par envoyer des offres aux posts clients potentiels',
  'errors.no_active_subscription_found':
    'Vous devez souscrire à un abonnement pour pouvoir envoyer des offres.',

  // Questions
  'question.flexibleHours':
    ' Avez - vous de la flexibilité avec les horaires ? ',
  'question.availableToNegociate':
    'Seriez - vous en mesure de négocier le budget ?',
  'question.payInAdvance': ' Paieriez - vous à l’avance si nécessaire ? ',
  'question.accesible': 'L’endroit est-il facilement accessible?',
  'question.availabilityForTravel':
    'Avez-vous la disponibilité pour déplacer le véhicule?',
  'question.parking': 'Y a-t-il un parking dans la zone?',
  'question.weekends': ' Le travail le week - end est - il autorisé ? ',
  'question.material': 'Le matériel est - il fourni ?',
  'question.neededParts': ' Avez - vous les pièces dont vous avez besoin ? ',
  'question.crane': ' Avez - vous besoin d’une grue ? ',
  'question.bigTrees': ' A - t - il de grands arbres ? ',
  'question.water':
    ' Avez - vous des fontaines ou d’autres zones avec de l’eau ? ',
  'question.workAtHome': ' Le travail peut - il être fait à la maison ? ',
  'question.goToTheHome': ' Avez - vous besoin qu’ils rentrent chez vous ? ',
  'question.squareMeters': 'Combien de m² le site possède-t-il ?',

  // Answers
  'answer.flexibleHours': 'Flexibilité dans les horaires',
  'answer.availableToNegociate': 'Budget négociable',
  'answer.payInAdvance': 'Disponibilité à payer à l’avance',
  'answer.accesible': 'Lieu d’accès facile',
  'answer.availabilityForTravel': 'Disponibilité des voyages',
  'answer.parking': 'Parking dans la zone',
  'answer.weekends': 'Le travail est autorisé le week - end',
  'answer.material': 'Le client fournit le matériel',
  'answer.neededParts': 'Disposer des pièces nécessaires',
  'answer.crane': 'Grue nécessaire',
  'answer.bigTrees': 'Grands arbres',
  'answer.water': 'Fontaines ou autres zones contenant de l’eau',
  'answer.workAtHome': 'Possibilité de travailler à domicile',
  'answer.goToTheHome': 'Il est nécessaire d’aller à la maison',
  'answer.squareMeters': 'Mètres carrés du site',

  // OTHERS
  shareTheApp: 'Partager l’application',
  shareTheAppMessage:
    'Partagez l’application Jobsaun avec vos amis, montrez - leur à quel point vous êtes bon en trouvant de nouvelles applications utiles.',
  goToChat: 'Aller au chat',
  seeMoreJobs: 'Voir plus d’œuvres',
  sendingYourOffer: 'Envoi d’offre',
  itCanTakeAMoment:
    'Ce processus peut prendre un certain temps.S’il vous plaît, soyez patient.',
  offerSuccessSent: 'Offre envoyée avec succès',
  continueWith: 'Que voulez-vous faire ensuite?',
  users: 'utilisateurs',
  yes: 'Oui',
  no: 'Non',
  save: 'Enregistrer',
  saveDraft: 'Enregistrer le brouillon',
  toPost: 'Publier',
  saving: 'Épargne...',
  posting: 'Édition...',
  postingJob: 'Votre travail est en cours de publication',
  pleaseBePatient: 'Veuillez attendre la fin de l’action.',
  nothingHere: 'Rien par ici...',
  tryAnother: 'Essayez une autre recherche ou catégorie.',
  settings: 'Paramètres',
  account: 'Compte',
  sent: 'Envoyé',
  youHaveToSignIn:
    'Vous devez être connecté pour accéder à cette fonctionnalité.',
  loading: 'Chargement en cours...',
  all: 'Tous',

  // POSTED JOBS
  postedJobs: 'Ouvrages publiés',
  send: 'Envoyer',
  cancel: 'Annuler',
  acceptedOffer: 'Offre acceptée',
  cancelledOffer: 'Offre annulée',
  acceptOffer: 'Accepter l’offre',
  draft: 'Projet',
  negotiating: 'Négociation',
  rejected: 'Rejeté',
  accepted: 'Accepté',
  cancelled: 'Annulé',
  proposed: 'proposition',
  assigned: 'Attribué',
  noDate: 'Non daté',
  anyJobWasFound:
    'Aucun travail publié correspondant aux catégories de l’entreprise n’a été trouvé.',
  theProposalHasBeenSent: 'La proposition a été envoyée avec succès',
  youAlreadySentThisJob: 'Vous avez déjà envoyé ce travail à l’entreprise.',
  theCompanyAlreadySentYouAnOffer:
    'L’entreprise vous a déjà envoyé une offre pour cet emploi.',
  theCompanyHasCancelTheOffers:
    'L’entreprise a annulé des offres pour ce travail.',

  // MENUS
  moveToDraft: 'Passer aux brouillons',
  edit: 'Modifier',
  delete: 'Supprimer',
  newFirst: 'Nouveau premier',
  oldFirst: ' Les anciens d’abord ',
  edited: 'Edité le',

  // NEW JOB
  newJob: 'Nouveaux travaux',
  title: 'Titre',
  subCategory: 'Sous - catégorie',
  explainTheNeededService:
    'Expliquez avec autant de détails que possible le service dont vous avez besoin, afin que les professionnels vous fassent l’offre la plus équitable.',
  postDescription: 'Description du service',
  city: 'Ville',
  helperCity:
    'Quelque chose a mal tourné pour obtenir la ville, veuillez vérifier le code postal ou l’écrire à la main.',
  uploadPictures: 'Images',
  maximumSizeExceded: 'La taille maximale du fichier a été dépassée',
  maximumNumberOfFiles: 'Le nombre maximal de fichiers a été dépassé',
  notAllowFile: 'Le fichier n’est pas autorisé.',
  allowFiles: 'Fichiers autorisés',
  otherDetails: 'Autres précisions',
  congrats: 'Félicitations!',
  postedSucceed: 'L’œuvre a été publiée avec succès.',
  justWait:
    'Il ne vous reste plus qu’à attendre de recevoir des offres d’entreprises intéressées.',
  seeTheJob: 'Voir l’emplacement',
  fiveDigits: 'Il doit y avoir 5 chiffres.',
  alreadyUploadThisFile: 'Vous avez déjà téléchargé ce fichier',
  optional: 'facultatif',
  willLoseTheProcessOnCancel: 'En annulant, vous perdrez tout progrès.',
  noCategoryFound:
    'Désolé, nous n’avons pas trouvé de catégorie avec la recherche effectuée. Veuillez sélectionner la catégorie la plus appropriée parmi les options ci-dessous.',
  weHaveSelectedTheNextCategory:
    'Sur la base de votre recherche, nous avons sélectionné la catégorie suivante, si vous pensez qu’elle n’est pas correcte, veuillez sélectionner la plus appropriée.',
  localitation: 'Emplacement',
  searchCompanies: 'Recherche d’entreprises',
  lookingCompanies: 'Recherche d’entreprises',
  thisCanTakeAWhile:
    'Cela peut prendre un certain temps, s’il vous plaît soyez patient.',
  weHaveFound: 'Nous avons trouvé...',
  whatDoYouWantToDoNext: 'Que voulez-vous faire ensuite?',
  seeCompanies: 'Voir entreprises',
  continueWithTheApplication: 'Poursuivre la demande',
  weAreSorryWeStillGrowing:
    ' Désolé, nous continuons à grandir et de plus en plus d’entreprises rejoignent Jobsaun.Poursuivez la demande aux entreprises de vous contacter.',
  whatBudgetDoYouHave: 'Quel budget avez-vous?',
  budgetSubtitle:
    'Ceci est juste une approximation pour informer les entreprises du montant maximum que vous pouvez vous permettre.',
  recommended: 'Recommandé',
  lessThan: 'Jusqu’à',
  moreThan: 'supérieur à',
  whenDoYouNeedIt: 'Quand en avez-vous besoin?',
  tellUsYourPreferences: 'Dites-nous vos préférences',
  doYouWantToProvideAnImage:
    'Voulez-vous fournir une image qui aide le professionnel?',
  dragAndDropOrBrowse: 'Glisser-déposer ou',
  browse: 'cliquez ici',
  allow: 'Autorisé',
  maxFilesSize: 'taille maximale',
  of: 'de',
  image: 'Image',
  'imageErrors.file-too-large': `Le fichier ${args[0]} est trop volumineux. La taille maximale est ${args[1]} et la taille du fichier est ${args[2]}`,
  'imageErrors.file-invalid-type': `Le type de fichier doit être l’un des suivants : ${args[0]}`,
  'imageErrors.too-many-files': `Trop de fichiers sélectionnés, le maximum est ${args[0]} fichiers.`,
  'imageErrors.bad-file': `Le fichier ${args[0]} ne peut pas être joint. S’il vous plaît, essayez-en un autre.`,
  postDetails: 'Détails de la demande',
  'post.sending': 'Votre demande est en cours de présentation',
  'post.pleaseBePatient':
    'Cela peut prendre un moment.S’il vous plaît, soyez patient.',
  'post.added': 'Publication créée avec succès.',
  'error.post.adding':
    'La publication n’a pas pu être créée.S’il vous plaît, essayez une autre fois.',
  'error.post.updating':
    'Impossible de mettre à jour la publication. Veuillez réessayer ultérieurement.',
  'error.post.deleting':
    ' Le message n’a pas pu être supprimé.S’il vous plaît, essayez une autre fois.',

  // NEW OFFER
  sendOfferTo: 'Vous allez envoyer une offre pour l’offre d’emploi:',
  sendingOffer: 'Envoi de l’offre...',
  theCompanyIsInterested: `La société ${args[0]} est intéressé par votre annonce.Acceptez l’offre, si vous êtes intéressé, pour entamer la conversation. Note : Toutes les autres offres seront automatiquement rejetées".`,
  offerAlreadySent:
    ' Une seule enchère est autorisée par annonce.Pour envoyer une autre offre, vous devez annuler la précédente.',
  youNeedAPlan:
    'Oups! Il semble que votre essai gratuit soit terminé.Veuillez souscrire à un plan afin de pouvoir envoyer une offre.',
  yourOfferHasAlreadyBeenAccepted:
    ' Votre offre a déjà été acceptée.Allez sur le chat pour plus de détails.',
  yourOfferHasAlreadyBeenRejected:
    ' Votre offre a été automatiquement rejetée parce que le client en a accepté une autre.Nous vous souhaitons bonne chance pour le prochain.',
  doYouWantToCancelNow: 'Voulez-vous l’annuler maintenant?',
  offercancelledSuccesfully: 'Offre annulée avec succès',
  finalize: 'Finition',
  toCompleteThePost: 'Pour remplir la demande, vous avez besoin d’un compte.',
  createAccountAndFinalize: 'Créer un compte et terminer',

  // PROFILE
  details: 'Détails du compte',
  companyDetails: 'coordonnées de l’entreprise',
  ratings: 'Évaluations',
  privacy: 'Confidentialité et cookies',
  legal: 'Juridique',
  info: 'Informations',
  updates: 'Journal de mise à jour',
  current: 'Actuel',
  subscription: 'Abonnement',
  accountState: 'État du compte',
  active: 'Actif',
  deactivate: 'Off',
  trial: 'Test',
  activeMessage: ' Tout se passe bien, aucune action n’est requise. ',
  trialMessage: 'Profitez de l’essai gratuit.',
  trialDescription:
    'Il sera automatiquement conforme lorsque l’une de vos offres aura été acceptée.',

  guest: 'Invité',
  contact: 'Contact',

  // SUBSCRIPTION ERRORS
  deactivateDescription:
    'Tant que votre compte reste désactivé, vous ne pourrez pas envoyer d’offres ou communiquer avec de nouveaux clients.',
  deactivateDefaultMessage:
    'Nous avons une sorte de problème avec votre compte.N’hésitez pas à nous contacter.',

  'error.payment': ' Nous avons du mal à être payés. ',
  'error.subscription': 'Vous n’avez encore souscrit à aucun plan.',
  'error.trialOff':
    ' Votre essai gratuit est terminé.Veuillez accéder à un abonnement pour continuer à profiter de toutes les fonctionnalités.',
  'error.404': 'L’abonnement n’existe pas.',
  'error.incomplete': 'La tentative de collecte initiale a échoué.',
  'error.past_due':
    'Le paiement n’a pas pu être effectué à la date d’échéance.',
  'error.unpaid': 'Le paiement n’a pas pu être effectué.',
  'error.cancelled': 'Votre abonnement a été annulé',
  'error.incomplete_expired': 'La tentative d’abonnement a expiré avec erreur',

  // DETAILS
  changePassword: 'Changer le mot de passe',
  editData: 'Modifier vos données',
  editBasicData: 'Modifier les données de base',
  editCompanyData: 'Modifier les données de l’entreprise',
  deleteAccount: 'Supprimer le compte',
  signOut: 'Déconnexion',
  confirmationEmailWillBeSent: 'Vous recevrez un e-mail de confirmation.',
  legalForm: 'Constitution',
  bio: 'Informations sur l’entreprise',
  bioPlaceHolder:
    'Décrivez votre entreprise du mieux possible, cette introduction peut convaincre de nombreux clients.',
  changeYourAvatar: 'Changer d’avatar',
  background: 'Contexte',
  hair: 'cheveux',
  skin: 'peau',
  features: 'Traits',
  profileUpdatedSuccessfully: 'Profil mis à jour avec succès.',
  accountDeleted: 'Compte supprimé avec succès.',

  // SUBSCRIPTION PAGE
  postulate:
    'Postulez à partir de centaines d’emplois et envoyez des devis à des clients potentiels.',
  selectThePlan: 'Sélectionnez le plan qui vous convient le mieux.',
  annual: 'Annuel',
  monthly: 'Mensuel',
  promo: 'Promo',
  uniquePayment: 'paiement unique',
  annualDescription: 'Facturé annuellement à titre de paiement récurrent.',
  monthlyDescription:
    'Facturé mensuellement en tant que paiement récurrent.Sans permanence.',
  uniquePaymentDescription: 'Facturé en un seul paiement.',
  billedAnnualy: 'facturé annuellement.',
  billedmonthly: 'facturé mensuellement.',
  saveA: 'Enregistrer un',
  withAnnualPayment: 'avec le paiement annuel.',
  continue: 'Continuer',
  overview: 'Généralités',
  invoices: 'factures',
  paymentDetails: 'Détails de paiement',
  yourSubscriptionPlan: 'Votre abonnement',
  paymentMethod: 'Mode de paiement',
  lastInvoice: 'Dernière facture',
  cancelPlan: 'Se désabonner',
  changePlan: 'Plan de changement',
  cardOf: 'Carte de',
  changePaymentMethod: 'Changer de mode de paiement',
  introduceAValidPaymentMethod:
    'Veuillez saisir un mode de paiement valide ci-dessous.',
  credit: 'crédit',
  debit: 'débit',
  cancelSubDialogTitle: 'Se désabonner',
  weSorryYouCancel:
    ' Nous sommes désolés que vous souhaitiez annuler votre abonnement. ',
  rememberThat: 'Souviens - toi que:',
  yourSubWillFinishAt:
    'Même si vous résiliez maintenant, vos avantages d’abonné continueront à fonctionner jusqu’au jour:',
  comeBack: 'Retour',
  cancelling: 'Annulation...',
  changing: 'Changer...',
  yourPlanWillBecancelledAt: 'Votre abonnement sera automatiquement résilié le',
  'plan.year': 'Annuel',
  'plan.month': 'Mensuel',
  'plan.year.cancelDialogBody':
    'Vous avez la possibilité de passer à un abonnement mensuel.',
  'plan.month.cancelDialogBody':
    'Vous avez la possibilité de passer à un abonnement annuel et d’en enregistrer un',
  'plan.year.changeQ': 'Voulez-vous passer au forfait mensuel?',
  'plan.month.changeQ': 'Voulez-vous passer au plan annuel?',
  withAnnualPlanYouCanSave: 'Avec le plan annuel, vous économisez un',
  whenChangePlan:
    'Votre plan sera modifié dès maintenant et les factures seront calculées au prorata de votre période de facturation actuelle.',
  moreOptions: 'Plus d’options',
  differentAccountHolder: 'Le titre est différent',
  noActiveSubscription: 'Vous n’avez pas d’abonnements actifs.',
  subscribeToAPlan:
    'Abonnez-vous à l’un des plans pour profiter de toutes les fonctionnalités',
  seePlans: 'Voir les plans',
  plans: 'Plans',
  status: 'État',
  'status.active': 'Actif',
  'status.incomplete': 'Incomplet',
  'status.incomplete_expired': 'Expiré',
  'status.trialing': 'Test',
  'status.past_due': 'Payable',
  'status.updaid': 'Non payé',
  'status.canceled': 'Annulé',

  // CHECKOUT
  checkout: 'Détails de paiement',
  finishCheckOut:
    'Terminez le processus de paiement pour terminer votre abonnement.',
  yourOrder: 'Votre abonnement',
  annualSubscription: 'Abonnement annuel',
  monthlySubscription: 'Abonnement mensuel',
  taxes: 'Impôts',
  total: 'Total',
  subTotal: 'Sous - total',
  subscribe: 'S’abonner',
  weWillNotStoreYourData:
    ' Nous ne verrons ni ne stockerons vos informations de paiement.Le processus de paiement et les données sont cryptés directement sur le système hautement sécurisé de Stripe.',
  checkoutName: 'Nom du titulaire',
  securePayment: 'Paiement',
  safeAndSecure: 'sûr et sécurisé',
  garanteed: 'garanti',
  thankYou: 'Merci!',
  subscriptionSucceed: 'Votre abonnement a été complété avec succès.',
  accept: 'OK',
  somethingWentWrong: 'Quelque chose a mal tourné...',
  thePaymentCouldNotBeDone:
    'Le paiement n’a pas pu être effectué.Veuillez vérifier que les données saisies sont correctes',

  // CHAT
  tabAll: 'Tout',
  tabAccepted: 'Accepté',
  tabRejected: 'Rejeté',
  tabNegotiation: 'Négociation',
  tabProposal: 'propositions',
  tabStandBy: 'boucles d’oreilles',
  chat: 'Chat',
  sendAMessage: 'Ecrire un message...',
  acceptToWrite: 'Acceptez l’offre d’écrire...',
  theUserHasToAccept:
    'Votre offre doit être acceptée pour commencer à écrire...',
  jobOfferIdText: 'ad id',
  attachedFileText: 'Pièce jointe',
  offerIdText: 'ID de l’offre',
  theUserIsStudingYourOffer:
    'Il étudie votre offre.Une fois qu’il l’accepte, vous pouvez entamer une conversation pour en décrire les détails.',
  theUserHasAccepted:
    'Il a accepté votre offre et vous pouvez discuter des détails.',
  yourOfferHasBeenRejected: 'Vous avez déjà accepté une autre offre.',
  thinksThatYouCanBeInterested:
    'pensez que ce travail pourrait vous intéresser.',
  valorate: 'Valeur',
  companyNotAvailable:
    'La société n’a pas votre compte actif pour le moment, veuillez essayer une autre offre.',
  offer: 'Offre',
  theOfferNeedToBeAccepted: [
    'Pour établir une conversation avec l’entreprise, vous devez accepter son offre.',
    'Une fois que vous acceptez une offre, les autres seront automatiquement annulées.',
    'Avant d’accepter, vous pouvez en savoir plus sur l’entreprise en cliquant sur leur nom.'
  ],
  you: 'vous',

  // ALERTS
  areYouSure: 'Méfiez-vous!',
  youAreGoingToDelete:
    'Vous allez supprimer le message et les offres que j’ai reçus.Voulez - vous continuer ?',
  deleteJobMessage:
    'Cette action ne peut pas être annulée, si vous voulez modifier des détails, essayez de la transmettre aux brouillons.',
  youAreGoingToDeleteYourAccount: 'Vous allez supprimer votre compte',
  allYourDataWillBeDeleted:
    ' Toutes vos données seront supprimées de notre base de données.Cette action ne peut être annulée.',
  introduceYourPassword: 'Entrez votre mot de passe.',
  deleting: 'Éliminant...',
  incorrectPassword: 'Mot de passe incorrect.',
  passwordChange: 'Changer le mot de passe',
  currentPassword: 'Mot de passe actuel',
  newPassword: 'Nouveau mot de passe',
  change: 'Changement',
  minimum8characteres: 'Doit comporter au moins 8 caractères',
  anErrorHappens: 'Une erreur s’est produite.Essayez plus tard',
  fix: 'correction',
  new: 'nouveauté',
  news: 'Dernières modifications',
  update: 'Mise à jour',
  later: 'Plus tard',
  newUpdateTitle: 'Mise à jour disponible',
  newUpdateAvailable:
    'Une nouvelle version est disponible.S’il vous plaît mettre à jour pour les dernières nouvelles et améliorations.',
  editingRating:
    ' Vous avez déjà apprécié cette entreprise.Vous pouvez modifier votre note ci - dessous.',
  commentSentSuccesfully: 'Votre avis a été soumis avec succès.',
  onlyAvailableForUsers:
    'Cette fonctionnalité n’est disponible que pour les personnes disposant d’un compte.',
  youShouldCompleteTheRequiredFields:
    'Vous devez remplir les champs obligatoires.',
  postUpdatedSuccessfully: 'Publication mise à jour avec succès.',
  jobDeletedSuccesfully: 'Publication supprimée avec succès',
  errorLoadingData:
    'Oups! Il y a eu une erreur dans l’obtention des données.Veuillez essayer plus tard.',
  noResultsWasFound:
    'Désolé, nous n’avons trouvé aucun résultat.Testez en modifiant les filtres par le haut.',

  // DELETE ACCOUNT PAGE
  pleaseIndicateReason:
    'Veuillez nous dire pourquoi vous souhaitez supprimer votre compte.',
  requestSended:
    'Votre demande a été soumise avec succès.Une fois que nous l’aurons étudié et que tout sera correct, nous procéderons à la suppression de votre compte.',
  toEliminateYouShouldIndicateAReason:
    'Pour que nous puissions supprimer votre compte, vous devez nous en indiquer la raison.',
  // COUNTRIES
  esp: 'Espagne',
  ch: 'Suisse',

  deletedAccount: 'Compte supprimé',

  // PLANS NAMES
  Anual: 'Annuel',
  Mensual: 'Mensuel'
})

export default fr
