import React, { useEffect, useMemo, useState } from 'react'
import * as serviceWorker from '../serviceWorkerRegistration'

const ServiceWorkerContext = React.createContext()

const ServiceWorkerProvider = ({ children }) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)
  const [isUpdateAvailable, setUpdateAvailable] = useState(false)

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setWaitingServiceWorker(registration.waiting)
        setUpdateAvailable(true)
      },
    })
  }, [])

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an incosistent state
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload()
        }
      })
    }
  }, [waitingServiceWorker])

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        const appVersion = process.env.REACT_APP_VERSION
        localStorage.setItem('version', appVersion)
        if (waitingServiceWorker) {
          // We send the SKIP_WAITING message to tell the Service Worker
          // to update its cache and flush the old one
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
        window.location.reload()
      },
    }),
    [isUpdateAvailable, waitingServiceWorker]
  )

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>
}

export { ServiceWorkerContext, ServiceWorkerProvider }
