import React, { useState, useEffect, useContext } from 'react'
import { auth } from 'apis/firebase'
import { SettingProvider } from './SettingContext'
import { useQuery } from '@apollo/client'
import { GET_USER } from 'apis/querys'
import { getUserCountryCode } from 'helpers/getUserCountryCode'

const UserContext = React.createContext([{}, () => {}])

const defaultUserState = {
  role: undefined,
  id: '',
  email: '',
  name: '',
  uid: ''
}

const UserProvider = ({ children }) => {
  const [authStateChanged, setAuthStateChanged] = useState(false)
  const [user, setUser] = useState(defaultUserState)
  const [isLoading, seIsLoading] = useState(true)

  const { data, loading } = useQuery(GET_USER, {
    variables: { userId: user.id, uid: user.uid },
    skip: !user.id
  })

  useEffect(() => {
    if (!loading) {
      if (data && data.getUser) {
        setUser((prev) => ({ ...prev, ...data.getUser }))
      } else {
        getUserCountryCode().then((country) => {
          setUser((prev) => ({ ...prev, country }))
        })
      }
      seIsLoading(false)
    }
  }, [data, loading])

  useEffect(() => {
    auth.onAuthStateChanged((userCredentials) => {
      if (userCredentials) {
        // User is signed in
        console.log('Is sign in')
        const localUser = localStorage.getItem('user')
        if (localUser) {
          const localData = JSON.parse(localUser)
          setUser({ ...localData, uid: userCredentials.uid })
        }
      } else {
        console.log('Is sign out')
        // User is signed out
        // Kepp the version value, for NewsDialog to work.
        localStorage.removeItem('user')
        // localStorage.removeItem('settings')

        setUser({ role: 'guest', id: '', email: '' })
      }
    })

    return () => setUser(defaultUserState)
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        setUser,
        authStateChanged,
        setAuthStateChanged
      }}
    >
      <SettingProvider>{children}</SettingProvider>
    </UserContext.Provider>
  )
}

const useUser = () => {
  const context = useContext(UserContext)
  return context
}

export { UserContext, UserProvider, useUser }
