import { Box, CardContent, Grid, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { CustomCard } from 'components'
import { hear } from 'constants/HearAboutUs'
import { useI18N, useThemeMode } from 'hooks'
import { useState } from 'react'

export default function AboutUs({ value, id, onChange }) {
  const { t } = useI18N()
  const [selected, setSelected] = useState(value)

  const handleChange = (source) => {
    setSelected(source)
    onChange({ [id]: source })
  }

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography
          fontSize='1.4rem'
          fontWeight={600}
          lineHeight='1.1em'
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('howDidYouHear')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {hear.map(({ id, color, icon, name }) => (
          <Grid key={id} item xs={6}>
            <SourceCard
              color={color}
              icon={icon}
              title={name}
              selected={selected === id}
              onClick={() => handleChange(id)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

const SourceCard = ({ color, title, icon, selected, onClick }) => {
  const { darkMode } = useThemeMode()
  return (
    <CustomCard
      sx={{
        bgcolor: darkMode ? 'background.default' : blueGrey[50],
        '&::before': {
          transition: 'all 0.25s ease-in-out',
          content: '""',
          position: 'absolute',
          display: 'block',
          zIndex: 0,
          inset: 0,
          background: color,
          opacity: selected ? 1 : 0
        }
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          height: 70,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {
          <>
            <Box
              component='img'
              src={icon}
              width={title ? 'auto' : '80%'}
              maxWidth={120}
              maxHeight={50}
              sx={{
                transition: 'all 0.15s ease-in-out',
                filter: selected
                  ? 'brightness(1)'
                  : darkMode
                  ? 'brightness(1) grayscale(1)'
                  : 'brightness(0) grayscale(1)',
                opacity: selected ? 1 : 0.2
              }}
            />
            {title && (
              <Typography
                fontWeight={600}
                fontSize={20}
                lineHeight={1}
                ml={icon ? 1 : 0}
                color='whitesmoke'
                sx={{
                  transition: 'all 0.15s ease-in-out',
                  filter: selected
                    ? 'brightness(1)'
                    : darkMode
                    ? 'brightness(1) grayscale(1)'
                    : 'brightness(0) grayscale(1)',
                  opacity: selected ? 1 : 0.2
                }}
              >
                {title}
              </Typography>
            )}
          </>
        }
      </CardContent>
    </CustomCard>
  )
}
