export const prodKeys = {
  // Stripe
  stripeSecretKey: process.env.REACT_APP_STRIPE_SECRET_KEY,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  stripeWebhookKey: process.env.REACT_APP_STRIPE_WEBHOOK,
  stripeAnnualPriceId: process.env.REACT_APP_ANNUAL_PRICE_ID,
  stripeMonthlyPriceId: process.env.REACT_APP_MONTHLY_PRICE_ID,
  // GraphQl
  graphBackendUri: process.env.REACT_APP_GRAPH_BACKEND_URI,
  graphSubscriptionUri: process.env.REACT_APP_SUBSCRIPTION_URI,
  backendURI: process.env.REACT_APP_BACKEND_URI,
  // Firebase
  firebaseApiKey: process.env.REACT_APP_FIREBASE_KEY,
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGIN_SENDER_ID,
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  firebaseMeasurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

export const devKeys = {
  // Stripe
  stripeSecretKey: process.env.REACT_APP_STRIPE_TEST_SECRET_KEY,
  stripePublicKey: process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY,
  stripeWebhookKey: process.env.REACT_APP_STRIPE_TEST_WEBHOOK,
  stripeAnnualPriceId: process.env.REACT_APP_ANNUAL_TEST_PRICE_ID,
  stripeMonthlyPriceId: process.env.REACT_APP_MONTHLY_TEST_PRICE_ID,
  // GraphQl
  graphBackendUri: process.env.REACT_APP_DEV_GRAPH_BACKEND_URI,
  graphSubscriptionUri: process.env.REACT_APP_DEV_SUBSCRIPTION_URI,
  backendURI: process.env.REACT_APP_DEV_BACKEND_URI,
  // Firebase
  firebaseApiKey: process.env.REACT_APP_DEV_FIREBASE_KEY,
  firebaseAuthDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId:
    process.env.REACT_APP_DEV_FIREBASE_MESSAGIN_SENDER_ID,
  firebaseAppId: process.env.REACT_APP_DEV_FIREBASE_APP_ID
}
