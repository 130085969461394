import { createContext, useMemo } from 'react'
import { ThemeProvider, createTheme, alpha } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { useSettings } from 'hooks'

const SettingContext = createContext([{}, () => {}])

const SettingProvider = ({ children }) => {
  const { settings, updateSettings } = useSettings()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        updateSettings({ theme: settings.theme === 'light' ? 'dark' : 'light' })
      }
    }),
    [settings.theme]
  )

  const getDesignTokens = (mode) => ({
    palette: {
      mode,
      primary: {
        main: '#52AEB9'
      },
      secondary: {
        main: '#b95c52'
      },
      ripple: {
        main: '#beced1'
      },
      success: {
        main: '#39BE6E'
      },
      warning: {
        main: '#F7C056'
      },
      error: {
        main: '#F25B5B'
      },
      contrast: {
        main: '#ffffff'
      },
      text: {
        primary: '#1E1E1E',
        secondary: '#808080',
        hint: '#AAAAAA'
      },
      background: {
        default: '#F5FAFB',
        contrast: '#D9EDF0',
        card: '#FFFFFF'
      },
      ...(mode === 'dark' && {
        text: {
          primary: '#E1E1E1',
          secondary: '#808080',
          hint: '#606060'
        },
        background: {
          default: '#191b1a',
          contrast: '#182729',
          card: '#0D0D0D'
        }
      })
    },
    shadows: {
      0: 'none',
      1: '0px 1px 4px rgba(0,0,0,0.1)',
      4: '0px 1px 10px rgba(0, 0, 0, 0.1)',
      6: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      8: '0px 1px 20px rgba(0,0,0,0.1)',
      24: '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
    },
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      h2: {
        fontWeight: 600,
        fontSize: '1.7rem',
        lineHeight: 1.1
      },
      h6: {
        fontWeight: 500,
        fontSize: '1.3rem',
        lineHeight: 1.6
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        h2 {
          &.MuiTypography-root{
            margin-bottom: 12px;
          }
        }
          h6 {
            &.MuiTypography-root{
              margin-bottom: 8px;
            }
          }
        `
      },
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          TouchRippleProps: {
            sx: {
              color: (theme) => alpha(theme.palette.text.primary, 0.1)
            }
          }
        }
      }
    }
  })

  // Theme with togle
  const theme =
    process.env.NODE_ENV === 'development'
      ? useMemo(
          () => createTheme(getDesignTokens(settings.theme)),
          [settings.theme]
        )
      : useMemo(
          () =>
            createTheme(getDesignTokens(prefersDarkMode ? 'dark' : 'light')),
          [prefersDarkMode]
        )

  return (
    <SettingContext.Provider value={{ settings, colorMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </SettingContext.Provider>
  )
}

export { SettingContext, SettingProvider }
