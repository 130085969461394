import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER } from 'apis/querys'
import { Box, Grid, Typography, Skeleton } from '@mui/material'
import {
  AccountStateCard,
  ButtonsList,
  ProfileInfo,
  ScrollableContainer,
  Spacer
} from 'components'
import { getSubscription } from 'apis/stripe'
import { getDate, getRandomId } from 'helpers'
import { ProfileMenu } from 'constants/ProfileMenu'
import guestPicture from 'assets/imgs/guest.jpg'
import { useI18N } from 'hooks'

const defaultAccountValues = {
  subscriptionId: '',
  accountState: undefined,
  problem: ''
}

export default function LoggedUser({ user }) {
  const { t } = useI18N()
  const { userMenu, companyMenu } = ProfileMenu()
  const [state, setState] = useState(defaultAccountValues)

  const { loading, data } = useQuery(GET_USER, {
    errorPolicy: 'all',
    variables: {
      userId: user.id,
      uid: user.uid
    }
  })

  useEffect(() => {
    return () => setState(defaultAccountValues)
  }, [])

  useEffect(() => {
    let isMounted = true

    if (!loading && data && user.role === 'company' && data.getUser) {
      const sub = data.getUser.plan
      const subscriptionId = sub.plan_id
      const trial = sub.trial

      if (subscriptionId !== '') {
        getSubscription(subscriptionId).then((resp) => {
          if (resp.statusCode && resp.statusCode === 404) {
            if (isMounted) {
              setState((prev) => ({
                ...prev,
                accountState: 'deactivate',
                problem: '404'
              }))
            }
            return
          }
          let subStatus
          let subsProblem = ''

          if (resp.cancel_at_period_end) {
            const today = new Date()
            let cancelDate = resp.current_period_end * 1000
            cancelDate = new Date(getDate(cancelDate))
            subStatus =
              resp.status === 'canceled' && cancelDate < today
                ? 'deactivate'
                : 'active'
          } else {
            subStatus = resp.status === 'active' ? 'active' : 'deactivate'
          }

          subsProblem = subStatus === 'active' ? '' : resp.status

          if (isMounted) {
            setState({
              ...state,
              subscriptionId,
              accountState: subStatus,
              problem: subsProblem
            })
          }
        })
      } else if (trial) {
        setState({
          ...state,
          accountState: 'trial'
        })
      } else {
        setState({
          ...state,
          accountState: 'deactivate',
          problem: 'trialOff'
        })
      }
    }
    return () => (isMounted = false)
  }, [loading, data])

  return (
    <ScrollableContainer>
      <Spacer m={2} pt={6}>
        {loading ? (
          <LoadingAccount />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <ProfileInfo
              image={data.getUser?.picture || guestPicture}
              alt={data.getUser?.name}
              userName={
                user.role === 'company'
                  ? data.getUser?.name
                  : data.getUser?.public_name
              }
              legalForm={
                user.role === 'company'
                  ? t(data.getUser?.company_data.legal_form)
                  : undefined
              }
            />
            <Grid
              container
              justifyContent='center'
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '20px',
                mt: user.role === 'company' ? 2 : 4,
                py: 3,
                maxWidth: (theme) => theme.breakpoints.values.sm
              }}
            >
              <Grid item xs={12}>
                <Box>
                  {user.role === 'company' && (
                    <AccountStateCard
                      state={state.accountState}
                      problem={state.problem}
                    />
                  )}
                  {/* <InstallListItem /> */}
                  <ButtonsList
                    list={user.role === 'user' ? userMenu : companyMenu}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Spacer>
    </ScrollableContainer>
  )
}

const LoadingAccount = () => (
  <Box sx={{ width: '100%' }}>
    <Skeleton variant='circular' width={88} height={88} sx={{ mx: 'auto' }} />
    <Typography textAlign='center' fontSize='1.5rem'>
      <Skeleton width={130} sx={{ mx: 'auto', mt: 2, mb: 3 }} />
    </Typography>
    <Grid
      container
      sx={{
        bgcolor: 'background.paper',
        borderRadius: '20px',
        p: 3,
        maxWidth: (theme) => theme.breakpoints.values.sm,
        mx: 'auto'
      }}
    >
      <Grid item xs={12}>
        {new Array(4).fill('').map(() => (
          <Skeleton
            variant='rectangular'
            key={getRandomId(8)}
            width='100%'
            height={48}
            sx={{ borderRadius: 2, mb: 1 }}
          />
        ))}
      </Grid>
    </Grid>
  </Box>
)
