import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import { GET_COMPANIES } from 'apis/querys'
import {
  CompanyResumeCard,
  InfoMessage,
  ScrollableContainer,
  Spacer
} from 'components'
import { routes } from 'constants/Routes'
import { useI18N, useSearchParams } from 'hooks'
import { useHistory } from 'react-router-dom'

export default function FoundCompanies() {
  const { t } = useI18N()
  const history = useHistory()
  const state = history.location.state
  const params = useSearchParams(['province', 'category', 'search']) ?? {}

  const { data, loading } = useQuery(GET_COMPANIES, {
    fetchPolicy: 'network-only',
    variables: {
      province: params?.province,
      category: params?.category,
      bio: params?.search,
      limit: state?.count ?? 50,
      offset: 0
    }
  })

  const companies = data?.companies ?? []

  return (
    <ScrollableContainer
      title={t('foundCompanies')}
      appBarProps={{
        hasBack: true
      }}
    >
      <Spacer id='companies-container' m={2}>
        <Grid container spacing={2}>
          {loading ? (
            <Loading />
          ) : companies.length > 0 ? (
            companies.map((company) => (
              <Grid key={company._id} item xs={12}>
                <CompanyResumeCard
                  name={company.name}
                  legalForm={t(company.company_data.legal_form)}
                  city={company.company_data.city}
                  postal={company.company_data.postal_code}
                  province={company.company_data.province}
                  img={company.picture}
                  href={`${routes.company}?id=${company._id}`}
                  companyId={company._id}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <InfoMessage variant='info' text={t('weCoudntFindAnyCompany')} />
            </Grid>
          )}
        </Grid>
      </Spacer>
    </ScrollableContainer>
  )
}

const Loading = () => {
  return [...Array(3).keys()].map((i) => (
    <Grid key={i} item xs={12}>
      <CompanyResumeCard loading />
    </Grid>
  ))
}
