const availablesCountriesCodes = ['ES', 'CH']

export async function getUserCountryCode() {
  try {
    const response = await fetch('https://ipapi.co/json/')
    const data = await response.json()
    // console.log({ data })
    return availablesCountriesCodes.includes(data.country) ? data.country : 'ES'
  } catch (error) {
    // console.log({ error })
    return ''
  }
}
