import { useState, useEffect, useCallback } from 'react'
import {
  getCustomer,
  getPaymentMethod,
  getPlans,
  getPrice,
  getSubscription
} from 'apis/stripe'
import { Tabs } from 'components'
import { Box, CircularProgress } from '@mui/material'
import { Invoices, General } from 'views'
import { useUser } from 'context/UserContext'
import { currencies } from 'constants/Countries'

export default function SubscriptionInfo({ data, direction, page }) {
  const [subscription, setSubscription] = useState(null)
  const [plan, setPlan] = useState(null)
  const { user } = useUser()

  const getSubscriptionData = useCallback(
    async ({ subscriptionId, paymentMethodId }) => {
      try {
        const subscriptionData = await getSubscription(subscriptionId)
        if (subscriptionData.statusCode === 404) {
          setPlan('none')
          return
        }

        const planPromise = getPrice(subscriptionData.plan.id)
        const paymentMethodDataPromise = getPaymentMethod(paymentMethodId)
        const plansPromise = getPlans()
        const customerPromise = getCustomer(subscriptionData.customer)

        const [plan, paymentMethodData, plans, customer] = await Promise.all([
          planPromise,
          paymentMethodDataPromise,
          plansPromise,
          customerPromise
        ])

        const currency = currencies[user.country] ?? currencies.CH

        const yearlyPrice =
          plans.find(
            (plan) =>
              plan.recurring?.interval === 'year' && !plan.metadata?.promo
          ).unit_amount / 100

        const monthlyPrice =
          plans.find(
            (plan) =>
              plan.recurring?.interval === 'month' &&
              (plan.currency_options[currency].unit_amount * 12) / 100 >
                yearlyPrice &&
              !plan.metadata?.promo
          ).currency_options[currency].unit_amount / 100

        setSubscription({
          ...data,
          paymentMethodData,
          subscriptionData,
          subscriptionId,
          yearlyPrice,
          monthlyPrice,
          customerData: customer
        })
        setPlan({
          ...plan,
          currency,
          unit_amount: plan.currency_options[currency].unit_amount
        })
      } catch (error) {
        console.log(error)
        // setPlan("none");
      }
    },
    []
  )

  useEffect(() => {
    getSubscriptionData({
      subscriptionId: data.plan_id,
      paymentMethodId: data.payment_method_id
    })
  }, [getSubscriptionData])

  const loading = !subscription || !plan

  return (
    <Tabs.Container direction={direction} page={page}>
      <Tabs.Page>
        {loading ? (
          <Box
            width='100%'
            sx={{
              textAlign: 'center'
            }}
          >
            <CircularProgress size={32} />
          </Box>
        ) : (
          <General subscription={subscription} plan={plan} />
        )}
      </Tabs.Page>
      <Tabs.Page>
        {loading ? (
          <Box
            width='100%'
            sx={{
              textAlign: 'center'
            }}
          >
            <CircularProgress size={32} />
          </Box>
        ) : (
          <Invoices subscription={subscription} />
        )}
      </Tabs.Page>
    </Tabs.Container>
  )
}
