const en = (...args) => ({
  // GENERAL
  processing: 'Loading...',
  sending: 'Sending...',
  loadMore: 'Load more',
  // SIGN IN
  signIn: 'Login',
  signInWelcome: "It's good to see you again!",
  forgotPassword: 'Forgot password?',
  signInEmailError: 'Please check your email.',
  signInPasswordError: 'Please check your password.',
  tooManyRequest:
    'You have made too many incorrect attempts, please try again later.',
  wrongCredentials: 'Incorrect credentials.',
  signInText: 'Sign in',
  signUpText: 'Create now',
  registerQ: "Don't have an account?",
  continueAsGuest: 'Continue as guest',
  resetPasswordTitle: 'Reset password',
  aEmailWillBeSend:
    'Please enter the email address you registered with. You will receive an email to recover your password.',
  theEmailHasBeenSent: 'The email has been sent. Please check your inbox.',
  close: 'Close',
  resetPassword: 'Reset password',
  yourUser: 'Your user:',
  'resetError.ExpiredActionCode':
    'The code has expired, please re-request password change.',
  'resetError.InternalError':
    'An error has occurred. Please return to the app to reset your password.',
  'resetError.Processing':
    'An error has occurred while processing your request. Please try again at a later time.',
  'resetError.auth/expired-action-code':
    'The code has expired, please re-request password change.',
  'resetError.auth/invalid-action-code':
    'The code is malformed or has already been used, please re-request password change.',
  'resetError.auth/user-disabled':
    'The user corresponding to the given password reset code has been disabled.',
  'resetError.auth/user-not-found':
    'There is no user corresponding to the given password reset code.',
  'resetError.auth/weak-password': 'The new password is not secure enough.',
  passwordResetSuccesfully: 'Password changed successfully.',
  nowYouCanUseNewPass:
    'You can now use your new password to log in to your Jobsaun account.',

  // SIGN UP
  signUp: 'Sign up',
  welcome: 'Welcome',
  asEAsyAsFindTheProfessional: (
    <>
      As <span>easy</span> as finding the professional you need
    </>
  ),
  allReady: 'All set!',
  letsCreateYourAccount: "Let's create your account",
  accountType: 'What type of account do you want to create?',
  completeTheData: 'Fill out the following form with your account information.',
  tellUsMoreAboutYourCompany: 'Tell us more about your company.',
  getMoreVisits:
    'Companies receive more contacts when they have an image and biography.',
  user: 'User',
  particular: 'Client',
  company: 'Company',
  country: 'Country',
  name: 'Full name',
  onlyVisibleForYou: 'It will only be visible to you',
  publicName: 'Public name',
  visibleForEveryone: 'It will be visible to everyone',
  phoneNumber: 'Contact phone number',
  onlyVisibleOfferAccepted:
    'It will only be visible for the offers you accept.',
  email: 'Email',
  pass: 'Password',
  repeatPass: 'Repeat password',
  howDidYouHear: 'How did you find us?',
  accept1: 'By creating an account you accept the',
  accept2: 'and the',
  terms: 'Terms of use',
  privacyPolicy: 'Privacy policy',
  termsAndPrivacy: (
    <>
      By creating an account, you accept the{' '}
      <a
        href='https://jobsaun.com/legal/terms'
        target='_blank'
        rel='noreferrer'
      >
        Terms of use
      </a>{' '}
      and the{' '}
      <a
        href='https://jobsaun.com/legal/privacy-and-cookies'
        target='_blank'
        rel='noreferrer'
      >
        Privacy policy
      </a>
    </>
  ),
  signUpButton: 'Create account',
  maincategory: 'Main category',
  companyName: 'Company name',
  nif: 'NIF or UID',
  province: 'Province',
  postalCode: 'Postal code',
  contactName: 'Contact name',
  signUpEmailError: 'Email does not seem to be correct.',
  phoneError: 'Phone must have 9 digits plus prefix.',
  nifError: 'Incorrect field, please check it.',
  passwordError: 'The password must have between 8 and 20 characters.',
  repeatPasswordError: 'Passwords do not match.',
  required: 'This field is required.',
  notValid: 'Not valid value',
  youMustSelectACategory: 'You must select at least one category.',
  contractRequired:
    'You must accept the Order Processing Contract to continue.',
  privacyRequired: 'You must accept our Privacy Policies to continue.',
  legalFormHelper: 'E.g.: Self-employed',
  contract: 'I have read and accept the Order Processing Contract.',
  pleaseReadTheContract:
    'Please read the following contract before continuing.',
  ifYouContinue: 'By continuing, you accept the order processing contract.',
  seeMyOffers: 'See my offers',
  maximumCharacters: 'The maximum number of allowed characters is',
  'error.alreadyInUse': 'The email is already assigned an account.',
  friend: 'Friend',
  digitalPaper: 'Digital newspaper',
  other: 'Other',
  someFieldIsINcomplete:
    'Some field is incomplete, please go back and fill them all.',

  // HOME
  exploreCategories: 'Explore categories',
  particulars: 'Clients',
  slider1: 'Find clients easily and quickly.',
  slider2: 'Now the professional you need finds you.',
  share: 'Share',
  search: 'Search...',
  categories: 'Categories',
  whatServiceDoYouNeed: 'What service do you need?',
  homeCTA: (
    <>
      Now <span>the professional you need</span> finds you
    </>
  ),
  bestRatedCompanies: 'Best rated companies',
  doYouHaveACompany: 'Do you have a company?',
  joinJobsaun: 'Join Jobsaun to make your company more visible.',
  joinNowForFree: 'Join for free now',
  weAreAlready: 'We are already',
  andGoingUp: 'companies and going up',

  //  DISCOVER
  reset: 'Restore',

  // INBOX
  inboxGuestMessage:
    'From the Mailbox you will be able to contact and finalize details with the client or company.',

  // OFFERS-POSTS
  boardGuestMessage: 'From the board you can manage your bids or jobs.',
  pluralPosted: 'Posted',
  pluralDraft: 'Drafts',
  pluralAssigned: 'Assigned',
  pluralAccepted: 'Accepted',
  archived: 'Archived',

  // ACCOUNT PAGE
  registerMessage:
    'Take advantage of all the functionalities of the application with an account. Creating an account is very easy and FREE',
  alreadyHasAccount: 'Already have an account?',
  knowMoreAboutUs: 'Do you want to know more about us?',
  moreInfo: 'More information',
  installPWA: 'Improve the experience',
  install: 'Install',
  welcomeToJobsaun: 'Welcome to Jobsaun',
  slogan: 'Let what you are looking for find you',
  joinNow: 'Join now',
  next: 'Next',
  preview: 'Previous',
  accountSlider_1:
    'Easily find verified professionals to perform the service you need.',
  accountSlider_2:
    "Increase your company's visibility by being closer to customers",
  accountSlider_3:
    'Reviews from users like you so you can evaluate the best alternatives',
  accountSlider_4:
    'Receive personalized quotes that benefit both the company and you',

  // CATEGORY PAGE
  jobs: 'Jobs',
  companies: 'Companies',
  searchInCategory: 'Search job in this category...',
  searchInCompanies: 'Search company in this category...',
  jobFilterTitle: 'Job filter',
  proximityFilter: 'Proximity (km)',
  budgetFilter: 'Budget',
  subcategories: 'Subcategories',
  selectAll: 'Select all',
  unSelectAll: 'Deselect all',

  // CATEGORIES
  construction: 'Construction and home',
  mechanic: 'Mechanics and transport',
  care: 'Care and aesthetics',
  leisure: 'Leisure and gastronomy',
  technology: 'Technology and marketing',
  others: 'Other services',

  // Subcategories
  // ---Construction
  demolition: 'Demolition',
  verticalsJobs: 'Vertical Jobs (Facades)',
  architecture: 'Architecture, planning',
  bathRenovations: 'Bathrooms',
  roofers: 'roofers, roofs',
  welding: 'weldings',
  reforms: 'Reforms',
  electrician: 'Electrician, electrical installations',
  windows: 'Windows and doors',
  garage: 'Garage doors',
  furnishPicker: 'Furniture assembler',
  glass: 'Glass and glazing',
  fireplace: 'Fireplaces',
  parquet: 'Parquet, laminate, carpet',
  isolation: 'Insulation',
  kitchen: 'Kitchens',
  decoration: 'Decoration',
  painting: 'Painting',
  builder: 'bricklayer, plaster, concrete',
  interiorDesign: 'Interior design',
  metal: 'Metal construction, locksmith, forge',
  slabs: 'Slabs, tiles',
  upholstery: 'upholstery, saddlery',
  plumbing: 'Plumber',
  carpenter: 'Carpenter',
  locksmith: 'Locksmith',
  gardener: 'Gardener, horticulture',
  earthmoving: 'Construction of wells, earthworks, foundations',
  greenhouses: 'greenhouses',
  fences: 'Fences, gates, privacy screens',
  remodeling: 'Remodeling, total renovation, enlargement',
  technician: 'Technician, systems installer',
  alarms: 'Alarms',
  housekeeping: 'Housekeeping, Supervisor',
  blinds: 'Blinds, shutters, awnings',
  confort: 'Heating, ventilation, air conditioning, refrigeration',
  climate: 'Air conditioning',
  cleaning: 'Cleaning',
  applianceTechnician: 'Appliance technician',
  moving: 'moving',
  scaffolding: 'Scaffolding',
  paving: 'Paving works, roads, highways',
  pestControl: 'Pest control',
  constructionMaterials: 'Construction materials',

  // ---Mechanic and transport
  mechanics: 'Mechanics, electronics',
  crane: 'Towing and moving of vehicles',
  carWash: 'Vehicle washing',
  electricCars: 'Electric vehicles',
  wheelbarrows: 'Wheelbarrows',
  chargingPoints: 'Installation of charging points',
  smallTransport: 'Transports, small transports',
  largeTransport: 'Transport, large transport',
  carPainting: 'Bodywork and painting',
  carService: 'Car service (Pre-ITV)',
  fineTunning: 'Tuning',
  motorcycle: 'Motorcycle, Quad',
  tuning: 'Tuning',

  // ---Care and aesthetics
  tattooArtist: 'Tattoo artist',
  dentist: 'Dentist',
  masseur: 'Masseur',
  osteopathy: 'Osteopathy',
  hairdresser: 'Hairdresser',
  esthetician: 'Esthetician',
  rehabilitation: 'Rehabilitation',
  physiotherapist: 'Physiotherapist',
  makeupArtist: 'Makeup artist',
  acupuncture: 'Acupuncture',
  motivationalCoach: 'Motivational coach',
  personalTrainer: 'Personal trainer',
  zumbaInstructor: 'Zumba instructor',
  pilatesInstructor: 'Pilates instructor',

  // ---Technology and marketing
  programmer: 'Programmer',
  webProgrammer: 'Web programmer',
  photographer: ' Photographer',
  webDesigner: 'Web designer',
  multimediaInstaller: 'Multimedia Installer',
  applicationProgrammer: 'Application Programmer',
  graphicDesigner: 'Graphic designer',
  computerTechnician: 'Computer technician',
  marketingAndCommunication: 'Marketing and Communication',
  printing: 'Printing company',
  analyst: 'Analyst',
  database: 'Database',
  machineLearning: 'Machine learning',
  productCustomization: 'Product customization',
  merchandising: 'Merchandising',
  artificialIntelligence: 'Artificial intelligence',
  cyberSecurity: 'Cyber security',
  itSystemsTechnician: 'Computer systems technician',
  itSupportTechnician: 'Computer support technician',

  // --Leisure and gastronomy
  cook: 'Cocinero/a',
  catering: 'Catering',
  animator: 'Animator',
  socioculturalAnimator: 'Sociocultural animator',
  functionalDiversityMonitor:
    'Leisure monitor for people with functional diversity',
  childrenMonitor: "Children's entertainment monitor",
  freeTimeMonitor: 'Leisure and free time monitor',
  waiter: 'Waiter/waitress',
  soundTechnician: 'Sound technician',
  dj: 'DJ',
  danceInstructor: 'Dance instructor',

  // ---Others
  model: 'Model',
  concierge: 'Concierge',
  administrative: 'Administrative',
  receptionist: 'Receptionist',
  lifeGuard: 'Lifeguard',
  commercial: 'Commercial',
  teacher: 'Teacher',
  privateTeacher: 'Private teacher',
  pedagogue: 'Pedagogue',
  seamstress: 'Seamstress',
  educationalAssistant: 'Educational Assistant',
  animalCaretaker: 'Animal caretaker',
  parcelDeliveryMan: 'Messenger',
  usedOilCollection: 'Used oil collection',
  socialEducator: 'Social educator',
  otherJob: 'Other job',

  // --------
  filter: 'Filter',
  filters: 'Filters',
  orderBy: 'Order by',
  betterRating: 'Best Rating',
  moreAcceptedOffers: 'More accepted offers',
  youMustSelectAtLeastOneSubcategory:
    'You must select at least one subcategory.',

  // COMPANY PROFILE
  sendJobOffer: 'Offer job',
  message: 'Message',
  companyInfo: 'Biography',
  seeMore: 'See more',
  seeLess: 'See less',
  customerReviews: 'Customer Reviews',
  noDetailsYet: 'The company has not yet provided details.',
  createFreeAccount: 'Create free account',

  // LEGAL FORMS
  freelance: 'Self-employed',
  civilSociety: 'Civil Society',
  collectiveSociety: 'Sociedad Colectiva',
  limitedLiabilityCompany: 'Limited Liability Company',
  limitedSociety: 'private limited company',
  anonymousSociety: 'Public Limited Company',
  cooperativeSociety: 'Cooperative Society',

  // REVIEWS
  valorations: 'Ratings',
  comunication: 'Communication',
  times: 'Delivery on time',
  asDescribed: 'Final work',
  recommend: 'Would you recommend it?',
  leaveAComment: 'Leave a comment',

  // PAGES
  home: 'Home',
  discover: 'Discover',
  inbox: 'Mailbox',
  messages: 'Messages',
  offers: 'Offers',
  board: 'Board',
  posts: 'Posts',
  profile: 'Profile',
  category: 'Category',
  newOffer: 'New offer',

  // JOB OFFERS
  posted: 'Published',
  startDate: 'Start date',
  preferredStartDate: 'Desired date',
  endDate: 'End/Delivery Date',
  mainDetails: 'Details',
  otherDeatils: 'Other details',
  receivedOffers: 'Offers received',
  createAnOffer: 'Send an offer',
  noOfferYet: 'This job has not received any offers yet, be the first!',
  offerAlert: 'To create an offer you must be logged in as a company',
  rateType: 'Rate type',
  flatRate: 'Flat rate',
  day: 'day',
  month: 'month',
  year: 'year',
  amount: 'Amount',
  addEndDate: 'Add end date',
  attachFile: 'Attach file',
  sendOffer: 'Send offer',
  offerMessageHelper: 'Will be displayed as first message of the chat.',
  fileError: 'File error: only .pdf is accepted and yours is',
  maxFileSize: 'The maximum file size is 10MB',
  budget: 'Budget',
  editPost: 'Edit post',
  nothingPostedYet: 'You have not posted anything yet',
  tryFromTheSearchbar: 'Try from the top search bar',
  nothingInDrafts: 'There is nothing in drafts',
  nothingInAssigned: 'There is nothing in assigned',
  nothingInArchived: 'Nothing in archived',
  nothingInRejected: 'You have no rejected offers',
  nothingInAccepted: 'You have no accepted offers',
  nothingInNegotiating: 'Send offers to start negotiation',
  nothingFound: 'No results for the search',
  StartSendingOffersToPotentialCustomers:
    'Start sending offers to potential customer publications',
  'errors.no_active_subscription_found':
    'You need to subscribe to a plan to be able to send offers.',

  // Questions
  'question.flexibleHours': 'Do you have flexibility with schedules?',
  'question.availableToNegociate':
    'Would you be available to negotiate the budget?',
  'question.payInAdvance': 'Would you pay in advance if necessary?',
  'question.accessible': 'Is the location easily accessible?',
  'question.availabilityForTravel':
    'Do you have availability to move the vehicle?',
  'question.parking': 'Is there parking in the area?',
  'question.weekends': 'Are you allowed to work on weekends?',
  'question.material': 'Is material provided?',
  'question.neededParts': 'Do you have the parts you need?',
  'question.crane': 'Do you need a crane?',
  'question.bigTrees': 'Do you have tall trees?',
  'question.water': 'Do you have fountains or other areas with water?',
  'question.workAtHome': 'Can the work be done at home?',
  'question.goToTheHome': 'Do you require travel to the home?',

  'question.squareMeters': 'How many square meters is the site?',

  // Answers
  'answer.flexibleHours': 'Flexibility in schedules',
  'answer.availableToNegociate': 'Budget negotiable',
  'answer.payInAdvance': 'Availability to pay in advance',
  'answer.accessible': 'Easily accessible location',
  'answer.availabilityForTravel': 'Availability to travel',
  'answer.parking': 'Parking in the area',
  'answer.weekends': 'Weekend work allowed',
  'answer.material': 'Customer provides material',
  'answer.neededParts': 'Needed parts available',
  'answer.crane': 'Crane needed',
  'answer.bigTrees': 'Tall trees',
  'answer.water': 'Fountains or other areas with water',
  'answer.workAtHome': 'Possibility to work at home',
  'answer.goToTheHome': 'Travel to the home is required',

  'answer.squareMeters': 'Square meters of the site',

  // OTHERS
  shareTheApp: 'Share the app',
  shareTheAppMessage:
    'Share the Jobsaun app with your friends, show them how good you are at finding new and useful apps.',
  goToChat: 'Go to chat',
  seeMoreJobs: 'See more jobs',
  sendingYourOffer: 'Sending offer',
  itCanTakeAMoment: 'This process may take a while. Please be patient.',
  offerSuccessSent: 'Offer successfully submitted',
  continueWith: 'What do you want to do next?',
  users: 'users',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  saveDraft: 'Save draft',
  toPost: 'Publish',
  saving: 'Saving...',
  posting: 'Publishing...',
  postingJob: 'Your job is being posted',
  pleaseBePatient: 'Please wait for the action to finish',
  nothingHere: 'Nothing here...',
  tryAnother: 'Try another search or category.',
  settings: 'Settings',
  account: 'Account',
  sent: 'Sent',
  youHaveToSignIn: 'You must be logged in to access this feature.',
  loading: 'Loading...',
  all: 'All',

  // POSTED JOBS
  postedJobs: 'Jobs posted',
  send: 'Send',
  cancel: 'Cancel',
  acceptedOffer: 'Accepted job offer',
  cancelledOffer: 'Offer cancelled',
  acceptOffer: 'Accept offer',
  draft: 'Draft',
  negotiating: 'Negotiating',
  rejected: 'Rejected',
  accepted: 'Accepted',
  cancelled: 'Canceled',
  proposed: 'Proposal',
  assigned: 'Assigned',
  noDate: 'No date',
  anyJobWasFound:
    "No posted jobs matching the company's categories have been found.",
  theProposalHasBeenSent: 'The proposal has been sent successfully',
  youAlreadySentThisJob: 'You have already sent this job to the company',
  theCompanyAlreadySentYouAnOffer:
    'The company has already sent you an offer about this job.',
  theCompanyHasCancelTheOffers:
    'The company has cancelled the bids for this job.',

  // MENUS
  moveToDraft: 'Move to drafts',
  edit: 'Edit',
  delete: 'Delete',
  newFirst: 'New first',
  oldFirst: 'Old first',
  edited: 'Edited',

  // NEW JOB
  newJob: 'New job',
  title: 'Title',
  subCategory: 'SubCategory',
  explainTheNeededService:
    'Explain in as much detail as possible the service you need, so that the professionals can make you the fairest offer.',
  postDescription: 'Description of the service',
  city: 'City',
  helperCity:
    'Something went wrong getting the city, please check the zip code, or type it in by hand.',
  uploadPictures: 'Images',
  maximumSizeExceeded: 'The maximum file size has been exceeded',
  maximumNumberOfFiles: 'The maximum number of files has been exceeded',
  notAllowFile: 'File is not allowed',
  allowFiles: 'Allowed files',
  otherDetails: 'Other details',
  congrats: 'Congratulations!',
  postedSucceed: 'The job has been successfully published.',
  justWait:
    'Now you just have to wait to receive offers from interested companies.',
  seeTheJob: 'See the post',
  fiveDigits: 'There must be 5 digits.',
  alreadyUploadThisFile: 'You have already uploaded this file',
  optional: 'Optional',
  willLoseTheProcessOnCancel: 'By canceling you will lose all progress.',
  noCategoryFound:
    'Sorry, we did not find a category with the search performed. Please select the most appropriate category from the options below.',
  weHaveSelectedTheNextCategory:
    'Based on your search we have selected the following category, if you feel this is not correct, please select the most appropriate one.',
  localitation: 'Localization',
  searchCompanies: 'Searching for companies',
  lookingCompanies: 'Searching for companies',
  thisCanTakeAWhile: 'This may take a while, please be patient.',
  weHaveFound: 'We have found...',
  whatDoYouWantToDoNext: 'What do you want to do next?',
  seeCompanies: 'See companies',
  continueWithTheApplication: 'Continue with the application',
  weAreSorryWeStillGrowing:
    'Sorry, we are still growing and more and more companies are joining Jobsaun. Continue the request for companies to contact you.',
  whatBudgetDoYouHave: 'What budget do you have?',
  budgetSubtitle:
    'This is just an aproximation to let the companies know the maximum amount you can afford.',
  recommended: 'Recommended',
  lessThan: 'Up to',
  moreThan: 'Greater than',
  whenDoYouNeedIt: 'When do you need it?',
  tellUsYourPreferences: 'Tell us your preferences',
  doYouWantToProvideAnImage:
    'Do you want to provide any images to help the professional?',
  dragAndDropOrBrowse: 'Drag and drop or',
  browse: 'click here',
  allow: 'Allowed',
  maxFilesSize: 'maximum size',
  of: 'of',
  image: 'Image',
  'imageErrors.file-too-large': `The file ${args[0]} is too large. The maximum size is ${args[1]} and the file has a size of ${args[2]}`,
  'imageErrors.file-invalid-type': `The file type must be one of the following: ${args[0]}`,
  'imageErrors.too-many-files': `Too many files selected, maximum is ${args[0]} files.`,
  'imageErrors.bad-file': `The file ${args[0]} cannot be attached. Please try another one.`,
  postDetails: 'Request details',
  'post.sending': 'Your request is being sent',
  'post.pleaseBePatient': 'This may take a moment. Please be patient.',
  'post.added': 'Post successfully created.',
  'error.post.adding':
    'Could not create the post. Please try again at a later time.',
  'error.post.updating':
    'Could not update the post. Please try again at a later time.',
  'error.post.deleting': 'Failed to delete the post. Please try again later',
  foundCompanies: 'Found companies',
  weCoudntFindAnyCompany:
    'Oops! It looks like we couldn’t find any company with those criteria.',

  // NEW OFFER
  sendOfferTo: 'You are going to send an offer for the job post:',
  sendingOffer: 'Sending the offer...',
  theCompanyIsInterested: `The company ${args[0]} is interested in your ad. Accept the offer, if you are interested, to start the conversation. Note: All other offers will be automatically rejected.`,
  offerAlreadySent:
    'You are only allowed to send one offer per ad. To submit another offer you must cancel the previous one.',
  youNeedAPlan:
    'Oops! It looks like your free trial has ended. Please subscribe to a plan to be able to send an offer.',
  yourOfferHasAlreadyBeenAccepted:
    'Your offer has been accepted. Please go to the chat for more details.',
  yourOfferHasAlreadyBeenRejected:
    'Your offer has been automatically rejected because the customer has accepted another one. We wish you luck with your next one.',
  doYouWantToCancelNow: 'Do you want to cancel now?',
  offercancelledSuccesfully: 'Offer successfully cancelled',
  finalize: 'Finalize',
  toCompleteThePost: 'To complete the request you need an account.',
  createAccountAndFinalize: 'Create account and finalize',

  // PROFILE
  details: 'Account details',
  companyDetails: 'Company details',
  ratings: 'Ratings',
  privacy: 'Privacy and Cookies',
  legal: 'Legal',
  info: 'Information',
  updates: 'Update log',
  current: 'Current',

  subscription: 'Subscription',
  accountState: 'Account status',
  active: 'Active',
  deactivate: 'Deactivated',
  trial: 'Trial',
  activeMessage: 'Everything is fine, no action required.',
  trialMessage: 'Enjoy the free trial.',
  trialDescription:
    'Will automatically fulfill when one of your offers has been accepted.',

  guest: 'Guest',
  contact: 'Contact',

  // SUBSCRIPTION ERRORS
  deactivateDescription:
    'As long as your account remains deactivated you will not be able to send offers or communicate with new customers.',
  deactivateDefaultMessage:
    'We are having some kind of problem with your account. Please contact us.',

  'error.payment': 'We are having problems receiving your payments.',
  'error.subscription': 'You have not yet subscribed to any plan.',
  'error.trialOff':
    'Your free trial has ended. Please access a subscription to continue enjoying all the features.',
  'error.404': 'The subscription does not exist.',
  'error.incomplete': 'The initial charge attempt has failed.',
  'error.past_due': 'Failed to cashout on due date.',
  'error.unpaid': 'The subscription could not be collected.',
  'error.cancelled': 'The subscription has been cancelled',
  'error.incomplete_expired': 'The subscription attempt has expired in error',

  // DETAILS
  changePassword: 'Change password',
  editData: 'Edit your data',
  editBasicData: 'Edit basic data',
  editCompanyData: 'Edit company data',
  deleteAccount: 'Delete account',
  signOut: 'Logout',
  confirmationEmailWillBeSent: 'You will receive a confirmation email',
  legalForm: 'Constitution',
  bio: 'Company information',
  bioPlaceHolder:
    'Describe your company as best as you can, this introduction can convince many customers.',
  changeYourAvatar: 'Change avatar',
  background: 'Background',
  hair: 'Hair',
  skin: 'Skin',
  features: 'Features',
  profileUpdatedSuccessfully: 'Profile successfully updated.',
  accountDeleted: 'Account successfully deleted.',

  // SUBSCRIPTION PAGE
  apply: 'Apply from hundreds of jobs and send quotes to potential clients.',
  selectThePlan: 'Select the plan that suits you best',
  annual: 'Annual',
  monthly: 'Monthly',
  promo: 'Promo',
  uniquePayment: 'One-time payment',
  annualDescription: 'Charged annually as a recurring payment.',
  monthlyDescription: 'Charged monthly as a recurring payment. No permanence.',
  uniquePaymentDescription: 'Charged as a one-time payment',
  billedAnnualy: 'Charged annually.',
  billedmonthly: 'charged monthly.',
  saveA: 'Save a',
  withAnnualPayment: 'with the annual payment',
  continue: 'Continue',
  overview: 'General',
  invoices: 'Invoices',
  paymentDetails: 'Payment Details',
  yourSubscriptionPlan: 'Your subscription',
  paymentMethod: 'Payment method',
  lastInvoice: 'Last invoice',
  cancelPlan: 'Cancel subscription',
  changePlan: 'Change plan',
  cardOf: 'Card from',
  changePaymentMethod: 'Change payment method',
  enterAValidPaymentMethod: 'Enter a valid payment method below.',
  credit: 'credit',
  debit: 'debit',
  cancelSubDialogTitle: 'Cancel subscription',
  weSorryYouCancel:
    'We are sorry to hear that you wish to cancel your subscription.',
  rememberThat: 'RememberThat:',
  yourSubWillFinishAt:
    'Even if you cancel now, your subscriber benefits will continue to run until the day:',
  comeBack: 'Return',
  cancelling: 'Canceling...',
  changing: 'Changing...',
  yourPlanWillBecancelledAt:
    'Your subscription will be automatically cancelled on',
  'plan.year': 'Annual',
  'plan.month': 'Monthly',
  'plan.year.cancelDialogBody':
    'You have the option to switch to a monthly subscription.',
  'plan.month.cancelDialogBody':
    'You have the option to change to a yearly subscription and save a',
  'plan.year.changeQ': 'Do you want to switch to the Monthly Plan?',
  'plan.month.changeQ': 'Do you want to switch to the Annual Plan?',
  withAnnualPlanYouCanSave: 'With the Annual Plan you save one',
  whenChangePlan:
    'Your plan will be changed at this time and invoices will be prorated with your current billing period.',
  moreOptions: 'More options',
  differentAccountHolder: 'The holder is different',
  noActiveSubscription: 'You do not have any active subscriptions.',
  subscribeToAPlan: 'Subscribe to one of the plans to enjoy all the features',
  seePlans: 'See plans',
  plans: 'Plans',
  status: 'Status',
  'status.active': 'Active',
  'status.incomplete': 'Incomplete',
  'status.incomplete_expired': 'Expired',
  'status.trialing': 'Trialing',
  'status.past_due': 'To be paid',
  'status.updaid': 'Unpaid',
  'status.canceled': 'Canceled',

  // CHECKOUT
  checkout: 'Payment details',
  finishCheckOut: 'Finish the payment process to complete your subscription',
  yourOrder: 'Your subscription',
  annualSubscription: 'Annual subscription',
  monthlySubscription: 'Monthly subscription',
  taxes: 'Taxes',
  total: 'Total',
  subTotal: 'Subtotal',
  subscribe: 'Subscribe me',
  weWillNotStoreYourData:
    "We will not see or store your payment data. The payment process and data is encrypted directly to Stripe's highly secure system.",
  checkoutName: 'Name of cardholder',
  securePayment: 'Payment',
  safeAndSecure: 'safe and secure',
  garanteed: 'guaranteed',
  thankYou: 'Thank you!',
  subscriptionSucceed: 'Your subscription has been successfully completed.',
  accept: 'Accept',
  somethingWentWrong: 'Something went wrong...',
  thePaymentCouldNotBeDone:
    'The payment could not be made. Please check that the data entered is correct',

  // CHAT
  tabAll: 'All',
  tabAccepted: 'Accepted',
  tabRejected: 'Rejected',
  tabNegotiation: 'Negotiating',
  tabProposal: 'Proposals',
  tabStandBy: 'Pending',
  chat: 'Chat',
  sendAMessage: 'Write a message...',
  acceptToWrite: 'Accept the offer to write...',
  theUserHasToAccept: 'Your offer must be accepted to start writing...',
  jobOfferIdText: 'Advertisement Id',
  attachedFileText: 'Attached file',
  offerIdText: 'Offer Id',
  theUserIsStudyingYourOffer:
    'is studying your offer. Once he accepts it, you can engage in conversation to work out the details.',
  theUserHasAccepted:
    'has accepted your offer and you can now discuss the details.',
  yourOfferHasBeenRejected: 'has already accepted another offer.',
  thinksThatYouCanBeInterested: 'thinks you might be interested in this job.',
  valorate: 'valorate',
  companyNotAvailable:
    'The company does not have your account active at this time, please try another offer.',
  offer: 'Offer',
  theOfferNeedToBeAccepted: [
    'To establish conversation with the company you must accept their offer.',
    'Once you accept an offer, all other offers will be automatically cancelled.',
    'Before accepting, you can view more information about the company by clicking on their name.'
  ],
  you: 'you',

  // ALERTS
  areYouSure: 'Watch out!',
  youAreGoingToDelete:
    'You are going to delete the post and any offers it have received. do you want to continue?',
  deleteJobMessage:
    'This action cannot be undone, if you want to change any details try moving it to drafts.',
  youAreGoingToDeleteYourAccount: 'You are deleting your account',
  allYourDataWillBeDeleted:
    'All your data will be deleted from our database. This action cannot be undone',
  enterYourPassword: 'Enter your password.',
  deleting: 'Deleting...',
  incorrectPassword: 'Incorrect password.',
  passwordChange: 'Change password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  change: 'Change',
  minimum8characters: 'Must be at least 8 characters long',
  anErrorHappens: 'An error has occurred. Try again later',
  fix: 'correction',
  new: 'new',
  news: 'Latest changes',
  update: 'Update',
  later: 'Later',
  newUpdateTitle: 'Update available',
  newUpdateAvailable:
    'A new version is available. Please update to have the latest news and improvements.',
  editingRating:
    'You have already rated this company. You can edit your rating below.',
  commentSentSuccesfully: 'Your rating has been submitted successfully.',
  onlyAvailableForUsers:
    'This feature is only available to individuals with an account.',
  youShouldCompleteTheRequiredFields: 'You must complete the required fields.',
  postUpdatedSuccessfully: 'Post successfully updated.',
  jobDeletedSuccesfully: 'Post successfully deleted.',
  errorLoadingData:
    'Oops! there has been an error getting the data. Please try again later.',
  noResultsWasFound:
    'Sorry, we did not find any results. Try modifying the filters from the top.',

  // DELETE ACCOUNT PAGE
  pleaseIndicateReason:
    'Please tell us the reason why you want to delete your account.',
  requestSended:
    'Your request has been sent successfully. Once we study it and everything is correct, we will proceed with the deletion of your account.',
  toEliminateYouShouldIndicateAReason:
    'In order for us to delete your account you must provide us with a reason.',

  // COUNTRIES
  esp: 'España',
  ch: 'Switzerland',

  deletedAccount: 'Account deleted',

  // PLANS NAMES
  Anual: 'Annual',
  Mensual: 'Monthly'
})

export default en
