import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { InvoiceCard } from 'components'
import { getInvoices } from 'apis/stripe'
import { getDate } from 'helpers'
import { useI18N } from 'hooks'

export default function Invoices({ subscription }) {
  const { t } = useI18N()
  const [invoices, setInvoices] = useState([])
  const customer = subscription.customerData.id

  useEffect(() => {
    getInvoices(customer).then((resp) => {
      setInvoices(resp.data)
    })
  }, [])

  const handleDownloadInvoice = (url) => () => {
    window.open(url, '_blank')
  }

  return (
    <Grid container spacing={2}>
      {invoices.length === 0 &&
        new Array(5).fill('').map((i, idx) => (
          <Grid key={idx} item xs={12}>
            <InvoiceCard loading />
          </Grid>
        ))}

      {invoices.length > 0 &&
        invoices.map((invoice) => (
          <Grid key={invoice.id} item xs={12}>
            <InvoiceCard
              date={getDate(invoice.created * 1000)}
              plan={t(
                invoice.lines.data[invoice.lines.data.length - 1].price.metadata
                  .nickname
              )}
              amount={invoice.amount_due / 100}
              onClick={handleDownloadInvoice(invoice.invoice_pdf)}
              currency={invoice.currency}
            />
          </Grid>
        ))}
    </Grid>
  )
}
