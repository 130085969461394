import texts from 'constants/texts'

export const routes = {
  home: '/',
  discover: '/discover',
  inbox: '/inbox',
  board: '/board',
  account: '/account',
  posts: '/posts',
  companies: '/companies',
  signIn: '/sign-in',
  signUp: '/sign-up',
  accountDetails: '/account/details',
  deleteAccount: '/account/delete-account',
  info: '/account/info',
  ratings: '/account/ratings',
  privacy: '/privacy',
  subscription: '/account/subscription',
  updates: '/account/info/updates',
  checkout: '/account/subscription/checkout',

  filteredCompanies: '/discover/filter',

  chat: '/chat',
  company: '/companies/company',
  postedJobs: '/companies/posted-jobs',
  post: '/posts/post',
  newPost: '/posts/new-post',
  editPost: '/posts/post/:postId',
  newOffer: '/posts/post/:postId/new-offer',

  resetpassword: '/account/resetpassword'
}

export const externalRoutes = {
  legal: 'https://jobsaun.com/legal/privacy-and-cookies',
  app: 'https://app.jobsaun.com'
}

export const slugs = {
  app: {
    name: 'Jobsaun App',
    link: 'https://app.jobsaun.com'
  },
  root: {
    name: texts().home,
    link: '/'
  },
  category: {
    name: texts().category,
    link: '/category'
  },
  newOffer: {
    name: texts().newOffer,
    link: '/new-offer'
  },
  // offerSent: {
  //   name: '',
  //   link: '/offer-sent',
  // },
  companies: {
    name: texts().companies,
    link: '/companies'
  },
  users: {
    name: texts().users,
    link: '/users'
  },
  postedJobs: {
    name: texts().postedJobs,
    link: '/posted-jobs'
  },
  newJob: {
    name: texts().newJob,
    link: '/new-job'
  },
  details: {
    name: texts().details,
    link: '/details'
  },
  ratings: {
    name: texts().ratings,
    link: '/ratings'
  },
  privacy: {
    name: texts().privacy,
    link: '/privacy'
  },
  legal: {
    name: texts().legal,
    link: 'https://jobsaun.com/legal/privacy-and-cookies'
  },
  updates: {
    name: texts().updates,
    link: '/info/updates'
  },
  subscription: {
    name: texts().subscription,
    link: '/subscription'
  },
  checkout: {
    name: texts().checkout,
    link: '/subscription/checkout'
  },
  offers: {
    name: texts().offers,
    link: '/offers'
  },
  board: {
    name: texts().board,
    link: '/board'
  },
  account: {
    name: texts().account,
    link: '/account'
  },
  inbox: {
    name: texts().inbox,
    link: '/inbox'
  },
  chat: {
    name: texts().chat,
    link: '/chat'
  },
  signIn: {
    name: texts().signIn,
    link: '/sign-in'
  },
  signUp: {
    name: texts().signUp,
    link: '/sign-up'
  },
  resetPassword: {
    name: '',
    link: '/resetpassword'
  },
  deleteAccount: {
    name: texts().deleteAccount,
    link: '/delete-account'
  },
  filter: {
    name: texts().filter,
    link: '/filter'
  },
  info: {
    name: texts().info,
    link: '/info'
  }
}
