import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Box, CircularProgress, Divider, Skeleton } from '@mui/material'
import { CustomerReviews, UserComment } from 'components'
import { COMPANY_RATINGS } from 'apis/querys'
import { getDate } from 'helpers'

const initialLimit = 8

export default function RatingsGroup({ companyId }) {
  const [limit, setLimit] = useState(initialLimit)
  const [comments, setComments] = useState()
  const [averages, setAverages] = useState()
  const [noMoreResults, setNoMoreResults] = useState(false)
  // const {
  //   state: { atBottom },
  // } = useContext(ScrollContext)
  const atBottom = false

  const { loading, data, fetchMore } = useQuery(COMPANY_RATINGS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: companyId,
      offset: 0,
      limit,
      sortBy: {
        order: 'DESC',
        field: 'date'
      }
    }
  })

  useEffect(() => {
    if (!loading && data && data.companyRatings) {
      setComments(data.companyRatings)
    }
    if (!loading && data && data.companyRatingsAverage) {
      setAverages(data.companyRatingsAverage)
    }
  }, [loading, data])

  useEffect(() => {
    if (atBottom && comments && comments.length >= initialLimit) {
      onLoadMore()
    }
  }, [atBottom])

  const onLoadMore = () => {
    if (!noMoreResults) {
      const currentLength = data?.companyRatings.length
      if (currentLength) {
        fetchMore({
          variables: {
            offset: currentLength,
            limit: initialLimit
          }
        }).then((fetchMoreResult) => {
          const newOffersLength = fetchMoreResult.data?.companyRatings.length
          if (newOffersLength === 0) {
            setNoMoreResults(true)
          }
          setLimit(currentLength + newOffersLength)
        })
      }
    }
  }

  return (
    <Box aria-label='ratings-container'>
      {averages ? <CustomerReviews averages={averages} /> : <LoadingReviews />}
      <Divider sx={{ pt: 1, mb: 2 }} />
      <Box aria-label='comment-container'>
        {comments
          ? comments.map((comment, index) => (
              <Box key={comment.user_id}>
                <UserComment
                  userId={comment.user_id}
                  date={getDate(comment.date)}
                  lastMod={getDate(comment?.last_mod)}
                  comment={comment.comment}
                  average={
                    comment.ratings.reduce((a, b) => a + b, 0) /
                    comment.ratings.length
                  }
                />
                {index + 1 < comments.length && (
                  <Divider light sx={{ pt: 2, mb: 2 }} />
                )}
              </Box>
            ))
          : [1, 2, 3].map((itm) => (
              <Skeleton
                key={itm}
                variant='rectangular'
                height={90}
                sx={{ borderRadius: 2, mb: 2 }}
              />
            ))}
        {loading && (
          <CircularProgress
            size={24}
            sx={{ mt: 1, mx: 'auto', display: 'block' }}
          />
        )}
      </Box>
    </Box>
  )
}

const LoadingReviews = () => (
  <>
    <Skeleton sx={{ p: 3, mt: -1 }} width={80} />
    <Skeleton sx={{ p: 1, mt: -2, mb: 0.5 }} width={150} />
    <Skeleton sx={{ mb: 0.5 }} />
    <Skeleton sx={{ mb: 0.5 }} />
    <Skeleton sx={{ mb: 0.5 }} />
    <Skeleton sx={{ mb: 0.5 }} />
  </>
)
