import React from 'react'
import { Avatar, Typography, Box } from '@mui/material'
import { getUserPicture } from 'helpers'

export default function ProfileInfo({ alt, image, userName, legalForm, postal, city, province, ...other }) {
  return (
    <Box {...other}>
      <Avatar
        alt={alt}
        src={getUserPicture(image).profileImage}
        sx={{
          width: 88,
          height: 88,
          mb: 1,
          bgcolor: 'action.hover',
          backgroundImage: `url(${getUserPicture(image).bgImage})`,
          backgroundSize: 'cover',
          mx: 'auto',
        }}
      />
      <Typography textAlign="center" fontSize="1.5rem">
        {userName}
      </Typography>
      {legalForm && (
        <Typography textAlign="center" fontSize="1rem" color="textSecondary">
          {legalForm}
        </Typography>
      )}
      {city && (
        <Typography textAlign="center" fontSize="1rem" color="textSecondary">
          {`${postal} ${city}, ${province}`}
        </Typography>
      )}
    </Box>
  )
}
