import React from 'react'
import PropTypes from 'prop-types'
import { CardContent, Typography, IconButton, Skeleton } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { DocumentDownload } from 'iconsax-react'
import { CustomCard } from 'components'

/// ////////COMPONENT PROPS
InvoiceCard.propTypes = {
  date: PropTypes.string,
  plan: PropTypes.string,
  amount: PropTypes.any,
  onClick: PropTypes.func
}

export default function InvoiceCard({
  loading,
  date,
  plan,
  amount,
  currency,
  onClick
}) {
  if (loading) return <LoadingCard />

  return (
    <CustomCard>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          '&:last-child': {
            pb: 2
          }
        }}
      >
        <Typography>{date}</Typography>
        <Typography color='textSecondary' textTransform='uppercase'>
          {plan}
        </Typography>
        <Typography fontWeight={500}>{`${amount} ${currency}`}</Typography>
        <IconButton
          aria-label='download-invoice'
          onClick={onClick}
          size='medium'
          color='primary'
          sx={{
            transition: 'background-color 250ms ease',
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15)
            }
          }}
        >
          <DocumentDownload variant='Linear' />
        </IconButton>
      </CardContent>
    </CustomCard>
  )
}

const LoadingCard = () => (
  <CustomCard>
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        '&:last-child': {
          pb: 2
        }
      }}
    >
      <Typography>
        <Skeleton width={80} />
      </Typography>
      <Typography color='textSecondary' textTransform='uppercase'>
        <Skeleton width={80} />
      </Typography>
      <Typography>
        <Skeleton width={50} />
      </Typography>
      <Skeleton variant='circular' width={38} height={38} />
    </CardContent>
  </CustomCard>
)
