import { Fragment } from 'react'
import { Box, CardContent, Divider, Skeleton, Typography } from '@mui/material'
import { CustomCard, LastMessage } from 'components'
import { ROLE } from 'constants/Roles'
import { useUser } from 'context/UserContext'

export default function ChatCard({ loading, chats, job, swipeable }) {
  const { user } = useUser()

  if (loading) return <LoadingCard user={user} />

  return (
    <CustomCard sx={{ '& .MuiCardContent-root:last-child': { pb: 0 } }}>
      <CardContent sx={{ p: 0 }}>
        <Box aria-label='job-info' sx={{ pt: 2, px: 2 }}>
          <Typography color='textSecondary'>{job.title}</Typography>
          {user.role === ROLE.COMPANY && (
            <Typography
              fontSize={14}
              sx={{ color: 'text.disabled' }}
            >{`${job.city}, ${job.province}`}</Typography>
          )}
        </Box>
        <Box
          aria-label='chats-list'
          sx={{ display: 'flex', flexDirection: 'column', pt: 0.5 }}
        >
          {chats.map((chat, index) => (
            <Fragment key={chat.chat_id}>
              <Box aria-label='chat-item'>
                <LastMessage chat={chat} jobData={job} offerData={chat.offer} />
              </Box>
              {index !== chats.length - 1 && <Divider variant='middle' />}
            </Fragment>
          ))}
        </Box>
      </CardContent>
    </CustomCard>
  )
}

const LoadingCard = ({ user }) => (
  <CustomCard sx={{ '& .MuiCardContent-root:last-child': { pb: 0 } }}>
    <CardContent sx={{ p: 0 }}>
      <Box aria-label='job-info' sx={{ pt: 2, px: 2 }}>
        <Typography>
          <Skeleton width='100%' />
        </Typography>
        {user.role === ROLE.COMPANY && (
          <Typography>
            <Skeleton width='25%' />
          </Typography>
        )}
      </Box>
      <Box
        aria-label='chats-list'
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box aria-label='chat-item' sx={{ display: 'flex', p: 2, gap: 2 }}>
          <Skeleton variant='circular' height={40} width={40} />
          <Box sx={{ width: '100%', flex: 1 }}>
            <Typography>
              <Skeleton width='60%' />
            </Typography>
            <Typography>
              <Skeleton />
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardContent>
  </CustomCard>
)
