import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Box, CardContent, Typography } from '@mui/material'
import { CustomCard } from 'components'
import { ROLE } from 'constants/Roles'
import { blueGrey } from '@mui/material/colors'
import { useI18N, useThemeMode } from 'hooks'

export default function AccountType({ onChange, value, id }) {
  const { t } = useI18N()
  const [selected, setSelected] = useState(value || null)

  const handleSelect = (type) => () => {
    setSelected(type)
    onChange({ [id]: type })
  }

  return (
    <>
      <Box sx={{ pb: 2, maxWidth: 320, mx: 'auto' }}>
        <Typography
          fontSize='1.7rem'
          fontWeight={600}
          lineHeight='1.1em'
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('accountType')}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <AccountCard
          title={t('particular')}
          id={ROLE.PARTICULAR}
          selected={selected}
          onSelect={handleSelect}
        />
        <AccountCard
          title={t('company')}
          id={ROLE.COMPANY}
          selected={selected}
          onSelect={handleSelect}
        />
      </Box>
    </>
  )
}

const AccountCard = ({ id, selected, onSelect, title }) => {
  const isSelected = selected === id
  const { darkMode } = useThemeMode()

  const colors = {
    user: {
      bg: 'linear-gradient(105deg, #00FFC4 0%, #214FFF 100%)',
      bubble1: 'radial-gradient(at 10% 0%, #9D77EE 0%, #5215D6 40%)',
      bubble2: 'radial-gradient(at 20% 10%, #C877EE 0%, #D11BCA 50%)'
    },
    company: {
      bg: 'linear-gradient(105deg, #FF3838 0%, #FFA95E 100%)',
      bubble1: 'radial-gradient(at 10% 0%, #FFCA8C 0%, #FFA800 40%)',
      bubble2: 'radial-gradient(at 20% 10%, #4138FF 0%, #FF5E5E 50%)'
    }
  }
  return (
    <CustomCard
      onClick={onSelect(id)}
      sx={{
        mb: 2,
        maxWidth: 365,
        position: 'relative',
        transition: 'all 0.6s ease-out',
        bgcolor: isSelected
          ? 'transparent'
          : darkMode
          ? 'background.default'
          : blueGrey[50],
        '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          zIndex: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background:
            darkMode && !isSelected
              ? 'linear-gradient(145deg, #1a1936 0%, transparent 50%, transparent 100%)'
              : 'transparent'
        }
      }}
    >
      <CardContent
        sx={{
          p: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 160,
          position: 'relative',
          zIndex: 5
        }}
      >
        <Typography
          textTransform='uppercase'
          textAlign='center'
          fontSize='2rem'
          fontWeight={600}
          color={
            isSelected
              ? 'whitesmoke'
              : darkMode
              ? 'text.secondary'
              : blueGrey[400]
          }
        >
          {title}
        </Typography>
      </CardContent>
      <AnimatePresence>
        {isSelected && (
          <>
            <motion.div
              key='background'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'spring', stiffness: 40 }}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 0,
                background: colors[id].bg
              }}
            />
            <motion.div
              key='bubble_2'
              initial={{ x: 200, y: 200, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 200, y: 200, opacity: 0 }}
              transition={{ type: 'spring', stiffness: 40 }}
              style={{
                position: 'absolute',
                top: 20,
                left: 80,
                zIndex: 1
              }}
            >
              <Box
                component='span'
                id='bubble_2'
                sx={{
                  borderRadius: '100%',
                  position: 'relative',
                  height: '400px',
                  width: '400px',
                  background: colors[id].bubble1,
                  display: 'block'
                }}
              />
            </motion.div>
            <motion.div
              key='bubble_1'
              initial={{ x: 200, y: -200, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 200, y: -200, opacity: 0 }}
              transition={{ type: 'spring', stiffness: 40, delay: 0.1 }}
              style={{ position: 'absolute', top: -50, right: -30, zIndex: 2 }}
            >
              <Box
                component='span'
                id='bubble_1'
                sx={{
                  borderRadius: '100%',
                  position: 'relative',
                  height: '150px',
                  width: '150px',
                  background: colors[id].bubble2,
                  display: 'block'
                }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </CustomCard>
  )
}
