import { Box, Typography } from '@mui/material'
import { AvatarInput, FileInput, MultilineInput } from 'components'
import { useSnackbar } from 'context/SnackBarContext'
import { useI18N } from 'hooks'

export default function OtherCompanyData({ id, values, onChange }) {
  const { t } = useI18N()
  const { showSnackbar } = useSnackbar()
  const handleChange = (newValue) => {
    onChange({ [id]: { ...values, ...newValue } })
  }
  const handleError = (error) => {
    showSnackbar({
      message: error
    })
  }

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography
          fontSize='1.4rem'
          fontWeight={600}
          lineHeight='1.1em'
          sx={{ textAlign: 'center', mb: 2 }}
        >
          {t('getMoreVisits')}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', mb: 4, position: 'relative' }}>
        <FileInput
          id='picture'
          accept='.png,.jpg,.jpeg'
          maxSize={5}
          value={values.picture}
          onChange={handleChange}
          onError={handleError}
        >
          <AvatarInput
            image={values.picture}
            sx={{
              borderRadius: 4,
              width: 140,
              height: 140,
              border: (theme) => `solid 1px ${theme.palette.action.focus}`
            }}
          />
        </FileInput>
      </Box>
      <MultilineInput
        id='bio'
        onChange={handleChange}
        label={t('bio')}
        value={values.bio}
        placeholder={t('bioPlaceHolder')}
      />
    </>
  )
}
