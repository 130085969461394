export const OFFER_STATES = {
  ACCEPTED: 'accepted',
  NEGOTIATING: 'negotiating',
  REJECTED: 'rejected',
  ARCHIVED: 'archived',
  // Only for chats
  PROPOSAL: 'proposal'
}

export const POST_STATES = {
  POSTED: 'posted',
  DRAFT: 'draft',
  ASSIGNED: 'assigned',
  ARCHIVED: 'archived'
}
