import { useState } from 'react'
import { useUser } from 'context/UserContext'
import { useHistory } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_CHAT_ID_BY_ASSIGNED, GET_USER_JOBS } from 'apis/querys'
import { Box, Grid, Typography } from '@mui/material'
import { CompactJobCard, Button } from 'components'
import { getDate } from 'helpers'
import { routes } from 'constants/Routes'
import ErrorIllustration from 'assets/illustration/error'
import NothingHereIllustration from 'assets/illustration/nothing-here'
import NothingAssignedIllustration from 'assets/illustration/nothing-assigned'
import NoResultsIllustration from 'assets/illustration/no-results'
import { POST_STATES } from 'constants/Enums'
import { useI18N } from 'hooks'

export default function UserPostsList({ state = POST_STATES.POSTED }) {
  const { t } = useI18N()
  const { user } = useUser()
  const history = useHistory()
  const [limit, setLimit] = useState(20)
  const [noMoreResults, setNoMoreResults] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [getChatId] = useLazyQuery(GET_CHAT_ID_BY_ASSIGNED)

  const variables = {
    userId: user.id,
    sortBy: {
      field: 'post_date',
      order: 'DESC'
    },
    offset: 0,
    limit,
    state: [state]
  }

  const { error, data, fetchMore, networkStatus } = useQuery(GET_USER_JOBS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables
  })
  const posts = data?.getUserJobs ?? []

  const onLoadMore = () => {
    if (!noMoreResults) {
      setLoadingMore(true)
      const currentLength = posts.length
      if (currentLength) {
        fetchMore({
          variables: {
            offset: currentLength,
            limit: 20
          }
        }).then((fetchMoreResult) => {
          const newPostsLength = fetchMoreResult.data?.getUserJobs.length

          if (newPostsLength === 0) {
            setNoMoreResults(true)
          }
          setLimit(currentLength + newPostsLength)
        })
      }
    }
  }

  const handlePostClick = (post) => {
    const { _id, state, assigned_to } = post

    switch (state) {
      case 'posted':
        return history.push(`${routes.post}?id=${_id}`)
      case 'draft':
        return history.push(`${routes.post}/${_id}`)
      case 'assigned':
        return getChatId({
          variables: {
            jobId: _id,
            companyId: assigned_to
          }
        }).then((resp) => {
          const { _id: chatId } = resp.data.getChat
          history.push(`${routes.chat}`, {
            chatId,
            jobData: post,
            receptorId: assigned_to
          })
        })
      default:
        break
    }
  }

  const loading = networkStatus !== 7

  if (error) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          inset: 0,
          textAlign: 'center',
          p: 2
        }}
      >
        <Box width='70%' maxWidth={300}>
          <ErrorIllustration />
        </Box>
        <h2>{t('errorLoadingData')}</h2>
      </Box>
    )
  }

  if (loading) {
    return (
      <Grid container spacing={2}>
        {[...Array(3).keys()].map((i) => (
          <Grid key={i} item xs={12}>
            <CompactJobCard loading />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      {posts.length === 0 && (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            inset: 0,
            textAlign: 'center',
            p: 2
          }}
        >
          {state === POST_STATES.POSTED && (
            <>
              <Box width='70%' maxWidth={200}>
                <NoResultsIllustration />
              </Box>
              <Typography fontSize={18} fontWeight={500} maxWidth={300}>
                {t('nothingPostedYet')}
              </Typography>
              <Typography color='textSecondary' maxWidth={300}>
                {t('tryFromTheSearchbar')}
              </Typography>
            </>
          )}
          {state === POST_STATES.DRAFT && (
            <>
              <Box width='70%' maxWidth={300}>
                <NothingHereIllustration />
              </Box>
              <Typography fontSize={18} fontWeight={500} maxWidth={150}>
                {t('nothingInDrafts')}
              </Typography>
            </>
          )}
          {state === POST_STATES.ASSIGNED && (
            <>
              <Box width='80%' maxWidth={300}>
                <NothingAssignedIllustration />
              </Box>
              <Typography fontSize={18} fontWeight={500} maxWidth={150}>
                {t('nothingInAssigned')}
              </Typography>
            </>
          )}
        </Box>
      )}

      {posts.length > 0 && (
        <Grid container spacing={2}>
          {posts.map((post) => (
            <Grid key={post._id} item xs={12}>
              <CompactJobCard
                title={post.title}
                startDate={
                  post.start_date ? getDate(post.start_date) : t('noDate')
                }
                postedDate={
                  post.post_date ? getDate(post.post_date) : t('noDate')
                }
                jobState={post.state}
                postalCode={post.postal_code}
                city={post.city}
                onClick={() => handlePostClick(post)}
              />
            </Grid>
          ))}
          {!loading && !noMoreResults && posts.length >= limit && (
            <Button
              onClick={onLoadMore}
              variant='loadMore'
              loading={loadingMore}
              text={t('loadMore')}
              sx={{ mx: 'auto', mt: 2 }}
            />
          )}
        </Grid>
      )}
    </>
  )
}
