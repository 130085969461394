export function omit(keys, obj) {
  const omitted = {}
  for (const key in obj) {
    // Use the includes method to check if a key is in the array
    if (!keys.includes(key)) {
      // Copy the key-value pair to the new object
      omitted[key] = obj[key]
    }
  }
  return omitted
}

// It compare if two object are equals
export function deepCompare(obj1, obj2) {
  // Compare primitives and functions
  if (obj1 === obj2) return true

  // Compare dates
  if (obj1 instanceof Date && obj2 instanceof Date)
    return obj1.getTime() === obj2.getTime()

  // Compare arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return (
      obj1.length === obj2.length &&
      obj1.every((item, index) => deepCompare(item, obj2[index]))
    )
  }

  // Compare objects
  if (obj1 instanceof Object && obj2 instanceof Object) {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    return (
      keys1.length === keys2.length &&
      keys1.every((key) => deepCompare(obj1[key], obj2[key]))
    )
  }

  // Otherwise return false
  return false
}
