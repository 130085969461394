import { CardContent, Typography, Box, Skeleton } from '@mui/material'
import { OfferDates, CustomCard } from 'components'
import texts from 'constants/texts'

function getPostState(state) {
  const states = ['draft', 'posted']
  const statesTexts = []
  statesTexts[0] = texts().draft
  statesTexts[1] = texts().posted
  statesTexts[2] = texts().assigned

  const index = states.indexOf(state) !== -1 ? states.indexOf(state) : 2
  return statesTexts[index]
}

export default function CompactJobCard({
  title,
  startDate,
  jobState,
  postedDate,
  postalCode,
  city,
  loading,
  ...other
}) {
  if (loading) return <LoadingCard />

  return (
    <CustomCard {...other}>
      <CardContent sx={{ maxWidth: '100%' }}>
        <Typography fontSize={16} fontWeight={500}>
          {title}
        </Typography>
        <Typography
          noWrap
          color='textSecondary'
          fontSize={'0.9rem'}
        >{`${postalCode} | ${city}`}</Typography>
        <Box aria-label='dates-container' sx={{ mb: 1 }}>
          <OfferDates startDate={startDate} />
        </Box>
        <Typography
          fontSize={14}
          lineHeight={0}
          color='textSecondary'
          textAlign='right'
        >
          {getPostState(jobState)}
          {jobState === 'posted' && `: ${postedDate}`}
        </Typography>
      </CardContent>
    </CustomCard>
  )
}

const LoadingCard = () => (
  <CustomCard>
    <CardContent sx={{ maxWidth: '100%' }}>
      <Typography fontSize={16}>
        <Skeleton />
      </Typography>
      <Typography fontSize='0.9rem'>
        <Skeleton width='50%' />
      </Typography>
      <Box sx={{ mb: 1 }}>
        <Skeleton width='50%' />
      </Box>
      <Typography fontSize={14}>
        <Skeleton width='20%' />
      </Typography>
    </CardContent>
  </CustomCard>
)
