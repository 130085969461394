import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Typography
} from '@mui/material'

function Checkbox({
  id,
  label,
  value,
  onChange,
  error,
  required,
  disabled,
  checkBoxStyle,
  ...other
}) {
  const handleChange = (checked) => {
    if (id) {
      onChange({ [id]: checked })
    } else {
      onChange(checked)
    }
  }

  return (
    <FormControl id={id} disabled={disabled} sx={{ textAlign: 'start' }}>
      <FormControlLabel
        control={
          <MuiCheckbox
            color='primary'
            checked={value}
            onChange={(e) => handleChange(e.target.checked)}
            sx={{
              color: (theme) =>
                error ? theme.palette.error.main : 'text.disabled',
              px: 1,
              py: 0,
              '&.MuiCheckbox-root': {
                mt: 0
              },
              ...checkBoxStyle
            }}
            {...other}
          />
        }
        label={label}
      />
      {error && (
        <Typography
          sx={{
            fontSize: 12,
            color: (theme) => theme.palette.error.main,
            pt: 0.5,
            pl: 3.5
          }}
        >
          {error}
        </Typography>
      )}
    </FormControl>
  )
}

export default React.memo(Checkbox)
