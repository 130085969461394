import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'

/// ////////COMPONENT PROPS
DiscreteSlider.propTypes = {
  inputTitle: PropTypes.string,
  inputId: PropTypes.string,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  units: PropTypes.string,
  defaultValue: PropTypes.number,
  steps: PropTypes.number,
  onChange: PropTypes.func, // function(event: object, value: number | number[]) => void
  name: PropTypes.string,
}

const useStyles = makeStyles({
  root: {
    textAlign: 'start',
  },
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    maxWidth: 250,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  slider: {
    maxWidth: 250,
  },
  labelContainer: {
    maxWidth: 270,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mark: {
    fontSize: '.857rem',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: 1.43,
    whiteSpace: 'nowrap',
    transform: 'translateX(-50%)',
    top: 40,
  },
  leftMark: {
    left: '0%',
  },
  rightMark: {
    left: '100%',
  },
})

const CustomSlider = withStyles((theme) => ({
  root: {
    height: 8,
    marginBottom: 20,
  },
  thumb: {
    height: 16,
    width: 16,
  },
  active: {},
  valueLabel: {
    left: 'calc(-60% + 2px)',
    '& *': {
      background: theme.palette.primary.main,
      color: '#ffff',
    },
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
  mark: {
    backgroundColor: '#ffff',
    height: 6,
    width: 2,
    "&[data-index='0']": {
      display: 'none',
    },
  },
}))(Slider)

function valuetext(value) {
  return `${value}`
}

export default function DiscreteSlider(props) {
  const classes = useStyles()
  const {
    inputId,
    inputTitle,
    maxValue,
    minValue,
    defaultValue,
    steps,
    onChange,
    units,
    name,
    ...other
  } = props  
  
  
  /* <Grid
  container
  spacing={0}
  justify="space-between"
  className={classes.labelContainer}
>
  <Grid item xs>
    <Typography color="textSecondary" className={classes.label}>
      {minValue}
      {units}
    </Typography>
  </Grid>
  <Grid item xs>
    <Typography
      color="textSecondary"
      align="right"
      className={classes.label}
    >
      {maxValue}
      {units}
    </Typography>
  </Grid>
</Grid> */

  return (
    <div className={classes.root}>
      <Typography gutterBottom>{inputTitle}</Typography>
      <div className={classes.sliderContainer}>
        <CustomSlider
          className={classes.slider}
          id={inputId}
          defaultValue={defaultValue}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-small-steps"
          step={steps}
          marks
          min={minValue}
          max={maxValue}
          valueLabelDisplay="auto"
          onChangeCommitted={onChange}
          {...other}
        />
        <Typography
            component="span"
            color="textSecondary"
            className={clsx(classes.mark, classes.leftMark)}
          >
            {minValue}
            {units}
          </Typography>
          <Typography
            component="span"
            color="textSecondary"
            align="right"
            className={clsx(classes.mark, classes.rightMark)}
          >
            {'≥ '}
            {maxValue}
            {units}
          </Typography>
      </div>
    </div>
  )
}
