import React from 'react'
import { Box } from '@mui/material'

export default function Form({ children, ...other }) {
  return (
    <Box component="form" {...other}>
      {children}
    </Box>
  )
}
