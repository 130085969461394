import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import {
  Button,
  Carrousel,
  Link,
  ScrollableContainer,
  Spacer
} from 'components'
import { routes } from 'constants/Routes'
import professional from 'assets/illustration/find-professional.png'
import research from 'assets/illustration/research.png'
import ratting from 'assets/illustration/ratting.png'
import helping from 'assets/illustration/helping.png'
import { useI18N } from 'hooks'

const items = (t) => [
  {
    label: t('accountSlider_1'),
    image: professional
  },
  {
    label: t('accountSlider_2'),
    image: research
  },
  {
    label: t('accountSlider_3'),
    image: ratting
  },
  {
    label: t('accountSlider_4'),
    image: helping
  }
]

export default function GuestUserAccount() {
  const { t } = useI18N()
  const history = useHistory()

  const goToSignUp = () => {
    history.push(routes.signUp)
  }

  return (
    <ScrollableContainer>
      <Box
        aria-label='page-container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <Spacer aria-label='carrousel-container' p={2}>
          <Carrousel items={items(t)} />
        </Spacer>

        <Spacer aria-label='cta-container' p={2} sx={{ flex: 1 }}>
          <Box aria-label='text-container' sx={{ mb: 3 }}>
            <Typography
              fontSize='1.8rem'
              fontWeight={700}
              lineHeight={1.1}
              mb={2}
            >
              {t('welcomeToJobsaun')}
            </Typography>
            <Typography>{t('slogan')}</Typography>
          </Box>
          <Spacer
            aria-label='button-container'
            y={2}
            sx={{
              width: '100%'
            }}
          >
            <Button onClick={goToSignUp} fullWidth text={t('joinNow')} />
            <Spacer pt={2} sx={{ textAlign: 'center' }}>
              <Typography>
                {t('alreadyHasAccount')}{' '}
                <Link href={routes.signIn}>{t('signIn')}</Link>
              </Typography>
            </Spacer>
          </Spacer>
        </Spacer>
      </Box>
    </ScrollableContainer>
  )
}
