import React from 'react'
import { alpha, Box, ButtonBase } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    }
  }
}

const Tabs = ({ items, page, onChange }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        width: '100%',
        borderRadius: 100,
        bgcolor: 'background.paper',
        p: 0.5,
        border: (theme) => `1px solid ${theme.palette.background.contrast}`,
        position: 'relative'
      }}
    >
      <Box
        component={motion.span}
        initial={false}
        animate={{
          x: `calc(${page * 100}% - ${page * 8}px)`
        }}
        transition={{ duration: 0.3 }}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          margin: 0.5,
          width: `${Math.abs(100 / items.length)}%`,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
          borderRadius: 100,
          zIndex: 0
        }}
      />

      {items.map((item, index) => {
        const isActive = index === page

        return (
          <ButtonBase
            onClick={() => onChange([index, index - page])}
            key={item}
            disableRipple
            sx={{
              transition: 'color 400ms ease',
              flex: 1,
              textAlign: 'center',
              padding: 1,
              zIndex: 1,
              position: 'relative',
              fontSize: '1rem',
              height: 40,
              color: (theme) =>
                isActive
                  ? theme.palette.primary.dark
                  : theme.palette.text.primary,
              fontFamily: 'Poppins',
              fontWeight: isActive ? 500 : 400
            }}
          >
            {item}
          </ButtonBase>
        )
      })}
    </Box>
  )
}

const TabPageContainer = ({ direction, page, children }) => {
  const childrenArray = React.Children.toArray(children)

  return (
    <AnimatePresence initial={false} custom={direction}>
      <Box
        className='hidden-scroll'
        key={page}
        component={motion.section}
        variants={variants}
        custom={direction}
        initial='enter'
        animate='center'
        exit='exit'
        transition={{
          x: { type: 'spring', stiffness: 300, damping: 30, duration: 2 },
          opacity: { duration: 0.2 }
        }}
        sx={{
          position: 'relative'
        }}
      >
        {children && childrenArray[page]}
      </Box>
    </AnimatePresence>
  )
}

const TabPage = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      inset: 0
    }}
  >
    <Box p={2}>{children}</Box>
  </Box>
)

Tabs.Page = TabPage
Tabs.Container = TabPageContainer

export default Tabs
