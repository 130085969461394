import React from 'react'

const StripeInput = React.forwardRef(
  ({ component: Component, ...props }, ref) => {
    const elementRef = React.createRef()

    React.useImperativeHandle(ref, () => ({
      focus: () => elementRef.current.focus
    }))

    return (
      <Component
        onReady={(element) => (elementRef.current = element)}
        {...props}
      />
    )
  }
)

export default StripeInput
