import PropTypes from 'prop-types'
import {
  ListItemText,
  ListItemAvatar,
  Avatar,
  List,
  ListSubheader,
  ListItemButton,
  Typography,
  Divider,
  Box
} from '@mui/material'
import { Spacer } from 'components'
import { alpha } from '@mui/material/styles'
import { ArrowFoward } from 'assets/Icons'

ButtonsList.propTypes = {
  list: PropTypes.array.isRequired,
  headerTitle: PropTypes.string,
  subHeaderTitle: PropTypes.string,
  disableGutters: PropTypes.bool
}

export default function ButtonsList({
  list,
  divider,
  headerTitle,
  subHeaderTitle,
  ...other
}) {
  return (
    <Box>
      <Spacer x={2}>
        {divider && <Divider sx={{ mb: 2 }} />}
        {headerTitle && (
          <Typography
            textTransform='uppercase'
            fontSize={14}
            color='textSecondary'
            gutterBottom
          >
            {headerTitle}
          </Typography>
        )}
      </Spacer>
      <List
        component='nav'
        subheader={
          <ListSubheader component='div'>{subHeaderTitle}</ListSubheader>
        }
        sx={{
          width: '100%',
          textAlign: 'left'
        }}
      >
        {list.map((item, index) => (
          <ListItemButton
            key={item.id || `list-item-${index}`}
            id={`item-${item.id || index}`}
            onClick={item.action}
            sx={{
              px: 2,
              '&:hover': {
                bgcolor: 'action.hover',
                '.MuiListItemText-root': {
                  color: 'text.secondary'
                }
              }
            }}
            {...other}
          >
            {item.icon && (
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: (theme) => alpha(theme.palette.text.primary, 0.05),
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'grey.400' : 'grey.700',
                    maxWidth: 40,
                    maxHeight: 40
                  }}
                >
                  {item.icon}
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              sx={{
                transition: 'color 0.2s ease-in-out',
                color: 'text.primary'
              }}
              primary={item.title}
              {...(item.subtitle && { secondary: item.subtitle })}
            />
            <ArrowFoward
              fontSize='small'
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'grey.400' : 'grey.700'
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  )
}
