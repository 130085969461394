import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import {
  AdornmentInput,
  Button,
  DateInput,
  Dialog,
  Input,
  MaskedInput,
  Modal,
  MultilineInput,
  PicturesInputContainer,
  RadioGroup,
  ScrollableContainer,
  SelectableInput,
  Spacer
} from 'components'
import { useForm, useI18N, useLazyEffect, usePost } from 'hooks'
import { categories, subcategoriesParents } from 'constants/CategoriesData'
import { getCityByPostalCode, provincesList } from 'constants/Provinces'
import texts from 'constants/texts'
import { questions } from 'constants/Questions'
import { routes } from 'constants/Routes'
import { useSnackbar } from 'context/SnackBarContext'
import { Trash } from 'iconsax-react'
import { POST_STATES } from 'constants/Enums'
import { deepCompare, omit } from 'helpers/compareObjects'
import dayjs from 'dayjs'

const sortObject = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key]
      return result
    }, {})
}

const booleanRadioItems = [
  { value: 'true', label: texts().yes },
  { value: 'false', label: texts().no }
]

const getQuestions = (category) => {
  const categoryQuestions = questions.filter((question) => {
    return question.categories.includes(category)
  })

  const defaultQuestionsAnswers = []
  const createMap = (id, main) => {
    const answer = main ? 'true' : ''
    return { id, main, answer }
  }

  categoryQuestions.forEach((item) => {
    defaultQuestionsAnswers.push(createMap(item.id, item.main))
  })

  return {
    categoryQuestions,
    defaultQuestionsAnswers
  }
}

export default function UpdatePost() {
  const { t } = useI18N()
  const history = useHistory()
  const { postId } = useParams()
  const [removeAlertOpen, setRemoveAlertOpen] = useState(false)
  const [postState, setPostState] = useState()
  const { remove, removing } = usePost()

  useEffect(() => {
    if (!postId) history.replace(routes.home)
  }, [])

  function handleRemovePost() {
    remove({ id: postId }).then(() => {
      history.replace(routes.board)
    })
  }

  function handleOnBack() {
    if (postState === POST_STATES.DRAFT) {
      history.replace(routes.board)
    } else {
      history.goBack()
    }
  }

  return (
    <ScrollableContainer
      title={t('editPost')}
      appBarProps={{
        hasBack: true,
        customGoBack: handleOnBack,
        ...(postId && {
          actions: (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                onClick={() => setRemoveAlertOpen(true)}
                disabled={removing}
              >
                <Trash size={24} />
              </IconButton>
            </Box>
          )
        })
      }}
    >
      <Spacer x={2} mb={2}>
        <NewJobForm postId={postId} onStateChange={setPostState} />
      </Spacer>
      <Modal open={removeAlertOpen} anchor='center'>
        <Dialog>
          <Dialog.Title title={t('areYouSure')} />
          <Dialog.Content>
            <Typography>{t('youAreGoingToDelete')}</Typography>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              disabled={removing}
              onClick={() => setRemoveAlertOpen(false)}
              text={t('cancel')}
              variant='light'
            />
            <Button
              loading={removing}
              onClick={handleRemovePost}
              text={removing ? t('deleting') : t('delete')}
            />
          </Dialog.Actions>
        </Dialog>
      </Modal>
    </ScrollableContainer>
  )
}

const NewJobForm = ({ postId, onStateChange }) => {
  const { t } = useI18N()
  const { showSnackbar } = useSnackbar()
  const [removedPictures, setRemovedPictures] = useState([])
  const [questionsSetUp, setQuestionsSetUp] = useState([])
  const [categoryFilter, setCategoryFilter] = useState('')
  const [updating, setUpdating] = useState(false)
  const { data, loading, update, savePictures, removePictures } = usePost({
    id: postId
  })

  let savingState
  const validate = (fieldValues = values) => {
    const temp = { ...errors }

    if ('title' in fieldValues)
      temp.title = fieldValues.title ? '' : t('required')

    if (savingState === 'posted') {
      if ('category' in fieldValues)
        temp.category = fieldValues.category ? '' : t('required')
      if ('province' in fieldValues)
        temp.province = fieldValues.province ? '' : t('required')
      if ('postal_code' in fieldValues)
        temp.postal_code = fieldValues.postal_code ? '' : t('fiveDigits')
      if ('city' in fieldValues)
        temp.city = fieldValues.city ? '' : t('required')
      if ('budget' in fieldValues)
        temp.budget = fieldValues.budget ? '' : t('required')
      if ('start_date' in fieldValues)
        temp.start_date = fieldValues.start_date ? '' : t('required')
    } else {
      if ('category' in fieldValues) temp.category = ''
      if ('province' in fieldValues) temp.province = ''
      if ('postal_code' in fieldValues) temp.postal_code = ''
      if ('city' in fieldValues) temp.city = ''
      if ('budget' in fieldValues) temp.budget = ''
      if ('start_date' in fieldValues) temp.start_date = ''
    }

    setErrors({
      ...temp
    })

    if (fieldValues === values) {
      const allCorrect = Object.values(temp).every((x) => x === '')
      return allCorrect
    }
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    null,
    false,
    validate
  )

  const newValues = values ? JSON.stringify(sortObject(values)) : null
  const oldValues = data ? JSON.stringify(sortObject(data)) : null

  useEffect(() => {
    if (data) {
      if (data.category) {
        setCategoryFilter(subcategoriesParents([data.category])[0])
      }
      setValues({ ...data, start_date: data.start_date * 1 })
      onStateChange(data.state)
    }
  }, [data])

  useLazyEffect(() => {
    if (values.postal_code.length >= 4 && values.country) {
      getCityByPostalCode(values.postal_code, values.country).then(
        ({ city = '', province = '' }) => {
          if (city) {
            setValues((prev) => ({ ...prev, city, province }))
          }
        }
      )
    } else {
      setValues((prev) => ({ ...prev, city: '', province: '' }))
    }
  }, [values.postal_code])

  useLazyEffect(() => {
    if (categoryFilter !== '') {
      const { categoryQuestions, defaultQuestionsAnswers } =
        getQuestions(categoryFilter)
      setQuestionsSetUp(categoryQuestions)
      setValues((prev) => ({
        ...prev,
        questions: prev.questions || defaultQuestionsAnswers
      }))
    }
  }, [categoryFilter])

  async function handleSubmit() {
    try {
      setUpdating(true)
      if (!validate()) throw new Error(t('youShouldCompleteTheRequiredFields'))

      const postDate =
        values.state === POST_STATES.POSTED
          ? values.post_date || new Date()
          : null

      const formValues = {
        title: values.title,
        category: values.category || null,
        country: values.country,
        province: values.province,
        postalCode: values.postal_code,
        city: values.city,
        budget: parseFloat(values.budget),
        startDate: String(values.start_date),
        description: values.description,
        questions: values.questions,
        state: values.state,
        postDate
      }

      const promises = []
      const newPictures = values.pictures.filter((pic) => pic?.file)

      // PICTURES ALREADY STORAGED HAS BEEN REMOVED
      if (removedPictures.length > 0) {
        promises.push(
          removePictures({
            postId,
            prevPictures: values.pictures,
            removedPictures
          })
        )
      }

      // NEW PICTURES HAS BEN ADDED
      if (newPictures.length > 0) {
        promises.push(savePictures({ postId, pictures: values.pictures }))
      }

      const itHasChanged = !deepCompare(
        omit(['pictures'], values),
        omit(['pictures'], data)
      )

      if (itHasChanged) {
        // SOMETHING ELSE HAS CHANGED
        promises.push(update({ postId, values: formValues }))
      }

      await Promise.all(promises)
        .then(() => {
          onStateChange(values.state)
          showSnackbar({
            message: t('postUpdatedSuccessfully'),
            severity: 'success'
          })
        })
        .catch((error) => {
          console.log({ error })
          throw new Error(t('error.post.updating'))
        })
      // endSaving(values.state)
    } catch (error) {
      console.log({ error })
      showSnackbar({ message: error.message })
    } finally {
      setUpdating(false)
    }
  }

  const handleDeletePicture = useCallback(
    (pictureName) => {
      setValues((prev) => ({
        ...prev,
        pictures: prev.pictures.filter((pic) => pic.name !== pictureName)
      }))

      // IF THE IMAGE WAS ALREADY IN STORAGE ADD IT TO REMOVE
      const savedImageIndex = data?.pictures.findIndex(
        (pic) => pic.name === pictureName
      )
      if (savedImageIndex !== -1) {
        setRemovedPictures((prev) => [...prev, pictureName])
      }
    },
    [values.pictures]
  )

  const subcategories =
    categories.find((c) => c.id === categoryFilter)?.subCategories ?? []

  const isLoading = loading || Object.keys(values).length === 0

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 12
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <SelectableInput
            id='state'
            onChange={handleInputChange}
            label={t('state')}
            value={values.state}
            customKey='id'
            items={[
              { id: POST_STATES.POSTED, name: t(POST_STATES.POSTED) },
              { id: POST_STATES.DRAFT, name: t(POST_STATES.DRAFT) }
            ]}
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id='title'
            onChange={handleInputChange}
            label={t('title')}
            value={values.title}
            error={errors.title}
            required
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectableInput
            onChange={(newValue) => setCategoryFilter(newValue)}
            label={t('category')}
            value={categoryFilter}
            items={categories}
            customKey='id'
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectableInput
            id='category'
            onChange={handleInputChange}
            label={t('subCategory')}
            value={values.category}
            error={errors.category}
            items={subcategories}
            customKey='id'
            disabled={!categoryFilter || updating}
          />
        </Grid>
        <Grid item xs={12}>
          <MaskedInput
            id='postal_code'
            onChange={handleInputChange}
            label={t('postalCode')}
            value={values.postal_code}
            error={errors.postal_code}
            helperText={t('fiveDigits')}
            mask='00000'
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectableInput
            id='province'
            onChange={handleInputChange}
            label={t('province')}
            value={values.province}
            error={errors.province}
            items={
              values.province &&
              !provincesList(values.country).includes(values.province)
                ? provincesList(values.country).concat(values.province)
                : provincesList(values.country)
            }
            listContent='array'
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id='city'
            onChange={handleInputChange}
            label={t('city')}
            value={values.city}
            error={errors.city}
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <AdornmentInput
            id='budget'
            onChange={handleInputChange}
            label={t('budget')}
            value={values.budget}
            error={errors.budget}
            adornment={
              <Typography
                fontSize='1.4rem'
                fontWeight={500}
                color='textSecondary'
              >
                €
              </Typography>
            }
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <DateInput
            id='start_date'
            label={t('startDate')}
            value={dayjs(values.start_date)}
            disablePast
            error={errors.start_date}
            onChange={handleInputChange}
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <MultilineInput
            id='description'
            label={t('description')}
            value={values.description}
            onChange={handleInputChange}
            placeholder={t('explainTheJobOffer')}
            rows={10}
            disabled={updating}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography marginBottom={2}>
            {t('uploadPictures')}{' '}
            <Typography
              component='span'
              fontSize='small'
              color='textSecondary'
            >{`(${t('optional')})`}</Typography>
          </Typography>
          <PicturesInputContainer
            id='pictures'
            disabled={values.pictures.length >= 5 || updating}
            onChange={handleInputChange}
            imagesList={values.pictures}
            value={values.pictures}
            onDelete={handleDeletePicture}
          />
        </Grid>
        {questionsSetUp.length > 0 && (
          <Grid item xs={12}>
            <Questions
              name='questions'
              questionsArray={questionsSetUp}
              questions={values.questions}
              onChange={handleInputChange}
              disabled={updating}
            />
          </Grid>
        )}
      </Grid>
      <Box
        aria-label='buttons-container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          my: 2,
          gap: 2
        }}
      >
        <Button
          fullWidth
          text={updating ? t('saving') : t('save')}
          disabled={newValues === oldValues}
          loading={updating}
          onClick={handleSubmit}
        />
      </Box>
    </>
  )
}

const Questions = React.memo(
  ({ name, questionsArray, questions, onChange, disabled }) => {
    const { t } = useI18N()
    const handleChange = (main) => (e) => {
      const object = {
        id: Object.keys(e)[0],
        main,
        answer: Object.values(e)[0]
      }
      const values = [...questions]
      const index = values.findIndex((q) => q.id === Object.keys(e)[0])
      values.splice(index, 1, object)
      onChange({ [name]: values })
    }

    return (
      <>
        <Typography>{t('otherDetails')}</Typography>
        {questionsArray.map(({ question, id, main, type }) => {
          const questionValue = questions.find((q) => q.id === id).answer
          return main ? (
            <RadioGroup
              id={id}
              key={id}
              label={question}
              value={questionValue || ''}
              onChange={handleChange(main)}
              items={booleanRadioItems}
              disabled={disabled}
            />
          ) : (
            <div key={id}>
              <Typography color='textSecondary' gutterBottom>
                {question}
              </Typography>
              <Input
                id={id}
                value={questionValue || ''}
                onChange={handleChange(main)}
                type={type || 'text'}
              />
            </div>
          )
        })}
      </>
    )
  }
)

Questions.displayName = 'question'
