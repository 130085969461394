import { Grid, Typography } from '@mui/material'
import { Input, MultilineInput } from 'components'
import { useI18N } from 'hooks'

export default function DescriptionPage({ title, description, onChange }) {
  const { t } = useI18N()

  return (
    <>
      <Typography fontSize='1.5rem' mb={2}>
        {t('postDetails')}
      </Typography>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <Input
            id='title'
            onChange={onChange}
            label={t('title')}
            value={title}
          />
        </Grid>
        <Grid item xs={12}>
          <MultilineInput
            id='description'
            label={t('postDescription')}
            value={description}
            onChange={onChange}
            placeholder={t('explainTheNeededService')}
            rows={20}
          />
        </Grid>
      </Grid>
    </>
  )
}
