import { useContext } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { CircularProgress, Box } from '@mui/material'
import { MainContent, BottomBar } from 'components'
import { UserContext } from 'context/UserContext'
import { routes } from 'constants/Routes'
import * as PAGE from 'views'
import { ROLE } from 'constants/Roles'

const PrivateRoute = ({
  roles = [ROLE.COMPANY, ROLE.PARTICULAR],
  redirect,
  children
}) => {
  const { user } = useContext(UserContext)

  return !user.id || !roles.includes(user.role) ? (
    <Redirect to={redirect || '/'} />
  ) : (
    children
  )
}

export default function Switcher() {
  const { user } = useContext(UserContext)
  const location = useLocation()
  const pathsWithBottomBar = [
    routes.home,
    routes.discover,
    routes.inbox,
    routes.board,
    routes.account
  ]

  const loading = !user || !user?.role

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress thickness={4.5} />
      </Box>
    )

  return (
    <>
      <MainContent hasBottomBar={pathsWithBottomBar}>
        <Switch location={location} key={location.key}>
          <Route exact path={routes.home}>
            {!user.id ? <PAGE.Home /> : <Redirect to={routes.discover} />}
          </Route>

          <Route exact strict path={routes.discover}>
            <PAGE.Discover />
          </Route>

          <Route exact strict path={routes.inbox}>
            <PAGE.Inbox />
          </Route>

          <Route exact strict path={routes.board}>
            <PrivateRoute>
              {user.role === ROLE.PARTICULAR && <PAGE.Posts />}
              {user.role === ROLE.COMPANY && <PAGE.Offers />}
            </PrivateRoute>
          </Route>

          <Route exact strict path={routes.account}>
            <PAGE.Account />
          </Route>

          <Route exact path={routes.signIn}>
            {user.role !== 'guest' ? (
              <Redirect to={routes.account} />
            ) : (
              <PAGE.SignIn />
            )}
          </Route>

          <Route exact path={routes.accountDetails}>
            <PrivateRoute redirect={routes.account}>
              <PAGE.Details />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.ratings}>
            <PrivateRoute roles={[ROLE.COMPANY]} redirect={routes.account}>
              <PAGE.Ratings />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.info}>
            <PrivateRoute redirect={routes.account}>
              <PAGE.Info />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.updates}>
            <PrivateRoute redirect={routes.account}>
              <PAGE.Updates />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.signUp}>
            {user.role !== ROLE.GUEST ? (
              <Redirect to={routes.account} />
            ) : (
              <PAGE.SignUp />
            )}
          </Route>

          <Route exact path={routes.company}>
            <PAGE.CompanyProfile />
          </Route>

          <Route exact path={routes.postedJobs}>
            <PrivateRoute>
              <PAGE.PostedJobs />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.post}>
            <PAGE.Job />
          </Route>

          <Route exact path={routes.newPost}>
            <PAGE.NewPost />
          </Route>

          <Route exact path={routes.editPost}>
            <PrivateRoute>
              <PAGE.UpdatePost />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.chat}>
            <PrivateRoute>
              <PAGE.Chat />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.subscription}>
            <PrivateRoute roles={[ROLE.COMPANY]} redirect={routes.account}>
              <PAGE.Subscription />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.checkout}>
            <PrivateRoute roles={[ROLE.COMPANY]} redirect={routes.account}>
              <PAGE.CheckOut />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.newOffer}>
            <PrivateRoute roles={[ROLE.COMPANY]} redirect={routes.account}>
              <PAGE.NewOffer />
            </PrivateRoute>
          </Route>

          <Route exact path={`${routes.deleteAccount}`}>
            <PrivateRoute redirect='/account'>
              <PAGE.DeleteAccount />
            </PrivateRoute>
          </Route>

          <Route exact path={`${routes.resetpassword}`}>
            <PrivateRoute redirect='/account'>
              <PAGE.ResetPassword />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.filteredCompanies}>
            <PAGE.FoundCompanies />
          </Route>

          {process.env.NODE_ENV === 'development' && (
            <Route exact path='/admin'>
              <PrivateRoute redirect='/account'>
                <PAGE.Admin />
              </PrivateRoute>
            </Route>
          )}

          <Route path='*'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </MainContent>
      {pathsWithBottomBar.includes(location.pathname) && <BottomBar />}
    </>
  )
}
