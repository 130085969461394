import { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { ChatContainer, ChatInput, Button } from 'components'
import { getRandomId } from 'helpers'
import {
  useFirebase,
  useForm,
  useI18N,
  useOffer,
  useUserPublicInfo
} from 'hooks'
import { UserContext } from 'context/UserContext'
import { useSnackbar } from 'context/SnackBarContext'
import { GET_OFFER_BY_JOB_AND_COMPANY } from 'apis/querys'
import { ADD_MESSAGE } from 'apis/mutations'
import { routes } from 'constants/Routes'
import { ROLE } from 'constants/Roles'

const defaultValues = {
  message: '',
  attachment: ''
}

export default function Chat() {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  const history = useHistory()
  const [sending, setSending] = useState(false)
  const [receptorInfo, setReceptorInfo] = useState()
  const [autoFocus, setAutoFocus] = useState(false)
  const { getBasicUserInfo } = useUserPublicInfo()
  const { showSnackbar } = useSnackbar()
  const { loading, acceptOffer } = useOffer()
  const [offerData, setOfferData] = useState()
  const { chatId, jobData, receptorId, receptorRole } =
    history.location.state || ''
  const role =
    receptorRole ??
    (user.role === ROLE.COMPANY ? ROLE.PARTICULAR : ROLE.COMPANY)

  const { handleInputChange, values, resetForm } = useForm(defaultValues)
  const { uploadFile, storageChatFiles } = useFirebase()

  const { loading: loadingOffer, data } = useQuery(
    GET_OFFER_BY_JOB_AND_COMPANY,
    {
      fetchPolicy: 'network-only',
      variables: {
        jobId: jobData?._id,
        companyId: user.role === 'company' ? user.id : receptorId,
        limit: 1
      },
      skip: !chatId
    }
  )

  const [sendMessage] = useMutation(ADD_MESSAGE)

  useEffect(() => {
    const getUserData = async () => {
      const { name, picture, _id, public_name } = await getBasicUserInfo(
        receptorId,
        role
      )
      setReceptorInfo({ name, picture, _id, public_name })
    }

    if (!history.location.state) {
      history.replace(routes.inbox)
    } else {
      getUserData()
    }
  }, [])

  useEffect(() => {
    if (!loadingOffer && data && data.getOffers) {
      const offerState = data.getOffers[0].state
      if (offerState === 'cancelled') {
        history.replace('/inbox')
      }
      setOfferData(data.getOffers[0])
    }
  }, [loadingOffer, data])

  const saveFile = async () => {
    setSending(true)
    const fileUrl = await uploadFile({
      storageRef: storageChatFiles(jobData._id, chatId),
      file: values.attachment.file,
      fileName: getRandomId(12)
    })

    if (fileUrl.data) {
      handleSendMessage(fileUrl.data)
    }
  }

  const handleSendMessage = (url) => {
    if (!sending) setSending(true)

    if (values.message !== '' || url) {
      let type
      if (url) {
        type = values.attachment.type.includes('application') ? 'pdf' : 'image'
      } else {
        type = 'text'
      }
      const content = {
        type,
        name: url ? values.attachment.name : null,
        value: values.message || url
      }

      sendMessage({
        variables: {
          jobId: jobData._id,
          chatId,
          content,
          from: user.id
        }
      }).then(() => {
        resetForm()
        setSending(false)
      })
    }
  }

  const handleAcceptOffer = (event) => {
    acceptOffer({
      companyId: receptorId,
      jobId: jobData._id,
      offerId: offerData._id
    }).then((resp) => {
      if (resp.error) {
        showSnackbar({
          message: t('companyNotAvailable'),
          marginBottom: true
        })
      } else {
        setAutoFocus(true)
        setOfferData((prev) => ({ ...prev, state: 'accepted' }))
      }
    })
  }

  useEffect(() => {
    if (values.attachment) {
      saveFile()
    }
  }, [values.attachment])

  const handleKeyPress = (e) => {
    // it triggers by pressing the ctrl + enter key
    if (e.ctrlKey && e.which === 13) {
      handleSendMessage()
    }
  }

  return (
    <>
      <ChatContainer
        receptorInfo={receptorInfo}
        role={role}
        accepted={offerData?.state === 'accepted'}
        chatId={chatId}
        post={jobData}
        offer={offerData}
      />
      {offerData && offerData?.state !== 'accepted' && user.role === 'user' ? (
        <Box sx={{ p: 2, position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <Button
            text={t('acceptOffer')}
            loading={loading}
            onClick={handleAcceptOffer}
            fullWidth
          />
        </Box>
      ) : (
        <ChatInput
          id='message'
          disabled={offerData?.state !== 'accepted' || sending}
          placeholder={t('sendAMessage')}
          onChange={handleInputChange}
          value={sending ? t('sending') : values.message}
          attachment={values.attachment.file}
          onSubmit={handleSendMessage}
          onKeyPress={handleKeyPress}
          autoFocus={autoFocus}
        />
      )}
    </>
  )
}
