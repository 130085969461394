import { storage, auth, fbase } from 'apis/firebase'

export default function useFirebase() {
  const storageJobsPicturesRef = (id) => storage.ref(`jobs/${id}/pictures`)
  const storageChatFiles = (id, chat_id) =>
    storage.ref(`jobs/${id}/chat/${chat_id}`)
  const storageUserProfile = (id) => storage.ref(`users/${id}/profile`)

  async function uploadFile({ storageRef, file, fileName }) {
    const name = fileName || file.name
    const fileRef = storageRef.child(name)

    try {
      const upload = await fileRef.put(file)
      const url = await upload.ref.getDownloadURL()
      return { data: url }
    } catch (error) {
      console.error('Error saving file: ', error)
      return { error }
    }
  }

  function deleteFile({ storageRef, fileName }) {
    return storageRef.child(fileName).delete()
  }

  const deleteFolder = async ({ storageRef }) => {
    try {
      const listResult = await storageRef.listAll()
      const promises = listResult.items.map((item) => {
        return item.delete()
      })
      Promise.all(promises)
    } catch (error) {
      // console.log(error)
    }
  }

  const reauthenticateUser = async ({ password, onCompleted, onError }) => {
    const user = auth.currentUser
    const credential = fbase.auth.EmailAuthProvider.credential(
      user.email,
      password
    )

    try {
      await user.reauthenticateWithCredential(credential)
      // console.log({ userCredential })
      onCompleted && onCompleted()
    } catch (error) {
      // console.log({ error })
      onError && onError(error)
    }
  }

  const updatePassword = ({ newPassword, onCompleted, onError }) => {
    const user = auth.currentUser

    user
      .updatePassword(newPassword)
      .then(() => {
        // Update successful.
        onCompleted && onCompleted()
      })
      .catch((e) => {
        // An error ocurred
        onError && onError(e)
      })
  }

  const deleteUserAccount = async ({ onCompleted, onError }) => {
    const user = auth.currentUser
    const storageRef = storageUserProfile(user.uid)
    await deleteFolder(storageRef)

    user
      .delete()
      .then(() => onCompleted && onCompleted())
      .catch((e) => {
        onError && onError(e)
      })
  }

  const sendPasswordReset = ({ email, onCompleted, onError }) => {
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        onCompleted && onCompleted()
      })
      .catch((e) => {
        onError && onError(e)
      })
  }

  const checkCode = ({ code, onCompleted, onError }) => {
    auth
      .verifyPasswordResetCode(code)
      .then((email) => {
        onCompleted && onCompleted(email)
      })
      .catch((e) => {
        onError && onError(e)
      })
  }

  const confirmPasswordReset = ({
    code,
    newPassword,
    onCompleted,
    onError
  }) => {
    auth
      .confirmPasswordReset(code, newPassword)
      .then(() => {
        onCompleted && onCompleted()
      })
      .catch((e) => {
        onError && onError(e)
      })
  }

  return {
    uploadFile,
    deleteFile,
    deleteFolder,
    storageJobsPicturesRef,
    storageChatFiles,
    storageUserProfile,
    updatePassword,
    reauthenticateUser,
    deleteUserAccount,
    sendPasswordReset,
    checkCode,
    confirmPasswordReset
  }
}
