import React from 'react'
import PropTypes from 'prop-types'
import { InputAdornment } from '@mui/material'
import { Input } from 'components'
import { IMaskInput } from 'react-imask'

/// ////////COMPONENT PROPS
AdornmentInput.propTypes = {
  error: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  adornment: PropTypes.element, // example: <div>€</div>
  mask: PropTypes.string, // example: "+34 ### ## ## ##"
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      thousandsSeparator="."
      unmask="typed"
      {...other}
    />
  )
})

function AdornmentInput({ adornment, mask, maskProps, ...other }) {
  return (
    <Input
      InputProps={{
        inputComponent: TextMaskCustom,
        inputProps: { mask: mask || Number, ...maskProps },
        endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
      }}
      {...other}
    />
  )
}

export default React.memo(AdornmentInput)
