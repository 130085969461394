import React, { useContext, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  MultilineInput,
  InfoMessage,
  ScrollableContainer
} from 'components'
import { Container, Grid, CircularProgress } from '@mui/material'
import { useSnackbar } from 'context/SnackBarContext'
import { ADD_CONTACT } from 'apis/mutations'
import { GET_CONTACTS } from 'apis/querys'
import { UserContext } from 'context/UserContext'
import { useLocation, useHistory } from 'react-router-dom'
import { routes } from 'constants/Routes'
import { useI18N } from 'hooks'

export default function DeleteAccount() {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  const history = useHistory()
  const location = useLocation()
  const { fromSettings } = location.state || ''
  const { showSnackbar } = useSnackbar()
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [addContact] = useMutation(ADD_CONTACT, {
    refetchQueries: [GET_CONTACTS]
  })
  const { loading: loadingData, data } = useQuery(GET_CONTACTS, {
    variables: {
      user_id: user.id,
      category: 'delete_account'
    },
    skip: !fromSettings
  })

  useEffect(() => {
    if (!fromSettings) {
      history.replace(routes.account)
    }
  }, [])

  const handleChange = (values) => {
    setMessage(values.message)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)

    if (!message) {
      setLoading(false)
      showSnackbar({
        message: t('toEliminateYouShouldIndicateAReason')
      })
      return
    }

    addContact({
      variables: {
        user_id: user.id,
        message,
        category: 'delete_account'
      }
    })
      .then(() => {
        setSent(true)
        setLoading(false)
      })
      .catch((e) => {
        showSnackbar({
          message: t('somethingWentWrong'),
          severity: 'error'
        })
        setLoading(false)
      })
  }

  return (
    <ScrollableContainer
      title={t('deleteAccount')}
      appBarProps={{ hasBack: true }}
    >
      <Container sx={{ py: 2 }}>
        {loadingData || !data ? (
          <CircularProgress sx={{ mx: 'auto', display: 'block', mt: 3 }} />
        ) : (
          <Grid container spacing={2}>
            {!sent && data.getContacts.length <= 0 ? (
              <>
                <Grid item xs={12}>
                  <MultilineInput
                    id='message'
                    required
                    value={message}
                    onChange={handleChange}
                    placeholder={t('pleaseIndicateReason')}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    text={t('send')}
                    onClick={handleSubmit}
                    disabled={loading}
                    sx={{ ml: 'auto', display: 'block' }}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <InfoMessage text={t('requestSended')} variant='info' />
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </ScrollableContainer>
  )
}
