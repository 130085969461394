import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { styled } from '@mui/styles'
import { TextField } from '@mui/material'
import { StripeInput } from 'components'

const CARD_OPTIONS = {
  iconStyle: 'default',
  style: {
    base: {
      iconColor: '#808080',
      color: '#808080',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#808080',
      },
      '::placeholder': {
        color: '#808080',
      },
    },
    invalid: {
      iconColor: '#F25B5B',
      color: '#F25B5B',
    },
  },
}

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 8,
  },
})

function CardField({ onChange, ...other }) {
  return (
    <StyledInput
      fullWidth
      InputProps={{
        inputComponent: StripeInput,
        inputProps: { component: CardElement, options: CARD_OPTIONS, onChange },
      }}
      {...other}
    />
  )
}

export default CardField
