import React from 'react'
import PropTypes from 'prop-types'
import { Typography, CircularProgress } from '@mui/material'
import { StyledFabButton } from './styles/StyledFAButton'

FAButton.propTypes = {
  variant: PropTypes.oneOf(['default', 'ghost']),
  text: PropTypes.string,
  icon: PropTypes.element,
  ghost: PropTypes.bool,
  loading: PropTypes.bool
}

export default function FAButton({
  text,
  icon,
  ghost,
  loading,
  size,
  disabled,
  ...other
}) {
  return (
    <StyledFabButton
      variant={text ? 'extended' : 'circular'}
      size={size || 'large'}
      ghost={ghost}
      disabled={disabled || loading}
      {...other}
    >
      {loading ? (
        <CircularProgress
          size={20}
          sx={{
            color: 'action.disabled',
            mr: text ? 1 : 0
          }}
        />
      ) : (
        icon
      )}

      {text && (
        <Typography
          fontSize='0.875rem'
          sx={{
            display: 'block',
            textTransform: 'none',
            ml: icon ? 1 : 0
          }}
        >
          {text}
        </Typography>
      )}
    </StyledFabButton>
  )
}
