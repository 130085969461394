import { useLocation } from 'react-router'
import { motion, AnimatePresence } from 'framer-motion'
import { Box } from '@mui/material'
import { routes } from 'constants/Routes'

const bottomBarHeight = 80

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
      opacity: 1
    }
  },
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      x: direction < 0 ? '100%' : '-100%',
      opacity: 1
    }
  }
}

export default function MainContent({ hasBottomBar = [], children }) {
  const location = useLocation()

  const paths = Object.values(routes)
  const index = paths.findIndex((i) => i === location.pathname)
  const fromIndex = location.state?.from
    ? paths.findIndex((i) => i === location.state.from)
    : index

  // console.log({ currentIndex: index, fromIndex, from: location.state?.from })

  const direction = index === fromIndex ? 0 : index < fromIndex ? -1 : 1

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <Box
          component={motion.div}
          key={location.pathname}
          custom={direction}
          variants={variants}
          initial='enter'
          animate='center'
          exit='exit'
          transition={{
            x: { duration: 0.2 },
            opacity: { duration: 0.2 }
          }}
          sx={{
            pb: hasBottomBar.includes(location.pathname)
              ? `${bottomBarHeight}px`
              : 0,
            position: 'absolute',
            overflow: 'hidden',
            inset: 0
          }}
        >
          {children}
        </Box>
      </AnimatePresence>
    </>
  )
}
