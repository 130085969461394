import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_POSTS } from 'apis/querys'
import { useFilters, useI18N, useSearchParams } from 'hooks'
import { Box, Grid } from '@mui/material'
import { JobCard, Button, Modal, DiscoverFilter, SortDialog } from 'components'
import { routes } from 'constants/Routes'
import NoResultsIllustration from 'assets/illustration/no-results'
import ErrorIllustration from 'assets/illustration/error'

const sortOptions = (t) => [
  {
    id: 'newFirst',
    field: 'creation_date',
    label: t('newFirst'),
    order: 'DESC'
  },
  {
    id: 'oldFirts',
    field: 'creation_date',
    label: t('oldFirst'),
    order: 'ASC'
  }
]

export default function PostsList({
  isFilterOpen,
  onFilterClose,
  isSortOpen,
  onSortClose
}) {
  const { t } = useI18N()
  const { search } = useSearchParams('search')
  const { postsFilters: filter } = useFilters()
  const [noMoreResults, setNoMoreResults] = useState(false)
  const [limit, setLimit] = useState(20)
  const [loadingMore, setLoadingMore] = useState(false)

  const variables = (filter, search) => ({
    sortBy: filter.sortBy,
    offset: 0,
    limit,
    category: filter.categories,
    province: filter.province,
    country: filter.country,
    budget: filter.budget,
    title: search,
    description: search
  })

  const { error, data, fetchMore, networkStatus, refetch } = useQuery(
    GET_POSTS,
    {
      fetchPolicy: 'cache-and-network',
      // fetchPolicy: 'network-only',
      // nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: variables(filter, search)
    }
  )

  const posts = data?.getJobs ?? []

  const onLoadMore = () => {
    if (!noMoreResults) {
      setLoadingMore(true)
      const currentLength = posts.length
      if (currentLength > 0) {
        fetchMore({
          variables: {
            offset: currentLength,
            limit: 20
          }
        }).then((fetchMoreResult) => {
          const newPostsLength = fetchMoreResult.data?.getJobs.length

          if (newPostsLength === 0) {
            setNoMoreResults(true)
          }
          setLoadingMore(false)
          setLimit(currentLength + newPostsLength)
        })
      }
    }
  }

  const handleFilterClose = () => {
    onFilterClose()
  }

  const handleSortClose = () => {
    onSortClose()
  }

  const handleFilterSave = (newValues) => {
    const newVariables = variables(newValues, search)
    refetch({ ...newVariables })
    onFilterClose()
  }

  const handleSortSave = (newValues) => {
    const newVariables = variables(newValues, search)
    refetch({ ...newVariables })
    onSortClose()
  }

  const loading = networkStatus === 1

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          p: 2
        }}
      >
        <Box height={240} width={240}>
          <ErrorIllustration />
        </Box>
        <h2>{t('errorLoadingData')}</h2>
      </Box>
    )
  }

  if (loading) {
    return (
      <Grid container spacing={2}>
        {[...Array(3).keys()].map((i) => (
          <Grid key={i} item xs={12}>
            <JobCard loading />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      {posts?.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            p: 2
          }}
        >
          <Box height={240} width={240}>
            <NoResultsIllustration />
          </Box>
          <h2>{t('noResultsWasFound')}</h2>
        </Box>
      )}

      {posts?.length > 0 && (
        <Grid container spacing={2}>
          {posts.map((post, index) => (
            <Grid key={index} item xs={12}>
              <JobCard
                userId={post.user_id}
                title={post.title}
                city={post.city}
                startDate={post.start_date}
                budget={post.budget}
                postDate={post.post_date}
                href={`${routes.post}?id=${post._id}`}
              />
            </Grid>
          ))}
          {!loading && !noMoreResults && posts.length >= limit && (
            <Button
              onClick={onLoadMore}
              variant='loadMore'
              loading={loadingMore}
              text={t('loadMore')}
              sx={{ mx: 'auto', mt: 2 }}
            />
          )}
        </Grid>
      )}
      {/* Filter Drawer */}
      <Modal aria-label='filter-drawer' anchor='bottom' open={isFilterOpen}>
        <DiscoverFilter onClose={handleFilterClose} onSave={handleFilterSave} />
      </Modal>

      {/* Sort Drawer */}
      <Modal
        aria-label='sort-drawer'
        anchor='bottom'
        open={isSortOpen}
        onClose={handleSortClose}
      >
        <SortDialog
          onClose={handleSortClose}
          onSave={handleSortSave}
          sortOptions={sortOptions(t)}
        />
      </Modal>
    </>
  )
}
