import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import createApolloClient from './apis/apollo'
import { UserProvider } from './context/UserContext'
import { ServiceWorkerProvider } from 'context/ServiceWorkerContext'
import { I18NProvider } from 'context/i18n'

const MainElement = () => {
  const client = createApolloClient()
  return (
    <ApolloProvider client={client}>
      <ServiceWorkerProvider>
        <I18NProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </I18NProvider>
      </ServiceWorkerProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(
  // <React.StrictMode>
  <MainElement />,
  // </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
