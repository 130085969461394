import texts from 'constants/texts'
import { routes, slugs } from 'constants/Routes'
import { useHistory } from 'react-router'
import {
  Card,
  InfoCircle,
  ExportCircle,
  Personalcard,
  Sms,
  Star1
} from 'iconsax-react'
import { useI18N } from 'hooks'

const sharingData = {
  url: slugs.app.link,
  text: texts().shareTheAppMessage,
  title: 'Jobsaun App'
}

const shareTheApp = () => navigator.share(sharingData)
const contact = () => window.open('mailto:info@jobsaun.com')

const menuItem = (id, title, icon, action) => {
  return { id, title, icon, action }
}

export function ProfileMenu() {
  const { t } = useI18N()
  const history = useHistory()

  const goTo = (to) => () => history.push(to)

  const userMenu = [
    menuItem(
      1,
      t('details'),
      <Personalcard size='24' variant='Outline' />,
      goTo(routes.accountDetails)
    ),
    // menuItem(2, t('ratings'), <StarBorderRoundedIcon />, goTo(slugs.ratings.link)),
    // menuItem(2, t('privacy'), <LockOutlinedIcon />, goTo(slugs.privacy.link)),
    menuItem(
      2,
      t('info'),
      <InfoCircle size='24' variant='Outline' />,
      goTo(routes.info)
    ),
    menuItem(
      3,
      t('shareTheApp'),
      <ExportCircle size='24' variant='Outline' />,
      shareTheApp
    ),
    menuItem(4, t('contact'), <Sms size='24' variant='Outline' />, contact)
  ]

  const companyMenu = [
    menuItem(
      1,
      t('details'),
      <Personalcard size='24' variant='Outline' />,
      goTo(routes.accountDetails)
    ),
    menuItem(
      2,
      t('ratings'),
      <Star1 size='24' variant='Outline' />,
      goTo(routes.ratings)
    ),
    menuItem(
      3,
      t('subscription'),
      <Card size='24' variant='Outline' />,
      goTo(routes.subscription)
    ),
    menuItem(
      4,
      t('info'),
      <InfoCircle size='24' variant='Outline' />,
      goTo(routes.info)
    ),
    menuItem(
      5,
      t('shareTheApp'),
      <ExportCircle size='24' variant='Outline' />,
      shareTheApp
    ),
    menuItem(6, t('contact'), <Sms size='24' variant='Outline' />, contact)
  ]

  return {
    userMenu,
    companyMenu
  }
}
