import { useEffect } from 'react'
import { ButtonBase, useScrollTrigger, Zoom } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { ArrowDown2 } from 'iconsax-react'

export default function ScrollBottom({ target }) {
  const trigger = useScrollTrigger({
    target: target || undefined,
    disableHysteresis: true,
    threshold: target.scrollHeight - 1200
  })

  useEffect(() => {
    startAtBottom()
  }, [])

  const goBottom = (e) => {
    e.preventDefault()
    scrollDown()
  }

  const scrollDown = () => {
    target.scrollTo({
      behavior: 'smooth',
      top: target.scrollHeight
    })
  }

  const startAtBottom = () => {
    target.scrollTo({
      top: target.scrollHeight
    })
  }

  return (
    <Zoom in={!trigger}>
      <ButtonBase
        onClick={goBottom}
        sx={{
          transition: 'all 0.3s ease-in-out',
          position: 'absolute',
          bottom: 80,
          right: 16,
          bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
          borderRadius: '50%',
          boxShadow: '0 1px 6px rgba(0,0,0,0.15)',
          p: 1
        }}
      >
        <ArrowDown2 />
      </ButtonBase>
    </Zoom>
  )
}
