import { Box, Typography } from '@mui/material'
import { Button, Dialog, Input, Modal } from 'components'
import { motion } from 'framer-motion'
import { useI18N } from 'hooks'

const ResetPasswordDialog = ({
  open,
  onChange,
  processing,
  sent,
  value,
  onClose,
  onSend
}) => {
  const { t } = useI18N()
  return (
    <Modal open={open} onClose={onClose}>
      <Dialog>
        <Dialog.Title title={t('resetPasswordTitle')} />
        <Dialog.Content>
          {!sent ? (
            <>
              <Typography fontSize='1rem' color='textSecondary' sx={{ mb: 2 }}>
                {t('aEmailWillBeSend')}
              </Typography>
              <Input
                id='email'
                type='email'
                value={value}
                onChange={onChange}
                disabled={processing}
              />
            </>
          ) : (
            <>
              <Box
                component={motion.div}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.2,
                  ease: [0, 0.71, 0.2, 1.01]
                }}
              >
                <Typography fontSize='4rem' align='center'>
                  📮
                </Typography>
              </Box>
              <Typography fontSize='1rem' color='textSecondary'>
                {t('theEmailHasBeenSent')}
              </Typography>
            </>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            fullWidth
            variant='light'
            text={sent ? t('close') : t('cancel')}
            onClick={onClose}
          />
          {!sent && <Button fullWidth text={t('send')} onClick={onSend} />}
        </Dialog.Actions>
      </Dialog>
    </Modal>
  )
}

export default ResetPasswordDialog
