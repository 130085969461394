import google from 'assets/about/google.svg'
import facebook from 'assets/about/facebook.svg'
import instagram from 'assets/about/instagram.svg'
import youtube from 'assets/about/youtube.svg'
import friend from 'assets/about/friend.svg'
import newspaper from 'assets/about/newspaper.svg'
import blog from 'assets/about/blog.svg'
import texts from './texts'

export const hear = [
  {
    id: 'google',
    name: '',
    icon: google,
    color: '#fff'
  },
  {
    id: 'facebook',
    name: '',
    icon: facebook,
    color: '#1877f2'
  },
  {
    id: 'instagram',
    name: '',
    icon: instagram,
    color:
      'radial-gradient(farthest-side at 0% 100%, rgb(250, 143, 33) 0%, rgb(216, 45, 126) 50.00%, rgb(140, 58, 170) 100%)'
  },
  {
    id: 'youtube',
    name: '',
    icon: youtube,
    color: '#f00'
  },
  {
    id: 'friend',
    name: texts().friend,
    icon: friend,
    color: '#10b981'
  },
  {
    id: 'digital-newspaper',
    name: texts().digitalPaper,
    icon: newspaper,
    color: '#7dd3fc'
  },
  {
    id: 'blog',
    name: 'Blog',
    icon: blog,
    color: '#fb923c'
  },
  {
    id: 'other',
    name: texts().other,
    icon: '',
    color: '#94a3b8'
  }
]
