import React, { useContext } from 'react'
import { InboxChat } from 'components'
import { getDate } from 'helpers'
import { UserContext } from 'context/UserContext'
import { useI18N } from 'hooks'

export default function LastMessage({ chat, offerData, jobData }) {
  const { t } = useI18N()

  const { user } = useContext(UserContext)
  const chatId = chat.chat_id
  const lastMessage = chat.last_message

  const messageContent = () => {
    if (offerData.state === 'proposal' && user.role === 'company') {
      return t('thinksThatYouCanBeInterested')
    } else if (offerData.state === 'negotiating') {
      return lastMessage.from !== user.id && user.role !== 'company'
        ? lastMessage.content.value
        : t('theUserIsStudingYourOffer')
    } else if (offerData.state === 'rejected') {
      return lastMessage.from !== user.id && user.role !== 'company'
        ? lastMessage.content.value
        : t('yourOfferHasBeenRejected')
    } else {
      return lastMessage.content.type === 'text'
        ? lastMessage.content.value
        : lastMessage.content.name
    }
  }

  const receptorId = chat.participants.find(
    (participant) => participant !== user.id
  )

  return (
    <InboxChat
      fromCurrentUser={lastMessage.from === user.id}
      unread={lastMessage.from !== user.id && lastMessage.state === 'sent'}
      hasAvatar
      receptorId={receptorId}
      date={getDate(lastMessage.date, false, 'd MMM')}
      content={messageContent()}
      chatId={chatId}
      jobData={jobData}
      offerData={offerData}
    />
  )
}
