import React, { useContext, useEffect } from 'react'
import { UserContext } from 'context/UserContext'
import { Divider, Box, Chip, Typography } from '@mui/material'
import { ChatBubble, InfoMessage } from 'components'
import { getDate, getRandomId } from 'helpers'
import { useI18N } from 'hooks'

function MessagesFeed({
  messages,
  offerState,
  receptorName,
  subscribeToNewMessages
}) {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  useEffect(() => {
    subscribeToNewMessages()
  }, [])

  if (messages.length < 1) {
    if (user.role === 'company') {
      switch (offerState) {
        case 'negotiating':
          return (
            <InfoMessage
              receptorName={receptorName}
              text={t('theUserIsStudingYourOffer')}
              variant='info'
            />
          )
        case 'accepted':
          return (
            <InfoMessage
              receptorName={receptorName}
              text={t('theUserHasAccepted')}
              variant='info'
            />
          )
        case 'rejected':
          return (
            <InfoMessage
              receptorName={receptorName}
              text={t('yourOfferHasBeenRejected')}
            />
          )
        default:
          return null
      }
    }
    if (user.role === 'user') {
      switch (offerState) {
        case 'negotiating':
          return (
            <InfoMessage
              text={t('theOfferNeedToBeAccepted').map((par) => (
                <Typography
                  key={getRandomId(12)}
                  component='span'
                  sx={{ py: 0.5, display: 'block' }}
                >
                  {par} <br />
                </Typography>
              ))}
              variant='info'
            />
          )
        default:
          return null
      }
    }
  }

  return (
    <>
      {messages.map(({ _id, content, from, date, state }, index) => {
        const time = getDate(date, false, 'HH:mm')
        const day = getDate(date, false, 'd MMM')

        const a = index !== 0 ? messages[index - 1].date : messages[index].date
        const previewMessageDay = getDate(a)
        const currentDay = getDate(date)

        const mContent = content.value
        const mType = content.type
        const fileName = content.name

        return (
          <Box aria-label='message-container' key={_id} pt={1}>
            {(previewMessageDay !== currentDay || index === 0) && (
              <Divider sx={{ mb: 2 }}>
                <Chip label={day} disabled size='small' />
              </Divider>
            )}
            <ChatBubble
              from={from}
              user={user.id}
              content={mContent}
              type={mType}
              time={time}
              state={state}
              fileName={fileName}
            />
          </Box>
        )
      })}
    </>
  )
}

export default React.memo(MessagesFeed)
