import React from 'react'
import { Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useI18N } from 'hooks'

function DateInput({ id, value, onChange, sx, error, ...other }) {
  const { locale } = useI18N()
  const handleChange = (value) => {
    const parsedValue = dayjs(value).toISOString()
    onChange({ [id]: parsedValue })
  }

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={locale ?? 'en'}
      >
        <DatePicker
          id={id}
          value={dayjs(value)}
          onChange={handleChange}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': {
              shrink: true,
              width: '100%',
              borderRadius: 2
            },
            ...(error && {
              '& .MuiFormLabel-root': {
                color: 'error.main'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'error.main'
              }
            }),
            ...sx
          }}
          {...other}
        />
      </LocalizationProvider>
      {error && (
        <Typography fontSize={12} color='error' ml={1}>
          {error}
        </Typography>
      )}
    </>
  )
}

export default React.memo(DateInput)
