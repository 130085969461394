import { styled } from '@mui/system'
import { Fab } from '@mui/material'
import { alpha } from '@mui/material/styles'

export const StyledFabButton = styled(Fab, {
  shouldForwardProp: (prop) =>
    prop !== 'round' &&
    prop !== 'shadow' &&
    prop !== 'ghost' &&
    prop !== 'borderColor'
})(({ theme, ghost, borderColor }) => ({
  transition: 'all 0.2s ease-in-out',
  minWidth: 'fit-content',
  boxShadow: `0px 2px 8px ${alpha(
    theme.palette.primary.main,
    theme.palette.mode === 'light' ? 0.5 : 0.2
  )}`,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.light
  },
  '&:active': {
    boxShadow: alpha(theme.palette.primary.main, 0.7)
  },
  '&.Mui-disabled': {
    '&:active': {
      backgroundColor: theme.palette.action.disabledBackground
    }
  },
  ...(ghost && {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: borderColor || theme.palette.primary.main,
    border: `2px solid ${borderColor || theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&.Mui-disabled': {
      border: `2px solid ${theme.palette.action.disabled}`,
      backgroundColor: 'transparent'
    }
  })
}))
