import { Box, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { TickSquare } from 'iconsax-react'

export default function CustomChip({ label, selected, onClick, sx, ...other }) {
  return (
    <Box
      role='button'
      sx={{
        transition: 'all 0.2s ease-in-out',
        borderRadius: 2,
        display: 'flex',
        p: 1,
        border: (theme) =>
          selected
            ? 'solid 1px transparent'
            : `solid 1px ${theme.palette.action.focus}`,
        alignItems: 'center',
        bgcolor: (theme) =>
          selected ? alpha(theme.palette.primary.main, 0.2) : 'transparent',
        cursor: onClick ? 'pointer' : 'default',
        ...sx
      }}
      onClick={onClick}
      {...other}
    >
      <Box
        component='span'
        sx={{
          transition: 'all 0.2s ease-in-out, color 0s ease',
          display: 'block',
          height: selected ? 20 : 0,
          minWidth: selected ? 20 : 0,
          mr: selected ? 1 : 0,
          transform: selected ? 'scale(1)' : 'scale(0)'
        }}
      >
        <TickSquare size='100%' />
      </Box>
      <Typography>{label}</Typography>
    </Box>
  )
}
