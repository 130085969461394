import React from 'react'
import PropTypes from 'prop-types'
import { FormLabel, FormControl, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@mui/material'

/// ////////COMPONENT PROPS
RadioGroup.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array,
}

export default function RadioGroup({ label, id, value, onChange, items, disabled }) {
  const handleChange = (event) => {
    const { name, value } = event.target
    onChange({ [name]: value })
  }

  return (
    <FormControl disabled={disabled} sx={{ width: '100%' }}>
      <FormLabel focused={false}>{label}</FormLabel>
      <MuiRadioGroup
        aria-label={`radio-group-${id}`}
        id={id}
        name={id}
        value={value}
        onChange={handleChange}
        row
      >
        {items.map(({ value: itemValue, label }) => (
          <FormControlLabel
            disabled={disabled}
            key={label}
            value={itemValue}
            control={<Radio color="primary" disabled={disabled} />}
            label={label}
            sx={{
              '& .MuiTypography-root': {
                color: (theme) => theme.palette.text.secondary,
              },
            }}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  )
}
