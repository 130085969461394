import { useContext } from 'react'
import { RatingsGroup, Spacer, ScrollableContainer } from 'components'
import { UserContext } from 'context/UserContext'
import { useI18N } from 'hooks'

export default function Ratings() {
  const { t } = useI18N()
  const { user } = useContext(UserContext)

  return (
    <ScrollableContainer title={t('ratings')} appBarProps={{ hasBack: true }}>
      <Spacer m={2}>
        <RatingsGroup companyId={user.id} />
      </Spacer>
    </ScrollableContainer>
  )
}
