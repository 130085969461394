import { Box, IconButton } from '@mui/material'
import { CloseCircle } from 'iconsax-react'

export default function BottomDrawer({
  title,
  closeButton,
  actions,
  onClose,
  children
}) {
  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Box
      aria-label='drawer'
      sx={{
        position: 'relative',
        width: '100%',
        borderRadius: '16px 16px 0px 0px',
        bgcolor: 'background.paper',
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {(title || closeButton) && (
        <Box
          aria-label='drawer-header-container'
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            p: 2
          }}
        >
          <Box
            aria-label='title-container'
            sx={{ width: '100%', height: '100%' }}
          >
            {title}
          </Box>
          {closeButton && (
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 10,
                right: 8,
                color: 'text.primary'
              }}
            >
              <CloseCircle variant='Bold' size={24} />
            </IconButton>
          )}
        </Box>
      )}
      <Box
        aria-label='drawer-content-container'
        className='scrollable'
        sx={{ flex: 1, p: 2 }}
      >
        {children}
      </Box>
      {actions && (
        <Box
          aria-label='drawer-buttons-container'
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            gap: 2,
            p: 2
          }}
        >
          {actions}
        </Box>
      )}
    </Box>
  )
}
