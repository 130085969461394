function precise(x) {
  return Number.parseFloat(x).toPrecision(2)
}

export default function getAverages(ratings) {
  let averagesList = [0, 0, 0, 0]
  const count = ratings?.length || 0
  let communication = 0
  let onTime = 0
  let asDescribed = 0
  let recommendation = 0

  if (count > 0) {
    const listOfRatings = ratings.map((item) => item.ratings)
    const sum = (l, i) => l.map((item, index) => i[index] + item)
    averagesList = listOfRatings.reduce(sum).map((item) => item / count)
  }

  communication = averagesList[0]
  onTime = averagesList[1]
  asDescribed = averagesList[2]
  recommendation = averagesList[3]

  const calc = (communication + onTime + asDescribed + recommendation) / 4
  const average = parseFloat(precise(calc))

  return {
    communication,
    onTime,
    asDescribed,
    recommendation,
    average,
    count
  }
}
