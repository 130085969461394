import { createContext, useCallback } from 'react'
import es from 'languages/es'
import en from 'languages/en'
import de from 'languages/de'
import fr from 'languages/fr'
import it from 'languages/it'

export const I18NContext = createContext(null)

const languages = { es, en, de, fr, it }

export function I18NProvider({ children }) {
  const currentLanguage = navigator.language.split('-')[0]
  const locale = ['es', 'en', 'de', 'fr', 'it'].includes(currentLanguage)
    ? currentLanguage
    : 'en'

  const t = useCallback(
    (key, ...args) => {
      const translation = languages[locale](...args)[key]
      return translation
    },
    [locale]
  )

  return (
    <I18NContext.Provider value={{ t, locale }}>
      {children}
    </I18NContext.Provider>
  )
}
