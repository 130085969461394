import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const publicKey =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
    : process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY

const stripePromise = loadStripe(publicKey)

function StripeElements({ children }) {
  return <Elements stripe={stripePromise}>{children}</Elements>
}

export default StripeElements
