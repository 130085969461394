import { useState } from 'react'
import { Filter, ArrowSwapVertical, Notepad2, Shop } from 'iconsax-react'
import { useHistory } from 'react-router-dom'
import { useI18N, useSearchParams, useSettings } from 'hooks'
import { Box, IconButton } from '@mui/material'
import {
  IconsSwitch,
  PostsList,
  CompaniesList,
  ScrollableContainer,
  SearchBar,
  Spacer
} from 'components'
import { AnimatePresence, motion } from 'framer-motion'

const dicoverSwitchOptions = (t) => [
  {
    id: 'companies',
    icon: <Shop />,
    label: t('companies')
  },
  {
    id: 'posts',
    icon: <Notepad2 />,
    label: t('posts')
  }
]

export default function Discover() {
  const { t } = useI18N()
  const { search } = useSearchParams('search')
  const {
    settings: { discover },
    updateSettings
  } = useSettings()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isSortOpen, setIsSortOpen] = useState(false)
  const history = useHistory()

  const handleFilterState = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const handleSortState = () => {
    setIsSortOpen(!isSortOpen)
  }

  const handleSearch = (search) => {
    const pathname = history.location.pathname
    if (search.length > 0) {
      history.push(`${pathname}?search=${search}`)
    } else {
      history.push(pathname)
    }
  }

  const handleChange = (newValue) => {
    updateSettings({ discover: newValue })
  }

  return (
    <>
      <ScrollableContainer
        toolBarChildren={
          <>
            <SearchBar
              onEnter={handleSearch}
              placeholder={t('search')}
              defaultValue={search}
              onClean={() => handleSearch('')}
              endActions={
                <>
                  <IconButton
                    onClick={handleSortState}
                    aria-label='sort'
                    size='medium'
                    sx={{ color: 'text.primary' }}
                  >
                    <ArrowSwapVertical size={24} />
                  </IconButton>
                  <IconButton
                    onClick={handleFilterState}
                    aria-label='filter'
                    size='medium'
                    sx={{ color: 'text.primary' }}
                  >
                    <Filter size={24} />
                  </IconButton>
                </>
              }
            />

            <IconsSwitch
              items={dicoverSwitchOptions(t)}
              defaultSelected={discover}
              onChange={handleChange}
            />
          </>
        }
      >
        <Spacer x={2} pb={2}>
          <AnimatePresence initial={false}>
            {discover === 'companies' && (
              <AnimatedContainer key='companies'>
                <CompaniesList
                  isFilterOpen={isFilterOpen}
                  onFilterClose={handleFilterState}
                  isSortOpen={isSortOpen}
                  onSortClose={handleSortState}
                />
              </AnimatedContainer>
            )}
            {discover === 'posts' && (
              <AnimatedContainer key='posts'>
                <PostsList
                  isFilterOpen={isFilterOpen}
                  onFilterClose={handleFilterState}
                  isSortOpen={isSortOpen}
                  onSortClose={handleSortState}
                />
              </AnimatedContainer>
            )}
          </AnimatePresence>
        </Spacer>
      </ScrollableContainer>
    </>
  )
}

const variants = {
  hidden: {
    opacity: 0,
    scale: 0.5
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      delay: 0.2,
      ease: [0, 0.71, 0.2, 1.01]
    }
  }
}

const AnimatedContainer = ({ children, ...other }) => (
  <Box
    as={motion.div}
    variants={variants}
    initial='hidden'
    animate='show'
    {...other}
  >
    {children}
  </Box>
)
