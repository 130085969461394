const profileImages = require.context('assets/imgs/profiles', true)
const bgImages = require.context('assets/imgs/profiles/bg', true)

const profileBgConstructor = (id, value) => ({ id, value })
const profileConstructor = (id, skin, color, value) => ({
  id,
  skin,
  color,
  value,
})

export const getRandomBg = () => {
  const bgs = ['01', '02', '03', '04', '05']
  const index = Math.floor(Math.random() * bgs.length)
  return bgs[index]
}

export const profilePicturesBg = [
  profileBgConstructor('01', bgImages('./g01.png')),
  profileBgConstructor('02', bgImages('./g02.png')),
  profileBgConstructor('03', bgImages('./g03.png')),
  profileBgConstructor('04', bgImages('./g04.png')),
  profileBgConstructor('05', bgImages('./g05.png')),
]

export const colors = [
  profileBgConstructor('black', '#292F33'),
  profileBgConstructor('brown', '#963B22'),
  profileBgConstructor('orange', '#E95F28'),
  profileBgConstructor('blond', '#FFAC33'),
  profileBgConstructor('grey', '#E1E8ED'),
]

export const skinColors = [
  profileBgConstructor(1, '#F7DECE'),
  profileBgConstructor(2, '#D5AB88'),
  profileBgConstructor(3, '#7C533E'),
]

export const profilePictures = [
  // Color Black - Skin 1
  profileConstructor('c01s01t01', 1, 'black', profileImages('./Emoji_color01_Skin01_Type01.png')),
  profileConstructor('c01s01t02', 1, 'black', profileImages('./Emoji_color01_Skin01_Type02.png')),
  profileConstructor('c01s01t03', 1, 'black', profileImages('./Emoji_color01_Skin01_Type03.png')),
  profileConstructor('c01s01t04', 1, 'black', profileImages('./Emoji_color01_Skin01_Type04.png')),
  profileConstructor('c01s01t05', 1, 'black', profileImages('./Emoji_color01_Skin01_Type05.png')),
  profileConstructor('c01s01t06', 1, 'black', profileImages('./Emoji_color01_Skin01_Type06.png')),
  profileConstructor('c01s01t07', 1, 'black', profileImages('./Emoji_color01_Skin01_Type07.png')),
  profileConstructor('c01s01t08', 1, 'black', profileImages('./Emoji_color01_Skin01_Type08.png')),
  profileConstructor('c01s01t09', 1, 'black', profileImages('./Emoji_color01_Skin01_Type09.png')),
  profileConstructor('c01s01t10', 1, 'black', profileImages('./Emoji_color01_Skin01_Type10.png')),
  profileConstructor('c01s01t11', 1, 'black', profileImages('./Emoji_color01_Skin01_Type11.png')),
  // Color Black - Skin 2
  profileConstructor('c01s02t01', 2, 'black', profileImages('./Emoji_color01_Skin02_Type01.png')),
  profileConstructor('c01s02t02', 2, 'black', profileImages('./Emoji_color01_Skin02_Type02.png')),
  profileConstructor('c01s02t03', 2, 'black', profileImages('./Emoji_color01_Skin02_Type03.png')),
  profileConstructor('c01s02t04', 2, 'black', profileImages('./Emoji_color01_Skin02_Type04.png')),
  profileConstructor('c01s02t05', 2, 'black', profileImages('./Emoji_color01_Skin02_Type05.png')),
  profileConstructor('c01s02t06', 2, 'black', profileImages('./Emoji_color01_Skin02_Type06.png')),
  profileConstructor('c01s02t07', 2, 'black', profileImages('./Emoji_color01_Skin02_Type07.png')),
  profileConstructor('c01s02t08', 2, 'black', profileImages('./Emoji_color01_Skin02_Type08.png')),
  profileConstructor('c01s02t09', 2, 'black', profileImages('./Emoji_color01_Skin02_Type09.png')),
  profileConstructor('c01s02t10', 2, 'black', profileImages('./Emoji_color01_Skin02_Type10.png')),
  profileConstructor('c01s02t11', 2, 'black', profileImages('./Emoji_color01_Skin02_Type11.png')),
  // Color Black - Skin 3
  profileConstructor('c01s03t01', 3, 'black', profileImages('./Emoji_color01_Skin03_Type01.png')),
  profileConstructor('c01s03t02', 3, 'black', profileImages('./Emoji_color01_Skin03_Type02.png')),
  profileConstructor('c01s03t03', 3, 'black', profileImages('./Emoji_color01_Skin03_Type03.png')),
  profileConstructor('c01s03t04', 3, 'black', profileImages('./Emoji_color01_Skin03_Type04.png')),
  profileConstructor('c01s03t05', 3, 'black', profileImages('./Emoji_color01_Skin03_Type05.png')),
  profileConstructor('c01s03t06', 3, 'black', profileImages('./Emoji_color01_Skin03_Type06.png')),
  profileConstructor('c01s03t07', 3, 'black', profileImages('./Emoji_color01_Skin03_Type07.png')),
  profileConstructor('c01s03t08', 3, 'black', profileImages('./Emoji_color01_Skin03_Type08.png')),
  profileConstructor('c01s03t09', 3, 'black', profileImages('./Emoji_color01_Skin03_Type09.png')),
  profileConstructor('c01s03t10', 3, 'black', profileImages('./Emoji_color01_Skin03_Type10.png')),
  profileConstructor('c01s03t11', 3, 'black', profileImages('./Emoji_color01_Skin03_Type11.png')),
  //* ********/
  // Color Brown - Skin 1
  profileConstructor('c02s01t01', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type01.png')),
  profileConstructor('c02s01t02', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type02.png')),
  profileConstructor('c02s01t03', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type03.png')),
  profileConstructor('c02s01t04', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type04.png')),
  profileConstructor('c02s01t05', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type05.png')),
  profileConstructor('c02s01t06', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type06.png')),
  profileConstructor('c02s01t07', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type07.png')),
  profileConstructor('c02s01t08', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type08.png')),
  profileConstructor('c02s01t09', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type09.png')),
  profileConstructor('c02s01t10', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type10.png')),
  profileConstructor('c02s01t11', 1, 'brown', profileImages('./Emoji_color02_Skin01_Type11.png')),
  // Color Brown - Skin 2
  profileConstructor('c02s02t01', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type01.png')),
  profileConstructor('c02s02t02', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type02.png')),
  profileConstructor('c02s02t03', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type03.png')),
  profileConstructor('c02s02t04', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type04.png')),
  profileConstructor('c02s02t05', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type05.png')),
  profileConstructor('c02s02t06', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type06.png')),
  profileConstructor('c02s02t07', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type07.png')),
  profileConstructor('c02s02t08', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type08.png')),
  profileConstructor('c02s02t09', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type09.png')),
  profileConstructor('c02s02t10', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type10.png')),
  profileConstructor('c02s02t11', 2, 'brown', profileImages('./Emoji_color02_Skin02_Type11.png')),
  // Color Brown - Skin 3
  profileConstructor('c02s03t01', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type01.png')),
  profileConstructor('c02s03t02', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type02.png')),
  profileConstructor('c02s03t03', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type03.png')),
  profileConstructor('c02s03t04', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type04.png')),
  profileConstructor('c02s03t05', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type05.png')),
  profileConstructor('c02s03t06', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type06.png')),
  profileConstructor('c02s03t07', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type07.png')),
  profileConstructor('c02s03t08', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type08.png')),
  profileConstructor('c02s03t09', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type09.png')),
  profileConstructor('c02s03t10', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type10.png')),
  profileConstructor('c02s03t11', 3, 'brown', profileImages('./Emoji_color02_Skin03_Type11.png')),
  //* ********/
  // Color Orange - Skin 1
  profileConstructor('c03s01t01', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type01.png')),
  profileConstructor('c03s01t02', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type02.png')),
  profileConstructor('c03s01t03', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type03.png')),
  profileConstructor('c03s01t04', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type04.png')),
  profileConstructor('c03s01t05', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type05.png')),
  profileConstructor('c03s01t06', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type06.png')),
  profileConstructor('c03s01t07', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type07.png')),
  profileConstructor('c03s01t08', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type08.png')),
  profileConstructor('c03s01t09', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type09.png')),
  profileConstructor('c03s01t10', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type10.png')),
  profileConstructor('c03s01t11', 1, 'orange', profileImages('./Emoji_color03_Skin01_Type11.png')),
  // Color Orange - Skin 2
  profileConstructor('c03s02t01', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type01.png')),
  profileConstructor('c03s02t02', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type02.png')),
  profileConstructor('c03s02t03', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type03.png')),
  profileConstructor('c03s02t04', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type04.png')),
  profileConstructor('c03s02t05', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type05.png')),
  profileConstructor('c03s02t06', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type06.png')),
  profileConstructor('c03s02t07', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type07.png')),
  profileConstructor('c03s02t08', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type08.png')),
  profileConstructor('c03s02t09', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type09.png')),
  profileConstructor('c03s02t10', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type10.png')),
  profileConstructor('c03s02t11', 2, 'orange', profileImages('./Emoji_color03_Skin02_Type11.png')),
  // Color Orange - Skin 3
  profileConstructor('c03s03t01', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type01.png')),
  profileConstructor('c03s03t02', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type02.png')),
  profileConstructor('c03s03t03', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type03.png')),
  profileConstructor('c03s03t04', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type04.png')),
  profileConstructor('c03s03t05', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type05.png')),
  profileConstructor('c03s03t06', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type06.png')),
  profileConstructor('c03s03t07', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type07.png')),
  profileConstructor('c03s03t08', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type08.png')),
  profileConstructor('c03s03t09', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type09.png')),
  profileConstructor('c03s03t10', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type10.png')),
  profileConstructor('c03s03t11', 3, 'orange', profileImages('./Emoji_color03_Skin03_Type11.png')),
  //* ********/
  // Color Blond - Skin 1
  profileConstructor('c04s01t01', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type01.png')),
  profileConstructor('c04s01t02', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type02.png')),
  profileConstructor('c04s01t03', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type03.png')),
  profileConstructor('c04s01t04', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type04.png')),
  profileConstructor('c04s01t05', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type05.png')),
  profileConstructor('c04s01t06', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type06.png')),
  profileConstructor('c04s01t07', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type07.png')),
  profileConstructor('c04s01t08', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type08.png')),
  profileConstructor('c04s01t09', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type09.png')),
  profileConstructor('c04s01t10', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type10.png')),
  profileConstructor('c04s01t11', 1, 'blond', profileImages('./Emoji_color04_Skin01_Type11.png')),
  // Color Blond - Skin 2
  profileConstructor('c04s02t01', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type01.png')),
  profileConstructor('c04s02t02', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type02.png')),
  profileConstructor('c04s02t03', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type03.png')),
  profileConstructor('c04s02t04', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type04.png')),
  profileConstructor('c04s02t05', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type05.png')),
  profileConstructor('c04s02t06', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type06.png')),
  profileConstructor('c04s02t07', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type07.png')),
  profileConstructor('c04s02t08', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type08.png')),
  profileConstructor('c04s02t09', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type09.png')),
  profileConstructor('c04s02t10', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type10.png')),
  profileConstructor('c04s02t11', 2, 'blond', profileImages('./Emoji_color04_Skin02_Type11.png')),
  // Color Blond - Skin 3
  profileConstructor('c04s03t01', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type01.png')),
  profileConstructor('c04s03t02', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type02.png')),
  profileConstructor('c04s03t03', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type03.png')),
  profileConstructor('c04s03t04', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type04.png')),
  profileConstructor('c04s03t05', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type05.png')),
  profileConstructor('c04s03t06', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type06.png')),
  profileConstructor('c04s03t07', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type07.png')),
  profileConstructor('c04s03t08', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type08.png')),
  profileConstructor('c04s03t09', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type09.png')),
  profileConstructor('c04s03t10', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type10.png')),
  profileConstructor('c04s03t11', 3, 'blond', profileImages('./Emoji_color04_Skin03_Type11.png')),
  //* ********/
  // Color Grey - Skin 1
  profileConstructor('c05s01t01', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type01.png')),
  profileConstructor('c05s01t02', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type02.png')),
  profileConstructor('c05s01t03', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type03.png')),
  profileConstructor('c05s01t04', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type04.png')),
  profileConstructor('c05s01t05', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type05.png')),
  profileConstructor('c05s01t06', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type06.png')),
  profileConstructor('c05s01t07', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type07.png')),
  profileConstructor('c05s01t08', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type08.png')),
  profileConstructor('c05s01t09', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type09.png')),
  profileConstructor('c05s01t10', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type10.png')),
  profileConstructor('c05s01t11', 1, 'grey', profileImages('./Emoji_color05_Skin01_Type11.png')),
  // Color Grey - Skin 2
  profileConstructor('c05s02t01', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type01.png')),
  profileConstructor('c05s02t02', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type02.png')),
  profileConstructor('c05s02t03', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type03.png')),
  profileConstructor('c05s02t04', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type04.png')),
  profileConstructor('c05s02t05', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type05.png')),
  profileConstructor('c05s02t06', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type06.png')),
  profileConstructor('c05s02t07', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type07.png')),
  profileConstructor('c05s02t08', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type08.png')),
  profileConstructor('c05s02t09', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type09.png')),
  profileConstructor('c05s02t10', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type10.png')),
  profileConstructor('c05s02t11', 2, 'grey', profileImages('./Emoji_color05_Skin02_Type11.png')),
  // Color Grey - Skin 3
  profileConstructor('c05s03t01', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type01.png')),
  profileConstructor('c05s03t02', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type02.png')),
  profileConstructor('c05s03t03', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type03.png')),
  profileConstructor('c05s03t04', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type04.png')),
  profileConstructor('c05s03t05', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type05.png')),
  profileConstructor('c05s03t06', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type06.png')),
  profileConstructor('c05s03t07', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type07.png')),
  profileConstructor('c05s03t08', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type08.png')),
  profileConstructor('c05s03t09', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type09.png')),
  profileConstructor('c05s03t10', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type10.png')),
  profileConstructor('c05s03t11', 3, 'grey', profileImages('./Emoji_color05_Skin03_Type11.png')),
]
