export const countries = {
  Spain: 'ES',
  Swiss: 'CH'
}

export const currencies = {
  ES: 'eur',
  CH: 'chf'
}
export const prefix = {
  ES: '+34',
  CH: '+41'
}
