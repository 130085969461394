import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import Checkbox from '@mui/material/Checkbox'

/// ////////COMPONENT PROPS
ListOptionItem.propTypes = {
  itemIcon: PropTypes.element,
  itemId: PropTypes.string.isRequired,
  itemText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  optionVariant: PropTypes.string,
}

const CustomSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.common.white,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: { backgroundColor: theme.palette.grey[500] },
}))(Switch)

const CustomCheckBox = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(Checkbox)

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    display: (props) => (props.itemIcon ? 'inline-block' : 'none'),
  },
}))

export default function ListOptionItem(props) {
  const classes = useStyles(props)
  const {
    itemIcon,
    itemId,
    itemText,
    disabled,
    onChange,
    checked,
    optionVariant, // Switch, CheckBox
  } = props

  const switchOption = (
    <CustomSwitch
      edge="end"
      onChange={onChange}
      checked={checked}
      inputProps={{ 'aria-labelledby': { itemId } }}
      disabled={disabled}
    />
  )

  const checkOption = (
    <CustomCheckBox
      edge="end"
      onChange={onChange}
      checked={checked}
      inputProps={{ 'aria-labelledby': itemId }}
      disabled={disabled}
    />
  )

  const Optionvariant = (prop) => {
    switch (prop) {
      case 'Switch':
        return switchOption
      case 'CheckBox':
        return checkOption
      default:
        return switchOption
    }
  }

  return (
    <ListItem>
      <ListItemIcon className={classes.listItemIcon}>{itemIcon}</ListItemIcon>
      <ListItemText id={itemId} primary={itemText} />
      <ListItemSecondaryAction>
       {Optionvariant(optionVariant)}
      </ListItemSecondaryAction>
    </ListItem>
  )
}
