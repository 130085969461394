import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery, useMutation } from '@apollo/client'
import { GET_USER } from 'apis/querys'
import { DELETE_USER, UPDATE_USER } from 'apis/mutations'
import { auth } from 'apis/firebase'
import { Box, Grid, MenuItem, Skeleton, Typography } from '@mui/material'
import {
  AvatarInput,
  AvatarPicker,
  Button,
  CategoriesSelector,
  FileInput,
  Input,
  ButtonsList,
  MaskedInput,
  MultilineInput,
  CustomChip,
  SelectableInput,
  ScrollableContainer,
  Spacer,
  ChangePasswordDialog,
  DeleteAccountDialog,
  CountrySelect
} from 'components'
import { useFirebase, useForm, useI18N } from 'hooks'
import { UserContext } from 'context/UserContext'
import { legalForms } from 'constants/LegalForms'
import { routes } from 'constants/Routes'
import { useSnackbar } from 'context/SnackBarContext'
import { ROLE } from 'constants/Roles'

const defaultValues = {
  avatar: '',
  picture: '',
  country: '',
  userName: '',
  publicName: '',
  phone: '',
  email: '',
  nif: '',
  province: '',
  postalCode: '',
  city: '',
  categories: [],
  subCategories: [],
  legalForm: '',
  bio: '',
  password: ''
}

const maxPublicNameLength = 15

const createMenuItem = (id, title, action) => {
  return { id, title, action }
}

const sortObject = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key]
      return result
    }, {})
}

export default function Details() {
  const { t } = useI18N()
  const { user } = useContext(UserContext)
  const currentUser = auth.currentUser
  const history = useHistory()
  const { showSnackbar } = useSnackbar()
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [open, setOpen] = useState(false)
  const [changPassOpen, setChangPassOpen] = useState(false)
  const [avatarDialog, setAvatarDialog] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [passError, setPassError] = useState('')
  const [oldPassError, setOldPassError] = useState('')
  const [newPassError, setNewPassError] = useState('')
  const [initialValues, setInitialValues] = useState(defaultValues)

  //  FINISH THE UPDATE USER DETAILS
  const {
    uploadFile,
    storageUserProfile,
    reauthenticateUser,
    deleteUserAccount,
    updatePassword
  } = useFirebase()

  const { loading, data, client } = useQuery(GET_USER, {
    errorPolicy: 'all',
    variables: { userId: user.id, uid: user.uid }
  })

  const [deleteUser] = useMutation(DELETE_USER)
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { userId: user.id, uid: user.uid }
      }
    ]
  })

  const validate = (fieldValues = values) => {
    const temp = { ...errors }

    if ('publicName' in fieldValues) {
      if (!fieldValues.publicName.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s.]+$/g)) {
        temp.publicName = t('notValid')
      } else if (fieldValues.publicName.length > maxPublicNameLength) {
        temp.publicName = `${t('maximumCharacters')} ${maxPublicNameLength}`
      } else {
        temp.publicName = ''
      }
    }

    if ('phone' in fieldValues)
      temp.phone =
        user.role === ROLE.PARTICULAR ||
        fieldValues.phone.replace(/\s/g, '').length === 9
          ? ''
          : t('required')

    if ('categories' in fieldValues && user.role === 'company')
      temp.categories =
        fieldValues.categories.length > 0
          ? ''
          : t('youMustSelectAtLeastOneSubcategory')

    if ('picture' in fieldValues)
      temp.picture = fieldValues.picture !== null ? '' : 'picture error'

    setErrors({
      ...temp
    })

    // console.log({ errors: temp })

    if (fieldValues === values) {
      return Object.values(temp).every((value) => value === '')
    }
  }

  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    defaultValues,
    false,
    validate
  )

  useEffect(() => {
    return () => {
      setEditMode(false)
      setSaving(false)
      setInitialValues(defaultValues)
      setValues(defaultValues)
    }
  }, [])

  useEffect(() => {
    if (data && !loading && currentUser) {
      const usr = data.getUser
      const company = usr.company_data

      if (user.role === 'company') {
        const companyValues = {
          ...values,
          picture: { url: usr.picture?.url || '' },
          country: usr.country,
          userName: usr.name,
          publicName: usr.public_name,
          phone: usr.phone,
          email: usr.email,
          nif: company.nif,
          province: company.province,
          postalCode: company.postal_code,
          city: company.city,
          categories: company.categories,
          legalForm: company.legal_form,
          bio: company.bio
        }
        setValues(companyValues)
        setInitialValues(companyValues)
      } else {
        const userValues = {
          ...values,
          avatar: { pic: usr.picture.pic, bg: usr.picture.bg },
          country: usr.country,
          userName: usr.name,
          publicName: usr.public_name,
          email: usr.email
        }
        setValues(userValues)
        setInitialValues(userValues)
      }
    }
  }, [data, loading, currentUser])

  const changePassword = () => {
    updatePassword({
      newPassword: values.new_password,
      onCompleted: () => {
        updateUser({
          variables: {
            user_id: user.id,
            password: values.new_password
          }
        }).then(() => {
          setProcessing(false)
          showSnackbar({
            message: t('passwordResetSuccesfully'),
            severity: 'success'
          })
          signOut()
        })
      },
      onError: (e) => {
        setProcessing(false)
        setNewPassError(e.message)
      }
    })
  }

  const handleChangePassword = () => {
    setProcessing(true)
    setNewPassError('')
    setOldPassError('')
    if (values.new_password) {
      const passError =
        values.new_password.length >= 8 ? '' : t('minimum8characteres')
      setNewPassError(passError)
      if (passError === '') {
        reauthenticateUser({
          password: values.old_password || '',
          onCompleted: changePassword,
          onError: (e) => {
            setOldPassError(t('incorrectPassword'))
            setProcessing(false)
          }
        })
      }
    } else {
      setProcessing(false)
      setNewPassError(t('required'))
    }
  }

  const signOut = () => {
    client.resetStore().then(() => {
      auth.signOut()
    })
  }

  const updateData = (url) => {
    if (user.role === 'user') {
      updateUser({
        variables: {
          user_id: user.id,
          name: values.userName,
          public_name: values.publicName,
          picture: values.avatar,
          country: values.country
        }
      })
        .then(() => {
          setSaving(false)
          setEditMode(false)
          showSnackbar({
            message: t('profileUpdatedSuccessfully'),
            severity: 'success'
          })
        })
        .catch((e) => {
          // console.log(e);
          setSaving(false)
          setEditMode(false)
          showSnackbar({ message: t('somethingWentWrong') })
        })
    }
    if (user.role === 'company') {
      updateUser({
        variables: {
          user_id: user.id,
          public_name: values.publicName,
          phone: values.phone,
          picture: { url },
          categories: values.categories,
          bio: values.bio
        }
      })
        .then(() => {
          showSnackbar({
            message: t('profileUpdatedSuccessfully'),
            severity: 'success'
          })
          setSaving(false)
          setEditMode(false)
        })
        .catch((e) => {
          // console.log(e);
          setSaving(false)
          setEditMode(false)
          showSnackbar({ message: t('somethingWentWrong') })
        })
    }
  }

  const saveChanges = async () => {
    setSaving(true)
    let newValues = sortObject(values)
    let oldValues = sortObject(initialValues)
    newValues = JSON.stringify(newValues)
    oldValues = JSON.stringify(oldValues)

    if (newValues !== oldValues) {
      // console.log('something has change')
      // SOMETHING CHANGE
      if (validate()) {
        if (values.picture !== initialValues.picture) {
          // console.log('picture has change')
          // PICTURE HAS BEEN UPDATED
          const fileUrl = await uploadFile({
            storageRef: storageUserProfile(user.uid),
            file: values.picture.file,
            fileName: 'profilePicture'
          })
          updateData(fileUrl.data)
        } else {
          // PICTURE HAS NOT BEEN UPDATE
          // console.log('picture has not change')
          updateData(values.picture.url)
        }
      } else {
        // console.log('There is an error')
        setSaving(false)
      }
    } else {
      // NOTHING CHANGE
      // console.log('nothing has change')
      setSaving(false)
      setEditMode(false)
    }
  }

  const handleEditMode = () => {
    setEditMode(!editMode)
    setValues(initialValues)
    setErrors({})
  }

  // const handleEditCompanyData = () => {
  //   // TODO: Edit company aata
  // }

  const handleDeleteAccount = () => {
    setProcessing(true)

    const successAction = () => {
      setPassError('')
      deleteUser({
        variables: { user_id: user.id, token: user.uid }
      }).then(() => {
        deleteUserAccount({
          onCompleted: () => {
            showSnackbar({
              message: t('accountDeleted'),
              severity: 'success'
            })
            signOut()
          }
        })
      })
    }

    const errorAction = (e) => {
      setPassError(t('incorrectPassword'))
      setProcessing(false)
    }

    reauthenticateUser({
      password: values.password,
      onCompleted: successAction,
      onError: (e) => errorAction(e)
    })
  }

  const handleDialogState = (key) => () => {
    switch (key) {
      case 'delete':
        setOpen(!open)
        break
      case 'change':
        setChangPassOpen(!changPassOpen)
        break
      case 'avatar':
        setAvatarDialog(!avatarDialog)
        break
      default:
        break
    }
  }

  const passwordSettings = [
    createMenuItem(1, t('changePassword'), handleDialogState('change'))
  ]

  const accountSettings = [
    createMenuItem(1, t('editData'), handleEditMode),
    createMenuItem(2, t('signOut'), signOut),
    createMenuItem(3, t('deleteAccount'), handleDialogState('delete'))
  ]

  const companyAccountSettings = [
    createMenuItem(1, t('editCompanyData'), handleEditMode),
    // createMenuItem(2, t('editCompanyData'), handleEditCompanyData),
    createMenuItem(3, t('signOut'), signOut),
    createMenuItem(4, t('deleteAccount'), () =>
      history.push(routes.deleteAccount, { fromSettings: true })
    )
  ]

  return (
    <ScrollableContainer
      scrollTop={editMode}
      title={t('details')}
      appBarProps={{ hasBack: true }}
    >
      {loading ? (
        <Spacer m={2}>
          <LoadingPage />
        </Spacer>
      ) : (
        <>
          {/* Form section */}
          <Spacer m={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                {user.role === 'company' ? (
                  <FileInput
                    id='picture'
                    accept='.png,.jpg,.jpeg'
                    maxSize={5}
                    value={values.picture}
                    onChange={handleInputChange}
                    onError={(error) =>
                      setErrors((prev) => ({ ...prev, picture: error }))
                    }
                    disabled={!editMode || saving}
                  >
                    <AvatarInput
                      disabled={!editMode || saving}
                      image={values.picture}
                      alt={values.userName}
                    />
                  </FileInput>
                ) : (
                  <AvatarInput
                    onClick={() => setAvatarDialog(true)}
                    image={values.avatar}
                    alt={values.userName}
                    disabled={!editMode || saving}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CountrySelect
                  id='country'
                  onChange={handleInputChange}
                  label={t('country')}
                  value={values.country}
                  disabled={user.role === 'company' || !editMode || saving}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id='userName'
                  onChange={handleInputChange}
                  label={user.role === 'company' ? t('companyName') : t('name')}
                  value={values.userName}
                  required={editMode}
                  disabled={user.role === 'company' || !editMode || saving}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id='publicName'
                  onChange={handleInputChange}
                  label={
                    user.role === 'company' ? t('contactName') : t('publicName')
                  }
                  value={values.publicName}
                  required={editMode}
                  disabled={!editMode || saving}
                  error={errors.publicName}
                />
              </Grid>
              {user.role === 'company' && (
                <Grid item xs={12}>
                  <MaskedInput
                    id='phone'
                    onChange={handleInputChange}
                    label={t('phoneNumber')}
                    value={values.phone}
                    required={editMode}
                    disabled={!editMode || saving}
                    mask='+00 000 000 000'
                    error={errors.phone}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Input
                  id='email'
                  onChange={handleInputChange}
                  label={t('email')}
                  value={values.email}
                  helperText={
                    currentUser !== null && values.email !== currentUser.email
                      ? t('confirmationEmailWillBeSent')
                      : ''
                  }
                  required={editMode}
                  disabled
                />
              </Grid>
              {user.role === 'company' && (
                <>
                  <Grid item xs={12}>
                    <Input
                      id='nif'
                      onChange={handleInputChange}
                      label={t('nif')}
                      value={values.nif}
                      required={editMode}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      id='province'
                      onChange={handleInputChange}
                      label={t('province')}
                      value={values.province}
                      required={editMode}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      id='postalCode'
                      onChange={handleInputChange}
                      label={t('postalCode')}
                      value={values.postalCode}
                      required={editMode}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectableInput
                      id='legalForm'
                      label={t('legalForm')}
                      items={legalForms}
                      value={values.legalForm}
                      listContent='custom'
                      required={editMode}
                      onChange={handleInputChange}
                      disabled={!editMode || saving}
                    >
                      {legalForms.map((item, index) => (
                        <MenuItem key={`item-${index}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </SelectableInput>
                  </Grid>
                  <Grid item xs={12}>
                    <MultilineInput
                      id='bio'
                      onChange={handleInputChange}
                      label={t('bio')}
                      value={values.bio}
                      placeholder={t('bioPlaceHolder')}
                      required={editMode}
                      disabled={!editMode || saving}
                    />
                  </Grid>
                  {!editMode && (
                    <Grid item xs={12}>
                      <Typography color='textSecondary' fontSize='small'>
                        {t('categories')}
                      </Typography>
                      <Box
                        className='scrollable'
                        sx={{
                          display: 'flex',
                          alignContent: 'flex-start',
                          flexWrap: 'wrap',
                          gap: 1,
                          maxWidth: '100vw',
                          mt: 2,
                          maxHeight: 200
                        }}
                      >
                        {values.categories.map((category) => (
                          <CustomChip key={category} label={t(category)} />
                        ))}
                      </Box>
                    </Grid>
                  )}

                  {editMode && (
                    <Grid item xs={12}>
                      <CategoriesSelector
                        value={values.categories}
                        onChange={handleInputChange}
                      />
                      {errors.categories && (
                        <Typography color='error'>
                          {errors.categories}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Spacer>
          {/* Buttons section */}
          {!editMode ? (
            <>
              <ButtonsList
                divider
                headerTitle={t('pass')}
                list={passwordSettings}
                disableGutters
              />
              <ButtonsList
                divider
                headerTitle={t('account')}
                list={
                  user.role === 'company'
                    ? companyAccountSettings
                    : accountSettings
                }
                disableGutters
              />
            </>
          ) : (
            <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
              <Button
                fullWidth
                text={t('cancel')}
                variant='light'
                onClick={handleEditMode}
                disabled={saving}
              />
              <Button
                fullWidth
                text={saving ? t('saving') : t('save')}
                onClick={saveChanges}
                loading={saving}
              />
            </Box>
          )}
        </>
      )}

      <AvatarPicker
        id='avatar'
        open={avatarDialog}
        onClose={handleDialogState('avatar')}
        currentAvatar={values.avatar}
        onSave={handleInputChange}
      />
      <DeleteAccountDialog
        open={open}
        onChange={handleInputChange}
        processing={processing}
        passError={passError}
        onClose={handleDialogState('delete')}
        onDelete={handleDeleteAccount}
      />
      <ChangePasswordDialog
        open={changPassOpen}
        onChange={handleInputChange}
        processing={processing}
        oldPassError={oldPassError}
        newPassError={newPassError}
        onClose={handleDialogState('change')}
        onSave={handleChangePassword}
      />
    </ScrollableContainer>
  )
}

const LoadingPage = () => {
  return (
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Skeleton
          variant='circular'
          height={88}
          width={88}
          sx={{ mx: 'auto', mb: 3 }}
        />
      </Grid>
      {new Array(7).fill('').map((item, index) => (
        <Grid key={index} item xs={12} sx={{ textAlign: 'center' }}>
          <Skeleton
            variant='rectangular'
            height={58}
            width='100%'
            sx={{ borderRadius: 2 }}
          />
        </Grid>
      ))}
    </Grid>
  )
}
