import { alpha, Menu as MuiMenu, MenuItem, Typography } from '@mui/material'

// const menuListExample = [
//     {id: 'id', label: 'label'}
// ]

export default function Menu({ id, menuList, onItemClick, ...props }) {
  const menuItemsList = (list) =>
    list.map((item) => (
      <MenuItem
        key={`menu-item-${item.id}`}
        onClick={() => onItemClick(item.id)}
        TouchRippleProps={{
          sx: {
            color: (theme) => alpha(theme.palette.text.primary, 0.2)
          }
        }}
      >
        <Typography>{item.label}</Typography>
      </MenuItem>
    ))

  return (
    <MuiMenu
      {...props}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={id ?? 'menu'}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        '.MuiPaper-root': {
          boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
          borderRadius: 4
        }
      }}
    >
      {menuList && menuItemsList(menuList)}
    </MuiMenu>
  )
}
